import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
import isElectron from 'is-electron';
/**
 * Function to delete collection from library
 */
const copyLibrarySkillPack = async (formData) => {
	if (isElectron()) {
		// TODO: check for electron
		const copyToLibraryAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.copyToLibrary.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.copyToLibrary.PATH_METHOD,
		});
		const copyToLibraryResponse = await copyToLibraryAPIFn(formData);
		return copyToLibraryResponse;
	}
};

export default copyLibrarySkillPack;
