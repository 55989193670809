import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import isElectron from 'is-electron';
//redux
import { store } from '../../redux/store';
import { updateBrainById } from '../../redux/actions';

/**
 * Function to Create a New Brain, given some parameters
 */
const sendStartSignal = async ({ brainId, device, slug }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	const formData = {
		_id: brainId,
		device: {
			id: device.id,
			deviceName: device.deviceName,
		},
		profileSlug: profileSlug,
	};
	var startBrainAPIFn, startBrainIPCFn, startBrainResponse;

	if (!isElectron()) {
		startBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.startBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.startBrain.PATH_METHOD,
		});
		startBrainResponse = await startBrainAPIFn(formData);
		if (!startBrainResponse['data']['error']) {
			return startBrainResponse['data']['results'];
		} else {
			return startBrainResponse['data'];
		}
	} else {
		startBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.startBrain.EVENT,
		});
		startBrainResponse = await startBrainIPCFn(formData);
		console.log('Start brain response from IPC Fetch');
		console.log(startBrainResponse);
		if (!startBrainResponse['data']['error']) {
			return startBrainResponse['data']['results'];
		} else {
			return startBrainResponse['data'];
		}
	}
};

const startBrain = ({ brain, slug, onDone, onError, onLoading }) => {
	console.log('yeet:', { brain, slug, onDone, onError, onLoading })

	const bId = brain._id
	console.log('yeet: bidts', bId.toString())

	let device = {
		id: brain.deviceId,
		deviceName: brain.deviceName,
	};
	onDone = typeof onDone === 'function' ? onDone : () => {};
	onError = typeof onError === 'function' ? onError : () => {};
	onLoading = typeof onLoading === 'function' ? onLoading : () => {};

	if (brain.status === 'STARTED') {
		onDone(false);
	} else {
		onLoading()
		sendStartSignal({ brainId: bId, device: device, slug: slug }).then( // Resolves when brain has started
			(res) => {
				if (res['error']) {
					onError();
				} else {
					if (isElectron()) {
						store.dispatch(
							updateBrainById(bId, {
								...res,
								status: 'STARTED',
							})
						);
						onDone(true);
					}
				}
			}
		);
	}
};

export default startBrain;
