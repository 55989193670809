/**
 * Device Actions
 *
 * Action creators for Device based actions.
 */

// Dependencies
// import { get } from "lodash";

// Action Types
import actionTypes from '../actionTypes';

/**
 * Set the Device Object
 *
 * Attempt to set the fleet object with list of devices containing user.
 *
 * @param {Object} devices The devices list obtained from the backend.
 */
const setDevices = (devices) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.SET_DEVICES,
		payload: {
			info: devices,
		},
	});
};

const setCurrentDevice = (device) => (dispatch, getState) => {
	//Dispath the ID
	dispatch({
		type: actionTypes.SET_CURRENT_DEVICE,
		payload: {
			info: device,
		},
	});
};

// Export the actions.
export { setDevices, setCurrentDevice };
