import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Input, useToast } from '@chakra-ui/react';
import IPCFetch from '../../../util/IPCFetch';
import SetupWizardContext from '../SetupWizardContext';
import ActionFooter from '../ActionFooter';
import registerDevice from '../../../functions/devices/registerDevice';
import isElectron from 'is-electron';
import { store } from '../../../redux/store';
import { setupWizardStages } from '../Config';
// import SelectBrainSW from './SelectBrainSW';
import SelectBrain from '../../Brains/SelectBrain';
import analytics from '../../../util/Analytics';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';
import { useSelector } from 'react-redux';
import getDevices from '../../../functions/devices/getDevices';

/**
 * Brain Creation
 */
const BrainCreation = ({ onFinish }) => {
	const { feature } = useContext(AnalyticsContext);
	const {
		currentStage,
		continueBtnRef,
		setStage,
		selectedBrain,
		setSelectedBrain,
	} = useContext(SetupWizardContext);

	const handleContinue = async () => {
		setStage(
			setupWizardStages.list[
				setupWizardStages.list.indexOf(currentStage) + 1
			]
		);
	};

	const brains = useSelector((state) => state.brains);

	useEffect(() => {
		if (continueBtnRef.current) {
			if (selectedBrain) {
				if (brains.byId[selectedBrain].status === 'STARTED') {
					continueBtnRef.current.disabled = false;
				} else {
					continueBtnRef.current.disabled = true;
				}
			} else continueBtnRef.current.disabled = true;
		}
	}, [selectedBrain, brains]);

	const { tier } = useSelector((store) => store.profiles.selected);

	const [limited, setLimited] = React.useState(false);
	const [skipSetup, setSkipSetup] = React.useState(false);
	// Check if device reg and workspace creation is skippable
	const checkIfSkip = async () => {
		getDevices().then((res) => {
			console.log('getDevices intro', res);
			if (res.error && res.message === 'No devices found for user') {
				setSkipSetup(false);
			} else if (brains.allIds.length === 0) {
				setSkipSetup(false);
			} else {
				setSkipSetup(true);
			}
		});
	};

	React.useEffect(() => {
		checkIfSkip();
	}, [brains]);
	//Limits removed
	// React.useEffect(() => {
	// 	if (tier === 'FREE') {
	// 		if (brains.allIds.length >= 2) {
	// 			setLimited(true);
	// 		}
	// 	}
	// }, [brains, tier]);

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box flex="1" py="4" display="flex" flexDir="column">
				<Box as="h1" fontWeight="medium" fontSize="3xl">
					{setupWizardStages.byId[currentStage].label}
				</Box>
				<Box
					fontWeight="medium"
					fontSize="1rem"
					mt="3"
					opacity="0.8"
					paddingBottom={'0.5rem'}
				>
					<span role="img">💡</span> A workspace is like a clean virtual
					environment where all the automation magic happens. All skills
					and modules are installed inside it.
				</Box>
				<SelectBrain
					onSelectFn={(id) => setSelectedBrain(id)}
					limited={limited}
					disableCrossButton={true}
					onlyDefaultBrain={true}
				/>
				{selectedBrain ? (
					<Box display="inline-block" my="4" fontSize="1rem" opacity="0.8">
						Selected! Next, press the "Continue" button.
					</Box>
				) : (
					brains.allIds.length === 0 && (
						<Box
							display="inline-block"
							my="4"
							fontSize="15px"
							lineHeight="28px"
							opacity="0.8"
						>
							Click on{' '}
							<Box
								as="span"
								bg="#565656"
								fontSize="13px"
								px="5"
								py="1.5"
								borderRadius="sm"
								mx="1"
								onClick={() =>
									analytics.track(
										`[${feature}] '+ New Workspace' Text Click`,
										{
											feature,
										}
									)
								}
							>
								+ New Workspace
							</Box>{' '}
							to create a new default Workspace, and start it on your
							device.
						</Box>
					)
				)}
			</Box>
			<ActionFooter
				continueBtnRef={continueBtnRef}
				currentSetupWizardStage={currentStage}
				onContinue={handleContinue}
				shopSkip={skipSetup}
				onFinish={onFinish}
				//showBackButton
				onBack={() =>
					setStage(
						setupWizardStages.list[
							setupWizardStages.list.indexOf(currentStage) - 1
						]
					)
				}
			/>
		</Box>
	);
};

export default BrainCreation;
