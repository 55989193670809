import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';
import isElectron from 'is-electron';
import { config } from '../../config/';

/**
 * Function to Create a New Brain, given some parameters
 */
const getDecodedAccessToken = async () => {
	if (isElectron()) {
		const getDecodedAccessTokenFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getDecodedAccessToken.EVENT,
		});
		/**
		 * @type {{
		 *		message: string,
		 *		data: {
		 *			error: boolean,
		 *			results: {
		 *				authenticationType: 'REFRESH_TOKEN',
		 *				email: string,
		 *				email_verified: boolean,
		 *				applicationId: string,
		 *				roles: [],
		 *				profileSlug: string,
		 *				access: [
		 *					{ permissions: string, slug: string, tier: 'FREE' | 'PREMIUM' },
		 *					{ permissions: string, slug: string, tier: 'FREE' | 'PREMIUM' },
		 *				],
		 *			},
		 *		},
		 *	}}
		 */
		const response = await getDecodedAccessTokenFn();
		return response;
	} else {
		let getDecodedAccessTokenApiFn, getDecodedAccessTokenApiFnResponse;
		getDecodedAccessTokenApiFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getProfileTier.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getProfileTier.PATH_METHOD,
		});
		getDecodedAccessTokenApiFnResponse = await getDecodedAccessTokenApiFn();
		if (getDecodedAccessTokenApiFnResponse.status === 200) {
			return getDecodedAccessTokenApiFnResponse;
		} else {
			return {
				data: {
					error: true,
				},
			};
		}
		// throw new Error('Cannot execute IPC call in browser!');
	}
};

export default getDecodedAccessToken;
