import React from 'react';
import { useColorModeValue, Box, Tooltip, Text } from '@chakra-ui/react';
import IconContainer from '../../util/React_Icon';

/**
 * TagWithIcon renders the tags at the bottom of module
 * cards.
 * Find in - Store > Modules
 *
 * @param {string} text - The text to display.
 * @param {component} icon - The icon to display
 */
const TagWithIcon = ({ text, icon, tooltip, onClick }) => {
	return (
		<Tooltip label={tooltip}>
			<Box
				bg={useColorModeValue('#F4F4F4', '#585858')}
				paddingX="14px"
				paddingY="4px"
				borderRadius="50px"
				display="flex"
				alignItems="center"
				cursor="pointer"
				marginRight="8px"
				border="0.473682px solid "
				borderColor={useColorModeValue('#ebebeb', '#434040')}
				boxSizing="border-box"
				boxShadow="0.947365px 0.947365px 0.947365px rgba(0, 0, 0, 0.05)"
				onClick={() => {
					if (typeof onClick === 'function') {
						onClick();
					}
				}}
			>
				<IconContainer
					icon={icon}
					value={{
						color: useColorModeValue('#8A8A8A', 'white'),
						className: 'global-class-name',
						size: '12px',
					}}
				/>
				{text ? (
					<Box
						ml={1}
						fontWeight="600"
						color={'white'}
						fontFamily="body"
						fontSize="12px"
					>
						{text}
					</Box>
				) : null}
			</Box>
		</Tooltip>
	);
};

export const TagWithIconMedium = ({
	text,
	icon,
	scale = 1,
	tooltip,
	props,
}) => {
	return (
		<Box
			bg={useColorModeValue('#F4F4F4', '#585858')}
			paddingX={`${11 * scale}px`}
			paddingY={`${4 * scale}px`}
			borderRadius={`${50 * scale}px`}
			display="flex"
			alignItems="center"
			cursor="pointer"
			marginRight={`${4 * scale}px`}
			border="0.473682px solid "
			borderColor={useColorModeValue('#ebebeb', '#434040')}
			boxSizing="border-box"
			boxShadow="0.947365px 0.947365px 0.947365px rgba(0, 0, 0, 0.05)"
			{...props}
		>
			<IconContainer
				icon={icon}
				value={{
					color: useColorModeValue('#8A8A8A', 'white'),
					className: 'global-class-name',
					size: `${10 * scale}px`,
				}}
			/>
			<Box
				marginLeft={`${5 * scale}px`}
				fontWeight="600"
				color={useColorModeValue('#8A8A8A', 'white')}
				fontFamily="body"
				fontSize={`${13 * scale}px`}
			>
				{text}
			</Box>
		</Box>
	);
};

export const TagWithIconSmall = ({ text, icon, scale = 1, tooltip }) => {
	return (
		<Box
			bg={useColorModeValue('#F4F4F4', '#585858')}
			paddingX={`${11 * scale}px`}
			paddingY={`${4 * scale}px`}
			borderRadius={`${50 * scale}px`}
			display="flex"
			alignItems="center"
			cursor="pointer"
			marginRight={`${4 * scale}px`}
			border="0.473682px solid "
			borderColor={useColorModeValue('#ebebeb', '#434040')}
			boxSizing="border-box"
			boxShadow="0.947365px 0.947365px 0.947365px rgba(0, 0, 0, 0.05)"
		>
			<IconContainer
				icon={icon}
				value={{
					color: useColorModeValue('#8A8A8A', 'white'),
					className: 'global-class-name',
					size: `${10 * scale}px`,
				}}
			/>
			<Text
				marginLeft={`${5 * scale}px`}
				fontWeight="600"
				color={useColorModeValue('#8A8A8A', 'white')}
				fontFamily="body"
				fontSize={`${10.5 * scale}px`}
				isTruncated
			>
				{text}
			</Text>
		</Box>
	);
};

export default TagWithIcon;
