/**
 * Usersnap Reducer
 *
 * Creates a Redux reducer for support widget events
 */

// Action Types
import actionTypes from '../actionTypes';

// Dependencies
import dotProp from 'dot-prop-immutable';

// Setup initial state
/**
 * @type {{
 * 	isOpen: boolean,
 * 	widgetIsOpen: boolean,
 * }}
 */
const initialState = {
	isOpen: false,
	widgetIsOpen: false,
};

const supportWidgetReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SUPPORT_WIDGET_SET_OPEN: {
			const { isOpen } = action.payload;
			state = dotProp.set(state, `isOpen`, isOpen);
			return state;
		}
		case actionTypes.SUPPORT_WIDGET_SET_WIDGET_OPEN: {
			const { widgetIsOpen } = action.payload;
			state = dotProp.set(state, `widgetIsOpen`, widgetIsOpen);
			return state;
		}
		default:
			return state;
	}
};

export default supportWidgetReducer;
