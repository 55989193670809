import React, { useContext } from 'react';
import { Box, Image } from '@chakra-ui/react';
import { manWriting, womanOnComputer } from '../../assets/setup/doodles';
import IPCFetch from '../../util/IPCFetch';
import ActionFooter from './ActionFooter';
import SetupWizardContext from './SetupWizardContext';
import { setupWizardStages } from './Config';
import getDevices from '../../functions/devices/getDevices';
import checkDevice from '../../functions/devices/checkDevice';
import getMachineDetails from '../../functions/devices/getMachineDetails';
import { connect, useSelector } from 'react-redux';
import { setCurrentDevice } from '../../redux/actions';
import VideoAnimationLoop from '../Reusable/VideoAnimation';

//assets
import demoAnimation from '../../assets/video/demo-animation.webm';
/**
 * Introduction to Maya
 */
const Introduction = ({ setCurrentDevice, onFinish }) => {
	const { allStages, currentStage, continueBtnRef, setStage } =
		useContext(SetupWizardContext);
	const brains = useSelector((store) => store.brains);
	const [skipSetup, setSkipSetup] = React.useState(false);
	// Check if device reg and workspace creation is skippable
	const checkIfSkip = async () => {
		getDevices().then((res) => {
			console.log('getDevices intro', res);
			if (res.error && res.message === 'No devices found for user') {
				setSkipSetup(false);
			} else if (brains.allIds.length === 0) {
				setSkipSetup(false);
			} else {
				setSkipSetup(true);
			}
		});
	};

	React.useEffect(() => {
		checkIfSkip();
	}, []);

	return (
		<Box display="flex" flexDir="column" height="100%" zIndex="50">
			<Box
				display="flex"
				flex="1"
				justifyContent="center"
				alignItems="center"
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="left"
					justifyContent="center"
					height="100%"
					zIndex="50"
					marginRight="65px"
					mt="-40px"
				>
					<Box
						fontSize="3.5rem"
						fontFamily="body"
						fontWeight="600"
						textAlign="left"
					>
						Introducing
					</Box>
					<Box
						color="#b495f3"
						mt="-1rem"
						fontSize="3.5rem"
						fontFamily="body"
						fontWeight="600"
						textAlign="left"
					>
						Maya.
					</Box>
					<Box
						fontSize="1.3rem"
						fontFamily="body"
						fontWeight="400"
						width="16rem"
						textAlign="left"
						mt="3"
						// mb="5rem"
					>
						A robotic assistant that gets things done faster.
					</Box>
				</Box>
				<VideoAnimationLoop src={demoAnimation} width={'450px'} />
			</Box>
			<ActionFooter
				continueBtnRef={continueBtnRef}
				currentSetupWizardStage={currentStage}
				showSkip={skipSetup}
				onFinish={onFinish}
				onContinue={() =>
					getDevices().then((res) => {
						console.log('getDevices intro', res);
						if (
							res.error &&
							res.message === 'No devices found for user'
						) {
							setStage(
								setupWizardStages.list[
									setupWizardStages.list.indexOf(currentStage) + 1
								]
							);
						} else if (res.results.length > 0) {
							console.log('setting current device', res.results[0]);
							getMachineDetails().then((machineDetailsResponse) => {
								if (machineDetailsResponse) {
									let machineId = machineDetailsResponse.machineID;
									checkDevice({
										machineID: machineId,
									}).then((deviceResponse) => {
										setCurrentDevice(deviceResponse.results.device);
										setStage(
											setupWizardStages.list[
												setupWizardStages.list.indexOf(
													currentStage
												) + 2
											]
										);
									});
								}
							});
							// setCurrentDevice(res.results[0]);
						} else {
							setStage(
								setupWizardStages.list[
									setupWizardStages.list.indexOf(currentStage) + 1
								]
							);
						}
					})
				}
			/>
		</Box>
	);
};

export default connect(null, { setCurrentDevice })(Introduction);
