import React from 'react';
import { Box, useColorMode, SimpleGrid, useToast } from '@chakra-ui/react';
import { FiFile, FiFileText, FiShoppingBag, FiCommand } from 'react-icons/fi';
import { AnimatePresence } from 'framer-motion';
import isElectron from 'is-electron';

//Components
import IconContainer from '../../util/React_Icon';
import HeaderWithDescription from '../Reusable/HeaderWithDesc';

//SkillOptions
import FromScratch from './NewSkillOptions/FromScratch';
import FromTemplate from './NewSkillOptions/FromTemplate';
import FromSkillGen from './NewSkillOptions/FromSkillGen';

//Analytics
import analytics from '../../util/Analytics';
import { HistoryWithSlug } from '../../util/History';

const NewSkillOption = ({
	option,
	setOption,
	header,
	desc,
	keyword,
	icon,
	onClick,
}) => {
	const { colorMode } = useColorMode();
	const fontColor = {
		light: '#444444',
		dark: 'white',
	};
	const descColor = {
		light: '#888383',
		dark: '#c3c3c3',
	};

	const borderColor = {
		light: 'gray.100',
		dark: 'dashboard.border_dark',
	};
	const bgSelectedColor = {
		light: '#f9f9f9',
		dark: 'maya_dark.250',
	};
	return (
		<Box
			height="90px"
			bg={option === keyword ? bgSelectedColor[colorMode] : 'none'}
			display="flex"
			alignItems="center"
			borderRadius="0.2rem"
			cursor="pointer"
			mb="0.5rem"
			mr="0.5rem"
			border="solid 1px"
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
			borderColor={borderColor[colorMode]}
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
			_hover={{ bg: bgSelectedColor[colorMode] }}
			onClick={() => {
				setOption(keyword);
				onClick();
				analytics.track(`New Skill Option Click`, {
					header,
				});
			}}
		>
			<Box ml="1.2rem" display="flex" alignItems="center">
				{icon}
				<Box ml="1.2rem">
					<Box
						fontFamily="body"
						color={fontColor[colorMode]}
						fontSize="16px"
					>
						{header}
					</Box>
					<Box
						fontFamily="body"
						fontSize="13px"
						color={descColor[colorMode]}
					>
						{desc}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const NewSkillSection = () => {
	const [option, setOption] = React.useState('new');
	const { colorMode } = useColorMode();
	const color = {
		light: '#686767',
		dark: 'white',
	};

	const toast = useToast();
	return (
		<Box overflow="scroll" width="100%">
			<HeaderWithDescription
				header={'Select Type'}
				desc={'Pick one these options to make the new Maya automation.'}
			/>
			<SimpleGrid columns={3}>
				<NewSkillOption
					header={'From Scratch'}
					desc={'Create a new Maya workflow from scratch.'}
					option={option}
					keyword="new"
					icon={
						<IconContainer
							icon={<FiFile />}
							value={{
								color: color[colorMode],
								className: 'global-class-name',
								size: '1.2rem',
							}}
						/>
					}
					onClick={() => {}}
					setOption={setOption}
				/>
				{false && (
					<NewSkillOption
						header={'From Library Template'}
						desc={'Use an existing Maya collection as a template.'}
						keyword="template"
						option={option}
						icon={
							<IconContainer
								icon={<FiFileText />}
								value={{
									color: color[colorMode],
									className: 'global-class-name',
									size: '1.2rem',
								}}
							/>
						}
						onClick={() => {
							HistoryWithSlug.push('/library');
						}}
						setOption={setOption}
					/>
				)}
				<NewSkillOption
					header={'From Maya Store'}
					desc={'Discover and install workflows from the store.'}
					keyword="store"
					option={option}
					icon={
						<IconContainer
							icon={<FiShoppingBag />}
							value={{
								color: color[colorMode],
								className: 'global-class-name',
								size: '1.2rem',
							}}
						/>
					}
					onClick={() => {
						HistoryWithSlug.push('/store');
					}}
					setOption={setOption}
				/>
				<NewSkillOption
					header={'From Workflow Generator'}
					desc={'Machine intelligence to auto-generate workflows.'}
					keyword="generator"
					option={option}
					icon={
						<IconContainer
							icon={<FiCommand />}
							value={{
								color: color[colorMode],
								className: 'global-class-name',
								size: '1.2rem',
							}}
						/>
					}
					onClick={() => {}}
					setOption={setOption}
				/>
				<NewSkillOption
					header={'From Bot Builder'}
					desc={'Request a custom tailor-made automation.'}
					keyword="custom"
					option={option}
					icon={
						<IconContainer
							icon={<FiCommand />}
							value={{
								color: color[colorMode],
								className: 'global-class-name',
								size: '1.2rem',
							}}
						/>
					}
					onClick={() => {
						if (isElectron()) {
							const electron = window.require('electron');
							const { shell } = electron;
							shell.openExternal('https://mayahq.com/custom');
						}
					}}
					setOption={setOption}
				/>
			</SimpleGrid>
			<AnimatePresence>
				{option === 'new' && <FromScratch />}
				{option === 'template' && <FromTemplate />}
				{option === 'generator' && <FromSkillGen />}
			</AnimatePresence>
		</Box>
	);
};

export default NewSkillSection;
