import React, { useContext } from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import _ from 'lodash';

//Components
import { StorePackItem } from './StorePackItemSW';
import { Box } from '@chakra-ui/react';

//Redux
import { connect } from 'react-redux';
import { updateBrainById } from '../../../redux/actions';

import SetupWizardContext from '../SetupWizardContext';

const StorePackList = ({
	brainIds,
	brains,
	height,
	onClickFn,
	selectedPackId,
	wholeItemActive,
	currentBrainId,
	updateBrainById,
}) => {
	const { selectedPublishedSkillPacks } = useContext(SetupWizardContext);

	const checkIsInstalled = (currentBrainId, packId) => {
		console.log('checkIsInstalled', currentBrainId, packId);
		let check;
		if (currentBrainId && brains.byId[currentBrainId]) {
			check =
				_.findIndex(
					brains.byId[currentBrainId].publishedSkillPacks,
					(pack) => {
						return pack.publishedSkillPack === packId;
					}
				) !== -1;
		} else {
			check = false;
		}
		console.log(check);
		return check;
	};

	return (
		<>
			<AnimateSharedLayout>
				<Box
					display="flex"
					height="100%"
					width="70%"
					position="relative"
					overflow="auto"
					borderRadius="md"
				>
					<motion.ul
						layout
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							position: 'absolute',
							borderRadius: '10px',
						}}
					>
						{selectedPublishedSkillPacks.map((publishedSkillPack, i) =>
							checkIsInstalled(
								currentBrainId,
								publishedSkillPack._id
							) ? (
								<StorePackItem
									key={publishedSkillPack._id}
									addButton={false}
									publishedSkillPack={publishedSkillPack}
									selected={false}
									onClick={(publishedSkillPack) =>
										console.log(publishedSkillPack._id)
									}
									wholeItemActive={wholeItemActive}
									isItemExpanded={i === 0}
									currentBrainId={currentBrainId}
									isInstalled={checkIsInstalled(
										currentBrainId,
										publishedSkillPack._id
									)}
									updateBrainById={updateBrainById}
								/>
							) : null
						)}
					</motion.ul>
				</Box>
			</AnimateSharedLayout>
		</>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	//let { query } = state.navigation;
	const brainIds = brains.allIds;
	return { brainIds, brains };
};

export default connect(mapStateToProps, { updateBrainById })(StorePackList);
