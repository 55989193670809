// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to get Devices
 */
const getMachineDetails = async () => {
	// Create an instance of your worker
	var getMachineDetailsFn;
	var getMachineDetailsResponse;
	if (isElectron()) {
		getMachineDetailsFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getMachineDetails.EVENT,
		});
		getMachineDetailsResponse = await getMachineDetailsFn();
	}

	return getMachineDetailsResponse;
};

export default getMachineDetails;
