import React, { useContext } from 'react';
import { Box, useColorMode, Image, Input } from '@chakra-ui/react';
import { connect, useDispatch, useSelector } from 'react-redux';
//Assets
import enterIconDark from '../../../../../../assets/cardui/entericon_dark.svg';
import enterIconLight from '../../../../../../assets/cardui/entericon_light.svg';
import defaultPlaceholderIconDark from '../../../../../../assets/cardui/defaultplaceholder_dark.svg';
import defaultPlaceholderIconLight from '../../../../../../assets/cardui/defaultplaceholder_light.svg';
import placeholderInstructionsDark from '../../../../../../assets/cardui/placeholder_search_instructions_dark.svg';
//Components
import TypeSelectorSearch from './TypeSelectorSearch';
import InstructionSection from '../../../../CardUI/Search/ExpandedSection/InstructionSection';
import ItemRightInstructions from '../../../../CardUI/Search/ExpandedSection/ItemInstructions';
// import { setVibrateInstructions } from '../../../../../../redux/actions/cardui';
import { truncate } from 'lodash-es';
import { vibrateInstructionsChange } from '../../../../../../redux/actions/cardUIV2';
import CommandBarContext from '../../CommandBarContext';

const InitialItem = ({ selected, text, type }) => {
	const ifMultipleInputPlaceholders = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.commandBar.searchArea.ifMultipleInputPlaceholders
	);
	const { colorMode } = useColorMode();
	const bgColorSelected = {
		light: 'card_ui.focus_light',
		dark: 'card_ui.focus_dark',
	};
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};
	const enterIcon = {
		light: enterIconLight,
		dark: enterIconDark,
	};
	const defaultPlaceholderIcon = {
		light: defaultPlaceholderIconLight,
		dark: defaultPlaceholderIconDark,
	};
	const dividerColor = {
		light: 'card_ui.divider_light',
		dark: 'card_ui.divider_dark',
	};

	const bgColor = {
		light: 'card_ui.placeholder_light',
		dark: 'card_ui.placeholder_dark',
	};

	return (
		<Box
			paddingY="1.5rem"
			paddingLeft="0.8rem"
			height="2rem"
			display="flex"
			alignItems="center"
			bg={selected ? bgColorSelected[colorMode] : 'none'}
			borderRight="solid 3px"
			borderRightColor={selected ? '#9B51E0' : 'transparent'}
			borderTop={'solid 1px'}
			justifyContent="space-between"
			borderTopColor={dividerColor[colorMode]}
			// roundedBottom={lastItem ? "1rem" : "0rem"}
		>
			<Box display="flex" alignItems="center">
				<Image
					src={defaultPlaceholderIcon[colorMode]}
					size="1.1rem"
					ml={2}
				/>
				<Box
					height="1.3rem"
					bg={bgColor[colorMode]}
					paddingX="0.7rem"
					paddingY="0.1rem"
					borderRadius="0.2rem"
					display="flex"
					justifyContent="center"
					alignItems="center"
					ml={5}
					cursor="pointer"
					color={color[colorMode]}
					_focus={{ outline: 'none' }}
					whiteSpace="nowrap"
					mb={1}
					mt={1}
				>
					<Input
						color={color[colorMode]}
						whiteSpace="nowrap"
						bg={bgColor[colorMode]}
						fontWeight="600"
						cursor="pointer"
						height="80%"
						padding="0"
						border="none"
						borderRadius="none"
						contentEditable
						textAlign={'center'}
						fontSize="0.9rem"
						value={type}
						onChange={() => {}}
						width={`${type.length * 0.55}rem`}
						_placeholder={{ color: '#777777' }}
						placeholder={type}
						_focus={{ outline: 'none' }}
						fontFamily="body"
					/>
				</Box>
				<Box
					display="flex"
					alignItems="center"
					ml={3}
					fontSize="0.9rem"
					fontFamily="body"
					fontWeight="600"
					flexWrap={'wrap'}
					color={color[colorMode]}
					whiteSpace="nowrap"
					textOverflow="ellipsis"
				>
					{text ? truncate(text, { length: 31 }) : 'Start typing...'}
				</Box>
			</Box>

			{selected ? (
				<ItemRightInstructions
					text={ifMultipleInputPlaceholders ? 'Shift + Enter' : 'Enter'}
				/>
			) : null}
		</Box>
	);
};

const PlaceholderSearch = () => {
	const dispatch = useDispatch();
	const { currentInputRef } = useContext(CommandBarContext);
	const currentPlaceholderId = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.commandBar.searchArea.selectedPlaceholderComponent
	);
	const currentIntentId = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea.selectedSearchItem
	);
	const placeholderType = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.entities.components.byId[currentPlaceholderId]?.type
	);
	const placeholderDetails = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities.components.byId[currentPlaceholderId]
	);
	const placeholderText = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.commandBar.searchArea.placeholderComponents.byId[
				currentPlaceholderId
			]?.inputValue
	);
	const selectedIndex = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.commandBar.suggestionsArea.placeholderSearch
				.selectedIndex
	);
	const vibrateInstructions = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea.vibrateInstructions
	);

	return (
		<Box
			onMouseDown={(e) => {
				e.preventDefault();
				dispatch(vibrateInstructionsChange({ vibrateInstructions: true }));
				setTimeout(
					() =>
						dispatch(
							vibrateInstructionsChange({ vibrateInstructions: false })
						),
					500
				);
				/** Select the current input */
				if (currentInputRef?.current) currentInputRef.current.select();
			}}
		>
			<InitialItem
				text={placeholderText ? placeholderText : ''}
				type={placeholderType || 'string'}
				selected={selectedIndex === 0}
			/>
			<TypeSelectorSearch
				placeholderText={placeholderText}
				placeholderType={placeholderType}
				selectedIndex={selectedIndex}
				placeholderDetails={placeholderDetails}
				currentIntentId={currentIntentId}
				currentPlaceholderId={currentPlaceholderId}
			/>
			<InstructionSection
				vibrate={vibrateInstructions}
				children={
					<Image
						src={placeholderInstructionsDark}
						height="1.2rem"
						mr={3}
					/>
				}
			/>
		</Box>
	);
};

export default PlaceholderSearch;
