import * as React from 'react';

import {
	Box,
	Input,
	Image,
	Select,
	Textarea,
	Button,
	SimpleGrid,
	useDisclosure,
	useToast,
	useColorModeValue,
	LightMode,
} from '@chakra-ui/react';
import dotProp from 'dot-prop-immutable';
import ReactPlayer from 'react-player';
import _ from 'lodash';

import { PreviewModal } from './PreviewModal';

import { MultiSelect } from '../../../util/CompLibrary/MultiSelect';
import { DeveloperAccountTabs } from '../../Settings/Developer/DeveloperAccount';

import History from '../../../util/History';
import getDeveloperAccounts from '../../../functions/developer/getDeveloperAccounts';
import getCategories from '../../../functions/categories/getCategories';
import getPlatforms from '../../../functions/platforms/getPlatforms';
import GetProfileSlug from '../../../util/ProfileSlug';

const FeaturedImage = ({ src }) => {
	return (
		<>
			<Box
				fontFamily="body"
				fontSize="0.9rem"
				color={useColorModeValue('#494949', '#f2f2f2')}
				mb={2}
			>
				Featured Thumbnail
			</Box>
			<Box width="15rem">
				<Image
					src={src}
					borderRadius="0.5rem"
					height="10rem"
					alt="cardimage"
					boxShadow={
						'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px'
					}
				/>
			</Box>
		</>
	);
};

/**
 * Preview Item
 */
const PreviewItem = ({ preview }) => {
	return (
		<Box>
			{preview.type === 'image' ? (
				<Image
					src={preview.img}
					width="12rem"
					height="7rem"
					mb="1"
					borderRadius="0.2rem"
					objectFit="cover"
					alt="cardimage"
					boxShadow={
						'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px'
					}
				/>
			) : (
				<ReactPlayer
					url={preview.url}
					width="12rem"
					height="7rem"
					light
					style={{
						boxShadow:
							'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px',
					}}
					config={{
						youtube: {
							playerVars: { controls: 1 },
						},
					}}
				/>
			)}
		</Box>
	);
};

/**
 * Add Preview Button
 */

const AddPreviewButton = ({ flowDetails, setFlowDetails }) => {
	const { isOpen, onOpen, onClose } = useDisclosure(false);
	const AddPreview = (preview) => {
		console.log(flowDetails);
		let details = dotProp.set(flowDetails, 'previews', (list) => [
			...list,
			preview,
		]);
		console.log('setFlowDetails: 1');
		setFlowDetails(details);
	};
	return (
		<>
			<Box
				fontFamily="body"
				fontSize="0.8rem"
				color="purple.200"
				_hover={{ textDecoration: 'underline' }}
				cursor="pointer"
				onClick={onOpen}
			>
				+ Add new
			</Box>
			<PreviewModal
				isOpen={isOpen}
				onClose={onClose}
				AddPreview={AddPreview}
			/>
		</>
	);
};

/**
 * Publish Page
 */

const PublishPage = ({
	nextStage,
	prevStage,
	flowDetails,
	setFlowDetails,
	updateID,
}) => {
	const toast = useToast();
	const [versions, setVersions] = React.useState([]);
	const [developerAccounts, setDeveloperAccounts] = React.useState([]);
	const [categories, setCategories] = React.useState([]);
	const [platforms, setPlatforms] = React.useState([]);
	const [selectedDeveloperAccount, setSelectedDeveloperAccount] =
		React.useState(null);
	const { slug } = GetProfileSlug();
	const getIntents = (pack, version) => {
		let intents = [];
		if (pack) {
			const versionObject = _.find(pack.versions, (v) => {
				return v.version === version;
			});
			if (versionObject) {
				intents = versionObject.flow.intents;
			}
		}
		console.log('getIntents', intents, pack, version);
		return intents;
	};
	const getModuleDependencies = (pack, version) => {
		let modList = [];
		if (pack) {
			const versionObject = _.find(pack.versions, (v) => {
				return v.version === version;
			});
			if (versionObject) {
				for (let mod of versionObject.flow.modules) {
					modList.push({
						module: mod.module._id,
						thumbnail: mod.module.thumbnail,
						name: mod.name,
						version: mod.version,
					});
				}
			}
		}
		console.log('getModuleDependencies', modList, pack, version);
		return modList;
	};

	const getInitialValues = async () => {
		//developer data
		const getDeveloperAccountsResponse = await getDeveloperAccounts({ slug });
		setDeveloperAccounts(getDeveloperAccountsResponse.data);
		if (getDeveloperAccountsResponse.data.length > 0) {
			setSelectedDeveloperAccount(getDeveloperAccountsResponse.data[0]);

			// categories
			const categories = await getCategories();
			setCategories(categories.data);
			// platforms
			const platforms = await getPlatforms();
			setPlatforms(platforms.data);
			// set versions
			let versionList = [];
			for (const v of flowDetails.skillPackDetails.versions) {
				versionList.push(v.version);
			}
			setVersions(versionList);

			// intents
			const intents = getIntents(
				flowDetails.skillPackDetails,
				flowDetails.currentVersion
			);

			// mods
			const moduleDependencies = getModuleDependencies(
				flowDetails.skillPackDetails,
				flowDetails.currentVersion
			);

			setFlowDetails({
				...flowDetails,
				intents: intents,
				moduleDependencies: moduleDependencies,
				developerId: getDeveloperAccountsResponse.data[0]._id,
			});
		}
	};

	const handleChange = (key, value) => {
		if (key === 'currentVersion') {
			const intents = getIntents(flowDetails.skillPackDetails, value);
			const res = getModuleDependencies(flowDetails.skillPackDetails, value);

			setFlowDetails({
				...flowDetails,
				[key]: value,
				intents: intents,
				moduleDependencies: res,
			});
		} else {
			console.log('setFlowDetails: 3');
			setFlowDetails({
				...flowDetails,
				[key]: value,
			});
		}
	};

	React.useEffect(() => {
		getInitialValues();
	}, []);

	//React.useEffect(() => {}, [setFlowDetails]);

	const handleVerifyAndNextOld = () => {
		nextStage();
	};

	const handleVerifyAndNext = () => {
		console.log('flowDetails', flowDetails);
		if (
			!flowDetails.platforms ||
			!flowDetails.categories ||
			!flowDetails.currentVersion ||
			!flowDetails.longDescription
			// !flowDetails.intents
		) {
			toast({
				title: 'Fields missing!',
				description: 'Make sure you fill them before continuing.',
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
		} else {
			nextStage();
		}
	};

	return (
		<>
			<Box pl={10} pt={4} overflow={'auto'} display="flex">
				<Box>
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
					>
						Developer Account
					</Box>
					{flowDetails.developerId ? (
						<Box display="flex">
							<Select
								height="2rem"
								width="11rem"
								//color={useColorModeValue("#494949", "#f2f2f2")}
								ml={3}
								mb={2}
								fontSize="0.8rem"
								focusBorderColor="purple.400"
								onChange={(e) =>
									handleChange('developerId', e.target.value)
								}
							>
								{developerAccounts.map((dev, i) => {
									return (
										<option value={dev._id} key={i}>
											{dev.name}
										</option>
									);
								})}
							</Select>
						</Box>
					) : (
						<Box
							fontFamily="body"
							fontSize="0.8rem"
							color="purple.200"
							_hover={{ textDecoration: 'underline' }}
							cursor="pointer"
							onClick={() => History.push('/settings?section=profile')}
						>
							No developer account found. Create an account here.
						</Box>
					)}
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
						mt={2}
					>
						Flow Name
					</Box>
					<Input
						placeholder="E.g. E-commerce Web Scraper Bot"
						focusBorderColor="purple.400"
						mb={2}
						width="100%"
						size="sm"
						onChange={(e) => handleChange('name', e.target.value)}
						defaultValue={flowDetails.name}
					/>
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
					>
						Short Description (10 words)
					</Box>
					<Input
						placeholder="E.g. Scrape e-commerce websites easily"
						focusBorderColor="purple.400"
						size="sm"
						onChange={(e) =>
							handleChange('shortDescription', e.target.value)
						}
						defaultValue={flowDetails.shortDescription}
						mb={2}
					/>
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
						mt={2}
					>
						Categories
					</Box>
					<MultiSelect
						name={'categories'}
						list={categories}
						initial={flowDetails.categories}
						onChangeFn={(e) => handleChange('categories', e)}
					/>
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
						mt={2}
					>
						Pricing
					</Box>
					<Select
						height="2rem"
						width="26.5rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mr={3}
						mb={2}
						fontSize="0.8rem"
						focusBorderColor="purple.400"
						onChange={(e) => handleChange('pricingType', e.target.value)}
						isRequired
						defaultValue={flowDetails.pricingType || 'free'}
					>
						<option value="free">Free</option>
						<option value="paid">Paid</option>
					</Select>
					{flowDetails.pricingType === 'paid' ? (
						<Box display="flex">
							<Input
								placeholder="Amount in $"
								focusBorderColor="purple.400"
								size="sm"
								value={flowDetails.pricingAmt}
								width="11rem"
								mb={2}
								onChange={(e) =>
									handleChange('pricingAmt', e.target.value)
								}
							/>
							<Select
								placeholder="Rate"
								focusBorderColor="purple.400"
								height="2.1rem"
								width="13rem"
								color="gray.600"
								ml={3}
								fontSize="0.8rem"
								onChange={(e) =>
									handleChange('pricingRate', e.target.value)
								}
								defaultValue={flowDetails.pricingRate || '/ once'}
							>
								<option value="/ once">paid once</option>
								<option value="/ mo">per month</option>
								<option value="/ yr">annually</option>
							</Select>
						</Box>
					) : null}

					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
					>
						Version
					</Box>
					<Box display="flex">
						{/* {flowDetails.versionType === "new" ? (
              <Input
                placeholder="E.g. 1.0.1a"
                focusBorderColor="purple.400"
                size="sm"
                height="2rem"
                width="13rem%"
                mb={2}
                defaultValue={flowDetails.version}
                onChange={(e) => handleChange("version", e.target.value)}
              />
            ) : null} */}
						<Select
							height="2rem"
							width="11rem"
							color={useColorModeValue('#494949', '#f2f2f2')}
							ml={3}
							mb={2}
							fontSize="0.8rem"
							focusBorderColor="purple.400"
							onChange={(e) =>
								handleChange('currentVersion', e.target.value)
							}
							defaultValue={flowDetails.currentVersion}
						>
							{versions.map((version, i) => {
								return (
									<option value={version} key={i}>
										{version}
									</option>
								);
							})}
						</Select>
					</Box>

					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
					>
						Visibility
					</Box>
					<Box display="flex">
						<Select
							height="2rem"
							width="11rem"
							color={useColorModeValue('#494949', '#f2f2f2')}
							ml={3}
							mb={2}
							fontSize="0.8rem"
							focusBorderColor="purple.400"
							onChange={(e) =>
								handleChange('visibility', e.target.value)
							}
							defaultValue={flowDetails.visibilty || 'public'}
						>
							<option value={'public'}>{'public'}</option>
							<option value={'unlisted'}>{'unlisted'}</option>
						</Select>
					</Box>

					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
					>
						Select Compatible Platforms
					</Box>
					<MultiSelect
						name={'platforms'}
						list={platforms}
						initial={flowDetails.platforms}
						onChangeFn={(e) => handleChange('platforms', e)}
					/>
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
						mt={2}
					>
						Long Description
					</Box>
					<Textarea
						placeholder="Here is a sample placeholder"
						size="sm"
						value={flowDetails.longDescription}
						width="25rem"
						height="3rem"
						borderRadius="0.2rem"
						focusBorderColor="purple.400"
						onChange={(e) =>
							handleChange('longDescription', e.target.value)
						}
					/>
				</Box>
				<Box pl={10} width="20rem">
					<FeaturedImage
						src={
							flowDetails.thumbnail ||
							'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
						}
					/>
					<Box
						fontFamily="body"
						fontSize="0.9rem"
						color={useColorModeValue('#494949', '#f2f2f2')}
						mb={1}
						mt={2}
						display="flex"
						alignItems="center"
					>
						<Box mr={2}>Previews</Box>
						<AddPreviewButton
							flowDetails={flowDetails}
							setFlowDetails={setFlowDetails}
						/>
					</Box>

					<Box display="flex">
						<SimpleGrid columns={1} spacing={2}>
							{flowDetails.previews &&
								flowDetails.previews.map((preview, i) => {
									return <PreviewItem key={i} preview={preview} />;
								})}
						</SimpleGrid>
					</Box>
				</Box>
				<LightMode>
					<Box display="flex" flexDirection="column">
						<Button
							colorScheme="green"
							size="sm"
							onClick={handleVerifyAndNext}
							mb={2}
							borderRadius="20px"
						>
							Save & Continue
						</Button>
						<Button
							colorScheme="purple"
							size="sm"
							onClick={prevStage}
							borderRadius="20px"
						>
							Back
						</Button>
					</Box>
				</LightMode>
			</Box>
		</>
	);
};

export default PublishPage;
