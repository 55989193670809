import EmailTypeSearch from './EmailType';
import OldStringTypeSearch from './OldStringType';
import StringTypeSearch from './StringType';
import FileTypeSearch from './FileType';
import TimeTypeSearch from './TimeType';
import AppTypeSearch from './AppType';
import PhoneTypeSearch from './PhoneType';
import TimeRangeTypeSearch from './TimeRangeType';
import CustomTypeSearch from './CustomType';
import { connect } from 'react-redux';
import {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
} from '../../../../../../redux/actions';
const mapStateToProps = (state) => {
	let { searchItems, selectedIndex } =
		state.cardUI.centerSearch.middle.placeholderSearchList;
	return { searchItems, selectedIndex };
};

const mapContactStateToProps = (state) => {
	let { searchItems, selectedIndex } =
		state.cardUI.centerSearch.middle.placeholderSearchList;
	let { contacts } = state.profiles;
	return { searchItems, selectedIndex, contacts };
};

let ConnectedEmailTypeSearch = connect(mapContactStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(EmailTypeSearch);

let ConnectedStringTypeSearch = connect(mapStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(StringTypeSearch);

let ConnectedFileTypeSearch = connect(mapStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(FileTypeSearch);

let ConnectedTimeTypeSearch = connect(mapStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(TimeTypeSearch);

let ConnectedAppTypeSearch = connect(mapStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(AppTypeSearch);

let ConnectedPhoneTypeSearch = connect(mapContactStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(PhoneTypeSearch);

let ConnectedTimeRangeTypeSearch = connect(mapStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(TimeRangeTypeSearch);

let ConnectedCustomTypeSearch = connect(mapStateToProps, {
	setMiddlePlaceholderText,
	setPlaceholderSearchItems,
	highlightPlaceholderSearchItem,
	setSearchBarLoadingStatus,
})(CustomTypeSearch);

export {
	ConnectedEmailTypeSearch,
	ConnectedStringTypeSearch,
	ConnectedFileTypeSearch,
	ConnectedTimeTypeSearch,
	ConnectedAppTypeSearch,
	ConnectedPhoneTypeSearch,
	ConnectedTimeRangeTypeSearch,
	ConnectedCustomTypeSearch,
};
