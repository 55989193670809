import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Input, useToast } from '@chakra-ui/react';
import { connect } from 'react-redux';
import { setCurrentDevice, setDevices } from '../../redux/actions';
import IPCFetch from '../../util/IPCFetch';
import SetupWizardContext from './SetupWizardContext';
import ActionFooter from './ActionFooter';
import registerDevice from '../../functions/devices/registerDevice';
import getMachineDetails from '../../functions/devices/getMachineDetails';
import checkDevice from '../../functions/devices/checkDevice';
import isElectron from 'is-electron';
import { setupWizardStages } from './Config';
import getMachineName from '../../functions/devices/getMachineName';
import analytics from '../../util/Analytics';
import AnalyticsContext from '../../util/Analytics/AnalyticsContext';

/**
 * Device Registration
 */
const DeviceRegistration = ({ setCurrentDevice }) => {
	const toast = useToast();
	const [deviceName, setDeviceName] = useState('');
	const [deviceNameIsCustomized, setDeviceNameIsCustomized] = useState(false);
	const [machineDetails, setMachineDetails] = React.useState({});
	const { currentStage, continueBtnRef, setStage } =
		useContext(SetupWizardContext);
	const { feature } = useContext(AnalyticsContext);
	console.log('machineDetails', machineDetails);
	const handleContinue = async () => {
		try {
			const res = await registerDevice({
				name: deviceName,
			});
			if (res.error) throw new Error(res.message);
			await getMachineDetails().then(async (machineDetailsResponse) => {
				if (machineDetailsResponse) {
					let machineId = machineDetailsResponse.machineID;
					setMachineDetails(machineId);
					const deviceResponse = await checkDevice({
						machineID: machineId,
					});
					setCurrentDevice(deviceResponse.results.device);
				}
			});
			analytics.track(`[${feature}] Register Device`, {
				feature,
				deviceName,
				deviceNameIsCustomized,
				status: 'success',
			});
			toast({
				title: 'Device Created',
				description: res.message,
				status: 'success',
				duration: 3000,
				isClosable: false,
			});
			setStage(
				setupWizardStages.list[
					setupWizardStages.list.indexOf(currentStage) + 1
				]
			);
		} catch (error) {
			console.error(error);
			analytics.track(`[${feature}] Register Device`, {
				feature,
				status: 'failure',
				error,
			});
			toast({
				title: 'Error Occurred.',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
		}
	};

	useEffect(() => {
		getMachineName().then((res) => {
			setDeviceName(res);
		});
	}, []);

	useEffect(() => {
		if (continueBtnRef.current) {
			if (deviceName) continueBtnRef.current.disabled = false;
			else continueBtnRef.current.disabled = true;
		}
	}, [continueBtnRef, deviceName]);

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box flex="1" py="4" display="flex" flexDir="column">
				<Box as="h1" fontWeight="medium" fontSize="3xl">
					{setupWizardStages.byId[currentStage].label}
				</Box>
				<Box fontWeight="medium" fontSize="1rem" mt="2.5">
					Enter a name for this device so that you can install skills on
					it.
				</Box>
				<Input
					placeholder="Enter device name"
					_focus={{
						borderColor: 'purple.500',
					}}
					my="5"
					value={deviceName}
					onChange={(e) => {
						setDeviceNameIsCustomized(true);
						setDeviceName(e.target.value);
					}}
					bg="#3C3C3C"
					zIndex="50"
				/>
				<Box fontWeight="medium" fontSize="1rem" opacity="0.8">
					<span role="img">💡</span> Using Maya, you can automate tasks by
					installing Maya skills across devices and platforms - desktop,
					cloud & soon, edge devices.
				</Box>
			</Box>
			<ActionFooter
				continueBtnRef={continueBtnRef}
				currentSetupWizardStage={currentStage}
				onContinue={handleContinue}
			/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { devices } = state;
	return { devices };
};

export default connect(mapStateToProps, { setCurrentDevice, setDevices })(
	DeviceRegistration
);
