/**
 * Device Reducer
 *
 * Creates a Redux reducer for populating the fleets.
 */

// Action Types
import actionTypes from '../actionTypes';

// Setup initial state with an fleet info object.
const initialState = {
	isLoading: true,
	showTrialExpiredModal: false,
};

// Export the Device Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_LOADING: {
			const { info } = action.payload;
			return { ...state, isLoading: info };
		}
		case actionTypes.SET_TRIAL_EXPIRY_MODAL_DISPLAY: {
			const { info } = action.payload
			return { ...state, showTrialExpiredModal: info }
		}
		default:
			return state;
	}
};
