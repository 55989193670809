import React from 'react';
import {
	Box,
	useColorMode,
	IconButton,
	useColorModeValue,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon, CloseIcon } from '@chakra-ui/icons';

// Components
import { CreateTeamSection } from '../Teams/CreateTeam';

// Utils
import History from '../../util/History';

const LandingPage = ({ children }) => {
	const { colorMode } = useColorMode();
	const bgColor = { light: 'white', dark: 'maya_dark.300' };
	function ColorModeExample() {
		const { colorMode, toggleColorMode } = useColorMode();
		return (
			<IconButton
				onClick={toggleColorMode}
				position="absolute"
				bottom="0"
				right="0"
				icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
			>
				Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
			</IconButton>
		);
	}
	return (
		<Box
			flexGrow="1"
			display="flex"
			height="100vh"
			flexDirection="column"
			alignItems="center"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			bg={bgColor[colorMode]}
		>
			<Box
				marginTop="2rem"
				fontSize="3rem"
				fontWeight="600"
				color={useColorModeValue('#444444', 'white')}
			>
				Create team workspace.
			</Box>
			<Box fontSize="1rem" color={useColorModeValue('#888383', '#c3c3c3')}>
				Fill some details here for your team mates.
			</Box>
			<Box
				display="flex"
				justifyContent="center"
				flexDirection="column"
				marginTop="4rem"
				width="30rem"
				alignItems="center"
			>
				<CreateTeamSection />
			</Box>

			<IconButton
				position="absolute"
				top="0"
				right="0"
				aria-label="Search database"
				icon={<CloseIcon />}
				bg="none"
				margin="0.5rem"
				onClick={() => History.go(-1)}
			/>

			<ColorModeExample />
		</Box>
	);
};

export default LandingPage;
