import isElectron from 'is-electron';

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import { store } from '../../redux/store';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const authenticateService = async ({ configDetails }) => {
	const { user } = store.getState();

	var authenticateServiceResponse, authenticateServiceIPCFn;
	let data = {
		config: {
			user: user.info.id,
			resource: configDetails.resource,
			type: configDetails.type,
			meta: configDetails.config.variables[0],
			redirectUrl: configDetails.redirectUrl,
			clientId: configDetails.clientId,
			authUrl: configDetails.authUrl,
			responseType: configDetails.responseType,
			extraAuthParams: configDetails.extraAuthParams,
			values: {},
		},
	};

	// for (let key in formData) {
	//     console.log(key);
	//     if (key !== "name") {
	//         data["config"]["values"][key] = formData[key];
	//     }
	// }
	if (isElectron()) {
		if (window.navigator.onLine) {
			authenticateServiceIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.authenticateContactService.EVENT,
			});
			authenticateServiceResponse = await authenticateServiceIPCFn(data);
			if (!authenticateServiceResponse.data.error) {
				return authenticateServiceResponse['data']['results'];
			}
		}
	} else {
		return {
			data: {
				error: true,
				message:
					'This service is available only for Maya Desktop Application',
			},
		};
	}
};

export default authenticateService;
