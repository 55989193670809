import React from 'react';
import { Handle } from 'react-flow-renderer';
import { useDisclosure } from '@chakra-ui/react';
import NodePreviewModal from './NodePreviewModal';

const customNodeStyles = {
	background: '#fff',
	color: '#474747',
	padding: '10px 30px',

	fontSize: '19px',
	fontFamily: 'lato',
	fontWeight: 500,
	letterSpacing: 0.5,

	borderRadius: 500,
	borderWidth: 2,
	borderStyle: 'solid',

	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

const CustomNode = ({ data }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<div
			style={{ ...customNodeStyles, borderColor: data.color || '#a1a1a1' }}
			onDoubleClick={onOpen}
		>
			<Handle
				type="target"
				position={'left'}
				style={{
					width: 17,
					height: 17,
					borderRadius: '50%',
					borderWidth: 0,
					left: '-7px',
					background: data.color || '#a1a1a1',
					zIndex: -1,
				}}
			/>
			<div>{data.label}</div>
			<Handle
				type="source"
				position={'right'}
				style={{
					width: 17,
					height: 17,
					borderRadius: '50%',
					borderWidth: 0,
					top: '50%',
					right: '-7px',
					background: data.color || '#a1a1a1',
					zIndex: 1,
				}}
			/>
			<NodePreviewModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				metadata={data.orgMetaData}
				label={data.label}
			/>
		</div>
	);
};

export default CustomNode;
