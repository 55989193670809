import _ from 'lodash';

const mergeIntentObjectArray = (objectArray) => {
	let mergedArray = [];
	objectArray.map((object) => {
		mergedArray.push(object.value);
	});

	return _.join(mergedArray, ' ');
};

export const formatPublishedSkillPackItems = (packs) => {
	var arr = [];
	for (var key in packs.byId) {
		let newItem = {
			_id: packs.byId[key]._id,
			name: packs.byId[key].name,
			commands: [],
		};

		packs.byId[key].currentFlow.intents.map((command) => {
			newItem.commands.push({
				_id: command._id,
				value: mergeIntentObjectArray(command.components),
			});
		});

		arr.push(newItem);
	}
	return arr;
};
