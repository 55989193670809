import React from 'react';

/**
 * @type {{
 *  currentStage: import("./Config").SetupWizardStage,
 *  setStage: (stageName: import("./Config").SetupWizardStage) => void,
 *  continueBtnRef: React.MutableRefObject<HTMLButtonElement>,
 *  selectedBrain: string,
 *  setSelectedBrain: (id: string) => void,
 *  selectedPublishedSkillPacks: any[],
 *  setSelectedPublishedSkillPacks: React.Dispatch<React.SetStateAction<any[]>>,
 * }}
 */
const contextObject = {
	currentStage: '',
	setStage: () => {},
	continueBtnRef: null,
	selectedBrain: '',
	setSelectedBrain: () => {},
	selectedPublishedSkillPacks: [],
	setSelectedPublishedSkillPacks: () => {},
};

const SetupWizardContext = React.createContext(contextObject);

export default SetupWizardContext;
