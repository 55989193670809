import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
import { store } from '../../redux/store';

const getPublishedSkillPackByDeveloper = async (formData) => {
	console.log(formData);
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;
	const getPublishedSkillPackByDeveloperAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH:
			APIRoutes.apiService.getPublishedSkillPackByDeveloper.PATH_SEARCH,
		PATH_METHOD:
			APIRoutes.apiService.getPublishedSkillPackByDeveloper.PATH_METHOD,
		URL_PARAM: {
			developerId: formData.developerId,
		},
	});

	const getPublishedSkillPackByDeveloperAPIResponse =
		await getPublishedSkillPackByDeveloperAPIFn({
			profileSlug,
			...formData,
		});
	return getPublishedSkillPackByDeveloperAPIResponse;
};

export default getPublishedSkillPackByDeveloper;
