import React, { useContext } from 'react';
import {
	Box,
	Image,
	Stack,
	useColorMode,
	useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { StarIcon } from '@chakra-ui/icons';
import _ from 'lodash';

import { GoVerified } from 'react-icons/go';
import { AiFillStar } from 'react-icons/ai';
import {
	FiGitPullRequest,
	FiDownload,
	FiGitBranch,
	FiCommand,
} from 'react-icons/fi';

//Components

import HorizontalItem from '../../Reusable/HorizontalItem';
import TagWithIcon from '../../Reusable/TagWithIcon';
import CommandList from '../../Commands/CommandList';
import {
	SkillsButton,
	InstallButton,
	UninstallButton,
} from './StorePackButtonsSW';
import SetupWizardContext from '../SetupWizardContext';
import analytics from '../../../util/Analytics';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';

export const StorePackItemLeft = ({ publishedSkillPack, onClick }) => {
	const { colorMode } = useColorMode();

	const rating = publishedSkillPack.rating ? publishedSkillPack.rating : 4;

	return (
		<Box display="flex" alignItems="center" onClick={onClick} height="100%">
			<Box
				height="100%"
				display="flex"
				alignItems="center"
				paddingX="5px"
				width="130px"
				justifyContent="center"
				ml="1"
			>
				<Image
					src={publishedSkillPack.thumbnail}
					objectFit="cover"
					borderRadius="5px"
				/>
			</Box>
			<Box ml="2">
				<Box
					color="#555555"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						fontSize="18px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
					>
						{publishedSkillPack.name}
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Box
						fontSize="13px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
						opacity="0.7"
					>
						{_.truncate(publishedSkillPack.shortDescription, {
							length: 55,
							separator: /,? +/,
						})}
					</Box>
				</Box>
				<Box
					color="#555555"
					// fontSize="1rem"
					display="flex"
					flexDirection="row"
					alignItems="center"
					mt="4px"
				>
					<TagWithIcon text={'Maya Official'} icon={<GoVerified />} />

					{false && (
						<TagWithIcon
							text={publishedSkillPack.currentVersion}
							icon={<FiGitPullRequest />}
						/>
					)}
					<TagWithIcon
						text={`${publishedSkillPack?.currentFlow?.intents?.length} skills`}
						icon={<FiCommand />}
					/>
				</Box>
			</Box>
		</Box>
	);
};

export const StorePackItem = ({
	publishedSkillPack,
	skillsButton,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	addButton,
	rightItem,
	isInstalled,
	updateBrainById,
}) => {
	const [hoveredButton, setHoveredButton] = useState('');
	const [isOpen, setIsOpen] = useState(isItemExpanded);
	const { selectedPublishedSkillPacks, setSelectedPublishedSkillPacks } =
		useContext(SetupWizardContext);
	const { feature } = useContext(AnalyticsContext);

	React.useEffect(() => {
		setIsOpen(isItemExpanded);
	}, [isItemExpanded]);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<HorizontalItem
			isOpen={isOpen}
			onClickItem={
				wholeItemActive
					? () => {
							toggleOpen();
							onClick(publishedSkillPack._id);
					  }
					: () => onClick(publishedSkillPack._id)
			}
			selected={selected}
			itemLeft={
				<StorePackItemLeft
					publishedSkillPack={publishedSkillPack}
					onClick={() => {
						toggleOpen();
						onClick(publishedSkillPack._id);
						analytics.track(
							`[${feature}] StorePackItem: StorePackItemLeft Click`,
							{
								feature,
								publishedSkillPackId: publishedSkillPack._id,
								publishedSkillPackName: publishedSkillPack.name,
							}
						);
					}}
					isInstalled={isInstalled}
				/>
			}
			buttons={
				<Box display="flex">
					{skillsButton === false ? null : (
						<SkillsButton
							onClick={() => {
								toggleOpen();
								onClick(publishedSkillPack._id);
								analytics.track(
									`[${feature}] StorePackItem: SkillsButton Click`,
									{
										feature,
										publishedSkillPackId: publishedSkillPack._id,
										publishedSkillPackName: publishedSkillPack.name,
									}
								);
							}}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
						/>
					)}
					{addButton === false ? null : !isInstalled && !selected ? (
						<InstallButton
							onClick={() =>
								setSelectedPublishedSkillPacks((old) => {
									if (
										old.some(
											(psp) => psp._id === publishedSkillPack._id
										)
									)
										return old;
									else return [...old, { ...publishedSkillPack }];
								})
							}
							publishedSkillPack={publishedSkillPack}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							currentBrainId={currentBrainId}
							updateBrainById={updateBrainById}
						/>
					) : (
						<UninstallButton
							onClick={() =>
								setSelectedPublishedSkillPacks((old) => {
									const temp = [...old];
									const index = temp.findIndex(
										(psp) => psp._id === publishedSkillPack._id
									);
									if (index > -1) temp.splice(index, 1);
									return temp;
								})
							}
							publishedSkillPack={publishedSkillPack}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							currentBrainId={currentBrainId}
							updateBrainById={updateBrainById}
						/>
					)}

					{rightItem}
				</Box>
			}
			isItemExpanded={isItemExpanded}
			expandedContent={
				<Box
					display="flex"
					justifyContent="space-between"
					flexDir="column"
					paddingLeft="11rem"
				>
					<CommandList commands={publishedSkillPack?.currentFlow?.intents} />
				</Box>
			}
		/>
	);
};

//
