import React from 'react';
import { Box } from '@chakra-ui/react';
import { connect } from 'react-redux';
import _ from 'lodash';
//Components
import HorizontalMenu from '../Reusable/HorizontalMenu';
import AddToLibraryButton from './AddToLibraryButton';

//Components
import AddNewOrUpdate from './AddNewOrUpdate';
import LibrarySkillPackSection from './LibrarySkillPackSection';
import LibraryStoreSection from './LibraryStoreSection';

//Functions
import getLibraryItems from '../../functions/library/getLibraryItems';

import GetProfileSlug from '../../util/ProfileSlug';

const ItemSection = ({
	isLoading,
	results,
	currentBrainId,
	packs,
	setPacks,
}) => {
	const [option, setOption] = React.useState('Internal');
	//console.log(results);
	return (
		<Box flex="1 1 auto" display="flex" flexDirection="column" height="100%">
			{/* <HorizontalMenu
				list={[
					{
						label: 'Internal',
						onClick: () => {
							setOption('Internal');
						},
					},
					{
						label: 'From Store',
						onClick: () => {
							setOption('From Store');
						},
					},
				]}
				mode={option}
				setMode={setOption}
			/> */}
			{option === 'Internal' ? (
				<LibrarySkillPackSection
					isLoading={isLoading}
					currentBrainId={currentBrainId}
					skillsButton={true}
					addButton={true}
					onClick={() => {}}
					deleteButton={true}
					packs={packs}
					setPacks={setPacks}
				/>
			) : (
				<LibraryStoreSection
					publishedSkillPacks={
						results.publishedSkillPacks ? results.publishedSkillPacks : []
					}
					currentBrainId={currentBrainId}
				/>
			)}
		</Box>
	);
};

const LibrarySection = ({ initMode, pageMode, currentBrainId }) => {
	const [mode, setMode] = React.useState(initMode || 'list');
	const [isLoading, setLoading] = React.useState(true);
	const [packs, setPacks] = React.useState([]);
	const [results, setResults] = React.useState({});
	const { slug } = GetProfileSlug();
	React.useEffect(() => {
		setLoading(true);
		setPacks([]);
		getLibraryItems({ slug }).then((res) => {
			console.log(res);
			setResults(res);
			let packItems = _.map(res.skillPacks, (pack) => {
				let currentVersionDetails = _.find(
					pack.skillPack.versions,
					function (versionDetails) {
						return (
							versionDetails.version === pack.skillPack.currentVersion
						);
					}
				);

				return {
					...pack.skillPack,
					currentVersionDetails,
				};
			});
			setPacks(packItems);
			setLoading(false);
		});
	}, [slug]);

	return (
		<Box
			borderRadius="10px"
			flex="1 1 auto"
			display="flex"
			flexDirection="column"
			// bg='green'
		>
			{pageMode === 'editor' ? (
				<AddToLibraryButton mode={mode} setMode={setMode} />
			) : null}
			{mode === 'list' ? (
				<ItemSection
					results={results}
					isLoading={isLoading}
					currentBrainId={currentBrainId}
					packs={packs}
					setPacks={setPacks}
				/>
			) : null}
			{mode === 'add' ? (
				<AddNewOrUpdate
					currentBrainId={currentBrainId}
					packs={packs}
					setPacks={setPacks}
					isLoading={isLoading}
				/>
			) : null}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { pageMode } = state.navigation;
	return { pageMode };
};

export default connect(mapStateToProps, null)(LibrarySection);
