import {
	Accordion,
	Box,
	AccordionButton,
	AccordionItem,
	AccordionIcon,
	AccordionPanel,
} from '@chakra-ui/react';

const AccordionSection = ({ title, expandedContent, rightHeaderContent }) => {
	return (
		<Accordion allowToggle defaultIndex={0} borderColor="transparent">
			<AccordionItem>
				{({ isExpanded }) => (
					<>
						<Box
							display="flex"
							flexDirection="row"
							justifyContent="space-between"
							alignItems="center"
							borderBottom="solid 1px"
							borderColor="#464646"
						>
							<AccordionButton
								display="flex"
								flexDirection="row"
								justifyContent="space-between"
								paddingX="0px"
								_focus={{ outline: 0, boxShadow: 'none' }}
							>
								<Box
									display="flex"
									flexDirection="row"
									alignItems="center"
								>
									<AccordionIcon boxSize="1rem" />
									<Box
										fontWeight="500"
										fontSize="1.1rem"
										textAlign="left"
										paddingLeft="0.5rem"
									>
										{title}
									</Box>
								</Box>
							</AccordionButton>
							{isExpanded ? rightHeaderContent : null}
						</Box>
						<AccordionPanel pb={4} paddingX="0px">
							{expandedContent}
						</AccordionPanel>
					</>
				)}
			</AccordionItem>
		</Accordion>
	);
};

export default AccordionSection;
