import React from 'react';
import { ItemCard } from '../Reusable/HorizontalBoxCardWithDescription';
import SkillPackInstallModal from './SkillPackInstallModal';
import isElectron from 'is-electron';

import {
	useColorMode,
	useColorModeValue,
	Image,
	Tooltip,
	useDisclosure,
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FiGitBranch, FiDownload, FiGitPullRequest } from 'react-icons/fi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { DeleteButton, CopyToButton } from './SkillPackButtons';

export const CardMenu = ({ pack, packs, setPacks }) => {
	return (
		<Menu>
			<MenuButton>
				<BsThreeDotsVertical size="17px" />
			</MenuButton>
			<MenuList color={useColorModeValue('#555555', 'white')} bg="#444444">
				<MenuItem>
					<DeleteButton pack={pack} packs={packs} setPacks={setPacks} />
				</MenuItem>
				<MenuItem>
					<CopyToButton pack={pack} packs={packs} setPacks={setPacks} />
				</MenuItem>
			</MenuList>
		</Menu>
	);
};
export const SkillPackItemCard = ({
	pack,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	skillsButton,
	addButton,
	deleteButton,
	isInstalled,
	packs,
	setPacks,
	updateBrainById,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Box>
			<ItemCard
				key={pack._id}
				thumbnail={
					pack.thumbnail ||
					'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
				}
				heading={pack.name}
				description={pack.description}
				pack={pack}
				packs={packs}
				setPacks={setPacks}
				//isInstalledGlobal={isInstalledGlobal}
				onClick={() => {
					if (isElectron()) {
						onOpen();
					} else {
						onClick(pack);
					}
				}}
				version={pack.currentVersion}
				scale={0.75}
				menu={
					<Box
						height="100%"
						display="flex"
						justifyContent="flex-start"
						alignItems="flex-start"
						paddingTop="5px"
					>
						<CardMenu pack={pack} packs={packs} setPacks={setPacks} />
					</Box>
				}
				devTag={false}
			/>
			<SkillPackInstallModal
				onClose={onClose}
				isOpen={isOpen}
				currentBrainId={currentBrainId}
				pack={pack}
			/>
		</Box>
	);
};
