import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';

const getCategories = async () => {

	const getCategoriesAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getCategories.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getCategories.PATH_METHOD,
	});

	const getCategoriesAPIResponse = await getCategoriesAPIFn();
	console.log("getCategories: ", getCategoriesAPIResponse)
	return getCategoriesAPIResponse;
};

export default getCategories;
