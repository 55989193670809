import React, { useEffect, useRef, useState } from 'react';
import {
	Modal,
	ModalContent,
	ModalHeader,
	ModalBody,
	Box,
	Image,
	ModalFooter,
	ModalOverlay,
	Img,
} from '@chakra-ui/react';
import dark_icon from '../../assets/header/dark_icon.svg';
import Introduction from './Introduction';
import { connect, useSelector } from 'react-redux';
import setupCheck from '../../functions/setup/setupCheck';
import DeviceRegistration from './DeviceRegistration';
import ActionFooter from './ActionFooter';
import SetupWizardContext from './SetupWizardContext';
import BrainCreation from './BrainCreation';
import { setupWizardStages } from './Config';
import SkillInstallation from './SkillInstallation';
import ModuleConfiguration from './ModuleConfiguration';
import FinishSetup from './FinishSetup';
import toggleSetupStatus from '../../functions/setup/toggleSetupStatus';
import analytics from '../../util/Analytics';
import AnalyticsContext from '../../util/Analytics/AnalyticsContext';
import toggleCommandBar from '../../functions/commandBar/toggleCommandBar';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

//assets
import mandalaOne from '../../assets/patterns/mandala_one.png';
import mandalaTwo from '../../assets/patterns/mandala_two.png';

/**
 * Install Wizard
 * @param {*} param0
 * @returns
 */
const InstallWizard = ({ isOpen, onOpen, onClose, isClosable }) => {
	const [stage, setStage] = useState(setupWizardStages.list[0]);
	const [selectedBrain, setSelectedBrain] = useState('');
	const [selectedPublishedSkillPacks, setSelectedPublishedSkillPacks] =
		useState([]);
	const btnRef = useRef();
	/**
	 * @type {React.MutableRefObject<HTMLButtonElement>}
	 */
	const continueBtnRef = useRef(null);

	const handleFinishSetup = async () => {
		try {
			toggleSetupStatus(true).then((res) => {
				onClose();
			});
			// toggleCommandBar({ method: 'finish button' });
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (!isOpen && !isClosable) {
			setupCheck()
				.then((res) => {
					console.log(res);
					if (!res.setupDone) {
						onOpen();
					} else {
						onClose();
					}
				})
				.catch((err) => {
					console.log('SetupCheckError', err);
				});
		}
	}, [isOpen]);

	useEffect(() => {
		if (isOpen)
			analytics.track('[setup-wizard] Log Stage Change', {
				feature: 'setup-wizard',
				stage,
			});
	}, [isOpen, stage]);

	return (
		<AnalyticsContext.Provider value={{ feature: 'setup-wizard' }}>
			<SetupWizardContext.Provider
				value={{
					currentStage: stage,
					setStage,
					continueBtnRef,
					selectedBrain,
					setSelectedBrain,
					selectedPublishedSkillPacks,
					setSelectedPublishedSkillPacks,
				}}
			>
				<CustomModal
					initialFocusRef={continueBtnRef}
					finalFocusRef={btnRef}
					onClose={onClose}
					isOpen={isOpen}
					closeOnOverlayClick={true}
					closeOnEsc={false}
					isCentered
				>
					<ModalOverlay />
					<ModalContent
						maxWidth="75vw"
						height="78vh"
						borderRadius="1rem"
						boxShadow="10px 10px 40px 4px rgba(0, 0, 0, 0.75)"
						background="#313131"
						display="flex"
						flexDir="column"
						p="9"
						overflow="hidden"
						onKeyDown={(e) => {
							const trackKeys = [
								'Shift',
								'Control',
								'Alt',
								'Tab',
								'Enter',
								' ',
								'ArrowDown',
								'ArrowUp',
								'ArrowLeft',
								'ArrowRight',
								'Backspace',
								'Escape',
								'm',
								'M',
							];
							if (trackKeys.includes(e.key))
								analytics.track('[setup-wizard] Key Down', {
									feature: 'setup-wizard',
									key: e.key,
								});
						}}
					>
						<Img
							width={{ sm: '105vw', md: '95vw', lg: '45vw' }}
							height={{ sm: '105vw', md: '95vw', lg: '45vw' }}
							objectFit="cover"
							src={mandalaOne}
							position="absolute"
							opacity="0.09"
							left="0"
							right="0"
							textAlign="center"
							margin="auto"
							marginLeft="-38vh"
							marginTop={{ sm: '-35vh', md: '-55vh', lg: '-42vh' }}
							zIndex="1"
						/>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							zIndex="50"
						>
							<Box display="flex" alignItems="center">
								<Image src={dark_icon} width="9rem" />
							</Box>
							<Box fontSize="2xl" fontWeight="bold">
								Setup Wizard
							</Box>
						</Box>
						<Box flex="1">
							{stage === 'introduction' ? (
								<Introduction onFinish={handleFinishSetup} />
							) : null}
							{stage === 'deviceRegistration' ? (
								<DeviceRegistration />
							) : null}
							{stage === 'brainCreation' ? (
								<BrainCreation onFinish={handleFinishSetup} />
							) : null}
							{stage === 'skillInstallation' ? (
								<SkillInstallation onFinish={handleFinishSetup} />
							) : null}
							{stage === 'moduleConfiguration' ? (
								<ModuleConfiguration onFinish={handleFinishSetup} />
							) : null}
							{stage === 'finishSetup' ? (
								<FinishSetup onFinish={handleFinishSetup} />
							) : null}
						</Box>
					</ModalContent>
				</CustomModal>
			</SetupWizardContext.Provider>
		</AnalyticsContext.Provider>
	);
};

/**
 * Map State To Props
 *
 * @param {} state
 */
const mapStateToProps = (state) => {
	const { current } = state.devices;
	const { device } = current;

	return { currentDevice: device };
};

export default connect(mapStateToProps)(InstallWizard);
