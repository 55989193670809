//Dependencies
import React from 'react';
import { Box, Image, useColorMode } from '@chakra-ui/react';
import logo from '../../assets/icon.png';

import darkHeaderLogo from '../../assets/header/dark_icon.svg';
import lightHeaderLogo from '../../assets/header/light_icon.svg';

/**
 * Maya Mast, located at the top of the LeftBar. logo and text.
 */
const MayaMast = () => {
	const { colorMode } = useColorMode();
	const color = { light: 'maya_dark.500', dark: 'white' };

	const headerLogo = {
		light: lightHeaderLogo,
		dark: darkHeaderLogo,
	};
	return (
		<Box
			width="100%"
			height="50px"
			display="flex"
			alignItems="center"
			fontFamily="body"
			ml="5px"
			mt="20px"
			mb="20px"
		>
			<Image
				width="180px"
				height="150px"
				src={headerLogo[colorMode]}
				ml="10px"
			/>
		</Box>
	);
};

export default MayaMast;
