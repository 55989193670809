import { Button, IconButton } from '@chakra-ui/button';
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { CheckIcon, CloseIcon, CopyIcon } from '@chakra-ui/icons';
import { Box, Code, Flex, Stack, Text } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { Skeleton } from '@chakra-ui/skeleton';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import getMayaJson from '../../../functions/skillPack/getMayaJson';
import useQueryParams from '../../../util/useQueryParams';
import { getRedFlows } from '../RedApiWrappers';
import HeaderWithDescription from './HeaderWithDescription';
import JsonTabContent from './JsonTabContent';
import TabContext from './TabContext';
import { LightMode } from '@chakra-ui/color-mode';

/**
 * # Node/Flow Selector (type) definitions
 */
const nodeTypeDefs = [
	{
		id: 'selected',
		label: 'Selected Nodes',
	},
	{
		id: 'flow',
		label: 'Current Flow',
	},
	{
		id: 'full',
		label: 'All Flows',
	},
];

/**
 * # Tab Definitions
 * @type {{
 * 	id: string,
 * 	label: React.ReactNode,
 * 	content: React.ReactNode,
 * }[]}
 */
const tabDefs = [
	// {
	// 	id: 'node-list',
	// 	label: 'Node List',
	// 	content: null,
	// },
	{
		id: 'JSON',
		label: 'JSON',
		content: <JsonTabContent />,
	},
];

/**
 *
 * @param {{
 *  isOpen: boolean,
 *  onClose?: () => void,
 *  modalProps?: import('@chakra-ui/modal').ModalProps,
 * }} param0
 * @returns
 */
const ExportFlowsModal = ({ isOpen, onClose, modalProps }) => {
	const query = useQueryParams();
	const currentBrainId = query.get('id');
	const [mayaFlowJson, setMayaFlowJson] = useState(null);
	const [isFlowLoading, setIsFlowLoading] = useState(false);
	const [isFlowCopied, setIsFlowCopied] = useState(false);
	const [selectedNodesType, setSelectedNodesType] = useState(
		/**
		 * @returns {'selected' | 'flow' | 'full'}
		 */
		() => 'flow'
	);

	const handleGetFlowsJson = useCallback(async () => {
		try {
			setIsFlowCopied(false);
			setIsFlowLoading(true);
			const RED = window.RED;
			if (!RED) throw new Error(`RED object is not available!`);
			const redFlows = getRedFlows(RED, selectedNodesType);
			const res = await getMayaJson(null, redFlows, currentBrainId);
			if (res.error) throw new Error(res?.message);
			setMayaFlowJson(res?.data?.results);
		} catch (error) {
			console.error(error);
		}
		setIsFlowLoading(false);
	}, [currentBrainId, selectedNodesType]);

	const handleDownload = async (event) => {
		try {
			const jsonString = JSON.stringify(mayaFlowJson, null, 2);
			const blob = new Blob([jsonString], {
				type: 'application/json',
			}); // create blob
			saveAs(blob, 'maya-flow.json'); // download(save as)
		} catch (error) {
			console.error(error);
		}
	};

	const handleCopyToClipboard = async (event) => {
		try {
			setIsFlowCopied(false);
			await navigator.clipboard.writeText(
				JSON.stringify(mayaFlowJson, null, 2)
			);
			setIsFlowCopied(true);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		if (isOpen) handleGetFlowsJson();
	}, [handleGetFlowsJson, isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size="3xl"
			{...modalProps}
		>
			<ModalOverlay />
			<ModalContent height="xl" bg="#313131">
				<HeaderWithDescription
					header="Export Flows"
					description="Save to library, copy to clipboard, or download file. "
					// rightButton={
					// 	<IconButton
					// 		size="sm"
					// 		borderRadius="0.25rem"
					// 		bg="none"
					// 		icon={<CloseIcon />}
					// 		onClick={onClose}
					// 	/>
					// }
					containerProps={{
						px: '4',
						py: '3',
						borderTopRadius: 'xl',
					}}
				/>
				<ModalCloseButton />
				<ModalBody p="4" flex="1" minHeight="0" display="flex">
					<Stack direction="column" spacing="1.5" pr="3">
						{nodeTypeDefs.map((nodeTypeObj, i) => {
							return (
								<Checkbox
									bg="#424242"
									px="3"
									py="0.7rem"
									borderRadius="0.25rem"
									size="sm"
									key={nodeTypeObj.id}
									colorScheme="purple"
									value={nodeTypeObj.id}
									isChecked={nodeTypeObj.id === selectedNodesType}
									onChange={(e) => {
										setSelectedNodesType(nodeTypeObj.id);
									}}
								>
									<Text ml="1" fontSize="small">
										{nodeTypeObj.label}
									</Text>
								</Checkbox>
							);
						})}
					</Stack>
					<Flex flex="1" minWidth="0" direction="column">
						{isFlowLoading ? (
							<>
								<Skeleton
									h="1.7rem"
									startColor="#424242"
									endColor="#363636"
									mb="1"
									w="8rem"
								/>
								<Skeleton
									flex="1"
									minHeight="0"
									startColor="#424242"
									endColor="#363636"
								/>
							</>
						) : (
							<Tabs
								variant="enclosed"
								size="sm"
								flex="1"
								minHeight="0"
								display="flex"
								flexDirection="column"
								defaultIndex={0}
							>
								<TabList border="none" mb="0">
									{tabDefs.map((tab) => (
										<Tab
											key={tab.id}
											border="none"
											mr="1"
											bg="#424242"
											mb="0"
											filter="brightness(85%)"
											_selected={{
												bg: '#424242',
												filter: 'brightness(100%)',
											}}
										>
											{tab.label}
										</Tab>
									))}
								</TabList>
								<TabContext.Provider
									value={{
										mayaFlowJson,
										setMayaFlowJson,
									}}
								>
									<TabPanels
										flex="1"
										minHeight="0"
										display="flex"
										flexDirection="column"
										bg="#424242"
										borderRadius="0.25rem"
										borderTopLeftRadius="none"
									>
										{tabDefs.map((tab) => (
											<TabPanel
												key={tab.id}
												flex="1"
												minHeight="0"
												display="flex"
												flexDirection="column"
												p="1.5"
											>
												{tab.content}
											</TabPanel>
										))}
									</TabPanels>
								</TabContext.Provider>
							</Tabs>
						)}
					</Flex>
				</ModalBody>
				<ModalFooter py="3" px="4" bg="#363636" borderBottomRadius="xl">
					<Stack direction="row" spacing="3">
						<Button
							borderRadius="0.25rem"
							size="sm"
							onClick={handleDownload}
						>
							Download
						</Button>
						<LightMode>
							<Button
								size="sm"
								borderRadius="0.25rem"
								colorScheme={isFlowCopied ? 'green' : 'purple'}
								onClick={handleCopyToClipboard}
								leftIcon={
									isFlowCopied ? (
										<CheckIcon color="green" />
									) : (
										<CopyIcon />
									)
								}
							>
								{isFlowCopied ? 'Copied!' : 'Copy to Clipboard'}
							</Button>
						</LightMode>
					</Stack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
};

export default ExportFlowsModal;
