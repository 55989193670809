import isElectron from 'is-electron';

// functions
import getSkillPack from '../skillPack/getSkillPack';
import updateSkillPackToBrain from '../skillPack/updateSkillPackToBrain';
import getPublishedSkillPack from '../publishedSkillPack/getPublishedSkillPack';
import updatePublishedSkillPackToBrain from '../publishedSkillPack/updatePublishedSkillPackToBrain';
import getPublishedSkillpackGQuery from '../publishedSkillPack/getPublishedSkillpackGquery';
import { store } from '../../redux/store';

const updateOne = async ({ item, slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	console.log(item);
	try {
		if (isElectron()) {
			if (item.skillPack) {
				let skillPack = await getSkillPack({ _id: item.skillPack._id });
				console.log('updating skillPack', skillPack.name);
				if (skillPack) {
					for (let brainId of item['brains']) {
						await updateSkillPackToBrain({
							SkillPackToInstall: skillPack,
							version: skillPack.currentVersion,
							brainId: brainId,
							profileSlug: profileSlug,
						});
					}
				}
			} else if (item.publishedSkillPack) {
				// get publishedSkillPack
				let response = await getPublishedSkillpackGQuery({
					_id: item.publishedSkillPack._id,
				});
				if (response) {
					console.log(
						'🚀 ~ file: updateOne.js ~ line 36 ~ updateOne ~ response',
						response
					);
					let publishedSkillPack = response.data['skillpacks'][0];
					if (publishedSkillPack) {
						for (let brainId of item['brains']) {
							await updatePublishedSkillPackToBrain({
								SkillPackToInstall: publishedSkillPack,
								PublishedSkillPackToInstall: publishedSkillPack,
								version: item.version,
								brainId: brainId,
								profileSlug: profileSlug,
							});
						}
					}
				}
			} else {
				// module update
			}
			return {
				error: false,
				message: 'Update successful!',
			};
		} else {
			// TODO
		}
	} catch (e) {
		console.log(e);
		return {
			error: true,
			message: 'Error in update',
		};
	}
};

export default updateOne;
