import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import _ from 'lodash';
//Components
import { SkillPackItem } from './SkillPackItem';
import { ItemCardSkeleton } from '../Reusable/HorizontalBoxCardWithDescription';
import { Box, Wrap, SimpleGrid } from '@chakra-ui/react';
//Redux
import { connect } from 'react-redux';
import { updateBrainById } from '../../redux/actions';
import { SkillPackItemCard } from './SkillPackItemCard';

const SkillPackList = ({
	query,
	height,
	packs,
	onClickFn,
	selectedPackId,
	isLoading,
	wholeItemActive,
	currentBrainId,
	skillsButton,
	addButton,
	deleteButton,
	onClick,
	brains,
	setPacks,
	updateBrainById,
}) => {
	const loadingList = [1, 2, 3];

	const checkIsInstalled = (currentBrainId, packId) => {
		let check;
		if (currentBrainId && brains.byId[currentBrainId]) {
			check =
				_.findIndex(brains.byId[currentBrainId].skillPacks, (pack) => {
					return pack.skillPack === packId;
				}) !== -1;
		} else {
			check = false;
		}
		return check;
	};

	return (
		<Box
			// bg='blue.300'
			position='relative'
			width='100%'
			display='flex'
			flex='1 1 auto'
			overflow='auto'
		>
			<Box
				// bg='red'
				flex='1 1 auto'
				borderRadius='10px'
				position='absolute'
				width='100%'
			>
				{!isLoading && packs.length === 0 ? (
					<Box
						textAlign="center"
						height="100%"
						width="100%"
						display="flex"
						flexDirection="column"
						justifyContent="center"
					>
						<Box>Library is empty</Box>
					</Box>
				) : null}
				<SimpleGrid
					overflowY="auto"
					position="relative"
					spacing='24px'
					minChildWidth='440px'
				>
					{!isLoading
						? packs.length > 0 &&
						packs.reverse().map((pack) => {
								return (
									<SkillPackItemCard
										key={pack._id}
										pack={pack}
										selected={false}
										onClick={(pack) => onClick(pack)}
										wholeItemActive={wholeItemActive}
										isItemExpanded={false}
										currentBrainId={currentBrainId}
										skillsButton={skillsButton}
										addButton={addButton}
										deleteButton={deleteButton}
										isInstalled={checkIsInstalled(
											currentBrainId,
											pack._id
										)}
										setPacks={setPacks}
										packs={packs}
										updateBrainById={updateBrainById}
									/>
								);
						})
						: loadingList.map((item) => {
								return <ItemCardSkeleton key={item} scale={0.75} />;
						})}
				</SimpleGrid>
			</Box>
		</Box>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */

const mapStateToProps = (state) => {
	let { brains } = state;
	let { query } = state.navigation;
	const brainIds = brains.allIds;
	return { brainIds, brains, query };
};

export default connect(mapStateToProps, { updateBrainById })(SkillPackList);
