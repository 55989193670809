import Fuse from 'fuse.js';
import { formatBrainItems } from './brain';
import { formatPublishedSkillPackItems } from './publishedSkillPacks';
import { formatModuleItems } from './module';

const filterItems = (formattedList, query, keys) => {
	const options = {
		includeScore: false,
		includeMatches: true,
		threshold: 0.3,
		keys: keys,
	};
	const fuse = new Fuse(formattedList, options);
	return fuse.search(query);
	// console.log(result, list, query);
};

const selectItems = (list, filteredList) => {
	console.log(list, filteredList);
	var arr = { byId: {}, allIds: [] };
	filteredList.map((item) => {
		arr.byId[item.item._id] = list.byId[item.item._id];
		arr.allIds.push(item.item._id);
	});
	console.log(arr);
	return arr;
};

const querySearch = (list, query, type) => {
	if (!query || query === '') {
		return list;
	}
	let keys = [];
	let formattedList = list;
	switch (type) {
		case 'brains':
			keys = ['title', 'commands.value'];
			formattedList = formatBrainItems(list);
			break;
		case 'publishedSkillPacks':
			keys = ['name', 'commands.value'];
			formattedList = formatPublishedSkillPackItems(list);
			break;
		case 'modules':
			keys = ['name', 'packageName'];
			formattedList = formatModuleItems(list);
			break;
		default:
			return list;
	}
	const filteredList = filterItems(formattedList, query, keys);
	return selectItems(list, filteredList);
};

export default querySearch;
