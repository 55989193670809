import { Box, Circle } from '@chakra-ui/react';
import React from 'react';

/**
 *
 * @param {{
 *  stages: string[]
 *  currentStage: string,
 *  label: string,
 * } & import("@chakra-ui/react").BoxProps
 * } param0
 * @returns
 */
const Stepper = ({ stages, currentStage, label, ...rest }) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="start"
			justifyContent="space-between"
			minWidth="48"
			{...rest}
		>
			<Box display="flex" alignItems="center">
				{stages.map((item, idx) => {
					const currStageIdx = stages.indexOf(currentStage);
					if (Math.abs(idx - currStageIdx) === 0)
						return (
							<Circle
								key={`stepper-item-${item}`}
								background="#949494"
								size="10px"
								mr="3"
							/>
						);
					else
						return (
							<Circle
								key={`stepper-item-${item}`}
								background="#514F4F"
								size="10px"
								mr="3"
							/>
						);
				})}
			</Box>
			{label ? <Box mb="-1">{label}</Box> : null}
		</Box>
	);
};

export default Stepper;
