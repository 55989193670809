import React, { useEffect, useRef } from 'react';
import {
	Input,
	useColorMode,
	InputGroup,
	InputAddon,
	Box,
	useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import isElectron from 'is-electron';
import Query from './Query';
import toggleCommandBar from '../../../../../../functions/commandBar/toggleCommandBar';
import navigateToMainWindowRoute from '../../../../../../functions/commandBar/navigateToMainWindowRoute';
import { useDispatch, useSelector } from 'react-redux';
// import // toggleCardsVisibility,
// // newCard,
// // setCardsExpandable,
// '../../../../../../redux/actions';
import KeyEnum from '../../../../../../util/KeyEnum';
import analytics from '../../../../../../util/Analytics';
import { commandHistoryLogIntentEvent } from '../../../../../../redux/actions/commandHistory';
import Addon from './Addon';
import {
	commandBarStatusUpdate,
	searchAddonClose,
	searchChange,
	searchInputKeydown,
	searchSubmit,
	startupIntentsInit,
	startupIntentsLoad,
} from '../../../../../../redux/actions/cardUIV2';
import useFuzzySort from './useFuzzySort';
import { resultsTabs } from '../../SuggestionsArea/TabBar';
import EmbViewPlaceholder from './EmbViewPlaceholder';
import { algoliaIntentsIndex } from '../../../../../../util/AlgoliaSearch';
import useDebounce from '../../../../../../util/useDebounce';
import StoreSubmit from './StoreSubmit';
import useSmartSuggest from './useSmartSuggest';

const BOT_COMMAND_TIMEOUT = 60 * 1000; // We want bot-command requests to timeout in 60 secondss.

/**
 *
 * @param {import('../../../../../../redux/reducers/cardUIV2').CuiSearchType} currentTab
 * @returns
 */
const getPlaceholderText = (currentTab) => {
	let tabTypeLabel = '';
	const currentTabLabel = resultsTabs
		.find((tab) => tab.type === currentTab)
		?.label.toLowerCase();
	if (currentTabLabel.endsWith('s')) {
		const singularLabel = currentTabLabel.substring(
			0,
			currentTabLabel.length - 1
		);
		tabTypeLabel = singularLabel || 'command';
	} else {
		tabTypeLabel = currentTabLabel;
	}
	return `Start typing for ${tabTypeLabel} search...`;
};

/**
 *
 * @param {{
 * myForwardedRef: React.MutableRefObject<HTMLInputElement>,
 * onCancel: () => void ,
 * inputValue: string,
 * setInputValue: (newVal: string) => void,
 * }} param0
 * @returns
 */
const Search = ({ myForwardedRef, onCancel, inputValue, setInputValue }) => {
	/** inputValue debounced at 150ms */
	const debouncedInputValue = useDebounce(inputValue, 150);
	const searchAreaMode = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.searchArea.mode
	);
	// const { intents } = useSelector(
	// 	/**
	// 	 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
	// 	 */
	// 	(state) => state.cardUIV2.entities
	// );
	const loadingStatus = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.searchArea.loadingStatus
	);
	const selectedIntentItemObj = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => {
			const selectedSearchItemId =
				state.cardUIV2.commandBar.suggestionsArea.selectedSearchItem;
			return state.cardUIV2.entities.intents.byId[selectedSearchItemId];
		}
	);
	const currentTab = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea.type
	);
	const dispatch = useDispatch();
	/**
	 * `taskIdRef` is used to identify if the response of onSubmit is for the
	 * current task or some old stale/zombie task
	 */
	const taskIdRef = useRef(null);
	const { getFuzzySortedIntents, getFuzzySortedCards } = useFuzzySort();
	const { getSmartSuggestIntents } = useSmartSuggest();

	const toast = useToast();
	const { colorMode } = useColorMode();
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	const handleOnKeyUp = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.UP_ARROW || keyCode === KeyEnum.DOWN_ARROW) {
			e.preventDefault();
		}
	};

	/**
	 *	Search onKeyDown handler
	 * @param {KeyboardEvent} e
	 */
	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.UP_ARROW) {
			e.preventDefault();
			dispatch(searchInputKeydown({ keyType: 'UP' }));
		} else if (keyCode === KeyEnum.DOWN_ARROW) {
			e.preventDefault();
			dispatch(searchInputKeydown({ keyType: 'DOWN' }));
		} else if (keyCode === KeyEnum.TAB) {
			if (e.ctrlKey || e.metaKey) {
				e.preventDefault();
				const tabs = resultsTabs.map((tab) => tab.type);
				// Cycle through tabs
				const tabName =
					tabs.indexOf(currentTab) === tabs.length - 1
						? tabs[0]
						: tabs[tabs.indexOf(currentTab) + 1];
				dispatch(
					searchInputKeydown({
						keyType: 'TAB_CYCLE',
						tabName,
					})
				);
			}
		} else if (keyCode === KeyEnum.ENTER) {
			e.preventDefault();
			dispatch(searchInputKeydown({ keyType: 'ENTER' }));
		} else if (keyCode === KeyEnum.ESC) {
			if (e.shiftKey) {
				dispatch(searchInputKeydown({ keyType: 'SHIFT+ESCAPE' }));
			} else {
				if (inputValue !== '' || loadingStatus !== 'normal') {
					setInputValue('');
				} else if (loadingStatus === 'normal') {
					setInputValue('');
					toggleCommandBar({ method: 'Esc Key' });
				}
				dispatch(searchInputKeydown({ keyType: 'ESCAPE' }));
			}
		} else if (keyCode === KeyEnum.BACKSPACE) {
			if (e.shiftKey) {
				dispatch(searchInputKeydown({ keyType: 'SHIFT+BACKSPACE' }));
			}
		}
	};

	/**
	 *	The main command Submit handler
	 * @param {{
	 * 	[key: string]: { value: string, meta: any }
	 * }} formData
	 */
	const onSubmit = (formData) => {
		/**
		 * taskId is generated here temporarily,
		 * to happen later in orchestrator/central backend
		 */
		const taskId = uuidv4();
		formData['taskId'] = taskId; // add taskId to formData
		taskIdRef.current = taskId; // set current taskId to ref
		dispatch(
			searchSubmit({
				status: 'start',
				metadata: {
					searchQuery: inputValue,
					taskId,
				},
			})
		);
		dispatch(
			commandBarStatusUpdate({
				visible: true,
				status: 'loading',
				message: 'Loading...',
			})
		);
		if (selectedIntentItemObj.type === 'http') {
			/** For intents triggered via http call */
			axios
				.post(
					selectedIntentItemObj.baseUrl + selectedIntentItemObj.endpoint,
					formData,
					{
						headers: {
							'Content-Type': 'application/json',
						},
						timeout: BOT_COMMAND_TIMEOUT,
					}
				)
				.then(function (response) {
					if (taskId !== taskIdRef.current) return; // ignore the response if from old/stale/zombie task
					/**
					 * @type {import('./BotResponse').BotResponse}
					 */
					const responseData = response.data;
					if (responseData.type) {
						if (responseData.type === 'card') {
							dispatch(
								commandBarStatusUpdate({
									visible: true,
									status: 'success',
									message: 'Success!',
								})
							);
							responseData.payload.robotId =
								selectedIntentItemObj.brainId;
							setTimeout(() => {
								dispatch(commandBarStatusUpdate({ visible: false }));
							}, 1000);
							dispatch(
								searchSubmit({
									status: 'success',
									type: 'card',
									cardPayload: {
										...responseData.payload,
										// Adding brainId additionally as not included in response
										brainId: selectedIntentItemObj.brainId,
									},
									metadata: {
										searchQuery: inputValue,
										card: true,
										taskId: responseData._taskid || taskId,
									},
								})
							);
							// myForwardedRef.current.blur();
						} else if (responseData.type === 'status') {
							const status = responseData.payload.status;
							const statusMsg = responseData.payload.message;
							if (status && status === 'success') {
								dispatch(
									commandBarStatusUpdate({
										visible: true,
										status: 'success',
										message: statusMsg || 'Success!',
									})
								);
								dispatch(
									searchSubmit({
										status: 'success',
										type: 'normal',
										metadata: {
											searchQuery: inputValue,
											card: false,
											taskId: responseData._taskid || taskId,
										},
									})
								);
								setTimeout(() => {
									dispatch(commandBarStatusUpdate({ visible: false }));
								}, 300);
								setTimeout(() => {
									toggleCommandBar({ method: 'on command submit' });
								}, 500);
							} else if (status && status === 'error') {
								dispatch(
									commandBarStatusUpdate({
										visible: true,
										status: 'error',
										message:
											statusMsg === 'Success!'
												? 'Error occurred'
												: statusMsg || 'Error occurred',
									})
								);
								setTimeout(() => {
									dispatch(commandBarStatusUpdate({ visible: false }));
								}, 1000);
								setTimeout(() => {
									dispatch(
										searchSubmit({
											status: 'error',
											metadata: {
												searchQuery: inputValue,
												card: false,
												taskId: responseData._taskid || taskId,
											},
										})
									);
								}, 1500);
							} else if (status && status === 'loading') {
								dispatch(
									commandBarStatusUpdate({
										visible: true,
										status: 'loading',
										message: statusMsg || 'Loading...',
									})
								);
							}
						}
					} else {
						dispatch(
							searchSubmit({
								status: 'success',
								metadata: {
									searchQuery: inputValue,
									card: false,
									taskId:
										responseData._taskid ||
										responseData.taskId ||
										taskId,
								},
							})
						);
						setTimeout(() => {
							dispatch(commandBarStatusUpdate({ visible: false }));
						}, 150);
						setTimeout(() => {
							toggleCommandBar({ method: 'on command submit' });
						}, 300);
						myForwardedRef.current.select();
					}
				})
				.catch((err) => {
					if (taskId !== taskIdRef.current) return; // ignore the response if from old/stale/zombie task
					console.log(err);
					dispatch(
						commandBarStatusUpdate({
							visible: true,
							status: 'error',
							message: 'Got an error response.',
						})
					);
					dispatch(
						searchSubmit({
							status: 'error',
							metadata: {
								searchQuery: inputValue,
								card: false,
								taskId: taskId,
							},
						})
					);
				});
		} else if (selectedIntentItemObj.type === 'native') {
			/** For native intents (configured via pseudo brain) */
			console.log(
				'Native Intent ~ onSubmit ~ selectedIntentItemObj',
				selectedIntentItemObj
			);
			// TODO: write native intent trigger logic here...
			navigateToMainWindowRoute({ route: selectedIntentItemObj.endpoint });
			// Focus the main electron window before executing the success/failure dispatch

			setTimeout(() => {
				if (taskId !== taskIdRef.current) return; // ignore the response if from old/stale/zombie task
				dispatch(
					commandBarStatusUpdate({
						visible: true,
						status: 'success',
						message: 'Success!',
					})
				);
				dispatch(
					searchSubmit({
						status: 'success',
						type: 'normal',
						metadata: {
							searchQuery: inputValue,
							card: false,
							taskId: taskId,
						},
					})
				);
				setTimeout(() => {
					dispatch(commandBarStatusUpdate({ visible: false }));
				}, 150);
				setTimeout(async () => {
					await toggleCommandBar({ method: 'native_command' });
				}, 300);
			}, 1000);
		}
		setInputValue('');
	};

	/**
	 * Intents/Commands search value change handler
	 * @param {string} searchValue
	 */
	const handleIntentsSearchChange = (searchValue) => {
		let suggestItems = { byId: {}, allIds: [] };
		suggestItems = getFuzzySortedIntents(searchValue);

		// if (searchValue !== '') {
		// 	suggestItems = getFuzzySortedIntents(searchValue);
		// } else {
		// 	suggestItems = getSmartSuggestIntents();
		// 	console.log('passing this', suggestItems);
		// }
		dispatch(
			searchChange({
				inputValue: searchValue,
				suggestions: suggestItems,
			})
		);
	};

	/**
	 * Cards search value change handler
	 * @param {string} searchValue
	 */
	const handleCardsSearchChange = (searchValue) => {
		let suggestItems = { byId: {}, allIds: [] };
		suggestItems = getFuzzySortedCards(searchValue);
		dispatch(
			searchChange({
				inputValue: searchValue,
				suggestions: suggestItems,
			})
		);
	};

	/**
	 * Store search value change handler
	 * Uses Algolia based async API(via sdk)
	 * @param {string} searchValue
	 */
	const handleStoreSearch = async (searchValue) => {
		const res = await algoliaIntentsIndex.search(searchValue);
		const sortedSkills = res.hits;
		const suggestItems = sortedSkills.reduce(
			(prev, curr) => {
				const id = curr.endpoint;
				prev.byId[id] = {
					id, // set id
					entity: 'storeSkill', // set entity
					...curr,
				};
				prev.allIds.push(id);
				return prev;
			},
			{
				byId: {},
				allIds: [],
			}
		);
		dispatch(
			searchChange({
				inputValue: searchValue,
				suggestions: suggestItems,
			})
		);
	};

	useEffect(() => {
		/** For handling realtime search based on changes in input/tab */
		if (currentTab === 'intents') handleIntentsSearchChange(inputValue);
		else if (currentTab === 'cards') handleCardsSearchChange(inputValue);
	}, [currentTab, inputValue]);

	useEffect(() => {
		/** For handling debounced search based on changes in input/tab */
		if (currentTab === 'store') handleStoreSearch(debouncedInputValue);
	}, [currentTab, debouncedInputValue]);

	useEffect(() => {
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer } = electron;

			ipcRenderer.removeAllListeners('/setCmdBarValue');
			ipcRenderer.on('/setCmdBarValue', (event, arg) => {
				let { searchQuery } = arg;
				setInputValue(searchQuery);
			});
		}
	});

	useEffect(() => {
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer } = electron;
			ipcRenderer.removeAllListeners('/searchbar/focus');
			ipcRenderer.on('/searchbar/focus', (event, arg) => {
				if (myForwardedRef.current) {
					myForwardedRef.current.focus();
				}
			});
		}
	});

	/**
	 * Populate with smart suggested intents on first toggle
	 */
	useEffect(() => {
		const startupIntents = getSmartSuggestIntents();
		dispatch(
			startupIntentsInit({
				intents: startupIntents,
			})
		);
		if (currentTab === 'intents') dispatch(startupIntentsLoad());
	}, [currentTab, dispatch, getSmartSuggestIntents]);

	return (
		<InputGroup>
			<InputAddon bg="none" border="none" paddingRight="0.8rem">
				<Addon
					onMayaIconClick={() => setInputValue('')}
					status={loadingStatus}
					onClose={() => {
						// onCancel();
						setInputValue('');
						dispatch(searchAddonClose());
					}}
				/>
			</InputAddon>
			{searchAreaMode === 'search' ? (
				<Input
					paddingY="0"
					bg="transparent"
					border="none"
					type="phone"
					ref={myForwardedRef}
					_placeholder={{
						color: color[colorMode],
						opacity: '0.8',
						fontSize: '1.3rem',
					}}
					_focus={{ outline: 'none' }}
					paddingRight="0.5rem"
					paddingLeft="0.5rem"
					width="20rem"
					placeholder={inputValue || getPlaceholderText(currentTab)}
					focusBorderColor="gray.200"
					color={color[colorMode]}
					onChange={(e) => {
						setInputValue(e.currentTarget.value);
					}}
					value={inputValue}
					fontFamily="body"
					fontWeight="400"
					fontSize="1.3rem"
					onKeyUp={handleOnKeyUp}
					onKeyDown={handleOnKeyDown}
				/>
			) : null}
			{searchAreaMode === 'query' && selectedIntentItemObj ? (
				<Box paddingLeft="0.5rem">
					<Query
						placeholderComponentIds={selectedIntentItemObj.components}
						onCancel={onCancel}
						onSubmit={(formData) => setTimeout(onSubmit(formData), 200)}
					/>
				</Box>
			) : null}
			{searchAreaMode === 'embeddedView' ? (
				<EmbViewPlaceholder
					handleOnKeyDown={handleOnKeyDown}
					handleOnKeyUp={handleOnKeyUp}
				/>
			) : null}
			{searchAreaMode === 'storeSubmit' ? (
				<StoreSubmit
					inputValue={debouncedInputValue}
					setInputValue={setInputValue}
				/>
			) : null}
		</InputGroup>
	);
};

export default Search;
