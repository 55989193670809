// Dependency imports

import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
/**
 * Function to Create a New Brain, given some parameters
 */

const updateBrain = async (formData) => {
	var updateBrainAPIFn, updateBrainIPCFn, updateBrainResponse;
	if (!isElectron()) {
		formData['local'] = false; //flag to check if brain PV is to be created on cloud
		updateBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.updateBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.updateBrain.PATH_METHOD,
		});
		updateBrainResponse = await updateBrainAPIFn(formData);
		if (updateBrainResponse['data']['error']) {
			return updateBrainResponse['data'];
		} else {
			return updateBrainResponse['data']['results'];
		}
	} else {
		formData['local'] = true; //flag to check if brain PV is to be created on cloud
		updateBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.updateBrain.EVENT,
		});
		console.log('Updating brain with intents', formData['intents']);
		updateBrainResponse = await updateBrainIPCFn(formData);
		if (!updateBrainResponse['data']['error']) {
			console.log(
				'Updated redux with intents',
				JSON.stringify(updateBrainResponse['data']['results']['intents'])
			);
			return updateBrainResponse['data'];
		} else {
			return updateBrainResponse['data'];
		}
	}
};

export default updateBrain;
