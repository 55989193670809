import React from 'react';
import { Box } from '@chakra-ui/react';

//Components
import DeveloperAccounts from './DeveloperSection';
import PublishedSkillPacks from './PublishedSkillPacks';

const DeveloperSection = () => {
	const [devAccountId, setDevAccountId] = React.useState('');

	return (
		<Box>
			<DeveloperAccounts
				setDevAccountId={setDevAccountId}
				devAccountId={devAccountId}
			/>
			<PublishedSkillPacks devAccountId={devAccountId} />
		</Box>
	);
};

export default DeveloperSection;
