import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';

/**
 *
 * @param {{
 * 	selected: number,
 * 	onSelect: (newValue: number) => void,
 * 	selectionRange: {
 * 		from: number,
 * 		to: number,
 * 	}
 * 	hasPaddedZeroes?: boolean,
 *  	menuProps?: import('@chakra-ui/react').MenuProps,
 *		menuButtonProps?: import('@chakra-ui/react').MenuButtonProps & import('@chakra-ui/react').ButtonProps,
 *		menuListProps?: import('@chakra-ui/react').MenuListProps,
 *		menuItemProps?: import('@chakra-ui/react').MenuItemProps,
 * }} param0
 * @returns
 */
const NumericPeriodValuesSelector = ({
	selected,
	onSelect,
	selectionRange,
	hasPaddedZeroes = false,
	menuProps,
	menuButtonProps,
	menuListProps,
	menuItemProps,
}) => {
	return (
		<Menu gutter={0} matchWidth placement="bottom" {...menuProps}>
			{({ isOpen }) => (
				<>
					<MenuButton
						as={Button}
						rightIcon={<ChevronDownIcon />}
						size="sm"
						borderBottomRadius={isOpen ? 0 : undefined}
						{...menuButtonProps}
					>
						{hasPaddedZeroes
							? selected.toString().padStart(2, '0')
							: selected}
					</MenuButton>
					<MenuList
						fontSize="sm"
						borderTopRadius={0}
						minWidth="inherit"
						bg="#3b3b3b"
						maxHeight="20rem"
						overflow="auto"
						{...menuListProps}
					>
						{range(selectionRange.from, selectionRange.to).map(
							(periodValue) => (
								<MenuItem
									key={periodValue}
									onClick={() => onSelect(periodValue)}
									{...menuItemProps}
								>
									{hasPaddedZeroes
										? periodValue.toString().padStart(2, '0')
										: periodValue}
								</MenuItem>
							)
						)}
					</MenuList>
				</>
			)}
		</Menu>
	);
};

export default React.memo(NumericPeriodValuesSelector);
