import React from 'react';
import { Box, Image, Progress } from '@chakra-ui/react';

//assets
import logo from '../../assets/icon.png';

const GeneralLoader = ({ text }) => {
	return (
		<Box
			justifyContent="center"
			display="flex"
			flexDirection="column"
			alignItems="center"
		>
			<Image src={logo} boxSize="8rem" />
			<Box mt="5" mb="5" fontWeight="500">
				{text}
			</Box>
			<Progress
				size="xs"
				width="7rem"
				isIndeterminate
				colorScheme="purple"
			/>
		</Box>
	);
};

export default GeneralLoader;
