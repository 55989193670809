import { Box } from '@chakra-ui/react';
import ReactPlayer from 'react-player';
import CommandsList from '../Commands/CommandList';
const PostInstallScreen = ({ publishedSkillPack, isInstalled }) => {
	const borderColor = {
		light: 'gray.100',
		dark: 'dashboard.border_dark',
	};
	const fontColor = {
		light: '#444444',
		dark: 'white',
	};
	console.log('INTENTS:', publishedSkillPack?.currentFlow);
	return (
		<Box>
			<Box paddingX="1rem" paddingY="0.5rem">
				<Box
					fontSize="1.2rem"
					fontWeight="600"
					fontFamily="body"
					color={fontColor['dark']}
				>
					Previews
				</Box>
				<Box
					fontSize="1rem"
					// mt="2"
					// mb="2"
					fontFamily="body"
					color="#c3c3c3"
					// paddingBottom="1rem"
				>
					{publishedSkillPack.postInstallInstruction
						? publishedSkillPack.postInstallInstruction
						: publishedSkillPack.longDescription}
				</Box>
			</Box>

			<Box
				display="flex"
				flexDir="row"
				justifyContent="space-around"
				paddingY="1rem"
				paddingX="1rem"
				alignItems="center"
			>
				<Box mt="1" mb="2" width="100%" alignItems="center">
					<ReactPlayer
						url={
							publishedSkillPack.previews[0]
								? publishedSkillPack.previews[0].url
								: ''
						}
						muted={true}
						playing={true}
						controls={false}
						loop={true}
						volume={1}
						light
						mb={2}
						width="100%"
						height="22.8rem"
						style={{
							boxShadow:
								'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px',
						}}
						config={{
							youtube: {
								playerVars: {
									controls: 1,
									autoplay: 1,
									loop: 1,
								},
							},
						}}
					/>
				</Box>
				<Box
					borderLeft="0.2px solid"
					borderColor={borderColor['dark']}
					marginTop="5"
					marginLeft="2.5"
					marginRight="2.5"
					height="21rem"
				/>
				{publishedSkillPack?.currentFlow?.intents ? (
					<Box
						mt="1"
						mb="2"
						height="22.8rem"
						justifyContent="start"
						width="400px"
					>
						<Box
							fontSize="1.2rem"
							fontWeight="600"
							fontFamily="body"
							mb="2"
							color={fontColor['dark']}
						>
							{`Commands (${publishedSkillPack?.currentFlow?.intents?.length})`}
						</Box>

						<CommandsList
							commands={publishedSkillPack?.currentFlow?.intents}
							height="19rem"
							clickable={isInstalled}
						/>
					</Box>
				) : null}
			</Box>
		</Box>
	);
};

export default PostInstallScreen;
