import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import navigateToMainWindowRoute from '../../../../../../functions/commandBar/navigateToMainWindowRoute';
import {
	commandBarStatusUpdate,
	storeSearchSubmit,
} from '../../../../../../redux/actions/cardUIV2';
import toggleCommandBar from '../../../../../../functions/commandBar/toggleCommandBar';

/**
 * activated on store(tab) Submit, used to execute submit handler for store items
 * @param {{
 * inputValue: string,
 * setInputValue: (value: string) => void
 * }} param0
 * @returns
 */
const StoreSubmit = ({ inputValue, setInputValue }) => {
	const dispatch = useDispatch();
	const selectedStoreItemObj = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => {
			const storeSkillId =
				state.cardUIV2.commandBar.suggestionsArea.selectedSearchItem;
			return state.cardUIV2.entities.storeSkills.byId[storeSkillId];
		}
	);

	const handleStoreSubmit = () => {
		try {
			if (!selectedStoreItemObj?.publishedSkillPack)
				throw new Error(`selectedStoreItemObj/publishedSkillPack invalid!`);
			dispatch(storeSearchSubmit({ status: 'start' }));
			dispatch(
				commandBarStatusUpdate({
					visible: true,
					status: 'loading',
					message: 'Loading...',
				})
			);
			navigateToMainWindowRoute({
				route: `/store/skills?id=${selectedStoreItemObj.publishedSkillPack}`,
			});
			setTimeout(() => {
				dispatch(
					commandBarStatusUpdate({
						visible: true,
						status: 'success',
						message: 'Success!',
					})
				);
				dispatch(
					storeSearchSubmit({
						status: 'success',
					})
				);
				setTimeout(() => {
					dispatch(commandBarStatusUpdate({ visible: false }));
				}, 150);
				setTimeout(() => {
					toggleCommandBar({ method: 'native_command' });
				}, 300);
			}, 1000);
		} catch (error) {
			console.error(error);
			dispatch(
				storeSearchSubmit({
					status: 'error',
				})
			);
		} finally {
			setInputValue('');
		}
	};

	useEffect(() => {
		handleStoreSubmit();
	}, []);

	return null;
};

export default StoreSubmit;
