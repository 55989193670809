import React from 'react';

import {
	Button,
	useColorModeValue,
	useToast,
	useDisclosure,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogHeader,
	LightMode,
	AlertDialogFooter,
} from '@chakra-ui/react';

import isElectron from 'is-electron';
import { FiPlusCircle, FiGitBranch, FiMinusCircle } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

//Components
import { HorizontalItemButton } from '../../Reusable/HorizontalItem';
import getSkillPack from '../../../functions/skillPack/getSkillPack';
import { StorePackModal } from './StorePackModal';
//Functions
import uninstallPublishedSkillPackFromBrain from '../../../functions/publishedSkillPack/uninstallPublishedSkillPackFromBrain';

//Utils
import GetProfileSlug from '../../../util/ProfileSlug';

//Analytics
import analytics from '../../../util/Analytics';

export const SkillsButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiGitBranch />}
			buttonKey="skills"
			text={'skills'}
			onClick={() => {
				onClick();
				analytics.track('Store Collection Item : Skills Button', {});
			}}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};

export const UninstallButton = ({
	setHoveredButton,
	hoveredButton,
	publishedSkillPack,
	currentBrainId,
	updateBrainById,
}) => {
	const [isUninstalling, setIsUninstalling] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();
	const handleUninstall = () => {
		setIsUninstalling(true);
		getSkillPack({ _id: publishedSkillPack.skillPack })
			.then((pack) => {
				var formData = {
					SkillPackToInstall: pack,
					PublishedSkillPackToInstall: publishedSkillPack,
					version: publishedSkillPack
						? publishedSkillPack.currentVersion
						: pack.versions[0].version,
					//version: pack.currentVersionDetails.version,
					brainId: currentBrainId,
					profileSlug: slug,
				};
				uninstallPublishedSkillPackFromBrain(formData)
					.then((res) => {
						if (res['error']) {
							setIsUninstalling(false);
							toast({
								title: 'Error!',
								description: 'This collection could not be uninstalled',
								status: 'error',
								duration: 3000,
								isClosable: true,
							});
							analytics.track(
								'[Store] Collection Item : Uninstall Modal',
								{
									_id: publishedSkillPack._id,
									name: publishedSkillPack.name,
									error: res['error'],
									status: 'failure',
								}
							);
						} else {
							setIsUninstalling(false);
							toast({
								title: 'Success!',
								description:
									'This collection was successfully uninstalled',
								status: 'success',
								duration: 3000,
								isClosable: true,
							});
						}
						onClose();
						updateBrainById(formData.brainId, res.brain);
						analytics.track('[Store] Collection Item : Uninstall Modal', {
							_id: publishedSkillPack._id,
							name: publishedSkillPack.name,
							error: !res['error'],
							status: 'success',
						});
					})
					.catch((err) => {
						setIsUninstalling(false);
						toast({
							title: 'Error!',
							description: 'This collection could not be uninstalled.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
						analytics.track('[Store] Collection Item : Uninstall Modal', {
							_id: publishedSkillPack._id,
							name: publishedSkillPack.name,
							error: true,
							status: 'failure',
						});
					});
			})
			.catch((err) => {
				setIsUninstalling(false);
				toast({
					title: 'Error!',
					description: 'This collection could not be uninstalled.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<HorizontalItemButton
				icon={<FiMinusCircle />}
				buttonKey="uninstall"
				text={'uninstall'}
				onClick={() => {
					setIsOpen(true);
					// analytics.track("[Store] Collection Item : Uninstall Button", {
					//   _id: publishedSkillPack._id,
					//   name: publishedSkillPack.name,
					// });
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Uninstall Collection
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={handleUninstall}
									ml={3}
									isLoading={isUninstalling}
									loadingText={'Uninstalling...'}
								>
									Delete
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

/**
 * Install Button
 * @param {} param0
 */

export const InstallButton = ({
	setHoveredButton,
	hoveredButton,
	onClick,
	publishedSkillPack,
	currentBrainId,
	updateBrainById,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [isLoading, setLoading] = React.useState(false);
	const [pack, setPack] = React.useState({});

	React.useEffect(() => {
		setLoading(true);
		if (isOpen) {
			getSkillPack({ _id: publishedSkillPack.skillPack })
				.then((res) => {
					console.log('~~~~', res);
					setPack(res);
					setLoading(false);
				})
				.catch((err) => console.log(err));
		}
	}, [publishedSkillPack, isOpen]);
	const loc = useLocation();
	let query = new URLSearchParams(loc.search);
	let id = query.get('id');
	React.useEffect(() => {
		if (id === publishedSkillPack._id) {
			onOpen();
		}
	}, [id]);
	return (
		<>
			<HorizontalItemButton
				icon={<FiPlusCircle />}
				buttonKey="install"
				text={'install'}
				onClick={() => {
					//onClick();'
					// analytics.track("Store Collection Item : Install Button", {
					//   _id: publishedSkillPack._id,
					//   name: publishedSkillPack.name,
					// });
					onOpen();
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<StorePackModal
				isOpen={isOpen}
				onClose={onClose}
				publishedSkillPack={publishedSkillPack}
				pack={pack}
				currentBrainId={currentBrainId}
				isLoading={isLoading}
			/>
		</>
	);
};
