import { config } from '../../../../config';
import APIFetchFn from '../../../../util/APIFetch/function';
import loginAPI from '../LoginAPI';

const fusionAuthIdentityProviderPost = APIFetchFn({
	BASEURL: config.fusionAuth.BASEURL,
	PATH_SEARCH: loginAPI.fusionAuth.identityProviderLogin.PATH_SEARCH,
	PATH_METHOD: loginAPI.fusionAuth.identityProviderLogin.PATH_METHOD,
});

/**
 * This is called after Google(GAPI) sign in flow.
 * Its Used to link Google Login to Fusion Auth.
 *
 * Params:
 * - `idToken`: `id_token` returned from `authResponse` from `GoogleUser`
 *
 * @param {{
 * 	idToken: string
 * }} param0
 */
export const fusionAuthGoogleLogin = async ({ idToken }) => {
	const res = await fusionAuthIdentityProviderPost({
		applicationId: config.fusionAuth.APPLICATION_ID,
		identityProviderId: config.fusionAuth.IDENTITY_PROVIDER_ID,
		data: {
			token: idToken,
		},
	});
	return res;
};

const registerFusionAuthRegisteredUser = APIFetchFn({
	BASEURL: config.apiServer.BASEURL,
	PATH_SEARCH:
		loginAPI.apiService.registerFusionAuthRegisteredUser.PATH_SEARCH,
	PATH_METHOD:
		loginAPI.apiService.registerFusionAuthRegisteredUser.PATH_METHOD,
});

/**
 * The user object received from fusion auth api needs to be
 * sent to this to register the user on maya backend
 *
 * @param {{
 * 	user: any
 * 	firstName: string,
 * }} param0
 * @returns
 */
export const registerGoogleLoginUser = async ({ user, firstName }) => {
	const newUser = {
		...user,
		firstName,
	};
	return registerFusionAuthRegisteredUser({
		user: newUser,
	});
};
