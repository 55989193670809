import isElectron from 'is-electron';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
import GetProfileSlug from '../../util/ProfileSlug';
/**
 * Function to get all configuration profiles of a certain module for user
 */
const getConfigProfiles = async ({ slug, moduleId, brainId }) => {
	const { user, devices } = store.getState();
	const profileSlug = slug ? slug : GetProfileSlug().slug;
	var getConfigProfilesResponse,
		getConfigProfilesAPIFn,
		filterConfigProfilesIPCFn,
		filterConfigProfilesResponse;

	if (!isElectron()) {
		if (window.navigator.onLine) {
			getConfigProfilesAPIFn = APIFunction({
				BASEURL: config.apiServer.BASEURL,
				PATH_SEARCH: APIRoutes.apiService.getConfigProfiles.PATH_SEARCH,
				PATH_METHOD: APIRoutes.apiService.getConfigProfiles.PATH_METHOD,
				PATH_QUERY: profileSlug,
				URL_PARAM: {
					moduleId: moduleId,
					deviceId: devices.current.device._id,
				},
			});

			getConfigProfilesResponse = await getConfigProfilesAPIFn();
			if (!getConfigProfilesResponse.data.error) {
				return getConfigProfilesResponse['data']['results'];
			}
		}
	} else {
		if (window.navigator.onLine) {
			getConfigProfilesAPIFn = APIFunction({
				BASEURL: config.apiServer.BASEURL,
				PATH_SEARCH: APIRoutes.apiService.getConfigProfiles.PATH_SEARCH,
				PATH_METHOD: APIRoutes.apiService.getConfigProfiles.PATH_METHOD,
				PATH_QUERY: profileSlug,
				URL_PARAM: {
					moduleId: moduleId,
				},
			});

			getConfigProfilesResponse = await getConfigProfilesAPIFn();
			// return getConfigProfilesResponse["data"]["results"];
			if (getConfigProfilesResponse.data.error) {
				return getConfigProfilesResponse['data']['error'];
			}

			filterConfigProfilesIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.filterConfigProfiles.EVENT,
			});
			filterConfigProfilesResponse = await filterConfigProfilesIPCFn({
				profileSlug,
				brainId,
				deviceId: devices.current.device._id,
				configProfileList: getConfigProfilesResponse['data']['results'],
			});
			console.log(filterConfigProfilesResponse);
			if (filterConfigProfilesResponse['data']['error']) {
				return getConfigProfilesResponse['data']['results'];
			} else {
				return filterConfigProfilesResponse['data']['results'];
			}
		}
	}
};

export default getConfigProfiles;
