import React from 'react';
import { Box, useColorModeValue, useColorMode } from '@chakra-ui/react';

//Components
import IconContainer from '../../../util/React_Icon';

/**
 *
 * @param {{
 * text: string,
 * icon: any,
 * rightItem: React.ReactNode,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const SettingsItem = ({ text, icon, rightItem, ...rest }) => {
	const { colorMode } = useColorMode();
	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};
	return (
		<Box
			height="60px"
			width="100%"
			display="flex"
			alignItems="center"
			borderBottom="solid 1px"
			justifyContent="space-between"
			borderColor={useColorModeValue('#f2f2f2', '#4a4949')}
			_hover={{ bg: useColorModeValue('', '#464646') }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			cursor="pointer"
			{...rest}
		>
			<Box display="flex" alignItems="center" paddingLeft="1rem">
				<IconContainer
					icon={icon}
					value={{
						color: color[colorMode],
						className: 'global-class-name',
						size: '0.7rem',
					}}
				/>
				<Box fontSize="16px" marginLeft="1rem">
					{text}
				</Box>
			</Box>
			<Box display="flex" alignItems="center">
				{rightItem}
			</Box>
		</Box>
	);
};

export default SettingsItem;
