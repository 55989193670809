import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import deploySkills from '../editor/deploy';

const installPublishedSkillPackToBrain = async (formData) => {
	console.log(formData);
	var installPublishedSkillPackToBrainAPIFn,
		installPublishedSkillPackToBrainIPCFn,
		installPublishedSkillPackToBrainResponse,
		getPublishedSkillPackByIdFn,
		getPublishedSkillPackByIdResponse;
	if (!isElectron()) {
		installPublishedSkillPackToBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH:
				APIRoutes.apiService.installPublishedSkillPackToBrain.PATH_SEARCH,
			PATH_METHOD:
				APIRoutes.apiService.installPublishedSkillPackToBrain.PATH_METHOD,
		});
		installPublishedSkillPackToBrainResponse =
			await installPublishedSkillPackToBrainAPIFn();
		if (installPublishedSkillPackToBrainResponse['data']['error']) {
			return installPublishedSkillPackToBrainResponse['data'];
		} else {
			return installPublishedSkillPackToBrainResponse['data']['results'];
		}
	} else {
		installPublishedSkillPackToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.installPublishedSkillPackToBrain.EVENT,
		});

		installPublishedSkillPackToBrainResponse =
			await installPublishedSkillPackToBrainIPCFn({
				...formData,
			});
		if (installPublishedSkillPackToBrainResponse['data']['error']) {
			return installPublishedSkillPackToBrainResponse['data'];
		}

		installPublishedSkillPackToBrainResponse['data']['brain'] =
			await deploySkills({
				profileSlug: formData.profileSlug,
				brainId: formData.brainId,
			});

		return installPublishedSkillPackToBrainResponse['data'];
	}
};

export default installPublishedSkillPackToBrain;
