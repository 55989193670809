import React from 'react';

import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Alert,
	AlertDescription,
	AlertIcon,
	useColorMode,
	useToast,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import isElectron from 'is-electron';
// API
import APIFetch from '../../../util/APIFetch';
import ResetAPI from './ResetAPI';

//Redux
import { connect } from 'react-redux';
import { setUser, setStatus } from '../../../redux/actions';

// Config
import { config } from '../../../config';

//History
import History, { HistoryWithSlug } from '../../../util/History';

//Utils
import KeyEnum from '../../../util/KeyEnum';
//Utils
import validate_password from '../../../util/Validate/validate_password';

// Controllers

const PasswordInput = ({
	name,
	placeholder,
	onChange,
	onKeyDown,
	invalid,
	disabled,
}) => {
	const [show, setShow] = React.useState(false);
	const borderColor = { light: '#f2f2f2', dark: '#494949' };
	const inputBgColor = { light: 'white', dark: '#4c4c4c' };
	const inputFontColor = { light: 'gray.800', dark: 'white' };
	const { colorMode } = useColorMode();
	const handleClick = () => setShow(!show);

	return (
		<InputGroup size="md" fontFamily="body">
			<Input
				name={name}
				type={show ? 'text' : 'password'}
				placeholder={placeholder || 'Enter password'}
				onChange={onChange}
				borderColor={invalid ? 'red.300' : borderColor[colorMode]}
				focusBorderColor={invalid ? 'red.300' : 'purple.500'}
				_placeholder={{ color: inputFontColor[colorMode] }}
				color={inputFontColor[colorMode]}
				fontSize="0.8rem"
				bg={inputBgColor[colorMode]}
				size="md"
				width="14rem"
				borderRadius="5px"
				onKeyDown={onKeyDown}
				isDisabled={disabled}
			/>
			<InputRightElement width="3rem">
				<Box
					as={show ? MdVisibilityOff : MdVisibility}
					size="32px"
					color="gray.300"
					onClick={handleClick}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

const ResetCard = ({ setUser, setStatus }) => {
	const toast = useToast();
	const [formData, setFormData] = React.useState({});
	const [formAlert, setFormAlert] = React.useState({ type: null });
	const [isSubmitting, setSubmitting] = React.useState(false);
	const [newPwd, setNewPwd] = React.useState('');
	const [confirmNewPwd, setConfirmNewPwd] = React.useState('');
	const [pwdErr, setPwdErr] = React.useState(false);
	const [match, setMatch] = React.useState(false);
	// Setup the API Fetch utility for the Login View.
	// console.log(formData);
	const [{ fetchResults }] = APIFetch({
		BASEURL: config.fusionAuth.BASEURL,
		PATH_SEARCH:
			ResetAPI.fusionAuth.changePassword.PATH_SEARCH +
			window.location.pathname.substring(6),
		PATH_METHOD: ResetAPI.fusionAuth.changePassword.PATH_METHOD,
	});

	React.useEffect(() => {
		if (newPwd && confirmNewPwd) {
			if (newPwd === confirmNewPwd) {
				setMatch(true);
			} else {
				setMatch(false);
			}
		} else {
			setMatch(false);
		}
	}, [newPwd, confirmNewPwd]);

	//insert reset password logic here
	const handleResetSubmit = () => {
		setSubmitting(true);
		let formData = {
			password: newPwd,
		};
		fetchResults(formData)
			.then((res) => {
				setSubmitting(false);
				toast({
					title: 'Success!',
					description: 'Password reset successfully. Now login.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			})
			.catch((err) => {
				setSubmitting(false);
				toast({
					title: 'Failed!',
					description: 'Change password failed. Try again!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	const { colorMode } = useColorMode();

	const fontColor = { light: 'maya_light.400', dark: 'white' };
	const bgColor = { light: '#f9f9f9', dark: '#424242' };
	const borderColor = { light: '#f2f2f2', dark: '#494949' };

	return (
		<Box
			paddingTop="1rem"
			paddingBottom="2rem"
			paddingX="1.2rem"
			display="flex"
			flexDirection="row"
			justifyContent="center"
			flexGrow="0"
			flexShrink="0"
			bg={bgColor[colorMode]}
			fontFamily="body"
			fontWeight="600"
			borderRadius="5px"
			border="1px solid"
			borderColor={borderColor[colorMode]}
			zIndex="50"
		>
			<Stack>
				<Box color={fontColor[colorMode]} fontSize="1.3rem" mb="1rem">
					Reset Password
				</Box>

				<PasswordInput
					name="password"
					placeholder="Enter new password"
					onChange={(e) => {
						setNewPwd(e.target.value);
						if (validate_password(e.target.value)) {
							setPwdErr(false);
						} else if (e.target.value) {
							setPwdErr(true);
						} else {
							setPwdErr(false);
						}
					}}
				/>
				<PasswordInput
					name="password"
					placeholder="Confirm password"
					onChange={(e) => {
						setConfirmNewPwd(e.target.value);
					}}
					invalid={confirmNewPwd && !match}
					disabled={pwdErr}
				/>

				<Button
					size="md"
					marginTop="0.5rem"
					onClick={handleResetSubmit}
					isLoading={isSubmitting}
					cursor
					bg={'purple.500'}
					color="white"
					fontSize="0.8rem"
					isDisabled={!match}
				>
					Set New Password
				</Button>
				<Box
					height="20px"
					width="14rem"
					fontSize="0.6rem"
					fontWeight="400"
					fontFamily="body"
					marginTop="0"
					textAlign="center"
				>
					{pwdErr
						? 'Must have at least 1 special character, 1 capital letter, 1 number, and be at least 8 characters long.'
						: null}
				</Box>
			</Stack>
		</Box>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus })(ResetCard);

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
