import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box } from '@chakra-ui/react';
import update from 'immutability-helper';

//Redux
import { connect } from 'react-redux';
import { setNavRoute } from '../../redux/actions';

//Components
import StoreCategories from './Categories';
import SkillPack from './StorePack';
import ModulePack from './ModulePack';
import CommandPack from './CommandPack';

//History
import History, { HistoryWithSlug } from '../../util/History';
import getStoreCategories from '../../functions/store/getStoreCategories';
import getStoreApps from '../../functions/store/getStoreApps';

/**
 * Store Panel Component
 *
 * @param {{
 * 	storeType: 'Collections' | 'Modules' | 'Commands',
 * 	pageMode: any,
 * 	setNavRoute: any,
 * 	currentBrainId: string,
 * }} param0
 * @returns
 */
const StorePanel = ({ storeType, pageMode, setNavRoute, currentBrainId }) => {
	const [type, setType] = useState(
		useCallback(
			() => ({
				kind: 'type',
				label: storeType,
				checked: true,
			}),
			[storeType]
		)
	);
	const [platforms, setPlatforms] = useState([]);
	const [allCategories, setAllCategories] = useState(
		/**
		 * @returns {{
		 * 	[key: string]: import('../../functions/store/types/Categories').StoreCategory & {
		 * 		_checked: boolean,
		 * 		kind: 'category'
		 * 	}
		 * }}
		 */
		() => ({})
	);
	const [allApps, setAllApps] = useState(
		/**
		 * @returns {{
		 * 	[key: string]: import('../../functions/store/types/Apps').StoreApp & {
		 * 		_checked: boolean,
		 * 		kind: 'app'
		 * 	}
		 * }}
		 */
		() => ({})
	);

	const navigateToRoute = (route) => {
		if (pageMode === 'editor') {
			setNavRoute(route);
		} else {
			HistoryWithSlug.push(route);
		}
	};

	const setStoreType = (entry, checked) => {
		let info = { ...entry, checked };
		if (checked) {
			if (entry.label === 'Collections') {
				navigateToRoute('/store/skills');
			} else if (entry.label === 'Modules') {
				navigateToRoute('/store/modules');
			} else if (entry.label === 'Commands') {
				navigateToRoute('/store/commands');
			}

			setType({
				...info,
			});
		}
	};

	const handleCatCheck = (_id, checked) => {
		setAllCategories(
			update(allCategories, {
				[_id]: {
					_checked: { $set: checked },
				},
			})
		);
	};

	const handleAppCheck = (_id, checked) => {
		setAllApps(
			update(allApps, {
				[_id]: {
					_checked: { $set: checked },
				},
			})
		);
	};

	useEffect(() => {
		getStoreCategories().then((res) => {
			const formattedRes = res.reduce((acc, cat) => {
				acc[cat.id] = { ...cat, _checked: false, kind: 'category' };
				return acc;
			}, {});
			setAllCategories(formattedRes);
		});
		getStoreApps().then((res) => {
			const apps = {};
			res.forEach((app) => {
				apps[app.id] = { ...app, _checked: false, kind: 'app' };
			});
			setAllApps(apps);
		});
	}, []);

	const checkedCategories = useMemo(
		() => Object.values(allCategories).filter((c) => c._checked),
		[allCategories]
	);
	const checkedApps = useMemo(
		() => Object.values(allApps).filter((a) => a._checked),
		[allApps]
	);

	return (
		<Box
			display="flex"
			flex="1 1 auto"
			// bg='green'
		>
			<StoreCategories
				type={type}
				categories={Object.values(allCategories)}
				apps={Object.values(allApps)}
				platforms={platforms}
				setType={setStoreType}
				setPlatforms={setPlatforms}
				handleCatCheck={handleCatCheck}
				handleAppCheck={handleAppCheck}
			/>

			{type.label === 'Collections' ? (
				<SkillPack
					currentBrainId={currentBrainId}
					platforms={platforms}
					categories={checkedCategories}
					apps={checkedApps}
				/>
			) : null}
			{type.label === 'Modules' ? (
				<ModulePack
					currentBrainId={currentBrainId}
					platforms={platforms}
					categories={checkedCategories}
					apps={checkedApps}
				/>
			) : null}
			{type.label === 'Commands' ? (
				<CommandPack
					categories={checkedCategories}
					apps={checkedApps}
					currentBrainId={currentBrainId}
				/>
			) : null}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { pageMode } = state.navigation;
	return { pageMode };
};

export default connect(mapStateToProps, { setNavRoute })(StorePanel);
