// Action Types
import actionTypes from '../actionTypes';

// Setup initial state with an fleet info object.
const initialState = {
	route: '/home',
	query: '',
	pageMode: '',
	isLoggedIn: true,
};

// Export the Navigation Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_NAV_ROUTE: {
			const { info } = action.payload;
			return { ...state, route: info };
		}
		case actionTypes.SET_NAV_SEARCH_QUERY: {
			const { info } = action.payload;
			return { ...state, query: info };
		}
		case actionTypes.SET_NAV_PAGE_MODE: {
			const { info } = action.payload;
			return { ...state, pageMode: info };
		}
		case actionTypes.SET_NAV_LOGGED_IN: {
			const { info } = action.payload;
			return { ...state, isLoggedIn: info };
		}
		default:
			return state;
	}
};
