import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';

import {
	CircularProgress,
	useToast,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	useColorModeValue,
	LightMode,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Textarea,
} from '@chakra-ui/react';

import isElectron from 'is-electron';
import {
	FiPlayCircle,
	FiPauseCircle,
	FiTrash2,
	FiExternalLink,
	FiCommand,
	FiAlertTriangle,
	FiCopy,
	FiCheck,
} from 'react-icons/fi';
// Components
import { HorizontalItemButton } from '../Reusable/HorizontalItem';
import History, { HistoryWithSlug } from '../../util/History';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

import GetProfileSlug from '../../util/ProfileSlug';

// Functions
import startBrain from '../../functions/brain/startBrain';
import stopBrain from '../../functions/brain/stopBrain';
import deleteBrain from '../../functions/brain/deleteBrain';
import getBrainFlows from '../../functions/brain/getBrainFlows';

// Redux imports
import {
	removeBrain,
	updateBrainById,
	addTab,
	closeTab,
	setTrialExpiryModalDisplay,
} from '../../redux/actions';
import { getTimeToExpiry } from '../../util/Tier/getTimeRemaining';

const copyButtonStates = {
	READY: 1,
	COPIED: 2,
};

export const CrashInfoButton = ({ brain, setHoveredButton, hoveredButton }) => {
	const { slug } = GetProfileSlug();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [copyButtonState, setCopyButtonState] = useState(
		copyButtonStates.READY
	);

	const [flows, setFlows] = useState('');

	const copyFlowRef = useRef();

	useEffect(() => {
		getBrainFlows({ brainId: brain._id, slug }).then((f) => {
			setFlows(f);
		});
	}, [isOpen]);

	function handleCopy() {
		navigator.clipboard.writeText(JSON.stringify(flows, null, 4));
		setCopyButtonState(copyButtonStates.COPIED);
		setTimeout(() => setCopyButtonState(copyButtonStates.READY), 800);
	}

	return (
		<>
			<HorizontalItemButton
				icon={<FiAlertTriangle color="#f56565" size="1.5rem" />}
				buttonKey="crashalert"
				text="crashed"
				onClick={() => console.log('yeet: We handling da crash')}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
				onClick={onOpen}
			/>
			<CustomModal
				isOpen={isOpen}
				onClose={onClose}
				initialFocusRef={copyFlowRef}
				isCentered
				size="2xl"
			>
				<ModalOverlay />
				<ModalContent bg="maya_dark.300">
					<ModalHeader>Runtime crashed</ModalHeader>
					<ModalCloseButton />
					<ModalBody color="#ddd">
						This workspace crashed too many times in 1 minute, there could
						be an irrecoverable error in your installed flows that occurs
						when it starts. If it keeps crashing, copy your flows and
						install them in a fresh workspace
						<Textarea height="400px" variant="filled" mt="32px">
							{JSON.stringify(flows, null, 8)}
						</Textarea>
					</ModalBody>
					<ModalFooter>
						<LightMode>
							<Button
								colorScheme="purple"
								size="sm"
								ref={copyFlowRef}
								leftIcon={
									copyButtonState === copyButtonStates.READY ? (
										<FiCopy />
									) : (
										<FiCheck />
									)
								}
								_focus={{ boxShadow: 'none', outline: 'none' }}
								width="110px"
								onClick={handleCopy}
							>
								{copyButtonState === copyButtonStates.READY
									? 'Copy flows'
									: 'Copied!'}
							</Button>
						</LightMode>
					</ModalFooter>
				</ModalContent>
			</CustomModal>
		</>
	);
};

export const StartStopButton = ({
	hold,
	setHold,
	brain,
	setHoveredButton,
	hoveredButton,
	setTrialExpiryModalDisplay,
	updateBrainById,
	isDisabled,
}) => {
	const [isLoading, setLoading] = React.useState(false);
	// const [device, setDevice] = React.useState({_id: "", name: ""});

	const toast = useToast();

	const { slug } = GetProfileSlug();

	async function handleStart() {
		let timeToExpiry
		try {
			timeToExpiry = await getTimeToExpiry()
			if (timeToExpiry <= 0) {
				setTrialExpiryModalDisplay(true)
				console.log('dat', 'bruh')
				return
			}
		} catch (e) {
			console.log('Unable to get time to tier expiry:', e)
			toast({
				title: 'Error!',
				description: 'Unable to get time to tier expiry. Try logging in again',
				status: 'error',
				duration: 5000,
				isClosable: true,
			})
			return
		}

		setLoading(true);
			setHold(true);
			startBrain({
				brain,
				slug,
				onDone: () => {
					setLoading(false);
					setHold(false);
					toast({
						title: 'Success!',
						description: 'The workspace has successfully started',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				},
				onError: () => {
					setLoading(false);
					setHold(false);
					toast({
						title: 'Error!',
						description: 'This workspace could not be started',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				},
				onLoading: () => {
					toast({
						title: 'Start in progress.',
						description: 'The workspace has been queued for starting',
						status: 'info',
						duration: 3000,
						isClosable: true,
					});
				},
			});
	}

	function handleStop() {
		setLoading(true);
		setHold(true);
		stopBrain({
			brain,
			slug,
			onDone: () => {
				setLoading(false);
				setHold(false);
				toast({
					title: 'Success!',
					description: 'The workspace has been stopped.',
					status: 'warning',
					duration: 3000,
					isClosable: true,
				});
			},
			onError: () => {
				setLoading(false);
				setHold(false);
				toast({
					title: 'Error!',
					description: 'This workspace could not be stopped.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			},
		});
	}

	function handleClick() {
		if (hold) {
			return toast({
				title: 'Wait a moment!',
				description: 'A workspace seems to be starting/stopping.',
				status: 'warning',
				duration: 3000,
				isClosable: true,
			});
		}

		switch (brain.status) {
			case 'STARTED':
				return handleStop();
			case 'PENDING':
				return handleStop();
			default:
				return handleStart();
		}
	}

	let buttonText = 'start';
	if (isLoading) {
		buttonText = 'loading';
	} else if (brain.status === 'STARTED' || brain.status === 'PENDING') {
		buttonText = 'stop';
	} else if (brain.status === 'TEMP_CRASH') {
		buttonText = 'recovering';
	}

	return (
		<HorizontalItemButton
			icon={
				isLoading ? (
					<CircularProgress
						isIndeterminate
						color={'purple.500'}
						size="1.5rem"
					/>
				) : brain.status === 'PENDING' ? (
					<FiPauseCircle />
				) : brain.status === 'STARTED' ? (
					<FiPauseCircle />
				) : (
					<FiPlayCircle />
				)
			}
			buttonKey="startstop"
			text={buttonText}
			onClick={handleClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
			isDisabled={isLoading || isDisabled || brain.status === 'TEMP_CRASH'}
		/>
	);
};

export const TempCrashButton = ({ setHoveredButton, hoveredButton }) => {
	return (
		<HorizontalItemButton
			icon={
				<CircularProgress isIndeterminate color="red.500" size="1.5rem" />
			}
			buttonKey="tempcrash"
			text="recovering"
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};

export const SkillsButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiCommand />}
			buttonKey="skills"
			text={'skills'}
			onClick={onClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};

export const OpenButton = ({
	setHoveredButton,
	hoveredButton,
	onClick,
	addTab,
	brain,
	isDisabled,
	hold,
	setTrialExpiryModalDisplay,
}) => {
	const toast = useToast();

	const goToBrain = () => {
		addTab(brain._id, brain.name);
		HistoryWithSlug.push(`/edit?id=${brain._id}`);
		// window.location.reload();
	};

	async function handleClick() {
		let timeToExpiry
		try {
			timeToExpiry = await getTimeToExpiry()
			if (timeToExpiry <= 0) {
				setTrialExpiryModalDisplay(true)
				console.log('dat', 'bruh')
				return
			}
		} catch (e) {
			console.log('Unable to get time to tier expiry:', e)
			toast({
				title: 'Error!',
				description: 'Unable to get time to tier expiry. Try logging in again',
				status: 'error',
				duration: 5000,
				isClosable: true,
			})
			return
		}

		goToBrain()
	}
	
	return (
		<HorizontalItemButton
			icon={<FiExternalLink />}
			buttonKey="open"
			text={'open'}
			onClick={handleClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
			isDisabled={hold || isDisabled}
			// tooltip={
			// 	isDisabled
			// 		? 'The Default workspace is locked for editing, create a new workspace to start creating and editing skills'
			// 		: ''
			// }
		/>
	);
};

export const ConnectedOpenButton = connect(null, { addTab, setTrialExpiryModalDisplay })(OpenButton);

export const DeleteButton = ({
	brain,
	setHoveredButton,
	hoveredButton,
	removeBrain,
	closeTab,
	isDisabled,
}) => {
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();
	const handleDelete = () => {
		setIsDeleting(true);
		deleteBrain({ brainId: brain._id, slug: slug })
			.then((res) => {
				if (res['error']) {
					setIsDeleting(false);
					toast({
						title: 'Error!',
						description: 'The selected workspace could not be removed.',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				} else {
					removeBrain(res._id);
					closeTab(res._id);
					setIsDeleting(false);
					toast({
						title: 'Deleted!',
						description: 'The selected workspace has been removed.',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				}
				onClose();
			})
			.catch((err) => {
				setIsDeleting(false);
				toast({
					title: 'Error!',
					description: 'The selected workspace could not be removed',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<HorizontalItemButton
				icon={<FiTrash2 />}
				buttonKey="delete"
				text={'delete'}
				onClick={() => {
					setIsOpen(true);
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
				// isDisabled={isDisabled}
				// tooltip={
				// 	isDisabled
				// 		? 'Default workspaces cannot be deleted. Uninstall skills from the Home section to remove skills'
				// 		: ''
				// }
			/>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Delete Workspace
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={handleDelete}
									ml={3}
									isLoading={isDeleting}
									loadingText={'Deleting'}
								>
									Delete
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

const mapStateToDeleteButtonProps = (state) => {
	let { brains } = state;
	return { brains };
};

const mapStateToStartStopButtonProps = (state) => {
	let { brains } = state;
	return { brains };
};

export const ConnectedDeleteButton = connect(mapStateToDeleteButtonProps, {
	removeBrain,
	closeTab,
})(DeleteButton);
export const ConnectedStartStopButton = connect(
	mapStateToStartStopButtonProps,
	{ updateBrainById, setTrialExpiryModalDisplay }
)(StartStopButton);
