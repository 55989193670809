import React from 'react';
import {
	Box,
	Image,
	Stack,
	Tooltip,
	useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';

import { FiExternalLink, FiCommand } from 'react-icons/fi';
import { HiLockClosed } from 'react-icons/hi';
import { MdComputer } from 'react-icons/md';

// Components
import HorizontalItem from '../Reusable/HorizontalItem';
import TagWithIcon from '../Reusable/TagWithIcon';
import CommandList from '../Commands/CommandList';

import {
	ConnectedStartStopButton,
	ConnectedDeleteButton,
	SkillsButton,
	ConnectedOpenButton,
	CrashInfoButton,
	TempCrashButton,
} from './BrainItemButtons';

export const BrainItemLeft = ({ brain, onClick }) => {
	return (
		<Box display="flex" alignItems="center" onClick={onClick} height="100%">
			<Box
				width="150px"
				height="100%"
				display="flex"
				alignItems="center"
				ml="2"
				bg="#777"
				borderRadius="6px"
			>
				<Image
					src={
						brain.thumbnail ||
						'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
					}
					objectFit="cover"
					borderRadius="5px"
					opacity="0.9"
				/>
			</Box>
			<Stack spacing={2} ml="1rem">
				<Box
					color="#555555"
					fontSize="1rem"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						width="8px"
						height="8px"
						mt="1"
						bg={
							brain.status === 'PENDING'
								? 'orange.400'
								: brain.status === 'STARTED'
								? 'green.400'
								: brain.status === 'CRASHED'
								? 'grey'
								: 'red.400'
						}
						borderRadius="1rem"
					/>
					<Box
						fontSize="22px"
						marginLeft="0.4rem"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
					>
						{brain.name}
					</Box>
				</Box>
				<Box display="flex">
					<TagWithIcon text={brain.deviceName} icon={<MdComputer />} />
					<TagWithIcon
						text={`${brain.intents.length} commands`}
						icon={<FiCommand />}
						tooltip={
							brain.default
								? 'Nodes used in the default workspace are not counted in the node limit.'
								: ''
						}
					/>
					{false ? (
						<TagWithIcon
							text={``}
							icon={<HiLockClosed />}
							tooltip="The Default Workspace is locked for editing, create a new workspace to start creating and editing workflows"
						/>
					) : null}
				</Box>
			</Stack>
		</Box>
	);
};

export const BrainItem = ({
	brain,
	skillsButton,
	controlButton,
	openButton,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	hold,
	setHold,
}) => {
	const [hoveredButton, setHoveredButton] = useState('');
	const [isOpen, setIsOpen] = useState(isItemExpanded);

	React.useEffect(() => {
		setIsOpen(isItemExpanded);
	}, [isItemExpanded]);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<HorizontalItem
			isOpen={isOpen}
			onClickItem={
				wholeItemActive
					? () => {
							toggleOpen();
							onClick(brain._id);
					  }
					: () => onClick(brain._id)
			}
			selected={selected}
			itemLeft={
				<BrainItemLeft
					brain={brain}
					onClick={() => {
						toggleOpen();
						onClick(brain._id);
					}}
				/>
			}
			buttons={
				<Box display="flex">
					{brain.status === 'TEMP_CRASH' && (
						<TempCrashButton
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							brain={brain}
						/>
					)}
					{brain.status === 'CRASHED' && (
						<CrashInfoButton
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							brain={brain}
						/>
					)}
					{skillsButton && (
						<SkillsButton
							onClick={() => {
								toggleOpen();
								onClick(brain._id);
							}}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
						/>
					)}
					{controlButton && (
						<ConnectedStartStopButton
							brain={brain}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							hold={hold}
							setHold={setHold}
							isDisabled={brain.status === 'TEMP_CRASH'}
						/>
					)}
					{openButton && (
						<ConnectedOpenButton
							icon={<FiExternalLink />}
							buttonKey="open"
							text={'open'}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							brain={brain}
							hold={hold}
							isDisabled={brain.status === 'CRASHED' || brain.status === 'TEMP_CRASH'}
						/>
					)}
					{controlButton && (
						<ConnectedDeleteButton
							brain={brain}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							isDisabled={brain.default}
						/>
					)}
				</Box>
			}
			isItemExpanded={isItemExpanded}
			expandedContent={
				<Box paddingLeft="11rem">
					<CommandList commands={brain.intents} height={'10rem'} />
				</Box>
			}
		/>
	);
};

//
