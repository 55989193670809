import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import deploySkills from '../editor/deploy';

const uninstallPublishedSkillPackFromBrain = async (formData) => {
	var uninstallPublishedSkillPackFromBrainAPIFn,
		uninstallPublishedSkillPackFromBrainIPCFn,
		uninstallPublishedSkillPackFromBrainResponse,
		getPublishedSkillPackByIdFn,
		getPublishedSkillPackByIdResponse;
	if (!isElectron()) {
		uninstallPublishedSkillPackFromBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH:
				APIRoutes.apiService.uninstallPublishedSkillPackFromBrain
					.PATH_SEARCH,
			PATH_METHOD:
				APIRoutes.apiService.uninstallPublishedSkillPackFromBrain
					.PATH_METHOD,
		});
		uninstallPublishedSkillPackFromBrainResponse =
			await uninstallPublishedSkillPackFromBrainAPIFn();
		if (uninstallPublishedSkillPackFromBrainResponse['data']['error']) {
			return uninstallPublishedSkillPackFromBrainResponse['data'];
		} else {
			return uninstallPublishedSkillPackFromBrainResponse['data']['results'];
		}
	} else {
		uninstallPublishedSkillPackFromBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.uninstallPublishedSkillPackFromBrain.EVENT,
		});

		uninstallPublishedSkillPackFromBrainResponse =
			await uninstallPublishedSkillPackFromBrainIPCFn(formData);
		if (uninstallPublishedSkillPackFromBrainResponse['data']['error']) {
			return uninstallPublishedSkillPackFromBrainResponse['data'];
		}

		uninstallPublishedSkillPackFromBrainResponse['data']['brain'] =
			await deploySkills({
				profileSlug: formData.profileSlug,
				brainId: formData.brainId,
			});

		return uninstallPublishedSkillPackFromBrainResponse['data'];
	}
};

export default uninstallPublishedSkillPackFromBrain;
