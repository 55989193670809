import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';
// store
import { store } from '../../redux/store';
/**
 * Function to check run status of interrupted brains from a previous session
 */
const checkStartupStatus = async (slug) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	var checkStartupStatusFn;
	if (!isElectron()) {
		checkStartupStatusFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.checkStartupStatus.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.checkStartupStatus.PATH_METHOD,
		});
	} else {
		checkStartupStatusFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.checkStartupStatus.EVENT,
		});
	}

	var checkStartupStatusResponse = await checkStartupStatusFn(profileSlug);
	return checkStartupStatusResponse;
};

export default checkStartupStatus;
