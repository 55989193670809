import GQLFetch from '../../util/GQLFetch';
// import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
import { store } from '../../redux/store';

/**
 *
 * @param {*} {"_id": "skillpackId"}
 * @returns
 */
const getPublishedSkillPackList = async (formData) => {
	console.log(formData);
	if (!formData || formData.length === 0) {
		return [];
	}
	const getPublishedSkillPackListGQueryFn = GQLFetch({
		BASEURL: config.app.CMS_BACKEND + '/graphql',
		gquery: `
		query($list: [String!]!) {
			skillpacks (where: {
				_id: $list
			 }){
				_id
				thumbnail,
				name,
				shortDescription,
				longDescription,
				postInstallInstruction,
				currentVersion,
				previews,
				currentFlow {
				_id
				intents
				externalModules {
					 name
					 packageName
					 version
					 type
				}
				modules{
					 _id
					 name
					 module{
						  _id
						  userProfile
						  name
						  thumbnail
						  packageName
						  description
						  currentVersion
						  type
					 }
				}
				}
			 }
		}`,
		params: { list: formData },
	});

	const getPublishedSkillPackListGQueryResponse =
		await getPublishedSkillPackListGQueryFn();
	console.log('gql response', getPublishedSkillPackListGQueryResponse);
	return getPublishedSkillPackListGQueryResponse.data.skillpacks || [];
};

export default getPublishedSkillPackList;
