import { Edge, Elements, Position } from 'react-flow-renderer';

const genericNodeColors = [
	'#8bd86f',
	'#3ebee5',
	'#911ae0',
	'#f4a871',
	'#dcd504',
	'#fa858c',
];

const elements = [
	{
		id: '1',
		type: 'generic', // input node
		data: { label: 'debug', color: '#8bd86f' },
		position: { x: 50, y: 70 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	// default node
	{
		id: '2',
		type: 'generic',
		// you can also pass a React component as a label
		data: { label: 'delay', color: '#3ebee5' },
		position: { x: 250, y: 180 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: '3',
		type: 'generic', // out put node
		data: { label: 'function', color: '#911ae0' },
		position: { x: 410, y: 300 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: '4',
		type: 'generic', // out put node
		data: { label: 'input', color: '#f4a871' },
		position: { x: 450, y: 150 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: '5',
		type: 'generic', // out put node
		data: { label: 'output', color: '#dcd504' },
		position: { x: 280, y: 100 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: '6',
		type: 'generic', // out put node
		data: { label: 'adder', color: '#fa858c' },
		position: { x: 50, y: 260 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: '7',
		type: 'switch', // out put node
		data: { label: 'switch', color: '#637ef8', numOfOutputs: 4 },
		position: { x: 220, y: 250 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: '8',
		type: 'generic',
		// you can also pass a React component as a label
		data: { label: 'delay', color: '#3ebee5' },
		position: { x: 450, y: 250 },
		sourcePosition: 'right',
		targetPosition: 'left',
	},
	{
		id: 'e1-2',
		source: '1',
		target: '2',
		// animated: true,
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e2-3',
		source: '2',
		target: '3',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e1-3',
		source: '1',
		target: '3',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e6-5',
		source: '6',
		target: '5',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e1-6',
		source: '1',
		target: '6',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e5-8',
		source: '5',
		target: '8',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e6-4',
		source: '6',
		target: '4',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e7.1-4',
		source: '7',
		target: '4',
		sourceHandle: '1',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e7.2-4',
		source: '7',
		target: '4',
		sourceHandle: '2',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
	{
		id: 'e7.3-4',
		source: '7',
		target: '4',
		sourceHandle: '4',
		style: {
			strokeWidth: 4.5,
			stroke: '#808080',
		},
	},
];

export const parseNodeRedToReactFlow = (nodeRedFlowDef, scalePosition = 1) => {
	/**
	 * Get node color definitions
	 * - Loop through the node definitions
	 * - map each type to a random color(from a generic list)
	 */
	const nodeColorDefs = nodeRedFlowDef.reduce((prev, curr, idx, arr) => {
		if (curr.type === 'switch') prev[curr.type] = '#637ef8';
		else
			prev[curr.type] =
				genericNodeColors[
					Math.floor(Math.random() * genericNodeColors.length)
				];
		return prev;
	}, {});
	const result = nodeRedFlowDef.reduce((prev, curr, idx, arr) => {
		if (typeof curr.x === 'number' && typeof curr.y === 'number') {
			switch (curr.type) {
				case 'switch': {
					prev.push({
						id: curr.id,
						type: 'switch', // out put node
						data: {
							label: curr.name || curr.type,
							color: nodeColorDefs[curr.type] || '#637ef8',
							numOfOutputs: curr.outputs || 1,
							orgMetaData: curr,
						},
						position: {
							x: curr.x * scalePosition,
							y: curr.y * scalePosition,
						},
						sourcePosition: 'right',
						targetPosition: 'left',
					});
					if (curr.wires && curr.wires.length > 0) {
						curr.wires.forEach((portWires, portWiresIdx) => {
							if (portWires.length > 0) {
								const outWires = portWires.map((wireId, wireIdx) => ({
									id: `e${curr.id}.${portWiresIdx + 1}-${wireId}`,
									source: curr.id,
									sourceHandle: `${portWiresIdx + 1}`,
									target: wireId,
									style: {
										strokeWidth: 4.5,
										stroke: '#808080',
									},
								}));
								prev.push(...outWires);
							}
						});
					}
					break;
				}
				default: {
					prev.push({
						id: curr.id,
						type: 'generic',
						data: {
							label: curr.name || curr.type,
							color: nodeColorDefs[curr.type] || '#f4a871',
							orgMetaData: curr,
						},
						position: {
							x: curr.x * scalePosition,
							y: curr.y * scalePosition,
						},
						sourcePosition: 'right',
						targetPosition: 'left',
					});
					if (
						curr.wires &&
						curr.wires.length > 0 &&
						curr.wires[0].length
					) {
						const outWires = curr.wires[0].map((wireId) => ({
							id: `e${curr.id}-${wireId}`,
							source: curr.id,
							target: wireId,
							style: {
								strokeWidth: 4.5,
								stroke: '#808080',
							},
						}));
						prev.push(...outWires);
					}
					break;
				}
			}
		}
		return prev;
	}, []);
	return result;
};
