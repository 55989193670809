import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box } from '@chakra-ui/react';

const baseStyle = {
	flex: 1,
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '20px',
	borderWidth: 1,
	borderRadius: 5,
	borderColor: '#7d7c7c',
	borderStyle: 'dashed',
	backgroundColor: '#313131',
	color: '#bdbdbd',
	outline: 'none',
	height: '150px',
	cursor: 'pointer',
	transition: 'border .24s ease-in-out',
};

const activeStyle = {
	borderColor: '#2196f3',
};

const acceptStyle = {
	borderColor: '#00e676',
};

const rejectStyle = {
	borderColor: '#ff1744',
};

const StyledDropzone = (props) => {
	const {
		getRootProps,
		getInputProps,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		accept: props.fileTypes || '*',
		onDrop: (files) =>
			props.selectFilesFn ? props.selectFilesFn(files) : console.log(files),
	});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isDragActive ? activeStyle : {}),
			...(isDragAccept ? acceptStyle : {}),
			...(isDragReject ? rejectStyle : {}),
		}),
		[isDragActive, isDragReject, isDragAccept]
	);

	return (
		<Box className="container" {...props}>
			<div {...getRootProps({ style })}>
				<input {...getInputProps()} />
				<p>Drag 'n' drop some files here, or click to select files</p>

				{props.fileTypesText ? <em>{props.fileTypesText}</em> : null}
			</div>
		</Box>
	);
};

export default StyledDropzone;
