import React, { useCallback } from 'react';
import {
	ModalBody,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Box,
	LightMode,
	Button,
	useToast,
	useColorModeValue,
	Alert,
	AlertIcon,
	DarkMode,
} from '@chakra-ui/react';
import _ from 'lodash';

// Redux imports
import { connect, useSelector } from 'react-redux';
import { updateBrainById } from '../../redux/actions';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import SelectBrain from '../Brains/SelectBrain';
import { ModuleItem } from '../Module/ModuleItem';
import PostInstallScreen from '../Reusable/PostInstallScreen';
import { HorizontalItemSkeleton } from '../Reusable/HorizontalItem';

//Functions
import installSkillPackToBrain from '../../functions/skillPack/installSkillPackToBrain';
import installPublishedSkillPackToBrain from '../../functions/publishedSkillPack/installPublishedSkillPackToBrain';
import installExternalSkillpack from '../../functions/publishedSkillPack/installExternalSkillpack';
import addToLibraryFromStore from '../../functions/library/addToLibraryFromStore';
import GetProfileSlug from '../../util/ProfileSlug';
import getConfigProfiles from '../../functions/configuration/getConfigProfiles';

//Analytics
import analytics from '../../util/Analytics';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import getUsage from '../../functions/settings/usage/getUsage';
import { getNodesCount } from '../../util/Nodes';
import { getFlowFromSkillPack } from '../../util/Misc';

import { HistoryWithSlug } from '../../util/History';

const SkillPackInstallModal = ({
	brains,
	onClose,
	isOpen,
	pack,
	currentBrainId,
	publishedSkillPackId,
	skillPackId,
	updateBrainById,
}) => {
	const [brainId, setBrainId] = React.useState();
	const { tier } = useSelector((store) => store.profiles.selected);
	const [nodeLimitFlag, setNodeLimitFlag] = React.useState(false);
	const [isLoading, setLoading] = React.useState(false);
	const [isModuleLoading, setModuleLoading] = React.useState(false);
	const [isDisabled, setDisabled] = React.useState(true);
	const [isInstalled, setInstalled] = React.useState(false);
	const [moduleList, setModuleList] = React.useState({ allIds: [], byId: {} });
	const [usage, setUsage] = React.useState({});
	const toast = useToast();
	const { slug } = GetProfileSlug();
	const loadingList = [1, 2];
	const countModuleConfigsRequired = useCallback(() => {
		let count = 0;
		if (moduleList.allIds.length === 0) {
			return 0;
		}
		for (let id of moduleList.allIds) {
			let obj = moduleList.byId[id];
			if (obj.configurationRequired && !obj.referenceId) {
				count = count + 1;
			}
		}
		return count;
	}, [moduleList]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const setModuleListUtil = async () => {
		let mods = { allIds: [], byId: {} };
		for (let moduleObj of pack.currentFlow.modules) {
			let mod = moduleObj.module;
			console.log('Module', mod);

			if (!mods.allIds.includes(mod._id)) {
				mods.allIds.push(mod._id);
				if (mod.configurationType) {
					let res = await getConfigProfiles({
						slug: slug,
						moduleId: mod._id,
						brainId: brainId,
					});
					mods.byId[mod._id] = {
						moduleName: mod.name,
						installed: false,
						configurationRequired: true,
						referenceId: res.length > 0 ? res[0].referenceId : null,
						profileName: res.length > 0 ? res[0].name : '',
						profileList: res,
					};
				} else {
					mods.byId[mod._id] = {
						moduleName: mod.name,
						installed: false,
						configurationRequired: false,
						referenceId: null,
						profileName: '',
						profileList: [],
					};
				}
			}
		}
		mods.allIds.sort((a) => (!mods.byId[a]?.configurationRequired ? 1 : -1));

		return mods;
	};

	React.useEffect(() => {
		getUsage().then((res) => {
			setUsage(res);
		});
	}, []);

	// check if pack is already installed
	React.useEffect(() => {
		if (brainId && brains.byId[brainId]) {
			// check if publishedSkillPack is installed
			if (pack) {
				const allInstalledSkillpacks = _.union(
					brains.byId[brainId].publishedSkillPacks,
					brains.byId[brainId].skillPacks
				);
				for (let p of allInstalledSkillpacks) {
					if (
						p.publishedSkillPack === pack._id ||
						p.skillPack === pack._id
					) {
						setInstalled(true);
						return;
					}
				}
			}
		}
	}, [brainId, brains, pack]);

	// runs only once when brain, pack or slug changes
	React.useEffect(() => {
		if (!brainId || brainId === '' || !pack || !slug) {
			return;
		}
		if (pack.currentFlow) {
			setModuleLoading(true);
			setModuleListUtil()
				.then((mods) => {
					setModuleList(mods);
					setModuleLoading(false);
				})
				.catch((err) => {
					console.log(err);
					setModuleLoading(false);
				});
		}
	}, [pack, brainId, slug, brains.byId]);

	// set Install button disable flag
	React.useEffect(() => {
		if (
			!isInstalled &&
			brainId &&
			!isLoading &&
			countModuleConfigsRequired() === 0
		) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [isInstalled, brainId, isLoading, countModuleConfigsRequired]);

	// install pack functions
	const handleInstallSkillPack = () => {
		setLoading(true);
		delete pack['version'];
		var formData = {
			SkillPackToInstall: skillPackId ? pack : null,
			PublishedSkillPackToInstall: publishedSkillPackId ? pack : null,
			ExternalSkillpackToInstall:
				!publishedSkillPackId && !skillPackId ? pack : null,
			version: pack.currentVersion,
			// version: pack.currentVersionDetails.version,
			brainId: brainId,
			moduleRefs: moduleList.byId,
			profileSlug: slug,
			thumbnail: pack.thumbnail || null,
		};
		if (publishedSkillPackId) {
			console.log('Installing published skillpack.....');
			installPublishedSkillPackToBrain(formData).then(async (res) => {
				setLoading(false);
				if (res['error']) {
					toast({
						title: 'Error!',
						description: 'The collection could not be installed',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					analytics.track('[Store] Collection Install Modal', {
						skillPack: pack._id,
						skillPackName: pack.name,
						version: formData.version,
						brainId,
						status: 'failure',
						error: res['error'],
					});
				} else {
					setInstalled(true);
					toast({
						title: 'Success!',
						description: 'The collection was successfully installed',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					console.log(res);
					updateBrainById(brainId, res.brain);
					analytics.track('[Store] Collection Install Modal', {
						skillPack: pack._id,
						skillPackName: pack.name,
						version: formData.version,
						brainId,
						status: 'success',
						error: !res['error'],
					});

					if (publishedSkillPackId) {
						await addToLibraryFromStore({ publishedSkillPackId });
					}
				}
			});
		} else if (skillPackId) {
			console.log('Installing skillpack.....');
			installSkillPackToBrain(formData).then(async (res) => {
				setLoading(false);
				if (res['error']) {
					toast({
						title: 'Error!',
						description: 'The collection could not be installed',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					analytics.track('[Library] Collection Install Modal', {
						skillPack: pack._id,
						skillPackName: pack.name,
						version: formData.version,
						brainId,
						status: 'failure',
						error: res['error'],
					});
				} else {
					setInstalled(true);
					toast({
						title: 'Success!',
						description: 'The collection was successfully installed',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					console.log(res);
					updateBrainById(brainId, res.brain);
					analytics.track('[Library] Collection Install Modal', {
						skillPack: pack._id,
						skillPackName: pack.name,
						version: formData.version,
						brainId,
						status: 'success',
						error: !res['error'],
					});

					// if (skillPackId) {
					// 	await addToLibraryFromStore({ skillPackId });
					// }
				}
			});
		} else {
			//TODO handle installation from JSON
			console.log('Installing external skillpack.....');
			installExternalSkillpack(formData).then(async (res) => {
				setLoading(false);
				if (res['error']) {
					toast({
						title: 'Error!',
						description: 'The collection could not be installed',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					analytics.track('[Store] Collection Install Modal', {
						skillPack: pack._id,
						skillPackName: pack.name,
						version: formData.version,
						brainId,
						status: 'failure',
						error: res['error'],
					});
				} else {
					setInstalled(true);
					toast({
						title: 'Success!',
						description: 'The collection was successfully installed',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					console.log(res);
					updateBrainById(brainId, res.brain);
					analytics.track('[Store] Collection Install Modal', {
						skillPack: pack._id,
						skillPackName: pack.name,
						version: formData.version,
						brainId,
						status: 'success',
						error: !res['error'],
					});

					if (publishedSkillPackId) {
						await addToLibraryFromStore({ publishedSkillPackId });
					}
				}
			});
		}
	};

	return (
		<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				height={'40rem'}
				maxWidth="55rem"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody
					paddingX="0"
					display="flex"
					flex="1"
					flexDir="column"
					minHeight="0"
				>
					<HeaderWithDescription
						header={`${pack.name}`}
						desc={'This will be installed in the selected workspace'}
						paddingX="1rem"
						tooltip={
							'This is a collection, a collection of Maya commands that execute Maya skill workflows.'
						}
					/>
					<Box
						display="flex"
						justifyContent="space-around"
						flexDir="column"
						flex="1"
						minHeight="0"
					>
						{publishedSkillPackId && (isLoading || isInstalled) ? (
							<PostInstallScreen
								publishedSkillPack={pack}
								isInstalled={isInstalled}
							/>
						) : (
							<Box
								paddingX="1rem"
								display="flex"
								justifyContent="space-between"
								flexDir="column"
								minHeight="0"
								flex="1"
							>
								<SelectBrain
									onSelectFn={(id) => {
										setBrainId(id);
									}}
									initialBrainId={currentBrainId}
									setBrainId={setBrainId}
								/>
								{brainId ? (
									<HeaderWithDescription
										header={'Configure Modules'}
										desc={
											countModuleConfigsRequired() > 0
												? 'Please configure the following modules.'
												: 'No module configurations required. Please continue.'
										}
									/>
								) : null}
								<Box flex="1" overflowY="auto">
									{brainId
										? isModuleLoading
											? loadingList.map((item) => (
													<HorizontalItemSkeleton key={item} />
											  ))
											: moduleList.allIds.map((id) => {
													const { module } = _.find(
														pack.currentFlow.modules,
														(obj) => {
															return obj.module._id === id;
														}
													);

													console.log(
														'profilesButton:',
														moduleList,
														id
													);

													return (
														<ModuleItem
															key={module._id}
															moduleId={id}
															module={module}
															selected={false}
															onClick={(moduleId) =>
																console.log(moduleId)
															}
															wholeItemActive={true}
															isItemExpanded={false}
															currentBrainId={brainId}
															addButton={false}
															profilesButton={
																moduleList.byId[id]
																	.configurationRequired
															}
															// version={
															// 	moduleObj.module.currentVersion
															// }
															setModuleList={setModuleList}
															moduleList={moduleList}
															isModuleLoading={isModuleLoading}
														/>
													);
											  })
										: null}
								</Box>
							</Box>
						)}
						<Box
							paddingX="1rem"
							borderTop="solid 1px"
							borderColor={useColorModeValue(
								'dashboard.border_light',
								'dashboard.border_dark'
							)}
							height="3.5rem"
							alignItems="center"
							justifyContent="space-between"
							display="flex"
						>
							<Box marginTop="0.5rem" width="100%">
								{tier === 'FREE' &&
								brainId &&
								!brains.byId[brainId].default &&
								false ? (
									<Alert
										status={nodeLimitFlag ? 'error' : 'warning'}
										//margin="5px"
										width="100%"
										borderRadius="5px"
										fontSize="13px"
										height="2.2rem"

										//colorScheme="purple"
									>
										<AlertIcon />
										{nodeLimitFlag
											? `[ ${usage.nodes}/${
													usage.upperLimit
											  } nodes used ] This collection needs ${
													getNodesCount(
														pack.currentFlow.flowJSON
													) +
													usage.nodes -
													usage.upperLimit
											  } extra node slots to be installed on this workspace. Upgrade now to go unlimited`
											: `Installing this collection will take up ${getNodesCount(
													pack.currentFlow.flowJSON
											  )} nodes. Upgrade now to go unlimited!`}

										<Button
											fontSize="10px"
											height="23px"
											marginLeft="15px"
											onClick={() => {
												HistoryWithSlug.push(
													`/settings?checkout=true`
												);
											}}
										>
											Continue
										</Button>
									</Alert>
								) : null}
							</Box>
							<LightMode>
								<Button
									colorScheme={isInstalled ? 'green' : 'purple'}
									size="sm"
									mr="2"
									ml="2"
									borderRadius="5px"
									variant={'solid'}
									paddingX="0.9rem"
									marginTop="0.5rem"
									onClick={() => {
										handleInstallSkillPack();
										//console.log('install click', moduleList);
									}}
									isLoading={isLoading}
									loadingText={'Installing...'}
									isDisabled={isDisabled}
								>
									{isInstalled ? 'Installed' : 'Install Now'}
								</Button>
							</LightMode>
						</Box>
					</Box>
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	return { brains };
};

export default connect(mapStateToProps, { updateBrainById })(
	SkillPackInstallModal
);
