import React from 'react';
import { AiFillFile } from 'react-icons/ai';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';

const FileTypeSearch = ({
	placeholderText: text,
	placeholderType: type,
	currentPlaceholderId,
	setPlaceholderSearchItems,
	setSearchBarLoadingStatus,
	searchItems,
	selectedIndex,
}) => {
	const [allItems, setAllItems] = React.useState([
		{ file: 'invoice.pdf', location: '/Users/sibeshkar/Maya/testdocs' },
		{ file: 'FinalFinalDoc.txt', location: '/Users/sibeshkar/Downloads' },
		{ file: 'SlideDeck.ppt', location: '/Users/sibeshkar/Maya/demo' },
		{ file: 'ApplicationDemo', location: '/Users/sibeshkar/Documents' },
	]);

	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.5, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['file', 'location'],
	};

	const fuse = new Fuse(allItems, options);

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return {
				show: thing.item.file,
				submit: thing.item.location + '/' + thing.item.file,
			};
		});
	};

	const onTextChange = (searchText) => {
		if (
			searchText &&
			_.findIndex(searchItems, function (o) {
				return o.show === searchText;
			}) === -1
		) {
			var results = fuse.search(searchText);
			setFilteredItems(results);
			setPlaceholderSearchItems(selectFunction(results));
		}
	};

	React.useEffect(() => {
		onTextChange(text);
	}, [text]);

	return filteredItems.map((contact, i) => {
		console.log(contact);
		return (
			<SearchItem
				selectedIndex={selectedIndex}
				key={i}
				i={i}
				searchItemMainText={contact.item.file}
				searchItemSubText={contact.item.location}
				reactIcon={<AiFillFile />}
			/>
		);
	});
};

export default FileTypeSearch;
