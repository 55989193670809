import fuzzysort from 'fuzzysort';
import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { store } from '../../../../../../redux/store';
import { nativePseudoBrain } from '../../../nativeCommands';

/**
 *
 * @param {string} value
 * @param {{
 *     label: string;
 *     id: string;
 *     hits: number;
 *     lastUsed: string;
 * }[]} searchableIntents
 * @param {import('../../../../../../redux/reducers/cardUIV2').CuiIntent[]} intents
 * @returns
 */
export const customSearchFn = (value, searchableIntents, intents) => {
	let sortedItems = {
		byId: {},
		allIds: [],
	};
	// If the only intents present are native commands, then prompt to install
	const results = fuzzysort.go(value, searchableIntents, {
		allowTypo: true,
		key: 'label',
	});
	const weightedResults = results.map((res) => {
		const { hits } = res.obj;
		const hitsWeight = 5;
		const weightedScore =
			res.score + hitsWeight * Math.log10(hits ? hits + 1 : 1);
		return { ...res, weightedScore };
	});
	const sortedResults = weightedResults.sort(
		(prev, next) => next.weightedScore - prev.weightedScore // Descending order
	);
	const selectedIntentIds = sortedResults.map((intent) => {
		return intent.obj.id;
	});

	selectedIntentIds.forEach((intentId) => {
		sortedItems.byId[intentId] = intents.byId[intentId];
		sortedItems.allIds.push(intentId);
	});

	searchableIntents.forEach(({ id }) => {
		if (!selectedIntentIds.includes(id)) {
			sortedItems.byId[id] = intents.byId[id];
			sortedItems.allIds.push(id);
		}
	});
	return sortedItems;
};

/**
 * Gets a sorted list of intents based on context/usage
 * @param {{
 * intents: import('../../../../../../redux/reducers/cardUIV2').CuiIntent[],
 *	components: import('../../../../../../redux/reducers/cardUIV2').CuiIntentComponent[],
 *	commandHistoryLogs: import('../../../../../../redux/reducers/types/commandHistoryReducer').CommandHistoryLogObject[],
 *	updateAvailable: boolean,
 *	brains: any,
 * }} param0
 * @returns
 */
export const getSmartSuggestIntentsFn = ({
	intents,
	components,
	commandHistoryLogs,
	updateAvailable,
	brains,
}) => {
	/**
	 * @type {import('../../../../../../redux/reducers/cardUIV2').default['commandBar']['suggestionsArea']['searchItems']}
	 */
	let sortedItems = {
		byId: {},
		allIds: [],
	};
	try {
		const searchableIntents = intents.allIds
			.filter(
				/** Get only active(started) brain's intents */
				(intentId) => intents.byId[intentId].brainStatus === 'STARTED'
			)
			.map((intentId) => {
				const intentLabel = intents.byId[intentId].components
					.map((componentId) => components.byId[componentId].value)
					.join(' ');
				/**
				 * Maximum number of recent logs from command history
				 * to be used for recommendation bias
				 */
				const MAX_RECENT_LOGS_FOR_RECOMMENDATION = 100;
				const allIntentLogs = commandHistoryLogs
					.slice(-MAX_RECENT_LOGS_FOR_RECOMMENDATION)
					.filter(
						(logObj) =>
							logObj.intentId === intentId && logObj.status === 'start'
					);
				return {
					label: intentLabel,
					id: intentId,
					hits: allIntentLogs.length ? allIntentLogs.length : 0,
					lastUsed: allIntentLogs[allIntentLogs.length - 1]
						? allIntentLogs[allIntentLogs.length - 1].timestamp
						: null,
				};
			});
		const lastUsedIntents = searchableIntents.sort(function (prev, next) {
			const date1 = new Date(prev.lastUsed);
			const date2 = new Date(next.lastUsed);
			return date2 - date1;
		});
		const startIntents = intents.allIds.filter(
			(intentId) => intents.byId[intentId].brainStatus === 'STARTED'
		);
		if (updateAvailable) {
			/**
			 * If update is available, prompt user to search for it
			 */
			sortedItems = customSearchFn('Maya Upd', lastUsedIntents, intents);
			return sortedItems;
		}

		if (
			brains.allIds.filter(
				(brainId) =>
					brains.byId[brainId].status === 'STARTED' &&
					brains.byId[brainId]._id !== 'nativePseudoBrain'
			).length === 0
		) {
			sortedItems = customSearchFn('Sto', lastUsedIntents, intents);
			return sortedItems;
		}

		if (startIntents.length === nativePseudoBrain.intents.length) {
			/**
			 * If no commands have been installed
			 */
			sortedItems = customSearchFn('Maya st', lastUsedIntents, intents);
		} else {
			lastUsedIntents.forEach(({ id }) => {
				sortedItems.byId[id] = intents.byId[id];
				sortedItems.allIds.push(id);
			});
		}
	} catch (error) {
		console.error(error);
	}
	return sortedItems;
};

/**
 * useSmartSuggest figures out what results to show the user on
 * cold start - when nothing has been typed. This could be based on
 * recent, relevant, favorites, and could later be based on context
 * of what's visible on the screen also.
 * @returns
 */
const useSmartSuggest = () => {
	let { intents, components, cards } = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities
	);
	const { brains } = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state
	);
	const commandHistoryLogs = useSelector(
		/**
		 * @param {{commandHistory: import('../../../../../../redux/reducers/types/commandHistoryReducer').CommandHistoryState}} state
		 */
		(state) => state.commandHistory.log
	);

	const updateAvailable = useSelector((state) => state.update.updateAvailable);

	/**
	 * Gets a sorted list of intents based on context/usage
	 */
	const getSmartSuggestIntents = useCallback(() => {
		return getSmartSuggestIntentsFn({
			intents,
			components,
			commandHistoryLogs,
			updateAvailable,
			brains,
		});
	}, [brains, commandHistoryLogs, components, intents, updateAvailable]);

	return { getSmartSuggestIntents };
};

export default useSmartSuggest;
