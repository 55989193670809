// Import modules
import History from './History';
//redux
import { store } from '../../redux/store';

const HistoryWithSlug = {};

HistoryWithSlug.push = (route) => {
	var { profiles } = store.getState();
	var { selected } = profiles;
	if (route.includes('store')) {
		History.push(route);
	} else {
		if (selected.isTeam) {
			History.push('/' + selected.profileSlug + route);
		} else {
			History.push(route);
		}
	}
};

HistoryWithSlug.replace = (route) => {
	var { profiles } = store.getState();
	var { selected } = profiles;
	if (route.includes('store')) {
		History.replace(route);
	} else {
		if (selected.isTeam) {
			History.replace('/' + selected.profileSlug + route);
		} else {
			History.replace(route);
		}
	}
};

export default HistoryWithSlug;
