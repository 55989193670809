// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';
import axios from 'axios';
import config from '../../config/config';

// config
// import { config } from '../../config';

import { store } from '../../redux/store';

const CMS_API_ROOT = config.app.CMS_BACKEND;

async function getInstallerFlow(mayaJson) {
	let request = {
		method: 'POST',
		url: `${CMS_API_ROOT}/skillpacks/getFlow`,
		data: mayaJson,
	};

	try {
		const response = await axios(request);
		/**
		 * @type {import('./getInstallerFlow').GetInstallerFlowResponseData}
		 */
		const flowJson = response.data;
		return flowJson;
	} catch (e) {
		console.log('Error fetching packlist:', e);
		return null;
	}
}

export default getInstallerFlow;
