import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { Box, Image, useColorMode } from '@chakra-ui/react';
import logo from '../../assets/header/dark_icon.svg';

const Header = styled.div`
	display: flex;
	z-index: 2000;
	flex-grow: 0;
	flex-shrink: 0;
	color: #656464;
	font-family: Lato;
	justify-content: center;
	align-items: center;
`;

export const HeaderSection = () => {
	const { colorMode } = useColorMode();

	const fontColor = { light: 'maya_light.400', dark: 'white' };
	return (
		<Header>
			<Box
				width="45rem"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				height="3.5rem"
			>
				<Box
					display="flex"
					fontFamily="body"
					fontSize="1.5rem"
					fontWeight="700"
					alignItems="center"
					color={fontColor[colorMode]}
				>
					<Image w="140px" h="40px" src={logo} alt="Maya Logo" mr="2" />
				</Box>
				<Box
					display="flex"
					fontFamily="body"
					fontSize="0.85rem"
					fontWeight="700"
					color={fontColor[colorMode]}
				>
					<Link style={{ textDecoration: 'none' }} to={'/login'}>
						<Box paddingX="0.5rem">Login</Box>
					</Link>
				</Box>
			</Box>
		</Header>
	);
};
