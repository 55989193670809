import isElectron from 'is-electron';

import IPCFunction from '../../../util/IPCFetch/function';
import IPCRoutes from '../../../util/IPCRoutes';

const getAppVersion = async () => {
	if (isElectron()) {
		var getAppVersionIPCFn, getAppVersionIPCResponse;
		getAppVersionIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getAppVersion.EVENT,
		});
		getAppVersionIPCResponse = await getAppVersionIPCFn();
		return getAppVersionIPCResponse;
	} else {
		return process.env.npm_package_version;
	}
};

export default getAppVersion;
