import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
import isElectron from 'is-electron';
/**
 * Function to delete skill pack from library
 */
const deleteLibrarySkillPack = async (formData) => {
	if (isElectron()) {
		// TODO: check for electron
		const deleteFromLibraryAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.deleteFromLibrary.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.deleteFromLibrary.PATH_METHOD,
		});

		const deleteFromLibraryResponse = await deleteFromLibraryAPIFn({
			profileSlug: formData.profileSlug,
			skillPackId: formData.skillPack._id,
		});

		const deleteSkillPackFromBrainsIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.deleteSkillPackFromBrains.EVENT,
		});

		const deleteSkillPackFromBrainsResponse =
			await deleteSkillPackFromBrainsIPCFn(formData);

		return deleteSkillPackFromBrainsResponse;
	}
};

export default deleteLibrarySkillPack;
