import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import { store } from '../../redux/store';
/**
 * Function to Check if profile slug exists
 */
const checkSlug = async ({ slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	var checkSlugFn;

	checkSlugFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.checkSlug.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.checkSlug.PATH_METHOD,
		PATH_QUERY: profileSlug,
	});

	var checkSlugResponse = await checkSlugFn();
	if (checkSlugResponse['data']['error']) {
		return checkSlugResponse['data'];
	} else {
		return !checkSlugResponse['data']['slugAvailable'];
	}
};

export default checkSlug;
