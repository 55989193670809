import React from 'react';
import { Drawer } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 *
 * @param {import('@chakra-ui/react').DrawerProps } param0
 * @returns
 */
const CustomDrawer = ({ isOpen, ...rest }) => {
	const isSupportWidgetOpen = useSelector(
		(state) => state.supportWidget.widgetIsOpen
	);

	return (
		<Drawer
			isOpen={isOpen}
			trapFocus={isOpen && isSupportWidgetOpen ? false : true}
			{...rest}
		/>
	);
};

export default CustomDrawer;
