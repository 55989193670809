// import APIFunction from "../../util/APIFetch/function";
// import APIRoutes from "../../util/APIRoutes";

// // config
// import { config } from "../../config";
// /**
//  * Function to get library internal flows
//  */
// const getLibraryItems = () => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => {
//       resolve([
//         {
//           _id: "112121",
//           name: "Twitter Template",
//           latestVersion: "5.6",
//           thumbnail:
//             "https://maya-frontend-static.s3.ap-south-1.amazonaws.com/static-1608922784458.jpg",
//           versions: [
//             { id: "5.6", flow: {} },
//             { id: "5.8", flow: {} },
//           ],
//         },
//       ]);
//     }, 1000);
//   });
// };

// export default getLibraryItems;

// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */
const getLibraryItems = async ({ slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	var getLibraryItemsFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getLibraryItems.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getLibraryItems.PATH_METHOD,
		URL_PARAM: {
			profileSlug: profileSlug,
		},
	});

	var getLibraryItemsResponse = await getLibraryItemsFn();

	return getLibraryItemsResponse['data']['library'];
};

export default getLibraryItems;
