/**
 * Task Actions
 *
 * Action creators for Task actions.
 */
import actionTypes from '../actionTypes';

/**
 *
 * Add a new task
 * @param {import('../reducers/types/commandHistoryReducer').TaskObject['taskId']} taskId
 * @param {import('../reducers/types/commandHistoryReducer').TaskObject} task
 * @returns
 */
export const addTask = (taskId, task) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.ADD_TASK,
		payload: {
			taskId: taskId,
			task: task,
		},
	});
};

/**
 *
 * Update Task
 * @param {import('../reducers/types/commandHistoryReducer').TaskObject['taskId']} taskId
 * @param {import('../reducers/types/commandHistoryReducer').TaskObject} task
 * @returns
 */
export const updateTask = (taskId, task) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.UPDATE_TASK,
		payload: {
			taskId: taskId,
			task: task,
		},
	});
};
