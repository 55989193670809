import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Icon } from '@chakra-ui/react';
import { FcGoogle } from 'react-icons/fc';
import { fusionAuthGoogleLogin, registerGoogleLoginUser } from './Service';
import useQueryParams from '../../../../util/useQueryParams';

const LoginWithGoogleButton = ({ onClick, handleFusionAuthLoginResponse }) => {
	const queryParams = useQueryParams();
	const autoLoginWithGoogle = queryParams.get('autoLoginWithGoogle');
	const desktopRedirect = queryParams.get('desktopRedirect');
	const googleSigningIn = queryParams.get('googleSigningIn');
	const [googleAuth2, setGoogleAuth2] = useState(null);
	const [isLoading, setIsLoading] = useState(googleSigningIn === 'true');

	const handleLoginInBtnClick = useCallback(
		async (e) => {
			try {
				setIsLoading(true);
				onClick();
				if (!googleAuth2) throw new Error(`googleAuth2 invalid!`);
				await googleAuth2.signIn(); // sign in
			} catch (error) {
				console.error(error);
				setIsLoading(false);
			}
		},
		[googleAuth2]
	);

	useEffect(() => {
		// This useEffect inits the Google auth2 instance/client
		window.gapi.load('auth2', () => {
			// Retrieve the singleton for the GoogleAuth library and set up the client.
			const redirect_uri = `${
				window.location.origin
			}/login?googleSigningIn=true&desktopRedirect=${
				desktopRedirect ? desktopRedirect : 'false'
			}`;
			const auth2 = window.gapi.auth2.init({
				// ref: https://developers.google.com/identity/sign-in/web/reference#gapiauth2clientconfig
				client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
				// cookiepolicy: 'single_host_origin',
				ux_mode: 'redirect',
				redirect_uri,
				// Request scopes in addition to 'profile' and 'email'
				scope: 'profile email openid',
			});
			setGoogleAuth2(auth2);
		});
	}, [desktopRedirect]);

	useEffect(() => {
		// This useEffect sets up the listener for user
		if (googleAuth2) {
			googleAuth2.currentUser.listen(async (user) => {
				try {
					if (user && user.isSignedIn()) {
						setIsLoading(true);
						// if user is signed in
						// ref: https://developers.google.com/identity/sign-in/web/reference#googleusergetauthresponseincludeauthorizationdata
						const authResponse = user.getAuthResponse(true);
						if (!authResponse?.id_token)
							throw new Error(`id_token invalid!`);
						const faglRes = await fusionAuthGoogleLogin({
							idToken: authResponse.id_token,
						});
						if (!faglRes.data.user)
							throw new Error(`faglRes.data.user invalid!`);
						const firstName = user.getBasicProfile().getName();
						let res;
						try {
							res = await registerGoogleLoginUser({
								user: faglRes.data.user,
								firstName,
							});
							await handleFusionAuthLoginResponse(faglRes);
						} catch (error) {
							if (error?.status === 400) {
								// axios 400 error handler
								await handleFusionAuthLoginResponse(faglRes);
							}
						}
					}
				} catch (error) {
					console.error(error);
				} finally {
					setIsLoading(false);
				}
			});
		}
	}, [googleAuth2]);

	useEffect(() => {
		/** Enable auto loggin in if query param is set */
		if (autoLoginWithGoogle) handleLoginInBtnClick();
	}, [autoLoginWithGoogle, handleLoginInBtnClick]);

	return (
		<Button
			size="md"
			marginTop="0.5rem"
			onClick={handleLoginInBtnClick}
			// isLoading={isLoggingIn}
			color="#525252"
			fontSize="0.8rem"
			bg="white"
			_hover={{
				bg: 'gray.200',
			}}
			_active={{
				bg: 'gray.100',
			}}
			leftIcon={<Icon as={FcGoogle} boxSize="6" />}
			isLoading={isLoading}
			loadingText="Please wait..."
		>
			Login with Google
		</Button>
	);
};

export default LoginWithGoogleButton;
