import React from 'react';
import {
	Box,
	useDisclosure,
	useColorMode,
	useColorModeValue,
	Modal,
	ModalOverlay,
	ModalCloseButton,
	ModalContent,
	ModalBody,
	Image,
	LightMode,
	Button,
	Textarea,
	EditableInput,
	Editable,
	EditablePreview,
	useToast,
} from '@chakra-ui/react';
import { FiLayers } from 'react-icons/fi';
import isElectron from 'is-electron';
import _ from 'lodash';
//Components
import HorizontalBox from '../Reusable/HorizontalBox';
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import IconContainer from '../../util/React_Icon';
import { NewItem, ItemCard, ItemCardSkeleton } from '../Reusable/HorizontalBox';
import InputComponent from '../Reusable/Input';
import Dropdown from '../Reusable/Dropdown';

//Functions
import addToLibraryFromBrain from '../../functions/library/addToLibraryFromBrain';
import updateLibrarySkillPack from '../../functions/library/updateLibrarySkillPack';
import getBrainById from '../../functions/brain/getBrainById';

import GetProfileSlug from '../../util/ProfileSlug';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

const AddOrUpdateModal = ({
	onClose,
	isOpen,
	mode,
	skillPack,
	currentBrainId,
	packs,
	setPacks,
}) => {
	const [versions, setVersions] = React.useState([
		{ label: 'New', value: 'new' },
	]);
	const [formData, setFormData] = React.useState({});
	const [selectedVersion, selectVersion] = React.useState('new');
	const { slug } = GetProfileSlug();
	const [isLoading, setIsLoading] = React.useState(false);
	const toast = useToast();

	console.log(formData);

	React.useEffect(() => {
		if (skillPack.versions) {
			var newArray = [{ label: 'New', value: 'new' }];

			newArray = newArray.concat(
				skillPack.versions.map((version) => {
					return { label: `${version.version}`, value: version.version };
				})
			);

			setVersions(newArray);
		}
	}, [skillPack]);

	React.useEffect(() => {
		if (mode === 'new') {
			selectVersion('new');
			getBrainById({ brainId: currentBrainId }).then((res) => {
				setFormData({
					...formData,
					name: res.name,
					brain: res,
				});
			});
		} else if (mode === 'update' && selectedVersion === 'new') {
		}
	}, [mode, currentBrainId, selectedVersion]);

	const handleSubmit = async () => {
		if (mode === 'new') {
			setIsLoading(true);
			console.log('FORM:', formData);
			const res = await addToLibraryFromBrain({
				...formData,
				version: formData.version ? formData.version : 'v0.0.1',
				slug: slug,
			});
			if (res.addSkillPacktoBrainResponse.data.error) {
				toast({
					title: 'Error!',
					description: "Couldn't add skillPack to library",
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Success!',
					description: 'SkillPack added to Library',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				setPacks([...packs, res.addToLibraryResponse.data.skillPack]);
			}
			setIsLoading(false);
			onClose();
		} else if (mode === 'update') {
			setIsLoading(true);
			const res = await updateLibrarySkillPack({
				...formData,
				skillPackId: skillPack._id,
				slug: slug,
			});
			if (res.updateSkillPackVersionResponse.data.error) {
				toast({
					title: 'Error!',
					description: "Couldn't update skillPack in library",
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Success!',
					description: 'SkillPack updated in Library',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				let temp = packs;
				const pos = packs.map((sp) => sp._id).indexOf(skillPack._id);
				temp[pos] = res.updateLibrarySkillPackResponse.data.skillPack;
				setPacks(temp);
				console.log('packs', packs);
				//console.log(temp);
			}
			setIsLoading(false);
			onClose();
		}
	};

	return (
		<CustomModal
			onClose={onClose}
			isOpen={isOpen}
			isCentered={isElectron() ? true : false}
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				height={isElectron() ? '24.5rem' : '21rem'}
				maxWidth="35rem"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody paddingX="0">
					{mode === 'new' ? (
						<HeaderWithDescription
							header={'Add New'}
							desc={
								'Save the current flow in the workspace as a reusable template.'
							}
							paddingX="1rem"
						/>
					) : null}
					{mode === 'update' ? (
						<HeaderWithDescription
							header={'Update Item'}
							desc={
								'Use the current flow in this brain workspace to update an existing collection.'
							}
							paddingX="1rem"
						/>
					) : null}
					<Box
						paddingX="1rem"
						display="flex"
						flexDirection="rows"
						marginTop="1rem"
					>
						<Box
							fontSize="1.3rem"
							fontWeight="600"
							marginRight="0.5rem"
							width="16rem"
						>
							<Editable
								defaultValue={
									mode === 'update'
										? skillPack.name
										: formData.brain
										? formData.brain.name
										: 'Click to add title'
								}
								fontSize="1.5rem"
								color={useColorModeValue('gray.600', 'white')}
								fontWeight="600"
								onSubmit={(e) => {
									setFormData({ ...formData, name: e });
								}}
								focusBorderColor="purple.500"
							>
								<EditablePreview />
								<EditableInput />
							</Editable>
							<Textarea
								placeholder="Add a description of what this flow does"
								focusBorderColor="purple.500"
								boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
								borderRadius="5px"
								borderColor={useColorModeValue(
									'dashboard.border_light',
									'dashboard.border_dark'
								)}
								transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
								marginTop="0.5rem"
								width="15rem"
								fontSize="0.8rem"
								resize={'none'}
								onChange={(e) => {
									setFormData({
										...formData,
										description: e.currentTarget.value,
									});
								}}
							/>
							<Box
								display="flex"
								flexDirection="rows"
								alignItems="center"
								width="15rem"
							>
								<Dropdown
									width="5rem"
									marginY="0.5rem"
									selectitem={(e) => selectVersion(e.value)}
									items={versions}
								/>
								{selectedVersion === 'new' ? (
									<InputComponent
										width="10rem"
										marginLeft="0.5rem"
										height="2.2rem"
										fontSize="0.8rem"
										placeholder="type version e.g v0.0.1"
										onChange={(e) => {
											setFormData({
												...formData,
												version: e.currentTarget.value,
											});
										}}
									/>
								) : null}
							</Box>
						</Box>
						<Box width="50%">
							<Image
								src={
									skillPack.thumbnail || formData.brain
										? formData.brain.thumbnail
											? formData.brain.thumbnail
											: 'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
										: 'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
								}
								width="99%"
								height="96%"
								borderRadius="5px 5px 5px 5px"
								objectFit="cover"
							/>
						</Box>
					</Box>
					<Box
						paddingX="1rem"
						borderTop="solid 1px"
						borderColor={useColorModeValue(
							'dashboard.border_light',
							'dashboard.border_dark'
						)}
						height="3.5rem"
						marginTop="1rem"
						alignItems="center"
						justifyContent="flex-end"
						display="flex"
					>
						<LightMode>
							<Button
								colorScheme="purple"
								size="xs"
								mr="2"
								borderRadius="5px"
								variant={'solid'}
								paddingX="0.9rem"
								onClick={handleSubmit}
								isLoading={isLoading}
								loadingText={
									mode === 'update' ? 'Updating...' : 'Adding...'
								}
							>
								{mode === 'update' ? 'Update' : 'Add Item'}
							</Button>
						</LightMode>
					</Box>
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

const AddNewOrUpdate = ({ currentBrainId, packs, setPacks, isLoading }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { colorMode } = useColorMode();
	const [mode, setMode] = React.useState('new');

	const loadingList = [1, 2, 3, 4];

	const [selectedSkillPack, selectSkillPack] = React.useState({});
	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};

	return (
		<Box maxWidth="1540px">
			<HeaderWithDescription
				header={'Select Option'}
				desc={
					'Choose if you want to add a new item, or click on an existing one to update it.'
				}
			/>
			<HorizontalBox>
				<NewItem
					onClick={() => {
						setMode('new');
						onOpen();
						//console.log(id);
					}}
					icon={
						<IconContainer
							icon={<FiLayers />}
							value={{
								color: color[colorMode],
								className: 'global-class-name',
								size: '2rem',
							}}
						/>
					}
					text={'+ Add New'}
				/>
				{isLoading
					? loadingList.map((item) => {
							return <ItemCardSkeleton key={item} />;
					  })
					: packs.map((p) => {
							return (
								<ItemCard
									key={p._id}
									thumbnail={
										p.thumbnail ||
										'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
									}
									leftText={p.name}
									rightText={
										p.currentVersion ? `${p.currentVersion}` : null
									}
									onClick={() => {
										setMode('update');
										onOpen();
										selectSkillPack(p);
									}}
								/>
							);
					  })}
			</HorizontalBox>
			<AddOrUpdateModal
				onClose={onClose}
				isOpen={isOpen}
				mode={mode}
				skillPack={selectedSkillPack}
				packs={packs}
				setPacks={setPacks}
				currentBrainId={currentBrainId}
			/>
		</Box>
	);
};

export default AddNewOrUpdate;
