import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Heading, Input, useToast, Tooltip, Link } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
import IPCFetch from '../../../util/IPCFetch';
import SetupWizardContext from '../SetupWizardContext';
import ActionFooter from '../ActionFooter';
import registerDevice from '../../../functions/devices/registerDevice';
import isElectron from 'is-electron';
import { setupWizardStages } from '../Config';
import getSkillPackList from '../../../functions/skillPack/getSkillPackList';
import { ModuleItem } from './ModuleItemSW';
import GetProfileSlug from '../../../util/ProfileSlug';
import getConfigProfiles from '../../../functions/configuration/getConfigProfiles';
import installPublishedSkillPackToBrain from '../../../functions/publishedSkillPack/installPublishedSkillPackToBrain';
import analytics from '../../../util/Analytics';
import { updateBrainById } from '../../../redux/actions';
import addToLibraryFromStore from '../../../functions/library/addToLibraryFromStore';
import getPublishedSkillpackGquery from '../../../functions/publishedSkillPack/getPublishedSkillpackGquery';
// import selectedPublishedSkillPacks from "../selectedPublishedSkillPacks.json"
import HorizontalItemSkeleton from './HorizontalItemSkeleton';
import ProgressCarousel from '../ProgressCarousel';
import { store } from '../../../redux/store';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';
import _ from 'lodash';

/**
 * @type {import("../SkillPack").SkillPack[]}
 */
const skillPacksInit = [];

/**
 * @type {{
 *  message: string,
 *  progressPercent: number,
 * }}
 */
const installingObjInit = {
	message: '',
	progressPercent: 0,
};

/**
 * Module Configuration
 */
const ModuleConfiguration = () => {
	const toast = useToast();
	const {
		currentStage,
		continueBtnRef,
		setStage,
		selectedPublishedSkillPacks,
		selectedBrain,
	} = useContext(SetupWizardContext);
	const { feature } = useContext(AnalyticsContext);
	// stores dependency module docs from mongo
	const [depModules, setDepModules] = useState([]);

	const [installingObj, setInstallingObj] = useState({ ...installingObjInit });
	const [modulesLoading, setModulesLoading] = useState(false);
	// stores profile config data
	const [moduleList, setModuleList] = React.useState({ allIds: [], byId: {} });

	const { slug } = GetProfileSlug();

	const countModuleConfigsRequired = useCallback(() => {
		let count = 0;
		console.log('counting moduleList total', moduleList);
		if (moduleList.allIds.length === 0) {
			return 0;
		}
		for (let id of moduleList.allIds) {
			let obj = moduleList.byId[id];
			console.log('counting moduleList', id, obj);
			if (obj.configurationRequired && !obj.referenceId) {
				count = count + 1;
			}
		}
		return count;
	}, [moduleList]);

	const setModuleListUtil = async (mList) => {
		let mods = { allIds: [], byId: {} };
		for (let mod of mList) {
			const moduleVersionDetails = mod.module;
			if (!mods.allIds.includes(mod.module._id)) {
				mods.allIds.push(mod.module._id);
				if (moduleVersionDetails.configurationType) {
					let res = await getConfigProfiles({
						slug: slug,
						moduleId: mod.module._id,
						brainId: selectedBrain,
					});
					mods.byId[mod.module._id] = {
						installed: false,
						configurationRequired: true,
						referenceId: res.length > 0 ? res[0].referenceId : null,
						profileName: res.length > 0 ? res[0].name : '',
						profileList: res,
					};
				} else {
					mods.byId[mod.module._id] = {
						installed: false,
						configurationRequired: false,
						referenceId: null,
						profileName: '',
						profileList: [],
					};
				}
			}
		}
		mods.allIds.sort((a) => (!mods.byId[a].configurationRequired ? 1 : -1));
		return mods;
	};

	const handleContinue = async () => {
		try {
			if (continueBtnRef.current) continueBtnRef.current.disabled = true; // disable btn (prevent multiple clicks)
			for (
				let publishedSkillPackIdx = 0;
				publishedSkillPackIdx < selectedPublishedSkillPacks.length;
				publishedSkillPackIdx++
			) {
				const installStartTime = window.performance.now();
				const currentSkillPackVersionObj =
					selectedPublishedSkillPacks[publishedSkillPackIdx];
				if (!currentSkillPackVersionObj)
					throw new Error(
						`currentSkillPackVersionObj not found! publishedSkillPackIdx: ${publishedSkillPackIdx}`
					);
				const { modules } = currentSkillPackVersionObj.currentFlow;
				if (!(modules.length > 0))
					throw new Error(
						`modules invalid! publishedSkillPackIdx: ${publishedSkillPackIdx}`
					);
				const formData = {
					SkillPackToInstall: null,
					PublishedSkillPackToInstall: currentSkillPackVersionObj,
					moduleRefs: moduleList.byId,
					// version: currentPublishedVersion,
					brainId: selectedBrain,
					profileSlug: slug,
					thumbnail: currentSkillPackVersionObj.thumbnail,
				};
				setInstallingObj((old) => ({
					...old,
					message: `Installing ${currentSkillPackVersionObj.name} (${
						publishedSkillPackIdx + 1
					} of ${selectedPublishedSkillPacks.length})`,
				}));
				const res = await installPublishedSkillPackToBrain(formData);
				if (res['error']) {
					toast({
						title: 'Error!',
						description: 'The collection could not be installed',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					analytics.track(`[${feature}] Collection Install`, {
						feature,
						skillPack: currentSkillPackVersionObj._id,
						skillPackName: currentSkillPackVersionObj.name,
						version: formData.version,
						brainId: selectedBrain,
						status: 'failure',
						error: res['error'],
					});
				} else {
					console.log('res.brain ', res.brain);
					store.dispatch(updateBrainById(selectedBrain, res.brain));
					analytics.track(`[${feature}] Collection Install`, {
						feature,
						skillPack: currentSkillPackVersionObj._id,
						skillPackName: currentSkillPackVersionObj.name,
						version: formData.version,
						brainId: selectedBrain,
						status: 'success',
					});
					// await addToLibraryFromStore({
					// 	publishedSkillPack: currentSkillPackVersionObj,
					// });
					setInstallingObj((old) => ({
						...old,
						message: `${currentSkillPackVersionObj.name} installed successfully!`,
						progressPercent:
							((publishedSkillPackIdx + 1) /
								selectedPublishedSkillPacks.length) *
							100,
					}));
				}
				const timeInMilliseconds =
					window.performance.now() - installStartTime;
				analytics.track(`[${feature}] Collection Install: Log Time Taken`, {
					feature,
					skillPack: currentSkillPackVersionObj._id,
					skillPackName: currentSkillPackVersionObj.name,
					version: formData.version,
					brainId: selectedBrain,
					timeInMilliseconds,
				});
			}
			toast({
				title: 'Installation successful!',
				description: 'Collections installed successfully!',
				status: 'success',
				duration: 3000,
				isClosable: false,
			});
			setStage(
				setupWizardStages.list[
					setupWizardStages.list.indexOf(currentStage) + 1
				]
			);
		} catch (error) {
			console.error(error);
			toast({
				title: 'Error Occurred.',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
			analytics.track(`[${feature}] Collection Install`, {
				feature,
				error,
				status: 'failure',
			});
		}
	};

	const getSkillPackListHandler = useCallback(async () => {
		try {
			setModulesLoading(true);
			/**
			 * @type {import("../SkillPack").SkillPack[]}
			 */
			const tmpSelectedPSPs = selectedPublishedSkillPacks;
			if (tmpSelectedPSPs.length === 0)
				throw new Error('skillPack List is empty!');
			let newRes = tmpSelectedPSPs.map(async (sp, index) => {
				let response;
				try {
					/**
					 * replace with a more complete `currentFlow` obj
					 * in selected Published Collections list object.
					 * Obtained using the G-query API.
					 */
					response = await getPublishedSkillpackGquery({ _id: sp._id });
					sp['currentFlow'] = {
						...sp['currentFlow'],
						...response.data['skillpacks'][0]['currentFlow'],
					};
					tmpSelectedPSPs[index] = sp;
					return true;
				} catch (err) {
					console.log('Error receiving data from server');
					toast({
						title: 'Error Occurred.',
						description: err.message,
						status: 'error',
						duration: 3000,
						isClosable: false,
					});
					tmpSelectedPSPs[index] = null;
					return false;
				}
			});
			/** Await all currentFlow objs to be populated */
			const allResponses = await Promise.all(newRes);
			/** Check if any response ret false (ie. Errored) */
			if (allResponses.some((x) => !x)) {
				throw new Error('Error obtaining required data from module api');
			}
			const mList = tmpSelectedPSPs.reduce((prev, curr, idx, arr) => {
				if (curr && curr?.currentFlow?.modules?.length) {
					const { modules } = curr?.currentFlow;
					const newModules = modules.filter(
						(m) => !prev.some((prm) => m.module._id === prm.module._id)
					);
					prev.push(...newModules);
				}
				return prev;
			}, []);
			setDepModules([...mList]);
			setModuleListUtil(mList).then((mods) => {
				setModuleList(mods); //moduleList
				console.log('@ moduleList', moduleList);
				setModulesLoading(false);
			});
		} catch (error) {
			console.error(error);
			toast({
				title: 'Error Occurred.',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
		}
	}, [selectedPublishedSkillPacks]);

	useEffect(() => {
		getSkillPackListHandler();
	}, [getSkillPackListHandler]);

	// continue button disable/enable
	useEffect(() => {
		console.log('contnue button toggle');
		if (continueBtnRef.current) {
			continueBtnRef.current.disabled = true;
			if (
				countModuleConfigsRequired() === 0 &&
				!modulesLoading &&
				selectedBrain
			) {
				continueBtnRef.current.disabled = false;
			}
		}
	}, [
		continueBtnRef,
		countModuleConfigsRequired,
		modulesLoading,
		selectedBrain,
	]);

	return (
		<Box
			display="flex"
			flexDirection="column"
			height="100%"
			zIndex="4"
			borderRadius="10px"
			// bg='green'
		>
			{installingObj.message ? (
				<Box
					flex="1"
					py="4"
					display="flex"
					flexDir="column"
					// bg='pink.300'
				>
					<ProgressCarousel
						messageText={installingObj.message}
						progressPercent={installingObj.progressPercent}
					/>
				</Box>
			) : (
				<>
					<Box
						flex="1"
						py="3"
						display="flex"
						flexDir="column"
						height="0"
						zIndex="4"
						borderRadius="30px"
						// bg='pink.300'
					>
						<Box as="h1" fontWeight="medium" fontSize="3xl">
							{setupWizardStages.byId[currentStage].label}
						</Box>
						<Box fontSize="1rem" mt="2" paddingBottom="1rem">
							{countModuleConfigsRequired()}{' '}
							{countModuleConfigsRequired() === 1 ? 'module' : 'modules'}{' '}
							to be configured.
							{countModuleConfigsRequired() === 0
								? ' Press the Continue button'
								: ' Press "Configure" to set up each module.'}{' '}
							<Tooltip
								label="A module is a group of related blocks
							that can be assembled together to make a Maya skill."
								aria-label="A tooltip"
								placement="right-end"
								cursor="pointer"
								fontSize="md"
							>
								<InfoIcon boxSize="13px" marginLeft="2px" mt="-1" />
							</Tooltip>
						</Box>
						<Box
							position="relative"
							flex="1 1 auto"
							overflow="auto"
							border="1px solid #535353"
							borderRadius="md"
							// bg='blue.400'
						>
							<Box
								display="flex"
								flexDir="column"
								width="100%"
								p="3"
								position="absolute"
								// bg='tomato'
							>
								{modulesLoading
									? selectedPublishedSkillPacks.map((psp) => (
											<HorizontalItemSkeleton
												key={`moduleSkeleton-${psp._id}`}
												width="100%"
											/>
									  ))
									: moduleList.allIds.length > 0
									? moduleList.allIds.map((id) => {
											const { module, version } = _.find(
												depModules,
												(obj) => {
													return obj.module._id === id;
												}
											);
											console.log(
												'ModuleItem: ',
												moduleList.byId[id],
												module
											);
											return (
												<ModuleItem
													key={module._id}
													module={module}
													selected={false}
													onClick={(moduleId) =>
														console.log(moduleId)
													}
													wholeItemActive={true}
													isItemExpanded={false}
													currentBrainId={selectedBrain}
													addButton={false}
													profilesButton={
														moduleList.byId[id]
															.configurationRequired
													}
													version={version}
													setModuleList={setModuleList}
													moduleList={moduleList}
													isModuleLoading={modulesLoading}
												/>
											);
									  })
									: null}
							</Box>
						</Box>
						{!modulesLoading ? (
							<Box
								fontWeight="medium"
								fontSize="1rem"
								mt="3"
								display="flex"
								flexDirection="row"
							>
								<span role="img">💡</span> Each Maya workflow is
								assembled by combining blocks from two or more modules.
							</Box>
						) : null}
						{modulesLoading ? (
							<Box fontSize="1rem" mt="2">
								Loading Modules...
							</Box>
						) : null}
					</Box>
					<ActionFooter
						continueBtnRef={continueBtnRef}
						currentSetupWizardStage={currentStage}
						onContinue={handleContinue}
						showBackButton
						onBack={() =>
							setStage(
								setupWizardStages.list[
									setupWizardStages.list.indexOf(currentStage) - 1
								]
							)
						}
					/>
				</>
			)}
		</Box>
	);
};

export default ModuleConfiguration;
