import React, { useEffect } from 'react';

import styled from '@emotion/styled';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import isElectron from 'is-electron';
import { Box, useColorMode, IconButton, useToast, Img } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';

import mandalaOne from '../assets/patterns/mandala_one.png';

// ACTIONS
import {
	setDashboardInfo,
	setLoading,
	setNavPageMode,
	setProfiles,
	setCurrentProfile,
	updateBrainById,
	setNavRoute,
	setTrialExpiryModalDisplay
} from '../redux/actions';

// Component logic abstaction
import getDashboardData from '../functions/dashboard/getDashboard';

// components
import SideBar from './Sidebar';
import NavSearch from './NavSearch';
import StartupLoader from './SetupWizard/StartupLoader';
import CheckOrRegisterDevice from './Device/CheckOrRegisterDevice';
import { SectionList } from './MainApp';

//Utils
import GetProfileSlug from '../util/ProfileSlug';
import { unSwitchableRoutes } from './Sidebar/ProfileButton/Profile';

// functions
import getUserProfiles from '../functions/profile/getUserProfiles';

// import RegisterDeviceComponent from "./Electron/RegisterDevice";

//Analytics
import analytics from '../util/Analytics';

//Store
import { store } from '../redux/store';
import { TrialExpiredModal } from './Payments/TrialExpiredModal';

export const Content = styled.div`
	display: flex;
	flex-grow: 1;
`;

export const CenterSection = styled.div`
	margin-top: 82px;
	flex-grow: 1;
	padding-left: 2rem;
	padding-right: 2rem;
	display: flex;
	flex-direction: column;
	flex: 1 1 auto;
	min-height: 0;
`;

const Dashboard = ({
	user,
	children,
	status,
	setLoading,
	showTrialExpiredModal,
	setTrialExpiryModalDisplay,
	setDashboardInfo,
	setNavPageMode,
	mode,
	route,
	misc,
	pageMode,
	currentBrainId,
	deviceId,
	setProfiles,
	setCurrentProfile,
	isLoggedIn,
	section,
	setNavRoute,
}) => {
	const toast = useToast();

	let location = useLocation();

	useEffect(() => {
		if (mode !== 'menu') {
			setNavPageMode('dashboard');
		}
		// Update the document title using the browser API
	}, [mode, setNavPageMode]);

	if (isElectron()) {
		var electron = window.require('electron');
		var { ipcRenderer } = electron;
		ipcRenderer.removeAllListeners('updateDashboardData');
		ipcRenderer.on('updateDashboardData', (event, arg) => {
			setDashboardData(arg);
		});
	}

	const setDashboardData = () => {
		getDashboardData({ slug }).then(async (res) => {
			if (res['error']) {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'Error fetching brains for the profile',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			} else {
				//status = "offline"
				setLoading(false);
				console.log(res);
				setDashboardInfo(res);
			}
		});
	};

	/**
	 * Offline State Update
	 *
	 * useEffect hook to update results in Redux state for both offline and online state
	 *
	 */
	const { slug } = GetProfileSlug();
	useEffect(() => {
		setLoading(true);

		setDashboardData();
		if (user.info) {
			analytics.identify(user.info.id);
			analytics.setUserDetails(user.info.email, user.info.id);
		}
	}, [setDashboardInfo, setLoading, status, slug]);

	useEffect(() => {
		getUserProfiles().then((res) => {
			setProfiles(res);
			console.log('Profiles :', res);
			var selectedProfile;
			var itemIndex;
			if (slug) {
				itemIndex = _.findIndex(res, function (item) {
					return item.profileSlug === slug;
				});
				if (itemIndex !== -1) {
					selectedProfile = res[itemIndex];
				}
			} else {
				itemIndex = _.findIndex(res, function (item) {
					return item.isTeam === false;
				});
				selectedProfile = res[itemIndex];
			}

			if (selectedProfile && !location.pathname.includes('store/')) {
				console.log(unSwitchableRoutes, location.pathname);
				setCurrentProfile(selectedProfile);
			}
		});
	}, [slug]);

	//In Editor menu mode only, trigger section change
	useEffect(() => {
		if (section) {
			if (mode === 'menu') {
				setNavRoute(section);
			}
		}
	}, [section]);

	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'white',
		dark: 'maya_dark.300',
	};
	function ColorModeExample() {
		const { colorMode, toggleColorMode } = useColorMode();
		return (
			<IconButton
				onClick={toggleColorMode}
				position="absolute"
				bottom="0"
				right="0"
				icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
			>
				Toggle {colorMode === 'light' ? 'Dark' : 'Light'}{' '}
			</IconButton>
		);
	}

	return (
		<>
			<TrialExpiredModal
				isCentered
				isOpen={showTrialExpiredModal} 
				onClose={() => setTrialExpiryModalDisplay(false)}
			/>
			<Box
				flex-grow="1"
				display="flex"
				flex-direction="column"
				flex="1 1 auto"
				overflow="hidden"
				marginTop={mode === 'menu' || !isElectron() ? '0px' : '45px'}
				height={mode === 'menu' ? '100%' : undefined}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				bg={bgColor[colorMode]}
				position="relative"
				onClick={(e) => {
					let elem = document.elementFromPoint(e.clientX, e.clientY);
					analytics.track('User Clicked Dashboard', {
						element: elem.outerHTML,
					});
				}}
			>
				<Content>
					{isElectron() && mode !== 'menu' ? <StartupLoader /> : null}
					{/* {onlineStatus ? null : <OfflineRibbon />} */}
					{/* {isElectron() ? <CheckOrRegisterDevice /> : null} */}
					<SideBar mode={mode} />
					<Box
						marginLeft="225px"
						position="relative"
						flex="1 1 auto"
						display="flex"
						flexDirection="column"
						minHeight="0"
					>
						<Box
							// bg="aqua"
							display="flex"
							position="absolute"
							top="0"
							left="0"
							width="100%"
						>
							<NavSearch mode={mode} misc={misc} />
						</Box>
						<CenterSection>
							{mode === 'menu' &&
							SectionList[route.replace('/', '').replace('/', '')]
								? SectionList[route.replace('/', '').replace('/', '')](
										currentBrainId
								)
								: children}
						</CenterSection>
					</Box>
				</Content>
				{false && (
					<Img
						width={{ sm: '105vw', md: '95vw', lg: '45vw' }}
						height={{ sm: '105vw', md: '95vw', lg: '45vw' }}
						objectFit="cover"
						src={mandalaOne}
						position="absolute"
						opacity="0.07"
						left="0"
						right="0"
						bottom="0"
						textAlign="center"
						margin="auto"
						marginRight="-45vh"
						marginBottom={{ sm: '-35vh', md: '-55vh', lg: '-40vh' }}
						zIndex="1"
					/>
				)}
			</Box>
		</>
	);
};
// TODO : Add electron stuff
// {isElectron() ? <RegisterDeviceComponent /> : null}
// {isElectron() ? <SetupWizard /> : null}
//       {isElectron() ? null : <DownloadApp />}
// <ColorModeExample />

const mapStateToProps = (state) => {
	const { status } = state.user;
	const { user } = state;
	const { brains } = state.brains;
	const { route, pageMode, isLoggedIn } = state.navigation;
	const { device } = state.devices.current;
	const { showTrialExpiredModal } = state.dashboard
	const deviceId = status === 'online' ? (device ? device._id : null) : null;
	return {
		user,
		status,
		deviceId,
		route,
		pageMode,
		brains,
		isLoggedIn,
		showTrialExpiredModal,
	};
};

export default connect(mapStateToProps, {
	setDashboardInfo,
	setLoading,
	setNavPageMode,
	setProfiles,
	setCurrentProfile,
	setNavRoute,
	setTrialExpiryModalDisplay
})(Dashboard);
