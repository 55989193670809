import React from 'react';
import { Box } from '@chakra-ui/react';
import SelectBrain from '../../Brains/SelectBrain';
import HeaderWithDescription from '../../Reusable/HeaderWithDesc';
import { motion } from 'framer-motion';

const FromTemplate = () => {
	return (
		<motion.div
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			<HeaderWithDescription
				header={'Select Template'}
				desc={'Select a template from the library to proceed'}
			/>
			<HeaderWithDescription
				header={'Configure'}
				desc={'Set up & add the modules you need.'}
			/>
		</motion.div>
	);
};

export default FromTemplate;
