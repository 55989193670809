import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactFlow from 'react-flow-renderer';
import CustomNode from './CustomNode';
import CustomSwitchNode from './CustomSwitchNode';
import { parseNodeRedToReactFlow } from './flowParser';

const nodeTypes = {
	generic: CustomNode,
	switch: CustomSwitchNode,
};

const FlowViz = ({
	flowDefinition,
	onRender,
	onNodeMouseEnter,
	onNodeMouseLeave,
	onElementClick,
	background = 'none',
	height = 300,
	width = 300,
	scaleImage = 1,
	scalePosition = 1,
}) => {
	const reactFlowInstance = useRef(null);
	const reactFlowElements = useMemo(
		() => parseNodeRedToReactFlow(flowDefinition, scalePosition),
		[flowDefinition]
	);

	const renderFun = useCallback(async () => {
		try {
			if (onRender) await onRender();
		} catch (error) {
			console.error(error);
		}
	}, [onRender]);

	const handleReactFlowLoad = useCallback((params) => {
		reactFlowInstance.current = params;
	}, []);
	const gridBgStyle =
		background === 'grid'
			? {
					backgroundSize: '15px 15px',
					backgroundImage: `linear-gradient(to right, rgb(58 57 57) 2px, transparent 1px), linear-gradient(rgb(58 55 55) 2px, transparent 1px)`,
			  }
			: {};

	useEffect(() => {
		/**
		 * Using this useEffect with reactFlowInstance ref to fit the
		 * nodes after the onLoad is called. This fix is followed as per the solution
		 * stated in the issue here: https://github.com/wbkd/react-flow/issues/533
		 */
		if (reactFlowInstance.current) {
			reactFlowInstance.current.fitView(); // Fit Nodes to view
			const numOfElementsInFC =
				reactFlowInstance.current.getElements().length;
			if (numOfElementsInFC > 0) renderFun();
		}
	}, [reactFlowElements, reactFlowInstance, renderFun]);

	return (
		<div
			style={{
				width,
				height,
				...gridBgStyle,
			}}
		>
			<ReactFlow
				elements={reactFlowElements}
				onLoad={handleReactFlowLoad}
				nodeTypes={nodeTypes}
				onNodeMouseEnter={onNodeMouseEnter}
				onNodeMouseLeave={onNodeMouseLeave}
				onElementClick={onElementClick}
			/>
		</div>
	);
};

export default FlowViz;
