/**
 * Tab Reducer
 *
 * Creates a Redux reducer for populating the tabs.
 */

// Action Types
import actionTypes from '../actionTypes';

//Dependencies
import dotProp from 'dot-prop-immutable';
import _ from 'lodash';

// Setup initial state with an tab info object.
const initialState = {
	selected: 'dashboard',
	list: {
		byId: {
			dashboard: { typeOf: 'dash', path: '/' },
		},
		allIds: ['dashboard'],
	},
};

// Export the Tab Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_TAB: {
			const { info } = action.payload;
			if (!_.includes(dotProp.get(state, `list.allIds`), info.brainId)) {
				state = dotProp.set(state, `list.byId.${info.brainId}`, info);
				state = dotProp.set(state, `list.allIds`, (list) => [
					...list,
					info.brainId,
				]);
			}
			state = dotProp.set(state, `selected`, info.brainId);
			return state;
		}
		case actionTypes.SWITCH_TAB: {
			const { info } = action.payload;
			state = dotProp.set(state, `selected`, info);
			return state;
		}
		case actionTypes.CLOSE_TAB: {
			const { info } = action.payload;
			let index = _.indexOf(dotProp.get(state, 'list.allIds'), info);
			if (index !== -1) {
				state = dotProp.set(state, `selected`, 'dashboard');
				state = dotProp.delete(state, `list.allIds.${index}`);
				state = dotProp.delete(state, `list.byId.${info}`);
			}

			return state;
		}
		case actionTypes.CLEAR_TABS: {
			state = dotProp.set(state, `list.byId`, {
				dashboard: { typeOf: 'dash', path: '/' },
			});
			state = dotProp.set(state, `list.allIds`, ['dashboard']);

			return state;
		}
		default:
			return state;
	}
};
