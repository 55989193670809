import React from 'react';
import { Button, useColorModeValue, LightMode } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';

//Redux
import { addTab } from '../../../redux/actions';

//Components
import SelectBrain from '../../Brains/SelectBrain';

//Utils
import { HistoryWithSlug } from '../../../util/History';

//Analytics
import analytics from '../../../util/Analytics';

const FromScratch = ({ addTab, brains }) => {
	const [brainId, setBrainId] = React.useState('');
	const goToBrain = () => {
		addTab(brainId, brains.byId[brainId].name);
		HistoryWithSlug.push(`/edit?id=${brains.byId[brainId]._id}`);
		// window.location.reload();
	};
	return (
		<motion.div
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			<SelectBrain
				onSelectFn={(id) => {
					setBrainId(id);
				}}
				hideDefaultBrain={false}
			/>
			<LightMode>
				<Button
					marginTop="1rem"
					size="sm"
					paddingX="2rem"
					paddingY="0.2rem"
					borderRadius="5px"
					fontFamily="body"
					border="solid 1px"
					isDisabled={brainId === ''}
					borderColor={useColorModeValue(
						'dashboard.border_light',
						'dashboard.border_dark'
					)}
					colorScheme="purple"
					onClick={() => {
						goToBrain();
						analytics.track('New Skill From Scratch : Continue Button', {
							brainId,
						});
					}}
					boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
					loadingText={'Creating...'}
					_focus={{ outline: 'none' }}
				>
					Continue
				</Button>
			</LightMode>
		</motion.div>
	);
};

const mapStateToProps = (state) => {
	const { brains } = state;
	return { brains };
};

export default connect(mapStateToProps, { addTab })(FromScratch);
