import getDecodedAccessToken  from "../../functions/configuration/getDecodedAccessToken"
import GetProfileSlug from "../ProfileSlug"

// const DUMMY_TIME_REMAINING = 14 * 86400000
const DUMMY_TIME_REMAINING = -1

export async function getExpiryTime() {
    const { data: tokenData } = await getDecodedAccessToken()
    if (tokenData.error) {
        return -1
    }

    // Try to find right access data using the slug. If thats not
    // possible, find the first access data in the token's accessList.
    // This isn't good, but right now there won't be any teams I hope
    const { slug } = GetProfileSlug()
    const accessList = tokenData.results.access
    let accessData = accessList.find(ele => ele.slug === slug)
    if (!accessData && accessList.length > 0) {
        accessData = accessList[0]
    }

    if (!accessData || !accessData.exp) {
        throw new Error(`Access data not found for slug: ${slug}`)
    }

    return { 
        expiryTime: accessData.exp,
        trialDays: accessData.trialDays
    }
}

export async function getTimeToExpiry() {
    const { expiryTime } = await getExpiryTime()
    // return DUMMY_TIME_REMAINING
    return expiryTime  - Date.now()
}

export async function getTotalTrialDays() {
    const { trialDays } = await getExpiryTime()
    return trialDays
}