import isElectron from 'is-electron';

import IPCRoutes from '../../util/IPCRoutes';
import APIRoutes from '../../util/APIRoutes';
import IPCFetch from '../../util/IPCFetch/function';
import APIFetch from '../../util/APIFetch/function';

import { config } from '../../config';

import updateBrain from '../brain/updateBrain';
import { store } from '../../redux/store';

/**
 * This function call updates the redux state with new intents from
 * @param {} data
 * @returns
 */
const deploySkill = async (data) => {
	const { user } = store.getState();
	let profileSlug = data.profileSlug ? data.profileSlug : user.info.id;
	data.profileSlug = profileSlug;
	var deploySkillAPIFn, deploySkillIPCFn, deploySkillResponse;

	deploySkillIPCFn = IPCFetch({
		EVENT: IPCRoutes.ipcService.deploySkill.EVENT,
	});

	deploySkillAPIFn = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.deploySkills.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.deploySkills.PATH_METHOD,
	});

	if (isElectron()) {
		deploySkillResponse = await deploySkillIPCFn(data);
		if (!deploySkillResponse['data']['error']) {
			var toUpdateBrainObject =
				deploySkillResponse['data']['results']['update'];
			toUpdateBrainObject['_id'] =
				deploySkillResponse['data']['results']['brainId'];
			toUpdateBrainObject['profileSlug'] =
				deploySkillResponse['data']['results']['profileSlug'];
			toUpdateBrainObject['deviceId'] =
				deploySkillResponse['data']['results']['deviceId'];
			//console.log("deploySkill", toUpdateBrainObject);
			var updateBrainResponse = await updateBrain(toUpdateBrainObject);

			if (!updateBrainResponse['error'])
				return updateBrainResponse['results'];
		} else {
			return {
				data: {
					error: true,
				},
			};
		}
	} else {
		console.log('API Call should happen');
		deploySkillResponse = await deploySkillAPIFn(data);
		if (!deploySkillResponse['data']['error']) {
			return deploySkillResponse['data']['results'];
		} else {
			return {
				data: {
					error: true,
				},
			};
		}
	}
};

export default deploySkill;
