import React from 'react';
import { Box } from '@chakra-ui/react';

//Components

import MainSearchList from './MainSearchList';

const ExpandedSection = (props) => {
	return (
		<Box>
			<MainSearchList />
		</Box>
	);
};

export default ExpandedSection;
