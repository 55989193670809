import React from 'react';
import { Input, useColorModeValue } from '@chakra-ui/react';

const InputComponent = (props) => {
	const [value, setValue] = React.useState('');
	const [typing, setTyping] = React.useState(false);

	// Set default if it is present in props. on clicking it should disappear
	React.useEffect(() => {
		if (props.default && typing === false) {
			setValue(props.default);
			setTyping(false);
		} else {
			setTyping(true);
		}
	}, [props]);
	return (
		<Input
			{...props}
			focusBorderColor="purple.500"
			colorScheme="purple"
			size="sm"
			type="text"
			borderRadius="5px"
			boxSizing="border-box"
			borderColor={useColorModeValue(
				'dashboard.border_light',
				'dashboard.border_dark'
			)}
			onChange={(event) => {
				setValue(event.target.value);
				if (props.onChange) {
					props.onChange(event);
				}
			}}
			onClick={(event) => {
				if (value === props.default) {
					setValue('');
					setTyping(true);
				}
				if (props.onClick) {
					props.onClick(event);
				}
			}}
			value={value}
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
		/>
	);
};

export default InputComponent;
