import axios from 'axios'
import config from '../../config/config'

const CMS_API_ROOT = config.app.CMS_BACKEND

async function getSkillpackFlow(skillPackId) {
    const request = {
        method: 'GET',
        url: `${CMS_API_ROOT}/skillpacks/${skillPackId}`
    }

    try {
        const response = await axios(request)
        const flow = response.data.currentFlow
        return flow
    } catch (e) {
        console.log('Error fetching flow:', e)
        return {}
    }
}

export default getSkillpackFlow