import * as React from 'react';

import {
	Box,
	Button,
	useToast,
	LightMode,
	DarkMode,
	useDisclosure,
} from '@chakra-ui/react';
import { StorePackDetails } from '../Store/StorePack/StorePackDetails';

import publishSkillPack from '../../functions/publishedSkillPack/publishSkillPack';
import updatePublishedSkillPack from '../../functions/publishedSkillPack/updatePublishedSkillPack';

//components
import { ShareLinkModal } from './ShareLink';
/**
 *
 * @param {*} param0
 */
const SkillPackDetailsPage = ({
	nextStage,
	prevStage,
	flowDetails,
	setFlowDetails,
	updateID,
}) => {
	const [isLoading, setLoading] = React.useState(false);
	const [id, setId] = React.useState('');
	const toast = useToast();

	React.useEffect(() => {
		if (flowDetails.skillPackDetails) {
			setFlowDetails({
				...flowDetails,
				skillPackDetails: flowDetails.skillPackDetails,
			});
		} else {
			setFlowDetails({
				...flowDetails,
				skillPackDetails: null,
			});
		}
	}, []);

	React.useEffect(() => {
		console.log('setting id', id);
	}, [id]);

	const publishNewFlow = () => {
		setLoading(true);
		flowDetails['skillPackDetails'] = null;
		publishSkillPack(flowDetails).then((res) => {
			console.log('publishedSkillPack!!', console.log(res));
			setId(res.data._id);
			console.log(id);
			toast({
				title: 'Success!',
				description: res.data.message,
				status: 'success',
				duration: 3000,
				isClosable: false,
			});
			setLoading(false);
		});
	};

	const updateExistingFlow = () => {
		let formData = {
			_id: updateID,
			data: flowDetails,
		};
		setLoading(true);
		updatePublishedSkillPack(formData).then((res) => {
			console.log('updated pack: ', res);
			setId(updateID);
			console.log(updateID, id);
			toast({
				title: 'Success!',
				description: res.data.message,
				status: 'success',
				duration: 3000,
				isClosable: false,
			});
			setLoading(false);
		});
	};

	const handleClick = () => {
		if (updateID) {
			console.log('update', updateID);
			updateExistingFlow();
		} else {
			console.log('publish', updateID);
			publishNewFlow();
		}
	};

	return (
		<Box p={4} display="flex">
			<Box width="100%" height="80vh" overflow="hidden">
				{flowDetails ? (
					<StorePackDetails
						publishedSkillPack={flowDetails}
						skillPack={flowDetails.skillPackDetails}
						isPublish={true}
						showModuleCard={false}
					/>
				) : null}
			</Box>
			<Box display="flex" flexDirection="column">
				<LightMode>
					<Button
						colorScheme="green"
						size="sm"
						onClick={handleClick}
						mb={2}
						isLoading={isLoading}
						borderRadius="20px"
					>
						Publish Flow
					</Button>
					<Button
						colorScheme="purple"
						size="sm"
						onClick={prevStage}
						borderRadius="20px"
					>
						Back
					</Button>
				</LightMode>
				{id ? <ShareButton id={id} /> : null}
			</Box>
		</Box>
	);
};

const ShareButton = ({ id }) => {
	const { onClose, isOpen, onOpen } = useDisclosure();
	return (
		<DarkMode>
			<Button
				colorScheme="gray"
				size="sm"
				onClick={() => {
					console.log('id at button', id);
					onOpen();
				}}
				borderRadius="20px"
			>
				Share
			</Button>
			<ShareLinkModal
				onClose={onClose}
				isOpen={isOpen}
				onOpen={onOpen}
				_id={id}
			/>
		</DarkMode>
	);
};

export default SkillPackDetailsPage;
