/**
 * Module Actions
 *
 * Action creators for Module based actions.
 */

// Dependencies
// import { get } from "lodash";

// Action Types
import actionTypes from '../actionTypes';

/**
 * Add the Module Object
 *
 * Attempt to set the robot object with list of fleets containing user.
 *
 * @param {Object} robots The robots list obtained from the backend for a given fleet.
 */
const addModules = (modules, refresh) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.ADD_MODULES,
		payload: {
			info: modules,
			refresh: refresh || false,
		},
	});
};

/**
 * Update Module
 *
 * @param {Object}
 */
const updateModule = (module) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.UPDATE_MODULE,
		payload: {
			info: module,
		},
	});
};

/**
 * Update Module
 *
 * @param {Object}
 */
const updateModuleById = (id, module) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	console.log('Readying dispatch', id, module);
	dispatch({
		type: actionTypes.UPDATE_MODULE_BY_ID,
		payload: {
			info: module,
			id: id,
		},
	});
};

/**
 * Delete a Module Object
 *
 * Delete fleet robot given robotID.
 *
 * @param {Object} robotID The fleets list obtained from the backend.
 */
const removeModule = (moduleID) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.REMOVE_MODULE,
		payload: {
			info: moduleID,
		},
	});
};

/**
 * Clear Modules
 *
 * Clear modules.
 *
 */
const clearModules = () => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.CLEAR_MODULES,
		payload: {},
	});
};

// Export the actions.
export {
	addModules,
	removeModule,
	updateModule,
	updateModuleById,
	clearModules,
};
