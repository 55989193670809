import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

const HorizontalMenu = ({ list, mode }) => {
	return (
		<Box
			width="100%"
			borderBottom="solid 1px"
			borderColor={useColorModeValue('#f2f2f2', '#4a4949')}
			marginTop="0.5rem"
			display="flex"
			paddingLeft="0.5rem"
		>
			{list.map((item) => {
				return (
					<Box
						fontSize="16px"
						height="40px"
						width="8rem"
						display="flex"
						borderBottom="solid 1px"
						borderColor={
							mode === item.label ? 'purple.500' : 'transparent'
						}
						paddingX="0.3rem"
						justifyContent="center"
						marginLeft="0.5rem"
						cursor="pointer"
						transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
						onClick={item.onClick}
					>
						{item.label}
					</Box>
				);
			})}
		</Box>
	);
};

export default HorizontalMenu;
