import { useLocation } from 'react-router-dom';

/**
 * Custom hook to get query params from current url
 */
const useQueryParams = () => {
	return new URLSearchParams(useLocation().search);
};

export default useQueryParams;
