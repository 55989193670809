import actionTypes from '../actionTypes';
import { produce } from 'immer';
import { v4 as uuidv4 } from 'uuid';

/**
 *	Tab Change reusable redux event handler
 * @param {typeof initialState} draft
 * @param {import('./cardUIV2').CuiSearchType} tabName
 * @returns
 */
const handleTabChange = (draft, tabName) => {
	if (draft.commandBar.display === 'expanded') {
		draft.commandBar.searchArea.mode = 'search'; // Reset search mode to normal search
		draft.commandBar.suggestionsArea.mode = 'normal'; // Set mode to normal
		draft.commandBar.suggestionsArea.type = tabName; // set tab/searchType
		draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
		draft.commandBar.searchArea.loadingStatus = 'normal'; // Reset loading status
		/** set items based on the selected tab */
		switch (tabName) {
			/** currently only handle cards */
			case 'cards': {
				/** set all cards as items */
				const allCards = draft.entities.cards;
				draft.commandBar.suggestionsArea.searchItems = allCards;
				break;
			}
			default:
				break;
		}
	}
	return draft;
};

/**
 * @type {import('./cardUIV2').default}
 */
const initialState = {
	entities: {
		components: {
			allIds: [],
			byId: {},
		},
		intents: {
			allIds: [],
			byId: {},
		},
		cards: {
			allIds: [],
			byId: {},
		},
		storeSkills: {
			allIds: [],
			byId: {},
		},
		startupIntents: {
			allIds: [],
			byId: {},
		},
	},
	commandBar: {
		visible: false,
		display: 'expanded', // show suggestions by default
		commandBarStatus: {
			visible: false,
			status: 'success',
			message: '',
		},
		searchArea: {
			mainInputValue: '',
			mode: 'search',
			selectedPlaceholderComponent: '',
			placeholderComponents: {
				allIds: [],
				byId: {},
			},
			ifMultipleInputPlaceholders: false,
			loadingStatus: 'normal',
			placeholderLoading: false,
		},
		suggestionsArea: {
			mode: 'normal',
			type: 'intents',
			placeholderSearch: {
				selectedIndex: 0,
				searchItems: [],
			},
			selectedIndex: 0,
			selectedSearchItem: '',
			searchItems: {
				allIds: [],
				byId: {},
			},
			vibrateInstructions: false,
		},
	},
	cardWindow: {
		isVisible: false,
		isInteractionEnabled: true,
	},
};

const cardUiV2Reducer = produce(
	/**
	 *
	 * @param {typeof initialState} draft
	 * @param {{
	 * 	type: keyof typeof actionTypes,
	 * 	payload: unknown
	 * }} action
	 * @returns
	 */
	(draft, action) => {
		switch (action.type) {
			case actionTypes.CUIV2_SET_COMMANDBAR_VISIBLE: {
				/**
				 * @type {Boolean}
				 */
				const { visibility } = action.payload;
				// Change visibility
				draft.visible = visibility ? visibility : false;
				return draft;
			}
			case actionTypes.CUIV2_ENTITIES_UPSERT: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_ENTITIES_UPSERT']}
				 */
				const payload = action.payload;
				const existingEntities = draft.entities;
				/** Upsert new entities using shallow merging using spread operator  */
				draft.entities = {
					...existingEntities,
					...payload, // overwrite existing entities or create new if do not exist
				};
				return draft;
			}
			case actionTypes.CUIV2_SEARCH_CANCEL: {
				// load startup intents as suggestions
				draft.commandBar = {
					...initialState.commandBar,
					suggestionsArea: {
						...initialState.commandBar.suggestionsArea,
						searchItems: draft.entities.startupIntents,
					},
				};
				return draft;
			}
			case actionTypes.CUIV2_SEARCH_INPUT_KEYDOWN: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_SEARCH_INPUT_KEYDOWN']}
				 */
				const payload = action.payload;
				const { keyType } = payload;
				const { length: searchItemsLength } =
					draft.commandBar.suggestionsArea.searchItems.allIds;
				let { selectedIndex } = draft.commandBar.suggestionsArea;
				switch (keyType) {
					case 'UP': {
						if (selectedIndex === 0)
							selectedIndex = searchItemsLength - 1;
						else selectedIndex -= 1;
						draft.commandBar.suggestionsArea.selectedIndex =
							selectedIndex;
						break;
					}
					case 'DOWN': {
						if (selectedIndex === searchItemsLength - 1)
							selectedIndex = 0;
						else selectedIndex += 1;
						draft.commandBar.suggestionsArea.selectedIndex =
							selectedIndex;
						break;
					}
					case 'TAB_CYCLE': {
						handleTabChange(draft, payload.tabName);
						break;
					}
					case 'ENTER': {
						if (searchItemsLength > 0) {
							const { allIds: searchItemIds } =
								draft.commandBar.suggestionsArea.searchItems;
							const selectedSearchItem = searchItemIds[selectedIndex]; // calculate selected Item as Id of the item at `selectedIndex`
							draft.commandBar.suggestionsArea.selectedSearchItem =
								selectedSearchItem; // set the selected item to state
							draft.commandBar.suggestionsArea.searchItems = {
								// reset search items
								allIds: [],
								byId: {},
							};
							draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx
							draft.commandBar.display = 'expanded';
							/** Set logic based on `selected tab` or `suggestion items type` */
							if (draft.commandBar.suggestionsArea.type === 'intents') {
								// for intents
								draft.commandBar.searchArea.mode = 'query';
								draft.commandBar.searchArea.loadingStatus =
									'submitting';
								// Setup Initialization of Query Mode
								const intentPlaceholderComponentIds = // get ids of the components in the selected intent
									draft.entities.intents.byId[
										searchItemIds[selectedIndex]
									].components;
								const inputPlaceholderIds =
									intentPlaceholderComponentIds.filter(
										// get only the component ids that support input
										(compId) =>
											draft.entities.components.byId[compId].type !==
											'prompt'
									);
								const intentPlaceholderComponents =
									intentPlaceholderComponentIds.reduce(
										(prev, curr) => {
											if (
												draft.entities.components.allIds.includes(
													curr
												)
											) {
												prev.allIds.push(curr);
												prev.byId[curr] =
													draft.entities.components.byId[curr];
											}
											return prev;
										},
										{
											allIds: [],
											byId: {},
										}
									);
								const placeholderComponentValues =
									intentPlaceholderComponents.allIds.reduce(
										(prev, curr) => {
											prev.byId[curr] = {
												id: curr,
												inputValue: '',
												meta: '',
											};
											prev.allIds.push(curr);
											return prev;
										},
										{
											allIds: [],
											byId: {},
										}
									);
								// Write the newly calculated values to state
								draft.commandBar.searchArea.ifMultipleInputPlaceholders =
									inputPlaceholderIds.length > 1; // set if more than 1 input placeholders
								draft.commandBar.searchArea.placeholderComponents =
									placeholderComponentValues;
								// set the 1st input placeholder comp as selected placeholder comp
								draft.commandBar.searchArea.selectedPlaceholderComponent =
									inputPlaceholderIds && inputPlaceholderIds[0]
										? inputPlaceholderIds[0]
										: '';
							} else if (
								draft.commandBar.suggestionsArea.type === 'cards'
							) {
								// for cards
								draft.commandBar.suggestionsArea.mode = 'embeddedView'; // change mode to view card in embedded
								draft.commandBar.searchArea.mode = 'embeddedView'; // set search mode to embedded
								draft.commandBar.searchArea.loadingStatus =
									'submitting'; // set to submitting to show the close btn
							} else if (
								draft.commandBar.suggestionsArea.type === 'store'
							) {
								// for store
								draft.commandBar.searchArea.mode = 'storeSubmit'; // activate storeSubmit comp that fires the submit handler
							}
						}
						break;
					}
					case 'ESCAPE': {
						draft.commandBar = {
							...initialState.commandBar,
							suggestionsArea: {
								...initialState.commandBar.suggestionsArea,
								searchItems: draft.entities.startupIntents,
							},
						};
						break;
					}
					case 'SHIFT+ESCAPE': {
						/** Currently set only for Cards tab */
						if (draft.commandBar.suggestionsArea.type === 'cards') {
							const allCards = draft.entities.cards; // get all avail cards
							draft.commandBar.suggestionsArea.searchItems = allCards; // set the cards as search items
							draft.commandBar.searchArea.mode = 'search'; // reset search mode back to search
							draft.commandBar.suggestionsArea.mode = 'normal'; // Set mode to normal
							draft.commandBar.suggestionsArea.type = 'cards'; // set tab/searchType
							draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
							draft.commandBar.searchArea.loadingStatus = 'normal'; // reset loading status
							draft.commandBar.display = 'expanded';
							draft.commandBar.searchArea.mainInputValue = ''; // reset input
						}
						break;
					}
					case 'SHIFT+BACKSPACE': {
						/** Currently set only for Cards tab in normal mode */
						if (
							draft.commandBar.suggestionsArea.type === 'cards' &&
							draft.commandBar.suggestionsArea.mode === 'normal'
						) {
							const allCards = draft.entities.cards; // get all avail cards
							const cardId =
								draft.commandBar.suggestionsArea.searchItems.allIds[
									selectedIndex
								]; // get cardId from selected idx
							if (cardId) {
								delete allCards.byId[cardId]; // delete card obj with `cardId`
								const cardIdIdx = allCards.allIds.indexOf(cardId); // get index of the cardId
								if (cardIdIdx > -1)
									allCards.allIds.splice(cardIdIdx, 1); // delete the cardId
								draft.entities.cards = allCards; // set the new cards entity
								draft.commandBar.suggestionsArea.searchItems = allCards; // set the cards as search items
								draft.commandBar.suggestionsArea.selectedIndex =
									selectedIndex - 1 >= 0 ? selectedIndex - 1 : 0; // set selected idx to prev item/or first item(if the first item was deleted)
							} else console.error(`cardId/selected idx invalid!`);
						}
						break;
					}
					default:
						break;
				}
				return draft;
			}
			case actionTypes.CUIV2_SEARCH_SUBMIT: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_SEARCH_SUBMIT']}
				 */
				const payload = action.payload;
				switch (payload.status) {
					case 'start': {
						draft.commandBar.searchArea.loadingStatus = 'waiting'; // set loading
						break;
					}
					case 'success': {
						if (payload.type === 'card') {
							const { cardPayload } = payload;
							const { display, mode, moduleWidth, brainId } =
								cardPayload;
							/** Add new card in cards entities */
							const cardId = uuidv4(); // generate a unique id
							draft.entities.cards.byId[cardId] = {
								id: cardId,
								entity: 'cards',
								brainId,
								display,
								mode,
								moduleWidth,
								url: cardPayload.middle.url,
								isNavVisible: cardPayload.bottom.visible,
								topIcon: cardPayload.top.icon,
								topText: cardPayload.top.text,
								tabId: cardPayload.middle.tab
									? cardPayload.middle.tab
									: undefined,
							};
							draft.entities.cards.allIds.unshift(cardId); // add card to the beginning of the list
							draft.commandBar.suggestionsArea.selectedSearchItem =
								cardId; // set the current card as selected
							draft.commandBar.searchArea.loadingStatus = 'submitting'; // set to submitting to show the close addon btn
							draft.commandBar.searchArea.mode = 'embeddedView'; // set search area mode to embeddedView
							/** Reset some states -- */
							draft.commandBar.suggestionsArea.mode = 'embeddedView'; // Set mode to embeddedView
							draft.commandBar.suggestionsArea.type = 'cards'; // set tab/searchType
							draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
							// draft.commandBar.searchArea.mainInputValue = ''; // reset input
							draft.commandBar.display = 'expanded';
						} else draft.commandBar = {
							...initialState.commandBar,
							commandBarStatus: {
								...draft.commandBar.commandBarStatus
							}
						}; // else reset
						break;
					}
					case 'error': {
						// draft.commandBar.searchArea.loadingStatus = 'normal'; // set loading
						// draft.commandBar.suggestionsArea.mode = 'normal'; // Set mode to normal
						// draft.commandBar.suggestionsArea.type = 'intents'; // set tab/searchType
						// draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
						// draft.commandBar.searchArea.mainInputValue = ''; // reset input
						// draft.commandBar.display = 'collapsed';
						draft.commandBar = initialState.commandBar;
						break;
					}
					default:
						break;
				}
				// draft.commandBar.searchArea.mode = 'search'; // reset back to search mode
				return draft;
			}
			case actionTypes.CUIV2_SEARCH_CHANGE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_SEARCH_CHANGE']}
				 */
				const payload = action.payload;
				const { inputValue, suggestions } = payload;
				draft.commandBar.searchArea.mainInputValue = inputValue; // Set the input value
				draft.commandBar.suggestionsArea.searchItems = suggestions; // set search items
				draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
				if (draft.commandBar.suggestionsArea.type === 'intents') {
					// this uses useSmartSuggest to show commands in any case
					// collapse if i/p is empty (used on all tabs other than cards)
					// if (inputValue) {
					// 	draft.commandBar.display = 'expanded';
					// } else draft.commandBar.display = 'collapsed'; // if input is empty collapse
					draft.commandBar.display = 'expanded';
				} else if (draft.commandBar.suggestionsArea.type === 'store') {
					// for store items
					// Set the suggestions as storeSkill entities
					draft.entities.storeSkills = suggestions;
				}
				return draft;
			}
			case actionTypes.CUIV2_SEARCH_ADDON_CLOSE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_SEARCH_ADDON_CLOSE']}
				 */
				const payload = action.payload;
				const searchAreaMode = draft.commandBar.searchArea.mode;
				// handle based on current mode. normal `search` is not handled as it is not shown during this mode
				switch (searchAreaMode) {
					case 'query': {
						/** If in query mode, reset and load startup intents as suggestions */
						draft.commandBar = {
							...initialState.commandBar,
							suggestionsArea: {
								...initialState.commandBar.suggestionsArea,
								searchItems: draft.entities.startupIntents,
							},
						};
						break;
					}
					case 'embeddedView': {
						/** Currently set only for Cards tab */
						if (draft.commandBar.suggestionsArea.type === 'cards') {
							const allCards = draft.entities.cards; // get all avail cards
							draft.commandBar.suggestionsArea.searchItems = allCards; // set the cards as search items
							draft.commandBar.searchArea.mode = 'search'; // reset search mode back to search
							draft.commandBar.suggestionsArea.mode = 'normal'; // Set mode to normal
							draft.commandBar.suggestionsArea.type = 'cards'; // set tab/searchType
							draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
							draft.commandBar.searchArea.loadingStatus = 'normal'; // reset loading status
							draft.commandBar.display = 'expanded';
							draft.commandBar.searchArea.mainInputValue = ''; // reset input
						}
						break;
					}
					default:
						break;
				}
				return draft;
			}
			case actionTypes.CUIV2_SUGGESTIONS_TAB_CLICK: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_SUGGESTIONS_TAB_CLICK']}
				 */
				const payload = action.payload;
				const { tabName } = payload;
				handleTabChange(draft, tabName);
				return draft;
			}
			case actionTypes.CUIV2_VIBRATE_INSTRUCTIONS_CHANGE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_VIBRATE_INSTRUCTIONS_CHANGE']}
				 */
				const payload = action.payload;
				const { vibrateInstructions } = payload;
				draft.commandBar.suggestionsArea.vibrateInstructions =
					vibrateInstructions; // vibrate/no-vibrate instructions
				return draft;
			}
			case actionTypes.CUIV2_PLACEHOLDER_SEARCH_CANCEL: {
				draft.commandBar.searchArea.loadingStatus = 'normal'; // set loading
				draft.commandBar.suggestionsArea.mode = 'normal'; // Set mode to normal
				draft.commandBar.suggestionsArea.type = 'intents';
				draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
				// draft.commandBar.display = 'collapsed';
				return draft;
			}
			case actionTypes.CUIV2_PLACEHOLDER_SEARCH_INIT: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_INIT']}
				 */
				const payload = action.payload;
				const { placeholderComponents, selectedPlaceholderComponent } =
					payload;
				draft.commandBar.searchArea.placeholderComponents =
					placeholderComponents;
				draft.commandBar.searchArea.selectedPlaceholderComponent =
					selectedPlaceholderComponent;
				return draft;
			}
			case actionTypes.CUIV2_SET_PLACEHOLDER_LOADING: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_LOADING']}
				 */
				const payload = action.payload;
				draft.commandBar.searchArea.placeholderLoading = payload;
				return draft;
			}
			case actionTypes.CUIV2_PLACEHOLDER_SEARCH_CHANGE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_CHANGE']}
				 */
				const payload = action.payload;
				switch (payload.type) {
					case 'placeholderComponents': {
						draft.commandBar.searchArea.placeholderComponents =
							payload.placeholderComponents;
						break;
					}
					case 'selectedPlaceholderComponent': {
						draft.commandBar.searchArea.selectedPlaceholderComponent =
							payload.selectedPlaceholderComponent;
						break;
					}
					case 'placeholderComponentById': {
						const { placeholderId, changes } = payload;
						const placeholderComponentObj = // get the selected placeholder Comp obj using id
							draft.commandBar.searchArea.placeholderComponents.byId[
								placeholderId
							];
						if (placeholderComponentObj) {
							// if valid
							draft.commandBar.searchArea.placeholderComponents.byId[
								placeholderId
							] = {
								...placeholderComponentObj,
								...changes, // update it with newer changes
							};
						}
						break;
					}
					default:
						break;
				}
				return draft;
			}
			case actionTypes.CUIV2_PLACEHOLDER_SEARCH_NEXT: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_NEXT']}
				 */
				const payload = action.payload;
				const selectedPlaceholderId =
					draft.commandBar.searchArea.selectedPlaceholderComponent;
				const selectedPlaceholderObj =
					draft.entities.components.byId[selectedPlaceholderId];
				const selectedSearchItemId = // get selected intent id
					draft.commandBar.suggestionsArea.selectedSearchItem;
				const intentPlaceholderComponentIds = // get ids of the components in the selected intent
					draft.entities.intents.byId[selectedSearchItemId].components;
				const inputPlaceholderIds = intentPlaceholderComponentIds.filter(
					// get only the component ids that support input
					(compId) =>
						draft.entities.components.byId[compId].type !== 'prompt'
				);
				/** Special handling for time/time-range input placeholder components */
				if (
					(selectedPlaceholderObj.type === 'time') |
					(selectedPlaceholderObj.type === 'timerange')
				) {
					const selectedIndex = 1; // selected the 1st suggestion item. ie. 0th item + 1,(because the 0th item is a dummy)
					// get the selected suggestion item based on selected idx
					const selectedSuggestionsItem =
						draft.commandBar.suggestionsArea.placeholderSearch
							.searchItems[selectedIndex - 1];
					if (selectedPlaceholderId && selectedSuggestionsItem) {
						// set the new input values and meta based on the newly selected suggestions item
						draft.commandBar.searchArea.placeholderComponents.byId[
							selectedPlaceholderId
						].inputValue = selectedSuggestionsItem.show;
						draft.commandBar.searchArea.placeholderComponents.byId[
							selectedPlaceholderId
						].meta = selectedSuggestionsItem.submit;
					}
				}
				// get idx of selected comp
				const selectedPlaceholderIdx = inputPlaceholderIds.indexOf(
					selectedPlaceholderId
				);
				if (selectedPlaceholderIdx > -1) {
					// if exists and valid
					const newIdx =
						selectedPlaceholderIdx >= inputPlaceholderIds.length - 1
							? 0
							: selectedPlaceholderIdx + 1;
					draft.commandBar.searchArea.selectedPlaceholderComponent =
						inputPlaceholderIds[newIdx]; // set the new idx
				} else
					draft.commandBar.searchArea.selectedPlaceholderComponent =
						inputPlaceholderIds[0]; // else reset to 0th idx
				return draft;
			}
			case actionTypes.CUIV2_SUGGESTIONS_MODE_CHANGE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_SUGGESTIONS_MODE_CHANGE']}
				 */
				const payload = action.payload;
				const { mode } = payload;
				draft.commandBar.suggestionsArea.mode = mode;
				return draft;
			}
			case actionTypes.CUIV2_PLACEHOLDER_SUGGESTIONS_CHANGE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SUGGESTIONS_CHANGE']}
				 */
				const payload = action.payload;
				const { searchItems } = payload;
				draft.commandBar.suggestionsArea.placeholderSearch.searchItems =
					searchItems;
				const selectedPlaceholderId =
					draft.commandBar.searchArea.selectedPlaceholderComponent;
				const selectedPlaceholderType =
					draft.entities.components.byId[selectedPlaceholderId]?.type;
				// reset placeholder suggestion selected idx based on placeholder type
				if (
					selectedPlaceholderType &&
					selectedPlaceholderType === 'custom'
				) {
					// also set the selected placeholderComponent Values to the first searchItem
					// ie. make the first searchItem selected by default
					const selectedIndex = 1; // selected the 1st suggestion item. ie. 0th item + 1,(because the 0th item is a dummy)
					draft.commandBar.suggestionsArea.placeholderSearch.selectedIndex =
						selectedIndex;
					// get the selected suggestion item based on selected idx (using n-1 here because searchItems have no dummy item, so 1st act item is 0th)
					const selectedSuggestionsItem = searchItems[selectedIndex - 1];
					if (selectedPlaceholderId && selectedSuggestionsItem) {
						// set the new input values and meta based on the newly selected suggestions item
						draft.commandBar.searchArea.placeholderComponents.byId[
							selectedPlaceholderId
						].searchValue = selectedSuggestionsItem.show;
						draft.commandBar.searchArea.placeholderComponents.byId[
							selectedPlaceholderId
						].meta = selectedSuggestionsItem.submit;
					}
				} else
					draft.commandBar.suggestionsArea.placeholderSearch.selectedIndex = 0;
				return draft;
			}
			case actionTypes.CUIV2_PLACEHOLDER_INPUT_KEYDOWN: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_INPUT_KEYDOWN']}
				 */
				const payload = action.payload;
				const { keyType } = payload;
				const searchItemsLength =
					draft.commandBar.suggestionsArea.placeholderSearch.searchItems
						.length;
				let { selectedIndex } =
					draft.commandBar.suggestionsArea.placeholderSearch;
				switch (keyType) {
					case 'UP': {
						if (selectedIndex === 0) selectedIndex = searchItemsLength;
						else selectedIndex -= 1;
						draft.commandBar.suggestionsArea.placeholderSearch.selectedIndex =
							selectedIndex; // set the new idx
						break;
					}
					case 'DOWN': {
						if (selectedIndex === searchItemsLength) selectedIndex = 0;
						else selectedIndex += 1;
						draft.commandBar.suggestionsArea.placeholderSearch.selectedIndex =
							selectedIndex; // set the new idx
						break;
					}
					default:
						break;
				}
				if (keyType === 'UP' || keyType === 'DOWN') {
					// get the selected suggestion item based on selected idx
					const selectedSuggestionsItem =
						draft.commandBar.suggestionsArea.placeholderSearch
							.searchItems[selectedIndex - 1];
					// get selected placeholderId
					const selectedPlaceholderId =
						draft.commandBar.searchArea.selectedPlaceholderComponent;
					if (selectedPlaceholderId && selectedSuggestionsItem) {
						const selectedPlaceholderType = // get placeholder type
							draft.entities.components.byId[selectedPlaceholderId]
								?.type;
						if (selectedPlaceholderType === 'custom') {
							// for custom type only
							// set the searchValue(not the inputValue) based on the newly selected suggestions item
							draft.commandBar.searchArea.placeholderComponents.byId[
								selectedPlaceholderId
							].searchValue = selectedSuggestionsItem.show;
						} else {
							// set the new input values based on the newly selected suggestions item
							draft.commandBar.searchArea.placeholderComponents.byId[
								selectedPlaceholderId
							].inputValue = selectedSuggestionsItem.show;
						}
						// set the meta based on the newly selected suggestions item
						draft.commandBar.searchArea.placeholderComponents.byId[
							selectedPlaceholderId
						].meta = selectedSuggestionsItem.submit;
					}
				}
				return draft;
			}
			case actionTypes.CUIV2_COMMAND_BAR_STATUS_UPDATE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_COMMAND_BAR_STATUS_UPDATE']}
				 */
				const payload = action.payload;
				const oldStatus = draft.commandBar.commandBarStatus;
				draft.commandBar.commandBarStatus = {
					...oldStatus,
					...payload, // update with whichever key is included in payload
				};
				return draft;
			}
			case actionTypes.CUIV2_CARD_MAXIMIZE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_CARD_MAXIMIZE']}
				 */
				const payload = action.payload;
				draft.commandBar.display = 'collapsed'; // collapse command display
				draft.cardWindow.isVisible = true; // make card window visible for maximized card
				draft.cardWindow.isInteractionEnabled = true; // enable interacting with it
				return draft;
			}
			case actionTypes.CUIV2_CARD_MINIMIZE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_CARD_MINIMIZE']}
				 */
				const payload = action.payload;
				draft.cardWindow.isVisible = false; // hide card window
				draft.commandBar.display = 'expanded'; // expand command display
				draft.commandBar.searchArea.mode = 'search'; // reset search area back to normal search
				draft.commandBar.suggestionsArea.mode = 'normal'; // change suggestions mode back to normal
				const allCards = draft.entities.cards; // get all avail cards
				draft.commandBar.suggestionsArea.searchItems = allCards; // set the cards as search items
				draft.commandBar.suggestionsArea.type = 'cards'; // set tab/searchType
				draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
				draft.commandBar.searchArea.mainInputValue = ''; // reset input
				return draft;
			}
			case actionTypes.CUIV2_CARD_CLOSE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_CARD_CLOSE']}
				 */
				const payload = action.payload;
				draft.cardWindow.isVisible = false; // hide card window
				draft.commandBar.display = 'expanded'; // expand command display
				draft.commandBar.searchArea.mode = 'search'; // reset search area back to normal search
				draft.commandBar.suggestionsArea.mode = 'normal'; // change suggestions mode back to normal
				const allCards = draft.entities.cards; // get all avail cards
				const cardId = draft.commandBar.suggestionsArea.selectedSearchItem; // get cardId from selected item
				delete allCards.byId[cardId]; // delete card obj with `cardId`
				const cardIdIdx = allCards.allIds.indexOf(cardId); // get index of the cardId
				if (cardIdIdx > -1) allCards.allIds.splice(cardIdIdx, 1); // delete the cardId
				draft.entities.cards = allCards; // set the new cards entity
				draft.commandBar.suggestionsArea.searchItems = allCards; // set the cards as search items
				draft.commandBar.suggestionsArea.type = 'cards'; // set tab/searchType
				draft.commandBar.suggestionsArea.selectedIndex = 0; // reset selected idx to 0
				draft.commandBar.searchArea.mainInputValue = ''; // reset input
				return draft;
			}
			case actionTypes.CUIV2_CARD_NAVIGATE: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_CARD_NAVIGATE']}
				 */
				const payload = action.payload;
				const { cardId, url } = payload;
				if (draft.entities.cards.byId[cardId]) {
					// if card with id exists
					draft.entities.cards.byId[cardId].url = url; // set the new url
				}
				return draft;
			}
			case actionTypes.CUIV2_STORE_SEARCH_SUBMIT: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_STORE_SEARCH_SUBMIT']}
				 */
				const payload = action.payload;
				switch (payload.status) {
					case 'start': {
						draft.commandBar.searchArea.loadingStatus = 'waiting'; // set loading
						break;
					}
					case 'success': {
						draft.commandBar = initialState.commandBar; // else reset
						break;
					}
					case 'error': {
						draft.commandBar = initialState.commandBar;
						break;
					}
					default:
						break;
				}
				return draft;
			}
			case actionTypes.CUIV2_STARTUP_INTENTS_INIT: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_STARTUP_INTENTS_INIT']}
				 */
				const payload = action.payload;
				draft.entities.startupIntents = payload.intents; // init startup intents
				return draft;
			}
			case actionTypes.CUIV2_STARTUP_INTENTS_LOAD: {
				/**
				 * @type {import('./cardUIV2').CuiActionPayloads['CUIV2_STARTUP_INTENTS_LOAD']}
				 */
				const payload = action.payload;
				draft.commandBar.suggestionsArea.searchItems =
					draft.entities.startupIntents; // load startup intents as suggestions
				draft.commandBar.display = 'expanded';
				return draft;
			}
			default: {
				return draft;
			}
		}
	},
	initialState
);

export default cardUiV2Reducer;
