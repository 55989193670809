import React from 'react';
import { MdPermContactCalendar } from 'react-icons/md';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';
import { useSelector, useDispatch } from 'react-redux';
import { setPlaceholderLoading } from '../../../../../../../redux/actions/cardUIV2';

const PhoneTypeSearch = ({
	placeholderText: text,
	setPlaceholderSearchItems,
	searchItems,
	selectedIndex,
}) => {
	const dispatch = useDispatch();
	const contacts = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.profiles.contacts
	);
	const [allItems, setAllItems] = React.useState([
		{ name: 'Sibesh Kar', email: 'sibesh96@gmail.com', phone: '9829118530' },
		{
			name: 'Shubham Mishra',
			email: 'shubham.root@gmail.com',
			phone: '9542226312',
		},
		{
			name: 'Mukesh Bhai',
			email: 'jio@roxx.com',
			phone: '81828810992',
		},
	]);

	React.useEffect(() => {
		var contactsArray = [];
		for (let key in contacts) {
			contactsArray = _.concat(contactsArray, contacts[key]);
		}

		var phoneArray = [];

		contactsArray.forEach((contact) => {
			contact.phoneNumbers.forEach((phone) => {
				phoneArray.push({ name: contact.displayName, phone: phone });
			});
		});
		setAllItems(phoneArray);
	}, [contacts]);

	React.useEffect(() => {
		var contactsArray = [];
		for (let key in contacts) {
			contactsArray = _.concat(contactsArray, contacts[key]);
		}
		if (contactsArray.length === 0) {
			setFilteredItems([
				{
					item: {
						name: 'No contacts found',
						phone: 'Type phone number manually or import contacts for easy access',
					},
				},
			]);
		} else {
			setFilteredItems([
				{
					item: {
						name: 'Contacts found in directory',
						phone: `Search through and select any to continue.`,
					},
				},
			]);
		}
	}, [allItems]);

	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.3, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['name', 'phone'],
	};

	const fuse = new Fuse(allItems, options);

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return { show: thing.item.phone, submit: thing.item.phone };
		});
	};

	var doneTypingInterval = 10; //time in ms, 5 second for example
	const typingTimer = React.useRef(null);
	const maxResults = 5;
	const onTextChange = (searchText) => {
		var contactsArray = [];
		for (let key in contacts) {
			contactsArray = _.concat(contactsArray, contacts[key]);
		}
		if (contactsArray.length === 0) {
			setFilteredItems([
				{
					item: {
						name: 'No contacts found',
						phone: 'Type phone number manually or import contacts for easy access',
					},
				},
			]);
		} else {
			if (
				searchText &&
				_.findIndex(searchItems, function (o) {
					return o.show === searchText;
				}) === -1
			) {
				dispatch(setPlaceholderLoading(true));
				var results = fuse.search(searchText).slice(0, maxResults);
				if (results.length > 0) {
					setFilteredItems(results);
					setPlaceholderSearchItems(selectFunction(results));
				} else {
					setFilteredItems([
						{
							item: {
								name: 'No matching contacts',
								phone: 'Enter phone number manually to continue',
							},
						},
					]);
				}

				dispatch(setPlaceholderLoading(false));
			}
		}

		// else {

		// }
	};

	React.useEffect(() => {
		clearTimeout(typingTimer.current);
		if (text) {
			typingTimer.current = setTimeout(() => {
				onTextChange(text);
			}, doneTypingInterval);
		}
	}, [text, doneTypingInterval]);

	return filteredItems.map((contact, i) => {
		console.log(contact);
		return (
			<SearchItem
				selectedIndex={selectedIndex}
				key={i}
				i={i}
				searchItemMainText={contact.item.name}
				searchItemSubText={contact.item.phone}
				reactIcon={<MdPermContactCalendar />}
			/>
		);
	});
};

export default PhoneTypeSearch;
