import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';
import _ from 'lodash';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

const uninstallModuleFromBrain = async (formData) => {
	const { user } = store.getState();
	let profileSlug = formData.profileSlug ? formData.profileSlug : user.info.id;
	formData.profileSlug = profileSlug;

	var uninstallModuleFromBrainAPIFn,
		uninstallModuleFromBrainIPCFn,
		uninstallModuleFromBrainResponse;
	if (!isElectron()) {
		uninstallModuleFromBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.uninstallModuleFromBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.uninstallModuleFromBrain.PATH_METHOD,
		});
		uninstallModuleFromBrainResponse = await uninstallModuleFromBrainAPIFn();
		if (uninstallModuleFromBrainResponse['data']['error']) {
			return uninstallModuleFromBrainResponse['data'];
		} else {
			return uninstallModuleFromBrainResponse['data'];
		}
	} else {
		uninstallModuleFromBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.uninstallModuleFromBrain.EVENT,
		});
		uninstallModuleFromBrainResponse = await uninstallModuleFromBrainIPCFn(
			formData
		);
		if (uninstallModuleFromBrainResponse['data']['error']) {
			return uninstallModuleFromBrainResponse['data'];
		} else {
			return uninstallModuleFromBrainResponse['data'];
		}
	}
};

export default uninstallModuleFromBrain;
