/**
 * Updates Reducer
 *
 * Creates a Redux reducer for processing desktop app updates.
 */

// Action Types
import actionTypes from '../actionTypes';

// Setup the initial state
const initialState = {
	updateAvailable: false,
	updateVersion: null,
	updateDownloaded: false,
	visible: false,
	error: null,
	progressObj: {},
};

// Export the Update Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.UPDATE_AVAILABLE: {
			const { version } = action.payload;
			console.log('Version:', version);
			return { ...state, updateAvailable: true, updateVersion: version };
		}
		case actionTypes.UPDATE_NOT_AVAILABLE: {
			return { ...state, updateAvailable: false };
		}
		case actionTypes.UPDATE_DOWNLOADED: {
			const { version } = action.payload;
			return {
				...state,
				updateDownloaded: true,
				updateVersion: version,
				visible: true,
			};
		}
		case actionTypes.UPDATE_DOWNLOAD_PROGRESS: {
			const progressObj = action.payload;
			return { ...state, progressObj: progressObj };
		}
		case actionTypes.UPDATE_ERROR: {
			const err = action.payload;
			return { ...state, error: err };
		}
		case actionTypes.UPDATE_NOT_NOW: {
			return { ...state, visible: false };
		}
		default:
			return state;
	}
};
