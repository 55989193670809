import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniqueId } from 'lodash';

/**
 * Queries all `STARTED` brains for scheduler nodes and
 * returns config for each scheduler node present along with brainId
 */
const useSchedulers = () => {
	const allBrains = useSelector(
		/**
		 * @param {{
		 * 	brains: import('../../redux/reducers/types/brains').Brains
		 * }} state
		 */
		(state) => state.brains
	);
	const schedulerConfigs = useMemo(
		() =>
			allBrains.allIds.reduce(
				(acc, brainId, idx) => {
					const brain = allBrains.byId[brainId];
					if (
						brain?.status === 'STARTED' &&
						Array.isArray(brain?.schedulers?.ids)
					) {
						for (let id of brain.schedulers.ids) {
							const scheduler = brain.schedulers.byId[id];
							const endpointFullUrl = brain.url + scheduler.endpointUrl;
							const newId = uniqueId();
							acc.ids.push(newId);
							acc.byId[newId] = {
								...scheduler,
								brainId,
								brainBaseURL: brain.url,
								endpointFullUrl,
							};
						}
					}
					return acc;
				},
				{
					ids: [],
					/**
					 * @type {{
					 * 	[key: string]: {
					 * 		brainId: string,
					 * 		brainBaseURL: string,
					 *			endpointFullUrl: string,
					 *			[key: string]: any,
					 * 	}
					 * }}
					 */
					byId: {},
				}
			),
		[allBrains.allIds, allBrains.byId]
	);

	return { schedulerConfigs };
};

export default useSchedulers;
