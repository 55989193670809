import isElectron from 'is-electron';

import IPCFunction from '../../../util/IPCFetch/function';
import IPCRoutes from '../../../util/IPCRoutes';

const toggleStatus = async () => {
	console.log('changing status');
	if (isElectron()) {
		var toggleStatusIPCFn, toggleStatusIPCResponse;
		toggleStatusIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.autoStartToggle.EVENT,
		});
		toggleStatusIPCResponse = await toggleStatusIPCFn();
		return toggleStatusIPCResponse;
	}
};

export default toggleStatus;
