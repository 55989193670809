import React from 'react';
import { IoEnterOutline } from 'react-icons/io5';
import { Box } from '@chakra-ui/react';

//Components
import IconContainer from '../../../../../../util/React_Icon';

const ItemRightInstruction = ({ text }) => {
	return (
		<Box
			height={`1.5rem`}
			bg={'card_ui.placeholder_dark'}
			borderRadius="0.2rem"
			display="flex"
			justifyContent="center"
			alignItems="center"
			paddingX="0.6rem"
			mr={3}
			color={'card_ui.font_dark.200'}
			_focus={{ outline: 'none' }}
			whiteSpace="nowrap"
			opacity="0.8"
		>
			<Box
				color={'card_ui.font_dark.200'}
				whiteSpace="nowrap"
				bg={'transparent'}
				fontWeight="400"
				fontFamily="body"
				padding="0"
				border="none"
				borderRadius="none"
				textAlign={'center'}
				fontSize={`0.7rem`}
				mr="0.3rem"
			>
				{text || 'Enter'}
			</Box>
			<IconContainer
				icon={<IoEnterOutline />}
				value={{
					color: 'white',
					className: 'global-class-name',
					size: '0.9rem',
				}}
			/>
		</Box>
	);
};

export default ItemRightInstruction;
