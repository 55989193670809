import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

const getSkillPack = async (formData) => {
	console.log(formData);
	var getSkillPackAPIFn, getSkillPackResponse;

	getSkillPackAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getSkillPack.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getSkillPack.PATH_METHOD,
		//PATH_QUERY: formData.id,
		URL_PARAM: {
			_id: formData._id,
		},
	});
	getSkillPackResponse = await getSkillPackAPIFn(formData);
	console.log('getSkillPackResponse', getSkillPackResponse);
	if (getSkillPackResponse['data']['error']) {
		return getSkillPackResponse['data'];
	} else {
		return getSkillPackResponse['data'];
	}
};

export default getSkillPack;
