/**
 * Brain Reducer
 *
 * Creates a Redux reducer for populating the robots.
 */

// Action Types
import actionTypes from '../actionTypes';

// Utilities
import { Add } from '../../util/Misc';

// Dependencies
import dotProp from 'dot-prop-immutable';
import _ from 'lodash';

// Setup initial state with an fleet info object.
const initialState = {
	byId: {},
	allIds: [],
};

// Export the Brain Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_PUBLISHEDSKILLPACKS: {
			const { info, refresh } = action.payload;
			if (refresh) {
				state = dotProp.set(state, `byId`, {});
				state = dotProp.set(state, `allIds`, []);
			}

			return Add(info, state);
			// return state;
		}
		case actionTypes.REMOVE_PUBLISHEDSKILLPACKS: {
			console.log(state);
			const { info } = action.payload;
			let index = _.indexOf(dotProp.get(state, 'allIds'), info);
			if (index !== -1) {
				state = dotProp.delete(state, `allIds.${index}`);
				state = dotProp.delete(state, `byId.${info}`);
			}

			console.log(state);

			return state;
		}
		case actionTypes.UPDATE_PUBLISHEDSKILLPACKS: {
			const { info } = action.payload;
			state = dotProp.set(state, `byId.${info._id}`, info);
			return state;
		}
		case actionTypes.UPDATE_PUBLISHEDSKILLPACKS_BY_ID: {
			const { info, id } = action.payload;
			let pack = dotProp.get(state, `byId.${id}`);
			state = dotProp.set(state, `byId.${id}`, {
				...pack,
				...info,
			});
			return state;
		}
		case actionTypes.CLEAR_PUBLISHEDSKILLPACKS: {
			state = dotProp.set(state, `byId`, {});
			state = dotProp.set(state, `allIds`, []);
			return state;
		}

		default:
			return state;
	}
};
