import { store } from '../../redux/store';
import mixpanel from './mixpanel';

const analytics = {};

analytics.identify = (userId) => {
	mixpanel.identify(userId);
};

analytics.track = (event, metadata) => {
	console.log('[ Analytics ]', event, metadata);
	mixpanel.track(event, {
		...metadata,
		release: 'maya-desktop@' + process.env.npm_package_version,
		environment: process.env.NODE_ENV,
	});
};

analytics.setUserDetails = (email, userId, metadata) => {
	var d = new Date();
	const state = store.getState();
	mixpanel.people.set({
		$name: state.profiles.user.name,
		$email: email, // only reserved properties need the $
		'Sign up date': d.toISOString(), // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
		USER_ID: userId,
		...metadata,
	});
};

export default analytics;
