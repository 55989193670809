import React from 'react';

/**
 * # Tab Context
 *
 * - Used for passing data to tab content components.
 * - Since we cannot use props as components are different, we use
 *   context.
 */
const StepperContext = React.createContext({
	stepIndex: 0,
	setStepIndex: () => {},
	mayaFlowJson: null,
	setMayaFlowJson: () => {},
	onModalClose: () => {},
});

export default StepperContext;
