/**
 * Login Controller
 *
 * Contains functions related to login within the application. It handles
 * login response from the FusionAuth server and also sets the cookies with
 * the API server for subsequent requests that required authentication.
 */

// Dependencies
import axios from 'axios';
import { get } from 'lodash';

// Config & Application Links
import { config, links } from '../../../config';

// Login API endpoints
import LoginAPI from './LoginAPI';

// import API Functions
import APIFunction from '../../../util/APIFetch/function';
import FusionauthRoutes from './LoginAPI';

// Response Handler
import ResponseHandler from '../../../util/ResponseHandler';

// Declare the Login Controller.
const LoginController = {};

/**
 * Determine post login action (from FusionAuth)
 *
 * Function takes in the status of the login response in order to
 * determine the post login action to be taken, whether that is to
 * display an error message, or to redirect the user. A promise is used
 * to redirect the user while reject is used to display a message.
 *
 * @param {number} response The response from the API request.
 */
LoginController.handleResponse = async (response) => {
	// Base path for language keys for the statuses.
	const langKey = ['common', 'auth', 'login', 'status'];
	// Redirect data for the redirectable statuses.
	const redirectData = {
		200: {
			redirect: links.home,
		},
		212: {
			redirect: links.verify,
		},
		203: {
			redirect: links.auth.changePassword,
			alert: true,
		},
		242: {
			redirect: links.auth.twoFactor,
			alert: true,
		},
	};

	// Alert data information for statuses. All statuses in this case use
	// the `danger` type, so we default all statuses to this with `500` as the key.
	const alertData = {
		500: 'danger',
	};

	// Return a promise with the results.
	return new Promise(async (resolve, reject) => {
		try {
			// Resolve with the redirect link.
			resolve(
				await ResponseHandler.status({
					response,
					apiMessage: false,
					langKey,
					redirectData,
					alertData,
				})
			);
		} catch (error) {
			// Reject with the error object.
			reject(error);
		}
	});
};

/**
 * Verify email based on tenantId and verificationId (from FusionAuth)
 *
 * @param {number} response The response from the API request.
 */
LoginController.verifyEmail = async ({ tenantId, verificationId }) => {
	const verifyEmailFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: FusionauthRoutes.apiService.verifyEmail.PATH_SEARCH,
		PATH_METHOD: FusionauthRoutes.apiService.verifyEmail.PATH_METHOD,
	});
	// Return a promise with the results.
	return new Promise(async (resolve, reject) => {
		try {
			// Resolve with the redirect link.
			const verifyEmailResponse = await verifyEmailFn({ verificationId });
			if (verifyEmailResponse.status === 200) {
				resolve({
					error: false,
					verifiedNow: true,
				});
			} else if (verifyEmailResponse.status === 404) {
				resolve({
					error: false,
					verifiedNow: false,
				});
			}
		} catch (error) {
			// Reject with the error object.
			reject({
				error: true,
				errorObject: error,
			});
		}
	});
};

/**
 *  * Resend verification email
 * @param {*} {email: valid email}
 * @returns
 */
LoginController.resendVerifyEmail = async ({ email }) => {
	const resendVerifyEmailFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: FusionauthRoutes.apiService.resendVerifyEmail.PATH_SEARCH,
		PATH_METHOD: FusionauthRoutes.apiService.resendVerifyEmail.PATH_METHOD,
	});
	// Return a promise with the results.
	return new Promise(async (resolve, reject) => {
		try {
			// Resolve with the redirect link.
			const verifyEmailResponse = await resendVerifyEmailFn({ email });
			// console.log('~Resend email response', verifyEmailResponse);
			if (verifyEmailResponse.status === 200) {
				resolve({
					error: false,
				});
			} else {
				resolve({
					error: true,
				});
			}
		} catch (error) {
			// Reject with the error object.
			reject({
				error: true,
				errorObject: error,
			});
		}
	});
};

/**
 * Handle Login Actions
 *
 * Uses the custom Response Handler to handle login actions.
 *
 * @param {Object} responseObject Response object from the request with alert info or redirect info.
 * @param {Object} data Data object required for handling the action.
 * @param {Function} action Redux action to handle data.
 */
LoginController.handleAction = (responseObject, data, action) => {
	// Add additional content to the response object to be handled.
	responseObject = {
		...responseObject,
		setUser: true,
		from: 'changePassword',
	};

	// Use the custom Response Handler to determine what to do with the user.
	const response = ResponseHandler.action(responseObject, data, action);
	// Check if there are any errors to display.
	if (get(response, 'type')) {
		// Return the error information.
		return response;
	} else {
		// No error, so the user was redirected.
		return false;
	}
};

/**
 * Set cookies on successful login
 *
 * Function makes a request to the backend API service to set cookies
 * for future requests in order to allow the user to access the API endpoints
 * for the application.
 *
 * @param {string} refreshToken User refresh token from the login response.
 * @param {string} token User access token from the login response.
 * @param {String} locale The current locale of the application.
 */
LoginController.setCookies = (refreshToken, token, id, email, locale) => {
	// Return a promise with the redirect url or the error response.

	return new Promise((resolve, reject) => {
		axios({
			baseURL: config.apiServer.BASEURL,
			url: LoginAPI.apiService.getLogin.PATH_SEARCH,
			method: LoginAPI.apiService.getLogin.PATH_METHOD,
			withCredentials: true,
			data: {
				refreshToken,
				token,
				id,
				email,
			},
			headers: {
				locale,
			},
		})
			.then((response) => {
				if (response.data && response.data.verifiedEmail) {
					resolve({ redirect: links.home });
				} else {
					resolve({ redirect: links.verify });
				}
			})
			.catch(() => reject('There was an issue logging you in.'));
	});
};

// Export the Login Controller.
export default LoginController;
