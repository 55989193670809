import React, { useState, useEffect } from 'react';
import {
	Flex,
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	Button,
	LightMode,
	SimpleGrid,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useToast,
} from '@chakra-ui/react';
import getLocale from '../../functions/settings/getLocale/getLocale';
import { FiChevronDown } from 'react-icons/fi';
import { connect } from 'react-redux';
import getStripeSessionId from '../../functions/payments/getStripeSessionId';
import { loadStripe } from '@stripe/stripe-js';
import { config } from '../../config';

const WEBSITE_URL = config.app.WEBSITE_URL;

const infoItems = [
	{
		text: 'Maya Desktop App',
		desc: 'Lifetime access to the Maya cross-platform desktop app. ',
	},
	{
		text: 'Unlimited Usage',
		desc: 'No limits on number or running time of Maya skill workflows. ',
	},
	{
		text: 'Maya Store',
		desc: 'Get access to 100+ pre-built bot workflows to use & modify.',
	},
	{
		text: 'Free Tailormade Bot',
		desc: 'We help build & run one custom automtion for you, end-to-end. ',
	},
	{
		text: 'Chat Support',
		desc: 'Chat and forum support for building and debugging your own bots.',
	},
	{
		text: 'App Updates',
		desc: 'Regular security updates, features & improvements.',
	},
];

const InfoCard = ({ title, description }) => {
	return (
		<Flex
			direction="column"
			border="1px solid black"
			borderRadius="8.75px"
			padding={{ sm: '8px 16px', md: '16px 24px' }}
			border="1.5px solid #F6F2FA1A"
			// bg={isSelected ? '#EDEDED1A' : '#bbbbbb1a'}
			// border={isSelected ? '1.5px solid #969696b3' : '1.5px solid #F6F2FA1A'}
			// minHeight='161px'
			backgroundColor="#85858526"
			transition="all 0.3s cubic-bezier(.08,.52,.52,1)"
			_hover={{
				backgroundColor: '#EDEDED26',
				cursor: 'pointer',
			}}
			cursor="pointer"
			color="white"
			_hover={{
				border: '1.5px solid #969696b3',
			}}
			// onClick={selectCard}
		>
			<Box fontSize={{ sm: '16px', md: '16px' }} fontWeight="700">
				{title}
			</Box>

			<Box fontSize="14px" flex="1 1 auto" opacity="0.8">
				{description}
			</Box>
		</Flex>
	);
};

const pricingInfo = {
	'en-IN': {
		original: '3499',
		discounted: '1399',
		symbol: '₹',
		letterSymbol: 'INR',
	},
	'en-US': {
		original: '49.99',
		discounted: '19.99',
		symbol: '$',
		letterSymbol: 'USD',
	},
};

const TrialExpiredModalComponent = ({
	user,
	isOpen,
	onClose,
	trialExpired = true,
}) => {
	const [locale, setLocale] = useState(null);
	const localeList = Object.keys(pricingInfo);
	const [buttonLoading, setButtonLoading] = useState(false);
	const toast = useToast();

	useEffect(() => {
		getLocale()
			.then((res) => {
				if (res.countryCode === 'IN') {
					setLocale('en-IN');
				} else {
					setLocale('en-US');
				}
			})
			.catch(() => {
				setLocale('en-US');
			});
	}, []);

	function handleClick() {
		const email = user.info.email;
		setButtonLoading(true);
		getStripeSessionId(locale, email)
			.then((res) => {
				window.open(`${WEBSITE_URL}/checkout?sessionId=${res.id}`);
				setButtonLoading(false);
			})
			.catch((e) => {
				console.log('locale', e);
				toast({
					title: 'Error',
					description: 'There was a problem completing the checkout',
					status: 'error',
					duration: '3000',
					isClosable: true,
				});
				setButtonLoading(false);
			});
	}

	return (
		<Modal
			isOpen={isOpen}
			isCentered
			size="6xl"
			onClose={onClose}
			closeOnOverlayClick={!trialExpired}
		>
			<ModalOverlay />
			<ModalContent bg="#3E3F3E">
				<ModalCloseButton />
				<Box>
					<Flex bg="#3E3F3E" borderRadius="5px" minHeight="300px">
						<Flex // Left section
							// bg='tomato'
							bg="#3E3F3E"
							flex="28%"
							borderLeftRadius="5px"
							padding="16px 24px"
							boxSizing="border-box"
						>
							<Flex direction="column">
								<Box
									// bg='pink'
									flex="1 1 auto"
								>
									<Box
										fontSize="26px"
										lineHeight="38px"
										// fontWeight='bold'
									>
										{trialExpired
											? 'Trial period for this account has expired'
											: 'Upgrade to premium'}
									</Box>
									<Box mt="12px">
										Upgrade to premium to use the full features of the
										app, forever
									</Box>
								</Box>
								{locale ? (
									<Box display="flex" ml="4px" width="100%">
										<Box
											fontSize="24px"
											fontWeight="600"
											// bg='blue.400'
											// bg='green'
										>
											{pricingInfo[locale].symbol}
											{pricingInfo[locale].discounted}{' '}
											{/* {` ${pricingInfo[locale].letterSymbol}`} */}
										</Box>
										<Box
											fontSize="14px"
											fontWeight="600"
											marginLeft="4px"
											// bg='green'
											textDecoration="line-through"
										>
											{pricingInfo[locale].symbol}
											{pricingInfo[locale].original}
										</Box>
										<Box fontSize="22px" ml="8px">
											<Menu>
												<MenuButton
													as={Button}
													rightIcon={<FiChevronDown />}
													cursor="pointer"
													height="30px"
													width="62px"
													paddingX="8px"
													fontSize="14px"
												>
													{pricingInfo[locale].letterSymbol}
												</MenuButton>
												<MenuList
													bg="maya_dark.300"
													minWidth="80px"
													padding="0px"
												>
													{localeList
														.filter((lcl) => lcl !== locale)
														.map((lcl) => (
															<MenuItem
																onClick={() => setLocale(lcl)}
																fontSize="16px"
																padding="0px"
																margin="0px"
															>
																<Box padding="8px 8px">
																	{
																		pricingInfo[lcl]
																			.letterSymbol
																	}
																</Box>
															</MenuItem>
														))}
												</MenuList>
											</Menu>
										</Box>
									</Box>
								) : null}
								<Box
									fontSize="13px"
									width="210px"
									opacity="0.8"
									ml="4px"
									mt="6px"
									// bg='green'
								>
									Early bird price. One-time payment.
								</Box>
								<LightMode>
									<Box>
										<Button
											colorScheme="purple"
											mt="22px"
											onClick={handleClick}
											isLoading={buttonLoading}
										>
											Upgrade to premium
										</Button>
									</Box>
								</LightMode>
							</Flex>
						</Flex>

						<Flex // Right section
							bg="green"
							bg="#383838"
							width="72%"
							borderRightRadius="5px"
							padding="16px 30px"
							boxSizing="border-box"
						>
							<Box width="100%" boxSizing="border-box">
								<Box
									letterSpacing="0.2em"
									fontSize="20px"
									fontWeight="bold"
									mt="4px"
								>
									WHAT YOU GET
								</Box>
								<SimpleGrid
									width="100%"
									minChildWidth="350px"
									spacing="16px"
									mt="16px"
								>
									{infoItems.map((item) => (
										<InfoCard
											title={item.text}
											description={item.desc}
										/>
									))}
								</SimpleGrid>
								{/* <Flex>
                                    <Box>

                                    </Box>
                                    <Box>

                                    </Box>
                                </Flex> */}
							</Box>
						</Flex>
					</Flex>
				</Box>
			</ModalContent>
		</Modal>
	);
};

const mapStateToProps = (state) => {
	const { user } = state;
	return { user };
};

export const TrialExpiredModal = connect(mapStateToProps)(
	TrialExpiredModalComponent
);
