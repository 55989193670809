import React from 'react';
import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalOverlay,
	ModalContent,
	useColorModeValue,
	ModalCloseButton,
} from '@chakra-ui/react';
import isElectron from 'is-electron';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import StyledDropzone from './StyledDropzone';
import CustomModal from './ChakraCustom/CustomModal';

const UploadFile = ({
	onOpen,
	isOpen,
	onClose,
	header,
	description,
	fileTypes,
	fileTypesText,
	selectFilesFn,
}) => {
	return (
		<>
			<CustomModal
				isOpen={isOpen}
				onClose={onClose}
				size="xl"
				fontFamily="body"
				isCentered={isElectron()}
			>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					maxHeight="400px"
					maxWidth="480px"
				>
					<ModalCloseButton
						right="2"
						border="none"
						cursor
						_focus={{ outline: 'none' }}
					/>
					<ModalBody paddingX="0">
						<HeaderWithDescription
							header={header || 'Upload File'}
							desc={description || 'Pick a file to upload '}
							paddingLeft="1rem"
							marginBottom="0"
						/>
						<Box paddingX="1rem" paddingY="0.5rem">
							<StyledDropzone
								fileTypes={fileTypes}
								fileTypesText={fileTypesText}
								selectFilesFn={(files) => {
									selectFilesFn(files);
								}}
							/>
						</Box>
						<Button
							colorScheme="purple"
							bg={'purple.500'}
							color="white"
							size="sm"
							onClick={() => {}}
							loadingText={'Starting...'}
							borderRadius="5px"
							paddingX="20px"
							ml="4"
						>
							Select
						</Button>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

export default UploadFile;
