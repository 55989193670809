/**
 * Profile Actions
 *
 * Action creators for Profile based actions.
 */

// Dependencies
// import { get } from "lodash";

// Action Types
import isElectron from 'is-electron';
import getDecodedAccessToken from '../../functions/configuration/getDecodedAccessToken';
import getProfileSlug from '../../util/ProfileSlug';
import actionTypes from '../actionTypes';

/**
 * Set Profiles
 *
 * set the profiles with the team and user details
 *
 */
const setProfiles = (profiles) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.SET_PROFILES,
		payload: {
			info: profiles,
		},
	});
};

const setCurrentProfile = (profile) => (dispatch, getState) => {
	//Dispatch the ID
	dispatch({
		type: actionTypes.SET_CURRENT_PROFILE,
		payload: {
			info: profile,
		},
	});
};

const setContacts = (contacts) => (dispatch, getState) => {
	//Dispatch the ID
	dispatch({
		type: actionTypes.SET_CONTACTS,
		payload: {
			info: contacts,
		},
	});
};

/**
 *
 * Action to update profile payment tier in redux store
 * - Uses IPC fn to fetch tier from access_token and set it in store or
 *   use providedTier argument if triggered on browser(not electron)
 * @param {'FREE' | 'PREMIUM'} providedTier
 * @returns
 */
export const currentProfileTierUpdate =
	(providedTier) => async (dispatch, getState) => {
		let tier;
		try {
			if (isElectron()) {
				const response = await getDecodedAccessToken();
				if (response.data.error)
					throw new Error(
						`getDecodedAccessToken Error: ${response.message}`
					);
				const { slug } = getProfileSlug();
				const accessObj = response.data.results.access.find(
					(obj) => obj?.slug === slug
				);
				if (!accessObj) throw new Error(`accessObj not found!`);
				tier = accessObj.tier;
			} else {
				if (!providedTier) throw new Error(`providedTier is invalid`);
				tier = providedTier;
			}
			dispatch({
				type: actionTypes.CURRENT_PROFILE_TIER_UPDATE,
				payload: {
					info: tier,
				},
			});
		} catch (error) {
			console.error(error);
		}
	};

// Export the actions.
export { setProfiles, setCurrentProfile, setContacts };
