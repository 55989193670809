// Export the API.
const API_ROUTES = {
	apiService: {
		//Dashboard data
		//ELECTRON
		getDashboardData: {
			PATH_SEARCH: '/api/v2/dashboard',
			PATH_METHOD: 'get',
		},
		//Feedback
		submitFeedback: {
			PATH_SEARCH: '/api/feedback/submit',
			PATH_METHOD: 'post',
		},
		//Teams
		//ELECTRON
		modifyTeam: {
			PATH_SEARCH: '/api/team/edit',
			PATH_METHOD: 'put',
		},
		// createTeam: {
		//   PATH_SEARCH: "/api/team/create",
		//   PATH_METHOD: "post",
		// },
		inviteMember: {
			PATH_SEARCH: '/api/team/members/invite',
			PATH_METHOD: 'post',
		},
		removeMember: {
			PATH_SEARCH: '/api/team/members/remove',
			PATH_METHOD: 'post',
		},
		addFlowToTeamLibrary: {
			PATH_SEARCH: '/api/team/library/add',
			PATH_METHOD: 'post',
		},
		deleteFlowFromTeamLibrary: {
			PATH_SEARCH: '/api/team/library/delete',
			PATH_METHOD: 'post',
		},
		getTeamLibraryItems: {
			PATH_SEARCH: '/api/team/library/list',
			PATH_METHOD: 'post',
		},
		getTeamFromStoreItems: {
			PATH_SEARCH: '/api/team/library/fromstore',
			PATH_METHOD: 'post',
		},
		//Flows
		getCurrentFlow: {
			PATH_SEARCH: '/api/flow/current',
			PATH_METHOD: 'post',
		},
		createFlow: {
			PATH_SEARCH: '/api/flow/create',
			PATH_METHOD: 'post',
		},
		deleteFlow: {
			PATH_SEARCH: '/api/flow/delete',
			PATH_METHOD: 'post',
		},
		//Fleets
		//ELECTRON
		createFleet: {
			PATH_SEARCH: '/api/fleet/create',
			PATH_METHOD: 'post',
		},
		//ELECTRON
		deleteFleet: {
			PATH_SEARCH: '/api/fleet/delete',
			PATH_METHOD: 'post',
		},
		getFleets: {
			PATH_SEARCH: '/api/fleet/list',
			PATH_METHOD: 'get',
		},
		//Devices
		checkDevice: {
			PATH_SEARCH: '/api/v2/device/check',
			PATH_METHOD: 'post',
		},
		registerDevice: {
			PATH_SEARCH: '/api/v2/device/register',
			PATH_METHOD: 'post',
		},
		getDevices: {
			PATH_SEARCH: '/api/v2/device/list',
			PATH_METHOD: 'get',
		},
		//Modules
		getModules: {
			PATH_SEARCH: '/api/modules/',
			PATH_METHOD: 'get',
		},
		getModuleList: {
			PATH_SEARCH: '/api/v2/modules/list',
			PATH_METHOD: 'post',
		},
		cmsGetStoreModules: {
			PATH_SEARCH: '/modules',
			PATH_METHOD: 'get',
		},
		//Robots
		//ELECTRON
		getRobotInfo: {
			PATH_SEARCH: '/api/robot/info',
			PATH_METHOD: 'post',
		},
		listRobots: {
			PATH_SEARCH: '/api/robot/list',
			PATH_METHOD: 'get',
		},
		updateRobot: {
			PATH_SEARCH: '/api/robot/update',
			PATH_METHOD: 'post',
		},
		installFlow: {
			PATH_SEARCH: '/api/robot/installFlow',
			PATH_METHOD: 'post',
		},
		uninstallFlow: {
			PATH_SEARCH: '/api/robot/uninstallFlow',
			PATH_METHOD: 'post',
		},
		installModule: {
			PATH_SEARCH: '/api/robot/installModule',
			PATH_METHOD: 'post',
		},
		uninstallModule: {
			PATH_SEARCH: '/api/robot/uninstallModule',
			PATH_METHOD: 'post',
		},
		onDeploy: {
			PATH_SEARCH: '/api/robot/deploy',
			PATH_METHOD: 'post',
		},
		//Config Types
		getConfigType: {
			PATH_SEARCH: '/api/v2/configurationTypes/',
			PATH_METHOD: 'get',
		},
		//CONFIG PROFILES
		getConfigProfiles: {
			PATH_SEARCH: '/api/v2/profiles/configs',
			PATH_METHOD: 'get',
		},
		addConfigToProfile: {
			PATH_SEARCH: '/api/v2/profiles/config',
			PATH_METHOD: 'post',
		},
		removeConfigProfile: {
			PATH_SEARCH: '/api/v2/profiles/configs',
			PATH_METHOD: 'delete',
		},
		//BRAINS
		createBrain: {
			PATH_SEARCH: '/api/v2/brains',
			PATH_METHOD: 'post',
		},
		deleteBrain: {
			PATH_SEARCH: '/api/v2/brains',
			PATH_METHOD: 'delete',
		},
		//ELECTRON
		startBrain: {
			PATH_SEARCH: '/api/v2/brains/start',
			PATH_METHOD: 'post',
		},
		//ELECTRON
		stopBrain: {
			PATH_SEARCH: '/api/v2/brains/stop',
			PATH_METHOD: 'post',
		},
		updateBrain: {
			PATH_SEARCH: '/api/v2/brains/',
			PATH_METHOD: 'put',
		},
		getBrainById: {
			PATH_SEARCH: '/api/v2/brains',
			PATH_METHOD: 'get',
		},
		getToken: {
			PATH_SEARCH: '/api/v2/brains/token',
			PATH_METHOD: 'get',
		},
		deploySkills: {
			PATH_SEARCH: '/api/v2/deploy',
			PATH_METHOD: 'put',
		},
		installModuleToBrain: {
			PATH_SEARCH: '/api/v2/brains/installModule',
			PATH_METHOD: 'put',
		},
		getStoreModules: {
			PATH_SEARCH: '/api/v2/store/modules',
			PATH_METHOD: 'get',
		},
		getModuleById: {
			PATH_SEARCH: '/api/v2/store/modules/',
			PATH_METHOD: 'get',
		},

		//ELECTRON
		deleteRobot: {
			PATH_SEARCH: '/api/robot/delete',
			PATH_METHOD: 'post',
		},

		//SETTINGS
		getUserDetails: {
			PATH_SEARCH: '/api/settings/user',
			PATH_METHOD: 'post',
		},
		updateUser: {
			PATH_SEARCH: '/api/settings/user',
			PATH_METHOD: 'put',
		},
		newDeveloperRequest: {
			PATH_SEARCH: '/api/v2/developer',
			PATH_METHOD: 'post',
		},
		deleteDeveloperRequest: {
			PATH_SEARCH: '/api/v2/developer',
			PATH_METHOD: 'delete',
		},
		getDeveloperAccounts: {
			PATH_SEARCH: '/api/v2/developer',
			PATH_METHOD: 'get',
		},
		//PUBLISH
		publishNewFlow: {
			PATH_SEARCH: '/api/publish/flow/new',
			PATH_METHOD: 'post',
		},
		updatePublishedFlow: {
			PATH_SEARCH: '/api/publish/flow/update',
			PATH_METHOD: 'post',
		},
		//STORE
		getStoreSkillPacks: {
			PATH_SEARCH: '/api/v2/store/skills',
			PATH_METHOD: 'post',
		},
		searchStoreSkillPacks: {
			PATH_SEARCH: '/api/v2/store/skills/search',
			PATH_METHOD: 'get',
		},
		getPublishedSkillPack: {
			PATH_SEARCH: '/api/v2/publish',
			PATH_METHOD: 'get',
		},
		getPublishedSkillPackByDeveloper: {
			PATH_SEARCH: '/api/v2/publish/developer',
			PATH_METHOD: 'get',
		},
		getStoreFlows: {
			PATH_SEARCH: '/api/store/',
			PATH_METHOD: 'get',
		},
		getStoreFlowById: {
			PATH_SEARCH: '/api/store/',
			PATH_METHOD: 'post',
		},
		getStoreFlowVersions: {
			PATH_SEARCH: '/api/store/versions',
			PATH_METHOD: 'post',
		},
		// UPLOAD
		uploadFileS3: {
			PATH_SEARCH: '/api/upload/public/s3',
			PATH_METHOD: 'post',
		},
		//CART
		createOrder: { PATH_SEARCH: '/api/cart/order', PATH_METHOD: 'post' },
		updatePayment: { PATH_SEARCH: '/api/cart/payment', PATH_METHOD: 'post' },
		syncData: {
			PATH_SEARCH: '/api/v2/sync',
			PATH_METHOD: 'post',
		},
		//LIBRARY
		addToLibrary: {
			PATH_SEARCH: '/api/v2/library/skillPack',
			PATH_METHOD: 'post',
		},
		addToLibraryFromStore: {
			PATH_SEARCH: '/api/v2/library/publishedSkillPack',
			PATH_METHOD: 'post',
		},
		deleteFromLibrary: {
			PATH_SEARCH: '/api/v2/library/skillPack',
			PATH_METHOD: 'delete',
		},
		copyToLibrary: {
			PATH_SEARCH: '/api/v2/library/copy',
			PATH_METHOD: 'post',
		},
		getLibraryItems: {
			PATH_SEARCH: '/api/v2/library/list',
			PATH_METHOD: 'get',
		},
		//skillPack
		updateLibrarySkillPack: {
			PATH_SEARCH: '/api/v2/library/skillPack/update',
			PATH_METHOD: 'post',
		},
		getSkillPack: {
			PATH_SEARCH: '/api/v2/skillPack',
			PATH_METHOD: 'get',
		},
		getSkillPackList: {
			PATH_SEARCH: '/api/v2/skillPack/list',
			PATH_METHOD: 'post',
		},
		//publishedSkillPack
		publishSkillPack: {
			PATH_SEARCH: '/api/v2/publish',
			PATH_METHOD: 'post',
		},
		updatePublishedSkillPack: {
			PATH_SEARCH: '/api/v2/publish',
			PATH_METHOD: 'patch',
		},
		getPublishedSkillPackList: {
			PATH_SEARCH: '/api/v2/publish/list',
			PATH_METHOD: 'post',
		},
		// Profile
		checkSlug: {
			PATH_SEARCH: '/api/v2/profiles/check',
			PATH_METHOD: 'get',
		},
		createTeam: {
			PATH_SEARCH: '/api/v2/profiles',
			PATH_METHOD: 'post',
		},
		getUserProfiles: {
			PATH_SEARCH: '/api/v2/profiles',
			PATH_METHOD: 'get',
		},
		deleteProfile: {
			PATH_SEARCH: '/api/v2/profiles',
			PATH_METHOD: 'delete',
		},
		// UPDATES
		checkUpdates: {
			PATH_SEARCH: '/api/v2/brains/checkUpdates',
			PATH_METHOD: 'post',
		},
		// CATEGORIES
		getCategories: {
			PATH_SEARCH: '/api/v2/categories',
			PATH_METHOD: 'get',
		},
		// PLATFORMS
		getPlatforms: {
			PATH_SEARCH: '/api/v2/platforms',
			PATH_METHOD: 'get',
		},
		// PAYMENTS
		// createSubscription: {
		// 	PATH_SEARCH: '/api/v2/payments/subscription/create',
		// 	PATH_METHOD: 'post',
		// },
		// mayapayments
		paymentsStatus: {
			PATH_SEARCH: '/api/v2/configurations/paymentenabled',
			PATH_METHOD: 'get',
		},
		getCheckoutPageURL: {
			PATH_SEARCH: '/api/v2/subscription/',
			PATH_METHOD: 'post',
		},
		verifySubscription: {
			PATH_SEARCH: '/api/v2/subscription/verify',
			PATH_METHOD: 'post',
		},
		deleteSubscription: {
			// only for internal use
			PATH_SEARCH: '/api/v2/subscription/internal',
			PATH_METHOD: 'delete',
		},
		cancelSubscription: {
			PATH_SEARCH: '/api/v2/subscription/cancel',
			PATH_METHOD: 'post',
		},
		updateSubscription: {
			PATH_SEARCH: '/api/v2/subscription/',
			PATH_METHOD: 'put',
		},
		getAvailablePlanPriceIds: {
			PATH_SEARCH: '/api/v2/subscription/plans',
			PATH_METHOD: 'get',
		},
		getProfileTier: {
			PATH_SEARCH: '/api/v2/profiles/tier',
			PATH_METHOD: 'get',
		},
		updateProfileTier: {
			PATH_SEARCH: '/api/v2/profiles/tier',
			PATH_METHOD: 'put',
		},
	},
};

export default API_ROUTES;
