import React from 'react';
import { Button, useColorModeValue, LightMode } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';

//Redux
import { addTab } from '../../../redux/actions';

//Components
import SelectGenerator from '../../SkillGen/SelectGenerator';

//Utils
import { HistoryWithSlug } from '../../../util/History';

//Analytics
import analytics from '../../../util/Analytics';

const FromSkillGen = ({ addTab, brains }) => {
	const [brainId, setBrainId] = React.useState('');
	const goToBrain = () => {
		addTab(brainId, brains.byId[brainId].name);
		HistoryWithSlug.push(`/edit?id=${brains.byId[brainId]._id}`);
		window.location.reload();
	};
	return (
		<motion.div
			layout
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			<SelectGenerator onSelectFn={() => {}} />
		</motion.div>
	);
};

const mapStateToProps = (state) => {
	const { brains } = state;
	return { brains };
};

export default connect(mapStateToProps, { addTab })(FromSkillGen);
