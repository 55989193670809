import isElectron from 'is-electron';
import axios from 'axios'

// import IPCFunction from '../../../util/IPCFetch/function';
// import IPCRoutes from '../../../util/IPCRoutes';

// const getLocaleNative = async () => {
//     if (isElectron()) {
//         const getLocaleIPCFn = IPCFunction({
//             EVENT: IPCRoutes.ipcService.getLocale.EVENT,
// 		});

//         const getLocaleIPCResponse = await getLocaleIPCFn();
//         return getLocaleIPCResponse
// 	} else {
// 		if (navigator.languages && Array.isArray(navigator.languages)) {
//             return navigator.languages[0]
//         } else {
//             return navigator.language
//         }
// 	}
// };

const getLocale = async () => {
    try {
        const response = await axios.get('https://api.country.is')
        const data = response.data
        if (data.country === 'IN') {
            return {
                countryCode: data.country,
                locale: 'en-IN'
            }
        } else {
            return {
                countryCode: 'US',
                locale: 'en-US'
            }
        }
    } catch (e) {
        return {
            countryCode: 'US',
            locale: 'en-US'
        }
    }
};

export default getLocale;