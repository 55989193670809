import React, { useEffect, useState } from 'react';
import { Box, Progress, CircularProgress, useToast } from '@chakra-ui/react';
import SliderCarousel from './Carousel';

/**
 *
 * @param {{
 *  progressPercent: number,
 *  messageText: string,
 * }} param0
 * @returns
 */
const ProgressBar = ({ progressPercent, messageText }) => {
	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="space-between"
			zIndex="4"
		>
			<Progress value={progressPercent} size="sm" colorScheme="purple" />
			<Box mt={2} fontFamily="body" display="flex" alignItems="center">
				<CircularProgress isIndeterminate size="1rem" color="#7161ff" />
				<Box ml={2}>{messageText}</Box>
			</Box>
		</Box>
	);
};

/**
 *
 * @param {{
 *  progressPercent: number,
 *  messageText: string,
 * }} param0
 * @returns
 */
const ProgressCarousel = ({ progressPercent, messageText }) => {
	return (
		<Box display="flex" flexDir="column" flex="1">
			<Box borderBottom="none" flex="1" display="flex" alignItems="center">
				<SliderCarousel />
			</Box>
			<ProgressBar
				progressPercent={progressPercent}
				messageText={messageText}
			/>
		</Box>
	);
};

export default ProgressCarousel;
