// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */
const getDashboardData = async ({ slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	// Create an instance of your worker

	var getDashboardDataFn;
	if (!isElectron()) {
		getDashboardDataFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getDashboardData.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getDashboardData.PATH_METHOD,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	} else {
		getDashboardDataFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getDashboardData.EVENT,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	}
	var getDashboardResponse = await getDashboardDataFn();
	// if (getDashboardResponse.data.error) {
	// 	return getDashboardResponse['data'];
	// } else {
	return getDashboardResponse['data']['results'];
	// }
};

export default getDashboardData;
