import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

const getBrainsByPublishedSkillPack = async (formData) => {
	console.log(formData);
	var getBrainsByPublishedSkillPackIPCFn,
		getBrainsByPublishedSkillPackResponse;

	if (isElectron()) {
		getBrainsByPublishedSkillPackIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getBrainsByPublishedSkillPack.EVENT,
		});

		getBrainsByPublishedSkillPackResponse =
			await getBrainsByPublishedSkillPackIPCFn({
				...formData,
			});
		if (getBrainsByPublishedSkillPackResponse['data']['error']) {
			return getBrainsByPublishedSkillPackResponse['data'];
		}

		return getBrainsByPublishedSkillPackResponse['data']['results'];
	}
};

export default getBrainsByPublishedSkillPack;
