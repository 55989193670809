const KeyEnum = {
	TAB: 9,
	ENTER: 13,
	LEFT_ARROW: 37,
	RIGHT_ARROW: 39,
	DOWN_ARROW: 40,
	UP_ARROW: 38,
	SPACE: 32,
	BACKSPACE: 8,
	ESC: 27,
	DELETE: 46,
};

export default KeyEnum;
