import React from 'react';
import {
	Box,
	Button,
	LightMode,
	useColorModeValue,
	Checkbox,
	useToast,
} from '@chakra-ui/react';

//Functions
import checkUpdates from '../../functions/updates/checkUpdates';
import getUpdates from '../../functions/updates/getUpdates';
import updateAll from '../../functions/updates/updateAll';
import updateOne from '../../functions/updates/updateOne';

//Components
import UpdatesMenu from './UpdatesMenu';

import GetProfileSlug from '../../util/ProfileSlug';
import restartBrainList from '../../functions/brain/restartBrainList';

const UpdatesItem = ({ item, updates, setUpdates, type, key }) => {
	//const [hover, setHover] = React.useState(false);
	const [isLoading, setLoading] = React.useState(false);
	const [isInstalled, setInstalled] = React.useState(false);
	const { slug } = GetProfileSlug();
	const toast = useToast();
	const handleUpdateOne = (arg) => {
		setLoading(true);
		updateOne(arg).then((res) => {
			if (res['error']) {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'Could not update skill',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			} else {
				restartBrainList({
					brainList: item.brains,
					slug,
					onDone: () => {
						setLoading(false);
						setInstalled(true);
						toast({
							title: 'Success!',
							description: 'Skill has been updated in the runtime',
							status: 'success',
							duration: 3000,
							isClosable: true,
						});
					},
					onError: () => {
						setLoading(false);
						toast({
							title: 'Error!',
							description: 'Could not restart workspaces after update',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
					},
					onLoading: () => {},
				});
			}
		});
	};

	return (
		<Box
			height="60px"
			width="100%"
			display="flex"
			alignItems="center"
			borderBottom="solid 1px"
			justifyContent="space-between"
			borderColor={useColorModeValue('#f2f2f2', '#4a4949')}
			// _hover={{ bg: useColorModeValue("", "#464646") }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			cursor="pointer"
			// onMouseEnter={() => {
			//   setHover(true);
			// }}
			// onMouseLeave={() => {
			//   setHover(false);
			// }}
		>
			<Box display="flex" alignItems="center" paddingLeft="1rem">
				<Checkbox size="sm" colorScheme="purple" />
				<Box fontSize="16px" marginLeft="1rem">
					Update{' '}
					<Box fontWeight="600" display="inline-block" color="purple.200">
						{item.skillPack
							? item.skillPack.name
							: item.publishedSkillPack
							? item.publishedSkillPack.name
							: null}
					</Box>{' '}
					to{' '}
					<Box fontWeight="600" display="inline-block" color="purple.200">
						{item.version}
					</Box>
					{/* {' '}
					in{' '}
					<Box fontWeight="600" display="inline-block" color="purple.200">
						{item.brains.length} brain runtimes
					</Box>{' '} */}
				</Box>
				{/* <Box fontSize="0.8rem" paddingX="0.3rem" marginBottom="0.4rem">
          .
        </Box>
        <Box fontSize="0.8rem">{Date.now()}</Box> */}
			</Box>
			{true ? (
				<Box display="flex" alignItems="center">
					<Button
						colorScheme={isInstalled ? 'green' : 'gray'}
						size="xs"
						marginRight="1rem"
						borderRadius="5px"
						width="7.5rem"
						paddingY="15px"
						onClick={() => handleUpdateOne({ item, slug })}
						isLoading={isLoading}
						loadingText={'Updating...'}
						isDisabled={isInstalled}
					>
						{isInstalled ? 'Updated' : 'Update'}
					</Button>
				</Box>
			) : null}
		</Box>
	);
};

const UpdateButtons = ({ updates }) => {
	const { slug } = GetProfileSlug();
	const [isLoading, setLoading] = React.useState(false);
	const toast = useToast();

	const handleUpdateAll = () => {
		setLoading(true);
		updateAll({ updates, slug }).then((res) => {
			setLoading(false);
			if (res['error']) {
				toast({
					title: 'Error!',
					description: 'Could not update skills',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			} else {
				toast({
					title: 'Success!',
					description: 'The skills have been updated across workspaces',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			}
			console.log(res);
		});
	};
	return (
		<Box
			height="60px"
			width="100%"
			display="flex"
			alignItems="center"
			borderBottom="solid 1px"
			justifyContent="space-between"
			borderColor={useColorModeValue('#f2f2f2', '#4a4949')}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			cursor="pointer"
			paddingLeft="1rem"
		>
			<Checkbox size="sm" colorScheme="purple" spacing="1rem">
				<Box fontSize="17px">Select all</Box>
			</Checkbox>
			<Box display="flex" alignItems="center">
				<Button
					size="xs"
					marginRight="1rem"
					borderRadius="5px"
					width="9.5rem"
					onClick={() => handleUpdateAll({ updates, slug })}
					paddingY="15px"
					isLoading={isLoading}
					loadingText={'Updating...'}
				>
					Update All
				</Button>
			</Box>
		</Box>
	);
};

const CheckButtons = ({ setUpdates }) => {
	const [isLoading, setLoading] = React.useState(false);
	const [autoUpdate, setAutoUpdate] = React.useState(true);
	const { slug } = GetProfileSlug();
	const toast = useToast();
	const handleCheckUpdates = () => {
		setLoading(true);
		checkUpdates({ slug }).then((res) => {
			setLoading(false);
			if (!res.data.error) {
				setUpdates(res.data);
				if (
					Object.keys(res.data.skillPacks).length === 0 &&
					Object.keys(res.data.publishedSkillPacks).length === 0
				) {
					toast({
						title: 'No updates found!',
						description: "You're up to date",
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				} else {
					toast({
						title: 'Updates found!',
						description: 'Please update to latest version',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				}
			} else {
				toast({
					title: 'Error!',
					description: 'Updates could not be fetched',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			}
			console.log(res.data);
		});
	};

	React.useState(() => {
		if (autoUpdate) {
			handleCheckUpdates();
		}
	}, [autoUpdate]);

	return (
		<LightMode>
			<Button
				colorScheme="purple"
				size="xs"
				borderRadius="5px"
				marginBottom="0.5rem"
				marginRight="1rem"
				onClick={handleCheckUpdates}
				width="9.5rem"
				paddingY="15px"
				isLoading={isLoading}
				loadingText={'Checking...'}
			>
				Check Updates
			</Button>
		</LightMode>
	);
};

const HorizontalMenu = () => {
	const [updates, setUpdates] = React.useState({});
	const [mode, setMode] = React.useState('Store Collections');

	React.useEffect(() => {
		getUpdates().then((res) => {
			if (res.data && !res.data.error) {
				setUpdates(res.data.results);
			}
		});
	}, []);

	return (
		<Box>
			<UpdatesMenu
				list={[
					{
						label: 'Store Collections',
						onClick: () => {
							setMode('Store Collections');
						},
						updatesNos:
							updates && updates.publishedSkillPacks
								? Object.keys(updates.publishedSkillPacks).length
								: null,
					},
					// {
					// 	label: 'Library Skills',
					// 	onClick: () => {
					// 		setMode('Library Skills');
					// 	},
					// 	updatesNos: updates.skillPacks
					// 		? Object.keys(updates.skillPacks).length
					// 		: null,
					// },
				]}
				mode={mode}
				setUpdates={setUpdates}
				LeftSection={CheckButtons}
			/>

			{mode === 'Library Skills' ? (
				<Box>
					{/* <UpdateButtons updates={updates} /> */}
					{updates.skillPacks
						? Object.keys(updates.skillPacks).map((key, id) => {
								console.log(updates['skillPacks'][key]);
								return (
									<Box>
										<UpdatesItem
											item={updates['skillPacks'][key]}
											setUpdates={setUpdates}
											updates={updates}
											type={'skillPacks'}
											key={key}
										/>
									</Box>
								);
						  })
						: null}
				</Box>
			) : null}
			{mode === 'Store Collections' ? (
				<Box>
					{/* <UpdateButtons updates={updates} /> */}
					{updates.publishedSkillPacks
						? Object.keys(updates.publishedSkillPacks).map((key, id) => {
								console.log(updates['publishedSkillPacks'][key]);
								return (
									<Box>
										<UpdatesItem
											item={updates['publishedSkillPacks'][key]}
											setUpdates={setUpdates}
											updates={updates}
											type={'publishedSkillPacks'}
											key={key}
										/>
									</Box>
								);
						  })
						: null}
				</Box>
			) : null}
		</Box>
	);
};

export default HorizontalMenu;
