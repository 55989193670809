/**
 * Brain Actions
 *
 * Action creators for Brain based actions.
 */

// Dependencies
// import { get } from "lodash";

// Action Types
import actionTypes from '../actionTypes';

/**
 * Add the Brain Object
 *
 * Attempt to set the robot object with list of fleets containing user.
 *
 * @param {Object} robots The robots list obtained from the backend for a given fleet.
 */
const addBrains = (brains, refresh) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.ADD_BRAINS,
		payload: {
			info: brains,
			refresh: refresh || false,
		},
	});
};

/**
 * Update Brain
 *
 * @param {Object}
 */
const updateBrain = (brain) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.UPDATE_BRAIN,
		payload: {
			info: brain,
		},
	});
};

/**
 * Update Brain
 *
 * @param {Object}
 */
const updateBrainById = (id, brain) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	console.log('Readying dispatch', id, brain);
	dispatch({
		type: actionTypes.UPDATE_BRAIN_BY_ID,
		payload: {
			info: brain,
			id: id,
		},
	});
};

/**
 * Delete a Brain Object
 *
 * Delete fleet robot given robotID.
 *
 * @param {Object} robotID The fleets list obtained from the backend.
 */
const removeBrain = (brainID) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.REMOVE_BRAIN,
		payload: {
			info: brainID,
		},
	});
};

/**
 * Clear Brains
 *
 * Clear brains.
 *
 */
const clearBrains = () => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.CLEAR_BRAINS,
		payload: {},
	});
};

// Export the actions.
export { addBrains, removeBrain, updateBrain, updateBrainById, clearBrains };
