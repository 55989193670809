/**
 * Navigation Actions
 *
 * Action creators for Navigation based actions.
 */

// Action Types
import actionTypes from '../actionTypes';

/**
 * Set Search Query
 *
 * Set the search query of the navigation search bar
 *
 * @param {query} query entered into the search bar
 */
const setNavSearchQuery = (status) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.SET_NAV_SEARCH_QUERY,
		payload: {
			info: status,
		},
	});
};

/**
 * Set Nav Route
 * @param {route} route
 */
const setNavRoute = (route) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_NAV_ROUTE,
		payload: {
			info: route,
		},
	});
};

/**
 * Set Nav Page Mode
 * @param {route} route
 */
const setNavPageMode = (mode) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_NAV_PAGE_MODE,
		payload: {
			info: mode,
		},
	});
};

const setLoggedIn = (boolean) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_NAV_LOGGED_IN,
		payload: {
			info: boolean,
		},
	});
};

// Export the actions.
export { setNavSearchQuery, setNavRoute, setNavPageMode, setLoggedIn };
