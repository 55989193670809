import React from 'react';
import { Box, useDisclosure } from '@chakra-ui/react';

//components
import HorizontalBoxCardWithDescription from '../Reusable/HorizontalBoxCardWithDescription';
import {
	ItemCard,
	ItemCardHome,
	ItemCardSkeletonHome,
} from '../Reusable/HorizontalBoxCardWithDescription';
import SkillDetailsModal from './SkillDetailsModal';

//functions
import getPublishedSkillPackList from '../../functions/publishedSkillPack/getPublishedSkillPackList';
import History from '../../util/History';

const SkillCard = ({ pack }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<ItemCardHome
				key={pack._id}
				thumbnail={
					pack.thumbnail ||
					'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
				}
				heading={pack.name}
				description={pack.shortDescription}
				count={pack.currentFlow.intents.length}
				version={pack.currentVersion}
				moduleDependencies={pack.currentFlow.modules || []}
				version={pack.currentVersion}
				onClick={() => {
					onOpen();
				}}
				scale={0.75}
			/>
			<SkillDetailsModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				publishedSkillPack={pack}
			/>
		</>
	);
};
/**
 * Installed Skills
 * @param {*} brains
 * @returns
 */
const InstalledSkills = ({ brains }) => {
	const [isLoading, setLoading] = React.useState(false);
	const [packs, setPacks] = React.useState([]);
	const loadingList = [1, 2, 3, 4, 5];

	const getInstalledPublishedSkillPacks = (brains) => {
		let list = [];
		for (let id of brains.allIds) {
			for (let obj of brains.byId[id].publishedSkillPacks) {
				if (!list.includes(obj.publishedSkillPack)) {
					list.push(obj.publishedSkillPack);
				}
			}
		}
		console.log('installed skills', list);
		return list;
	};

	React.useEffect(() => {
		setLoading(true);
		getPublishedSkillPackList(getInstalledPublishedSkillPacks(brains))
			.then((res) => {
				setPacks(res);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	}, [brains]);

	return (
		<Box marginY="0.2rem">
			{!isLoading && packs.length === 0 ? (
				<Box
					textAlign="center"
					height="100%"
					width="100%"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					paddingTop="2rem"
				>
					<Box>You don't have any collections installed</Box>
					<Box
						color="purple.300"
						onClick={() => {
							History.push('/store/skills');
						}}
						_hover={{ color: 'purple.200' }}
						cursor="pointer"
					>
						Discover Maya collections
					</Box>
				</Box>
			) : null}
			<HorizontalBoxCardWithDescription>
				{isLoading
					? loadingList.map((item) => {
							return <ItemCardSkeletonHome key={item} scale={0.75} />;
					  })
					: packs.length > 0
					? packs.map((pack) => {
							return <SkillCard pack={pack} />;
					  })
					: null}
			</HorizontalBoxCardWithDescription>
		</Box>
	);
};

export default InstalledSkills;
