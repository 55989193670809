import axios from 'axios'
import config from '../../config/config'

const CMS_API_ROOT = config.app.CMS_BACKEND

async function getCommands(categories = [], apps = [], search='') {
    const request = {
        method: 'POST',
        url: `${CMS_API_ROOT}/skillpages/filter`,
        data: {
            search,
            _limit: 50
        }
    }

    if (categories) request.data.categories = categories.map(c => c._id)
    if (apps) request.data.apps = apps.map(a => a._id)
    
    try {
        const response = await axios(request)
        return response.data
    } catch (e) {
        return []
    }
}

export default getCommands