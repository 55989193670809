import React from 'react';
import {
	Button,
	Box,
	Image,
	Tooltip,
	SimpleGrid,
	useColorModeValue,
	Badge,
	useColorMode,
	LightMode,
	useDisclosure,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Flex,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import ReactPlayer from 'react-player';
import SimpleBar from 'simplebar-react';
import { GoVerified } from 'react-icons/go';
import 'simplebar/dist/simplebar.min.css';
import { AiFillApple, AiFillWindows } from 'react-icons/ai';
import { FaCloud } from 'react-icons/fa';
import { DiLinux } from 'react-icons/di';
import { motion, AnimatePresence } from 'framer-motion';
import isElectron from 'is-electron';
import ReactMarkdown from 'react-markdown';

// Import components
import IconContainer from '../../../util/React_Icon';
import { ModuleCard } from '../../Module/ModuleCard';
import CommandsList from '../../Commands/CommandList';
import MayaSkeleton from '../../../util/ContentLoaders/MayaSkeleton';
import SkillPackInstallModal from '../../SkillPack/SkillPackInstallModal';
import { ModuleItemCard } from '../../Module/ModuleItem';

//functions
import addToLibraryFromStore from '../../../functions/library/addToLibraryFromStore';
import getSkillpackFlow from '../../../functions/skillPack/getSkillpackFlow';
import _ from 'lodash';

// Import images
import { web, csv, email, gpio } from '../../../assets/thumbnails';
import getSkillPack from '../../../functions/skillPack/getSkillPack';

//Analytics
import analytics from '../../../util/Analytics';

//Utils
import AppProtocol from '../../../util/AppProtocol';
import AppProtocolEvents from '../../../util/AppProtocol/Events';
import getStoreSkillPacks from '../../../functions/store/getStoreSkillPacks';

export const flowDetails = {
	thumbnail: web,
	name: 'Ride-Sharing Web Scraper',
	version: '1.0.1',
	shortDescription:
		'Scrape through ride-sharing websites to get prices and things.',
	developerAccount: { name: 'Maya Technologies Inc.' },
	rating: {
		stars: 3.4,
		reviewCount: 5,
	},
	categories: [
		{ value: 'web', label: 'Web & HTML' },
		{ value: 'essentials', label: 'Essentials' },
		{ value: 'iot', label: 'IoT Sensors' },
	],

	platforms: [
		{ value: 'cloud', label: 'Maya Cloud' },
		{ value: 'linux', label: 'Linux' },
		{ value: 'mac', label: 'MacOS' },
		{ value: 'win', label: 'Windows' },
	],
	pricingType: 'paid',
	pricingAmt: 3,
	pricingRate: '/ mo',

	previews: [
		{
			img: csv,
			type: 'video',
			label: 'Intro',
			url: 'https://www.youtube.com/watch?v=9NoxljNbovM',
		},
		{
			img: 'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/static-1591613891128.jpg',
			type: 'image',
			label: 'Video',
		},
		{ img: email, type: 'image', label: 'Intro' },
	],
	longDescription:
		"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic..",
	installedModules: [
		{
			_id: 1213213212132,
			name: 'Web & HTML',
			description:
				'This is sample description that fits within the criteria.',
		},
		{
			_id: 1213212132,
			name: 'CSV & Excel',
			description:
				'This is sample description that might not fit within criteria.',
		},
		{
			_id: 121321123124132,
			name: 'Iterators',
			description: "This is sample description that I don't care about.",
		},
	],
};

const StarRating = ({ rating }) => {
	const { colorMode } = useColorMode();
	const emptyStar = {
		light: '#c4c4c4',
		dark: '#636363',
	};
	return (
		<Box d="flex" mt="1" mb="1" alignItems="center">
			{Array(5)
				.fill('')
				.map((_, i) => (
					<StarIcon
						boxSize="0.8rem"
						mr="0.1rem"
						key={i}
						color={i < rating.stars ? 'yellow.400' : emptyStar[colorMode]}
					/>
				))}
			<Box
				as="span"
				ml="2"
				color={useColorModeValue('gray.600', 'white')}
				fontSize="xs"
				fontFamily="body"
			>
				{rating.reviewCount} reviews
			</Box>
		</Box>
	);
};

const CategoryTab = ({ name }) => {
	return (
		<Box mr="1" paddingY="0.1rem">
			<Badge
				variant="subtle"
				colorScheme="purple"
				size="xs"
				fontSize="0.7rem"
			>
				{name}
			</Badge>
		</Box>
	);
};

const Categories = ({ categories }) => {
	return (
		<Box mb="1">
			<Box display="flex" width="25rem" flexWrap="wrap">
				{categories.map((category, i) => {
					return <CategoryTab name={category.label} key={i} />;
				})}
			</Box>
		</Box>
	);
};

const PlatformList = ({ platforms }) => {
	var iconColor = useColorModeValue('#656565', 'white');
	const platformLogos = {
		MacOS: (
			<IconContainer
				icon={<AiFillApple />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.5rem',
				}}
			/>
		),
		Linux: (
			<IconContainer
				icon={<DiLinux />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.5rem',
				}}
			/>
		),
		Windows: (
			<IconContainer
				icon={<AiFillWindows />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.5rem',
				}}
			/>
		),
		'Maya Cloud': (
			<IconContainer
				icon={<FaCloud />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.5rem',
				}}
			/>
		),
	};
	return (
		<Box display="flex">
			{platforms.map((platform, i) => {
				return (
					<Tooltip
						label={platform.label}
						placement="bottom"
						zIndex="5000"
						key={i}
					>
						<Box ml="1">{platformLogos[platform.label]}</Box>
					</Tooltip>
				);
			})}
		</Box>
	);
};

const PreviewList = ({ previews }) => {
	return (
		<Box
			display="flex"
			mt="1"
			flexDirection="column"
			alignItems="center"
			justifyItems="flex-start"
			overflow="auto"
		>
			{previews.map((preview, i) => {
				return preview.type === 'image' ? (
					<Image
						key={i}
						src={preview.img}
						borderRadius="0.2rem"
						objectFit="cover"
						width="100%"
						mb={2}
						height="100%"
						boxShadow={
							'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px'
						}
					/>
				) : (
					<ReactPlayer
						key={i}
						url={preview.url}
						light
						mb={2}
						width="100%"
						height="9rem"
						style={{
							boxShadow:
								'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px',
						}}
						config={{
							youtube: {
								playerVars: { controls: 1 },
							},
						}}
					/>
				);
			})}
		</Box>
	);
};

const InstalledModules = ({ moduleDependencies, currentBrainId }) => {
	return (
		<Box padding="1.5rem">
			<Box
				fontSize="1.2rem"
				fontWeight="600"
				fontFamily="body"
				color={'white'}
			>
				Installed Modules
			</Box>
			<SimpleGrid columns={3} spacing={0}>
				{moduleDependencies.map((moduleDependency, i) => (
					<ModuleItemCard
						key={i}
						module={moduleDependency.module}
						currentBrainId={currentBrainId}
					/>
				))}
			</SimpleGrid>
		</Box>
	);
};

const ModuleList = ({ moduleList }) => {
	const [selected, setSelected] = React.useState('');

	const colorArray = [
		'purple.500',
		'red.500',
		'blue.500',
		'orange.500',
		'yellow.500',
	];
	return (
		<SimpleGrid columns={3} spacing={1}>
			{moduleList.map((module, i) => (
				<ModuleCard
					key={i}
					module={module}
					moduleColor={colorArray[i % 5]}
					selected={module._id === selected}
					setSelected={setSelected}
					canInstall={false}
				/>
			))}
		</SimpleGrid>
	);
};

// TODO: Clean this up and move these markdown components to another file

const H1 = (props) => {
	return (
		<Box fontSize="28px" fontWeight="600">
			{props.children}
		</Box>
	);
};

const H2 = (props) => (
	<Box fontSize="24px" margin="8px 0px 4px 0px">
		{props.children}
	</Box>
);

const H3 = (props) => (
	<Box fontSize="22px" margin="4px 0px 2px 0px">
		{props.children}
	</Box>
);

const Li = (props) => (
	<Box marginLeft="28px" fontSize="16px">
		<li>{props.children}</li>
	</Box>
);

const P = (props) => <Box fontSize="16px">{props.children}</Box>;

const markdownComponents = {
	h1: ({ node, ...props }) => <H1 {...props} />,
	h2: ({ node, ...props }) => <H2 {...props} />,
	h3: ({ node, ...props }) => <H3 {...props} />,
	li: ({ node, ...props }) => <Li {...props} />,
	p: ({ node, ...props }) => <P {...props} />,
};

function convertToLegacyPack(newPsp) {
	const newVersionsArray = newPsp.versions.map((v) => {
		return {
			...v,
			_id: v.id,
		};
	});
	const legacyPsp = {
		...newPsp,
		versions: newVersionsArray,
	};

	return legacyPsp;
}

export const StorePackDetails = ({
	publishedSkillPack,
	currentBrainId,
	isPublish,
	showModuleCard = true,
}) => {
	const [mode, setMode] = React.useState('previews');
	// const [pack, setPack] = React.useState(publishedSkillPack);
	// const [currentVersionDetails, setCurrentVersionDetails] = React.useState();
	const [packWithDetails, setPackWithDetails] =
		React.useState(publishedSkillPack);
	const [flowLoading, setFlowLoading] = React.useState(false);

	React.useEffect(() => {
		// console.log('newPsp', publishedSkillPack);
		if (publishedSkillPack?.currentFlow) {
			setPackWithDetails(publishedSkillPack);
			setFlowLoading(true);
			return;
		}
		// getStoreSkillPacks({ _id: publishedSkillPack.id })
		// 	.then((res) => {
		// 		console.log('newPsp big', res);
		// 		setPackWithDetails(res);
		// 		setFlowLoading(false);
		// 	})
		// 	.catch((e) => setFlowLoading(false));
		// getSkillpackFlow(publishedSkillPack._id)
		// 	.then(res => {
		// 		console.log('newPsp flow', res)
		// 		setCurrentFlow(res)
		// 		setFlowLoading(false)
		// 	})
		// 	.catch(e => setFlowLoading(false))
	}, [publishedSkillPack]);

	const tabStyles = {
		bg: '#4e4e4e',
		borderTopRadius: '6px',
		marginRight: '4px',
		_selected: {
			bg: '#3e3e3e',
			borderTopRadius: '6px',
		},
		_active: {
			bg: '#3e3e3e',
			borderTopRadius: '6px',
		},
		// _hover: {
		//   bg: '#535353',
		//   borderTopRadius: '6px'
		// },
		_focus: {
			outline: 'none',
		},
	};

	const tabPanelStyles = {
		bg: '#3e3e3e',
		borderBottomRadius: '6px',
		borderTopRightRadius: '6px',
		height: '100%',
		overflow: 'auto',
		maxHeight: '450px',
	};

	const {
		isOpen: isInstallOpen,
		onOpen: onInstallOpen,
		onClose: onInstallClose,
	} = useDisclosure();

	const { colorMode } = useColorMode();
	const fontColor = {
		light: '#444444',
		dark: 'white',
	};
	const borderColor = {
		light: 'gray.100',
		dark: 'dashboard.border_dark',
	};

	return (
		<SimpleBar
			style={{ maxHeight: '30rem' }}
			forceVisible="y"
			autoHide={true}
		>
			<Box>
				<Box
					display="flex"
					borderBottom="solid 1px"
					borderColor={useColorModeValue(
						'gray.100',
						'dashboard.border_dark'
					)}
					pt="1rem"
					pb="0.8rem"
					justifyContent="space-between"
					paddingX="1rem"
				>
					<Box display="flex">
						<Image
							src={
								publishedSkillPack.thumbnail ||
								'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
							}
							width="15rem"
							height="10rem"
							ml="2"
							borderRadius="0.5rem"
							objectFit="cover"
							alt="cardimage"
							boxShadow={
								'rgba(50, 50, 93, 0.10902) 0px 2px 10px, rgba(0, 0, 0, 0.296078) 0px 2px 2px'
							}
						/>
						<Box marginLeft="1rem">
							<Box
								fontSize="1.2rem"
								fontWeight="600"
								fontFamily="body"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								color={useColorModeValue('#444444', 'white')}
							>
								<Box>{publishedSkillPack.name}</Box>
							</Box>
							<Box
								display="flex"
								flexDirection="columns"
								justifyContent="space-between"
								alignItems="center"
							>
								<Box
									fontSize="14px"
									fontFamily="body"
									width="90%"
									height="3rem"
									color={useColorModeValue('gray.600', '#d7d7d7')}
								>
									{publishedSkillPack.shortDescription}
								</Box>
							</Box>
							<Box display="flex" alignItems="center">
								<IconContainer
									icon={<GoVerified />}
									value={{
										color: useColorModeValue('#8A8A8A', 'white'),
										className: 'global-class-name',
										size: '0.7rem',
									}}
								/>

								<Box
									fontSize="0.8rem"
									fontFamily="body"
									fontWeight="600"
									_hover={{ textDecoration: 'underline' }}
									cursor="pointer"
									marginRight="0.5rem"
									marginLeft="0.3rem"
									color={useColorModeValue('#444444', 'white')}
								>
									{publishedSkillPack.developerAccount
										? publishedSkillPack.developerAccount.name
										: 'Maya Official'}
								</Box>

								{/* <StarRating
									rating={{
										stars: 3,
										reviewCount: 5,
									}}
								/> */}
							</Box>
							<Categories
								categories={
									publishedSkillPack.categories
										? publishedSkillPack.categories
										: []
								}
							/>
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								{isPublish ? null : (
									<Box>
										<LightMode>
											{isElectron() ? (
												<Button
													colorScheme="purple"
													size="sm"
													mr="2"
													borderRadius="5px"
													variant={'solid'}
													paddingX="2rem"
													onClick={() => {
														// if (mode.includes("install")) {
														//   setMode("previews");
														// } else {
														//   setMode("install");
														// }
														analytics.track(
															'Store Pack Details : Install Now Button',
															{
																_id: publishedSkillPack._id,
																name: publishedSkillPack.name,
															}
														);
														onInstallOpen();
													}}
												>
													Install
												</Button>
											) : (
												<Button
													colorScheme="purple"
													size="xs"
													mr="2"
													borderRadius="5px"
													variant={'solid'}
													paddingX="0.9rem"
													onClick={() => {
														// if (mode.includes("install")) {
														//   setMode("previews");
														// } else {
														//   setMode("install");
														// }
														analytics.track(
															'Store Pack Details : Install Now Button',
															{
																_id: publishedSkillPack._id,
																name: publishedSkillPack.name,
															}
														);
														//Open in app via protocol
														AppProtocol(
															AppProtocolEvents.openInApp,
															`store/skills?id=${publishedSkillPack._id}`
														);
													}}
												>
													Open In App
												</Button>
											)}
										</LightMode>
										{publishedSkillPack.publishMetadata &&
										flowLoading ? (
											<SkillPackInstallModal
												onClose={onInstallClose}
												isOpen={isInstallOpen}
												currentBrainId={currentBrainId}
												pack={publishedSkillPack}
												publishedSkillPackId={
													publishedSkillPack._id
												}
											/>
										) : null}
									</Box>
								)}
							</Box>
						</Box>
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
						alignItems="flex-end"
					>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="flex-end"
						>
							<PlatformList
								platforms={
									publishedSkillPack.platforms
										? publishedSkillPack.platforms
										: []
								}
							/>
							<Box
								fontFamily="body"
								bg="purple.500"
								paddingX="0.3rem"
								borderRadius="0.2rem"
								fontSize="0.8rem"
								color="white"
								maxWidth="3rem"
								marginTop="2rem"
								textAlign="center"
								fontWeight="700"
							>
								{publishedSkillPack.version}
							</Box>
						</Box>
						<Box fontSize="12px" marginTop="33px">
							{publishedSkillPack.currentVersion}
						</Box>
						<Box
							fontFamily="body"
							fontWeight="600"
							display="flex"
							alignItems="flex-end"
							color={useColorModeValue('#444444', 'white')}
						>
							<Box>
								{publishedSkillPack.pricingAmount
									? '₹' + publishedSkillPack.pricingAmt
									: 'Free'}
							</Box>
							{publishedSkillPack.pricingType === 'paid' ? (
								<Box
									fontFamily="body"
									fontWeight="400"
									fontSize="0.8rem"
									ml="0.1rem"
								>
									{publishedSkillPack.pricingRate}
								</Box>
							) : null}
						</Box>
					</Box>
				</Box>
				<AnimatePresence>
					<motion.div
						layout
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<Box
							borderBottom="solid 1px"
							borderColor={borderColor[colorMode]}
							display="flex"
							flexDirection="row"
							paddingX="1rem"
							paddingY="0.5rem"
							width="100%"
							bg={{ light: '#fafcff', dark: '#383838' }[colorMode]}
							minH="280px"
						>
							<Box display="flex" flexDirection="row" width="100%">
								<Box mt="1" mb="2" width="35%" paddingX="0.5rem">
									<Box
										fontSize="1.2rem"
										fontWeight="600"
										fontFamily="body"
										color={fontColor[colorMode]}
									>
										Previews
									</Box>
									{/* <Box
										fontSize="14px"
										mt="4px"
										mb="24px"
										fontFamily="body"
										color='#d7d7d7'
									>
										{publishedSkillPack.longDescription}
									</Box> */}
									<PreviewList
										previews={
											publishedSkillPack.previews
												? publishedSkillPack.previews
												: []
										}
									/>
								</Box>

								<Box // The thin separator between previews and tabs
									borderLeft="1px solid"
									borderColor={borderColor[colorMode]}
									marginY="5"
									marginX="6"
									bg="yellow"
								/>

								<Box
									display="flex"
									flexDirection="column"
									width="60%"
									// height='300px'
								>
									<Tabs // line,enclosed, enclosed-colored, soft-rounded, solid-rounded
										variant="unstyled"
										height="100%"
										// bg='tomato'
										display="flex"
										flexDirection="column"
										fontSize="16px"
										color="#d7d7d7"
										mt={1}
										mb={2}
									>
										<TabList>
											<Tab {...tabStyles}>Details</Tab>
											<Tab {...tabStyles}>Commands</Tab>
											<Tab {...tabStyles}>Subflows</Tab>
										</TabList>
										<TabPanels height="100%">
											<TabPanel {...tabPanelStyles} color="#d7d7d7">
												<ReactMarkdown
													components={markdownComponents}
												>
													{publishedSkillPack.longDescription}
												</ReactMarkdown>
											</TabPanel>

											<TabPanel
												{...tabPanelStyles}
												display="flex"
												flexDirection="column"
											>
												{publishedSkillPack?.currentFlow
													?.intents ? (
													<CommandsList
														commands={
															publishedSkillPack.currentFlow
																.intents
														}
														height="12rem"
														clickable={false}
													/>
												) : (
													<Box>
														We're still working on getting this
														information to you :)
													</Box>
												)}
											</TabPanel>

											<TabPanel {...tabPanelStyles}>
												Coming Soon!
											</TabPanel>
										</TabPanels>
									</Tabs>
								</Box>

								{/* {publishedSkillPack?.currentFlow?.intents &&
									publishedSkillPack?.currentFlow?.intents?.length > 0 ? (
									<Box mt="1" mb="2" height="310px" bg='green'>
										<Box
											fontSize="1.2rem"
											fontWeight="600"
											fontFamily="body"
											mb="2"
											color={fontColor[colorMode]}
										>
											{`Commands (${publishedSkillPack.currentFlow.intents.length})`}
										</Box>
										<CommandsList
											commands={publishedSkillPack.currentFlow.intents}
											height="290px"
											clickable={false}
										/>
									</Box>
								) : null} */}
							</Box>
						</Box>
						{showModuleCard && (
							<InstalledModules
								currentBrainId={currentBrainId}
								moduleDependencies={
									packWithDetails?.currentFlow?.modules
										? packWithDetails?.currentFlow?.modules
										: []
								}
							/>
						)}
					</motion.div>
				</AnimatePresence>
			</Box>
		</SimpleBar>
	);
};

// <Box
//                 borderBottom="solid 1px"
//                 borderColor={borderColor[colorMode]}
//                 paddingX="1.5rem"
//               >
//                 <Box mt="3" mb="3">
//                   <Box
//                     fontSize="1.2rem"
//                     fontWeight="600"
//                     fontFamily="body"
//                     mb="2"
//                     color={fontColor[colorMode]}
//                   >
//                     Installed Modules
//                   </Box>
//                   <ModuleList moduleList={publishedSkillPack.installedModules} />
//                 </Box>
//               </Box>

export const StorePackItemSkeleton = () => {
	return (
		<Box>
			<Box pt={4} display="flex" paddingX="1rem">
				<MayaSkeleton width="15rem" height="10rem" borderRadius="5px" />
				<Box ml={2}>
					<MayaSkeleton width="15rem" height="2rem" borderRadius="5px" />
					<MayaSkeleton
						width="7rem"
						height="0.8rem"
						mt="2"
						borderRadius="5px"
					/>

					<MayaSkeleton
						width="12rem"
						height="0.8rem"
						mt="2"
						borderRadius="5px"
					/>
					<MayaSkeleton
						width="15rem"
						height="0.8rem"
						mt="2"
						borderRadius="5px"
					/>
					<Box display="flex">
						<MayaSkeleton
							width="5rem"
							height="1rem"
							mt="2"
							borderRadius="5px"
						/>
						<MayaSkeleton
							width="5rem"
							height="1rem"
							mt="2"
							ml={2}
							borderRadius="5px"
						/>
						<MayaSkeleton
							width="5rem"
							height="1rem"
							mt="2"
							ml={2}
							borderRadius="5px"
						/>
					</Box>
					<Box display="flex">
						<MayaSkeleton
							width="10rem"
							height="1.7rem"
							mt="2"
							borderRadius="5px"
						/>
						<MayaSkeleton
							width="10rem"
							height="1.7rem"
							mt="2"
							ml={2}
							borderRadius="5px"
						/>
					</Box>
				</Box>
			</Box>
			<Box pt={6} paddingX="1rem">
				<MayaSkeleton width="8rem" height="2rem" borderRadius="5px" />
				<Box display="flex" pt={3}>
					<MayaSkeleton width="30%" height="8rem" borderRadius="5px" />
					<MayaSkeleton
						width="30%"
						height="8rem"
						ml={2}
						borderRadius="5px"
					/>
					<MayaSkeleton
						width="30%"
						height="8rem"
						ml={2}
						borderRadius="5px"
					/>
				</Box>
				<Box pt={2}>
					<MayaSkeleton width="50%" height="0.7rem" borderRadius="5px" />
					<MayaSkeleton
						width="50%"
						height="0.7rem"
						mt={2}
						borderRadius="5px"
					/>
				</Box>
			</Box>
		</Box>
	);
};
