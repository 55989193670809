import isElectron from 'is-electron';

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import { store } from '../../redux/store';

/**
 * Function to sync contacts service
 */
const syncService = async ({ configDetails }) => {
	const { user } = store.getState();
	var syncServiceResponse, syncServiceIPCFn;
	let data = {
		config: {
			user: user.info.id,
			resource: configDetails.resource,
			type: configDetails.type,
			meta: configDetails.config.variables[0],
			values: {},
		},
	};

	if (isElectron()) {
		if (window.navigator.onLine) {
			syncServiceIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.syncContactService.EVENT,
			});
			syncServiceResponse = await syncServiceIPCFn(data);
			if (!syncServiceResponse.data.error) {
				return syncServiceResponse['data']['results'];
			}
		}
	} else {
		return {
			data: {
				error: true,
				message:
					'This service is available only for Maya Desktop Application',
			},
		};
	}
};

export default syncService;
