import * as React from 'react';
import Select from 'react-select';

export const MultiSelect = ({ name, list, initial, onChangeFn }) => {
	return (
		<Select
			isMulti
			name={name}
			options={list}
			defaultValue={initial}
			onChange={onChangeFn}
			className="basic-multi-select"
			classNamePrefix="select"
			styles={{
				control: (styles, state) => ({
					...styles,
					backgroundColor: '#3c3c3c1f',
					borderColor: state.isFocused ? '#9F7AEA' : null,
					border: state.isFocused ? 'solid 1px' : null,
					width: '26.5rem',
					fontSize: '0.8rem',
					color: state.isFocused ? '#718096' : '#A0AEC0',
					boxShadow: state.isFocused
						? '0 0 0 1px #ffffff3d'
						: '0 0 0 1px #ffffff3d',
					fontFamily: 'Lato',
					'&:hover': {
						borderColor: state.isFocused ? '#9F7AEA' : null,
					},
				}),
				option: (styles, state) => ({
					...styles,
					color: state.isSelected ? '#FFF' : styles.color,
					fontFamily: 'Lato',
					fontSize: '0.9rem',
					backgroundColor: '#3c3c3c',
				}),
				menu: (styles, state) => ({
					...styles,
					width: '26.5rem',
				}),
			}}
		/>
	);
};
