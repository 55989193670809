import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

//redux
import { store } from '../../redux/store';

const getBrainFlows = async ({ brainId, slug }) => {
    const { user } = store.getState()
    const profileSlug = slug ? slug : user.info.id;

    if (!isElectron()) {
        return {
            status: 'error',
            data: { error: 'The getBrainFlows function only works within electron at the moment' }
        }
    }

    const getBrainFlowsFn = IPCFunction({
        EVENT: IPCRoutes.ipcService.getBrainFlows.EVENT,
        PATH_QUERY: brainId,
        URL_PARAM: {
            profileSlug
        }
    })

    const getBrainFlowsResponse = await getBrainFlowsFn()
    console.log('yeet getBrainFlowsResponse', getBrainFlowsResponse)

    if (getBrainFlowsResponse['data']['error']) {
		return getBrainFlowsResponse['data'];
	} else {
		getBrainFlowsResponse['data']['results']['_id'] = brainId;
		return getBrainFlowsResponse['data']['results'];
	}
}

export default getBrainFlows