import React from 'react';
import {
	Box,
	Image,
	Stack,
	useColorMode,
	useDisclosure,
	useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import _ from 'lodash';

import { GoVerified } from 'react-icons/go';
import { RiAlertFill } from 'react-icons/ri';
import IconContainer from '../../util/React_Icon';
import {
	FiPackage,
	FiCheckCircle,
	FiGitPullRequest,
	FiDownload,
} from 'react-icons/fi';
import { BsCheckCircle } from 'react-icons/bs';

//Components
import HorizontalItem from '../Reusable/HorizontalItem';
import {
	InstallButton,
	UninstallButton,
	ConfigureModuleButton,
} from './ModuleItemButtons';
import TagWithIcon from '../Reusable/TagWithIcon';
import ModuleInstallModal from './ModuleInstallModal';
import { HorizontalCard } from '../Reusable/HorizontalCard';
import { MdComputer } from 'react-icons/md';
import NodeItem from '../Reusable/NodeItem';

/**
 * ModuleItemLeft is used to display the module information you see on the left
 * of the card. It's a separate component because we want to reuse it in other places.
 * Find at Store > Modules
 *
 * @param {object} module                 The module info to be displayed
 * @param {object} currentVersionDetails  Contains information about currently installed
 *                                        version (version, number of nodes, etc)
 * @param {function} onClick              Called when the user clicks on the module card
 */
export const ModuleItemLeft = ({
	module,
	currentVersionDetails,
	onClick,
	isInstalled,
	isInstalledGlobal,
}) => {
	const { colorMode } = useColorMode();
	return (
		<Box display="flex" alignItems="center" onClick={onClick} height="100%">
			<Box
				height="100%"
				display="flex"
				alignItems="center"
				paddingX="5px"
				width="140px"
				justifyContent="center"
			>
				<Image
					src={module.thumbnail}
					objectFit="cover"
					borderRadius="5px"
				/>
			</Box>
			<Box>
				<Box
					color="#555555"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						fontSize="20px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
					>
						{module.name}
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Box
						fontSize="17px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
						opacity="0.7"
					>
						{_.truncate(module.description, {
							length: 90,
							separator: /,? +/,
						})}
					</Box>
				</Box>
				<Box
					color="#555555"
					// fontSize="1rem"
					display="flex"
					flexDirection="row"
					alignItems="center"
					mt="8px"
				>
					<TagWithIcon text={'Maya Official'} icon={<GoVerified />} />
					{process.env.NODE_ENV === 'development' &&
					currentVersionDetails ? (
						<TagWithIcon
							text={currentVersionDetails.version}
							icon={<FiGitPullRequest />}
						/>
					) : null}
					<TagWithIcon
						text={
							currentVersionDetails
								? `${currentVersionDetails.nodes.length} nodes`
								: `0 nodes`
						}
						icon={<FiPackage />}
					/>
					{/* <TagWithIcon
						text={`${module.totalInstalls} installs`}
						icon={<FiDownload />}
					/> */}
					{isInstalled ? (
						<TagWithIcon text="Added" icon={<FiCheckCircle />} />
					) : null}
					{isInstalledGlobal ? (
						<TagWithIcon text={'installed'} icon={<FiDownload />} />
					) : null}
				</Box>
			</Box>
		</Box>
	);
};

const SelectedConfigProfile = ({ name }) => {
	const { colorMode } = useColorMode();
	const color = { light: '#6C6C6C', dark: 'white' };
	return (
		<Box textAlign="right" height="55px" mr="3">
			<Box fontWeight="600">
				{name ? 'Selected Config' : 'No Config Selected'}
			</Box>
			{name ? (
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					<IconContainer
						icon={<GoVerified />}
						value={{
							color: color[colorMode],
							className: 'global-class-name',
							size: '0.7rem',
						}}
					/>
					<Box fontSize="16px" ml="1" color={color[colorMode]}>
						{name}
					</Box>
				</Box>
			) : (
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					<IconContainer
						icon={<RiAlertFill />}
						value={{
							color: color[colorMode],
							className: 'global-class-name',
							size: '0.8rem',
						}}
					/>
					<Box
						fontSize="16px"
						ml="1"
						opacity="0.8"
						mb="1"
						color={color[colorMode]}
					>
						none
					</Box>
				</Box>
			)}
		</Box>
	);
};

/**
 * ModuleItem renders the module cards.
 * Find at Store > Modules
 */
export const ModuleItem = ({
	moduleId,
	module,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	profilesButton,
	addButton,
	setModuleList,
	moduleList,
	isModuleLoading,
	isInstalled,
	isInstalledGlobal,
	updateBrainById,
	// version,
}) => {
	const [hoveredButton, setHoveredButton] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	// const [currentVersion, setCurrentVersion] = useState(
	// 	version ? version : module.currentVersion
	// );
	const [currentVersionDetails, setCurrentVersionDetails] = React.useState(
		module.currentVersionModule
	);

	React.useEffect(() => {
		setIsOpen(isItemExpanded);
	}, [isItemExpanded]);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<HorizontalItem
			isOpen={isOpen}
			onClickItem={
				wholeItemActive
					? () => {
							toggleOpen();
							onClick(moduleId);
					  }
					: () => onClick(moduleId)
			}
			selected={selected}
			itemLeft={
				<ModuleItemLeft
					module={module}
					currentVersionDetails={currentVersionDetails}
					onClick={() => {
						toggleOpen();
						onClick(moduleId);
					}}
					isInstalledGlobal={isInstalledGlobal}
					isInstalled={isInstalled}
				/>
			}
			buttons={
				<Box display="flex" flexDirection="row" alignItems="center">
					{profilesButton ? (
						<ConfigureModuleButton
							module={module}
							moduleId={moduleId}
							currentBrainId={currentBrainId}
							currentVersionDetails={currentVersionDetails}
							setModuleList={setModuleList}
							moduleList={moduleList}
							isModuleLoading={isModuleLoading}
						/>
					) : null}
					{addButton ? (
						isInstalled ? (
							<UninstallButton
								onClick={() => {
									toggleOpen();
									onClick(moduleId);
								}}
								module={module}
								setHoveredButton={setHoveredButton}
								hoveredButton={hoveredButton}
								currentBrainId={currentBrainId}
								currentVersionDetails={currentVersionDetails}
								updateBrainById={updateBrainById}
							/>
						) : (
							<InstallButton
								onClick={() => {
									toggleOpen();
									onClick(moduleId);
								}}
								module={module}
								setHoveredButton={setHoveredButton}
								hoveredButton={hoveredButton}
								currentBrainId={currentBrainId}
								currentVersionDetails={currentVersionDetails}
								updateBrainById={updateBrainById}
								isInstalled={isInstalled}
							/>
						)
					) : null}
				</Box>
			}
			isItemExpanded={isItemExpanded}
			//expandedContent={<Box height="120px">Hello</Box>}
		/>
	);
};

/**
 * ModuleItemCard renders the module cards.
 * Find at Store > Modules
 */
export const ModuleItemCard = ({
	module,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	profilesButton,
	addButton,
	setModuleList,
	moduleList,
	isModuleLoading,
	isInstalled,
	isInstalledGlobal,
	updateBrainById,
	version,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentVersion, setCurrentVersion] = useState(
		version ? version : module.currentVersion
	);
	const [currentVersionDetails, setCurrentVersionDetails] = React.useState(
		_.find(module.versions, function (versionDetails) {
			return versionDetails.version === currentVersion;
		})
	);
	/**
	 * List of Node objects in the current version
	 * of the module.
	 */
	const nodes = React.useMemo(() => {
		try {
			/**
			 * @type {import('./Module').ModuleEntity}
			 */
			const moduleEntity = module;
			const { currentVersion, versions = [] } = moduleEntity;
			if (!currentVersion || !versions.length)
				throw new Error('currentVersion || versions.length is invalid!');
			const currentVersionObj = versions.find(
				(obj) => obj.version === currentVersion
			);
			if (!currentVersionObj) throw new Error(`currentVersionObj invalid!`);
			return currentVersionObj.nodes
				.filter((obj) => typeof obj !== 'string')
				.map((obj) => ({
					title: obj.name,
					description: obj.description,
				}));
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [module]);

	return (
		<Box>
			<HorizontalCard
				key={module._id}
				thumbnail={module.thumbnail}
				title={module.name}
				description={module.description}
				onClick={() => {
					onOpen();
				}}
				isActive={isInstalled}
				tags={[
					{ text: `${nodes.length} nodes`, icon: <FiPackage /> },
					{
						text: `Installed`,
						icon: <BsCheckCircle />,
						disabled: !isInstalled,
					},
				]}
				// expandedSection={
				// 	<Stack direction="row" p="2" overflow="auto">
				// 		{nodes.slice(0, 3).map((node) => {
				// 			return (
				// 				<NodeItem
				// 					key={node.title}
				// 					title={node.title}
				// 					description={node.description}
				// 				/>
				// 			);
				// 		})}
				// 	</Stack>
				// }
			/>
			<ModuleInstallModal
				onClose={onClose}
				isOpen={isOpen}
				currentVersionDetails={currentVersionDetails}
				onClick={onClick}
				module={module}
				currentBrainId={currentBrainId}
			/>
		</Box>
	);
};
