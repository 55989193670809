import React from 'react';
import { InfoIcon } from '@chakra-ui/icons';
import { useColorMode, useColorModeValue } from '@chakra-ui/color-mode';
import { Box, Flex } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/tooltip';

/**
 *
 * @param {{
 *  	containerProps: import('@chakra-ui/layout').FlexProps,
 * 	rightButton: React.ReactNode,
 *		children: React.ReactNode,
 *		header: React.ReactNode,
 *		description: React.ReactNode,
 *		tooltipLabel: string,
 * }} param0
 * @returns
 */
const HeaderWithDescription = ({
	containerProps,
	rightButton,
	children,
	header,
	description,
	tooltipLabel,
}) => {
	const { colorMode } = useColorMode();

	const borderColor = {
		light: 'gray.100',
		dark: 'dashboard.border_dark',
	};

	return (
		<Flex
			fontFamily="body"
			borderBottom="solid 1px"
			borderColor={borderColor[colorMode]}
			justifyContent="space-between"
			bg="#363636"
			{...containerProps}
		>
			<Box>
				<Box
					color={useColorModeValue('#444444', 'white')}
					fontFamily="body"
					fontSize="md"
				>
					{header}
					{tooltipLabel ? (
						<Tooltip
							label={tooltipLabel}
							aria-label="A tooltip"
							cursor="pointer"
						>
							<InfoIcon boxSize="11px" marginLeft="4px" opacity="0.9" />
						</Tooltip>
					) : null}
				</Box>
				{children}
				<Box color={useColorModeValue('#888383', '#c3c3c3')} fontSize="sm">
					{description}
				</Box>
			</Box>
			{rightButton ? rightButton : null}
		</Flex>
	);
};

export default HeaderWithDescription;
