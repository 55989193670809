import React from 'react';
import {
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Spinner,
	Tooltip,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import isElectron from 'is-electron';

/**
 *	Search Input Component
 * @param {{
 * 	inputRef: React.MutableRefObject<HTMLInputElement>,
 * 	onChange: React.ChangeEventHandler<HTMLInputElement>,
 * 	onKeyDown: React.KeyboardEventHandler<HTMLInputElement>,
 * 	isLoading: boolean,
 * 	inputGroupProps: import('@chakra-ui/input').InputGroupProps,
 * 	inputProps: import('@chakra-ui/input').InputProps,
 * }} param0
 * @returns
 */
const SearchInput = ({
	inputRef,
	onChange,
	onKeyDown,
	isLoading,
	inputGroupProps,
	inputProps,
}) => {
	return (
		<InputGroup
			fontSize={isElectron() ? '0.9rem' : '0.8rem'}
			size="sm"
			colorScheme="purple"
			{...inputGroupProps}
		>
			<InputLeftElement pointerEvents="none" color="gray.300">
				<SearchIcon color="#ffffff75" />
			</InputLeftElement>
			<Input
				ref={inputRef}
				placeholder="Start typing to Search..."
				variant="flushed"
				borderRadius="none"
				height={isElectron() ? '1.85rem' : '1.5rem'}
				borderColor="#494949"
				_hover={{
					borderColor: '#828282',
				}}
				fontFamily="body"
				focusBorderColor="purple.500"
				{...inputProps}
				onChange={onChange}
				onKeyDown={onKeyDown}
			/>
			{isLoading ? (
				<InputRightElement
					children={<Spinner color="#ffffff75" size="sm" />}
				/>
			) : null}
		</InputGroup>
	);
};

export default SearchInput;
