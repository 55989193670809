import React from 'react';

//Libs
import { useColorMode, Box, Image } from '@chakra-ui/react';
import { BiMicrophone } from 'react-icons/bi';
import { useSpeechRecognition } from 'react-speech-kit';

//Assets
import audio_anim_light from '../../../../../../assets/cardui/voice/animation_audio_light.gif';
import audio_anim_dark from '../../../../../../assets/cardui/voice/animation_audio_dark.gif';

//Redux
import { connect } from 'react-redux';
import {
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
} from '../../../../../../redux/actions';

//Components
import IconContainer from '../../../../../../util/React_Icon';

const AudioSection = ({
	inputValue,
	setInputValue,
	searchItems,
	setMode,
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
}) => {
	const { colorMode } = useColorMode();
	const [mockListening, setMockListening] = React.useState(false);
	const mocking = true;

	const onStopListening = React.useCallback(() => {
		if (searchItems.allIds.length !== 0) {
			selectMiddleSearchItem();
			setMiddleSearchItems({ byId: {}, allIds: [] });
			toggleSearchDisplayMode('collapsed');
			toggleCardsVisibility(false);
			setMode('enter');
		}
	});

	const { listen, listening, stop } = useSpeechRecognition({
		onResult: (result) => {
			setInputValue(result);
		},
		onEnd: () => {
			onStopListening();
		},
	});

	const onStartListening = () => {
		listen();

		setTimeout(() => {
			stop();
			onStopListening();
		}, 5000);
	};

	const onMockStart = () => {
		setMockListening(true);
		const valueArray = [
			'voice',
			'Voice control',
			'Voice control Coming',
			'Voice control coming soon!',
		];

		valueArray.map((input, i) => {
			setTimeout(() => {
				setInputValue(input);
			}, i * 700 + 1000);
		});

		setTimeout(() => {
			// selectMiddleSearchItem();
			// setMiddleSearchItems({ byId: {}, allIds: [] });
			// toggleSearchDisplayMode("collapsed");
			// toggleCardsVisibility(false);
			// setMode("enter");
			setMockListening(false);
		}, 6000);
	};

	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};
	const audio_icons = {
		light: audio_anim_light,
		dark: audio_anim_dark,
	};
	return (
		<Box display="flex">
			{listening || mockListening ? (
				<Box
					width="4rem"
					height="2.5rem"
					overflow="hidden"
					cursor="pointer"
					onClick={stop}
				>
					<Image
						src={audio_icons[colorMode]}
						objectFit="cover"
						boxSize="5.5rem"
						cursor="pointer"
						marginTop="-1.5rem"
					/>
				</Box>
			) : (
				<Box
					mr={5}
					cursor="pointer"
					onClick={mocking ? onMockStart : onStartListening}
				>
					<IconContainer
						icon={<BiMicrophone />}
						cursor="pointer"
						value={{
							color: color[colorMode],
							className: 'global-class-name',
							size: '1.3rem',
						}}
					/>
				</Box>
			)}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { centerSearch } = state.cardUI;
	const { selectedItem, loadingStatus, submitProgress } = centerSearch.top;
	const { searchItems } = centerSearch.middle;
	const { intents } = state.cardUI;
	return { selectedItem, intents, loadingStatus, submitProgress, searchItems };
};

export default connect(mapStateToProps, {
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
})(AudioSection);
