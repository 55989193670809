import React from 'react';
import {
	Input,
	useColorMode,
	InputGroup,
	InputAddon,
	CircularProgress,
	Box,
	Image,
	useToast,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import _ from 'lodash';

import { v4 as uuidv4 } from 'uuid';
import isElectron from 'is-electron';

//Components
import Command from '../../../CommandItem';
import { resultsTabs } from '../ExpandedSection/CommandSearch';

//Utils
import toggleCommandBar from '../../../../../functions/commandBar/toggleCommandBar';

//Assets
import mayaIconDark from '../../../../../assets/cardui/mayaicon_dark.svg';
import mayaIconLight from '../../../../../assets/cardui/mayaicon_light.svg';

//Redux
import { connect } from 'react-redux';
import {
	highlightMiddleSearchItem,
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
	newCard,
	setSearchBarLoadingStatus,
	setMiddleSearchModeAndType,
	setCardsExpandable,
	commandHistoryLogIntentEvent,
	updateCommandBarStatus,
} from '../../../../../redux/actions';
import KeyEnum from '../../../../../util/KeyEnum';

//Analytics
import analytics from '../../../../../util/Analytics';

import fuzzysort from 'fuzzysort';

const BOT_COMMAND_TIMEOUT = 60 * 1000; // We want bot-command requests to timeout in 60 secondss.

const intentString = (intents) => {
	console.log('intentString', intents);
	let str = '';
	for (let intent of intents) {
		if (intent.type === 'text') {
			str = str + intent.value;
		} else if (intent.type === 'placeholder') {
			str = str + ' placeholder';
		}
	}
	console.log(str);
	return str;
};

const CloseButtonItem = ({ onCancel }) => {
	return (
		<Box
			width="1.6rem"
			height="1.6rem"
			borderRadius="1rem"
			onClick={() => onCancel()}
			display="flex"
			justifyContent="center"
			alignItems="center"
			bg="maya_dark.250"
		>
			<SmallCloseIcon />
		</Box>
	);
};

/**
 * Return which Result Tab to switch to
 * @param {*} resultsType
 * @param {'left' | 'right'} direction
 */
const resultsTabTypeChange = (resultsType, direction) => {
	let index = _.findIndex(resultsTabs, function (o) {
		return o.type === resultsType;
	});

	if (direction === 'right' && index !== -1) {
		if (index === resultsTabs.length - 1) {
			return resultsTabs[0].type;
		} else {
			return resultsTabs[index + 1].type;
		}
	} else if (direction === 'left' && index !== -1) {
		if (index === 0) {
			return resultsTabs[resultsTabs.length - 1].type;
		} else {
			return resultsTabs[index - 1].type;
		}
	} else {
		return 'commands';
	}
};

// export const resultsTabs = [
//   { type: "commands", label: "Commands" },
//   { type: "recent", label: "Recent" },
//   { type: "favorites", label: "Favorites" },
//   { type: "cards", label: "Cards" },
//   { type: "store", label: "Store" },
// ];

const CommandSearch = ({
	myForwardedRef,
	resultsType,
	highlightMiddleSearchItem,
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	setSearchBarLoadingStatus,
	setMiddleSearchModeAndType,
	toggleCardsVisibility,
	loadingStatus,
	submitProgress,
	searchItems,
	newCard,
	setCardsExpandable,
	selectedItem,
	mode,
	setMode,
	onCancel,
	intents,
	inputValue,
	setInputValue,
	commandHistoryLogIntentEvent,
	commandHistoryLogs,
	updateCommandBarStatus,
}) => {
	const toast = useToast();

	const { colorMode } = useColorMode();
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	const handleOnKeyUp = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.UP_ARROW || keyCode === KeyEnum.DOWN_ARROW) {
			e.preventDefault();
		}
	};

	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.UP_ARROW) {
			analytics.track(`Command Bar Key Up`);
			e.preventDefault();
			highlightMiddleSearchItem('UP');
		} else if (keyCode === KeyEnum.DOWN_ARROW) {
			analytics.track(`Command Bar Key Down`);
			e.preventDefault();
			highlightMiddleSearchItem('DOWN');
		} else if (keyCode === KeyEnum.TAB) {
			analytics.track(`Command Bar cycle tabs`);
			if (e.ctrlKey || e.metaKey) {
				e.preventDefault();
				setMiddleSearchModeAndType(
					'intents',
					resultsTabTypeChange(resultsType, 'right')
				);
			}
		} else if (keyCode === KeyEnum.ENTER) {
			e.preventDefault();
			analytics.track(`Command Bar Key Enter`);
			if (searchItems.allIds.length !== 0) {
				setSearchBarLoadingStatus('submitting');
				selectMiddleSearchItem();
				setMiddleSearchItems({ byId: {}, allIds: [] });
				toggleSearchDisplayMode('collapsed');
				toggleCardsVisibility(false);
				setMode('enter');
			}
		} else if (keyCode === KeyEnum.ESC) {
			setTimeout(() => {
				updateCommandBarStatus(false);
			}, 200);

			if (inputValue !== '' || loadingStatus) {
				setMiddleSearchModeAndType('intents', 'commands');
				setSearchBarLoadingStatus(null);
				toggleSearchDisplayMode('collapsed');
				setInputValue('');
				analytics.track(`Command Bar Key Esc`);
			} else if (!loadingStatus) {
				setMiddleSearchModeAndType('intents', 'commands');
				setSearchBarLoadingStatus(null);
				toggleSearchDisplayMode('collapsed');
				toggleCommandBar({ method: 'Esc Key' });
				setInputValue('');
			}
		}
	};

	const onSubmit = (formData) => {
		// analytics.track("Submitting Command", {
		//   intent: intentString(selectedItem.intent)
		// });
		setCardsExpandable(false);
		// taskId is generated here temporarily, to happen
		// later in orchestrator/central backend
		let taskId = uuidv4();
		formData['taskId'] = taskId;
		if (selectedItem.submitDetails.type === 'http') {
			setSearchBarLoadingStatus('waiting');
			// Temp timeout before returning to avoid flashy changes
			updateCommandBarStatus(true, 'loading', 'Loading...');
			setSearchBarLoadingStatus(null);
			setMiddleSearchModeAndType('intents', 'commands');
			setInputValue('');
			toggleSearchDisplayMode('collapsed');
			commandHistoryLogIntentEvent({ ...selectedItem }, 'start', {
				searchQuery: inputValue,
				taskId: taskId,
			});
			axios
				.post(
					selectedItem.submitDetails.url +
						selectedItem.submitDetails.trigger,
					formData,
					{
						headers: {
							'Content-Type': 'application/json',
						},
						timeout: BOT_COMMAND_TIMEOUT,
					}
				)
				.then(function (response) {
					console.log('response', response);

					// setSearchBarLoadingStatus(null);
					// setMiddleSearchModeAndType('intents', 'commands');
					// setInputValue('');
					// toggleSearchDisplayMode('collapsed');
					analytics.track('Command Run', {
						intent: intentString(selectedItem.intent),
						status: 'success',
						card: false,
					});
					// myForwardedRef.current.focus();
					if (response.data.type) {
						// setSearchBarLoadingStatus(null);
						// setMiddleSearchModeAndType('intents', 'commands');
						// setInputValue('');
						// toggleSearchDisplayMode('collapsed');
						if (response.data.type === 'card') {
							updateCommandBarStatus(true, 'success', 'Success!');
							setCardsExpandable(true);
							analytics.track('Command Run', {
								intent: intentString(selectedItem.intent),
								status: 'success',
								card: true,
							});
							response.data.payload.robotId =
								selectedItem.submitDetails.robotId;
							setTimeout(() => {
								updateCommandBarStatus(false);
							}, 1000);
							commandHistoryLogIntentEvent({ ...selectedItem }, 'end', {
								searchQuery: inputValue,
								card: true,
								taskId: response.data._taskid || taskId,
							});
							newCard(response.data.payload);
							myForwardedRef.current.blur();
						} else if (response.data.type === 'status') {
							analytics.track('Command Run', {
								intent: intentString(selectedItem.intent),
								status: response.data.payload.status,
								card: false,
							});

							let status = response.data.payload.status;
							let statusMsg = response.data.payload.message;
							if (status && status === 'success') {
								updateCommandBarStatus(
									true,
									status,
									statusMsg || 'Success!'
								);
								commandHistoryLogIntentEvent(
									{ ...selectedItem },
									'end',
									{
										searchQuery: inputValue,
										card: false,
										taskId: response.data._taskid || taskId,
									}
								);
								setTimeout(() => {
									updateCommandBarStatus(false);
								}, 150);

								setTimeout(() => {
									toggleCommandBar({ method: 'on command submit' });
								}, 300);
							} else if (status && status === 'error') {
								updateCommandBarStatus(
									true,
									status,
									statusMsg === 'Success!'
										? 'Error occurred'
										: statusMsg || 'Error occurred'
								);
								commandHistoryLogIntentEvent(
									{ ...selectedItem },
									'failed',
									{
										searchQuery: inputValue,
										card: false,
										taskId: response.data._taskid || taskId,
									}
								);
							} else if (status && status === 'loading') {
								updateCommandBarStatus(
									true,
									status,
									statusMsg || 'Loading...'
								);
							}
						}
					} else {
						analytics.track('Command Run', {
							intent: intentString(selectedItem.intent),
							status: 'success',
							card: false,
						});
						commandHistoryLogIntentEvent({ ...selectedItem }, 'end', {
							searchQuery: inputValue,
							card: false,
							taskId:
								response.data._taskid || response.data.taskId || taskId,
						});
						setTimeout(() => {
							updateCommandBarStatus(false);
						}, 150);

						setTimeout(() => {
							toggleCommandBar({ method: 'on command submit' });
						}, 300);
						//
						// setSearchBarLoadingStatus(null);
						// setMiddleSearchModeAndType('intents', 'commands');
						// setInputValue('');
						// toggleSearchDisplayMode('collapsed');

						myForwardedRef.current.select();
					}
				})
				.catch((err) => {
					console.log(err);
					updateCommandBarStatus(true, 'error', 'Got an error response.');
					setMiddleSearchModeAndType('intents', 'commands');
					setSearchBarLoadingStatus(null);
					toggleSearchDisplayMode('collapsed');
					setInputValue('');
					analytics.track('Command Run', {
						intent: intentString(selectedItem.intent),
						status: 'failure',
						card: false,
					});
					commandHistoryLogIntentEvent({ ...selectedItem }, 'failed', {
						searchQuery: inputValue,
						card: false,
						taskId: taskId, //TODO : is is temp, this taskId should be independently set from runtime response, add when bot-utils is changed
					});
					// toast({
					// 	title: "Couldn't run that command!",
					// 	description: 'Got an error response from the runtime skill.',
					// 	status: 'error',
					// 	duration: 3000,
					// 	isClosable: true,
					// });
				});
		}
		onCancel();
		setMode('search');
		toggleCardsVisibility(true);
	};

	const mergeIntentObjectArray = (objectArray) => {
		let mergedArray = [];
		objectArray.map((object) => {
			if (object.type === 'placeholder') {
				mergedArray.push(object.key);
			} else if (object.type === 'text') {
				mergedArray.push(object.value);
			}
		});

		return _.join(mergedArray, ' ');
	};
	const typingTimer = React.useRef(null);

	const onSearchChange = (e) => {
		let suggestItems = { byId: {}, allIds: [] };
		let doneTypingInterval = 400; //in ms
		clearTimeout(typingTimer.current);
		if (e.currentTarget.value) {
			typingTimer.current = setTimeout(() => {
				analytics.track('Command Bar Querying', {
					value: e.currentTarget.value,
				});
			}, doneTypingInterval);
		}
		if (e.currentTarget.value !== '') {
			toggleCardsVisibility(false);
			let allIntents = intents.allIds.map((intentId) => {
				let mergedArray = mergeIntentObjectArray(
					intents.byId[intentId].intent
				);
				/**
				 * Maximum number of recent logs from command history
				 * to be used for recommendation bias
				 */
				const MAX_RECENT_LOGS_FOR_RECOMMENDATION = 100;
				const allIntentLogs = commandHistoryLogs
					.slice(-MAX_RECENT_LOGS_FOR_RECOMMENDATION)
					.filter(
						(logObj) =>
							logObj.intentId === intentId && logObj.status === 'start'
					);
				return {
					label: mergedArray,
					intentId,
					hits: allIntentLogs.length ? allIntentLogs.length : 0,
				};
			});
			const results = fuzzysort.go(e.currentTarget.value, allIntents, {
				allowTypo: true,
				key: 'label',
			});
			const weightedResults = results.map((res) => {
				const { hits } = res.obj;
				const hitsWeight = 5;
				const weightedScore =
					res.score + hitsWeight * Math.log10(hits ? hits + 1 : 1);
				return { ...res, weightedScore };
			});
			const sortedResults = weightedResults.sort(
				(prev, next) => next.weightedScore - prev.weightedScore // Descending order
			);

			const selectedIntentIds = sortedResults.map((intent) => {
				return intent.obj.intentId;
			});
			selectedIntentIds.map((intentId, i) => {
				let randomId = uuidv4();
				suggestItems.byId[randomId] = intents.byId[intentId];
				suggestItems.allIds.push(randomId);
			});
			setMiddleSearchItems(suggestItems);
			toggleSearchDisplayMode('expanded');
			// if (selectedIntentIds.length !== 0) {
			//   toggleSearchDisplayMode("expanded");
			// } else {
			//   toggleSearchDisplayMode("collapsed");
			// }
		} else {
			toggleCardsVisibility(true);
			setMiddleSearchItems(suggestItems);
			toggleSearchDisplayMode('collapsed');
		}
	};

	React.useEffect(() => {
		onSearchChange({ currentTarget: { value: inputValue } });
	}, [inputValue]);

	React.useEffect(() => {
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer } = electron;

			ipcRenderer.removeAllListeners('/setCmdBarValue');
			ipcRenderer.on('/setCmdBarValue', (event, arg) => {
				let { searchQuery } = arg;
				setInputValue(searchQuery);
			});
		}
	});

	React.useEffect(() => {
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer } = electron;
			ipcRenderer.removeAllListeners('/searchbar/focus');
			ipcRenderer.on('/searchbar/focus', (event, arg) => {
				if (myForwardedRef.current) {
					myForwardedRef.current.focus();
				}
			});
		}
	});

	const mayaIcon = {
		light: mayaIconLight,
		dark: mayaIconDark,
	};

	const SideAddOn = (status) => {
		return (
			<Box>
				{status === null ? (
					<Image
						src={mayaIcon[colorMode]}
						boxSize="1.6rem"
						onClick={() => setInputValue('')}
					/>
				) : null}
				{status === 'waiting' ? (
					<CircularProgress
						isIndeterminate
						size="25px"
						ml={0}
						color="gray"
						thickness={0.2}
						position="relative"
						capIsRound
					/>
				) : null}
				{status === 'submitting' ? (
					<CloseButtonItem
						onCancel={() => {
							onCancel();
							setSearchBarLoadingStatus(null);

							setMiddleSearchModeAndType('intents', 'commands');
							toggleSearchDisplayMode('collapsed');
							toggleCardsVisibility(true);
							setInputValue('');
							setTimeout(() => {
								myForwardedRef.current.select();
							}, 200);
						}}
					/>
				) : null}
			</Box>
		);
	};

	const onMouseLeave = () => {
		console.log('Mouse left');
	};

	return (
		<InputGroup onMouseLeave={onMouseLeave()}>
			<InputAddon
				bg="none"
				border="none"
				paddingRight="0.8rem"
				children={SideAddOn(loadingStatus)}
			/>
			{mode === 'search' ? (
				<Input
					paddingY="0"
					bg="transparent"
					border="none"
					type="phone"
					ref={myForwardedRef}
					_placeholder={{
						color: color[colorMode],
						opacity: '0.8',
						fontSize: '1.3rem',
					}}
					_focus={{ outline: 'none' }}
					paddingRight="0.5rem"
					paddingLeft="0.5rem"
					width="20rem"
					placeholder={inputValue || 'Start typing for command search...'}
					focusBorderColor="gray.200"
					color={color[colorMode]}
					onChange={(e) => {
						setInputValue(e.currentTarget.value);
					}}
					value={inputValue}
					fontFamily="body"
					fontWeight="400"
					fontSize="1.3rem"
					onKeyUp={handleOnKeyUp}
					onKeyDown={handleOnKeyDown}
				/>
			) : (
				<Box paddingLeft="0.5rem">
					<Command
						intentArray={selectedItem ? selectedItem.intent : null}
						onCancel={onCancel}
						onSubmit={(formData) => setTimeout(onSubmit(formData), 200)}
					/>
				</Box>
			)}
		</InputGroup>
	);
};

/**
 *
 * @param {{cardUI: typeof initialState, commandHistory: import('../../../../../redux/reducers/types/commandHistoryReducer').CommandHistoryState} state
 * @returns
 */
const mapStateToProps = (state) => {
	const { centerSearch } = state.cardUI;
	const { selectedItem, loadingStatus, submitProgress } = centerSearch.top;
	const { searchItems, type } = centerSearch.middle;
	const { intents } = state.cardUI;
	const { log: commandHistoryLogs } = state.commandHistory;
	return {
		selectedItem,
		intents,
		loadingStatus,
		submitProgress,
		searchItems,
		resultsType: type,
		commandHistoryLogs,
	};
};

const ConnectedCommandSearch = connect(mapStateToProps, {
	highlightMiddleSearchItem,
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
	newCard,
	setSearchBarLoadingStatus,
	setMiddleSearchModeAndType,
	setCardsExpandable,
	commandHistoryLogIntentEvent,
	updateCommandBarStatus,
})(CommandSearch);

export default React.forwardRef((props, ref) => (
	<ConnectedCommandSearch {...props} myForwardedRef={ref} />
));
