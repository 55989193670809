import React from 'react';
import TopBar from './TopSection/';
import ExpandedSection from './ExpandedSection';
import dotProp from 'dot-prop-immutable';
import { connect } from 'react-redux';
import posed from 'react-pose';

import { Box, useColorMode } from '@chakra-ui/react';

//Components
import CommandBarStatus from './CommandBarStatus';

export const PoseBox = posed(Box)({
	flip: {
		transition: { duration: 100 },
	},
});

const SearchBar = (props) => {
	const { colorMode } = useColorMode();

	const bgColor = { light: 'card_ui.bg_light', dark: 'card_ui.bg_dark' };

	return (
		<>
			<CommandBarStatus
				position="absolute"
				marginTop="18.8vh"
				marginLeft={'auto'}
				marginRight={'auto'}
				width={`${38}rem`}
				left={'0'}
				right={'0'}
				className="maya-command-ui" // added for checkInBody identification
			/>
			<Box
				className="maya-command-ui" // added for checkInBody identification
				width={`${38}rem`}
				marginTop="23vh"
				display="inline-block"
				roundedTop="0.25rem"
				roundedBottom="0.25rem"
				bg={bgColor[colorMode]}
				position="absolute"
				justifyContent="center"
				left={'0'}
				right={'0'}
				marginLeft={'auto'}
				marginRight={'auto'}
				// paddingBottom={expanded ? "0rem" : "0.2rem"}
				// width={`${props.moduleWidth}vw`}
				isFullWidth={true}
				boxShadow={'1px 5px 20px rgba(0, 0, 0, 0.2)'}
			>
				<TopBar display={props.display} />
				{props.display === 'expanded' ? (
					<ExpandedSection mode={props.mode} />
				) : null}
			</Box>
		</>
	);
};

const matchStateToProps = (state) => {
	const { centerSearch } = state.cardUI;
	const mode = dotProp.get(centerSearch, `mode`);
	const display = dotProp.get(centerSearch, `display`);
	return { mode, display };
};

export default connect(matchStateToProps)(SearchBar);
