import { Box } from '@chakra-ui/react';
import React from 'react';

const VideoAnimationLoop = ({ src, width, styles }) => {
	return (
		<Box
			objectFit="cover"
			width={width ? width : { sm: '98vw', md: '600px' }}
			zIndex="50"
			borderRadius="5px"
			// boxShadow="5px 15px 250px -34px rgba(150, 135, 135, 0.6)"
			filter="drop-shadow(0 5px 45px rgb(0, 0 , 0, 0.3));"
			{...styles}
		>
			<video autoPlay loop muted>
				<source src={src} type="video/webm" />
			</video>
		</Box>
	);
};

export default VideoAnimationLoop;
