import React from 'react';

import {
	Button,
	useColorModeValue,
	useColorMode,
	Modal,
	Box,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	LightMode,
	useToast,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogHeader,
	AlertDialogFooter,
} from '@chakra-ui/react';
import {
	FiPlusCircle,
	FiSettings,
	FiMinusCircle,
	FiCheckCircle,
} from 'react-icons/fi';
import IconContainer from '../../util/React_Icon';

import _ from 'lodash';
import isElectron from 'is-electron';

//Components

import { HorizontalItemButton } from '../Reusable/HorizontalItem';

import SetupConfigurationProfiles from './ConfigurationProfiles';
import ModuleInstallModal from './ModuleInstallModal';

//Functions
import installModuleToBrain from '../../functions/modules/installModuleToBrain';
import uninstallModuleFromBrain from '../../functions/modules/uninstallModuleFromBrain';
import GetProfileSlug from '../../util/ProfileSlug';

//Analytics
import analytics from '../../util/Analytics';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

export const InstallButton = ({
	setHoveredButton,
	hoveredButton,
	onClick,
	module,
	installed,
	currentBrainId,
	currentVersionDetails,
	updateBrainById,
	isInstalled,
}) => {
	const {
		isOpen: isInstallOpen,
		onOpen: onInstallOpen,
		onClose: onInstallClose,
	} = useDisclosure();
	const [isLoading, setLoading] = React.useState(false);
	const [brainId, setBrainId] = React.useState('');
	const [configProfileId, setConfigProfileId] = React.useState('');
	const toast = useToast();
	const { slug } = GetProfileSlug();

	const handleInstall = () => {
		setLoading(true);
		//TODO: allow users to choose the version of module but preselect current version
		// don't change these variable names
		var formData = {
			brainId: brainId,
			moduleId: module._id,
			packageName: module.packageName,
			profileSlug: slug,
			versionToInstall: module.currentVersion, // TODO: Module version to install should be user selectable
			referenceId: configProfileId ? configProfileId : null,
		};
		installModuleToBrain(formData).then((res) => {
			if (res['error']) {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'Module could not be installed',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			} else {
				setLoading(false);
				toast({
					title: 'Success!',
					description: 'Module successfully installed',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			}
			updateBrainById(brainId, res.brain);
		});
	};

	const handleRemove = () => {
		// setLoading(true);
		// installModuleToBrain({ moduleId: module._id }).then((res) => {
		//   setLoading(false);
		// });
	};

	React.useEffect(() => {
		if (currentBrainId) {
			setBrainId(currentBrainId);
		}
	}, [brainId]);

	// React.useEffect(() => {
	//   setLoading(true);
	//   if (isOpen) {
	//     getStoreFlowByIdAPI({ flowIDs: [packID] })
	//       .then((results) => {
	//         console.log(results);
	//         setModule(results.data.flows[0]);
	//         setLoading(false);
	//       })
	//       .catch((err) => console.log(err));
	//   }
	// }, [packID, isOpen]);
	return (
		<>
			<HorizontalItemButton
				icon={<FiPlusCircle />}
				buttonKey="install"
				text={'install'}
				onClick={() => {
					//onClick();
					// analytics.track("Module Item : Install Button", {
					//   _id: module._id,
					//   name: module.name,
					// });
					onInstallOpen();
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<ModuleInstallModal
				onClose={onInstallClose}
				isOpen={isInstallOpen}
				currentVersionDetails={currentVersionDetails}
				installed={installed}
				onClick={onClick}
				module={module}
				currentBrainId={currentBrainId}
				isInstalled={isInstalled}
			/>
		</>
	);
};

/**
 * Set uninstall button
 * @param {*} param0
 */

export const UninstallButton = ({
	setHoveredButton,
	hoveredButton,
	module,
	currentBrainId,
	updateBrainById,
}) => {
	const [isUninstalling, setIsUninstalling] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();
	const handleUninstall = () => {
		setIsUninstalling(true);
		uninstallModuleFromBrain({
			brainId: currentBrainId,
			profileSlug: slug,
			moduleToUninstall: module,
		})
			.then((res) => {
				if (res['error']) {
					setIsUninstalling(false);
					toast({
						title: 'Error!',
						description: res.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
					analytics.track('Module Uninstall', {
						brainId: currentBrainId,
						packageName: module.packageName,
						version: module.version,
						status: 'failure',
						error: res['error'],
					});
				} else {
					setIsUninstalling(false);
					toast({
						title: 'Uninstalled!',
						description: res.message,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					analytics.track('Module Uninstall', {
						brainId: currentBrainId,
						packageName: module.packageName,
						version: module.version,
						status: 'success',
						error: !res['error'],
					});
				}
				console.log(res);
				updateBrainById(currentBrainId, res.brain);
				onClose();
			})
			.catch((err) => {
				setIsUninstalling(false);
				toast({
					title: 'Error!',
					description: 'This module could not be uninstalled.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<HorizontalItemButton
				icon={<FiMinusCircle />}
				buttonKey="uninstall"
				text={'uninstall'}
				onClick={() => {
					setIsOpen(true);
					// analytics.track("Module Item : Uninstall Button", {
					//   _id: module._id,
					//   name: module.name,
					// });
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Uninstall Module
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={handleUninstall}
									ml={3}
									isLoading={isUninstalling}
									loadingText={'Uninstalling...'}
								>
									Delete
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

/**
 *
 */

export const ProfilesButton = ({
	setHoveredButton,
	hoveredButton,
	currentVersionDetails,
	module,
	currentBrainId,
	moduleList,
	setModuleList,
	setName,
	setProfileConfigured,
}) => {
	// change this whenever it is used next
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [configProfileId, setConfigProfileId] = React.useState('');
	return (
		<>
			<HorizontalItemButton
				icon={<FiSettings />}
				buttonKey="setup"
				text={'setup'}
				onClick={() => {
					//onClick();
					onOpen();
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					maxWidth="30rem"
				>
					{currentVersionDetails.configurationType ? (
						<Box paddingX="1rem" marginTop="0.5rem">
							<SetupConfigurationProfiles
								onSelectFn={(id, name) => {
									let temp = moduleList;
									temp[module._id]['referenceId'] = id;
									temp[module._id]['profileName'] = name;
									setConfigProfileId(id);
									setName(name);
									setModuleList({
										byId: temp.byId,
										allIds: temp.allIds,
									});
									let flag = true;
									for (let i of Object.keys(moduleList)) {
										if (moduleList[i].configurationRequired) {
											flag =
												flag &&
												(moduleList[i].referenceId ? true : false);
										}
									}
									setProfileConfigured(flag);
									console.log('moduleList', moduleList);
								}}
								currentVersionDetails={currentVersionDetails}
								moduleId={module._id}
								moduleName={module.packageName}
								heightLimit="265px"
							/>
						</Box>
					) : (
						<Box />
					)}
					<Box
						paddingX="1rem"
						borderTop="solid 1px"
						borderColor={useColorModeValue(
							'dashboard.border_light',
							'dashboard.border_dark'
						)}
						height="3rem"
						alignItems="center"
						display="flex"
					>
						<LightMode>
							<Button
								colorScheme="purple"
								size="xs"
								mr="2"
								borderRadius="1rem"
								variant={'solid'}
								paddingX="0.9rem"
								onClick={() => {
									onClose();
								}}
								loadingText={'Installing...'}
							>
								Select
							</Button>
						</LightMode>
					</Box>
				</ModalContent>
			</CustomModal>
		</>
	);
};

/**
 * Configure Module Button
 */

export const ConfigureModuleButton = ({
	moduleId,
	currentVersionDetails,
	module,
	currentBrainId,
	moduleList,
	setModuleList,
	isModuleLoading,
}) => {
	const [configProfileId, setConfigProfileId] = React.useState(
		moduleList.byId[moduleId].referenceId
	);
	const [name, setName] = React.useState(
		moduleList.byId[moduleId].profileName
	);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { colorMode } = useColorMode();
	const bgColor = {
		light: '#F4F4F4',
		dark: '#585858',
	};
	const hoverColor = {
		light: '#F4F4F4',
		dark: '#5d5d5d',
	};
	console.log('config profile id', configProfileId, name, moduleList);

	React.useEffect(() => {
		setConfigProfileId(moduleList.byId[moduleId].referenceId);
		setName(moduleList.byId[moduleId].profileName);
	}, [moduleId, module, moduleList]);

	return (
		<>
			<Box
				bg={!configProfileId ? bgColor[colorMode] : '#19AC39'}
				paddingX="14px"
				paddingY="6px"
				borderRadius="50px"
				display="flex"
				alignItems="center"
				cursor="pointer"
				marginRight="22px"
				border="0.473682px solid "
				borderColor={useColorModeValue('#ebebeb', '#434040')}
				boxSizing="border-box"
				boxShadow="0.947365px 0.947365px 0.947365px rgba(0, 0, 0, 0.05)"
				width="100px"
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				_hover={{
					bgColor: !configProfileId ? hoverColor[colorMode] : '#19AC39',
				}}
				onClick={() => {
					//onClick();
					onOpen();
					analytics.track(`[Module] ConfigureModuleButton: Click`, {
						feature: 'uncategorized',
						moduleId: module._id,
						moduleName: module.name,
					});
				}}
			>
				<IconContainer
					icon={!configProfileId ? <FiSettings /> : <FiCheckCircle />}
					value={{
						color: useColorModeValue('#8A8A8A', 'white'),
						className: 'global-class-name',
						size: '12px',
					}}
				/>
				<Box
					ml={2}
					fontWeight="600"
					color={useColorModeValue('#8A8A8A', 'white')}
					fontFamily="body"
					fontSize="11px"
				>
					{!configProfileId ? 'configure' : 'configured'}
					{/* // : _.truncate(name, {
						  // 		length: 10,
						  // 		separator: /,? +/,
						  //   })} */}
				</Box>
			</Box>
			<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					maxWidth="30rem"
				>
					{module.configurationType ? (
						<Box paddingX="1rem" marginTop="0.5rem">
							<SetupConfigurationProfiles
								configurationType={module.configurationType}
								moduleId={module._id}
								moduleName={module.packageName}
								moduleList={moduleList}
								setModuleList={setModuleList}
								isModuleLoading={isModuleLoading}
								heightLimit="265px"
							/>
						</Box>
					) : (
						<Box />
					)}
					<Box
						paddingX="1rem"
						borderTop="solid 1px"
						borderColor={useColorModeValue(
							'dashboard.border_light',
							'dashboard.border_dark'
						)}
						height="3rem"
						alignItems="center"
						display="flex"
					>
						<LightMode>
							<Button
								colorScheme="purple"
								size="xs"
								mr="2"
								borderRadius="5px"
								variant={'solid'}
								paddingX="0.9rem"
								onClick={() => {
									onClose();
								}}
								isDisabled={!configProfileId}
								loadingText={'Installing...'}
							>
								Select
							</Button>
						</LightMode>
					</Box>
				</ModalContent>
			</CustomModal>
		</>
	);
};
