import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@chakra-ui/react';

/**
 *
 * @param {{
 * 	src: string,
 * 	mode: string,
 * 	iframeStyle: string
 * 	onDidNavigate?: (url: string) => void,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const WebView = ({ src, mode, iframeStyle = '', onDidNavigate, ...rest }) => {
	/**
	 * @type {React.MutableRefObject<HTMLDivElement>}
	 */
	const webviewWrapper = useRef(null);
	const [first, setFirst] = useState(true); // tag for firstTime/onFirstMount classification

	useEffect(() => {
		let webview = null;
		const handleNavigation = (event) => {
			if (onDidNavigate) onDidNavigate(event.url);
		};
		// run this only on first mount
		if (first && webviewWrapper.current) {
			let url = src;
			webviewWrapper.current.innerHTML = `<webview src=${url} style="height:100%; width:100%;"/>`;
			webview = webviewWrapper.current.querySelector('webview');
			if (webview) {
				const iframe = webview.shadowRoot?.querySelector('iframe');
				iframe.style = 'width: 100%; height: 100%;' + iframeStyle;
				// Add event listeners for page navigation events
				webview.addEventListener('did-navigate', handleNavigation);
				webview.addEventListener('did-navigate-in-page', handleNavigation);
			}
			setFirst(false);
		}
		return () => {
			// cleanup
			if (!first && webview) {
				webview.removeEventListener('did-navigate', handleNavigation);
				webview.removeEventListener(
					'did-navigate-in-page',
					handleNavigation
				);
			}
		};
	}, [first, iframeStyle, mode, onDidNavigate, src]);

	return <Box ref={webviewWrapper} {...rest}></Box>;
};

export default WebView;
