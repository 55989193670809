/**
 * Tab Actions
 *
 * Action creators for Tab based actions.
 */

// Action Types
import actionTypes from '../actionTypes';

/**
 * Add the Tab
 *
 * Attempt to set the tab object with list of tabs containing user.
 *
 * @param {Object} tabId The robots list obtained from the backend for a given fleet.
 */
const addTab = (brainId, name) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.
	const tabItem = {
		typeOf: 'runtime',
		path: `/edit?id=${brainId}`,
		name: name,
		brainId: brainId,
	};

	// Dispatch the result.
	dispatch({
		type: actionTypes.ADD_TAB,
		payload: {
			info: tabItem,
		},
	});
};

const closeTab = (brainId) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.CLOSE_TAB,
		payload: {
			info: brainId,
		},
	});
};

const switchTab = (brainId) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.SWITCH_TAB,
		payload: {
			info: brainId,
		},
	});
};

const clearTabs = () => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.CLEAR_TABS,
	});
};

// Export the actions.
export { addTab, closeTab, switchTab, clearTabs };
