import React, { useState, useCallback } from 'react';
import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Button,
	Icon,
} from '@chakra-ui/react';
import Cropper from 'react-easy-crop';

const createImage = (url) =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.addEventListener('load', () => resolve(image));
		image.addEventListener('error', (error) => reject(error));
		image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
		image.src = url;
	});

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 */
export const getCroppedImg = async (imageSrc, pixelCrop) => {
	const image = await createImage(imageSrc);
	const canvas = document.createElement('canvas');
	canvas.width = pixelCrop.width;
	canvas.height = pixelCrop.height;
	const ctx = canvas.getContext('2d');

	ctx.fillStyle = '#ffffff';
	ctx.fillRect(0, 0, canvas.width, canvas.height);
	ctx.drawImage(
		image,
		pixelCrop.x,
		pixelCrop.y,
		pixelCrop.width,
		pixelCrop.height,
		0,
		0,
		pixelCrop.width,
		pixelCrop.height
	);

	// As Base64 string
	// return canvas.toDataURL('image/jpeg');

	// As a blob
	// return new Promise((resolve, reject) => {
	//   canvas.toBlob((file) => {
	//     resolve(URL.createObjectURL(file));
	//   }, "image/jpeg");
	// });

	return new Promise((resolve, reject) => {
		resolve(canvas.toDataURL());
	});
};

const readFile = (file) => {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
};

export const ImgUpload = ({ setThumbnail }) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [img, setImg] = useState('');
	const [cropped, setCropped] = React.useState();
	const [submitted, setSubmitted] = React.useState(false);

	const [zoom, setZoom] = useState(1);
	const onCropComplete = useCallback(
		async (croppedArea, croppedAreaPixels) => {
			console.log(croppedArea, croppedAreaPixels);
			let croppedImage = await getCroppedImg(img, croppedAreaPixels);
			setCropped(croppedImage);
		},
		[img]
	);

	const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
			const file = e.target.files[0];
			let imageDataUrl = await readFile(file);
			setImg(imageDataUrl);
			setThumbnail('dummy');
		}
	};
	return (
		<div>
			{submitted ? (
				<div style={{ display: 'flex' }}>
					<img src={cropped} alt="lol" height="210px" width="400px" />
					<Icon
						name="small-close"
						style={{ marginLeft: '4px' }}
						cursor="pointer"
						onClick={() => {
							setImg('');
							setThumbnail('');
							setSubmitted(false);
						}}
					/>
				</div>
			) : (
				<div>
					<input
						type="file"
						onChange={onFileChange}
						style={{
							marginBottom: '3px',
							backgroundColor: '#fff',
							boxShadow: 'none',
							fontFamily: 'Lato, sans-serif',
						}}
					/>
					{img && (
						<>
							<div
								style={{
									position: 'relative',
									height: '210px',
									width: '400px',
									display: 'flex',
								}}
							>
								<Cropper
									image={img}
									crop={crop}
									zoom={zoom}
									aspect={9 / 6}
									onCropChange={setCrop}
									onCropComplete={onCropComplete}
									onZoomChange={setZoom}
								/>
								<Button
									variantColor="purple"
									size="xs"
									borderRadius="none"
									onClick={() => {
										setThumbnail(cropped);
										setSubmitted(true);
									}}
									_focus={{ outline: 'none' }}
								>
									Save
								</Button>
							</div>
							<Slider
								min={1}
								max={3}
								step={0.1}
								width="400px"
								defaultValue={1}
								onChange={(zoom) => setZoom(zoom)}
							>
								<SliderTrack />
								<SliderFilledTrack />
								<SliderThumb />
							</Slider>
						</>
					)}
				</div>
			)}
		</div>
	);
};
