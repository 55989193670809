import React from 'react';
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	useColorModeValue,
	useColorMode,
	Badge,
	useToast,
	Switch,
	Textarea,
	Tooltip,
} from '@chakra-ui/react';
import { BrainIconLarge } from '../../assets/icons';
import isElectron from 'is-electron';
import axios from 'axios';

//Redux
import { connect } from 'react-redux';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import HorizontalBox from '../Reusable/HorizontalBox';
import { NewItem } from '../Reusable/HorizontalBox';
import InputComponent from '../Reusable/Input';
import SkillPackInstallModal from '../SkillPack/SkillPackInstallModal';
import ImportDAGModal from './ImportDAGModal';

//Utils
import GetProfileSlug from '../../util/ProfileSlug';

//FlowViz
import FlowViz from '../../util/FlowViz';

//JSONs
import gmailSendJson from './samples/gmail-send.json';
import youtubePlayJson from './samples/youtube-play.json';
import cronReminderJson from './samples/cron-reminder.json';
import googleScrapeJson from './samples/google-scrape.json';
import telegramThreeReplyJson from './samples/telegram-3-reply.json';
import telegramTwoReplyJson from './samples/telegram-2-reply.json';
import httpJson from './samples/http.json';
import templateFormJson from './samples/template-form.json';
import twitterJson from './samples/twitter.json';
import urlJson from './samples/url-skill.json';
import templateSliderJson from './samples/template-slider.json';
import droneJson from './samples/drone.json';

//JSONs

//Analytics
import analytics from '../../util/Analytics';

//Functions
import createSkillPackFromFlow from './createSkillPackFromFlow';
import getDeveloperAccounts from '../../functions/developer/getDeveloperAccounts';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

const ExportButton = ({ flow, prompt }) => {
	function getDateString() {
		const date = new Date();
		const year = date.getFullYear();
		const month = `${date.getMonth() + 1}`.padStart(2, '0');
		const day = `${date.getDate()}`.padStart(2, '0');
		const hours = `${date.getHours()}`.padStart(2, '0');
		const mins = `${date.getMinutes()}`.padStart(2, '0');
		return `${hours}${mins}hrs-${day}-${month}-${year}`;
	}

	const downloadFile = async () => {
		const myData = {
			title: 'Flow generated and exported from Maya',
			text: prompt,
			flow: flow,
		};
		const fileName = 'dagbert-skillflow-' + getDateString();
		const json = JSON.stringify(myData);
		const blob = new Blob([json], { type: 'application/json' });
		const href = await URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = href;
		link.download = fileName + '.json';
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Button
			colorScheme="purple"
			bg={'purple.500'}
			color="white"
			size="sm"
			onClick={() => {
				downloadFile();
			}}
			loadingText={'Starting...'}
			borderRadius="5px"
			paddingX="20px"
			isDisabled={flow.length === 0}
			ml="2"
		>
			Export
		</Button>
	);
};

const GeneratorModal = ({ currentBrainId, isOpen, onOpen, onClose }) => {
	const [isGenLoading, setGenLoading] = React.useState(false);
	const [flow, setFlow] = React.useState([]);
	const [enabled, setEnabled] = React.useState(false);
	const {
		isOpen: isInstallOpen,
		onOpen: onInstallOpen,
		onClose: onInstallClose,
	} = useDisclosure();
	const [prompt, setPrompt] = React.useState('');
	let [url, setUrl] = React.useState('');
	const [pack, setPack] = React.useState({});
	const [mocking, setMocking] = React.useState(true);
	const toast = useToast();
	const { slug } = GetProfileSlug();

	const delayBetweenFlows = 2000;
	const delayBetweenNodes = 100;
	const setIncrementally = (selectedFlow) => {
		setFlow([]);
		return new Promise((resolve, reject) => {
			selectedFlow.map((nodeItem, i) => {
				setTimeout(() => {
					setFlow((flow) => [...flow, nodeItem]);
					if (i === selectedFlow.length - 1) {
						setTimeout(() => {
							// setFlow([]);
							resolve(true);
						}, delayBetweenFlows);
					}
				}, delayBetweenNodes * i);
			});
		});
	};

	const jsonArray = [
		youtubePlayJson,
		cronReminderJson,
		googleScrapeJson,
		gmailSendJson,
	];

	const runAnimation = (d) => {
		setIncrementally(jsonArray[d]).then(() => {
			d++;
			if (d < jsonArray.length) {
				runAnimation(d);
			} else {
				runAnimation(0);
			}
		});
	};

	React.useEffect(() => {
		let serverUrl = localStorage.getItem('genUrl');
		setUrl(serverUrl);
	}, []);

	React.useEffect(() => {
		getDeveloperAccounts({ slug }).then((res) => {
			if (res.data.length > 0) {
				setEnabled(true);
			} else {
				setEnabled(false);
			}
		});
	}, [slug]);

	const randomJsonArray = [
		googleScrapeJson,
		templateFormJson,
		gmailSendJson,
		telegramTwoReplyJson,
		cronReminderJson,
		templateSliderJson,
		urlJson,
		droneJson,
		telegramThreeReplyJson,
		httpJson,
		youtubePlayJson,
		twitterJson,
	];

	const onGenerateAPI = () => {
		return new Promise((resolve, reject) => {
			if (mocking) {
				if (prompt) {
					var choice = {};
					if (prompt.includes('drone')) {
						choice = droneJson;
						resolve(choice.flow);
					} else if (prompt.includes('email')) {
						choice = gmailSendJson;
						resolve(choice.flow);
					} else if (
						prompt.includes('Telegram') ||
						prompt.includes('telegram')
					) {
						choice = telegramTwoReplyJson;
						resolve(choice.flow);
					} else if (prompt.includes('twitter')) {
						choice = twitterJson;
						resolve(choice.flow);
					} else if (prompt.includes('sliders')) {
						choice = templateSliderJson;
						resolve(choice.flow);
					} else if (
						prompt.includes('Google') ||
						prompt.includes('google')
					) {
						choice = googleScrapeJson;
						resolve(choice.flow);
					} else {
						var rndChoice =
							randomJsonArray[
								Math.floor(Math.random() * randomJsonArray.length)
							];
						var jsonChoice = rndChoice.flow;

						setPrompt(rndChoice.text);
						resolve(jsonChoice);
					}
				} else {
					var rndChoice =
						randomJsonArray[
							Math.floor(Math.random() * randomJsonArray.length)
						];
					var jsonChoice = rndChoice.flow;

					setPrompt(rndChoice.text);
					resolve(jsonChoice);
				}
			} else {
				if (url) {
					var config = {
						method: 'get',
						url: url,
						headers: {
							'Content-Type': 'application/json',
						},
						params: {
							prefix: prompt,
						},
					};

					axios(config)
						.then(function (response) {
							localStorage.setItem('genUrl', url);
							console.log(response);
							resolve(response.data.flow);
						})
						.catch(function (error) {
							console.log(error);
						});
				} else {
					setGenLoading(false);
					toast({
						title: 'Server URL Missing!',
						description:
							'Enter in the format : http://<host>:<port>/gen.',
						status: 'warning',
						duration: 9000,
						isClosable: true,
					});
				}
			}
		});
	};

	React.useEffect(() => {
		if (flow.length >= 0) {
			createSkillPackFromFlow(flow, slug, prompt)
				.then((res) => {
					setPack(res);
					setGenLoading(false);
				})
				.catch((err) => {
					console.log(err);
				});
		}
	}, [flow, slug]);

	const handleGenerate = () => {
		setGenLoading(true);
		setTimeout(async () => {
			var jsonChoice = await onGenerateAPI();
			setIncrementally(jsonChoice);
			// setFlow(jsonChoice);
		}, 1000);
	};

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="xl"
			fontFamily="body"
			isCentered={isElectron()}
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				maxHeight="700px"
				maxWidth="880px"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody paddingX="0">
					<HeaderWithDescription
						header={'Generate Maya Skill'}
						desc={"Describe what you'd like Maya to do."}
						paddingLeft="1rem"
					/>
					<Box>
						<Box display="flex" paddingX="1rem" paddingY="0.2rem">
							<Textarea
								placeholder="Describe what you want Maya to do here e.g. Create a form with entries name, date of birth, and age."
								size="sm"
								colorScheme="purple"
								minWidth="690px"
								height="40px"
								variant="filled"
								fontSize="1rem"
								borderRadius="5px"
								focusBorderColor="purple.300"
								value={prompt}
								onChange={(e) => {
									setPrompt(e.target.value);
								}}
							/>
							<Box>
								<Button
									colorScheme="purple"
									bg={'purple.500'}
									color="white"
									size="sm"
									borderRadius="5px"
									paddingX="20px"
									height="40px"
									ml="2"
									mb="2"
									width="150px"
									isLoading={isGenLoading}
									isDisabled={!mocking && prompt === '' && url === ''}
									loadingText="Generating..."
									onClick={handleGenerate}
								>
									Generate
								</Button>
								<Button
									colorScheme="gray"
									color="white"
									size="sm"
									borderRadius="5px"
									paddingX="20px"
									ml="2"
									mb="2"
									width="150px"
									onClick={() => {
										setFlow([]);
										setPrompt('');
									}}
								>
									Clear
								</Button>
							</Box>
						</Box>
						<FlowViz
							flowDefinition={flow}
							onRender={() => {}}
							onNodeMouseEnter={() => {}}
							onNodeMouseLeave={() => {}}
							onElementClick={() => {}}
							background={'grid'}
							height={350}
							width={880}
						/>
					</Box>
					<Box
						paddingTop="0.5rem"
						display="flex"
						width="100%"
						alignItems="center"
						borderTop="solid 1px"
						borderColor={useColorModeValue(
							'dashboard.border_light',
							'dashboard.border_dark'
						)}
						justifyContent="space-between"
						boxShadow="rgba(0, 0, 0, 0.1) 0px -2px 4px"
					>
						{enabled ? (
							<Box display="flex" alignItems="center" marginLeft="4">
								Live?{' '}
								<Switch
									size="sm"
									marginLeft="2"
									colorScheme="purple"
									isChecked={!mocking}
									onChange={(e) => {
										setMocking(!mocking);
										setFlow([]);
										setPrompt('');
									}}
								/>
								{mocking ? null : (
									<InputComponent
										placeholder="http://<host>:<port>/gen"
										marginLeft="3"
										width="200px"
										default={''}
										onChange={(event) => {
											setUrl(event.target.value);
										}}
									/>
								)}
							</Box>
						) : (
							<Tooltip
								label="Request a developer account in the Settings section to start using this. Till then, pressing Generate will display some test samples."
								aria-label="A tooltip"
							>
								<Badge
									colorScheme="purple"
									marginLeft="1rem"
									cursor="pointer"
								>
									DEVELOPER ACCOUNT REQUIRED
								</Badge>
							</Tooltip>
						)}
						<Box
							mr="1rem"
							display="flex"
							width="100%"
							justifyContent="flex-end"
							alignItems="center"
							flexDirection="row"
						>
							{false && (
								<Button
									colorScheme="purple"
									bg={'purple.500'}
									color="white"
									size="sm"
									onClick={() => {}}
									loadingText={'Starting...'}
									borderRadius="5px"
									paddingX="20px"
									isDisabled={flow.length === 0}
									ml="2"
								>
									Save
								</Button>
							)}
							{enabled && mocking ? (
								<ImportDAGModal
									setFlow={setFlow}
									setPrompt={setPrompt}
								/>
							) : null}
							{mocking ? null : (
								<ExportButton flow={flow} prompt={prompt} />
							)}
							<Button
								colorScheme="green"
								bg={'green.500'}
								color="white"
								size="sm"
								onClick={() => {
									onInstallOpen();
								}}
								loadingText={'Starting...'}
								borderRadius="5px"
								paddingX="20px"
								isDisabled={flow.length === 0}
								ml="2"
							>
								Install
							</Button>
						</Box>
					</Box>
					{flow.length !== 0 ? (
						<SkillPackInstallModal
							onClose={onInstallClose}
							isOpen={isInstallOpen}
							currentBrainId={currentBrainId}
							pack={pack}
						/>
					) : null}
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

// <ModalHeader>Select Brain</ModalHeader>

/**
 * Select Brain/New Workspace Component.
 *
 * This is a reusable component which can be used anywhere to select
 * an existing Maya Brain or create a new one and select it.
 * @param {*} param0
 */

const SelectGenerator = ({
	brains,
	brainIds,
	isLoading,
	onSelectFn,
	initialBrainId,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { colorMode } = useColorMode();
	const [mode, setMode] = React.useState('existing');

	return (
		<Box>
			<HeaderWithDescription
				header={'Select Flow Generator'}
				desc={'Choose from among an array of generalization capabilities.'}
			/>

			<HorizontalBox
				onClick={() => {
					onOpen();
				}}
			>
				<NewItem
					onClick={() => {
						setMode('new');
					}}
					icon={
						<BrainIconLarge
							size={40}
							color={{ light: '#464646', dark: 'white' }[colorMode]}
						/>
					}
					text={'Maya L1 v0.0.5'}
				/>
			</HorizontalBox>

			<GeneratorModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				mode={mode}
				setMode={setMode}
				currentBrainId={initialBrainId}
			/>
		</Box>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	const brainIds = brains.allIds;
	const { isLoading } = state.dashboard;
	return { brainIds, brains, isLoading };
};

export default connect(mapStateToProps, null)(SelectGenerator);
