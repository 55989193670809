import React from 'react';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { Provider } from 'react-redux';
import Main from './components/MainApp';
import History from './util/History';
import customTheme from './theme/index';
import performanceData from './util/Performance';
import { store, persistor } from '../src/redux/store';

//Sentry added
performanceData.init();

const App = () => {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ChakraProvider theme={customTheme}>
					<ColorModeScript initialColorMode="dark" />
					<Router history={History}>
						<Main />
					</Router>
				</ChakraProvider>
			</PersistGate>
		</Provider>
	);
};

export default App;

// const App = () => {
//   return (
//     <Provider store={store}>
//       <PersistGate loading={null} persistor={persistor}>
//         <ThemeProvider theme={customTheme}>
//           <ColorModeProvider>
//             <CSSReset />
//             <Router history={History}>
//               <Main />
//             </Router>
//           </ColorModeProvider>
//         </ThemeProvider>
//       </PersistGate>
//     </Provider>
//   );
// };
