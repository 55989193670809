import React from 'react';
import { Box, IconButton, Button, LightMode } from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { useSelector } from 'react-redux';
import isElectron from 'is-electron';
import Iframe from 'react-iframe';
import _ from 'lodash';

const RedDashboardSection = ({ currentBrainId }) => {
	const brains = useSelector((state) => {
		return state.brains;
	});
	const botDashUrl = React.useMemo(() => {
		if (currentBrainId) {
			const hostname = brains.byId[currentBrainId].url;
			const newUrl = hostname + '/ui?' + 'mode=' + 'dark' + '&theme=angular';
			return newUrl;
		} else return '';
	}, [brains.byId]);

	const dashboardExists = React.useMemo(() => {
		var brain = brains.byId[currentBrainId];
		var check = _.findIndex(brain.flows, function (o) {
			return o.type === 'ui_tab';
		});

		if (check === -1) {
			return false;
		} else {
			return true;
		}
	}, [brains, currentBrainId]);

	return dashboardExists ? (
		<Box
			height="100vh"
			display="flex"
			flexDirection="column"
			justifyContent="right"
		>
			<Button
				size="xs"
				margin="auto"
				marginRight="0"
				marginBottom="0.5rem"
				// width="140px"
				leftIcon={<ExternalLinkIcon />}
				variant="solid"
				onClick={async () => {
					if (isElectron()) {
						const electron = window.require('electron');
						const { shell } = electron;
						await shell.openExternal(botDashUrl);
					} else {
					}
				}}
			>
				Open In Browser
			</Button>

			{botDashUrl ? (
				<Iframe
					url={botDashUrl}
					width="100%"
					height="100%"
					display="initial"
					position="relative"
				/>
			) : null}
		</Box>
	) : (
		<Box width="100%">
			<LightMode>
				<Box
					margin="auto"
					width="350px"
					textAlign="center"
					marginTop="10rem"
				>
					No dashboard found. Install the Maya Dashboard module from the
					store to start building one.
					<Button
						marginTop="1rem"
						colorScheme="purple"
						onClick={() => {
							if (isElectron()) {
								const electron = window.require('electron');
								const { shell } = electron;
								shell.openExternal(
									'https://docs.mayahq.com/product/node-library/dashboard'
								);
							} else {
							}
						}}
					>
						View docs
					</Button>
				</Box>
			</LightMode>
		</Box>
	);
};

export default RedDashboardSection;
