import actionTypes from '../actionTypes';
import { initialState } from './card_init';
import dotProp from 'dot-prop-immutable';
import _ from 'lodash';

export const AddIntents = (sourceArray, targetArray) => {
	_.forEach(sourceArray.allIds, (sourceId) => {
		if (!targetArray.intents.byId[sourceId]) {
			targetArray = dotProp.set(
				targetArray,
				`intents.byId.${sourceId}`,
				sourceArray.byId[sourceId]
			);
			targetArray = dotProp.set(targetArray, 'intents.allIds', (list) => [
				...list,
				sourceId,
			]);
		} else {
			targetArray = dotProp.set(
				targetArray,
				`intents.byId.${sourceId}`,
				sourceArray.byId[sourceId]
			);
		}
	});
	_.forEach(targetArray.intents.allIds, (targetId) => {
		if (!_.includes(sourceArray.allIds, targetId)) {
			let index = _.indexOf(targetArray.intents.allIds, targetId);
			if (index !== -1) {
				targetArray = dotProp.delete(
					targetArray,
					`intents.allIds.${index}`
				);
				targetArray = dotProp.delete(
					targetArray,
					`intents.byId.${targetId}`
				);
			}
		}
	});

	return targetArray;
};

const cardUiReducer = (state = initialState, action) => {
	switch (action.type) {
		// flow/ level
		case actionTypes.CUI_ADD_INTENTS: {
			const { intents } = action.payload;
			return AddIntents(intents, state);
		}

		case actionTypes.CUI_SET_CARD_INTERACTION: {
			const { enabled } = action.payload;
			return dotProp.set(
				state,
				`cards.settings.interactionEnabled`,
				enabled
			);
		}

		case actionTypes.CUI_NEW_CARD: {
			console.log('NEW CARD CREATED');
			const { cardId, card } = action.payload;
			// Collapse all existing cards first
			dotProp.get(state, `cards.allIds`).forEach((cardId) => {
				state = dotProp.set(
					state,
					`cards.byId.${cardId}.display`,
					'collapsed'
				);
			});
			state = dotProp.set(state, `cards.byId.${cardId}`, card);
			state = dotProp.set(state, `cards.allIds`, (list) => [
				...list,
				cardId,
			]);
			let cardIds = dotProp.get(state, `cards.allIds`);
			state = dotProp.set(
				state,
				`cards.settings.selectedIndex`,
				cardIds.length - 1
			);

			return state;
		}
		case actionTypes.CUI_SET_CARD_MAXIMIZED: {
			const { id, maximized } = action.payload;
			state = dotProp.set(state, `cards.byId.${id}.maximized`, maximized);
			return state;
		}
		case actionTypes.CUI_DELETE_CARD: {
			const { cardId } = action.payload;
			const cardIds = dotProp.get(state, 'cards.allIds');
			let index = _.findIndex(cardIds, (item) => {
				return item === cardId;
			});
			if (index !== -1) {
				console.log('index', index, cardIds.length - 2);
				state = dotProp.delete(state, `cards.byId.${cardId}`);
				state = dotProp.delete(state, `cards.allIds.${index}`);
				console.log(index !== 0 ? index - 1 : index);
				state = dotProp.set(
					state,
					`cards.settings.selectedIndex`,
					index !== 0 ? index - 1 : index
				);
			}

			// console.log(flowId, moduleId);
			return state;
		}
		case actionTypes.CUI_SET_SELECTED_CARD_INDEX: {
			const { index } = action.payload;
			return dotProp.set(state, `cards.settings.selectedIndex`, index);
		}
		case actionTypes.CUI_TOGGLE_DISPLAY_MODE: {
			const { cardId, displayMode } = action.payload;
			return dotProp.set(state, `cards.byId.${cardId}.display`, displayMode);
		}
		case actionTypes.CUI_TOGGLE_SEARCH_DISPLAY_MODE: {
			const { displayMode } = action.payload;
			return dotProp.set(state, `centerSearch.display`, displayMode);
		}
		case actionTypes.CUI_SET_CARD_WIDTH: {
			const { cardId, width } = action.payload;
			return dotProp.set(state, `cards.byId.${cardId}.moduleWidth`, width);
		}
		// flow/module/top level reducers
		case actionTypes.CUI_SET_SEARCH_LOADING_STATUS: {
			const { status } = action.payload;
			return dotProp.set(state, `centerSearch.top.loadingStatus`, status);
		}
		//TODO : Redundant
		case actionTypes.CUI_SET_SEARCH_SUBMIT_PROGRESS: {
			const { progress } = action.payload;
			return dotProp.set(state, `centerSearch.top.submitProgress`, progress);
		}
		case actionTypes.CUI_SET_SEARCH_MODE: {
			const { mode } = action.payload;
			return dotProp.set(state, `centerSearch.mode`, mode);
		}
		case actionTypes.CUI_SET_TOP_SEARCH_CONTENT: {
			const { cardId, array } = action.payload;
			return dotProp.set(state, `cards.byId.${cardId}.top.text`, array);
		}
		case actionTypes.CUI_SET_TOP_SEARCH_APP: {
			const { cardId, name } = action.payload;
			let app = dotProp.get(state, `applications.byId.${name}`);
			return dotProp.set(
				state,
				`cards.byId.${cardId}.top.selectedItem`,
				app
			);
		}
		case actionTypes.CUI_SET_TOP_INTENT_ARRAY: {
			const { intentObjectArray } = action.payload;
			return dotProp.set(
				state,
				`centerSearch.top.intentObjectArray`,
				intentObjectArray
			);
		}
		case actionTypes.CUI_SET_TOP_CURRENT_PLACEHOLDER_ID: {
			const { id } = action.payload;
			return dotProp.set(
				state,
				`centerSearch.middle.currentPlaceholderId`,
				id
			);
		}
		// flow/module/middle level reducers
		case actionTypes.CUI_SET_MIDDLE_SEARCH_ITEMS: {
			const { searchItems } = action.payload;
			console.log(searchItems);
			const stateLength = dotProp.set(
				state,
				`centerSearch.middle.length`,
				searchItems.allIds.length
			);
			let itemState = dotProp.set(
				stateLength,
				`centerSearch.middle.searchItems`,
				searchItems
			);

			let selectedIndex = 0;

			return dotProp.set(
				itemState,
				`centerSearch.middle.selectedIndex`,
				selectedIndex
			);
		}
		case actionTypes.CUI_SELECT_MIDDLE_SEARCH_ITEM: {
			let selectedIndex = dotProp.get(
				state,
				`centerSearch.middle.selectedIndex`
			);
			let searchItems = dotProp.get(
				state,
				`centerSearch.middle.searchItems`
			);

			return dotProp.set(
				state,
				`centerSearch.top.selectedItem`,
				searchItems.byId[searchItems.allIds[selectedIndex]]
			);
		}
		case actionTypes.CUI_SET_MIDDLE_SEARCH_ITEM_SELECTED_INDEX: {
			const { selectedIndex } = action.payload;
			return dotProp.set(
				state,
				`centerSearch.middle.selectedIndex`,
				selectedIndex
			);
		}
		case actionTypes.CUI_HIGHLIGHT_MIDDLE_SEARCH_ITEM: {
			const { direction } = action.payload;
			let length = dotProp.get(
				state,
				`centerSearch.middle.searchItems.allIds`
			).length;
			let selectedIndex = dotProp.get(
				state,
				`centerSearch.middle.selectedIndex`
			);

			if (direction === 'UP') {
				if (selectedIndex === 0) {
					selectedIndex = length - 1;
				} else {
					selectedIndex -= 1;
				}
			} else if (direction === 'DOWN') {
				if (selectedIndex === length - 1) {
					selectedIndex = 0;
				} else {
					selectedIndex += 1;
				}
			}
			return dotProp.set(
				state,
				`centerSearch.middle.selectedIndex`,
				selectedIndex
			);
		}
		case actionTypes.CUI_SET_MIDDLE_SEARCH_MODE_AND_TYPE: {
			const { mode, type } = action.payload;
			state = dotProp.set(state, `centerSearch.middle.mode`, mode);
			state = dotProp.set(
				state,
				`centerSearch.middle.placeholderSearchList.selectedIndex`,
				0
			);
			return dotProp.set(state, `centerSearch.middle.type`, type);
		}
		case actionTypes.CUI_TOGGLE_CARDS_VISIBILITY: {
			const { visibility } = action.payload;
			return dotProp.set(state, `cards.settings.visibility`, visibility);
		}
		case actionTypes.CUI_SET_MIDDLE_PLACEHOLDER_TEXT: {
			const { text } = action.payload;

			return dotProp.set(state, `centerSearch.middle.placeholderText`, text);
		}
		case actionTypes.CUI_TOGGLE_CARDS_ENABLED: {
			const { enabled } = action.payload;
			return dotProp.set(state, `cards.settings.enabled`, enabled);
		}
		case actionTypes.CUI_SET_PLACEHOLDER_VALUE_BY_ID: {
			const { id, value, meta } = action.payload;
			state = dotProp.set(
				state,
				`centerSearch.top.intentObjectArray.byId.${id}.meta`,
				meta
			);
			return dotProp.set(
				state,
				`centerSearch.top.intentObjectArray.byId.${id}.value`,
				value
			);
		}
		case actionTypes.CUI_SET_PLACEHOLDER_SEARCH_ITEMS: {
			const { searchItems } = action.payload;
			console.log(searchItems);
			const stateLength = dotProp.set(
				state,
				`centerSearch.middle.placeholderSearchList.length`,
				searchItems.length
			);

			return dotProp.set(
				stateLength,
				`centerSearch.middle.placeholderSearchList.searchItems`,
				searchItems
			);
		}
		case actionTypes.CUI_HIGHLIGHT_PLACEHOLDER_SEARCH_ITEM: {
			const { direction } = action.payload;
			let length =
				dotProp.get(
					state,
					`centerSearch.middle.placeholderSearchList.searchItems`
				).length + 1;
			let selectedIndex = dotProp.get(
				state,
				`centerSearch.middle.placeholderSearchList.selectedIndex`
			);

			if (direction === 'UP') {
				if (selectedIndex === 0) {
					selectedIndex = length - 1;
				} else {
					selectedIndex -= 1;
				}
			} else if (direction === 'DOWN') {
				if (selectedIndex === length - 1) {
					selectedIndex = 0;
				} else {
					selectedIndex += 1;
				}
			}
			return dotProp.set(
				state,
				`centerSearch.middle.placeholderSearchList.selectedIndex`,
				selectedIndex
			);
		}
		case actionTypes.CUI_SET_VIBRATE_INSTRUCTIONS: {
			const { vibrateInstructions } = action.payload;

			return dotProp.set(
				state,
				`centerSearch.middle.vibrateInstructions`,
				vibrateInstructions
			);
		}
		case actionTypes.CUI_UPDATE_COMMAND_BAR_STATUS: {
			const { visible, status, message } = action.payload;
			console.log('reducer', action.payload);
			if (visible !== null) {
				state = dotProp.set(state, `commandBarStatus.visible`, visible);
			}

			if (status !== null) {
				state = dotProp.set(state, `commandBarStatus.status`, status);
			}

			if (message !== null) {
				state = dotProp.set(state, `commandBarStatus.message`, message);
			}

			return state;
		}

		default: {
			return state;
		}
	}
};

export default cardUiReducer;
