import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// store
import { store } from '../../redux/store';

const getMayaJson = async (slug, flowjson, brainId) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;

	var getMayaJsonIPCFn, getMayaJsonResponse;

	getMayaJsonIPCFn = IPCFunction({
		EVENT: IPCRoutes.ipcService.getMayaJson.EVENT,
	});
	/**
	 * {
	 * 	error: true/false,
	 * 	message: "<error message if any>"
	 * 	data: []
	 * }
	 */
	getMayaJsonResponse = await getMayaJsonIPCFn({
		profileSlug,
		flowjson,
		brainId,
	});

	//TODO to be removed in next application release

	/**
	 * Check/Modify config values in bot-config node.
	 * Reset config values with exportVar = `false`, otherwise keep the value as is.
	 */
	let flowsWithModules = getMayaJsonResponse?.data?.results.map((node) => {
		let newNode = node;
		if (newNode.type === 'bot-config') {
			const botConfigNode = newNode;
			if (Array.isArray(botConfigNode.rules)) {
				botConfigNode.rules = botConfigNode.rules.map((rule) => {
					if (!rule.exportVar) return { ...rule, to: '' }; // reset if not true
					console.log(rule);
					return rule; // allow export
				});
			}
			newNode = botConfigNode;
		}
		return newNode;
	});
	getMayaJsonResponse['data']['results'] = flowsWithModules;

	return getMayaJsonResponse;
};

export default getMayaJson;
