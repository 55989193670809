import React from 'react';
import { Box, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useState } from 'react';

import _ from 'lodash';
import { SiGoogle } from 'react-icons/si';
import { GoSync } from 'react-icons/go';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';

//Components
import HorizontalItem, {
	HorizontalItemButton,
} from '../../Reusable/HorizontalItem';
import IconContainer from '../../../util/React_Icon';
import ImportContactsButton from './ImportContactsButton';
import SyncButton from './SyncButton';

let icons = {
	gcontacts: <SiGoogle />,
};

export const ContactServiceItemLeft = ({
	details,
	contactService,
	contactServiceName,
	onClick,
}) => {
	const { colorMode } = useColorMode();
	const fontColor = {
		light: '#6C6C6C',
		dark: 'white',
	};
	return (
		<Box display="flex" alignItems="center" onClick={onClick} height="100%">
			<Box
				width="70px"
				height="100%"
				display="flex"
				alignItems="center"
				ml="8"
			>
				<IconContainer
					icon={icons[contactService]}
					value={{
						color: fontColor[colorMode],
						className: 'global-class-name',
						size: '2rem',
					}}
				/>
			</Box>
			<Box justifyContent="space-between" display="flex" flexDir="column">
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					fontSize="22px"
					mb="0.10rem"
					color={useColorModeValue('#6C6C6C', 'white')}
					fontFamily="body"
				>
					{contactServiceName}
				</Box>
				{details ? (
					details.length !== 0 ? (
						<Box
							fontSize="17px"
							color={fontColor[colorMode]}
							fontFamily="body"
							opacity="0.7"
							mt="-0.15rem"
							mb="0.12rem"
						>
							{_.truncate(`${details.length} contacts`, {
								length: 90,
								separator: /,? +/,
							})}
						</Box>
					) : (
						<Box
							fontSize="17px"
							color={fontColor[colorMode]}
							fontFamily="body"
							opacity="0.7"
							mt="-0.15rem"
							mb="0.12rem"
						>
							{_.truncate(`0 contacts`, {
								length: 90,
								separator: /,? +/,
							})}
						</Box>
					)
				) : (
					<Box
						fontSize="17px"
						color={fontColor[colorMode]}
						fontFamily="body"
						opacity="0.7"
						mt="-0.15rem"
						mb="0.12rem"
					>
						{_.truncate(`0 contacts`, {
							length: 90,
							separator: /,? +/,
						})}
					</Box>
				)}
			</Box>
		</Box>
	);
};

const ContactsList = ({ contact }) => {
	const { colorMode } = useColorMode();
	const fontColor = {
		light: '#6C6C6C',
		dark: 'white',
	};
	const bgColor = {
		light: '#FFFFFF',
		dark: '#444444',
	};
	return (
		<Box
			display="flex"
			direction="rtl"
			alignItems="center"
			bg={bgColor[colorMode]}
			maxWidth="95%"
			width={'230px'}
			paddingX="1rem"
			paddingY="0.3rem"
			mb="2"
			mt="2"
			justifyContent="space-between"
			borderRadius="0.4rem"
			boxShadow="0px 2px 2px rgba(0, 0, 0, 0.05)"
		>
			<Box>{contact.displayName}</Box>
			<Box display="flex">
				{contact.phoneNumbers.length >= 0 ? (
					<Box>
						<IconContainer
							icon={<AiOutlinePhone />}
							value={{
								color: fontColor[colorMode],
								className: 'global-class-name',
								size: '1rem',
							}}
						/>
					</Box>
				) : null}
				{contact.emailAddresses.length >= 0 ? (
					<Box ml="2">
						<IconContainer
							icon={<AiOutlineMail />}
							value={{
								color: fontColor[colorMode],
								className: 'global-class-name',
								size: '1rem',
							}}
						/>
					</Box>
				) : null}
			</Box>
		</Box>
	);
};

const ContactServiceItem = ({
	contactsDetails,
	contactService,
	contactServiceName,
	serviceDetails,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	setContacts,
}) => {
	const [hoveredButton, setHoveredButton] = useState('');
	const [isOpen, setIsOpen] = useState(isItemExpanded);

	React.useEffect(() => {
		setIsOpen(isItemExpanded);
	}, [isItemExpanded]);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<HorizontalItem
			isOpen={isOpen}
			onClickItem={
				wholeItemActive
					? () => {
							toggleOpen();
					  }
					: () => {}
			}
			selected={selected}
			itemLeft={
				<ContactServiceItemLeft
					details={contactsDetails}
					contactService={contactService}
					contactServiceName={contactServiceName}
					onClick={() => {
						toggleOpen();
					}}
				/>
			}
			buttons={
				<Box display="flex">
					<ImportContactsButton
						setHoveredButton={setHoveredButton}
						hoveredButton={hoveredButton}
						serviceDetails={serviceDetails}
						setContacts={setContacts}
					/>
					<SyncButton
						setHoveredButton={setHoveredButton}
						hoveredButton={hoveredButton}
						serviceDetails={serviceDetails}
						setContacts={setContacts}
					/>
				</Box>
			}
			isItemExpanded={isItemExpanded}
			expandedContent={
				<Box paddingLeft="6rem" height="20rem" overflow="auto">
					{contactsDetails
						? contactsDetails.map((contact) => {
								return <ContactsList contact={contact} />;
						  })
						: null}
				</Box>
			}
		/>
	);
};

export default ContactServiceItem;

//
