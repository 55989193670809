import React from 'react';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import fuzzysort from 'fuzzysort';
import { setPlaceholderLoading } from '../../../../../../../redux/actions/cardUIV2';
import { Box } from '@chakra-ui/layout';

/**
 * Max number of visible placeholder suggestions(results)
 */
const MAX_VISIBLE_RESULTS = 5;

/**
 * Max number of total results
 */
const MAX_RESULTS = 15;

/**
 * Fn to get clipped subset of allItems for scrollable displaying
 * @param {any[]} allItems list of all items
 * @param {number} selectedIdx index of selected item
 * @param {number} maxResults max number of displayable results
 */
const getClippedItemsScrollable = (
	allItems,
	selectedIdx,
	maxResults = MAX_VISIBLE_RESULTS
) => {
	const itemsCopy = allItems.map((item, idx) => ({ item, idx }));
	if (itemsCopy.length <= maxResults) {
		return itemsCopy;
	} else {
		if (selectedIdx <= maxResults) return itemsCopy.slice(0, maxResults);
		else return itemsCopy.slice(selectedIdx - maxResults, selectedIdx);
	}
};

/**
 * Custom error class for CustomTypeSearch
 */
class CustomTypeSearchError extends Error {
	constructor(message) {
		super(message);
		this.name = 'CustomTypeSearchError';
	}
}

const CustomTypeSearch = ({
	placeholderText: text,
	placeholderDetails,
	setPlaceholderSearchItems,
	searchItems,
	selectedIndex,
	currentIntentId,
}) => {
	const dispatch = useDispatch();
	const currentIntentObject = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities.intents.byId[currentIntentId]
	);

	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.8, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['value', 'meta.subtext'],
	};

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return { show: thing.item.value, submit: thing.item.meta };
		});
	};

	var doneTypingInterval = 200; //time in ms, 5 second for example
	const typingTimer = React.useRef(null);

	console.log(
		'Placeholder details:',
		currentIntentObject.baseUrl + placeholderDetails.endpoint
	);
	const onTextChange = async (searchText) => {
		dispatch(setPlaceholderLoading(true));
		try {
			if (
				searchItems.findIndex((obj) => {
					return obj.show === searchText;
				}) === -1 // searchText is not present in suggestions
			) {
				const response = await axios.post(
					currentIntentObject.baseUrl + placeholderDetails.endpoint,
					{
						search: searchText,
					}
				);
				/**
				 * @type {{
				 * 	value: string,
				 * 	meta: {
				 * 		subtext: string,
				 * 		[key: string]: any,
				 * 	}
				 * }[]}
				 */
				const respData = response.data;
				if (respData?.length > 0) {
					let newSearchItems;
					if (searchText && searchText !== '') {
						const results = fuzzysort.go(searchText, respData, {
							allowTypo: true,
							keys: ['value', 'meta.subtext'],
						});
						newSearchItems = results
							// .slice(0, MAX_RESULTS)
							.map((item) => ({ item: item.obj }));
					} else {
						newSearchItems = respData
							// .slice(0, MAX_RESULTS)
							.map((item) => ({ item: item }));
					}
					setFilteredItems(newSearchItems);
					setPlaceholderSearchItems(selectFunction(newSearchItems));
					dispatch(setPlaceholderLoading(false));
				} else {
					dispatch(setPlaceholderLoading(false));
					throw new CustomTypeSearchError(
						'Search term did not match any results'
					);
				}
			}
		} catch (error) {
			console.log(error);
			const noResultsItem = {
				value: 'No results found',
				meta: {
					subtext: 'An error occurred while fetching',
					icon: '', // reset icon
				},
			};
			if (error instanceof CustomTypeSearchError) {
				noResultsItem.meta.subtext = error.message;
			}
			const newSearchItems = [
				{
					item: noResultsItem,
				},
			];
			setFilteredItems(newSearchItems);
			setPlaceholderSearchItems(selectFunction(newSearchItems));
			dispatch(setPlaceholderLoading(false));
		}
	};

	React.useEffect(() => {
		clearTimeout(typingTimer.current);
		typingTimer.current = setTimeout(() => {
			onTextChange(text);
		}, doneTypingInterval);
	}, [text, doneTypingInterval]);

	/** Scrollbar height in % */
	const scrollbarHeight = (MAX_VISIBLE_RESULTS / filteredItems.length) * 100;
	/**
	 * Get scrollbar top margin based on the following mapping ratio:
	 * idx = 0 -> top = 0
	 * idx = items.length - 1 -> top = 100% - height
	 * PS: actual values subjected to tweaks
	 */
	/** Scrollbar top margin in % */
	const scrollbarTopMargin =
		(selectedIndex / filteredItems.length) * (100 - scrollbarHeight);

	return (
		<Box position="relative">
			{/* show scrollbar only if items are more than max visible items */}
			{filteredItems.length > MAX_VISIBLE_RESULTS ? (
				<Box
					position="absolute"
					left="0"
					bg="card_ui.purple"
					w="1px"
					h={`${scrollbarHeight}%`}
					top={`${scrollbarTopMargin}%`}
				/>
			) : null}
			{getClippedItemsScrollable(filteredItems, selectedIndex).map(
				({ item: current, idx: i }) => (
					<SearchItem
						selectedIndex={selectedIndex}
						key={i}
						i={i}
						searchItemMainText={current.item.value}
						searchItemSubText={current.item.meta.subtext || ''}
						reactIcon={current.item.meta.icon}
					/>
				)
			)}
		</Box>
	);

	// return filteredItems.map((current, i) => {
	// 	console.log(current);
	// 	return (
	// 		<SearchItem
	// 			selectedIndex={selectedIndex}
	// 			key={i}
	// 			i={i}
	// 			searchItemMainText={current.item.value}
	// 			searchItemSubText={current.item.meta.subtext || ''}
	// 			reactIcon={current.item.meta.icon}
	// 		/>
	// 	);
	// });
};

export default CustomTypeSearch;
