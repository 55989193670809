// Dependency imports

import IPCFunction from '../../util/IPCFetch/function';
import isElectron from 'is-electron';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to toggle Command Bar
 */

const toggleCommandBar = async (arg) => {
	if (isElectron()) {
		var toggleCommandBarIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.toggleCommandBar.EVENT,
		});
		return toggleCommandBarIPCFn(arg);
	} else {
		return {};
	}
};

export default toggleCommandBar;
