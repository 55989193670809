import React from 'react';

import { Box, useColorMode, IconButton, DarkMode } from '@chakra-ui/react';
import { AddIcon, SmallCloseIcon } from '@chakra-ui/icons';
import History, { HistoryWithSlug } from '../../util/History';

//Redux
import { connect } from 'react-redux';
import { setNavRoute, setTrialExpiryModalDisplay } from '../../redux/actions';

//Analytics
import analytics from '../../util/Analytics';
import { getTimeToExpiry } from '../../util/Tier/getTimeRemaining';

const NewSkillButton = ({ mode, setMode, pageMode, setNavRoute, setTrialExpiryModalDisplay }) => {
	const { colorMode } = useColorMode();

	const fontColor = { light: '#555555', dark: 'white' };
	const plusColor = { light: 'purple.500', dark: 'white' };
	const hoverColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.250',
	};
	const borderColor = {
		light: 'dashboard.border_light',
		dark: 'dashboard.border_dark',
	};

	function handleClick() {
		if (mode === 'new') {
			if (pageMode !== 'editor') {
				HistoryWithSlug.push('/skills');
			} else {
				setNavRoute('skills');
			}

			analytics.track(`New Skill Button Pressed`, {
				pageMode,
				mode,
			});
			setMode('list');
		} else {
			getTimeToExpiry()
				.then((res) => {
					console.log('ver time', res)
					if (res <= 0) {
						console.log('ver bruhhhhhh')
						setTrialExpiryModalDisplay(true)
						return
					}

					if (pageMode !== 'editor') {
						HistoryWithSlug.push('/skills/new');
					} else {
						setNavRoute('skillsnew');
					}
					
					setMode('new');
				})
				.catch((e) => {
					console.log('ver', e)
				})
				
			analytics.track(`New Skill Button Pressed`, {
				pageMode,
				mode,
			});
		}
	}

	return (
		<Box
			height="100px"
			// maxWidth="1550px"
			bg={mode === 'new' ? 'purple.500' : 'none'}
			display="flex"
			alignItems="center"
			borderRadius="0.2rem"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			mb="1rem"
			mt="0.5rem"
			border="solid 1px"
			borderColor={borderColor[colorMode]}
			_hover={{
				bg: mode === 'new' ? 'purple.500' : hoverColor[colorMode],
				borderColor:
					mode === 'new' ? 'transparent' : borderColor[colorMode],
			}}
			onClick={handleClick}
		>
			<Box
				ml="1rem"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				width="100%"
				minHeight="100px"
			>
				<Box display="flex" alignItems="center">
					<Box
						w="40px"
						h="40px"
						border="solid 1px"
						borderColor={mode === 'new' ? 'white' : plusColor[colorMode]}
						borderRadius="60px"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<AddIcon
							boxSize="0.7rem"
							color={mode === 'new' ? 'white' : plusColor[colorMode]}
						/>
					</Box>
					<Box ml="1rem">
						<Box
							fontFamily="body"
							fontSize="19px"
							color={mode === 'new' ? 'white' : fontColor[colorMode]}
						>
							New Skill Workflow
						</Box>
						<Box
							fontFamily="body"
							fontSize="14px"
							color={mode === 'new' ? 'white' : fontColor[colorMode]}
						>
							Create Maya workflows to do tasks from scratch or from an
							existing template , and add it to a new or existing Maya
							Workspace.
						</Box>
					</Box>
				</Box>
				{mode === 'new' ? (
					<DarkMode>
						<IconButton
							aria-label="cancel"
							icon={<SmallCloseIcon color="white" />}
							mr="1rem"
							size="sm"
							borderRadius="5px"
							onClick={() => {
								if (pageMode !== 'editor') {
									HistoryWithSlug.push('/skills');
								} else {
									setNavRoute('skills');
								}
								analytics.track(`Exit to Skill List`, { pageMode });

								setMode('list');
							}}
						/>
					</DarkMode>
				) : null}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { pageMode } = state.navigation;
	return { pageMode };
};

export default connect(mapStateToProps, { setNavRoute, setTrialExpiryModalDisplay })(NewSkillButton);
