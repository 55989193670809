import { isCronMinutesValue, isCronSecondsValue } from '@mayahq/cron';
import { Flex, Text } from '@chakra-ui/react';
import isNumber from 'lodash/isNumber';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import NumericPeriodValuesSelector from '../NumericPeriodValuesSelector';

/**
 *
 * @param {{
 * 	cronObject: import('@mayahq/cron').CronQuartz,
 * 	onCronObjectChange: (newCronObject: import('@mayahq/cron').CronQuartz) => void,
 *  	menuProps?: import('@chakra-ui/react').MenuProps,
 *		menuButtonProps?: import('@chakra-ui/react').MenuButtonProps & import('@chakra-ui/react').ButtonProps,
 *		menuListProps?: import('@chakra-ui/react').MenuListProps,
 *		menuItemProps?: import('@chakra-ui/react').MenuItemProps,
 * }} param0
 * @returns
 */
const EveryMinutesSelector = ({
	cronObject,
	onCronObjectChange,
	menuProps,
	menuButtonProps,
	menuListProps,
	menuItemProps,
}) => {
	const [minutes, setMinutes] = useState(() =>
		isCronMinutesValue(cronObject?.minutes?.every)
			? cronObject.minutes.every
			: 1
	);
	const [seconds, setSeconds] = useState(() =>
		isCronSecondsValue(cronObject?.seconds) ? cronObject.seconds : 0
	);

	useEffect(() => {
		onCronObjectChange({
			seconds,
			minutes: { every: minutes, start: 0 },
			hours: '*',
			dayOfWeek: '*',
			dayOfMonth: '*',
			month: '*',
			year: '*',
		});
	}, [onCronObjectChange, minutes, seconds]);

	return (
		<Flex mt="2" alignItems="center">
			<Text mr="1">Every</Text>
			<NumericPeriodValuesSelector
				selected={minutes}
				onSelect={setMinutes}
				selectionRange={{
					from: 1,
					to: 60,
				}}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
			/>
			<Text mx="1">minute(s) and </Text>
			<NumericPeriodValuesSelector
				selected={seconds}
				onSelect={setSeconds}
				selectionRange={{
					from: 0,
					to: 60,
				}}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
			/>
			<Text ml="1">second(s)</Text>
		</Flex>
	);
};

export default React.memo(EveryMinutesSelector);
