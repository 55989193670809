import React from 'react';

import { Box, useColorMode, IconButton, DarkMode } from '@chakra-ui/react';
import { AddIcon, SmallCloseIcon } from '@chakra-ui/icons';

//Redux
import { connect } from 'react-redux';
import { setNavRoute } from '../../redux/actions';

const AddToLibraryButton = ({ mode, setMode }) => {
	const { colorMode } = useColorMode();

	const fontColor = { light: '#555555', dark: 'white' };
	const plusColor = { light: 'purple.500', dark: 'white' };
	const hoverColor = {
		light: 'dashboard.bg_light.100',
		dark: '#434343',
	};
	const borderColor = {
		light: 'dashboard.border_light',
		dark: 'dashboard.border_dark',
	};
	return (
		<Box
			height="100px"
			bg={mode === 'add' ? 'purple.500' : 'none'}
			display="flex"
			alignItems="center"
			borderRadius="0.2rem"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			mb="0.5rem"
			mt="0.5rem"
			border="solid 1px"
			borderColor={borderColor[colorMode]}
			_hover={{
				bg: mode === 'add' ? 'purple.500' : hoverColor[colorMode],
				borderColor:
					mode === 'add' ? 'transparent' : borderColor[colorMode],
			}}
			paddingY="30px"
			onClick={
				mode === 'add'
					? () => {
							setMode('list');
					  }
					: () => {
							// TODO : sibeshkar mentioned here

							setMode('add');
					  }
			}
		>
			<Box
				ml="1rem"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Box display="flex" alignItems="center">
					<Box
						w="40px"
						h="40px"
						border="solid 1px"
						borderColor={mode === 'add' ? 'white' : plusColor[colorMode]}
						borderRadius="60px"
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<AddIcon
							boxSize="0.7rem"
							color={mode === 'add' ? 'white' : plusColor[colorMode]}
						/>
					</Box>
					<Box ml="1rem">
						<Box
							fontFamily="body"
							fontSize="19px"
							color={mode === 'add' ? 'white' : fontColor[colorMode]}
						>
							Add To Library
						</Box>
						<Box
							fontFamily="body"
							fontSize="14px"
							color={mode === 'add' ? 'white' : fontColor[colorMode]}
						>
							Save Maya skill workflows from this workspace to use them
							later.
						</Box>
					</Box>
				</Box>

				{mode === 'add' ? (
					<DarkMode>
						<IconButton
							aria-label="cancel"
							icon={<SmallCloseIcon color="white" />}
							mr="1rem"
							size="sm"
							borderRadius="5px"
							onClick={() => {
								setMode('list');
							}}
						/>
					</DarkMode>
				) : null}
			</Box>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { pageMode } = state.navigation;
	return { pageMode };
};

export default connect(mapStateToProps, { setNavRoute })(AddToLibraryButton);
