import React, { useEffect, useState } from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import { motion } from 'framer-motion';

/**
 *
 * @param {{
 * vibrate?: boolean,
 * vibrateDuration?: number,
 * vibrateWidth?: number,
 * children: JSX.Element,
 * }} param0
 * @returns
 */
const InstructionSection = ({
	vibrate,
	vibrateDuration = 0.2,
	vibrateWidth = 2,
	children,
}) => {
	const { colorMode } = useColorMode();
	// const [animate, setAnimate] = useState(false);
	const bgColorSelected = {
		light: 'card_ui.focus_light',
		dark: '#3b3b3b',
	};

	const borderColor = {
		light: '#f9f9f9',
		dark: '#3E3E3E',
	};

	return (
		<Box
			paddingY="0.4rem"
			display="flex"
			alignItems="center"
			justifyContent="center"
			pl={5}
			borderTop={'solid 1px'}
			bg={bgColorSelected[colorMode]}
			borderBottomRadius="0.5rem"
			borderColor={borderColor[colorMode]}
			userSelect="none"
			// onClick={() => {
			// 	setAnimate(true);
			// 	setTimeout(() => setAnimate(false), 500);
			// }}
		>
			<motion.div
				animate={vibrate ? 'vibrate' : 'steady'}
				variants={{
					vibrate: {
						x: [-vibrateWidth, vibrateWidth, -vibrateWidth],
						transition: { repeat: Infinity, duration: vibrateDuration },
					},
					steady: {
						x: 0,
						transition: { repeat: Infinity, repeatDelay: Infinity },
					},
				}}
				initial={true}
			>
				<Box>{children}</Box>
			</motion.div>
		</Box>
	);
};

export default InstructionSection;
