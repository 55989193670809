import React, { useState } from 'react';

import { useLocation } from 'react-router-dom';
// API
import APIFetch from '../../../util/APIFetch';
import RegisterAPI from './RegisterAPI';
import { Link } from '@chakra-ui/react';
// Config
import { config } from '../../../config';

//History
import History, { HistoryWithSlug } from '../../../util/History/';

//Utils
import validate_password from '../../../util/Validate/validate_password';
import validate_email from '../../../util/Validate/validate_email';

import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	useToast,
	useColorMode,
} from '@chakra-ui/react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';

const PasswordInput = ({
	name,
	placeholder,
	onChange,
	onKeyDown,
	invalid,
	disabled,
}) => {
	const [show, setShow] = React.useState(false);
	const borderColor = { light: '#f2f2f2', dark: '#494949' };
	const inputBgColor = { light: 'white', dark: '#4c4c4c' };
	const inputFontColor = { light: 'gray.800', dark: 'white' };
	const { colorMode } = useColorMode();
	const handleClick = () => setShow(!show);

	return (
		<InputGroup size="md" fontFamily="body">
			<Input
				name={name}
				type={show ? 'text' : 'password'}
				placeholder={placeholder || 'Enter password'}
				onChange={onChange}
				borderColor={invalid ? 'red.300' : borderColor[colorMode]}
				focusBorderColor={invalid ? 'red.300' : 'purple.500'}
				_placeholder={{ color: inputFontColor[colorMode] }}
				color={inputFontColor[colorMode]}
				fontSize="0.8rem"
				bg={inputBgColor[colorMode]}
				size="md"
				borderRadius="5px"
				onKeyDown={onKeyDown}
				isDisabled={disabled}
			/>
			<InputRightElement width="3rem">
				<Box
					as={show ? MdVisibilityOff : MdVisibility}
					size="32px"
					color="gray.300"
					onClick={handleClick}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

export const RegisterCard = () => {
	const [formData, setFormData] = useState({
		inviteCode: { value: '' },
		firstName: { value: '' },
		email: { value: '' },
	});
	const [isRegistering, setRegistering] = React.useState(false);
	const [pwd, setPwd] = React.useState('');
	const [confirmPwd, setConfirmPwd] = React.useState('');
	const [pwdErr, setPwdErr] = React.useState(false);
	const [emailErr, setEmailErr] = React.useState(false);
	const [match, setMatch] = React.useState(false);

	const toast = useToast();
	// const [user, setUser] = useState("");
	// const [password, setPw] = useState("");
	// Setup the API Fetch utility for the Register View.
	const [{ fetchResults }] = APIFetch({
		locale: 'en',
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: RegisterAPI.apiService.postRegister.PATH_SEARCH,
		PATH_METHOD: RegisterAPI.apiService.postRegister.PATH_METHOD,
		formData,
	});

	React.useEffect(() => {
		if (pwd && confirmPwd) {
			if (pwd === confirmPwd) {
				setMatch(true);
			} else {
				setMatch(false);
			}
		} else {
			setMatch(false);
		}
	}, [pwd, confirmPwd]);

	const handleInputChange = ({ target }) => {
		// Get the right value for the type of input.
		const value = target.value;

		// Update state with new value for the input.
		setFormData((prevState) => {
			return {
				...prevState,
				[target.name]: {
					...formData[target.name],
					value,
				},
			};
		});
	};

	const onSubmit = async (e) => {
		// console.log(formData);
		setRegistering(true);
		try {
			const response = await fetchResults(formData);
			console.log('Registration response', response);
			if (response.status !== 200) {
				toast({
					title: 'Account creation failed!',
					description:
						'The account already exists or some error occured signing user up',
					position: 'bottom',
					status: 'error',
					duration: 3000,
					isClosable: false,
				});
				setRegistering(false);
			} else {
				toast({
					title: 'Account Created!',
					description: 'You can now login to Maya.',
					position: 'bottom',
					status: 'success',
					duration: 3000,
					isClosable: false,
				});
				setRegistering(false);
				History.push('/login');
			}
			// console.log(response);
		} catch (err) {
			toast({
				title: 'Account creation failed!',
				description:
					'The account already exists or some error occured signing user up',
				position: 'bottom',
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
			setRegistering(false);
		}
	};
	let query = new URLSearchParams(useLocation().search);
	React.useEffect(() => {
		const inviteCode = query.get('inviteCode');
		const emailId = query.get('emailId');
		const firstName = query.get('firstName');
		if (inviteCode) {
			setFormData((prevState) => {
				return {
					...prevState,
					inviteCode: {
						value: inviteCode,
					},
				};
			});
		}
		if (emailId) {
			setFormData((prevState) => {
				return {
					...prevState,
					email: {
						value: emailId,
					},
				};
			});
		}
		if (firstName) {
			setFormData((prevState) => {
				return {
					...prevState,
					firstName: {
						value: firstName,
					},
				};
			});
		}
	}, [setFormData]);

	const { colorMode } = useColorMode();

	const fontColor = { light: 'maya_light.400', dark: 'white' };
	const bgColor = { light: '#f9f9f9', dark: '#424242' };
	const inputBgColor = { light: 'white', dark: '#4c4c4c' };
	const inputFontColor = { light: 'gray.800', dark: 'white' };
	const borderColor = { light: '#f2f2f2', dark: '#494949' };
	return (
		<Box
			paddingTop="1rem"
			paddingBottom="1.5rem"
			paddingX="1.2rem"
			display="flex"
			flexDirection="row"
			justifyContent="center"
			flexGrow="0"
			flexShrink="0"
			bg={bgColor[colorMode]}
			fontFamily="body"
			fontWeight="600"
			borderRadius="5px"
			border="1px solid"
			borderColor={borderColor[colorMode]}
			zIndex="50"
		>
			<Stack>
				<Box
					color={fontColor[colorMode]}
					fontSize="1.3rem"
					marginBottom="1rem"
				>
					Sign up today for free.
				</Box>
				<InputGroup
					size="md"
					style={{ marginBottom: '0rem', marginTop: '0.5rem' }}
				>
					<Input
						name="inviteCode"
						placeholder="Enter Invite Code"
						onChange={handleInputChange}
						focusBorderColor="purple.500"
						defaultValue={formData.inviteCode.value}
						borderColor={borderColor[colorMode]}
						_placeholder={{ color: inputFontColor[colorMode] }}
						bg={inputBgColor[colorMode]}
						color={inputFontColor[colorMode]}
						size="md"
						width="14rem"
						fontSize="0.8rem"
						colorScheme="purple"
						borderRadius="5px"
					/>
				</InputGroup>
				<InputGroup size="md" style={{ marginBottom: '0rem' }}>
					<Input
						name="firstName"
						placeholder="Enter first name"
						onChange={handleInputChange}
						focusBorderColor="purple.500"
						defaultValue={formData.firstName.value}
						borderColor={borderColor[colorMode]}
						_placeholder={{ color: inputFontColor[colorMode] }}
						bg={inputBgColor[colorMode]}
						color={inputFontColor[colorMode]}
						size="md"
						width="14rem"
						fontSize="0.8rem"
						colorScheme="purple"
						borderRadius="5px"
					/>
				</InputGroup>
				<InputGroup size="md" style={{ marginBottom: '0rem' }}>
					<Input
						name="email"
						placeholder="Enter your email"
						onChange={(e) => {
							if (validate_email(e.target.value)) {
								setEmailErr(false);
							} else if (e.target.value) {
								setEmailErr(true);
							} else {
								setEmailErr(false);
							}
							handleInputChange(e);
						}}
						defaultValue={formData.email.value}
						_placeholder={{ color: inputFontColor[colorMode] }}
						borderColor={emailErr ? 'red.300' : borderColor[colorMode]}
						focusBorderColor={emailErr ? 'red.300' : 'purple.500'}
						bg={inputBgColor[colorMode]}
						color={inputFontColor[colorMode]}
						size="md"
						width="14rem"
						fontSize="0.8rem"
						colorScheme="purple"
						borderRadius="5px"
					/>
				</InputGroup>

				<PasswordInput
					name="password"
					placeholder="Enter password"
					invalid={pwdErr}
					onChange={(e) => {
						setPwd(e.target.value);

						if (validate_password(e.target.value)) {
							setPwdErr(false);
						} else if (e.target.value) {
							setPwdErr(true);
						} else {
							setPwdErr(false);
						}
						handleInputChange(e);
					}}
				/>
				<PasswordInput
					name="password"
					placeholder="Confirm password"
					onChange={(e) => {
						setConfirmPwd(e.target.value);

						handleInputChange(e);
					}}
					invalid={confirmPwd && !match}
					disabled={pwdErr}
				/>

				<Button
					size="md"
					marginTop="0.5rem"
					onClick={onSubmit}
					isLoading={isRegistering}
					cursor
					fontSize="0.8rem"
					bg={'purple.500'}
					color="white"
					isDisabled={!match}
				>
					Sign up
				</Button>

				<Box
					marginTop="0.8rem"
					fontSize="0.6rem"
					fontWeight="400"
					textAlign="center"
					opacity="0.9"
				>
					By clicking Sign Up, you agree to our <br />{' '}
					<Link
						href="https://mayahq.com/terms-of-use/"
						target="_blank"
						rel="noopener noreferrer"
						color="purple.300"
					>
						Terms of Use
					</Link>{' '}
					and{' '}
					<Link
						href="https://mayahq.com/privacy/"
						target="_blank"
						rel="noopener noreferrer"
						color="purple.300"
					>
						Privacy Policy
					</Link>
					.
				</Box>
				<Box
					height="20px"
					width="14rem"
					fontSize="0.55rem"
					fontWeight="400"
					fontFamily="body"
					textAlign="center"
				>
					{pwdErr
						? 'Must have at least 1 special character, 1 capital letter, 1 number, and be at least 8 characters long.'
						: null}
				</Box>
			</Stack>
		</Box>
	);
};

export default RegisterCard;

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
