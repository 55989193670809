import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import _ from 'lodash';

import isElectron from 'is-electron';

import { store } from '../../redux/store';

/**
 * Function to add or update library collection from brain runtime
 */
const checkUpdates = async ({ slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	//console.log("profileSlug", profileSlug);
	if (isElectron()) {
		// get brains data
		const getBrainsForUpdatesIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getBrainsForUpdates.EVENT,
		});
		const getBrainsForUpdatesResponse = await getBrainsForUpdatesIPCFn({
			profileSlug,
		});

		// check for updates
		const checkUpdatesAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.checkUpdates.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.checkUpdates.PATH_METHOD,
		});
		const updates = await checkUpdatesAPIFn(
			getBrainsForUpdatesResponse['data']['results']
		);
		// if (updates.status === 401) {
		// 	return updates;
		// }
		// set updates to lowdb
		const setUpdatesIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.setUpdates.EVENT,
		});

		const setUpdatesResponse = await setUpdatesIPCFn(updates.data);

		return updates;
	} else {
		// TODO
		// backend API call to get brain data
		// check updates
		// set updates info
		const checkUpdatesAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.checkUpdates.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.checkUpdates.PATH_METHOD,
		});
		const updates = await checkUpdatesAPIFn({});
		return {
			data: {
				error: true,
			},
		};
	}
};

export default checkUpdates;
