import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';
// config
import { config } from '../../config';

import { store } from '../../redux/store';
/**
 * Function to add or update library collection from brain runtime
 */
const updateLibrarySkillPack = async (formData) => {
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;

	if (isElectron()) {
		var requestData = {
			profileSlug: profileSlug,
			flow: {
				flows: formData.brain.flows,
				nodes: formData.brain.nodes,
				thumbnail:
					formData.brain.thumbnail ||
					'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg',
				published: false,
			},
			skillPack: {
				_id: formData.skillPackId,
				name: formData.name,
				description: formData.description,
				version: formData.version,
				thumbnail:
					formData.brain.thumbnail ||
					'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg',
			},
			installedModules: formData.brain.modules,
			version: formData.version,
			published: false,
		};

		// TODO: check for electron
		const updateLibrarySkillPackAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.updateLibrarySkillPack.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.updateLibrarySkillPack.PATH_METHOD,
		});

		const updateLibrarySkillPackResponse = await updateLibrarySkillPackAPIFn(
			requestData
		);

		const updateSkillPackVersionIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.updateSkillPackVersion.EVENT,
		});
		const updateSkillPackVersionResponse = await updateSkillPackVersionIPCFn({
			version: formData.version,
			skillPackId: formData.skillPackId,
			brainId: formData.brain._id,
			profileSlug: profileSlug,
		});

		return { updateLibrarySkillPackResponse, updateSkillPackVersionResponse };
	}
	// if (addToLibraryResponse["data"]["error"]) {
	//   return addToLibraryResponse["data"];
	// } else {
	//   return addToLibraryResponse["data"]["results"];
	// }
};

export default updateLibrarySkillPack;
