import React from 'react';
import { Box, SlideFade, Tooltip } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import truncate from 'lodash/truncate';
import { updateCommandBarStatus } from '../../../../../redux/actions/cardui';

/**
 *
 * @param {{
 * visible: boolean,
 * status: 'loading' | 'success' | 'error',
 * message: string,
 * onClose: () => void,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const CommandBarStatus = ({ visible, status, message, onClose, ...rest }) => {
	console.log('status update: ', status, message);
	return (
		<Box {...rest}>
			<SlideFade in={visible} offsetY="20px">
				<Box
					bg="card_ui.bg_dark"
					height="2rem"
					roundedTop="0.25rem"
					paddingY="6px"
					paddingX="3px"
					roundedBottom="0.25rem"
					alignItems="center"
					justifyContent="center"
					paddingLeft="10px"
					boxShadow={'1px 5px 20px rgba(0, 0, 0, 0.2)'}
					display="inline-block"
					whiteSpace="nowrap"
					transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				>
					<Box display="flex" alignItems="center">
						<Box
							width="14px"
							height="9px"
							bg={
								status === 'loading'
									? 'orange.300'
									: status === 'error'
									? 'red.500'
									: 'green.300'
							}
							borderRadius="5rem"
						/>
						<Box
							marginLeft="7px"
							fontFamily="body"
							fontSize="14px"
							width="100%"
						>
							<Tooltip label={message} aria-label="feedback-message">
								{truncate(message, {
									length: 80,
									separator: ' ',
								})}
							</Tooltip>
						</Box>
						<SmallCloseIcon
							marginLeft="8px"
							marginRight="5px"
							cursor="pointer"
							onClick={onClose}
						/>
					</Box>
				</Box>
			</SlideFade>
		</Box>
	);
};

// const mapStateToProps = (state, ownProps) => {
// 	const { commandBarStatus } = state.cardUI;
// 	const { visible, status, message } = commandBarStatus;
// 	return { visible, status, message };
// };

export default CommandBarStatus;
