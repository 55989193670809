import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import _ from 'lodash';

import isElectron from 'is-electron';

import { store } from '../../redux/store';

/**
 * Function to add or update library collection from brain runtime
 */
const getUpdates = async () => {
	if (isElectron()) {
		// get brains data
		const getUpdatesIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getUpdates.EVENT,
		});
		const getUpdatesResponse = await getUpdatesIPCFn();
		// console.log('HELLO', getUpdatesResponse);

		return {
			data: {
				results: getUpdatesResponse,
				error: false,
			},
		};
	} else {
		// TODO
		return {
			data: {
				error: true,
			},
		};
	}
};

export default getUpdates;
