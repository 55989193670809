import { Button } from '@chakra-ui/button';
import { LightMode } from '@chakra-ui/color-mode';
import { Flex, Spacer, Stack } from '@chakra-ui/layout';

/**
 *
 * @param {{
 * onCancel: () => void,
 *	onContinue: () => void,
 *	cancelBtnProps: import("@chakra-ui/button").ButtonProps,
 *	continueBtnProps: import("@chakra-ui/button").ButtonProps,
 *	containerProps: import("@chakra-ui/layout").FlexProps,
 * }} param0
 * @returns
 */
const StepperFooter = ({
	onCancel,
	onContinue,
	cancelBtnProps,
	continueBtnProps,
	containerProps,
}) => {
	return (
		<Flex
			py="3"
			px="4"
			bg="#363636"
			borderBottomRadius="xl"
			{...containerProps}
		>
			<Spacer />
			<Stack direction="row" spacing="3">
				<Button
					borderRadius="0.25rem"
					size="sm"
					minWidth="28"
					onClick={onCancel}
					{...cancelBtnProps}
				>
					Cancel
				</Button>
				<LightMode>
					<Button
						size="sm"
						borderRadius="0.25rem"
						colorScheme="purple"
						minWidth="28"
						onClick={onContinue}
						{...continueBtnProps}
					>
						Continue
					</Button>
				</LightMode>
			</Stack>
		</Flex>
	);
};

export default StepperFooter;
