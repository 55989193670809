// useDebounce.js
import { useState, useEffect } from 'react';

/**
 * Custom hook for debounced input capture
 * - `value`: inputValue state
 * - `delay`: debounce delay in `ms`
 */
const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

export default useDebounce;
