import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Box, useDisclosure } from '@chakra-ui/react';
import isElectron from 'is-electron';
import { connect, useDispatch } from 'react-redux';

//Components
import TabSection from './Electron/Tabs';
import Dashboard from './Dashboard';
import SkillPage from './Skills/SkillPage';
import LoginCard from './LandingPage/Login';
import ResetCard from './LandingPage/Reset';
import ForgotCard from './LandingPage/Forgot';
import RegisterCard from './LandingPage/Register';
import VerifyCard from './LandingPage/Verify';
import LandingPage from './LandingPage';
import NewTeam from './Onboarding/NewTeam';
import StoreSection from './Store';
import EditorPage from './Editor';
import LibrarySection from './Library';
import SettingsSection from './Settings';
import HomeSection from './Home';
import UpdateSection from './Updates';
import PublishSection from './Publish';
import RedDashboardSection from './Editor/RedDashboard';
import { Intercom } from '../util/SupportWidget';
import {
	closeTab,
	setTrialExpiryModalDisplay,
	supportWidgetSetOpen,
	supportWidgetSetWidgetOpen,
} from '../redux/actions';
import OfflineRibbon from './Offline';

//Utils
import History, { HistoryWithSlug } from '../util/History';
import useOnlineStatus from '../util/useOnlineStatus';

import NewCardUI from './Commands/NewCardUI';
import { store } from '../redux/store';
import { updateBrainById } from '../redux/actions';
// import getDecodedAccessToken from '../functions/configuration/getDecodedAccessToken';
// import { TrialExpiredModal } from './Payments/TrialExpiredModal';
import { getTimeToExpiry } from '../util/Tier/getTimeRemaining';
import stopBrain from '../functions/brain/stopBrain';
import GetProfileSlug from '../util/ProfileSlug';
import getLocale from '../functions/settings/getLocale/getLocale';

const EXPIRY_CHECK_INTERVAL = 30 * 60 * 1000; // 30 minutes

export const SectionList = {
	home: (currentBrainId) => <HomeSection currentBrainId={currentBrainId} />,
	storeskills: (currentBrainId) => (
		<StoreSection storeType="Collections" currentBrainId={currentBrainId} />
	),
	storemodules: (currentBrainId) => (
		<StoreSection storeType="Modules" currentBrainId={currentBrainId} />
	),
	storecommands: (currentBrainId) => (
		<StoreSection storeType="Commands" currentBrainId={currentBrainId} />
	),
	skills: (currentBrainId) => (
		<SkillPage initMode={'list'} currentBrainId={currentBrainId} />
	),
	skillsnew: (currentBrainId) => (
		<SkillPage initMode={'new'} currentBrainId={currentBrainId} />
	),
	library: (currentBrainId) => (
		<LibrarySection currentBrainId={currentBrainId} />
	),
	settings: (currentBrainId) => (
		<SettingsSection currentBrainId={currentBrainId} />
	),
	updates: (currentBrainId) => (
		<UpdateSection currentBrainId={currentBrainId} />
	),
	publish: (currentBrainId) => (
		<PublishSection currentBrainId={currentBrainId} />
	),
	dashboard: (currentBrainId) => (
		<RedDashboardSection currentBrainId={currentBrainId} />
	),
};

const Main = ({ user, brains, setTrialExpiryModalDisplay, closeTab }) => {
	const [tabs, setTabs] = React.useState(true);
	const [trialExpiryModalShown, setTrialExpiryModalShown] =
		React.useState(false);

	const onlineStatus = useOnlineStatus();

	function onTrialExpired() {
		const { slug } = GetProfileSlug();
		if (!trialExpiryModalShown) {
			setTrialExpiryModalDisplay(true);
			setTrialExpiryModalShown(true);
		}
		brains.allIds.forEach((id) => {
			console.log('dat stopping brain:', id);
			stopBrain({
				brain: brains.byId[id],
				slug,
				onDone: () => console.log(`Stopped brain ${id} on trial expiry`),
				onError: () =>
					console.log(`Failed to stop brain ${id} on trial expiry`),
			});

			try {
				closeTab(id);
			} catch (e) {
				console.log('Unable to close tab for brain:', id, e);
			}
		});
	}

	function checkTrialExpiry() {
		getTimeToExpiry()
			.then((timeRemaining) => {
				if (timeRemaining <= 0) {
					onTrialExpired();
				}
			})
			.catch((e) => {
				console.log('Tiers: Unable to get time to expiry:', e);
			});
	}

	// Listening for changes in brain status from the main thread
	// This is especially important for listening to brain crash events
	React.useEffect(() => {
		// Can't do this for web
		if (!isElectron()) {
			return;
		}

		const electron = window.require('electron');
		const { ipcRenderer } = electron;

		ipcRenderer.removeAllListeners('/brain/status/update');
		ipcRenderer.on('/brain/status/update', (event, arg) => {
			const data = JSON.parse(arg);
			const { brainId, status } = data;
			store.dispatch(updateBrainById(brainId, { status }));
		});

		// Check if trial period has expired every 30 minutes, starting now.
		// This is cheap
		checkTrialExpiry();
		setInterval(checkTrialExpiry, EXPIRY_CHECK_INTERVAL);

		// getLocale()
		// 	.then(res => console.log('locale', res))
		// 	.catch(e => console.log('locale', e))
	}, []);

	React.useEffect(() => {
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer, Notification } = electron;
			ipcRenderer.removeAllListeners('/overlay/cli');
			ipcRenderer.on('/overlay/cli', (event, arg) => {
				console.log('/overlay/cli');
				setTabs(false);
				History.push('/cli');
			});
			//Send redirect history push messages from electron
			ipcRenderer.removeAllListeners('/history/go');
			ipcRenderer.on('/history/go', (event, arg) => {
				console.log('/history/go');
				const { route } = arg;
				if (route[0] === '/') {
					HistoryWithSlug.push(route);
				} else {
					HistoryWithSlug.push('/' + route);
				}
			});
			ipcRenderer.removeAllListeners('/notification/send');
			ipcRenderer.on('/notification/send', (event, arg) => {
				console.log('/notification/send');
				const { title, message } = arg;
				new Notification({
					title: title,
					body: message,
				}).show();
			});
		}
	});

	let location = useLocation();
	React.useEffect(() => {
		if (location.pathname === '/cli') {
			setTabs(false);
		} else {
			setSupportWidget(true);
		}
	}, [location]);

	console.log('ONLINE STATUS:', onlineStatus);

	const [supportWidget, setSupportWidget] = React.useState(false);
	const dispatch = useDispatch();
	/** Use this to store current location, that would be used to restore after login */
	const [currentPathWithoutDomain, setCurrentPathWithoutDomain] = useState(
		`${History.location.pathname}${History.location.search}${History.location.hash}`
	);

	return (
		<Box
			height="100vh"
			display="flex"
			flexDirection="column"
			overflow="hidden"
		>
			{tabs ? <TabSection /> : null}
			{onlineStatus ? null : tabs ? <OfflineRibbon /> : null}
			{supportWidget ? (
				<Intercom
					onOpen={() => {
						dispatch(supportWidgetSetOpen(true));
					}}
					onClose={() => {
						dispatch(supportWidgetSetOpen(false));
					}}
					onWidgetOpen={() => {
						dispatch(supportWidgetSetWidgetOpen(true));
					}}
					onWidgetClose={() => {
						dispatch(supportWidgetSetWidgetOpen(false));
					}}
					visible={supportWidget}
				/>
			) : null}

			{/* <TrialExpiredModal
				isCentered
				isOpen={isOpen}
				onClose={onClose}
			/> */}

			<Switch>
				<Route
					exact
					path="/"
					children={
						user.status !== 'offline' ? (
							<Redirect to="/home" />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route path="/edit" children={<EditorPage />} />
				<Route path="/:slug/edit" children={<EditorPage />} />
				<Route
					exact
					path="/home"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['home']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/:slug/home"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['home']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>

				<Route path="/onboarding" children={<NewTeam />} />
				<Route
					path="/login"
					children={<LandingPage children={<LoginCard />} />}
				/>
				<Route
					path="/reset"
					children={<LandingPage children={<ResetCard />} />}
				/>
				<Route
					path="/forgot"
					children={<LandingPage children={<ForgotCard />} />}
				/>
				<Route
					path="/register"
					children={<LandingPage children={<RegisterCard />} />}
				/>
				<Route
					path="/verify"
					children={<LandingPage children={<VerifyCard />} />}
				/>
				<Route
					exact
					path="/store/skills"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['storeskills']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/store/modules"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['storemodules']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/store/commands"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['storecommands']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/skills"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['skills']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/:slug/skills"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['skills']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					path="/skills/new"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['skillsnew']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					path="/:slug/skills/new"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['skillsnew']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/library"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['library']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/:slug/library"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['library']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/settings"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['settings']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/updates"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['updates']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/:slug/updates"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['updates']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/:slug/settings"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['settings']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/publish"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['publish']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/:slug/publish"
					children={
						user.status !== 'offline' ? (
							<Dashboard children={SectionList['publish']()} />
						) : (
							<Redirect
								to={{
									pathname: '/login',
									state: {
										restoreLocation: currentPathWithoutDomain,
									},
								}}
							/>
						)
					}
				/>
				<Route
					exact
					path="/store"
					children={<Redirect to="/store/commands" />}
				/>
				<Route path="/cli" children={<NewCardUI />} />
			</Switch>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { user, brains } = state;
	console.log('dat state', state);
	return { user, brains };
};

export default connect(mapStateToProps, {
	setTrialExpiryModalDisplay,
	closeTab,
})(Main);
