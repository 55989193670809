import React from 'react';
import {
	Box,
	Image,
	Stack,
	useColorMode,
	useDisclosure,
	useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { motion } from 'framer-motion';
import { StarIcon } from '@chakra-ui/icons';
import _ from 'lodash';

import { GoVerified } from 'react-icons/go';
import { RiAlertFill } from 'react-icons/ri';
import IconContainer from '../../util/React_Icon';
import {
	FiPackage,
	FiCheckCircle,
	FiGitPullRequest,
	FiDownload,
	FiGitBranch,
} from 'react-icons/fi';

//Components
import HorizontalItem from '../Reusable/HorizontalItem';
import {
	InstallButton,
	UninstallButton,
	ConfigureModuleButton,
} from './ModuleItemButtons';
import TagWithIcon from '../Reusable/TagWithIcon';
import ModuleInstallModal from './ModuleInstallModal';
import { HorizontalCard } from '../Reusable/HorizontalCard';
/**
 * ModuleItemLeft is used to display the module information you see on the left
 * of the card. It's a separate component because we want to reuse it in other places.
 * Find at Store > Modules
 *
 * @param {object} module                 The module info to be displayed
 * @param {object} currentVersionDetails  Contains information about currently installed
 *                                        version (version, number of nodes, etc)
 * @param {function} onClick              Called when the user clicks on the module card
 */
export const ModuleItemLeft = ({
	module,
	currentVersionDetails,
	onClick,
	isInstalled,
	isInstalledGlobal,
}) => {
	const { colorMode } = useColorMode();
	console.log('currentVersionDetails', currentVersionDetails);
	return (
		<Box display="flex" alignItems="center" onClick={onClick} height="100%">
			<Box
				height="100%"
				display="flex"
				alignItems="center"
				paddingX="5px"
				width="140px"
				justifyContent="center"
			>
				<Image
					src={module.thumbnail}
					objectFit="cover"
					borderRadius="5px"
				/>
			</Box>
			<Box>
				<Box
					color="#555555"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						fontSize="20px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
					>
						{module.name}
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Box
						fontSize="17px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
						opacity="0.7"
					>
						{_.truncate(module.description, {
							length: 90,
							separator: /,? +/,
						})}
					</Box>
				</Box>
				<Box
					color="#555555"
					// fontSize="1rem"
					display="flex"
					flexDirection="row"
					alignItems="center"
					mt="8px"
				>
					<TagWithIcon text={'Maya Official'} icon={<GoVerified />} />
					{process.env.NODE_ENV === 'development' &&
					currentVersionDetails ? (
						<TagWithIcon
							text={currentVersionDetails.version}
							icon={<FiGitPullRequest />}
						/>
					) : null}
					<TagWithIcon
						text={
							currentVersionDetails
								? `${currentVersionDetails.nodes.length} nodes`
								: `0 nodes`
						}
						icon={<FiPackage />}
					/>
					{/* <TagWithIcon
						text={`${module.totalInstalls} installs`}
						icon={<FiDownload />}
					/> */}
					{isInstalled ? (
						<TagWithIcon text="Added" icon={<FiCheckCircle />} />
					) : null}
					{isInstalledGlobal ? (
						<TagWithIcon text={'installed'} icon={<FiDownload />} />
					) : null}
				</Box>
			</Box>
		</Box>
	);
};

const SelectedConfigProfile = ({ name }) => {
	const { colorMode } = useColorMode();
	const color = { light: '#6C6C6C', dark: 'white' };
	return (
		<Box textAlign="right" height="55px" mr="3">
			<Box fontWeight="600">
				{name ? 'Selected Config' : 'No Config Selected'}
			</Box>
			{name ? (
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					<IconContainer
						icon={<GoVerified />}
						value={{
							color: color[colorMode],
							className: 'global-class-name',
							size: '0.7rem',
						}}
					/>
					<Box fontSize="16px" ml="1" color={color[colorMode]}>
						{name}
					</Box>
				</Box>
			) : (
				<Box display="flex" alignItems="center" justifyContent="flex-end">
					<IconContainer
						icon={<RiAlertFill />}
						value={{
							color: color[colorMode],
							className: 'global-class-name',
							size: '0.8rem',
						}}
					/>
					<Box
						fontSize="16px"
						ml="1"
						opacity="0.8"
						mb="1"
						color={color[colorMode]}
					>
						none
					</Box>
				</Box>
			)}
		</Box>
	);
};

/**
 * ModuleItem renders the module cards.
 * Find at Store > Modules
 */
export const ModuleItem = ({
	module,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	profilesButton,
	addButton,
	setModuleList,
	moduleList,
	isModuleLoading,
	isInstalled,
	isInstalledGlobal,
	updateBrainById,
	version,
}) => {
	const [hoveredButton, setHoveredButton] = useState('');
	const [isOpen, setIsOpen] = useState(false);
	const [currentVersion, setCurrentVersion] = useState(
		version ? version : module.currentVersion
	);
	const [currentVersionDetails, setCurrentVersionDetails] = React.useState();

	React.useEffect(() => {
		const currentVersionObject = _.find(
			module.versions,
			function (versionDetails) {
				return versionDetails.version === currentVersion;
			}
		);
		setCurrentVersionDetails(currentVersionObject);
	}, []);

	React.useEffect(() => {
		setIsOpen(isItemExpanded);
	}, [isItemExpanded]);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<HorizontalItem
			isOpen={isOpen}
			onClickItem={
				wholeItemActive
					? () => {
							toggleOpen();
							onClick(module._id);
					  }
					: () => onClick(module._id)
			}
			selected={selected}
			itemLeft={
				<ModuleItemLeft
					module={module}
					currentVersionDetails={currentVersionDetails}
					onClick={() => {
						toggleOpen();
						onClick(module._id);
					}}
					isInstalledGlobal={isInstalledGlobal}
					isInstalled={isInstalled}
				/>
			}
			buttons={
				<Box display="flex" flexDirection="row" alignItems="center">
					{moduleList &&
					moduleList.byId[module._id].configurationRequired ? (
						<ConfigureModuleButton
							module={module}
							currentBrainId={currentBrainId}
							currentVersionDetails={currentVersionDetails}
							setModuleList={setModuleList}
							moduleList={moduleList}
							isModuleLoading={isModuleLoading}
						/>
					) : null}
					{addButton ? (
						isInstalled ? (
							<UninstallButton
								onClick={() => {
									toggleOpen();
									onClick(module._id);
								}}
								module={module}
								setHoveredButton={setHoveredButton}
								hoveredButton={hoveredButton}
								currentBrainId={currentBrainId}
								currentVersionDetails={currentVersionDetails}
								updateBrainById={updateBrainById}
							/>
						) : (
							<InstallButton
								onClick={() => {
									toggleOpen();
									onClick(module._id);
								}}
								module={module}
								setHoveredButton={setHoveredButton}
								hoveredButton={hoveredButton}
								currentBrainId={currentBrainId}
								currentVersionDetails={currentVersionDetails}
								updateBrainById={updateBrainById}
								isInstalled={isInstalled}
							/>
						)
					) : null}
				</Box>
			}
			isItemExpanded={isItemExpanded}
			//expandedContent={<Box height="120px">Hello</Box>}
		/>
	);
};

/**
 * ModuleItemCard renders the module cards.
 * Find at Store > Modules
 */
export const ModuleItemCard = ({
	module,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	profilesButton,
	addButton,
	setModuleList,
	moduleList,
	isModuleLoading,
	isInstalled,
	isInstalledGlobal,
	updateBrainById,
	version,
	openModal,
}) => {
	console.log('moduleItemCard here', moduleList, module);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [currentVersion, setCurrentVersion] = useState(
		version ? version : module.currentVersion
	);
	const [currentVersionDetails, setCurrentVersionDetails] = React.useState(
		_.find(module.versions, function (versionDetails) {
			return versionDetails.version === currentVersion;
		})
	);

	React.useEffect(() => {
		if (openModal) {
			onOpen();
		}
	}, [openModal]);

	const tags = [];
	if (currentVersionDetails && currentVersionDetails?.nodes?.length) {
		const numNodes = currentVersionDetails?.nodes?.length;
		tags.push({
			text: `${numNodes} nodes`,
			icon: <FiGitBranch />,
		});
	} else {
		tags.push({
			text: `0 nodes`,
			icon: <FiGitBranch />,
		});
	}

	return (
		<Box>
			<HorizontalCard
				key={module._id}
				thumbnail={module.thumbnail}
				title={module.name}
				description={module.description}
				onClick={() => {
					onOpen();
				}}
				isActive={isInstalled}
				tags={tags}
			/>
			<ModuleInstallModal
				onClose={onClose}
				isOpen={isOpen}
				currentVersionDetails={currentVersionDetails}
				onClick={onClick}
				module={module}
				currentBrainId={currentBrainId}
			/>
		</Box>
	);
};
