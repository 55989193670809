import isElectron from 'is-electron';

import IPCFunction from '../../../util/IPCFetch/function';
import IPCRoutes from '../../../util/IPCRoutes';

const resetDefaults = async () => {
	console.log('changing status');
	if (isElectron()) {
		var resetDefaultsIPCFn, resetDefaultsIPCResponse;
		resetDefaultsIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.resetDefaults.EVENT,
		});
		resetDefaultsIPCResponse = await resetDefaultsIPCFn();
		return resetDefaultsIPCResponse;
	}
};

export default resetDefaults;
