/**
 * Brain Actions
 *
 * Action creators for Brain based actions.
 */

// Dependencies
// import { get } from "lodash";

// Action Types
import actionTypes from '../actionTypes';

/**
 * Add the Brain Object
 *
 * Attempt to set the robot object with list of fleets containing user.
 *
 * @param {Object} robots The robots list obtained from the backend for a given fleet.
 */
const addPublishedSkillPacks =
	(publishedSkillPacks, refresh) => (dispatch, getState) => {
		// Grab only the pertinent information to send to storage. We don't want everything.

		// Dispatch the result.
		dispatch({
			type: actionTypes.ADD_PUBLISHEDSKILLPACKS,
			payload: {
				info: publishedSkillPacks,
				refresh: refresh || false,
			},
		});
	};

/**
 * Update Brain
 *
 * @param {Object}
 */
const updatePublishedSkillPack =
	(publishedSkillPack) => (dispatch, getState) => {
		// Grab only the pertinent information to send to storage. We don't want everything.

		// Dispatch the result.
		dispatch({
			type: actionTypes.UPDATE_PUBLISHEDSKILLPACK,
			payload: {
				info: publishedSkillPack,
			},
		});
	};

/**
 * Update Brain
 *
 * @param {Object}
 */
const updatePublishedSkillPackById =
	(id, publishedSkillPack) => (dispatch, getState) => {
		// Grab only the pertinent information to send to storage. We don't want everything.

		// Dispatch the result.
		console.log('Readying dispatch', id, publishedSkillPack);
		dispatch({
			type: actionTypes.UPDATE_PUBLISHEDSKILLPACK_BY_ID,
			payload: {
				info: publishedSkillPack,
				id: id,
			},
		});
	};

/**
 * Delete a Brain Object
 *
 * Delete fleet robot given robotID.
 *
 * @param {Object} robotID The fleets list obtained from the backend.
 */
const removePublishedSkillPack =
	(publishedSkillPackID) => (dispatch, getState) => {
		// Grab only the pertinent information to send to storage. We don't want everything.

		// Dispatch the result.
		dispatch({
			type: actionTypes.REMOVE_PUBLISHEDSKILLPACK,
			payload: {
				info: publishedSkillPackID,
			},
		});
	};

/**
 * Clear Brains
 *
 * Clear brains.
 *
 */
const clearPublishedSkillPacks = () => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.CLEAR_PUBLISHEDSKILLPACKS,
		payload: {},
	});
};

// Export the actions.
export {
	addPublishedSkillPacks,
	removePublishedSkillPack,
	updatePublishedSkillPack,
	updatePublishedSkillPackById,
	clearPublishedSkillPacks,
};
