import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	useColorModeValue,
	Box,
	ModalFooter,
	Button,
	useToast,
	Alert,
	AlertIcon,
	AlertDescription,
	AlertTitle,
	CloseButton,
} from '@chakra-ui/react';
import isElectron from 'is-electron';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import Dropdown from '../Reusable/Dropdown';
import InputComponent from '../Reusable/Input';

//Functions
import createTeam from '../../functions/team/createTeam';
import checkSlug from '../../functions/team/checkSlug';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

export const CreateTeamSection = () => {
	const [workspaceName, setWorkspaceName] = React.useState('');

	const [teamSize, setTeamSize] = React.useState({});
	const [teamType, setTeamType] = React.useState({});
	const [slug, setSlug] = React.useState('');
	const [btnLoading, setBtnLoading] = React.useState(false);
	const [flags, setFlags] = React.useState({
		workspaceName: false,
		slug: false,
		email: false,
	});

	const [slugExists, setSlugExists] = React.useState(false);

	const toast = useToast();

	React.useEffect(() => {
		if (slug) {
			checkSlug({ slug }).then((res) => {
				console.log(res);
				//checks if slug exists, returns true if it does
				if (res) {
					setFlags({ ...flags, slug: true });
					setSlugExists(true);
				} else {
					setFlags({ ...flags, slug: false });
					setSlugExists(false);
				}
			});
		} else {
			setFlags({ ...flags, slug: false });
			setSlugExists(false);
		}
	}, [checkSlug, slug]);

	// Add hook for email validation

	const onSubmit = (e) => {
		if (workspaceName && slug) {
			setBtnLoading(true);

			setFlags({ workspaceName: false, slug: false, email: false });
			console.log(workspaceName, teamSize, teamType, slug);
			createTeam({ workspaceName, slug })
				.then((res) => {
					console.log(res);
					setBtnLoading(false);
					toast({
						title: 'Success!',
						description: 'The team has been successfully created',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				})
				.catch((err) => {
					toast({
						title: 'An error occurred.',
						description: 'The team could not be created.',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				});
		} else {
			if (slug === '' && workspaceName === '') {
				setFlags({ workspaceName: true, slug: true });
			} else if (slug === '' && workspaceName !== '') {
				setFlags({ ...flags, slug: true });
			} else if (slug !== '' && workspaceName === '') {
				setFlags({ ...flags, workspaceName: true });
			}
		}
	};
	return (
		<Box>
			<HeaderWithDescription
				header={'Workspace name'}
				desc={'The name of your company or organization.'}
				marginLeft="0.5rem"
				borderBottom="none"
				marginBottom="none"
			>
				<InputComponent
					width="20rem"
					placeholder="Icme, Inc."
					marginY="0.3rem"
					onChange={(e) => {
						setWorkspaceName(e.currentTarget.value);
						setFlags({ ...flags, workspaceName: false });
					}}
					isInvalid={flags.workspaceName}
				/>
			</HeaderWithDescription>

			<HeaderWithDescription
				header={'Company Size'}
				marginLeft="0.5rem"
				borderBottom="none"
				marginBottom="none"
			/>
			<Dropdown
				marginLeft="0.5rem"
				width="20rem"
				selectitem={(e) => setTeamSize(e)}
				items={[
					{
						label: '2-5 people',
						value: '2-5',
					},
					{
						label: '5-20 people',
						value: '5-20',
					},
					{
						label: '20-100 people',
						value: '20-100',
					},
					{
						label: '100+ people',
						value: '100+',
					},
				]}
			/>
			<HeaderWithDescription
				header={'Your starting team'}
				desc={''}
				marginLeft="0.5rem"
				borderBottom="none"
				marginBottom="none"
			>
				<Dropdown
					width="20rem"
					marginY="0.3rem"
					selectitem={(e) => setTeamType(e)}
					items={[
						{
							label: 'Design',
							value: 'design',
						},
						{
							label: 'Engineering',
							value: 'engineering',
						},
						{
							label: 'Human resources',
							value: 'hr',
						},
						{
							label: 'Marketing',
							value: 'marketing',
						},
						{
							label: 'Sales',
							value: 'sales',
						},
						{
							label: 'Support',
							value: 'support',
						},
					]}
				/>
			</HeaderWithDescription>

			<HeaderWithDescription
				header={'Domain'}
				desc={'Pick an unique domain to get your own special URL.'}
				marginLeft="0.5rem"
				marginBottom="none"
				borderBottom="none"
			>
				<Box display="flex" alignItems="center" marginY="0.3rem">
					<Box
						fontFamily="body"
						fontSize="1.2rem"
						fontWeight="600"
						color={useColorModeValue('#444444', 'white')}
					>
						app.mayahq.com /
					</Box>
					<InputComponent
						marginLeft="0.5rem"
						width="10rem"
						placeholder="URL"
						fontWeight="600"
						onChange={(e) => {
							setSlug(e.currentTarget.value);
							setFlags({ ...flags, slug: false });
						}}
						isInvalid={flags.slug}
					/>
				</Box>
			</HeaderWithDescription>

			<Button
				colorScheme="purple"
				bg={'purple.500'}
				color="white"
				size="sm"
				width="20rem"
				marginTop="10px"
				marginLeft="0.5rem"
				onClick={onSubmit}
				isLoading={btnLoading}
				loadingText="Creating..."
			>
				Continue
			</Button>
			<Box width="20rem" marginLeft="0.5rem" marginTop="0.5rem">
				{slugExists && (
					<Alert
						status="error"
						alignItems="center"
						size="sm"
						borderRadius="0.2rem"
						height="2.5rem"
						variant="left-accent"
					>
						<AlertIcon />
						<AlertTitle mr={2}>That slug already exists!</AlertTitle>
					</Alert>
				)}
			</Box>
		</Box>
	);
};

const CreateTeamModal = ({ isOpen, onOpen, onClose }) => {
	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="xl"
			fontFamily="body"
			isCentered={isElectron()}
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				height="31rem"
				maxWidth="22rem"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody paddingX="0">
					<HeaderWithDescription
						header={'New Team Workspace'}
						desc={'Fill some details here for your team mates.'}
						marginLeft="1rem"
						borderBottom="none"
					/>
					<Box height="19rem" paddingX="0.5rem">
						<CreateTeamSection />
					</Box>
				</ModalBody>
				<ModalFooter></ModalFooter>
			</ModalContent>
		</CustomModal>
	);
};

export default CreateTeamModal;
