/**
 * @typedef { 'free' | 'premium'} SubscriptionTier
 */

/**
 * @typedef {'id' | 'feature' | SubscriptionTier} TierTableColumns
 */

import { Icon } from '@chakra-ui/react';
import { uniqueId } from 'lodash-es';
import { GoCheck, GoX } from 'react-icons/go';

/**
 * @type {{ [key in TierTableColumns]: React.ReactNode }[]}
 */
const tierTableConfig = [
	{
		id: uniqueId(),
		feature: 'Default Workspace',
		free: <Icon as={GoCheck} fontSize="x-large" />,
		premium: <Icon as={GoCheck} fontSize="x-large" />,
	},
	{
		id: uniqueId(),
		feature: 'Editable Workspaces',
		free: 'Unlimited',
		premium: 'Unlimited',
	},

	{
		id: uniqueId(),
		feature: 'Monthly Active Nodes',
		free: 25,
		premium: 'Unlimited',
		tooltip:
			'A monthly active node is counted when an unique node has been run atleast 5 times a month.',
	},
	{
		id: uniqueId(),
		feature: 'Task Runs',
		free: 'Unlimited',
		premium: 'Unlimited',
	},
	{
		id: uniqueId(),
		feature: 'Free Skills',
		free: <Icon as={GoCheck} fontSize="x-large" />,
		premium: <Icon as={GoCheck} fontSize="x-large" />,
	},
	{
		id: uniqueId(),
		feature: 'Premium Skills',
		free: <Icon as={GoX} fontSize="x-large" />,
		premium: <Icon as={GoCheck} fontSize="x-large" />,
		tooltip:
			'Complex workflows that can be acquired via monthly subscription or one-time payment.',
	},
	{
		id: uniqueId(),
		feature: 'Cloud Workspaces',
		free: <Icon as={GoX} fontSize="x-large" />,
		premium: 'Coming Soon',
		tooltip:
			'Create webhooks, public dashboards and bots that run on our cloud servers.',
	},
	{
		id: uniqueId(),
		feature: 'Technical Support',
		free: <Icon as={GoX} fontSize="x-large" />,
		premium: <Icon as={GoCheck} fontSize="x-large" />,
		tooltip:
			'Get live help from our team in building and maintaining custom automations.',
	},
];

export default tierTableConfig;
