import React, { useEffect } from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import isElectron from 'is-electron';
import { useDispatch, useSelector } from 'react-redux';
import { addIntents, setContacts } from '../../../redux/actions';
import { setCommandBarVisibility } from '../../../redux/actions/cardUIV2';
import getContacts from '../../../functions/contacts/getContacts';
import toggleCommandBar from '../../../functions/commandBar/toggleCommandBar';
import analytics from '../../../util/Analytics';
import CardWindow from './CardWindow';
import CommandBar from './CommandBar';
import SearchBar from '../CardUI/Search/Bar';
import useNormalizedBrains from './useNormalizer';
import { entitiesUpsert } from '../../../redux/actions/cardUIV2';

const processComponents = (components, url) => {
	return components.map((component) => {
		if (component.type === 'prompt') {
			return {
				type: 'text',
				value: component.value,
			};
		}
		if (component.type === 'custom') {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: 'custom',
				endpointUrl: url + component.endpoint,
			};
		} else {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: component.type,
			};
		}
	});
};

const extractIntentsFromBrains = (brains) => {
	let intents = { byId: {}, allIds: [] };
	for (let id in brains.byId) {
		if (brains.byId[id].status === 'STARTED') {
			brains.byId[id].intents.forEach((intent) => {
				let randomId = intent.endpoint.substring(8);
				intents.byId[randomId] = {
					intent: processComponents(
						intent.components,
						brains.byId[id].url
					),
					submitDetails: {
						robotId: brains.byId[id]._id,
						robotStatus: brains.byId[id].status,
						type: 'http',
						url: brains.byId[id].url,
						trigger: intent.endpoint,
					},
				};
				intents.allIds.push(randomId);
			});
		}
	}
	return intents;
};

/**
 *
 * @param {Element} element
 * @returns
 */
const checkIsBody = (element) => {
	if (element) {
		return element.outerHTML.includes(`maya-command-ui`);
	} else {
		return true;
	}
};

const CardUI = () => {
	const brains = useSelector((state) => state.brains);
	const user = useSelector((state) => state.user);
	const dispatch = useDispatch();

	/** Custom fn that returns normalized entities inside the brain */
	const { getNormalizedBrains } = useNormalizedBrains();

	useEffect(() => {
		/**
		 * Normalize brain into components and intents entities
		 * and set them in the redux store
		 */
		const {
			entities: { components, intents },
		} = getNormalizedBrains();
		if (components && intents) {
			dispatch(
				entitiesUpsert({
					components: {
						allIds: Object.keys(components).sort(),
						byId: components,
					},
					intents: {
						allIds: Object.keys(intents).sort(),
						byId: intents,
					},
				})
			);
		}
	}, [dispatch, getNormalizedBrains]);

	/** Extract intents list from various brains */
	useEffect(() => {
		if (isElectron()) {
			let intents = extractIntentsFromBrains(brains);
			dispatch(addIntents(intents));
		}
	}, [brains, dispatch]);

	useEffect(() => {
		getContacts().then((res) => {
			dispatch(setContacts(res));
		});
	}, [dispatch]);

	/** identify analytics user */
	useEffect(() => {
		if (user.info) {
			analytics.identify(user.info.id);
			analytics.setUserDetails(user.info.email, user.info.id);
		}
	}, [user.info]);

	useEffect(() => {
		/** IPC Command Bar Toggle Listener */
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer } = electron;
			ipcRenderer.removeAllListeners('/toggleCmdBar');
			ipcRenderer.on('/toggleCmdBar', (event, arg) => {
				console.log('toggling command bar from frontend', arg);
				//window.$crisp.push(['do', 'chat:hide']);
				if (arg.mode === 'open') {
					dispatch(setCommandBarVisibility(true));
					analytics.track(`Command Bar Open`, {
						method: arg.method,
					});
				} else {
					dispatch(setCommandBarVisibility(false));
					analytics.track(`Command Bar Close`, {
						method: arg.method,
					});
				}
			});
		}
	});

	return (
		<Box
			width="100vw"
			height="100vh"
			onClick={(e) => {
				let elem = document.elementFromPoint(e.clientX, e.clientY);
				console.log('User Clicked Card UI', elem.outerHTML);
				let isBody = false;
				if (elem) {
					isBody = checkIsBody(elem);
				} else {
					isBody = true;
				}
				if (isBody) {
					toggleCommandBar({ method: 'mouse click' });
				} else {
					analytics.track('User Clicked Card UI', {
						element: elem.outerHTML,
					});
				}
			}}
		>
			<HStack>
				{/* <Box position="relative" width="50%">
					<SearchBar />
				</Box> */}
				<Box position="relative" width="100%">
					<CommandBar />
				</Box>
			</HStack>
			<CardWindow />
		</Box>
	);
};

export default CardUI;
