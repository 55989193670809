import * as React from 'react';
import styled from '@emotion/styled';
import {
	Box,
	useColorModeValue,
	useColorMode,
	useTooltip,
	Tooltip,
} from '@chakra-ui/react';
import isElectron from 'is-electron';
import { FiGrid } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { getOS } from '../../../util/PlatformUtils/platform';

//Components
import Updater from './Updater';

//Redux
import { connect } from 'react-redux';

// Redux Actions
import { closeTab, setTrialExpiryModalDisplay, switchTab } from '../../../redux/actions';

// IPCFetch
import IPCFetch from '../../../util/IPCFetch';

// History
import { HistoryWithSlug } from '../../../util/History';
import { getTimeToExpiry } from '../../../util/Tier/getTimeRemaining';

const TabHeader = ({ children }) => {
	return (
		<Box
			style={{ WebkitAppRegion: 'drag' }}
			display="flex"
			bgColor={useColorModeValue('dashboard.bg_light.100', 'maya_dark.300')} //#2f2f2f
			width="100%"
			height="45px"
			position="absolute"
			zIndex="2000"
			flexGrow="0"
			flexShrink="0"
			color="#272727"
			fontFamily="body"
			alignItems="flex-end"
		>
			{children}
		</Box>
	);
};
/**
 *
 * Brain Tab
 *
 * Click this to go to a particular runtime
 *
 * @param {String} selected
 * @param {String} info
 * @param {String} closeFn
 * @param {String} switchFn
 */

const BrainTab = ({ selected, info, closeFn, switchFn, showTrialExpiryModal }) => {
	const [hover, onHover] = React.useState(false);
	const [showTooltip, toggleTooltipState] = React.useState(true);
	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: '#383737',
	};
	function toggleTooltipHandlerDirty() {
		toggleTooltipState(true);
	}
	function toggleTooltipHandlerClean() {
		toggleTooltipState(false);
	}
	document.removeEventListener('dirtyTabDOM', toggleTooltipHandlerDirty);
	document.removeEventListener('cleanTabDOM', toggleTooltipHandlerClean);
	document.addEventListener('dirtyTabDOM', toggleTooltipHandlerDirty);
	document.addEventListener('cleanTabDOM', toggleTooltipHandlerClean);

	async function handleClick() {
		try {
			const timeToExpiry = await getTimeToExpiry()
			if (timeToExpiry <= 0) {
				return showTrialExpiryModal()
			}
		} catch (e) {
			console.log('Unable to get time to tier expiry')
			return
		}
		
		if (!showTooltip) {
			switchFn();
			HistoryWithSlug.push(`/edit?id=${info.brainId}`);
		}
	}

	return (
		<Tooltip
			label={showTooltip ? 'Deploy changes before switching tabs' : ''}
		>
			<Box
				style={{ WebkitAppRegion: 'no-drag' }}
				marginLeft="0.2rem"
				paddingX="0.7rem"
				height="2rem"
				fontFamily="body"
				display="flex"
				fontSize="0.8rem"
				color={hover || selected ? 'white' : '#969494'}
				bg={hover || selected ? '#3a3a3a' : bgColor[colorMode]}
				alignItems="center"
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				justifyContent="center"
				onMouseEnter={() => onHover(true)}
				onMouseLeave={() => onHover(false)}
			>
				<Box
					onClick={handleClick}
					cursor="pointer"
				>
					{info.name}
				</Box>
				<SmallCloseIcon marginLeft="0.3rem" onClick={closeFn} />
			</Box>
		</Tooltip>
	);
};

const GridButton = ({ selected, info, switchFn }) => {
	const { colorMode } = useColorMode();
	const [hover, onHover] = React.useState(false);
	const [showTooltip, toggleTooltipState] = React.useState(false);
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.250',
	};

	function toggleTooltipHandlerDirty() {
		toggleTooltipState(true);
	}
	function toggleTooltipHandlerClean() {
		toggleTooltipState(false);
	}
	document.removeEventListener('dirtyTabDOM', toggleTooltipHandlerDirty);
	document.removeEventListener('cleanTabDOM', toggleTooltipHandlerClean);
	document.addEventListener('dirtyTabDOM', toggleTooltipHandlerDirty);
	document.addEventListener('cleanTabDOM', toggleTooltipHandlerClean);

	return (
		<Tooltip
			label={
				showTooltip ? 'Deploy changes before switching to Dashboard' : ''
			}
		>
			<Box
				style={{ WebkitAppRegion: 'no-drag' }}
				marginLeft="0.8rem"
				height="2rem"
				width="2rem"
				display="flex"
				bg={hover || selected ? '#3a3a3a' : null}
				alignItems="center"
				justifyContent="center"
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				onMouseEnter={() => onHover(true)}
				onMouseLeave={() => onHover(false)}
				onClick={() => {
					if (!showTooltip) {
						switchFn();
						HistoryWithSlug.replace('/home');
						// window.location.replace("/");
					}
				}}
				cursor="pointer"
			>
				<IconContext.Provider
					value={{
						color: hover || selected ? 'white' : '#777777',
						className: 'global-class-name',
					}}
				>
					<div>
						<FiGrid />
					</div>
				</IconContext.Provider>
			</Box>
		</Tooltip>
	);
};

/**
 * Close Minimize Menu
 *
 * A set of buttons that help to close minimize and fullscreen the window.
 */

const CloseMinimizeMenu = () => {
	const [hover, onHover] = React.useState(true);
	const os = getOS();

	const [{ fetchResultsIPC: closeWindow }] = IPCFetch({
		EVENT: '/window/close',
		METHOD: 'post',
	});

	const [{ fetchResultsIPC: macHideWindow }] = IPCFetch({
		EVENT: '/mac/window/hide',
		METHOD: 'post',
	});

	const [{ fetchResultsIPC: minimizeWindow }] = IPCFetch({
		EVENT: '/window/minimize',
		METHOD: 'post',
	});

	const [{ fetchResultsIPC: toggleMaximizeWindow }] = IPCFetch({
		EVENT: '/window/togglemax',
		METHOD: 'post',
	});

	return (
		<Box
			display="flex"
			style={{ WebkitAppRegion: 'no-drag' }}
			marginBottom="15px"
		>
			<Box
				display="flex"
				onMouseEnter={() => onHover(true)}
				onMouseLeave={() => onHover(false)}
			>
				<Box
					height="10px"
					width="10px"
					bg={hover ? 'red.400' : null}
					border={hover ? 'solid 1px #3c3c3c' : 'solid 1px #5a5a5a'}
					borderRadius="50%"
					marginLeft="0.8rem"
					onClick={async () =>
						os === 'Mac' ? macHideWindow() : closeWindow()
					}
				/>
				<Box
					height="10px"
					width="10px"
					border={hover ? 'solid 1px #3c3c3c' : 'solid 1px #5a5a5a'}
					bg={hover ? 'yellow.400' : null}
					borderRadius="50%"
					marginLeft="0.5rem"
					onClick={async () => minimizeWindow()}
				/>
				<Box
					height="10px"
					width="10px"
					border={hover ? 'solid 1px #3c3c3c' : 'solid 1px #5a5a5a'}
					bg={hover ? 'green.300' : null}
					borderRadius="50%"
					marginLeft="0.5rem"
					onClick={async () => toggleMaximizeWindow()}
				/>
			</Box>
		</Box>
	);
};

const TabSection = ({
	selected,
	list,
	closeTab,
	switchTab,
	setTrialExpiryModalDisplay
}) => {
	console.log(selected, list);
	const TabList = list.allIds.map((id, i) => {
		return list.byId[id].typeOf === 'dash' ? (
			<GridButton
				key={i}
				selected={selected === id}
				info={list.byId[id]}
				switchFn={() => HistoryWithSlug.push('/')}
			/>
		) : (
			<BrainTab
				key={i}
				info={list.byId[id]}
				selected={selected === id}
				closeFn={() => {
					closeTab(id);
					HistoryWithSlug.push('/home');
				}}
				switchFn={() => {
					switchTab(id);
					// HistoryWithSlug.push(`/edit?id=${id}`)
				}}
				showTrialExpiryModal={() => setTrialExpiryModalDisplay(true)}
			/>
		);
	});

	return (
		<>
			<TabHeader>
				<CloseMinimizeMenu />
				{TabList}
			</TabHeader>
			<Updater />
		</>
	);
};

const mapStateToProps = (state) => {
	const { tabs } = state;
	const { selected } = tabs;
	const { list } = tabs;
	return { selected, list };
};

export default isElectron()
	? connect(mapStateToProps, { closeTab, switchTab, setTrialExpiryModalDisplay })(TabSection)
	: Box;

// export default connect(mapStateToProps, { closeTab, switchTab })(TabSection)
