/**
 * Command History Actions
 */

// Action Types
import actionTypes from '../actionTypes';
import { addTask, updateTask } from './task';
/**
 *
 * Log intent/command event in commandHistory redux store
 * @param {import('../reducers/types/commandHistoryReducer').IntentObject} intentObject
 * @param {import('../reducers/types/commandHistoryReducer').CommandHistoryLogObject['status']} status
 * @param {import('../reducers/types/commandHistoryReducer').CommandHistoryLogObject['metadata']} metadata
 * @returns
 */
export const commandHistoryLogIntentEvent =
	(intentObject, status, metadata = null) =>
	(dispatch, getState) => {
		/**
		 * @type {import('./types/commandHistoryReducer').CommandHistoryLogObject}
		 */
		var logItem = {
			intentId: intentObject.submitDetails.trigger.substring(8),
			intentObject,
			status,
			metadata,
			timestamp: new Date().toISOString(),
		};

		if (status === 'start') {
			dispatch(
				addTask(metadata.taskId, { taskId: metadata.taskId, ...logItem })
			);
		} else {
			dispatch(
				updateTask(metadata.taskId, { taskId: metadata.taskId, ...logItem })
			);
		}
		dispatch({
			type: actionTypes.COMMAND_HISTORY_LOG_INTENT_EVENT,
			payload: { logItem },
		});
		return;
	};
