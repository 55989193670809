import axios from 'axios';
import config from '../../config/config';

const CMS_API_ROOT = config.app.CMS_BACKEND;

const getStoreCategories = async () => {
	try {
		const response = await axios.get(`${CMS_API_ROOT}/categories`);
		/**
		 * @type {import('./types/Categories').StoreCategory[]}
		 */
		const categories = response.data;
		return categories;
	} catch (e) {
		console.error(e);
		return [];
	}
};

export default getStoreCategories;
