import React, { useContext, useEffect, useRef } from 'react';
import { Box, Input, Text, useColorMode } from '@chakra-ui/react';
import CommandBarContext from '../../CommandBarContext';
import { useSelector } from 'react-redux';

const color = {
	light: 'card_ui.font_light.200',
	dark: 'card_ui.font_dark.200',
};

/**
 *
 * @param {{
 *	onClick?: () => void,
 *	handleOnKeyUp: (e: MouseEvent) => void,
 *	handleOnKeyDown: (e: MouseEvent) => void,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const EmbViewPlaceholder = ({
	onClick,
	handleOnKeyUp,
	handleOnKeyDown,
	...rest
}) => {
	const { setCurrentInputRef } = useContext(CommandBarContext);
	const { colorMode } = useColorMode();
	/**
	 * @type {React.MutableRefObject<HTMLParagraphElement>}
	 */
	const inputRef = useRef(null);

	const selectedCardItemObj = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => {
			const cardId =
				state.cardUIV2.commandBar.suggestionsArea.selectedSearchItem;
			return state.cardUIV2.entities.cards.byId[cardId];
		}
	);

	useEffect(() => {
		if (inputRef.current) {
			setCurrentInputRef(inputRef);
			inputRef.current.focus();
		}
	}, [setCurrentInputRef]);

	return (
		<Box
			display="flex"
			alignItems="center"
			onClick={() => {
				if (onClick) onClick();
				if (inputRef.current) inputRef.current.focus();
			}}
			{...rest}
		>
			{selectedCardItemObj ? (
				<Text
					paddingY="0"
					bg="transparent"
					border="none"
					type="phone"
					ref={inputRef}
					_focus={{ outline: 'none' }}
					tabIndex={0}
					paddingRight="0.5rem"
					paddingLeft="0.5rem"
					width="20rem"
					focusBorderColor="gray.200"
					color={color[colorMode]}
					fontFamily="body"
					fontWeight="400"
					fontSize="1.3rem"
					onKeyUp={handleOnKeyUp}
					onKeyDown={handleOnKeyDown}
				>
					{selectedCardItemObj.topText}
				</Text>
			) : null}
		</Box>
	);
};

export default EmbViewPlaceholder;
