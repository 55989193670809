import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import deploySkills from '../editor/deploy';

const updateSkillPackToBrain = async (formData) => {
	var updateSkillPackToBrainAPIFn,
		updateSkillPackToBrainIPCFn,
		updateSkillPackToBrainResponse,
		getSkillPackByIdFn,
		getSkillPackByIdResponse;
	if (!isElectron()) {
		// updateSkillPackToBrainAPIFn = APIFunction({BASEURL: config.apiServer.BASEURL, PATH_SEARCH: APIRoutes.apiService.updateSkillPackToBrain.PATH_SEARCH, PATH_METHOD: APIRoutes.apiService.updateSkillPackToBrain.PATH_METHOD});
		// updateSkillPackToBrainResponse = await updateSkillPackToBrainAPIFn();
		// if (updateSkillPackToBrainResponse["data"]["error"]) {
		//     return updateSkillPackToBrainResponse["data"];
		// } else {
		//     return updateSkillPackToBrainResponse["data"]["results"];
		// }
	} else {
		console.log('updating skillpack', formData);
		updateSkillPackToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.updateSkillPackToBrain.EVENT,
		});
		updateSkillPackToBrainResponse = await updateSkillPackToBrainIPCFn({
			...formData,
		});
		if (updateSkillPackToBrainResponse['data']['error']) {
			return updateSkillPackToBrainResponse['data'];
		}

		updateSkillPackToBrainResponse['data']['brain'] = await deploySkills({
			profileSlug: formData.profileSlug,
			brainId: formData.brainId,
		});

		return updateSkillPackToBrainResponse['data']['results'];
	}
};

export default updateSkillPackToBrain;
