import { generateSlug } from 'random-word-slugs';

const randomDefaultProfileName = (resource) => {
	let random = generateSlug(1, {
		partsOfSpeech: ['adjective', 'adjective'],
		categories: {
			adjective: ['emotions', 'personality', 'condition'],
		},
	});

	return resource + '-' + random;
};

export default randomDefaultProfileName;
