import * as React from 'react';
import { Box, Tooltip, IconButton, useColorModeValue } from '@chakra-ui/react';
import {
	ChevronDownIcon,
	ChevronUpIcon,
	SmallCloseIcon,
} from '@chakra-ui/icons';
// import { TiFolderOpen, TiClipboard, TiShoppingCart } from "react-icons/ti";
import isElectron from 'is-electron';
// import { GoPackage } from "react-icons/go";

//Components
// import IconContainer from "../../util/React_Icon";

//Panels
// import PluginPanel from "./Plugins";
// import CustomDashboard from "./MenuPanel/CustomDashboard";
import Dashboard from '../Dashboard';

export const PanelExpandClose = ({
	expanded,
	handleHideExpand,
	handleClose,
}) => {
	return (
		<Box
			bg={useColorModeValue('dashboard.bg_light.100', '#464646')}
			borderRadius="5px"
			padding="1"
			ml="0.5rem"
			border="solid 1px"
			borderColor={useColorModeValue(
				'dashboard.border_light',
				'dashboard.border_dark'
			)}
			display="flex"
			alignItems="center"
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
		>
			<IconButton
				icon={expanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
				bg="transparent"
				borderRadius="5px"
				_focus={{ border: 'none' }}
				onClick={handleHideExpand}
			/>
			<IconButton
				icon={<SmallCloseIcon />}
				bg="transparent"
				borderRadius="5px"
				onClick={handleClose}
				_focus={{ border: 'none' }}
			/>
		</Box>
	);
};

const TabItem = ({ icon, name, selected, onClick, description }) => {
	return (
		<Tooltip hasArrow label={description} placement="bottom" zIndex="4000">
			<Box
				borderRight="1px solid #D8D7D7"
				width="6rem"
				display="flex"
				height="100%"
				alignItems="center"
				justifyContent="center"
				fontFamily="body"
				paddingX="2rem"
				onClick={onClick}
				bg={selected ? '#ececec' : null}
				cursor
			>
				{icon}
				<Box marginX="0.6rem" fontWeight="600" color="#727070">
					{name}
				</Box>
			</Box>
		</Tooltip>
	);
};

const MenuPanel = ({ brainId, runtime, closeFn, section }) => {
	const [selectedTab, selectTab] = React.useState('Menu');
	const [size, setSize] = React.useState('60vh');
	const [expanded, setExpanded] = React.useState(false);
	const handleHideExpand = (e) => {
		setSize(expanded ? '60vh' : isElectron() ? '95vh' : '100vh');
		setExpanded(!expanded);
	};

	const handleClose = () => {
		closeFn();
		setExpanded(false);
	};
	// React.useEffect(() => {
	//   if (size === "100vh") {
	//     setExpanded(true);
	//   }
	// }, [size]);
	console.log(brainId);
	return (
		<Box height={size}>
			<Dashboard
				currentBrainId={brainId}
				section={section}
				mode="menu"
				misc={{
					expanded: expanded,
					setExpanded: setExpanded,
					handleHideExpand: handleHideExpand,
					handleClose: handleClose,
				}}
			/>
		</Box>
	);
};

export default MenuPanel;

// <Box
//         width="100%"
//         height="2.5rem"
//         borderBottom="1px solid #D8D7D7"
//         display="flex"
//         flexDirection="rows"
//         justifyContent="space-between"
//       >
//         <Box display="flex" flexDirection="rows">
//           <PanelButton
//             name="Store"
//             selected={selectedTab === "Store" ? true : false}
//             selectTab={selectTab}
//             description="Use nodes made by other people."
//             icon={<TiShoppingCart />}
//           />

//           <PanelButton
//             name="Dashboard"
//             selected={selectedTab === "Dashboard" ? true : false}
//             selectTab={selectTab}
//             description="Use this to interact with the bot."
//             icon={<TiClipboard />}
//           />
//         </Box>
//         <Box>
//           <IconButton
//             icon={expanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
//             bg="#F8F8F8"
//             color="gray.600"
//             borderRadius="none"
//             _focus={{ border: "none" }}
//             onClick={handleHideExpand}
//           />
//           <IconButton
//             icon={<SmallCloseIcon />}
//             onClick={() => {
//               closeFn();
//               setExpanded(false);
//             }}
//             borderRadius="none"
//             color="gray.600"
//             bg="#F8F8F8"
//             _focus={{ border: "none" }}
//           />
//         </Box>
//       </Box>
