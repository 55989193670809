import React, { useMemo } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { useDispatch, useSelector } from 'react-redux';
import analytics from '../../../util/Analytics';
import {
	Box,
	HStack,
	IconButton,
	Spacer,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import WebCard from './CommandBar/SuggestionsArea/EmbeddedView/WebCard';
import { ChevronRightIcon, CloseIcon } from '@chakra-ui/icons';
import { BsDash } from 'react-icons/bs';
import WebCardButton from './CommandBar/SuggestionsArea/EmbeddedView/WebCard/Button';
import {
	cardClose,
	cardMinimize,
	cardNavigate,
} from '../../../redux/actions/cardUIV2';

import DefaultSuggestionIcon from './CommandBar/SuggestionsArea/DefaultSuggestionIcon';

const iconColor = {
	light: '#6C6C6C',
	dark: '#FFFEFE',
};

const CardWindow = () => {
	const { colorMode } = useColorMode();
	const dispatch = useDispatch();
	const brains = useSelector((state) => state.brains);
	const { selectedSearchItem: selectedSearchItemId } = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea
	);
	const allCards = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities.cards
	);
	const { isVisible, isInteractionEnabled } = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.cardWindow
	);
	const selectedCardItem = useMemo(
		() =>
			allCards.allIds.length > 0 && selectedSearchItemId
				? allCards.byId[selectedSearchItemId]
				: null,
		[allCards.allIds.length, allCards.byId, selectedSearchItemId]
	);
	const botDashUrl = useMemo(() => {
		if (
			selectedCardItem &&
			selectedCardItem.mode === 'tab' &&
			selectedCardItem.brainId
		) {
			const hostname = brains.byId[selectedCardItem.brainId].url;
			const newUrl = selectedCardItem.url.includes('?')
				? hostname +
				  selectedCardItem.url +
				  '&mode=' +
				  colorMode +
				  '&theme=angular' +
				  '&tab=' +
				  selectedCardItem.tabId
				: hostname +
				  selectedCardItem.url +
				  '?mode=' +
				  colorMode +
				  '&theme=angular' +
				  '&tab=' +
				  selectedCardItem.tabId;
			return newUrl;
		} else return '';
	}, [brains.byId, colorMode, selectedCardItem]);

	const handleOnKeyUp = (e) => {
		if (e === 'down') {
			console.log(`Command Bar Key Down`);
			analytics.track(`Command Bar Key Down`);
		} else if (e === 'up') {
			console.log(`Command Bar Key Up`);
			analytics.track(`Command Bar Key Up`);
		} else if (e === 'enter' && isInteractionEnabled) {
			console.log(`Command Bar Key Enter`);
			analytics.track(`Command Bar Key Enter`);
		}
	};

	return isVisible ? (
		<Box position="relative" width="100vw" height="100vh">
			<KeyboardEventHandler
				handleKeys={['up', 'down', 'enter']}
				key="keyboard_handler"
				onKeyEvent={(key, e) => handleOnKeyUp(key)}
			/>
			{selectedCardItem ? (
				<Box
					position="absolute"
					width="70vw"
					top="50%"
					left="50%"
					transform="translate(-50%, -50%)"
					bg="maya_dark.300"
					display="flex"
					flexDir="column"
					borderRadius="0.32rem"
				>
					<Box display="flex" p="0.3rem" pb="0" alignItems="center">
						{selectedCardItem.topIcon === 'chevron-right' ? (
							<DefaultSuggestionIcon />
						) : null}
						<Text isTruncated fontSize="0.9rem">
							{selectedCardItem.topText}
						</Text>
						<Spacer flex="1" />
						<HStack spacing="1">
							<WebCardButton
								aria-label="Close card"
								icon={<CloseIcon boxSize="0.5rem" />}
								onClick={() => dispatch(cardClose())}
							/>
						</HStack>
					</Box>
					<WebCard
						url={
							selectedCardItem.mode === 'tab'
								? botDashUrl
								: selectedCardItem.url
						}
						onMinimize={() => dispatch(cardMinimize())}
						isMaximized={true}
						onWebviewDidNavigate={(url) => {
							dispatch(
								cardNavigate({ cardId: selectedCardItem.id, url })
							);
						}}
					/>
				</Box>
			) : null}
		</Box>
	) : null;
};

export default CardWindow;
