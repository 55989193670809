import React from 'react';
import { Box } from '@chakra-ui/react';
import { HiOutlineStatusOffline } from 'react-icons/hi';

import IconContainer from '../../util/React_Icon';

const OfflineRibbon = () => {
	return (
		<Box
			width="100%"
			height="3rem"
			justifyContent="center"
			display="flex"
			flexDirection="column"
			alignItems="center"
			position="absolute"
			zIndex="10"
			bottom="0"
		>
			<Box
				margin="auto"
				left="0"
				height="100%"
				bg="purple.500"
				right="0"
				top="0"
				bottom="0"
				justifyContent="center"
				display="flex"
				flexDirection="row"
				alignItems="center"
				width="60%"
				borderTopRadius="10px"
			>
				<IconContainer
					icon={<HiOutlineStatusOffline />}
					value={{
						color: '#fff',
						className: 'global-class-name',
						size: '1rem',
					}}
				/>
				<Box marginLeft="5">
					Oops, looks like you are offline. Try reconnecting to your
					network.
				</Box>
			</Box>
		</Box>
	);
};

export default OfflineRibbon;
