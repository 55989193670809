import _ from 'lodash';

const mergeIntentObjectArray = (objectArray) => {
	let mergedArray = [];
	objectArray.map((object) => {
		mergedArray.push(object.value);
	});

	return _.join(mergedArray, ' ');
};

export const formatBrainItems = (brains) => {
	var brainArray = [];
	for (var key in brains.byId) {
		let newItem = {
			_id: brains.byId[key]._id,
			title: brains.byId[key].name,
			commands: [],
		};

		brains.byId[key].intents.map((command) => {
			newItem.commands.push({
				_id: command._id,
				value: mergeIntentObjectArray(command.components),
			});
		});

		brainArray.push(newItem);
	}
	return brainArray;
};
