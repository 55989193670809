import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import {
	Box,
	Button,
	Checkbox,
	Flex,
	FormControl,
	FormLabel,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	LightMode,
	Spacer,
	Stack,
	Switch,
	Tag,
	TagLeftIcon,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { ReCron } from '@sbzen/re-cron';
import HeaderWithDescription from './HeaderWithDescription';
import './ReCron.css';
import { InfoIcon } from '@chakra-ui/icons';
import useDebounce from '../../../util/useDebounce';
import Axios from 'axios';
import { BiBrain } from 'react-icons/bi';
import { HistoryWithSlug } from '../../../util/History';
import { useDispatch, useSelector } from 'react-redux';
import { addTab } from '../../../redux/actions';
import SimpleCronScheduler from './SimpleCronScheduler';

/**
 *
 * @param {{
 *  isOpen: boolean,
 *  task: import('../LongRunningTasks').LongRunningTask,
 *  onCancel: () => void,
 *  onConfirm: (newCronString: string) => void,
 *  modalProps?: import('@chakra-ui/modal').ModalProps,
 * }} param0
 * @returns
 */
const RescheduleTaskModal = ({
	isOpen,
	task,
	onCancel,
	onConfirm,
	modalProps,
}) => {
	const dispatch = useDispatch();
	const brain = useSelector((state) => {
		if (task?.brainId) return state?.brains?.byId[task.brainId];
		return null;
	});
	const [quartzCron, setQuartzCron] = useState(
		task?.config?.cronString ? task.config.cronString : '* * * * * *'
	);
	const [cronInfoText, setCronInfoText] = useState('');
	// '2 hours from 6pm, at every interval from 1 to 3, for 10 days in next 3 years'
	const debouncedQuartzCron = useDebounce(quartzCron, 1000);
	const [advancedControls, setAdvancedControls] = useState(false);

	/**
	 * Fetches the cron info text from cron expression
	 */
	const getCronInfoText = useCallback(
		/**
		 * POST runtimeUrl/botscheduler/0/expressionTip
		 */
		async () => {
			try {
				if (task) {
					const res = await Axios.post(
						task.brainBaseURL + '/botscheduler/0/expressionTip',
						{
							expressionType: 'cron',
							expression: debouncedQuartzCron,
						}
					);
					if (res.data.description) setCronInfoText(res.data.description);
				}
			} catch (error) {
				console.error(error);
			}
		},
		[debouncedQuartzCron, task]
	);

	useEffect(() => {
		if (isOpen) {
			setQuartzCron(
				task?.config?.cronString ? task.config.cronString : '* * * * * *'
			);
		}
	}, [isOpen, task?.config?.cronString]);

	useEffect(() => {
		getCronInfoText();
	}, [getCronInfoText]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onCancel}
			isCentered
			size="2xl"
			{...modalProps}
		>
			<ModalOverlay />
			<ModalContent height="lg" bg="#313131">
				<HeaderWithDescription
					header="View Task"
					description="View the CRON based long running task"
					containerProps={{
						px: '4',
						py: '3',
						borderTopRadius: 'xl',
					}}
				/>
				<ModalCloseButton />
				<ModalBody
					flex="1"
					minHeight="0"
					display="flex"
					flexDirection="column"
					p="0"
				>
					<Flex
						flex="1"
						minHeight="0"
						direction="column"
						m="2"
						border="1px solid #4d4d4d"
						borderRadius="md"
						className="re-cron-container"
						position="relative"
					>
						<Flex position="absolute" right="1" top="2">
							<FormControl display="flex" alignItems="center">
								<Switch
									id="advanced-controls"
									size="sm"
									colorScheme="purple"
									isChecked={advancedControls}
									onChange={(e) =>
										setAdvancedControls(e.target.checked)
									}
								/>
								<FormLabel htmlFor="advanced-controls" mb="0" ml="1.5">
									Advanced Controls
								</FormLabel>
							</FormControl>
						</Flex>
						{advancedControls ? (
							<ReCron value={quartzCron} onChange={setQuartzCron} />
						) : (
							<SimpleCronScheduler
								value={quartzCron}
								onChange={setQuartzCron}
							/>
						)}
					</Flex>
					<Flex direction="row" p="2" pt="0">
						<Input
							value={quartzCron}
							_focus={{
								borderColor: '#c3adff',
							}}
							isReadOnly
							flex="1"
							minWidth="15rem"
						/>
						{cronInfoText ? (
							<Flex flex="2" minWidth="0" ml="2">
								<Tooltip label={cronInfoText}>
									<Tag
										flex="1"
										minWidth="0"
										variant="subtle"
										colorScheme="purple"
									>
										<TagLeftIcon boxSize="12px" as={InfoIcon} />
										<Text flex="1" minWidth="0" isTruncated>
											{cronInfoText}
										</Text>
									</Tag>
								</Tooltip>
							</Flex>
						) : null}
					</Flex>
					<Flex py="3" px="4" bg="#363636" borderBottomRadius="xl">
						<Button
							borderRadius="0.25rem"
							size="sm"
							minWidth="28"
							leftIcon={<Icon as={BiBrain} />}
							onClick={() => {
								if (brain) {
									dispatch(addTab(brain._id, brain.name));
									HistoryWithSlug.push(`/edit?id=${task.brainId}`);
								} else {
									console.error(`brain is invalid!`);
								}
							}}
						>
							Open Workspace
						</Button>
						<Spacer />
						<Stack direction="row" spacing="3">
							{task?.config?.cronString === quartzCron ? (
								<>
									<Button
										borderRadius="0.25rem"
										size="sm"
										minWidth="28"
										onClick={onCancel}
									>
										Close
									</Button>
								</>
							) : (
								<>
									<Button
										borderRadius="0.25rem"
										size="sm"
										minWidth="28"
										onClick={onCancel}
									>
										Cancel
									</Button>
									<LightMode>
										<Button
											size="sm"
											borderRadius="0.25rem"
											colorScheme="purple"
											minWidth="28"
											onClick={() => onConfirm(quartzCron)}
										>
											Confirm
										</Button>
									</LightMode>
								</>
							)}
						</Stack>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default RescheduleTaskModal;
