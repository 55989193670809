import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import isElectron from 'is-electron';
import { FiGitBranch } from 'react-icons/fi';

//Components
import IconContainer from '../../util/React_Icon/';

//functions
import getAppVersion from '../../functions/settings/version/getAppVersion';
const AppVersion = () => {
	const [version, setVersion] = React.useState('');
	const { colorMode } = useColorMode();
	React.useEffect(() => {
		if (isElectron()) {
			getAppVersion().then((res) => {
				console.log('VERSION:', res);
				setVersion(res);
			});
		}
	}, [setVersion]);
	var bgColor = {
		light: '#f2f2f2',
		dark: 'maya_dark.250',
	};
	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};
	return (
		version && (
			<Box
				width="90%"
				height="35px"
				bg={bgColor[colorMode]}
				margin="10px"
				borderRadius="5px"
				padding="10px"
				display="flex"
				alignItems="center"
				fontSize="13px"
				fontWeight="600"
				boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
			>
				<IconContainer
					icon={<FiGitBranch />}
					value={{
						color: color[colorMode],
						className: 'global-class-name',
						size: '13px',
					}}
				/>
				<Box ml="2">{`Maya v${version}`}</Box>
			</Box>
		)
	);
};

export default AppVersion;
