import { Flex, Text } from '@chakra-ui/react';
import {
	isCronDayOfWeekValueString,
	isCronHoursValue,
	isCronMinutesValue,
	isCronSecondsValue,
} from '@mayahq/cron';
import isNumber from 'lodash/isNumber';
import React, { useEffect, useState } from 'react';
import NumericPeriodValuesSelector from '../NumericPeriodValuesSelector';
import WeekDayValuesSelector from './WeekDayValuesSelector';

/**
 *
 * @param {{
 * 	cronObject: import('@mayahq/cron').CronQuartz,
 * 	onCronObjectChange: (newCronObject: import('@mayahq/cron').CronQuartz) => void,
 *  	menuProps?: import('@chakra-ui/react').MenuProps,
 *		menuButtonProps?: import('@chakra-ui/react').MenuButtonProps & import('@chakra-ui/react').ButtonProps,
 *		menuListProps?: import('@chakra-ui/react').MenuListProps,
 *		menuItemProps?: import('@chakra-ui/react').MenuItemProps,
 * }} param0
 * @returns
 */
const EveryDayOfWeekSelector = ({
	cronObject,
	onCronObjectChange,
	menuProps,
	menuButtonProps,
	menuListProps,
	menuItemProps,
}) => {
	const [weekDay, setWeekDay] = useState(() =>
		isCronDayOfWeekValueString(cronObject?.dayOfWeek)
			? cronObject.dayOfWeek
			: 'SUN'
	);
	const [hours, setHours] = useState(() =>
		isCronHoursValue(cronObject?.hours) ? cronObject.hours : 0
	);
	const [minutes, setMinutes] = useState(() =>
		isCronMinutesValue(cronObject?.minutes) ? cronObject.minutes : 0
	);
	const [seconds, setSeconds] = useState(() =>
		isCronSecondsValue(cronObject?.seconds) ? cronObject.seconds : 0
	);

	useEffect(() => {
		onCronObjectChange({
			seconds,
			minutes,
			hours,
			dayOfWeek: weekDay,
			dayOfMonth: '?',
			month: '*',
			year: '*',
		});
	}, [hours, minutes, onCronObjectChange, seconds, weekDay]);

	return (
		<Flex mt="2" alignItems="center">
			<Text mr="1">Every</Text>
			<WeekDayValuesSelector
				selected={weekDay}
				onSelect={setWeekDay}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
			/>
			<Text mx="1">at time</Text>
			<NumericPeriodValuesSelector
				selected={hours}
				onSelect={setHours}
				selectionRange={{
					from: 0,
					to: 24,
				}}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
				hasPaddedZeroes
			/>
			<Text mx="1">:</Text>
			<NumericPeriodValuesSelector
				selected={minutes}
				onSelect={setMinutes}
				selectionRange={{
					from: 0,
					to: 60,
				}}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
				hasPaddedZeroes
			/>
			<Text mx="1">:</Text>
			<NumericPeriodValuesSelector
				selected={seconds}
				onSelect={setSeconds}
				selectionRange={{
					from: 0,
					to: 60,
				}}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
				hasPaddedZeroes
			/>
		</Flex>
	);
};

export default React.memo(EveryDayOfWeekSelector);
