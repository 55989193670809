import React from 'react';

/**
 * # Tab Context
 *
 * - Used for passing data to tab content components.
 * - Since we cannot use props as components are different, we use
 *   context.
 */
const TabContext = React.createContext({
	mayaFlowJson: null,
	setMayaFlowJson: () => {},
});

export default TabContext;
