import React from 'react';

const CommandBarContext = React.createContext({
	/**
	 * Currently Selected Input Ref
	 * @type {React.MutableRefObject<HTMLInputElement>}
	 */
	currentInputRef: null,
	setCurrentInputRef:
		/**
		 *	Sets the currently selected input ref
		 * @param {React.MutableRefObject<HTMLInputElement>} newRef
		 */
		(newRef) => {},
});

export default CommandBarContext;
