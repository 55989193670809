// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to get Devices
 */
const getMachineName = async () => {
	// Create an instance of your worker
	var getMachineNameFn;
	var getMachineNameResponse;
	if (isElectron()) {
		getMachineNameFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getMachineName.EVENT,
		});
		getMachineNameResponse = await getMachineNameFn();
		console.log('getMachineName: ', getMachineNameResponse);
	}

	return getMachineNameResponse;
};

export default getMachineName;
