/**
 * Init/get Google auth instance.
 */
export const getGoogleAuthInstance = async () => {
	return await new Promise((resolve, reject) => {
		window.gapi.load('auth2', () => {
			try {
				// Retrieve the singleton for the GoogleAuth library and set up the client.
				let auth2 = window.gapi.auth2.getAuthInstance();
				if (!auth2) {
					// if not init
					// init the auth2 instance
					// Retrieve the singleton for the GoogleAuth library and set up the client.
					auth2 = window.gapi.auth2.init({
						// ref: https://developers.google.com/identity/sign-in/web/reference#gapiauth2clientconfig
						client_id: process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID,
						// cookiepolicy: 'single_host_origin',
						ux_mode: 'redirect',
						// redirect_uri: 'http://localhost:3000/login',
						// Request scopes in addition to 'profile' and 'email'
						scope: 'profile email openid',
					});
				}
				resolve(auth2);
			} catch (error) {
				reject(error);
			}
		});
	});
};

/**
 * Service for logging out of Google, if user was signed in via Google.
 * Does nothing otherwise.
 */
export const googleLogout = async () => {
	const auth2 = await getGoogleAuthInstance();
	console.log(
		'🚀 ~ file: GoogleLogoutService.js ~ line 16 ~ auth2 ~ auth2',
		auth2
	);
	if (auth2) {
		// if auth instance is available
		// is avail only if user is signed in via google
		await auth2.signOut(); // sign out
	}
};
