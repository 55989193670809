import React from 'react';
import {
	Box,
	Flex,
	SimpleGrid,
	Stack,
	Text,
	useColorMode,
} from '@chakra-ui/react';
import { FiClock } from 'react-icons/fi';
import { MdComputer } from 'react-icons/md';
import IconContainer from '../../util/React_Icon';
import { TagWithIconSmall } from './TagWithIcon';

const formatIntent = (intentObject) => {
	let commandString = '';
	intentObject.intent.map((item) => {
		if (item.type === 'text') {
			commandString += item.value + ' ';
		} else if (item.type === 'placeholder') {
			commandString += '{ ' + item.key + ' } ';
		}
	});
	return commandString;
};

/**
 *
 * @param {{
 *  title: string,
 *  deviceName: string,
 *  nextTime: string,
 *  themeColor: import('@chakra-ui/react').BorderProps['borderLeftColor'],
 *  actionButton: React.ReactNode,
 *  onClick: React.MouseEventHandler<HTMLDivElement>,
 * }} param0
 * @returns
 */
const LongRunningTaskItem = ({
	title,
	deviceName,
	nextTime,
	themeColor,
	actionButton,
	onClick,
}) => {
	const { colorMode } = useColorMode();

	const fontColor = { light: '#555555', dark: 'white' };

	const hoverColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.150',
	};
	const borderColor = {
		light: 'dashboard.border_light',
		dark: 'dashboard.border_dark',
	};
	const bgColor = {
		light: '#f9f9f9',
		dark: 'maya_dark.250',
	};
	return (
		<Flex
			bg={bgColor[colorMode]}
			p="2.5"
			alignItems="center"
			borderRadius="0.3rem"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			border="solid 1px"
			borderLeftWidth="2px"
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
			borderRightColor={borderColor[colorMode]}
			borderTopColor={borderColor[colorMode]}
			borderBottomColor={borderColor[colorMode]}
			borderLeftColor={themeColor || 'purple.500'}
			_hover={{
				bg: hoverColor[colorMode],
				borderRightColor: borderColor[colorMode],
				borderTopColor: borderColor[colorMode],
				borderBottomColor: borderColor[colorMode],
				borderLeftColor: themeColor || 'purple.500',
			}}
			onClick={onClick}
		>
			<Flex flexDir="column" alignItems="center" px="1">
				<IconContainer
					icon={<FiClock />}
					value={{
						color: fontColor[colorMode],
						className: 'global-class-name',
						size: '18px',
					}}
				/>
			</Flex>
			<Flex flex="1" minWidth="0" direction="column" px="2">
				<Text
					fontFamily="body"
					color={fontColor[colorMode]}
					marginBottom="9px"
					fontSize="smaller"
					isTruncated
				>
					{title}
				</Text>
				<Flex w="full" flexWrap="wrap">
					{deviceName ? (
						<TagWithIconSmall
							text={deviceName}
							icon={<MdComputer />}
							scale="0.9"
						/>
					) : null}
					{nextTime ? (
						<TagWithIconSmall
							text={nextTime}
							icon={<FiClock />}
							scale="0.9"
						/>
					) : null}
				</Flex>
			</Flex>
			{actionButton ? (
				<Flex flexDir="column" alignItems="center">
					{actionButton}
				</Flex>
			) : null}
		</Flex>
	);
};

export default LongRunningTaskItem;
