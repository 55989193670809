import { generateSlug } from 'random-word-slugs';

const randomDefaultBrainName = () => {
	let random = generateSlug(2, {
		format: 'title',
		partsOfSpeech: ['adjective', 'adjective'],
		categories: {
			adjective: ['emotions', 'personality', 'condition'],
		},
	});

	return random + ' Workspace';
};

export default randomDefaultBrainName;
