import React from 'react';
import dotProp from 'dot-prop-immutable';
import CommandSearch from './CommandSearch';
import { connect } from 'react-redux';
import { Box, useColorMode } from '@chakra-ui/react';

import AudioIcon from './Voice';
import { setSearchMode } from '../../../../../redux/actions/cardui';

const TopBar = ({ display, loadingStatus, mode, setMode, setFeedbackItem }) => {
	const { colorMode } = useColorMode();
	const [inputValue, setInputValue] = React.useState('');
	const bgColor = { light: 'card_ui.bg_light', dark: 'card_ui.bg_dark' };
	const dividerColor = {
		light: 'card_ui.divider_light',
		dark: 'card_ui.divider_dark',
	};
	let searchRef = React.useRef();

	const onCancel = () => {
		setMode('search');
		setInputValue('');
		setTimeout(() => {
			searchRef.current.focus();
		}, 200);
	};

	const audioEnabled = true;

	return (
		<div>
			<Box
				roundedTop="0.7rem"
				roundedBottom={display === 'expanded' ? '0rem' : '0.7rem'}
				bg={bgColor[colorMode]}
				overflow="hidden"
				borderColor="gray.200"
				width="100%"
				display="flex"
				paddingY="0.6rem"
				onClick={() => {
					return mode === 'search' ? searchRef.current.focus() : () => {};
				}}
				cursor="pointer"
				justifyContent="space-between"
				gridTemplateColumns="1fr 1fr"
				alignItems="center"
				borderBottom={'solid 1px'}
				borderBottomColor={dividerColor[colorMode]}
			>
				<CommandSearch
					ref={searchRef}
					mode={mode}
					setMode={setMode}
					onCancel={onCancel}
					inputValue={inputValue}
					setInputValue={setInputValue}
					setFeedbackItem={setFeedbackItem}
				/>

				{audioEnabled && mode === 'search' ? (
					<AudioIcon
						inputValue={inputValue}
						setInputValue={setInputValue}
						mode={mode}
						setMode={setMode}
					/>
				) : null}
			</Box>
		</div>
	);
};

const matchStateToProps = (state, ownProps) => {
	const { centerSearch } = state.cardUI;
	const { loadingStatus, submitProgress } = centerSearch.top;
	const bottom = dotProp.get(centerSearch, `bottom`);
	const mode = dotProp.get(centerSearch, `mode`);
	return { bottom, loadingStatus, submitProgress, mode };
};

export default connect(matchStateToProps, { setMode: setSearchMode })(TopBar);
