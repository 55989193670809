import React from 'react';
import {
	Box,
	useColorMode,
	useColorModeValue,
	Image,
	Tooltip,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
} from '@chakra-ui/react';
import _ from 'lodash';
import {
	FiGitBranch,
	FiDownload,
	FiGitPullRequest,
	FiCommand,
} from 'react-icons/fi';
import { GoVerified } from 'react-icons/go';
import { TagWithIconSmall, TagWithIconMedium } from './TagWithIcon';
const HorizontalBoxCardWithDescription = ({ onClick, children }) => {
	const { colorMode } = useColorMode();
	const hoverColor = { light: '#f5f5f5', dark: 'maya_dark.250' };
	const borderColor = { light: '#f9f9f9', dark: '#4a4949' };
	return (
		<Box
			width="100%"
			onClick={onClick}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			//_hover={{ bg: hoverColor[colorMode] }}
			cursor="pointer"
			borderColor={borderColor[colorMode]}
			paddingY="0.5rem"
			display="flex"
			// overflow="auto"
			// position="absolute"
		>
			{children}
		</Box>
	);
};

export const NewItem = ({ onClick, icon, heading, description }) => {
	return (
		<Box
			bg={useColorModeValue('white', '#464646')}
			height="100%"
			marginX="0.5rem"
			borderRadius="5px"
			onClick={onClick}
			border="solid 2px"
			_hover={{ borderColor: 'purple.500' }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
		>
			<Box
				height="70%"
				display="flex"
				width="14rem"
				alignItems="center"
				justifyContent="center"
			>
				{icon}
			</Box>
			<Box
				width="100%"
				justifyContent="space-between"
				borderBottomRightRadius="5px"
				borderBottomLeftRadius="5px"
				//bg={useColorModeValue('#f9f9f9', '#565656')}
				bg="#464646"
				height="30%"
				borderTop="solid 1px"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				color={useColorModeValue('#555555', 'white')}
				paddingX="0.5rem"
				paddingTop="0.2rem"
			>
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					fontSize="0.9rem"
				>
					<Box>{heading}</Box>
				</Box>
				<Box fontSize="0.7rem" opacity="0.8">
					{_.truncate(description, {
						length: 45,
						separator: /,? +/,
					})}
				</Box>
			</Box>
		</Box>
	);
};

export const ItemCard = ({
	onClick,
	thumbnail,
	heading,
	description,
	count = 0,
	moduleDependencies,
	apps,
	isInstalledGlobal,
	version,
	scale = 1,
	menu,
	devTag,
	isActive = false,
}) => {
	return (
		<>
			<Box
				bg={useColorModeValue('white', '#464646')}
				// bg='tomato'
				height={`${170 * scale}px`}
				// width={`${620 * scale}px`}
				// marginRight="0.6rem"
				// marginBottom="0.6rem"
				borderRadius="10px"
				border="solid 2px"
				cursor="pointer"
				display="flex"
				_hover={{ borderColor: 'purple.500' }}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				borderColor={isActive ? 'green' : '#4a4949'}
				boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
				padding="2px"
			>
				<Box
					display="flex"
					flexDirection="column"
					width={`${330 * scale}px`}
					alignItems="flex-end"
					justifyContent="flex-end"
					backgroundImage={thumbnail}
					backgroundPosition="center"
					backgroundSize="cover"
					borderRadius="5px"
					onClick={onClick}
					// opacity="70%"
				/>
				<Box
					width="100%"
					justifyContent="space-between"
					borderBottomRightRadius="8px"
					borderBottomLeftRadius="8px"
					//bg={useColorModeValue('#f9f9f9', '#565656')}
					bg="#464646"
					height="33%"
					borderTop="solid 1px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingLeft="0.7rem"
					paddingRight="0.2rem"
					paddingTop="0.4rem"
					cursor="pointer"
					onClick={onClick}
				>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<Box
							fontSize={`${1.4 * scale}rem`}
							marginBottom={`${0.4 * scale}rem`}
						>
							{heading}
						</Box>
						<Box
							fontSize={`${1 * scale}rem`}
							// fontSize='14px'
							marginBottom={`${0.4 * scale}rem`}
							opacity="0.9"
							width="95%"
						>
							{_.truncate(description, {
								length: 45,
								separator: /,? +/,
							})}
						</Box>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="flex-start"
						marginBottom={`${0.4 * scale}rem`}
						// bg='green'
					>
						{devTag ? (
							<TagWithIconMedium
								text={'Maya Official'}
								icon={<GoVerified />}
								scale={scale}
							/>
						) : null}
						<TagWithIconMedium
							text={`${count} skills`}
							icon={<FiCommand />}
							scale={scale}
						/>
						{version ? (
							<TagWithIconMedium
								text={version}
								icon={<FiGitPullRequest />}
								scale={scale}
							/>
						) : null}
						{isInstalledGlobal ? (
							<TagWithIconMedium
								text={'installed'}
								icon={<FiDownload />}
								scale={scale}
							/>
						) : null}
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						height={`${30}px`}
						opacity="0.9"
						alignItems="center"
						borderTopLeftRadius="5px"
						mt="8px"
					>
						{apps && apps.length > 0
							? apps.map((app, idx) => {
									if (apps.length > 3) {
										if (idx < 2) {
											return (
												<Image
													boxSize={`${30}px`}
													borderRadius="5px"
													src={app.thumbnail}
													width="20px"
													height="20px"
													mr="12px"
												/>
											);
										} else if (idx === 2) {
											<Box
												boxSize={`${20 * scale}px`}
												margin="10px"
												borderRadius="full"
												textAlign="center"
												display="flex"
												flexDirection="column"
												justifyContent="center"
												fontSize={`${0.8 * scale}px`}
												bg="#464646"
											>
												{apps.length - 2}+
											</Box>;
										} else {
											return null;
										}
									} else {
										return (
											<Image
												boxSize={`${30}px`}
												borderRadius="5px"
												src={app.thumbnail}
												width="20px"
												height="20px"
												mr="12px"
											/>
										);
									}
							  })
							: null}
					</Box>
				</Box>
				{menu}
			</Box>
		</>
	);
};

export const ItemCardSkeleton = ({ scale = 1 }) => {
	return (
		<Box
			bg={useColorModeValue('white', '#424242')}
			height={`${170 * scale}px`}
			// width={`${620 * scale}px`}
			// marginRight="0.9rem"
			// marginBottom="0.9rem"
			borderRadius="10px"
			border="solid 2px"
			display="flex"
			_hover={{ borderColor: 'purple.500' }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
		>
			<Box
				display="flex"
				width={`${295 * scale}px`}
				alignItems="center"
				justifyContent="center"
			></Box>
			<Box
				width="100%"
				display="flex"
				height="100%"
				alignItems="left"
				flexDirection="column"
				borderBottomRightRadius="5px"
				borderBottomLeftRadius="5px"
				bg={useColorModeValue('#f9f9f9', '#525252')}
				fontSize="0.8rem"
				borderTop="solid 1px"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				color={useColorModeValue('#555555', 'white')}
				paddingX={`${1.4 * scale}rem`}
				paddingTop={`${1 * scale}rem`}
				opacity="0.9"
			>
				<Box
					width="55%"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					height="15px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					opacity="0.9"
					marginY={`${8 * scale}px`}
				></Box>
				<Box
					width="40%"
					display="flex"
					height="15px"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingX={`${0.5 * scale}rem`}
					paddingTop={`${0.4 * scale}rem`}
					opacity="0.9"
					marginY={`${8 * scale}px`}
				/>
				<Box
					width="30%"
					display="flex"
					height="15px"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingX={`${0.5 * scale}rem`}
					paddingTop={`${0.4 * scale}rem`}
					opacity="0.9"
					marginY={`${8 * scale}px`}
				/>
			</Box>
		</Box>
	);
};

export const ItemCardHome = ({
	onClick,
	thumbnail,
	heading,
	description,
	count = 0,
	moduleDependencies,
	isInstalledGlobal,
	version,
	scale = 1,
}) => {
	return (
		<>
			<Box
				bg={useColorModeValue('white', '#424242')}
				height={`${150 * scale}px`}
				width={`${320 * scale}px`}
				marginRight="0.6rem"
				marginBottom="0.6rem"
				borderRadius="10px"
				border="solid 2px"
				cursor="pointer"
				onClick={onClick}
				_hover={{ borderColor: 'purple.500' }}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
			>
				<Box
					height="40%"
					display="flex"
					flexDirection="column"
					width={'100%'}
					alignItems="flex-start"
					justifyContent="center"
					backgroundPosition="center"
					backgroundSize="cover"
					borderTopRadius="10px"
					bg="#4a4a4a"
					// opacity="70%"
				>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="center"
						height={`${30}px`}
						bg="none"
						opacity="0.9"
						borderTopLeftRadius="5px"
						paddingLeft="0.4rem"
					>
						{moduleDependencies && moduleDependencies.length > 0
							? moduleDependencies.map((mod, idx) => {
									if (moduleDependencies.length > 3) {
										if (idx < 2) {
											return (
												<Image
													boxSize={`${30}px`}
													border="5px"
													src={mod.module.thumbnail}
												/>
											);
										} else if (idx === 2) {
											<Box
												boxSize={`${20 * scale}px`}
												margin="7px"
												borderRadius="full"
												textAlign="center"
												display="flex"
												flexDirection="column"
												justifyContent="center"
												fontSize={`${0.8 * scale}px`}
												bg="#464646"
											>
												{moduleDependencies.length - 2}+
											</Box>;
										} else {
											return null;
										}
									} else {
										return (
											<Image
												boxSize={`${30}px`}
												border="5px"
												src={mod.module.thumbnail}
											/>
										);
									}
							  })
							: null}
					</Box>
				</Box>
				<Box
					width="100%"
					justifyContent="space-between"
					borderBottomRightRadius="8px"
					borderBottomLeftRadius="8px"
					//bg={useColorModeValue('#f9f9f9', '#565656')}
					bg="#464646"
					height="33%"
					borderTop="solid 1px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingLeft="0.7rem"
					paddingRight="0.2rem"
					paddingTop="0.4rem"
				>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="space-between"
					>
						<Box
							fontSize={`${1.15 * scale}rem`}
							marginBottom={`${0.5 * scale}rem`}
							marginLeft="0.1rem"
						>
							{heading}
						</Box>
					</Box>
					<Box
						display="flex"
						flexDirection="row"
						justifyContent="flex-start"
					>
						<TagWithIconSmall
							text={'Maya Official'}
							icon={<GoVerified />}
							scale={scale}
						/>
						<TagWithIconSmall
							text={`${count} commands`}
							icon={<FiCommand />}
							scale={scale}
						/>
						{version ? (
							<TagWithIconSmall
								text={version}
								icon={<FiGitPullRequest />}
								scale={scale}
							/>
						) : null}
						{isInstalledGlobal ? (
							<TagWithIconSmall
								text={'installed'}
								icon={<FiDownload />}
								scale={scale}
							/>
						) : null}
					</Box>
				</Box>
			</Box>
		</>
	);
};

// export const ItemCardLibrary = ({
// 	onClick,
// 	thumbnail,
// 	heading,
// 	description,
// 	count = 0,
// 	moduleDependencies,
// 	isInstalledGlobal,
// 	pack,
// 	packs,
// 	setPacks,
// 	scale = 1,
// }) => {
// 	return (
// 		<Box
// 			bg={useColorModeValue('white', '#464646')}
// 			height={`${220 * scale}px`}
// 			width={`${300 * scale}px`}
// 			marginRight="0.6rem"
// 			marginBottom="0.6rem"
// 			borderRadius="10px"
// 			border="solid 2px"
// 			cursor="pointer"
// 			_hover={{ borderColor: 'purple.500' }}
// 			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
// 			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
// 			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
// 		>
// 			<Box
// 				height="67%"
// 				display="flex"
// 				flexDirection="column"
// 				width={`${295 * scale}px`}
// 				alignItems="flex-end"
// 				justifyContent="flex-end"
// 				backgroundImage={thumbnail}
// 				backgroundPosition="center"
// 				backgroundSize="cover"
// 				borderTopRadius="10px"
// 				onClick={onClick}
// 				// opacity="70%"
// 			>
// 				<Box
// 					display="flex"
// 					flexDirection="row"
// 					justifyContent="center"
// 					height={`${30}px`}
// 					bg="#292929"
// 					opacity="0.9"
// 					borderTopLeftRadius="5px"
// 				>
// 					{moduleDependencies && moduleDependencies.length > 0
// 						? moduleDependencies.map((mod, idx) => {
// 								if (moduleDependencies.length > 3) {
// 									if (idx < 2) {
// 										return (
// 											<Image
// 												boxSize={`${30}px`}
// 												f
// 												border="5px"
// 												src={mod.thumbnail}
// 											/>
// 										);
// 									} else if (idx === 2) {
// 										<Box
// 											boxSize={`${20 * scale}px`}
// 											margin="10px"
// 											borderRadius="full"
// 											textAlign="center"
// 											display="flex"
// 											flexDirection="column"
// 											justifyContent="center"
// 											fontSize={`${0.8 * scale}px`}
// 											bg="#464646"
// 										>
// 											{moduleDependencies.length - 2}+
// 										</Box>;
// 									} else {
// 										return null;
// 									}
// 								} else {
// 									return (
// 										<Image
// 											boxSize={`${30}px`}
// 											border="5px"
// 											src={mod.thumbnail}
// 										/>
// 									);
// 								}
// 						  })
// 						: null}
// 				</Box>
// 			</Box>
// 			<Box
// 				width="100%"
// 				justifyContent="space-between"
// 				borderBottomRightRadius="8px"
// 				borderBottomLeftRadius="8px"
// 				//bg={useColorModeValue('#f9f9f9', '#565656')}
// 				bg="#464646"
// 				height="33%"
// 				borderTop="solid 1px"
// 				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
// 				color={useColorModeValue('#555555', 'white')}
// 				paddingLeft="0.7rem"
// 				paddingRight="0.2rem"
// 				paddingTop="0.4rem"
// 			>
// 				<Box
// 					display="flex"
// 					flexDirection="row"
// 					justifyContent="space-between"
// 				>
// 					<Box
// 						fontSize={`${1.15 * scale}rem`}
// 						marginBottom={`${0.3 * scale}rem`}
// 					>
// 						{heading}
// 					</Box>

// 					<Box
// 						height="100%"
// 						display="flex"
// 						justifyContent="flex-start"
// 						alignItems="flex-start"
// 						paddingTop="5px"
// 					>
// 						<CardMenu pack={pack} packs={packs} setPacks={setPacks} />
// 					</Box>
// 				</Box>
// 				<Box display="flex" flexDirection="row" justifyContent="flex-start">
// 					<TagWithIconSmall
// 						text={pack.currentVersion}
// 						icon={<FiGitPullRequest />}
// 						scale={scale}
// 					/>
// 					<TagWithIconSmall
// 						text={`${count} skills`}
// 						icon={<FiGitBranch />}
// 						scale={scale}
// 					/>
// 					{isInstalledGlobal ? (
// 						<TagWithIconSmall
// 							text={'installed'}
// 							icon={<FiDownload />}
// 							scale={scale}
// 						/>
// 					) : null}
// 				</Box>
// 			</Box>
// 		</Box>
// 	);
// };

export const ItemCardSkeletonHome = ({ scale = 1 }) => {
	return (
		<Box
			bg={useColorModeValue('white', '#424242')}
			height={`${150 * scale}px`}
			width={`${300 * scale}px`}
			marginRight="0.9rem"
			marginBottom="0.9rem"
			borderRadius="10px"
			border="solid 2px"
			_hover={{ borderColor: 'purple.500' }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
		>
			<Box
				height="67%"
				display="flex"
				width={`${295 * scale}px`}
				alignItems="center"
				justifyContent="center"
			></Box>
			<Box
				width="100%"
				display="flex"
				alignItems="left"
				flexDirection="column"
				justifyContent="space-between"
				borderBottomRightRadius="5px"
				borderBottomLeftRadius="5px"
				bg={useColorModeValue('#f9f9f9', '#525252')}
				height="33%"
				fontSize="0.8rem"
				borderTop="solid 1px"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				color={useColorModeValue('#555555', 'white')}
				paddingX={`${1 * scale}rem`}
				paddingTop={`${0.2 * scale}rem`}
				opacity="0.9"
			>
				<Box
					width="55%"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					height="20%"
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingX={`${0.5 * scale}rem`}
					paddingTop={`${0.4 * scale}rem`}
					opacity="0.9"
					marginY={`${10 * scale}px`}
				></Box>
				<Box
					width="40%"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					height="20%"
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingX={`${0.5 * scale}rem`}
					paddingTop={`${0.4 * scale}rem`}
					opacity="0.9"
					marginY={`${10 * scale}px`}
				></Box>
			</Box>
		</Box>
	);
};

export default HorizontalBoxCardWithDescription;

//Older version
