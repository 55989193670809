import React from 'react';
import {
	Box,
	useColorMode,
	IconButton,
	useColorModeValue,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalBody,
	ModalContent,
	ModalCloseButton,
	LightMode,
	Button,
	Tooltip,
	Text,
	Alert,
	AlertIcon,
	useToast,
} from '@chakra-ui/react';
import { DeleteIcon, SmallAddIcon, InfoIcon, EditIcon } from '@chakra-ui/icons';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import InputComponent from '../Reusable/Input';

//Functions
import getConfigProfiles from '../../functions/configuration/getConfigProfiles';
import saveConfigProfile from '../../functions/configuration/saveConfigProfile';
import authenticateConfiguration from '../../functions/configuration/authenticateConfiguration';
import removeConfigProfile from '../../functions/configuration/removeConfigProfile';
import isUsingKeytar from '../../functions/settings/keytar/isUsingKeytar';

import GetProfileSlug from '../../util/ProfileSlug';
import randomDefaultProfileName from '../../util/RandomDefaults/newprofile';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import MayaSkeleton from '../../util/ContentLoaders/MayaSkeleton';
import isElectron from 'is-electron';

const ConfigurationProfile = ({
	name,
	updated,
	selected,
	onClick,
	moduleId,
	moduleName,
	referenceId,
	onRemove,
	configurationType,
	moduleList,
	setModuleList,
}) => {
	const { colorMode } = useColorMode();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [removeBtnLoading, setRemoveBtnLoading] = React.useState(false);
	const toast = useToast();
	const borderColor = {
		light: '#f2f2f2',
		dark: '#494949',
	};
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: '#444444',
	};

	const descColor = {
		light: '#888383',
		dark: '#c3c3c3',
	};
	const handleDelete = () => {
		setRemoveBtnLoading(true);
		//TODO Handle OAuth Error here
		removeConfigProfile(referenceId, moduleId, moduleName).then((res) => {
			if (res.error) {
				setRemoveBtnLoading(false);
				toast({
					title: 'Error occurred',
					description: 'Something went wrong while deleting profile.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			} else {
				setRemoveBtnLoading(false);
				onRemove();
				onClose();
			}
		});
	};
	return (
		<Box
			borderRadius="5px"
			border={`solid 2px`}
			marginTop="15px"
			borderColor={borderColor[colorMode]}
			bg={selected ? 'purple.500' : bgColor[colorMode]}
			overflow={'hidden'}
			cursor={'pointer'}
			boxShadow={'rgba(0, 0, 0, 0.09) 1px 2px 4px'}
			display="flex"
			justifyContent="space-between"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			alignItems="center"
			paddingX="1rem"
			paddingY="0.5rem"
			onClick={() => {
				onClick();
			}}
		>
			<Box fontFamily="body" ml="1rem">
				<Box
					fontSize="0.9rem"
					fontWeight="600"
					color={useColorModeValue('#444444', 'white')}
				>
					{name}
				</Box>
				<Box
					fontSize="0.8rem"
					color={selected ? 'white' : descColor[colorMode]}
				>
					{updated}
				</Box>
			</Box>
			<Box>
				<AddOrEditButton
					configurationType={configurationType}
					moduleId={moduleId}
					moduleName={moduleName}
					moduleList={moduleList}
					setModuleList={setModuleList}
					flag={false}
					referenceId={referenceId}
					profileName={name}
					mode={'edit'}
				/>
				<Tooltip label="Delete Profile" aria-label="A tooltip">
					<IconButton
						aria-label="cancel"
						icon={<DeleteIcon />}
						size="sm"
						borderRadius="5px"
						onClick={() => {
							onOpen();
						}}
					/>
				</Tooltip>
			</Box>
			<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					height="180px"
					maxWidth="30rem"
				>
					<ModalCloseButton
						right="2"
						border="none"
						cursor
						_focus={{ outline: 'none' }}
					/>
					<ModalBody paddingX="0">
						<HeaderWithDescription
							header={`Remove : ${name}`}
							desc={updated}
							paddingX="1rem"
						/>
						<Box paddingX="1rem">
							<Box marginBottom="20px">
								This will remove this configuration profile and revoke
								all associated permissions, if any.
							</Box>
							<LightMode>
								<Button
									colorScheme="purple"
									size="xs"
									mr="2"
									borderRadius="5px"
									variant={'solid'}
									paddingX="0.9rem"
									onClick={handleDelete}
									isLoading={removeBtnLoading}
									loadingText={'Removing...'}
								>
									Continue
								</Button>
							</LightMode>
						</Box>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</Box>
	);
};

const AddOrEditButton = ({
	configurationType,
	moduleId,
	moduleName,
	moduleList,
	setModuleList,
	profileName,
	referenceId,
	flag,
	mode,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [useKeytar, setUseKeytar] = React.useState(true);
	const [isLoading, setLoading] = React.useState(false);
	const [formData, setFormData] = React.useState({
		name:
			mode === 'add'
				? flag
					? `${configurationType.resource}-default`
					: randomDefaultProfileName(configurationType.resource)
				: profileName,
	});
	const [authComplete, setAuthComplete] = React.useState(false);
	let { slug } = GetProfileSlug();
	const toast = useToast();

	React.useEffect(() => {
		isUsingKeytar().then((res) => {
			console.log('isUsingKeytar', res);
			setUseKeytar(res);
		});
	}, []);

	React.useEffect(() => {
		console.log('flag value changed', flag);
		if (flag) {
			setTimeout(onOpen, 1000);
		}
	}, [flag]);

	const handleAuthenticateCall = () => {
		setLoading(true);
		formData['mode'] = mode;
		setTimeout(() => {}, 3000);
		authenticateConfiguration({ configurationType, formData }).then(
			async (res) => {
				if (res.error) {
					setLoading(false);
					toast({
						title: 'Error occurred',
						description:
							'Something went wrong while authenticating profile.',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				} else {
					setFormData({ name: formData.name, ...res.results });
					setAuthComplete(true);
					let savedProfileResponse = await saveConfigProfile({
						configurationType,
						formData: { ...formData, ...res.results },
						slug,
						moduleId,
						moduleName,
						mode,
						referenceId,
					});
					if (savedProfileResponse.error) {
						setLoading(false);
						toast({
							title: 'Error occurred',
							description:
								'Something went wrong while authenticating profile.',
							status: 'success',
							duration: 3000,
							isClosable: true,
						});
					} else {
						let profiles = await getConfigProfiles({
							slug: slug,
							moduleId: moduleId,
						});
						let temp = moduleList;
						temp.byId[moduleId]['referenceId'] = profiles[0].referenceId;
						temp.byId[moduleId]['profileName'] = profiles[0].name;
						temp.byId[moduleId]['profileList'] = profiles;
						setModuleList({ allIds: temp.allIds, byId: temp.byId });
						onClose();
						setLoading(false);
					}
				}
			}
		);
	};

	return (
		<>
			<Tooltip
				label={mode === 'add' ? 'Add Profile' : 'Reconfigure Profile'}
				aria-label="A tooltip"
			>
				<IconButton
					aria-label="cancel"
					icon={
						mode === 'add' ? (
							<SmallAddIcon boxSize="1.2rem" />
						) : (
							<EditIcon />
						)
					}
					size="sm"
					borderRadius="5px"
					marginRight={mode === 'add' ? '1.1rem' : '0.5rem'}
					onClick={() => {
						onOpen();
					}}
				/>
			</Tooltip>
			<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					height="25rem"
					maxWidth="30rem"
				>
					<ModalCloseButton
						right="2"
						border="none"
						cursor
						_focus={{ outline: 'none' }}
					/>
					<ModalBody
						paddingX="0"
						display="flex"
						justifyContent="space-between"
						flexDirection="column"
						paddingBottom="0"
					>
						<Box>
							<HeaderWithDescription
								header={
									mode === 'add'
										? 'Add New Profile'
										: 'Reconfigure Profile'
								}
								desc={
									mode === 'add'
										? 'Configure a new profile integration'
										: 'Reset and configure this profile again '
								}
								paddingX="1rem"
								tooltip={
									'A configuration profile lets you securely integrate with any 3rd party app or service. You can create multiple configuration profiles for multiple accounts.'
								}
							/>
							<Box>
								<Box paddingX="1rem">
									<InputComponent
										width={'95%'}
										marginBottom="0.5rem"
										onChange={(e) => {
											setFormData({
												...formData,
												name: e.currentTarget.value,
											});
										}}
										isDisabled={mode !== 'add'}
										placeholder={'Enter profile name'}
										default={formData.name}
									/>
									{configurationType.config.type === 'form' ? (
										configurationType.config.variables.map(
											(detail) => {
												return (
													<InputComponent
														width={'95%'}
														key={detail.key}
														placeholder={detail.text}
														marginBottom="0.5rem"
														onChange={(e) => {
															setFormData({
																...formData,
																[detail.key]:
																	e.currentTarget.value,
															});
														}}
													/>
												);
											}
										)
									) : (
										<LightMode>
											<Button
												colorScheme={
													authComplete ? 'green' : 'purple'
												}
												size="sm"
												mr="2"
												borderRadius="5px"
												variant={'solid'}
												paddingX="0.9rem"
												isLoading={isLoading}
												loadingText={
													authComplete
														? 'Saving profile...'
														: 'Authenticating...'
												}
												onClick={() => {
													handleAuthenticateCall();
												}}
												isDisabled={formData.name ? false : true}
											>
												{configurationType.config.variables[0].key}
											</Button>
										</LightMode>
									)}
								</Box>
							</Box>
						</Box>
						{mode === 'edit' ? (
							<Alert
								status="warning"
								margin="5px"
								width="98%"
								borderRadius="5px"
								fontSize="14px"
							>
								<AlertIcon />
								For changes to apply, make sure you restart the runtimes
								which use this module.
							</Alert>
						) : (
							<Alert
								status="warning"
								margin="5px"
								width="98%"
								borderRadius="5px"
								fontSize="14px"
								colorScheme="purple"
							>
								<Box paddingRight="10px">🔐</Box>
								<Box display="inline-block">
									All details are encrypted & stored locally, and never
									leave this device.
									{!useKeytar &&
										' Enable keychain access for enhanced security.'}{' '}
									Learn more{' '}
									<span
										onClick={() => {
											if (isElectron()) {
												const { shell } =
													window.require('electron');
												shell.openExternal(
													'https://mayahq.com/privacy'
												);
											}
										}}
										style={{
											cursor: 'pointer',
											textDecoration: 'underline',
										}}
									>
										here
									</span>
									.
								</Box>
							</Alert>
						)}
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

/**
 * Configure integration profiles
 */

const SetupConfigurationProfiles = ({
	configurationType,
	moduleId,
	moduleName,
	currentBrainId,
	onSelectFn,
	onClose,
	heightLimit,
	moduleList,
	setModuleList,
	isModuleLoading,
}) => {
	const selectedProfileReducer = (state, action) => {
		return moduleList.byId[moduleId].referenceId;
	};
	const profilesReducer = (state, action) => {
		return moduleList.byId[moduleId].profileList;
	};
	const [selectedProfile, setSelectedProfile] = React.useReducer(
		selectedProfileReducer,
		moduleList.byId[moduleId].referenceId || null
	);
	const [profiles, setProfiles] = React.useReducer(
		profilesReducer,
		moduleList.byId[moduleId].profileList || []
	);
	const skeletonList = [1, 2, 3, 4];
	const { colorMode } = useColorMode();
	const borderColor = {
		light: '#f2f2f2',
		dark: '#494949',
	};
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: '#444444',
	};
	onSelectFn = (id, name) => {
		let temp = moduleList;
		temp.byId[moduleId]['referenceId'] = id;
		temp.byId[moduleId]['profileName'] = name;
		setModuleList({ allIds: temp.allIds, byId: temp.byId });
		console.log('onselect function');
	};
	// TODO add onloading skeleton here
	React.useEffect(() => {
		console.log('useEffect triggered');
		setProfiles(moduleList.byId[moduleId].profileList);
		setSelectedProfile(moduleList.byId[moduleId].referenceId);
	}, [moduleId, moduleList]);

	return (
		<Box>
			<HeaderWithDescription
				header={'Setup Configurations'}
				desc={'Select, edit or add new configurations/credentials.'}
				tooltip={
					'A configuration profile lets you securely integrate with any 3rd party app or service. You can create multiple configuration profiles for multiple accounts.'
				}
				marginBottom="0"
				rightbutton={
					<AddOrEditButton
						configurationType={configurationType}
						moduleId={moduleId}
						moduleName={moduleName}
						moduleList={moduleList}
						setModuleList={setModuleList}
						flag={!isModuleLoading && profiles.length === 0}
						mode={'add'}
					/>
				}
			/>
			<Box height={heightLimit ? heightLimit : '100%'} overflow="auto">
				{isModuleLoading ? (
					skeletonList.map(() => (
						<MayaSkeleton
							borderRadius="5px"
							border={`solid 2px`}
							marginTop="15px"
							borderColor={borderColor[colorMode]}
							bg={bgColor[colorMode]}
							overflow={'hidden'}
							cursor={'pointer'}
							boxShadow={'rgba(0, 0, 0, 0.09) 1px 2px 4px'}
							transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
							alignItems="center"
							paddingX="0.5rem"
							paddingY="0.5rem"
							marginY="1rem"
							height="3.5rem"
						/>
					))
				) : profiles.length > 0 ? (
					profiles.map((profile) => {
						return (
							<ConfigurationProfile
								key={profile.referenceId}
								referenceId={profile.referenceId}
								name={profile.name}
								updated={profile.updated}
								moduleId={moduleId}
								moduleName={moduleName}
								moduleList={moduleList}
								setModuleList={setModuleList}
								selected={profile.referenceId === selectedProfile}
								configurationType={configurationType}
								onClick={() => {
									onSelectFn(profile.referenceId, profile.name);
								}}
								onRemove={() => {
									let temp = moduleList;
									temp.byId[moduleId]['referenceId'] = undefined;
									temp.byId[moduleId]['profileName'] = '';
									temp.byId[moduleId]['profileList'] = profiles.filter(
										(p) => p.referenceId !== profile.referenceId
									);
									setModuleList({
										allIds: temp.allIds,
										byId: temp.byId,
									});
								}}
							/>
						);
					})
				) : (
					<Box
						textAlign="center"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						color="whiteAlpha.700"
						height="100%"
					>
						No profiles found. <br></br>Please create a new profile to
						configure module.
					</Box>
				)}
			</Box>
		</Box>
	);
};

export default SetupConfigurationProfiles;
