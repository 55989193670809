import { Box } from '@chakra-ui/layout';

/**
 * `----- or ------` Divider component
 * @returns
 */
const OrDivider = () => {
	return (
		<Box display="flex" alignItems="center" w="full">
			<hr style={{ flex: 1 }} />
			<Box
				textAlign="center"
				color="purple.100"
				fontSize="xs"
				fontWeight="thin"
				mx="1"
			>
				or
			</Box>
			<hr style={{ flex: 1 }} />
		</Box>
	);
};

export default OrDivider;
