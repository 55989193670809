import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

//Components
import { StorePackItem, StorePackItemCard } from './StorePackItem';
import {
	Box,
	useColorMode,
	WrapItem,
	Wrap,
	SimpleGrid,
} from '@chakra-ui/react';
import { ItemCardSkeleton } from '../../Reusable/HorizontalBoxCardWithDescription';
//Redux
import { connect } from 'react-redux';
import { updateBrainById } from '../../../redux/actions';
import {
	addPublishedSkillPacks,
	clearPublishedSkillPacks,
} from '../../../redux/actions';

//Utils
import { isPublishedSkillPackInstalled, normalize } from '../../../util/Misc';
import GetProfileSlug from '../../../util/ProfileSlug';

//Functions
import getStoreSkillPacks from '../../../functions/store/getStoreSkillPacks';
import querySearch from '../../../util/Search';
import { StorePackSkeleton } from './StorePackNewCard';

const StorePackList = ({
	query,
	publishedSkillPacks,
	categories,
	apps,
	platforms,
	brainIds,
	brains,
	height,
	onClickFn,
	selectedPackId,
	wholeItemActive,
	currentBrainId,
	updateBrainById,
	addPublishedSkillPacks,
	clearPublishedSkillPacks,
}) => {
	const loadingList = [1, 2, 3, 4, 5, 6, 7, 8];
	const { colorMode } = useColorMode();
	const hoverColor = {
		light: 'dashboard.bg_light.200',
		dark: 'maya_dark.150',
	};
	const [packList, setPackList] = React.useState([]);

	const loc = useLocation();
	let URLQuery = new URLSearchParams(loc.search);
	const [id, setId] = React.useState(() => {
		if (loc.pathname === '/store/skills') {
			return URLQuery.get('id');
		} else return null;
	});
	const [isLoading, setLoading] = React.useState(true);
	const { slug } = GetProfileSlug();

	React.useEffect(() => {
		setLoading(true);
		getStoreSkillPacks({
			slug: slug,
			_id: id,
			categories,
			platforms,
			apps,
		}).then((res) => {
			let list = Array.isArray(res) ? res : [res];
			setPackList(list);
			addPublishedSkillPacks(normalize(list), true);
			setLoading(false);
		});
	}, [slug, id, categories, platforms, apps]);

	React.useEffect(() => {
		let filteredPacks = querySearch(
			publishedSkillPacks,
			query,
			'publishedSkillPacks'
		);
		let finalList = [];
		for (let id of filteredPacks.allIds) {
			finalList.push(filteredPacks.byId[id]);
		}
		setPackList(finalList);
	}, [query, publishedSkillPacks]);

	const checkIsInstalled = (currentBrainId, packId) => {
		// console.log('checkIsInstalled', currentBrainId, packId);
		let check;
		if (currentBrainId && brains.byId[currentBrainId]) {
			check =
				_.findIndex(
					brains.byId[currentBrainId].publishedSkillPacks,
					(pack) => {
						return pack.publishedSkillPack === packId;
					}
				) !== -1;
		} else {
			check = false;
		}
		return check;
	};

	return (
		<Box
			// bg='blue.300'
			position="relative"
			width="100%"
			display="flex"
			flex="1 1 auto"
			overflow="auto"
		>
			<Box
				// bg='red'
				flex="1 1 auto"
				borderRadius="10px"
				position="absolute"
				width="100%"
			>
				{!isLoading && packList.length === 0 ? (
					<Box
						textAlign="center"
						height="80vh"
						width="100%"
						display="flex"
						flexDirection="column"
						justifyContent="center"
					>
						<Box>Couldn't find what you were looking for?</Box>
						<Box
							color="purple.300"
							onClick={() => {
								window.Intercom('show');
							}}
							_hover={{ color: 'purple.200' }}
							cursor="pointer"
						>
							Suggest a skill
						</Box>
					</Box>
				) : null}
				{/* !isLoading
						? packList.length > 0 */}
				<SimpleGrid
					overflowY="auto"
					width="100%"
					spacing="24px"
					minChildWidth="320px"
				>
					{!isLoading
						? packList.length > 0 &&
						  packList.map((publishedSkillPack, i) => {
								return (
									<StorePackItemCard
										key={i}
										publishedSkillPack={publishedSkillPack}
										currentBrainId={currentBrainId}
										isInstalled={checkIsInstalled(
											currentBrainId,
											publishedSkillPack._id
										)}
										isInstalledGlobal={isPublishedSkillPackInstalled(
											brains,
											publishedSkillPack._id
										)}
									/>
								);
						  })
						: loadingList.map((item, idx) => {
								return <StorePackSkeleton key={idx} />;
						  })}
				</SimpleGrid>
			</Box>
		</Box>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	let { publishedSkillPacks } = state;
	let { query } = state.navigation;
	const brainIds = brains.allIds;
	return { brainIds, brains, query, publishedSkillPacks };
};

export default connect(mapStateToProps, {
	updateBrainById,
	addPublishedSkillPacks,
	clearPublishedSkillPacks,
})(StorePackList);
