import actionTypes from '../actionTypes';
import { v4 as uuidv4 } from 'uuid';
import { commandHistoryLogIntentEvent } from './commandHistory';
import analytics from '../../util/Analytics';

/**
 * Process components (from entities) and convert them into intentObjectArray
 * @param {import('../reducers/cardUIV2').CuiIntentComponent[]} components
 * @param {import('../reducers/cardUIV2').default['commandBar']['searchArea']['placeholderComponents']['byId']} componentValues
 * @param {string} url
 * @returns
 */
const processComponents = (components, componentValues, url) => {
	return components.map((component) => {
		const values = componentValues[component.id];
		if (component.type === 'prompt') {
			return {
				type: 'text',
				value: component.value,
				meta: values?.meta,
			};
		}
		if (component.type === 'custom') {
			return {
				type: 'placeholder',
				value: values?.inputValue,
				key: component.value,
				keyType: 'custom',
				endpointUrl: url + component.endpoint,
				meta: values?.meta,
			};
		} else {
			return {
				type: 'placeholder',
				value: values?.inputValue,
				key: component.value,
				keyType: component.type,
				meta: values?.meta,
			};
		}
	});
};

/**
 * Set if the command bar is visible or not
 * @param {Boolean} visibility
 * @returns
 */
export const setCommandBarVisibility = (visibility) => {
	return {
		type: actionTypes.CUIV2_SET_COMMANDBAR_VISIBLE,
		payload: {
			visibility,
		},
	};
};

/**
 *	Add or Update entities to redux store.
 *	- Uses **shallow merging** using spread(...) operator.
 * - Use for setting entities at the top level only, do not use for nested values!
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_ENTITIES_UPSERT']} payload
 * @returns
 */
export const entitiesUpsert = (payload) => {
	return {
		type: actionTypes.CUIV2_ENTITIES_UPSERT,
		payload,
	};
};

/**
 * Dispatched on Search Cancel
 * @returns
 */
export const searchCancel = () => {
	return {
		type: actionTypes.CUIV2_SEARCH_CANCEL,
		payload: {},
	};
};

/**
 *	Dispatched on search input keydown event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SEARCH_INPUT_KEYDOWN']} payload
 * @returns
 */
export const searchInputKeydown =
	(payload) =>
	/**
	 * @param {*} dispatch
	 * @param {() => { cardUIV2: import('../reducers/cardUIV2').default }} getState
	 * @returns
	 */
	(dispatch, getState) => {
		/** for analytics */
		const { type: currentTab, mode: suggestionsMode } =
			getState().cardUIV2.commandBar.suggestionsArea;
		const analyticsPayload = {
			key: payload.keyType,
			suggestionsMode,
			suggestionsType: currentTab,
		};
		if (payload.keyType === 'TAB_CYCLE')
			analyticsPayload.toSuggestionsType = payload.tabName;
		analytics.track('Command Bar: searchInputKeyDown', analyticsPayload);
		/** dispatch the keyDown Redux event */
		dispatch({
			type: actionTypes.CUIV2_SEARCH_INPUT_KEYDOWN,
			payload,
		});
	};

/**
 *	Dispatched during/after/on search submit
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SEARCH_SUBMIT']} payload
 * @returns
 */
export const searchSubmit =
	(payload) =>
	/**
	 *
	 * @param {*} dispatch
	 * @param {() => { cardUIV2: import('../reducers/cardUIV2').default }} getState
	 * @returns
	 */
	(dispatch, getState) => {
		/** Get required data for command history action */
		const selectedIntentId =
			getState().cardUIV2.commandBar.suggestionsArea.selectedSearchItem;
		/**
		 * @type {import('../reducers/cardUIV2').CuiIntent}
		 */
		const selectedIntent =
			getState().cardUIV2.entities.intents.byId[selectedIntentId];
		const placeholderComponentValues =
			getState().cardUIV2.commandBar.searchArea.placeholderComponents;
		const allComponents = getState().cardUIV2.entities.components;
		const intentComponents = placeholderComponentValues.allIds.map(
			(compId) => allComponents.byId[compId]
		);
		if (!selectedIntent) {
			/**
			 * empty return as the selected intent is invalid! nothing to dispatch
			 * only log the error. This could happen if axios call got cancelled due to
			 * network issues or failure that returned an error even if user has moved to
			 * another task on the command bar. Thus we do not want to dispatch any new
			 * changes due to this.
			 */
			console.error(`selectedIntent is invalid`); // log error
			return;
		}

		/** for analytics */
		const intentString = intentComponents.map((comp) => comp.value).join(' ');
		const analyticsPayload = {
			intent: intentString,
			status: payload.status,
		};
		if (payload.status !== 'start')
			analyticsPayload.card = !!payload.metadata?.card;
		analytics.track('Command Run', analyticsPayload);

		/**
		 * @type {import('../reducers/types/commandHistoryReducer').IntentObject}
		 */
		const intentObject = {
			intent: processComponents(
				intentComponents,
				placeholderComponentValues.byId,
				selectedIntent?.baseUrl
			),
			submitDetails: {
				robotId: selectedIntent?.brainId,
				robotStatus: 'STARTED',
				type: 'http',
				url: selectedIntent?.baseUrl,
				trigger: selectedIntent?.endpoint,
			},
		};
		/** Dispatch the history log event with calculated data above */
		dispatch(
			commandHistoryLogIntentEvent(
				intentObject,
				payload.status,
				payload.metadata
			)
		);
		/** dispatch the actual submit event */
		dispatch({
			type: actionTypes.CUIV2_SEARCH_SUBMIT,
			payload,
		});
	};

/**
 *	Dispatched on search change
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SEARCH_CHANGE']} payload
 * @returns
 */
export const searchChange = (payload) => {
	return {
		type: actionTypes.CUIV2_SEARCH_CHANGE,
		payload,
	};
};

/**
 *	Dispatched on Close(addon) btn press
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SEARCH_ADDON_CLOSE']} payload
 * @returns
 */
export const searchAddonClose = (payload) => {
	return {
		type: actionTypes.CUIV2_SEARCH_ADDON_CLOSE,
		payload,
	};
};

/**
 *	Dispatched on clicking any Tab to change search type/suggestions
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SUGGESTIONS_TAB_CLICK']} payload
 * @returns
 */
export const suggestionsTabClick =
	(payload) =>
	/**
	 * @param {*} dispatch
	 * @param {() => { cardUIV2: import('../reducers/cardUIV2').default }} getState
	 * @returns
	 */
	(dispatch, getState) => {
		/** for analytics */
		const { type: currentTab, mode: suggestionsMode } =
			getState().cardUIV2.commandBar.suggestionsArea;
		const analyticsPayload = {
			suggestionsMode,
			suggestionsType: currentTab,
			toSuggestionsType: payload.tabName,
		};
		analytics.track('Command Bar: suggestionsTabClick', analyticsPayload);
		/** dispatch the main Redux event */
		dispatch({
			type: actionTypes.CUIV2_SUGGESTIONS_TAB_CLICK,
			payload,
		});
	};

/**
 *	Dispatched when instructions need to be vibrated
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_VIBRATE_INSTRUCTIONS_CHANGE']} payload
 * @returns
 */
export const vibrateInstructionsChange = (payload) => {
	return {
		type: actionTypes.CUIV2_VIBRATE_INSTRUCTIONS_CHANGE,
		payload,
	};
};

/**
 *	Dispatched when placeholder search is cancelled (eg: on Esc key press)
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_CANCEL']} payload
 * @returns
 */
export const placeholderSearchCancel = (payload) => {
	return {
		type: actionTypes.CUIV2_PLACEHOLDER_SEARCH_CANCEL,
		payload,
	};
};

/**
 *	Dispatched when placeholder search is init/mounted
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_INIT']} payload
 * @returns
 */
export const placeholderSearchInit = (payload) => {
	return {
		type: actionTypes.CUIV2_PLACEHOLDER_SEARCH_INIT,
		payload,
	};
};

/**
 *	Dispatched when placeholder search input values or selected placeholder are changed
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_CHANGE']} payload
 * @returns
 */
export const placeholderSearchChange = (payload) => {
	return {
		type: actionTypes.CUIV2_PLACEHOLDER_SEARCH_CHANGE,
		payload,
	};
};

/**
 *	Dispatched when placeholder boolean value is changed
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SET_PLACEHOLDER_LOADING']} payload
 * @returns
 */
export const setPlaceholderLoading = (payload) => {
	return {
		type: actionTypes.CUIV2_SET_PLACEHOLDER_LOADING,
		payload,
	};
};

/**
 *	Dispatched when selected placeholder is toggled to the next placeholder
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SEARCH_NEXT']} payload
 * @returns
 */
export const placeholderSearchNext = (payload) => {
	return {
		type: actionTypes.CUIV2_PLACEHOLDER_SEARCH_NEXT,
		payload,
	};
};

/**
 *	Dispatched on requiring mode change in suggestions Area
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_SUGGESTIONS_MODE_CHANGE']} payload
 * @returns
 */
export const suggestionsModeChange = (payload) => {
	return {
		type: actionTypes.CUIV2_SUGGESTIONS_MODE_CHANGE,
		payload,
	};
};

/**
 *	Dispatched when placeholder suggestions are changed
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_SUGGESTIONS_CHANGE']} payload
 * @returns
 */
export const placeholderSuggestionsChange = (payload) => {
	return {
		type: actionTypes.CUIV2_PLACEHOLDER_SUGGESTIONS_CHANGE,
		payload,
	};
};

/**
 *	Dispatched on placeholder input keyDown event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_PLACEHOLDER_INPUT_KEYDOWN']} payload
 * @returns
 */
export const placeholderInputKeydown =
	(payload) =>
	/**
	 * @param {*} dispatch
	 * @param {() => { cardUIV2: import('../reducers/cardUIV2').default }} getState
	 * @returns
	 */
	(dispatch, getState) => {
		/** for analytics */
		const analyticsPayload = {
			key: payload.keyType,
		};
		analytics.track('Command Bar: placeholderInputKeydown', analyticsPayload);
		/** dispatch the main Redux event */
		dispatch({
			type: actionTypes.CUIV2_PLACEHOLDER_INPUT_KEYDOWN,
			payload,
		});
	};

/**
 *	Dispatched when command bar status is to be updated
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_COMMAND_BAR_STATUS_UPDATE']} payload
 * @returns
 */
export const commandBarStatusUpdate = (payload) => {
	return {
		type: actionTypes.CUIV2_COMMAND_BAR_STATUS_UPDATE,
		payload,
	};
};

/**
 *	Dispatched on card maximize event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_CARD_MAXIMIZE']} payload
 * @returns
 */
export const cardMaximize = (payload) => {
	return {
		type: actionTypes.CUIV2_CARD_MAXIMIZE,
		payload,
	};
};

/**
 *	Dispatched on card minimize event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_CARD_MINIMIZE']} payload
 * @returns
 */
export const cardMinimize = (payload) => {
	return {
		type: actionTypes.CUIV2_CARD_MINIMIZE,
		payload,
	};
};

/**
 *	Dispatched on card close event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_CARD_CLOSE']} payload
 * @returns
 */
export const cardClose = (payload) => {
	return {
		type: actionTypes.CUIV2_CARD_CLOSE,
		payload,
	};
};

/**
 *	Dispatched on card (web view) navigation event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_CARD_NAVIGATE']} payload
 * @returns
 */
export const cardNavigate = (payload) => {
	return {
		type: actionTypes.CUIV2_CARD_NAVIGATE,
		payload,
	};
};

/**
 *	Dispatched on store search submit event
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_STORE_SEARCH_SUBMIT']} payload
 * @returns
 */
export const storeSearchSubmit = (payload) => {
	return {
		type: actionTypes.CUIV2_STORE_SEARCH_SUBMIT,
		payload,
	};
};

/**
 *	Dispatched on startup intents init
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_STARTUP_INTENTS_INIT']} payload
 * @returns
 */
export const startupIntentsInit = (payload) => {
	return {
		type: actionTypes.CUIV2_STARTUP_INTENTS_INIT,
		payload,
	};
};

/**
 *	Dispatched on startup intents load
 * @param {import('../reducers/cardUIV2').CuiActionPayloads['CUIV2_STARTUP_INTENTS_LOAD']} payload
 * @returns
 */
export const startupIntentsLoad = (payload) => {
	return {
		type: actionTypes.CUIV2_STARTUP_INTENTS_LOAD,
		payload,
	};
};
