import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

const newDeveloperRequest = async (formData) => {
	console.log(formData);
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;

	const newDeveloperRequestAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.newDeveloperRequest.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.newDeveloperRequest.PATH_METHOD,
	});

	const newDeveloperRequestAPIResponse = await newDeveloperRequestAPIFn({
		...formData,
		profileSlug,
	});
	return newDeveloperRequestAPIResponse;
};

export default newDeveloperRequest;
