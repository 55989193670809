import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
import isElectron from 'is-electron';
/**
 * Function to add or update library collection from brain runtime
 */
const addToLibraryFromBrain = async (formData) => {
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;

	if (isElectron()) {
		var requestData = {
			profileSlug: profileSlug,
			flow: {
				flows: formData.brain.flows,
				nodes: formData.brain.nodes,
				thumbnail:
					formData.brain.thumbnail ||
					'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg',
			},
			skillPack: {
				name: formData.name,
				description: formData.description,
				version: formData.version,
				thumbnail:
					formData.brain.thumbnail ||
					'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg',
			},
			installedModules: formData.brain.modules,
		};

		// TODO: check for electron
		const addToLibraryAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.addToLibrary.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.addToLibrary.PATH_METHOD,
		});

		const addToLibraryResponse = await addToLibraryAPIFn(requestData);
		console.log('addToLibraryResponse', addToLibraryResponse);
		const addSkillPacktoBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.addSkillPacktoBrain.EVENT,
		});

		const addSkillPacktoBrainResponse = await addSkillPacktoBrainIPCFn({
			brainId: formData.brain._id,
			profileSlug: profileSlug,
			skillPackId: addToLibraryResponse.data.skillPack._id,
			version: formData.version,
		});

		return { addSkillPacktoBrainResponse, addToLibraryResponse };
	}

	// if (addToLibraryResponse["data"]["error"]) {
	//   return addToLibraryResponse["data"];
	// } else {
	//   return addToLibraryResponse["data"]["results"];
	// }
};

export default addToLibraryFromBrain;
