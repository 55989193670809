import React from 'react';
import { Box } from '@chakra-ui/react';
import dotProp from 'dot-prop-immutable';
import _ from 'lodash';
//Components
import HeaderWithDesc from '../../Reusable/HeaderWithDesc';
import {
	DeveloperAccountButton,
	DeveloperAccountTabs,
} from './DeveloperAccount';

import getDeveloperAccounts from '../../../functions/developer/getDeveloperAccounts';
import GetProfileSlug from '../../../util/ProfileSlug';

const DeveloperAccount = ({ devAccountId, setDevAccountId }) => {
	const [details, setDetails] = React.useState({ developerAccounts: [] });
	const [isLoading, setLoading] = React.useState(true);
	const { slug } = GetProfileSlug();
	React.useEffect(() => {
		setLoading(false);
		getDeveloperAccounts({ slug }).then((res) => {
			setDetails({ developerAccounts: res.data });
			if (res.data.length > 0) {
				setDevAccountId(res.data[0]._id);
			}
			setLoading(false);
		});
	}, []);

	const handleAddAccount = (account) => {
		let newDetails = dotProp.set(details, 'developerAccounts', (list) => [
			...list,
			account,
		]);
		setDetails(newDetails);
	};
	const handleDeleteAccount = (accountID) => {
		let index = _.findIndex(details.developerAccounts, (o) => {
			return o._id === accountID;
		});

		if (index !== -1) {
			let newDetails = dotProp.delete(details, `developerAccounts.${index}`);
			setDetails(newDetails);
		}
	};
	return (
		<Box>
			<HeaderWithDesc
				header={'Developer Accounts'}
				desc={
					'Choose or request a new developer account to start contributing to the Maya Store.'
				}
				marginLeft="0.5rem"
			/>

			{details.developerAccounts.length === 0 ? ( //TODO : Check
				<DeveloperAccountButton onSubmit={handleAddAccount} />
			) : (
				<DeveloperAccountTabs
					list={details.developerAccounts}
					onDelete={handleDeleteAccount}
					heading={false}
					deleteButton={true}
					devAccountId={devAccountId}
					setDevAccountId={setDevAccountId}
				/>
			)}
		</Box>
	);
};

export default DeveloperAccount;
