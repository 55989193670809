import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';
//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */
const getBrainById = async ({ brainId, slug }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	var getBrainByIdFn;
	if (!isElectron()) {
		getBrainByIdFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getBrainById.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getBrainById.PATH_METHOD,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	} else {
		getBrainByIdFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getBrainById.EVENT,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	}

	var getBrainByIdResponse = await getBrainByIdFn();
	if (getBrainByIdResponse['data']['error']) {
		return getBrainByIdResponse['data'];
	} else {
		getBrainByIdResponse['data']['results']['_id'] = brainId;
		return getBrainByIdResponse['data']['results'];
	}
};

export default getBrainById;
