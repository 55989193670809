/**
 * API Fetch Utility
 *
 * This utility is used for API requests for the application. It handles responses and sets
 * error objects as well. API requests can be made on component update, or through an
 * explicit request with the fetchResults function.
 */

// Dependencies
import axios from 'axios';
import { createStandaloneToast } from '@chakra-ui/react';
// Config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';
import { setLoggedIn } from '../../redux/actions';

// Toasty
// import Toasty from "../Toasty";
import customTheme from '../../theme/';

// History
import History from '../History';

import GetProfileSlug from '../ProfileSlug';

/**
 * API Fetch Utility
 *
 * A clean utility for making calls to different API endpoints across the application.
 * It can be used to retrieve results or errors from attempting API requests.
 *
 * @TODO cleanup per page & current page
 *
 * @param {String} locale The current locale / language for the user.
 * @param {String} BASEURL Base url for API requests.
 * @param {String} PATH_SEARCH API path to request.
 * @param {String} PATH_METHOD Method for API requests (ex get, post, put, delete).
 * @param {String} PATH_QUERY Query to be sent along with the the API request.
 * @param {Number} PATH_PERPAGE Limit the number of results per page.
 * @param {Object} URL_PARAM Objects containting url query param as key and values and resp values
 * @param {Object} formData Form data to be sent with the API request.
 * @param {Object} initialData Initial data for the calling component.
 */
const APIFunction = ({
	locale,
	BASEURL,
	PATH_SEARCH,
	PATH_METHOD,
	PATH_QUERY = '',
	PATH_PERPAGE = '',
	URL_PARAM = null,
	formData = null,
	initialData = null,
	status = 'online',
	contentType = 'application/json',
}) => {
	// Setup initial state
	const results = initialData;
	const hasError = null;
	const query = PATH_QUERY;
	const perPage = PATH_PERPAGE;
	const Authorization = config.fusionAuth.API_KEY;
	let slug;
	try {
		slug = GetProfileSlug().slug;
	} catch (err) {
		slug = null;
	}

	var urlQuery = '';
	if (URL_PARAM !== null) {
		for (var key in URL_PARAM) {
			urlQuery = urlQuery + key + '=' + URL_PARAM[key] + '&';
		}
		urlQuery = urlQuery.slice(0, -1);
	}

	const toast = createStandaloneToast({ theme: customTheme });
	// const customToast = createStandaloneToast({ theme: yourCustomTheme })

	// Array of methods that require input data.
	const postMethods = ['post', 'put'];

	/**
	 * Fetch results from the API
	 *
	 * Function performs the request to the provided API. Can be called manually,
	 * but it is also called when state changes in useEffect which can be used to
	 * load data when the page first loads.
	 *
	 * @param {Object} formData Form data to be sent with the API request.
	 * @param {Number} searchPerPage Limit the number of results per page.
	 * @param {Number} page Current page for the request.
	 */
	const fetchResults = (formData, searchPerPage = 0, page = 0) => {
		if (status === 'local') {
			return;
		}

		let headers;
		// Setup headers for the request.
		if (slug) {
			headers = {
				locale,
				profileSlug: slug,
			};
		} else {
			headers = {
				locale,
			};
		}

		// Change headers to an empty object if the request is to FusionAuth because
		// it will error out if we try to send the language info.
		if (BASEURL === config.fusionAuth.BASEURL) {
			headers = {
				'Content-Type': contentType,
				Authorization,
			};
		}

		// axios.defaults.withCredentials = true;

		// URL Creation
		var url = `${BASEURL}${PATH_SEARCH}/`;

		if (URL_PARAM !== null && PATH_QUERY !== '') {
			url = url + query + '?' + urlQuery;
		} else if (PATH_QUERY !== '') {
			url = `${BASEURL}${PATH_SEARCH}${query}`;
		} else if (URL_PARAM !== null) {
			url = url + '?' + urlQuery;
		}

		/**
		 * Fetch a response from an API
		 *
		 * Returns a promise after making a request to an API endpoint.
		 */
		return new Promise((resolve, reject) => {
			axios({
				url: url,
				method: PATH_METHOD,
				data: formData,
				withCredentials: true,
				headers,
				validateStatus: function (status) {
					return status < 400 || status === 404;
				},
			})
				.then((result) => {
					// Return from the promise with the result from the API request.
					resolve(result);
				})
				.catch(({ response }) => {
					// Check if the response requires the user to log in again.
					if (response) {
						if (response.data.loginAgain) {
							// Let the user know they cannot access the page.
							console.log('Error:', response.data.message);
							console.log('going to login');
							store.dispatch(setLoggedIn(false));
							/** Sending the user to the login page. */
							/** get current location */
							const currentPathWithoutDomain = `${History.location.pathname}${History.location.search}${History.location.hash}`;
							History.push('/login', {
								restoreLocation: currentPathWithoutDomain, // pass it via state to be restored after login
							});
						} else if (
							Object.keys(response.data).includes('emailVerified') &&
							!response.data.emailVerified
						) {
							History.push('/verify');
						} else {
							// Return from the promise with the error from the API request.
							reject(response);
						}
					} else {
						toast({
							title: 'An error occurred.',
							description:
								'We were unable to connect to the mothership.',
							status: 'error',
							duration: 2000,
							isClosable: true,
						});
					}
				});
		});
	};

	// Return the useful data and functions from the API Fetch utility.
	return fetchResults;
};

// Export the API Fetch utility.
export default APIFunction;
