import * as React from "react";

export const ClockIcon = (props) => {
  return (
    <svg
      width={props.size || 14}
      height={props.size || 14}
      fill="none"
      {...props}
    >
      <path
        d="M7 12.833A5.833 5.833 0 107 1.167a5.833 5.833 0 000 11.666z"
        stroke={props.color || "#474747"}
        strokeWidth={1.167}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 3.5V7l2.333 1.167"
        stroke={props.color || "#474747"}
        strokeWidth={1.167}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const StoreIcon = (props) => {
  return (
    <svg
      width={props.size || 14}
      height={props.size || 14}
      fill="none"
      {...props}
    >
      <path
        d="M3.25 1.083L1.625 3.25v7.583a1.083 1.083 0 001.083 1.084h7.584a1.083 1.083 0 001.083-1.084V3.25L9.75 1.083h-6.5zM1.625 3.25h9.75M8.667 5.417a2.167 2.167 0 01-4.334 0"
        stroke={props.color || "#474747"}
        strokeWidth={1.083}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SkillIcon = (props) => {
  return (
    <svg
      width={props.size || 14}
      height={props.size || 14}
      fill="none"
      {...props}
    >
      <path
        d="M3.25 1.625v6.5M9.75 4.875a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM3.25 11.375a1.625 1.625 0 100-3.25 1.625 1.625 0 000 3.25zM9.75 4.875A4.875 4.875 0 014.875 9.75"
        stroke={props.color || "#474747"}
        strokeWidth={1.083}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const BrainIcon = (props) => {
  return (
    <svg
      width={props.size || 16}
      height={props.size || 16}
      fill="none"
      {...props}
    >
      <path
        d="M6.5 2c-.955 0-1.729.693-1.922 1.594C3.412 3.802 2.5 4.775 2.5 6c0 .123.014.232.031.344A2.923 2.923 0 002 8c0 .899.447 1.655 1.078 2.203A2.2 2.2 0 003 10.75 2.258 2.258 0 005.25 13h.032c.29.565.795 1 1.468 1A1.744 1.744 0 008 13.469 1.745 1.745 0 009.25 14c.674 0 1.178-.435 1.469-1h.031a2.254 2.254 0 002.235-2.063H13c0-.031-.014-.062-.015-.093.002-.031.015-.063.015-.094 0-.185-.066-.344-.11-.515C13.54 9.684 14 8.915 14 8c0-.623-.209-1.181-.531-1.657.018-.11.031-.22.031-.343 0-1.225-.912-2.197-2.078-2.406C11.228 2.694 10.455 2 9.5 2c-.6 0-1.133.28-1.5.703A1.988 1.988 0 006.5 2zm0 1c.559 0 1 .442 1 1v1c0 .834-.666 1.5-1.5 1.5v1c.566 0 1.08-.195 1.5-.516v5.266c0 .42-.33.75-.75.75a.735.735 0 01-.734-.61l-.094-.484-.484.079A1.244 1.244 0 014 10.75c0-.155.025-.299.078-.438.004-.011.012-.02.015-.031.155-.385.491-.666.907-.75l-.203-.984c-.51.1-.97.377-1.297.781A1.979 1.979 0 013 8c0-.45.154-.886.438-1.234l.156-.188-.047-.25A1.429 1.429 0 013.5 6c0-.834.666-1.5 1.5-1.5h.5V4c0-.558.441-1 1-1zm3 0c.559 0 1 .442 1 1v.5h.5c.834 0 1.5.666 1.5 1.5 0 .107-.02.211-.047.328l-.046.25.155.188c.284.348.439.784.438 1.234 0 .6-.256 1.135-.672 1.5a1.903 1.903 0 00-1.265-.5 1.89 1.89 0 00-1.235.453l.641.766a.903.903 0 01.594-.219c.482 0 .867.361.921.828a1.233 1.233 0 01-1.421 1.157l-.485-.079-.093.485A.735.735 0 019.25 13c-.42 0-.75-.33-.75-.75V6.984c.43.333.957.515 1.5.516v-1c-.834 0-1.5-.666-1.5-1.5V4c0-.558.441-1 1-1z"
        fill={props.color || "#474747"}
      />
    </svg>
  );
};

export const BrainIconLarge = (props) => {
  return (
    <svg
      width={57}
      height={57}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.162 7.477c-3.372 0-6.103 2.448-6.786 5.628-4.117.734-7.336 4.17-7.336 8.494 0 .434.047.82.11 1.214-1.138 1.674-1.876 3.646-1.876 5.847 0 3.172 1.58 5.84 3.806 7.777-.159.629-.275 1.256-.275 1.931 0 4.366 3.578 7.944 7.943 7.944h.112c1.027 1.993 2.806 3.53 5.184 3.53a6.16 6.16 0 004.413-1.876 6.16 6.16 0 004.413 1.877c2.38 0 4.157-1.538 5.187-3.53h.109c4.145 0 7.543-3.228 7.889-7.282h.054c0-.112-.047-.221-.054-.332.007-.11.054-.221.054-.33 0-.655-.234-1.215-.386-1.82 2.29-1.946 3.917-4.66 3.917-7.89 0-2.199-.738-4.17-1.877-5.847.064-.392.112-.777.112-1.213 0-4.325-3.22-7.758-7.337-8.496-.683-3.176-3.414-5.626-6.785-5.626-2.118 0-4 .987-5.296 2.482-1.296-1.493-3.177-2.482-5.296-2.482zm0 3.53a3.503 3.503 0 013.53 3.531v3.53a5.27 5.27 0 01-5.296 5.296v3.53c2 0 3.813-.688 5.296-1.82v18.59a2.622 2.622 0 01-2.648 2.648 2.595 2.595 0 01-2.593-2.152l-.33-1.708-1.71.275a4.391 4.391 0 01-5.076-4.358c0-.546.089-1.056.276-1.545.014-.04.042-.07.054-.111a4.357 4.357 0 013.2-2.648l-.716-3.474a7.86 7.86 0 00-4.579 2.757 6.987 6.987 0 01-1.765-4.688 6.885 6.885 0 011.544-4.359l.553-.661-.166-.883a5.046 5.046 0 01-.166-1.158 5.27 5.27 0 015.296-5.296h1.765v-1.765a3.503 3.503 0 013.53-3.53zm10.59 0a3.503 3.503 0 013.531 3.531v1.765h1.765a5.27 5.27 0 015.296 5.296c0 .38-.07.745-.166 1.158l-.164.883.55.661a6.884 6.884 0 011.545 4.359c0 2.118-.903 4.007-2.372 5.296-1.206-1.069-2.743-1.766-4.468-1.766a6.676 6.676 0 00-4.358 1.6l2.263 2.704a3.188 3.188 0 012.095-.773c1.704 0 3.061 1.276 3.255 2.923-.145 2.325-2 4.138-4.358 4.138-.159 0-.353-.007-.662-.055l-1.71-.277-.33 1.712a2.594 2.594 0 01-2.594 2.15 2.622 2.622 0 01-2.648-2.648v-18.59a8.676 8.676 0 005.296 1.82v-3.53a5.27 5.27 0 01-5.296-5.296v-3.53a3.503 3.503 0 013.53-3.53z"
        fill={props.color || "#fff"}
      />
    </svg>
  );
};

export const LibraryIcon = (props) => {
  return (
    <svg
      width={props.size || 14}
      height={props.size || 14}
      fill="none"
      {...props}
    >
      <path
        d="M7 1.167L1.167 4.083 7 7l5.833-2.917L7 1.167zM1.167 9.917L7 12.833l5.833-2.916M1.167 7L7 9.917 12.833 7"
        stroke={props.color || "#474747"}
        strokeWidth={1.167}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const SettingsIcon = (props) => {
  return (
    <svg
      width={props.size || 14}
      height={props.size || 14}
      fill="none"
      {...props}
    >
      <path
        d="M7 8.75a1.75 1.75 0 100-3.5 1.75 1.75 0 000 3.5z"
        stroke={props.color || "#474747"}
        strokeWidth={1.167}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.317 8.75a.963.963 0 00.192 1.062l.035.035a1.166 1.166 0 01-.825 1.993 1.166 1.166 0 01-.826-.342l-.035-.035a.963.963 0 00-1.061-.193.963.963 0 00-.584.88v.1a1.167 1.167 0 11-2.333 0v-.053a.963.963 0 00-.63-.88.963.963 0 00-1.062.192l-.035.035a1.166 1.166 0 01-1.993-.825 1.167 1.167 0 01.342-.826l.035-.035a.962.962 0 00.193-1.061.962.962 0 00-.88-.584h-.1a1.167 1.167 0 110-2.333h.052a.962.962 0 00.881-.63.963.963 0 00-.192-1.062l-.035-.035a1.167 1.167 0 111.65-1.65l.036.035a.962.962 0 001.061.192h.047a.962.962 0 00.583-.88v-.1a1.167 1.167 0 012.334 0v.053a.962.962 0 00.583.88.963.963 0 001.062-.192l.035-.035a1.167 1.167 0 111.65 1.65l-.034.036a.962.962 0 00-.193 1.061v.047a.962.962 0 00.88.583h.1a1.167 1.167 0 010 2.334h-.053a.963.963 0 00-.88.583v0z"
        stroke={props.color || "#474747"}
        strokeWidth={1.167}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const UpdatesIcon = (props) => {
  return (
    <svg
      width={props.size || 14}
      height={props.size || 14}
      fill="none"
      {...props}
    >
      <g
        clipPath="url(#prefix__clip0)"
        stroke={props.color || "#474747"}
        strokeWidth={1.189}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M4.895 10.109l2.379 2.378 2.378-2.378M7.274 7.136v5.352" />
        <path d="M12.554 10.757a2.973 2.973 0 00-1.712-5.405h-.75a4.756 4.756 0 10-8.17 4.335" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(.138)"
            d="M0 0h14.271v14.271H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DoubleChevron = (props) => {
  return (
    <svg width={19} height={19} fill="none" {...props}>
      <path
        d="M12.5 7.5L9.708 4.75 7 7.5M7 12l2.792 2.75L12.5 12"
        stroke="#999"
        strokeWidth={0.792}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const NewPageIcon = (props) => {
  return (
    <svg
      width={props.size || 27}
      height={props.size || 27}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.625 2.25H6.75A2.25 2.25 0 004.5 4.5v18a2.25 2.25 0 002.25 2.25h13.5a2.25 2.25 0 002.25-2.25V10.125L14.625 2.25z"
        stroke={props.color || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.625 2.25v7.875H22.5"
        stroke={props.color || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export const FilledPageIcon = (props) => {
  return (
    <svg
      width={props.size || 24}
      height={props.size || 24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 2H7a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z"
        stroke={props.color || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 2v6h6M17 13H9M17 17H9M11 9H9"
        stroke={props.color || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
