import React from 'react';
import { AlertDialog } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/**
 *
 * @param {import('@chakra-ui/react').AlertDialogProps } param0
 * @returns
 */
const CustomAlertDialog = ({ isOpen, ...rest }) => {
	const isSupportWidgetOpen = useSelector(
		(state) => state.supportWidget.widgetIsOpen
	);
	const subscriptionsCheckoutModalIsOpen = useSelector(
		/**
		 *
		 * @param {{subscriptions: import('../../../redux/reducers/types/subscriptionsReducer').default}} state
		 * @returns
		 */
		(state) => state.subscriptions.checkoutModalIsOpen
	);

	return (
		<AlertDialog
			isOpen={isOpen}
			trapFocus={
				isOpen && (isSupportWidgetOpen || subscriptionsCheckoutModalIsOpen)
					? false
					: true
			}
			{...rest}
		/>
	);
};

export default CustomAlertDialog;
