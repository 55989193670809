/**
 * Profile Reducer
 *
 * Creates a Redux reducer for populating the profiles - users and teams.
 */

// Action Types
import actionTypes from '../actionTypes';

//Dependencies
import dotProp from 'dot-prop-immutable';

// Setup initial state with an fleet info object.
const initialState = {
	selected: {},
	user: {},
	teams: [],
	contacts: {},
};

// Export the Store Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_PROFILES: {
			const { info } = action.payload;
			state = { ...state, teams: [] };
			info.map((item) => {
				if (!item.isTeam) {
					state = { ...state, user: item };
					if (!state.selected._id) {
						state = { ...state, selected: item };
					}
				} else {
					state = dotProp.set(state, 'teams', (list) => [...list, item]);
				}
			});
			return state;
		}
		case actionTypes.SET_CURRENT_PROFILE: {
			const { info } = action.payload;
			state = { ...state, selected: info };
			return state;
		}
		case actionTypes.SET_CONTACTS: {
			const { info } = action.payload;
			state = { ...state, contacts: info };
			return state;
		}
		case actionTypes.CLEAR_PROFILES: {
			state = { ...state, selected: {}, user: {}, teams: [], contacts: {} };
			return state;
		}
		case actionTypes.CURRENT_PROFILE_TIER_UPDATE: {
			const { info } = action.payload;
			state = { ...state, selected: { ...state.selected, tier: info } };
			return state;
		}

		default:
			return state;
	}
};
