import { Flex, Text } from '@chakra-ui/react';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';
import NumericPeriodValuesSelector from '../NumericPeriodValuesSelector';
import { isCronSecondsValue } from '@mayahq/cron';
/**
 *
 * @param {{
 * 	cronObject: import('@mayahq/cron').CronQuartz,
 * 	onCronObjectChange: (newCronObject: import('@mayahq/cron').CronQuartz) => void,
 *  	menuProps?: import('@chakra-ui/react').MenuProps,
 *		menuButtonProps?: import('@chakra-ui/react').MenuButtonProps & import('@chakra-ui/react').ButtonProps,
 *		menuListProps?: import('@chakra-ui/react').MenuListProps,
 *		menuItemProps?: import('@chakra-ui/react').MenuItemProps,
 * }} param0
 * @returns
 */
const EverySecondsSelector = ({
	cronObject,
	onCronObjectChange,
	menuProps,
	menuButtonProps,
	menuListProps,
	menuItemProps,
}) => {
	const [selected, setSelected] = useState(() =>
		isCronSecondsValue(cronObject?.seconds?.every)
			? cronObject.seconds.every
			: 1
	);

	useEffect(() => {
		onCronObjectChange({
			seconds: { every: selected, start: 0 },
			minutes: '*',
			hours: '*',
			dayOfWeek: '*',
			dayOfMonth: '*',
			month: '*',
			year: '*',
		});
	}, [onCronObjectChange, selected]);

	return (
		<Flex mt="2" alignItems="center">
			<Text mr="1">Every</Text>
			<NumericPeriodValuesSelector
				selected={selected}
				onSelect={setSelected}
				selectionRange={{
					from: 1,
					to: 60,
				}}
				menuProps={menuProps}
				menuButtonProps={menuButtonProps}
				menuListProps={menuListProps}
				menuItemProps={menuItemProps}
			/>
			<Text ml="1">second(s)</Text>
		</Flex>
	);
};

export default React.memo(EverySecondsSelector);
