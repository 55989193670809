import React from 'react';
import { Box } from '@chakra-ui/react';

// Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import SelectDevice from '../Device/SelectDevice';
import InputComponent from '../Reusable/Input';

const NewBrain = ({ name, setName, setDevice, defaultRuntime = false }) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="space-evenly"
			height="100%"
		>
			<Box>
				<HeaderWithDescription
					header={'Choose Name'}
					desc={
						'Use a random name, or type one that suggests what this will be used for - e.g Twitter Workspace'
					}
					marginLeft="0.5rem"
					borderBottom="none"
					paddingBottom="none"
				/>
				<InputComponent
					placeholder="Enter name here"
					marginLeft="0.5rem"
					width="90%"
					default={name}
					onChange={(event) => {
						setName(event.target.value);
						console.log('onChange', event.target.value);
					}}
					isDisabled={defaultRuntime}
					onClick={(event) => {
						setName('');
						console.log('onClick');
					}}
				/>
			</Box>

			<Box marginY="1.2rem">
				<HeaderWithDescription
					header={'Select Device'}
					desc={
						'The new Maya Workspace will be initialized on the chosen device.'
					}
					marginLeft="0.5rem"
					borderBottom="none"
					paddingBottom="none"
				/>
				<SelectDevice
					margin="0.5rem"
					onChange={(deviceItem) => setDevice(deviceItem)}
				/>
			</Box>
		</Box>
	);
};

export default NewBrain;
