import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';

import {
	FiClock,
	FiShoppingBag,
	FiGitBranch,
	FiCommand,
	FiLayers,
	FiSettings,
	FiDownloadCloud,
	FiHome,
	FiGrid,
} from 'react-icons/fi';
import { BiBrain } from 'react-icons/bi';
import IconContainer from '../../util/React_Icon';

//Analytics
import analytics from '../../util/Analytics';

const icons = {
	home: FiHome,
	store: FiShoppingBag,
	brains: BiBrain,
	skills: FiCommand,
	settings: FiSettings,
	library: FiLayers,
	updates: FiDownloadCloud,
	dashboard: FiGrid,
};

const SideBarLink = ({ selected, onClick, type, text }) => {
	const { colorMode } = useColorMode();
	const color = { light: '#686767', dark: 'white' };
	const selectedColor = { light: 'white', dark: 'white' };
	const hoverColor = {
		light: 'dashboard.bg_light.200',
		dark: 'maya_dark.150',
	};

	const IconElement = icons[type];
	return (
		<Box
			display="flex"
			flexDirection="columns"
			alignItems="center"
			borderColor="#e6e5e5"
			cursor="pointer"
			margin="0.3rem"
			marginBottom="0.1rem"
			borderRadius="5px"
			padding="2px 0px 2px 20px"
			transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
			bg={selected ? 'purple.500' : null}
			onClick={() => {
				onClick();
				analytics.track(`Sidebar Link Clicked`, { type, selected, text });
			}}
			willChange="background-color, color"
			_hover={{ bg: selected ? 'purple.500' : hoverColor[colorMode] }}
		>
			{/* <IconElement
        size={17}
        boxSize={170}
        color={selected ? selectedColor[colorMode] : color[colorMode]}
        style={{ marginTop: "0.2rem" }}
      /> */}
			<IconContainer
				icon={<IconElement />}
				value={{
					color: color[colorMode],
					className: 'global-class-name',
					size: '16px',
				}}
			/>
			<Box
				// bg="tomato"
				fontSize="16px"
				width="6rem"
				paddingX="0.2rem"
				paddingY="0.5rem"
				fontFamily="body"
				border="none"
				outline="none"
				ml="4"
				fontWeight={'400'}
				color={selected ? selectedColor[colorMode] : color[colorMode]}
			>
				{text}
			</Box>
		</Box>
	);
};

export default SideBarLink;
