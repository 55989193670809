import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Heading, Input, useToast, Tooltip, Link } from '@chakra-ui/react';
import { ModuleItem } from '../../SetupWizard/ModuleConfiguration/ModuleItemSW';
import GetProfileSlug from '../../../util/ProfileSlug';
import getConfigProfiles from '../../../functions/configuration/getConfigProfiles';
import getStoreModules from '../../../functions/store/getStoreModules';

// import selectedPublishedSkillPacks from "../selectedPublishedSkillPacks.json"
import HorizontalItemSkeleton from '../../SetupWizard/ModuleConfiguration/HorizontalItemSkeleton';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';
import _ from 'lodash';
import { HistoryWithSlug } from '../../../util/History';

/**
 * @type {import("../SkillPack").SkillPack[]}
 */
const skillPacksInit = [];

/**
 * @type {{
 *  message: string,
 *  progressPercent: number,
 * }}
 */
const installingObjInit = {
	message: '',
	progressPercent: 0,
};

/**
 * Module Configuration
 */
const ModuleConfiguration = () => {
	const toast = useToast();
	const { feature } = useContext(AnalyticsContext);
	// stores dependency module docs from mongo
	const [depModules, setDepModules] = useState([]);

	const [modulesLoading, setModulesLoading] = useState(true);
	// stores profile config data
	const [moduleList, setModuleList] = React.useState({ allIds: [], byId: {} });

	const { slug } = GetProfileSlug();

	const countModuleConfigsRequired = useCallback(() => {
		let count = 0;
		console.log('counting moduleList total', moduleList);
		if (moduleList.allIds.length === 0) {
			return 0;
		}
		for (let id of moduleList.allIds) {
			let obj = moduleList.byId[id];
			console.log('counting moduleList', id, obj);
			if (obj.configurationRequired && !obj.referenceId) {
				count = count + 1;
			}
		}
		return count;
	}, [moduleList]);

	const setModuleListUtil = async (mList) => {
		let mods = { allIds: [], byId: {} };
		for (let mod of mList) {
			// const moduleVersionDetails = mod.versions[0];
			if (!mods.allIds.includes(mod._id)) {
				mods.allIds.push(mod._id);
				if (mod.configurationType) {
					let res = await getConfigProfiles({
						slug: slug,
						moduleId: mod._id,
						brainId: '',
					});
					mods.byId[mod._id] = {
						installed: false,
						configurationRequired: true,
						referenceId: res.length > 0 ? res[0].referenceId : null,
						profileName: res.length > 0 ? res[0].name : '',
						profileList: res,
					};
				} else {
					mods.byId[mod._id] = {
						installed: false,
						configurationRequired: false,
						referenceId: null,
						profileName: '',
						profileList: [],
					};
				}
			}
		}
		mods.allIds.sort((a) => (!mods.byId[a].configurationRequired ? 1 : -1));
		return mods;
	};
	/**
	 *  Add module fetching here
	 * */
	React.useEffect(() => {
		const fetchModules = async () => {
			const res = await getStoreModules({ slug, configOnly: true });
			setDepModules([...res]);
			setModuleListUtil(res).then((mods) => {
				setModuleList(mods);
				setModulesLoading(false);
			});
		};
		fetchModules();
	}, [slug]);

	// continue button disable/enable

	return (
		<Box
			flex="1"
			minHeight="0"
			display="flex"
			flexDirection="column"
			zIndex="4"
		>
			<Box flex="1" minHeight="0" display="flex" flexDir="column" zIndex="4">
				<Box fontSize="15px" mt="2" paddingBottom="1rem">
					Once you authenticate Maya to connect to these services, head
					over to the{' '}
					<span
						style={{ color: '#D6BCFA', cursor: 'pointer' }}
						onClick={() => {
							HistoryWithSlug.push('/store');
						}}
					>
						Maya Store
					</span>{' '}
					to install skills that will use one or more combinations of these
					modules.
				</Box>
				<Box flex="1" minHeight="0" overflowY="auto" borderRadius="md">
					{modulesLoading
						? [1, 2, 3].map((psp, i) => (
								<HorizontalItemSkeleton
									key={`moduleSkeleton-${i}`}
									width="100%"
								/>
						  ))
						: moduleList.allIds.map((id) => {
								const module = _.find(depModules, (obj) => {
									return obj._id === id;
								});
								console.log(
									'ModuleItem: ',
									moduleList.byId[id],
									module
								);
								return (
									!module.private && (
										<ModuleItem
											key={module._id}
											module={module}
											selected={false}
											onClick={(moduleId) => console.log(moduleId)}
											wholeItemActive={true}
											isItemExpanded={false}
											addButton={false}
											profilesButton={
												moduleList.byId[id].configurationRequired
											}
											version={module.currentVersion}
											setModuleList={setModuleList}
											moduleList={moduleList}
											isModuleLoading={modulesLoading}
										/>
									)
								);
						  })}
				</Box>
			</Box>
		</Box>
	);
};

export default ModuleConfiguration;
