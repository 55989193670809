// Export the API.
const routes = {
	ipcService: {
		// Plugins:
		getPlugins: {
			PATH_SEARCH: '/api/plugin/list',
			PATH_METHOD: 'post',
		},
		downloadPlugin: {
			PATH_SEARCH: '/api/plugin/download',
			PATH_METHOD: 'post',
		},
		installPlugin: {
			PATH_SEARCH: '/api/plugin/install',
			PATH_METHOD: 'post',
		},

		// Dashboard data
		// ELECTRON
		getDashboardData: {
			EVENT: 'getDashboardData',
		},
		// Feedback
		submitFeedback: {
			PATH_SEARCH: '/api/feedback/submit',
			PATH_METHOD: 'post',
		},
		// Teams
		// ELECTRON
		modifyTeam: {
			PATH_SEARCH: '/api/team/edit',
			PATH_METHOD: 'put',
		},
		createTeam: {
			PATH_SEARCH: '/api/team/create',
			PATH_METHOD: 'post',
		},
		inviteMember: {
			PATH_SEARCH: '/api/team/members/invite',
			PATH_METHOD: 'post',
		},
		removeMember: {
			PATH_SEARCH: '/api/team/members/remove',
			PATH_METHOD: 'post',
		},
		addFlowToTeamLibrary: {
			PATH_SEARCH: '/api/team/library/add',
			PATH_METHOD: 'post',
		},
		deleteFlowFromTeamLibrary: {
			PATH_SEARCH: '/api/team/library/delete',
			PATH_METHOD: 'post',
		},
		getTeamLibraryItems: {
			PATH_SEARCH: '/api/team/library/list',
			PATH_METHOD: 'post',
		},
		getTeamFromStoreItems: {
			PATH_SEARCH: '/api/team/library/fromstore',
			PATH_METHOD: 'post',
		},
		// Flows
		getCurrentFlow: {
			PATH_SEARCH: '/api/flow/current',
			PATH_METHOD: 'post',
		},
		createFlow: {
			PATH_SEARCH: '/api/flow/create',
			PATH_METHOD: 'post',
		},
		deleteFlow: {
			PATH_SEARCH: '/api/flow/delete',
			PATH_METHOD: 'post',
		},
		// Modules
		getModules: {
			PATH_SEARCH: '/api/modules/',
			PATH_METHOD: 'get',
		},
		// Robots
		// ELECTRON
		getRobotInfo: {
			PATH_SEARCH: '/api/robot/info',
			PATH_METHOD: 'post',
		},
		listRobots: {
			PATH_SEARCH: '/api/robot/list',
			PATH_METHOD: 'get',
		},
		updateRobot: {
			PATH_SEARCH: '/api/robot/update',
			PATH_METHOD: 'post',
		},
		installFlow: {
			PATH_SEARCH: '/api/robot/installFlow',
			PATH_METHOD: 'post',
		},
		uninstallFlow: {
			PATH_SEARCH: '/api/robot/uninstallFlow',
			PATH_METHOD: 'post',
		},
		installModule: {
			PATH_SEARCH: '/api/robot/installModule',
			PATH_METHOD: 'post',
		},
		uninstallModule: {
			PATH_SEARCH: '/api/robot/uninstallModule',
			PATH_METHOD: 'post',
		},
		onDeploy: {
			PATH_SEARCH: '/api/robot/deploy',
			PATH_METHOD: 'post',
		},
		// ELECTRON
		createRobot: {
			PATH_SEARCH: '/api/robot/create',
			PATH_METHOD: 'post',
		},
		// BRAINS
		getBrainFlows: {
			EVENT: 'getBrainFlows',
		},
		createBrain: {
			EVENT: 'createBrain',
		},
		deleteBrain: {
			EVENT: 'deleteBrain',
		},
		startBrain: {
			EVENT: 'startBrain',
		},
		stopBrain: {
			EVENT: 'stopBrain',
		},
		updateBrain: {
			EVENT: 'updateBrain',
		},
		updateAllBrains: {
			EVENT: 'updateAllBrains',
		},
		getUpdates: {
			EVENT: 'getUpdates',
		},
		getTokenIPC: {
			EVENT: 'getToken',
		},
		getBrainById: {
			EVENT: 'getBrainById',
		},
		getBrainsByPublishedSkillPack: {
			EVENT: 'getBrainsByPublishedSkillPack',
		},
		startInterruptedBrains: {
			EVENT: 'startInterruptedBrains',
		},
		checkStartupStatus: {
			EVENT: 'checkStartupStatus',
		},
		getLocale: {
			EVENT: 'getLocale'
		},
		getAppVersion: {
			EVENT: 'getAppVersion',
		},
		autoStartToggle: {
			EVENT: 'autoStartToggle',
		},
		resetDefaults: {
			EVENT: 'resetDefaults',
		},
		autoStartStatus: {
			EVENT: 'autoStartStatus',
		},
		deploySkill: {
			EVENT: 'deploySkill',
		},
		installModuleToBrain: {
			EVENT: 'installModuleToBrain',
		},
		uninstallModuleFromBrain: {
			EVENT: 'uninstallModuleFromBrain',
		},
		getInstalledModulesFromBrain: {
			EVENT: 'getInstalledModulesFromBrain',
		},
		installSkillPackToBrain: {
			EVENT: 'installSkillPackToBrain',
		},
		uninstallSkillPackFromBrain: {
			EVENT: 'uninstallSkillPackFromBrain',
		},
		updateSkillPackToBrain: {
			EVENT: 'updateSkillPackToBrain',
		},
		addSkillPacktoBrain: {
			EVENT: 'addSkillPacktoBrain',
		},
		deleteSkillPackFromBrains: {
			EVENT: 'deleteSkillPackFromBrains',
		},
		updateSkillPackVersion: {
			EVENT: 'updateSkillPackVersion',
		},
		installPublishedSkillPackToBrain: {
			EVENT: 'installPublishedSkillPackToBrain',
		},
		uninstallPublishedSkillPackFromBrain: {
			EVENT: 'uninstallPublishedSkillPackFromBrain',
		},
		updatePublishedSkillPackToBrain: {
			EVENT: 'updatePublishedSkillPackToBrain',
		},
		embedConfigProfileToRuntime: {
			EVENT: 'embedConfigProfileToRuntime',
		},
		saveConfigProfile: {
			EVENT: 'saveConfigProfile',
		},
		getAccountId: {
			EVENT: 'getAccountId',
		},
		revokeAndRemoveConfigProfile: {
			EVENT: 'revokeAndRemoveConfigProfile',
		},
		authenticateConfiguration: {
			EVENT: 'authenticateConfiguration',
		},
		refreshConfiguration: {
			EVENT: 'refreshConfiguration',
		},
		getDecodedAccessToken: {
			EVENT: 'getDecodedAccessToken',
		},
		filterConfigProfiles: {
			EVENT: 'filterConfigProfiles',
		},
		toggleCommandBar: {
			EVENT: 'toggleCommandBar',
		},
		navigateToMainWindowRoute: {
			EVENT: 'navigateToRoute',
		},
		toggleMouseClickThrough: {
			EVENT: 'toggleMouseClickThrough',
		},
		// UPDATES
		getBrainsForUpdates: {
			EVENT: 'getBrainsForUpdates',
		},
		setUpdates: {
			EVENT: 'setUpdates',
		},
		// Devices
		checkDevice: {
			EVENT: 'checkDevice',
		},
		registerDevice: {
			EVENT: 'registerDevice',
		},
		getDevices: {
			EVENT: 'getDevices',
		},
		getMachineDetails: {
			EVENT: 'getMachineDetails',
		},
		getMachineName: {
			EVENT: 'getMachineName',
		},
		setupCheck: {
			EVENT: '/setup/check',
		},
		toggleSetupStatus: {
			EVENT: 'toggleSetupStatus',
		},
		//CONTACTS
		authenticateContactService: {
			EVENT: 'authenticateContactService',
		},
		listContactServices: {
			EVENT: 'listContactServices',
		},
		syncContactService: {
			EVENT: 'syncContactService',
		},
		listContacts: {
			EVENT: 'listContacts',
		},
		getIconPng: {
			EVENT: 'getIconPng',
		},
		getFormattedFlow: {
			EVENT: 'getFormattedFlow',
		},
		getMayaJson: {
			EVENT: 'getMayaJson',
		},
		installExternalSkillpack: {
			EVENT: 'installExternalSkillpack',
		},
		// ELECTRON
		startRobot: {
			PATH_SEARCH: '/api/robot/start',
			PATH_METHOD: 'post',
		},
		// ELECTRON
		stopRobot: {
			PATH_SEARCH: '/api/robot/stop',
			PATH_METHOD: 'post',
		},
		// ELECTRON
		deleteRobot: {
			PATH_SEARCH: '/api/robot/delete',
			PATH_METHOD: 'post',
		},
		getToken: {
			PATH_SEARCH: '/api/robot/token',
			PATH_METHOD: 'post',
		},
		// SETTINGS
		getUserDetails: {
			PATH_SEARCH: '/api/settings/user',
			PATH_METHOD: 'post',
		},
		updateUser: {
			PATH_SEARCH: '/api/settings/user',
			PATH_METHOD: 'put',
		},
		newDeveloperRequest: {
			PATH_SEARCH: '/api/settings/developer/new',
			PATH_METHOD: 'post',
		},
		deleteDeveloperRequest: {
			PATH_SEARCH: '/api/settings/developer/delete',
			PATH_METHOD: 'post',
		},
		useKeytar: {
			EVENT: 'useKeytar',
		},
		// PUBLISH
		publishNewFlow: {
			PATH_SEARCH: '/api/publish/flow/new',
			PATH_METHOD: 'post',
		},
		updatePublishedFlow: {
			PATH_SEARCH: '/api/publish/flow/update',
			PATH_METHOD: 'post',
		},
		// STORE
		getStoreFlows: {
			PATH_SEARCH: '/api/store/',
			PATH_METHOD: 'get',
		},
		getStoreFlowById: {
			PATH_SEARCH: '/api/store/',
			PATH_METHOD: 'post',
		},
		getStoreFlowVersions: {
			PATH_SEARCH: '/api/store/versions',
			PATH_METHOD: 'post',
		},
		// UPLOAD
		uploadFileS3: {
			PATH_SEARCH: '/api/upload/public/s3',
			PATH_METHOD: 'post',
		},
		// CART
		getCartItems: {
			PATH_SEARCH: '/api/cart',
			PATH_METHOD: 'post',
		},
		setCartItem: {
			PATH_SEARCH: '/api/cart/set',
			PATH_METHOD: 'post',
		},
		deleteCartItem: {
			PATH_SEARCH: '/api/cart/delete',
			PATH_METHOD: 'post',
		},
		createOrder: {
			PATH_SEARCH: '/api/cart/order',
			PATH_METHOD: 'post',
		},
		updatePayment: {
			PATH_SEARCH: '/api/cart/payment',
			PATH_METHOD: 'post',
		},
	},
};

export default routes;
