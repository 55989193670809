/**
 * @typedef {(
 *  "introduction" |
 *  "deviceRegistration" |
 *  "brainCreation" |
 *  "skillInstallation" |
 *  "moduleConfiguration" |
 *  "finishSetup")
 * } SetupWizardStage
 */

/**
 * @type {{
 *  list: SetupWizardStage[],
 *  byId: {
 *    [key in SetupWizardStage]?: {
 *      label: string,
 *    }
 *  }
 * }}
 */
export const setupWizardStages = {
	list: [
		'introduction',
		'deviceRegistration',
		'brainCreation',
		'skillInstallation',
		'moduleConfiguration',
		'finishSetup',
	],
	byId: {
		introduction: {
			label: 'Introduction',
		},
		deviceRegistration: {
			label: 'Register New Device',
		},
		brainCreation: {
			label: 'Create a Workspace',
		},
		skillInstallation: {
			label: 'Install Collections',
		},
		moduleConfiguration: {
			label: 'Configure Modules',
		},
		finishSetup: {
			label: 'Setup Finished',
		},
	},
};
