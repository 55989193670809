/**
 * Add utility function
 *
 * Adds a normalized array to another normalized array.
 */

import { forEach } from 'lodash';
import dotProp from 'dot-prop-immutable';

export const Add = (sourceArray, targetArray) => {
	forEach(sourceArray.allIds, (sourceId) => {
		if (!targetArray.byId[sourceId]) {
			targetArray = dotProp.set(
				targetArray,
				`byId.${sourceId}`,
				sourceArray.byId[sourceId]
			);
			targetArray = dotProp.set(targetArray, 'allIds', (list) => [
				...list,
				sourceId,
			]);
		} else {
			targetArray = dotProp.set(
				targetArray,
				`byId.${sourceId}`,
				sourceArray.byId[sourceId]
			);
		}
	});

	return targetArray;
};

export const dataURItoBlob = (dataURI) => {
	// convert base64/URLEncoded data component to raw binary data held in a string
	var byteString;
	if (dataURI.split(',')[0].indexOf('base64') >= 0)
		byteString = atob(dataURI.split(',')[1]);
	else byteString = unescape(dataURI.split(',')[1]);

	// separate out the mime component
	var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

	// write the bytes of the string to a typed array
	var ia = new Uint8Array(byteString.length);
	for (var i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new Blob([ia], { type: mimeString });
};

export const normalize = (array) => {
	const newArray = {
		byId: {},
		allIds: [],
	};
	array.forEach((item) => {
		newArray.byId[item._id ? item._id : item.id] = item;
		newArray.allIds.push(item._id ? item._id : item.id);
	});
	return newArray;
};

export const isPublishedSkillPackInstalled = (brains, publishedSkillPacId) => {
	let count = 0;
	for (let id of brains.allIds) {
		for (let obj of brains.byId[id].publishedSkillPacks) {
			if (obj.publishedSkillPack === publishedSkillPacId) {
				count = count + 1;
			}
		}
	}
	return count > 0;
};

export const isSkillPackInstalled = (brains, skillPackId) => {
	let count = 0;
	for (let id of brains.allIds) {
		for (let obj of brains.byId[id].skillPacks) {
			if (obj.skillPack === skillPackId) {
				count = count + 1;
			}
		}
	}
	return count > 0;
};
export const isModuleInstalled = (brains, moduleId) => {
	let count = 0;
	for (let id of brains.allIds) {
		for (let obj of brains.byId[id].modules) {
			if (obj.module === moduleId) {
				count = count + 1;
			}
		}
	}
	return count > 0;
};

export const getFlowFromSkillPack = (skillPack, version) => {
	for (let sp of skillPack.versions) {
		if (sp.version === version) return sp.flow.flowJSON;
	}
};
