import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	Box,
	Image,
	useColorModeValue,
	useToast,
	Button,
	LightMode,
} from '@chakra-ui/react';
import {
	FiPackage,
	FiCheckCircle,
	FiGitPullRequest,
	FiDownload,
} from 'react-icons/fi';
import _ from 'lodash';

// Redux imports
import { connect, useSelector } from 'react-redux';
import { updateBrainById } from '../../redux/actions';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';

import TagWithIcon from '../Reusable/TagWithIcon';
import SelectBrain from '../Brains/SelectBrain';
import SetupConfigurationProfiles from './ConfigurationProfiles';

//Functions
import installModuleToBrain from '../../functions/modules/installModuleToBrain';
import uninstallModuleFromBrain from '../../functions/modules/uninstallModuleFromBrain';
import getConfigProfiles from '../../functions/configuration/getConfigProfiles';

//Utils
import GetProfileSlug from '../../util/ProfileSlug';

//Analytics
import analytics from '../../util/Analytics';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import isElectron from 'is-electron';
import NodeItem from '../Reusable/NodeItem';
import AppProtocol from '../../util/AppProtocol';
import AppProtocolEvents from '../../util/AppProtocol/Events';

const ModuleInstallModal = ({
	onClose,
	isOpen,
	currentVersionDetails,
	module,
	currentBrainId,
	onClick,

	updateBrainById,
}) => {
	const [isLoading, setLoading] = React.useState(false);
	const [isModuleLoading, setModuleLoading] = React.useState(true);
	const [processComplete, setProcessComplete] = React.useState(false);
	const [isInstalled, setIsInstalled] = React.useState(false);
	const [brainId, setBrainId] = React.useState(currentBrainId || '');
	const [moduleList, setModuleList] = React.useState({
		allIds: [module._id],
		byId: {
			[module._id]: {
				installed: false,
				configurationRequired: module.configurationType ? true : false,
				referenceId: null,
				profileName: '',
				profileList: [],
			},
		},
	});

	const brains = useSelector((state) => state.brains);
	//const [configProfileId, setConfigProfileId] = React.useState(null);
	const [isDisabled, setDisabled] = React.useState(true);
	const toast = useToast();
	const { slug } = GetProfileSlug();
	/**
	 * List of Node objects in the current version
	 * of the module.
	 */
	const nodes = React.useMemo(() => {
		try {
			// /**
			//  * @type {import('./Module').ModuleEntity}
			//  */
			// const moduleEntity = module;
			// const { currentVersion, versions = [] } = moduleEntity;
			// if (!currentVersion || !versions.length)
			// 	throw new Error('currentVersion || versions.length is invalid!');
			// const currentVersionObj = versions.find(
			// 	(obj) => obj.version === currentVersion
			// );
			// if (!currentVersionObj) throw new Error(`currentVersionObj invalid!`);
			return currentVersionDetails.nodes
				.filter((obj) => typeof obj !== 'string')
				.map((obj) => ({
					title: obj.name,
					description: obj.description,
				}));
		} catch (error) {
			console.error(error);
			return [];
		}
	}, [module]);

	// check if installed
	React.useEffect(() => {
		console.log('setIsInstalled triggered', brainId, isInstalled);
		setProcessComplete(false);
		setIsInstalled(false);
		if (brainId && brains.byId[brainId]) {
			// check if module is installed
			for (let p of brains.byId[brainId].modules) {
				if (p.module === module._id) {
					setIsInstalled(true);
					return;
				}
			}
		}
	}, [brainId, slug, module._id]);

	React.useEffect(() => {
		if (module && module.configurationType && brainId) {
			setModuleLoading(true);
			getConfigProfiles({
				slug: slug,
				moduleId: module._id,
				brainId: brainId,
			}).then((res) => {
				if (res.length > 0) {
					const temp = moduleList;
					temp.byId[module._id].referenceId = res[0].referenceId;
					temp.byId[module._id].profileName = res[0].name;
					temp.byId[module._id].profileList = res;
					setModuleList({ byId: temp.byId, allIds: temp.allIds });
				}
				setModuleLoading(false);
				console.log('module install modal', moduleList);
			});
		} else if (!module.configurationType) {
			setModuleLoading(false);
		}
	}, [brainId, slug, module._id]);

	React.useEffect(() => {
		console.log(
			'useEffect for disabled: ',
			brainId,
			isModuleLoading,
			isLoading,
			processComplete
		);
		if (brainId && !isModuleLoading && !isLoading && !processComplete) {
			if (
				moduleList.byId[module._id].configurationRequired &&
				!moduleList.byId[module._id].referenceId
			) {
				setDisabled(true);
			} else {
				setDisabled(false);
			}
		} else {
			setDisabled(true);
		}
	}, [
		brainId,
		isModuleLoading,
		isLoading,
		moduleList,
		module._id,
		processComplete,
	]);

	const handleInstall = () => {
		setLoading(true);
		//TODO: allow users to choose the version of module but preselect current version
		// don't change these variable names
		var formData = {
			brainId: brainId,
			moduleId: module._id,
			packageName: module.packageName,
			profileSlug: slug,
			version: module.currentVersion, // TODO: Module version to install should be user selectable
			referenceId: moduleList.byId[module._id].referenceId,
		};
		installModuleToBrain(formData, module).then((res) => {
			if (res['error']) {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'Module could not be installed',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
				analytics.track('Module Install', {
					brainId: formData.brainId,
					packageName: formData.packageName,
					version: formData.version,
					status: 'failure',
					error: res['error'],
				});
			} else {
				setLoading(false);
				toast({
					title: 'Success!',
					description: 'Module successfully installed',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				analytics.track('Module Install', {
					brainId: formData.brainId,
					packageName: formData.packageName,
					version: formData.version,
					status: 'success',
					error: !res['error'],
				});
			}
			console.log(res);
			updateBrainById(brainId, res.brain);
			setProcessComplete(true);
		});
	};

	const handleUninstall = () => {
		setLoading(true);
		uninstallModuleFromBrain({
			brainId: brainId,
			profileSlug: slug,
			moduleToUninstall: module,
		})
			.then((res) => {
				if (res['error']) {
					setLoading(false);
					toast({
						title: 'Error!',
						description: res.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
					analytics.track('Module Uninstall', {
						brainId: currentBrainId,
						packageName: module.packageName,
						version: module.version,
						status: 'failure',
						error: res['error'],
					});
				} else {
					setLoading(false);
					toast({
						title: 'Uninstalled!',
						description: res.message,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					analytics.track('Module Uninstall', {
						brainId: brainId,
						packageName: module.packageName,
						version: module.version,
						status: 'success',
						error: !res['error'],
					});
				}
				console.log(res);
				updateBrainById(brainId, res.brain);
				setProcessComplete(true);
				//onClose();
			})
			.catch((err) => {
				setLoading(false);
				toast({
					title: 'Error!',
					description: 'This module could not be uninstalled.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<CustomModal
			onClose={onClose}
			isOpen={isOpen}
			isCentered={true}
			height="800px"
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				maxWidth="850px"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody paddingX="0">
					<HeaderWithDescription
						header={'Install Module'}
						desc={'This module will be configured & installed.'}
						paddingX="1rem"
						marginBottom="0rem"
						tooltip="A module is a collection of similar blocks that can be connected together to make a Maya skill workflow."
					/>
					<Box display="flex">
						<Box width="600px">
							<Box
								display="flex"
								alignItems="center"
								onClick={onClick}
								paddingY="0.5rem"
								width="800px"
							>
								<Box
									height="100%"
									display="flex"
									alignItems="center"
									paddingX="1rem"
								>
									<Image
										src={module.thumbnail}
										objectFit="cover"
										borderRadius="5px"
									/>
								</Box>
								<Box>
									<Box
										color="#555555"
										fontSize="1rem"
										display="flex"
										flexDirection="row"
										alignItems="center"
									>
										<Box
											fontSize="18px"
											color={useColorModeValue('#6C6C6C', 'white')}
											fontFamily="body"
										>
											{module.name}
										</Box>
									</Box>
									<Box display="flex" alignItems="center" mt="0.1rem">
										<Box
											fontSize="16px"
											color={useColorModeValue('#6C6C6C', 'white')}
											fontFamily="body"
											opacity="0.7"
											width="420px"
										>
											{_.truncate(module.description, {
												length: 110,
												separator: /,? +/,
											})}
										</Box>
									</Box>
									<Box
										color="#555555"
										fontSize="1rem"
										display="flex"
										flexDirection="row"
										alignItems="center"
										mt="0.4rem"
									>
										<TagWithIcon
											text={module.currentVersion}
											icon={<FiGitPullRequest />}
										/>
										<TagWithIcon
											text={
												currentVersionDetails
													? `${currentVersionDetails.nodes.length} nodes`
													: `0 nodes`
											}
											icon={<FiPackage />}
										/>
										<TagWithIcon
											text={'Docs'}
											icon={<FiGitPullRequest />}
											onClick={() => {
												if (module.docs) {
													if (isElectron()) {
														const electron =
															window.require('electron');
														const { shell } = electron;
														shell.openExternal(module.docs);
													} else {
													}
												}
											}}
										/>
										{false && (
											<TagWithIcon
												text={`${module.totalInstalls} installs`}
												icon={<FiDownload />}
											/>
										)}
										{isInstalled ? (
											<TagWithIcon
												text="Added"
												icon={<FiCheckCircle />}
											/>
										) : null}
									</Box>
								</Box>
							</Box>

							<Box height="500px" overflow="scroll">
								<Box paddingX="1rem">
									<SelectBrain
										onSelectFn={(id) => {
											setBrainId(id);
											console.log('setting brainId', id);
										}}
										initialBrainId={currentBrainId}
										setBrainId={setBrainId}
									/>
								</Box>
								{module.configurationType && brainId ? (
									<Box paddingX="1rem" marginTop="0.5rem">
										<SetupConfigurationProfiles
											configurationType={module.configurationType}
											moduleId={module._id}
											moduleName={module.packageName}
											currentBrainId={brainId}
											moduleList={moduleList}
											setModuleList={setModuleList}
											heightLimit="200px"
											isModuleLoading={isModuleLoading}
										/>
									</Box>
								) : (
									<Box />
								)}
							</Box>
						</Box>
						<Box bg="#2d2d2d" width="300px" padding="1rem" height="600px">
							<Box fontWeight="700" marginBottom="0.5rem">
								Nodes
							</Box>
							<Box height="550px" overflow="scroll">
								{nodes.map((node) => {
									return (
										<NodeItem
											key={node.title}
											title={node.title}
											description={node.description}
										/>
									);
								})}
							</Box>
						</Box>
					</Box>
					<Box
						paddingX="1rem"
						borderTop="solid 1px"
						borderColor={useColorModeValue(
							'dashboard.border_light',
							'dashboard.border_dark'
						)}
						height="3rem"
						alignItems="center"
						justifyContent="flex-end"
						display="flex"
					>
						<LightMode>
							{isElectron() ? (
								isInstalled ? (
									<Button
										colorScheme={!processComplete ? 'red' : 'green'}
										size="sm"
										mr="2"
										borderRadius="5px"
										variant={'solid'}
										paddingX="0.9rem"
										marginTop="0.6rem"
										onClick={() => {
											handleUninstall();
										}}
										isLoading={isLoading}
										loadingText={'Uninstalling...'}
										isDisabled={isDisabled}
									>
										{processComplete ? 'Uninstalled' : 'Uninstall'}
									</Button>
								) : (
									<Button
										colorScheme={
											!processComplete ? 'purple' : 'green'
										}
										size="sm"
										mr="2"
										borderRadius="5px"
										variant={'solid'}
										paddingX="0.9rem"
										marginTop="0.6rem"
										onClick={() => {
											handleInstall();
										}}
										isLoading={isLoading}
										loadingText={'Installing...'}
										isDisabled={isDisabled}
									>
										{processComplete ? 'Installed' : 'Install Now'}
									</Button>
								)
							) : (
								<Button
									colorScheme={!processComplete ? 'purple' : 'green'}
									size="sm"
									mr="2"
									borderRadius="5px"
									variant={'solid'}
									paddingX="0.9rem"
									marginTop="0.6rem"
									onClick={() => {
										AppProtocol(
											AppProtocolEvents.openInApp,
											`store/modules?id=${module._id}`
										);
									}}
									isLoading={isLoading}
									loadingText={'Installing...'}
								>
									Open In App
								</Button>
							)}
						</LightMode>
					</Box>
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

export default connect(null, { updateBrainById })(ModuleInstallModal);
