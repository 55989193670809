import React from 'react';
//Components
import HorizontalMenu from '../Reusable/HorizontalMenu';
import ProfileSection from './Profile';
import DeveloperSection from './Developer';
import ContactSection from './Contacts';
import GeneralSection from './General';
import ModuleConfigSection from './ModuleConfigs';

const LibrarySection = () => {
	const [mode, setMode] = React.useState('General');

	let search = window.location.search;
	let params = new URLSearchParams(search);
	let pathQuery = params.get('mode');
	React.useEffect(() => {
		if (pathQuery) {
			setMode(pathQuery.charAt(0).toUpperCase() + pathQuery.slice(1));
		} else {
		}
	}, [pathQuery]);

	return (
		<>
			<HorizontalMenu
				list={[
					{
						label: 'General',
						onClick: () => {
							setMode('General');
						},
					},

					{
						label: 'Contacts',
						onClick: () => {
							setMode('Contacts');
						},
					},
					{
						label: 'Configurations',
						onClick: () => {
							setMode('Configurations');
						},
					},

					{
						label: 'Developer',
						onClick: () => {
							setMode('Developer');
						},
					},
				]}
				mode={mode}
				setMode={setMode}
			/>
			{mode === 'Profile' ? <ProfileSection /> : null}
			{mode === 'Developer' ? <DeveloperSection /> : null}
			{mode === 'Contacts' ? <ContactSection /> : null}
			{mode === 'General' ? <GeneralSection /> : null}
			{mode === 'Configurations' ? <ModuleConfigSection /> : null}
		</>
	);
};

export default LibrarySection;
