/**
 * Device Reducer
 *
 * Creates a Redux reducer for populating the fleets.
 */

// Action Types
import actionTypes from '../actionTypes';
import dotProp from 'dot-prop-immutable';

// Setup initial state with an fleet info object.
const initialState = {
	current: {},
	started: false,
	list: { byId: {}, allIds: [] },
};

// Export the Device Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_DEVICES: {
			const { info } = action.payload;
			return { ...state, list: info };
			// return state;
		}
		case actionTypes.SET_CURRENT_DEVICE: {
			const { info } = action.payload;
			const data = {
				device: info,
			};
			return {
				...state,
				current: data,
			};
		}
		case actionTypes.CLEAR_DEVICES: {
			state = dotProp.set(state, `current`, {});
			state = dotProp.set(state, `started`, false);
			state = dotProp.set(state, `list`, { byId: {}, allIds: [] });
			return state;
		}
		default:
			return state;
	}
};
