import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';

const getPlatforms = async () => {

	const getPlatformsAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getPlatforms.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getPlatforms.PATH_METHOD,
	});

	const getPlatformsAPIResponse = await getPlatformsAPIFn();
	console.log("getPlatforms: ", getPlatformsAPIResponse)
	return getPlatformsAPIResponse;
};

export default getPlatforms;
