import React from 'react';
import { Box, useColorMode, useColorModeValue, Image } from '@chakra-ui/react';
import _ from 'lodash';
import MayaSkeleton from '../../util/ContentLoaders/MayaSkeleton';
import { TagWithIconMedium, TagWithIconSmall } from './TagWithIcon';
import { tag } from 'postcss-selector-parser';

export const HorizontalCard = ({
	title,
	description,
	thumbnail,
	onClick,
	tags = [],
	isActive = false,
	expandedSection,
}) => {
	const { colorMode } = useColorMode();

	const fontColor = { light: '#555555', dark: 'white' };
	const borderColor = {
		light: 'dashboard.border_light',
		dark: '#484848',
	};
	const bgColor = {
		light: '#f9f9f9',
		dark: '#404040',
	};
	return (
		<Box
			height="120px"
			bg={bgColor[colorMode]}
			display="flex"
			alignItems="center"
			borderRadius="0.5rem"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			border="solid 2px"
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
			borderColor={borderColor[colorMode]}
			_hover={{ borderColor: 'purple.500' }}
			marginTop="0.6rem"
			marginRight="0.6rem"
			onClick={onClick}
		>
			<Box
				ml="1rem"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				// minWidth="500px"
			>
				<Box display="flex" alignItems="center">
					<Box flexDir="column" display="flex" alignItems="center">
						<Image src={thumbnail} boxSize="65px" />
					</Box>
					<Box ml="1rem" width="210px" paddingRight="15px">
						<Box
							fontFamily="body"
							color={fontColor[colorMode]}
							fontWeight="600"
							marginBottom="0.1rem"
							fontSize="14px"
						>
							{_.truncate(title, {
								length: 20,
								separator: ' ',
							})}
						</Box>
						<Box
							fontFamily="body"
							fontSize="12px"
							color={fontColor[colorMode]}
							opacity="0.9"
							width="80%"
						>
							{_.truncate(description, {
								length: 40,
								separator: ' ',
							})}
						</Box>
						{tags.length !== 0 ? (
							<Box
								marginTop="0.5rem"
								display="flex"
								flexDirection={'row'}
							>
								{tags.map((tag) => {
									return tag.disabled ? null : (
										<TagWithIconMedium
											text={tag.text}
											scale={0.8}
											icon={tag.icon}
										/>
									);
								})}
							</Box>
						) : null}
					</Box>
				</Box>
			</Box>
			{expandedSection ? (
				<Box
					flex="1"
					minWidth="0"
					height="100%"
					bg="#3b3b3c"
					borderRightRadius="8px"
				>
					{expandedSection}
				</Box>
			) : null}
		</Box>
	);
};

export const HorizontalCardSkeleton = () => {
	return (
		<Box
			bg={useColorModeValue('white', '#464646')}
			height="100px"
			// width="19.4rem"
			// marginBottom="1rem"
			// marginRight="1rem"
			marginTop="0.6rem"
			marginRight="0.6rem"
			borderRadius="5px"
			padding="10px"
			border="solid 2px"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
		>
			<Box height="80%" display="flex">
				<MayaSkeleton borderRadius="6px" width="155px" height="100%" />
				<Box height="80%" ml="2">
					<MayaSkeleton
						borderRadius="9px"
						width="150px"
						height="10px"
						ml="2"
						mt="3"
					/>
					<Box display="flex">
						<MayaSkeleton
							borderRadius="9px"
							width="100px"
							height="10px"
							ml="2"
							mt="2"
						/>
						<MayaSkeleton
							borderRadius="9px"
							width="100px"
							height="10px"
							ml="2"
							mt="2"
						/>
					</Box>
					<Box display="flex">
						<MayaSkeleton
							borderRadius="9px"
							width="80px"
							height="10px"
							ml="2"
							mt="2"
						/>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};
