import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import _ from 'lodash';
//Components
import { IntentDisplay } from '../CommandItem/IntentDisplay';

//Functions
import toggleCommandBar from '../../../functions/commandBar/toggleCommandBar';

const processComponents = (components) => {
	return _.map(components, (component) => {
		if (component.type === 'prompt') {
			return {
				type: 'text',
				value: component.value,
			};
		} else {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: component.type,
			};
		}
	});
};

const CommandList = ({ commands, height, clickable = true }) => {
	const { colorMode } = useColorMode();

	if (!commands) {
		return null; // For now, since some data in the CMS is bad
	}

	let commandsArray = commands.map((command) => {
		return processComponents(command.components);
	});
	const bgColor = {
		light: '#FFFFFF',
		dark: '#444444',
	};

	const bgColorHover = {
		light: '#FFFFFF',
		dark: '#4c4c4c',
	};

	const getWidth = (intentArray) => {
		let count = 0;
		intentArray.map((intent) => {
			if (intent.type === 'text') {
				count += intent.value.length + 1;
			} else {
				count += intent.key.length + 3;
			}
		});
		return count;
	};

	const generateQuery = (command) => {
		let intentString = '';
		command.map((item) => {
			if (item.type === 'text') {
				intentString = intentString + item.value + ' ';
			} else {
				intentString = intentString + item.key + ' ';
			}
		});
		return intentString.trim();
	};
	return (
		<Box position="relative" flex="1 1 auto">
			<Box
				width="100%"
				display="flex"
				flexDirection="column"
				height={height}
				overflow="auto"
				paddingTop="0.5rem"
				position="absolute"
			>
				{commandsArray.map((command, i) => {
					return (
						<Box
							display="flex"
							direction="rtl"
							alignItems="center"
							bg={bgColor[colorMode]}
							key={i}
							maxWidth="95%"
							width={getWidth(command) * 0.5 + 0.5 + 'rem'}
							paddingX="1rem"
							paddingY="0.3rem"
							mb="2"
							_hover={{ bg: bgColorHover[colorMode] }}
							borderRadius="0.4rem"
							transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
							cursor="pointer"
							boxShadow="0px 2px 2px rgba(0, 0, 0, 0.05)"
							onClick={() => {
								if (clickable)
									toggleCommandBar({
										search: generateQuery(command),
										method: 'intent click',
									});
							}}
						>
							<IntentDisplay intentArray={command} sizeMultiple={0.85} />
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default CommandList;
