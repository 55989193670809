import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';
import memoizee from 'memoizee';
/**
 * Function to Create a New Brain, given some parameters
 */
const getIconPng = memoizee(
	async ({ path }) => {
		var getIconPngFn;
		if (!isElectron()) {
			// TODO
		} else {
			getIconPngFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.getIconPng.EVENT,
			});
		}

		var getIconPngResponse = await getIconPngFn({ path });
		console.log('getIcon called');
		return getIconPngResponse['data'];
	},
	{ async: true }
);

export default getIconPng;
