import * as React from 'react';
import { Box } from '@chakra-ui/react';
import _ from 'lodash';
//Components
import LibrarySkillPackSection from '../Library/LibrarySkillPackSection';

//Functions
import getLibraryItems from '../../functions/library/getLibraryItems';

import GetProfileSlug from '../../util/ProfileSlug';

const SkillPackSelectPage = ({ nextStage, flowDetails, setFlowDetails }) => {
	const [isLoading, setLoading] = React.useState(true);
	const [skillPacks, setSkillPacks] = React.useState([]);
	const { slug } = GetProfileSlug();
	React.useEffect(() => {
		setLoading(true);
		getLibraryItems({ slug }).then((res) => {
			let packItems = _.map(res.skillPacks, (pack) => {
				let currentVersionDetails = _.find(
					pack.skillPack.versions,
					function (versionDetails) {
						return (
							versionDetails.version === pack.skillPack.currentVersion
						);
					}
				);

				return {
					...pack.skillPack,
					currentVersionDetails,
				};
			});
			setLoading(false);
			console.log('isLoading', isLoading);
			setSkillPacks(packItems);
			console.log(packItems);
		});
	}, [slug]);

	const handleSelect = (pack) => {
		setFlowDetails({
			skillPack: pack._id,
			name: pack.name,
			currentVersion: pack.currentVersion,
			shortDescription: pack.description,
			thumbnail:
				pack.currentVersionDetails.flow.thumbnail ||
				'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg',
			pricingType: 'free',
			previews: [],
			skillPackDetails: pack,
			visibility: 'public',
		});
		// setTimeout(() => {
		nextStage();
		// }, 500);
	};
	return (
		<Box width="100%" display="flex" flex="1 1 auto">
			<LibrarySkillPackSection
				packs={skillPacks}
				isLoading={isLoading}
				skillsButton={false}
				addButton={false}
				deleteButton={false}
				onClick={handleSelect}
				wholeItemActive={false}
			/>
		</Box>
	);
};

export default SkillPackSelectPage;
