import actionTypes from '../actionTypes';
import dotProp from 'dot-prop-immutable';

/**
 * @type {import('./types/taskReducer').TaskReducerState}
 */
const initialState = {
	allIds: [],
	byId: {},
};

const commandHistoryReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_TASK: {
			const { task, taskId } = action.payload;

			state = dotProp.set(state, 'allIds', (list) => [...list, taskId]);

			return dotProp.set(state, `byId.${taskId}`, task);
		}
		case actionTypes.UPDATE_TASK: {
			const { task, taskId } = action.payload;
			let taskDef = dotProp.get(state, `byId.${taskId}`);
			return dotProp.set(state, `byId.${taskId}`, { ...taskDef, ...task });
		}
		default: {
			return state;
		}
	}
};

export default commandHistoryReducer;
