import React, { useRef } from 'react';
import { Button } from '@chakra-ui/button';
import {
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
} from '@chakra-ui/modal';
import CustomAlertDialog from '../Reusable/ChakraCustom/CustomAlertDialog';
import { useColorModeValue } from '@chakra-ui/color-mode';

/**
 *
 * @param {{
 * 	onDeactivateBtnClick?: () => void
 * } & import('@chakra-ui/modal').AlertDialogProps} param0
 * @returns
 */
const DeactivateSubscriptionAlert = ({ onDeactivateBtnClick, ...rest }) => {
	const cancelRef = useRef();
	return (
		<CustomAlertDialog {...rest} leastDestructiveRef={cancelRef}>
			<AlertDialogOverlay>
				<AlertDialogContent
					bg={useColorModeValue('white', 'maya_dark.300')}
				>
					<AlertDialogHeader fontSize="lg" fontWeight="bold">
						Deactivate Premium Subscription?
					</AlertDialogHeader>
					<AlertDialogBody>
						Are you sure? You will no longer enjoy premium privileges.
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button
							ref={cancelRef}
							onClick={rest.onClose ? rest.onClose : undefined}
						>
							Cancel
						</Button>
						<Button
							colorScheme="red"
							onClick={
								onDeactivateBtnClick ? onDeactivateBtnClick : undefined
							}
							ml={3}
						>
							Deactivate
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</CustomAlertDialog>
	);
};

export default DeactivateSubscriptionAlert;
