/**
 * This file defines the native commands. They are static
 * and not changeable during runtime like other skills.
 */

/**
 * We try to model the native skills as a pseudo brain
 * to fit with our existing infrastructure of intents.
 * Thus by maintaining the same format and mapping we try
 * to avoid creating specific niche cases are generally
 * handle the native skills like typical intents
 * @type {{
 * 	_id: 'nativePseudoBrain',
 *		url: `http://localhost:3000`, // set default app url
 *		status: 'STARTED', // since this is not a real brain, fix status to started,
 *		intents: {
 *			components: { value: string, type: 'prompt', required: boolean }[]
 *			endpoint: 'string', // should be unique (as is used as ID)
 *		}[]
 * }}
 */
export const nativePseudoBrain = {
	_id: 'nativePseudoBrain',
	url: `http://localhost:3000`, // set default app url
	status: 'STARTED', // since this is not a real brain, fix status to started
	/** define native intents/commands here */
	intents: [
		{
			components: [
				{ value: 'Show Maya Home', type: 'prompt', required: true },
			],
			/** route to be used by native command */
			endpoint: '/home', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{
					value: 'Install Commands from Maya Store',
					type: 'prompt',
					required: true,
				},
			],
			/** route to be used by native command */
			endpoint: '/store', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{
					value: 'Configure Maya Integrations',
					type: 'prompt',
					required: true,
				},
			],
			/** route to be used by native command */
			endpoint: '/settings?mode=configurations', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{
					value: 'Create new Maya Automation',
					type: 'prompt',
					required: true,
				},
			],
			/** route to be used by native command */
			endpoint: '/skills/new', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{
					value: 'Start Stopped Maya Workspaces',
					type: 'prompt',
					required: true,
				},
			],
			/** route to be used by native command */
			endpoint: '/skills', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{ value: 'Show Maya Workspaces', type: 'prompt', required: true },
			],
			/** route to be used by native command */
			endpoint: '/skills', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{ value: 'Check for Maya updates', type: 'prompt', required: true },
			],
			/** route to be used by native command */
			endpoint: '/updates', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{ value: 'Show Maya Library', type: 'prompt', required: true },
			],
			/** route to be used by native command */
			endpoint: '/library', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{ value: 'Show Maya Settings', type: 'prompt', required: true },
			],
			/** route to be used by native command */
			endpoint: '/settings', // should be unique(at least within this brain) as also used as intentID
		},
		{
			components: [
				{
					value: 'Add Contacts To Maya',
					type: 'prompt',
					required: true,
				},
			],
			/** route to be used by native command */
			endpoint: '/settings?mode=contacts', // should be unique(at least within this brain) as also used as intentID
		},
		// add more native command objects here accordingly ...
	],
};
