import React, { useContext } from 'react';

import {
	Button,
	useColorModeValue,
	Modal,
	ModalOverlay,
	ModalContent,
	useToast,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogHeader,
	LightMode,
	AlertDialogFooter,
} from '@chakra-ui/react';

import isElectron from 'is-electron';
import {
	FiPlusCircle,
	FiGitBranch,
	FiMinusCircle,
	FiCommand,
} from 'react-icons/fi';
import { useLocation } from 'react-router-dom';

//Components
import HeaderWithDescription from '../../Reusable/HeaderWithDesc';
import { HorizontalItemButton } from '../../Reusable/HorizontalItem';
import {
	StorePackDetails,
	StorePackItemSkeleton,
} from '../../Store/StorePack/StorePackDetails';
import getSkillPack from '../../../functions/skillPack/getSkillPack';

//Functions
import uninstallPublishedSkillPackFromBrain from '../../../functions/publishedSkillPack/uninstallPublishedSkillPackFromBrain';

//Utils
import GetProfileSlug from '../../../util/ProfileSlug';

//Analytics
import analytics from '../../../util/Analytics';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';
import CustomModal from '../../Reusable/ChakraCustom/CustomModal';

export const SkillsButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiCommand />}
			buttonKey="skills"
			text={'skills'}
			onClick={() => {
				onClick();
			}}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};

export const UninstallButton = ({
	setHoveredButton,
	hoveredButton,
	publishedSkillPack,
	currentBrainId,
	updateBrainById,
	onClick,
}) => {
	const [isUninstalling, setIsUninstalling] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();
	const { feature } = useContext(AnalyticsContext);

	const handleUninstall = () => {
		setIsUninstalling(true);
		getSkillPack({ _id: publishedSkillPack.skillPack })
			.then((pack) => {
				var formData = {
					SkillPackToInstall: pack,
					PublishedSkillPackToInstall: publishedSkillPack,
					version: publishedSkillPack
						? publishedSkillPack.currentVersion
						: pack.versions[0].version,
					//version: pack.currentVersionDetails.version,
					brainId: currentBrainId,
					profileSlug: slug,
				};
				uninstallPublishedSkillPackFromBrain(formData)
					.then((res) => {
						if (res['error']) {
							setIsUninstalling(false);
							toast({
								title: 'Error!',
								description: 'This collection could not be uninstalled',
								status: 'error',
								duration: 3000,
								isClosable: true,
							});
							analytics.track(
								`[${feature}] Collection Item : Uninstall Modal`,
								{
									_id: publishedSkillPack._id,
									name: publishedSkillPack.name,
									error: res['error'],
									status: 'failure',
									feature,
								}
							);
						} else {
							setIsUninstalling(false);
							toast({
								title: 'Success!',
								description:
									'This collection was successfully uninstalled',
								status: 'success',
								duration: 3000,
								isClosable: true,
							});
						}
						onClose();
						updateBrainById(formData.brainId, res.brain);
						analytics.track(
							`[${feature}] Collection Item : Uninstall Modal`,
							{
								_id: publishedSkillPack._id,
								name: publishedSkillPack.name,
								error: !res['error'],
								status: 'success',
								feature,
							}
						);
					})
					.catch((err) => {
						setIsUninstalling(false);
						toast({
							title: 'Error!',
							description: 'This collection could not be uninstalled.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
						analytics.track(
							`[${feature}] Collection Item : Uninstall Modal`,
							{
								_id: publishedSkillPack._id,
								name: publishedSkillPack.name,
								error: true,
								status: 'failure',
								feature,
							}
						);
					});
			})
			.catch((err) => {
				setIsUninstalling(false);
				toast({
					title: 'Error!',
					description: 'This collection could not be uninstalled.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<HorizontalItemButton
				icon={<FiMinusCircle />}
				buttonKey="Deselect"
				text={'Deselect'}
				onClick={() => {
					// setIsOpen(true);
					// analytics.track("[Store] Collection Item : Uninstall Button", {
					//   _id: publishedSkillPack._id,
					//   name: publishedSkillPack.name,
					// });
					onClick();
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Uninstall Collection
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={handleUninstall}
									ml={3}
									isLoading={isUninstalling}
									loadingText={'Uninstalling...'}
								>
									Delete
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

/**
 * Install Button
 * @param {} param0
 */

export const InstallButton = ({
	setHoveredButton,
	hoveredButton,
	onClick,
	publishedSkillPack,
	currentBrainId,
	updateBrainById,
}) => {
	return (
		<HorizontalItemButton
			icon={<FiPlusCircle />}
			buttonKey="Select"
			text={'Select'}
			onClick={() => {
				onClick();
			}}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};
