import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
	Box,
	Checkbox,
	CheckboxGroup,
	useCheckbox,
	useCheckboxGroup,
	useColorModeValue,
	Input,
	InputGroup,
	InputLeftElement,
} from '@chakra-ui/react';

import Fuse from 'fuse.js';

import debounce from 'lodash/debounce';

import { SearchIcon } from '@chakra-ui/icons';

import { connect } from 'react-redux';

import useFuse from './useFuse';

//Analytics
import analytics from '../../util/Analytics';
import getStoreCategories from '../../functions/store/getStoreCategories';

// TODO : Filter items according category boxes on the right
const CategoryItem = ({ entry, handleCheck, entryType, checked }) => {
	return (
		<Box
			bg={useColorModeValue('dashboard.bg_light.100', 'maya_dark.250')}
			height="50px"
			minWidth="180px"
			display="flex"
			paddingLeft="12px"
			marginBottom="5px"
			borderRadius="5px"
			border="solid 1px"
			borderColor={useColorModeValue(
				'dashboard.border_light',
				'dashboard.border_dark'
			)}
			boxShadow="rgba(0, 0, 0, 0.02) 1px 2px 2px"
		>
			<Checkbox
				size="sm"
				fontFamily="body"
				colorScheme="purple"
				value={entry.value}
				isChecked={entryType ? entryType.label === entry.label : checked}
				onChange={(e) => {
					if (handleCheck) {
						handleCheck(entry, e.target.checked);
					}
					console.log('checkbox toggle: ', e.target.checked);
					// analytics.track(`Category Checkbox`, {
					// 	entry,
					// 	entryLabel: entry.label,
					// 	entryType,
					// });
				}}
			>
				<Box
					ml="2"
					fontSize="16px"
					color={useColorModeValue('#6C6C6C', 'white')}
				>
					{entry.label}
				</Box>
			</Checkbox>
		</Box>
	);
};

const CategoryHeader = ({ text }) => {
	return (
		<Box
			fontFamily="body"
			marginTop="16px"
			marginBottom="5px"
			marginLeft="8px"
			fontWeight="600"
			fontSize="14px"
			color={useColorModeValue('#6C6C6C', 'white')}
		>
			{text} :
		</Box>
	);
};

const types = [
	{ kind: 'type', label: 'Collections' },
	{ kind: 'type', label: 'Commands' },
	{ kind: 'type', label: 'Modules' },
];

const NUM_INTEGRATIONS_TO_SHOW = 4;

/**
 *
 * @param {{
 * 	categories: (import('../../functions/store/types/Categories').StoreCategory & {
 *		 	_checked: boolean;
 *		 	kind: 'category';
 *		})[],
 *		setType: (entry: any, checked: boolean) => void,
 *		apps: import('../../functions/store/types/Apps').StoreApp & {
 *			_checked: boolean;
 *			kind: 'app';
 *		},
 *		handleCatCheck: (_id, checked) => void,
 *		handleAppCheck: (_id, checked) => void,
 *		type: {
 *			kind: string;
 *			label: any;
 *			checked: boolean;
 *		},
 * }} param0
 * @returns
 */
const StoreCategories = ({
	categories,
	setType,
	apps,
	handleCatCheck,
	handleAppCheck,
	type: entryType,
}) => {
	const { result, search, term, reset } = useFuse({
		data: apps,
		options: { keys: ['label'] },
	});

	const handleCheck = (entry, checked) => {
		console.log('handleCheck', entry, checked);
		switch (entry.kind) {
			case 'type':
				return setType(entry, checked);
			case 'category':
				return handleCatCheck(entry._id, checked);
			case 'app':
				return handleAppCheck(entry._id, checked);
			default:
				return;
		}
	};

	const debouncedQueryHandler = useCallback(
		debounce(function (query) {
			search(query);
		}, 50),
		[]
	);

	const totalApps = useMemo(() => {
		const filteredApps = result
			.slice(0, NUM_INTEGRATIONS_TO_SHOW)
			.map((r) => (r.item ? r.item : r));
		console.log('resultfuse', filteredApps);
		if (apps.length > NUM_INTEGRATIONS_TO_SHOW) {
			const includes = {};
			filteredApps.forEach((a) => (includes[a.id] = true));
			let i = 0;
			while (
				filteredApps.length < NUM_INTEGRATIONS_TO_SHOW &&
				i < apps.length
			) {
				if (!includes[apps[i].id]) {
					filteredApps.push(apps[i]);
				}
				i++;
			}
		}
		return filteredApps;
	}, [apps, result]);

	return (
		<Box
			display="flex"
			height="100%"
			position="relative"
			overflow="auto"
			width="180px"
			mt="3"
		>
			<Box
				display="flex"
				flexDirection="column"
				position="absolute"
				borderRadius="10px"
			>
				{types.map((type, i) => {
					return (
						<CategoryItem
							entry={type}
							entryType={entryType}
							key={i}
							handleCheck={handleCheck}
						/>
					);
				})}

				<CategoryHeader text="Integrations" />
				<InputGroup>
					<InputLeftElement>
						<SearchIcon color="#ccc" />
					</InputLeftElement>
					<Input
						placeholder="Search Integrations"
						variant="filled"
						mb="8px"
						value={term}
						onChange={(e) => debouncedQueryHandler(e.target.value)}
					/>
				</InputGroup>
				{totalApps.map((app, i) => {
					return (
						<CategoryItem
							entry={app}
							key={i}
							handleCheck={handleCheck}
							checked={app._checked}
						/>
					);
				})}

				<CategoryHeader text="Categories" />
				{categories.map((category, i) => {
					return (
						<CategoryItem
							entry={category}
							key={i}
							handleCheck={handleCheck}
							checked={category._checked}
						/>
					);
				})}
				{/* <CheckboxGroup
					onChange={(e) => {
						console.log("checkbox group: ", e);
						setCategories(e);
					}}
				>
				</CheckboxGroup> */}
				{/**
				 * Removing the platforms section for now because we don't have different skills
				 * for different platforms yet
				 */}
				{/* <CategoryHeader text="Platforms" />
				<CheckboxGroup
					onChange={(e) => {
						console.log("checkbox group: ", e);
						setPlatforms(e);
					}}
				>
					{platforms.map((platform, i) => {
						return (
							<CategoryItem
								entry={platform}
								key={i}
								handleCheck={handleCheck}
							/>
						);
					})}

				</CheckboxGroup> */}
			</Box>
		</Box>
	);
};

export default connect(null, null)(StoreCategories);
