import React from 'react';
import { Button, useDisclosure, useToast } from '@chakra-ui/react';
//Components
import UploadFile from '../Reusable/UploadFile';

const ImportDAGModal = ({ setFlow, setPrompt }) => {
	const { onOpen, isOpen, onClose } = useDisclosure();
	const toast = useToast();
	const readSingleFile = (files) => {
		var file = files[0];
		if (!file) {
			return;
		}
		var reader = new FileReader();
		reader.onload = function (e) {
			var contents = e.target.result;
			displayContents(contents);
		};
		reader.readAsText(file);
	};

	const displayContents = (contents) => {
		var contentsJSON = JSON.parse(contents);

		if (
			contentsJSON.hasOwnProperty('flow') &&
			contentsJSON.hasOwnProperty('text')
		) {
			setFlow(contentsJSON.flow);
			setPrompt(contentsJSON.text);
			onClose();
		} else if (typeof contentsJSON === 'object') {
			if (contentsJSON[0]) {
				if (contentsJSON[0].id) {
					setFlow(contentsJSON);
					setPrompt('Imported from raw JSON flow data.');
					onClose();
				}
			}
		} else {
			toast({
				title: 'An error occurred.',
				description: 'File seems to be not formatted right.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			<Button
				colorScheme="blue"
				bg={'blue.500'}
				color="white"
				size="sm"
				onClick={() => {
					onOpen();
				}}
				loadingText={'Starting...'}
				borderRadius="5px"
				paddingX="20px"
				ml="2"
			>
				Import
			</Button>
			<UploadFile
				isOpen={isOpen}
				onClose={onClose}
				header="Import Maya Flow"
				description="Choose a Maya flow JSON definition to display here."
				fileTypes=".json"
				fileTypesText="(Only .json files accepted)"
				selectFilesFn={(files) => {
					console.log(files);
					readSingleFile(files);
				}}
			/>
		</>
	);
};

export default ImportDAGModal;
