import axios from 'axios';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import config from '../../config/config';

const CMS_API_ROOT = config.app.CMS_BACKEND;

// import { store } from "../../redux/store";

const getStoreModules1 = async ({ slug, configOnly }) => {
	// var { user } = store.getState();
	// Decide if you want to get only modules that require config
	var configFetch = configOnly ? configOnly : false;
	// const profileSlug = formData.slug ? formData.slug : user.info.id;
	var getStoreModulesFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getStoreModules.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getStoreModules.PATH_METHOD,
		URL_PARAM: {
			config: configFetch,
		},
	});
	var getStoreModulesResponse = await getStoreModulesFn();
	if (!getStoreModulesResponse['data']['error']) {
		const result = getStoreModulesResponse['data']['results'];
		console.log('gsm', result);
		return result;
	} else {
		return getStoreModulesResponse['data'];
	}
};

const getStoreModules = async ({
	_id = null,
	categories = [],
	apps = [],
	slug,
	configOnly = false,
	search = ''
}) => {
	let request, configFetch;
	if (_id) {
		request = {
			url: `${CMS_API_ROOT}/modules/${_id}`,
			method: 'get',
		};
	} else {
		configFetch = configOnly;

		request = {
			url: `${CMS_API_ROOT}/modules/filter`,
			method: 'post',
			data: {
				config: configFetch,
			},
		};
	}

	if (categories.length !== 0) {
		request.data.categories = categories.map((c) => c._id);
	}

	if (apps.length !== 0) {
		request.data.apps = apps.map((a) => a._id);
	}

	if (search) {
		request.data.search = search
	}

	// const getStoreModulesFn = APIFunction({
	// 	BASEURL: CMS_API_ROOT,
	// 	PATH_SEARCH: APIRoutes.apiService.cmsGetStoreModules.PATH_SEARCH,
	// 	PATH_METHOD: APIRoutes.apiService.cmsGetStoreModules.PATH_METHOD,
	// 	URL_PARAM: { config: configFetch },
	// 	formData: data
	// })

	// const response = await getStoreModulesFn()
	try {
		console.log('modulereq', request)
		const response = await axios(request);

		console.log('moduleres', response.data)
		return response.data;
	} catch (e) {
		console.log('gsm error:', e);
		return [];
	}
};

export default getStoreModules;

// return [
//   {
//     _id: 120910092,
//     thumbnail: gmail,
//     name: "Gmail Automation",
//     description: "Send, read, write emails from your Google account.",
//     version: "v4.0.2",
//     nodes: "7",
//     installs: "100",
//     configurationProfiles: [
//       {
//         _id: "13891029992030",
//         name: "Profile 1",
//         updated: "9/12/2020",
//         details: {},
//       },
//       {
//         _id: "188399299030",
//         name: "Profile 2",
//         updated: "5/12/2020",
//         details: {},
//       },
//       {
//         _id: "1893990299030",
//         name: "Profile 3",
//         updated: "8/12/2020",
//         details: {},
//       },
//     ],
//   },
//   {
//     _id: 377882893,
//     thumbnail: twitter,
//     name: "Twitter Automation",
//     description: "Read and post tweets from your as a Maya skill.",
//     version: "v3.0.1",
//     nodes: "5",
//     installs: "200",
//     configurationProfiles: [
//       {
//         _id: "13891029992030",
//         name: "Profile 1",
//         updated: "9/12/2020",
//         details: {},
//       },
//       {
//         _id: "188399299030",
//         name: "Profile 2",
//         updated: "5/12/2020",
//         details: {},
//       },
//     ],
//   },
//   {
//     _id: 727788283,
//     thumbnail: chrome,
//     name: "Web Navigation & Scraping",
//     description: "Web automation, navigation, scraping & testing on Chromium",
//     version: "v2.0.1",
//     nodes: "9",
//     installs: "50",
//     configurationProfiles: [
//       {
//         _id: "13891029992030",
//         name: "Profile 1",
//         updated: "9/12/2020",
//         details: {},
//       },
//       {
//         _id: "188399299030",
//         name: "Profile 2",
//         updated: "5/12/2020",
//         details: {},
//       },
//     ],
//   },
//   {
//     _id: 7277232283,
//     thumbnail: whatsapp,
//     name: "Whatsapp Web Automation",
//     description: "Automate Whatsapp web automatically.",
//     version: "v2.0.1",
//     nodes: "9",
//     installs: "50",
//     configurationProfiles: [
//       {
//         _id: "13891029992030",
//         name: "Profile 1",
//         updated: "9/12/2020",
//         details: {},
//       },
//       {
//         _id: "188399299030",
//         name: "Profile 2",
//         updated: "5/12/2020",
//         details: {},
//       },
//     ],
//   },
//   {
//     _id: 288839992,
//     thumbnail: dash,
//     name: "Dashboard Utilities",
//     description: "Automate Whatsapp web automatically.",
//     version: "v2.0.1",
//     nodes: "9",
//     installs: "50",
//     configurationProfiles: [
//       {
//         _id: "13891029992030",
//         name: "Profile 1",
//         updated: "9/12/2020",
//         details: {},
//       },
//       {
//         _id: "188399299030",
//         name: "Profile 2",
//         updated: "5/12/2020",
//         details: {},
//       },
//     ],
//   },
//   {
//     _id: 288856992,
//     thumbnail: bot,
//     name: "Interaction Utilities",
//     description: "Expose Maya to be accessed from outside.",
//     version: "v2.0.1",
//     nodes: "9",
//     installs: "50",
//     configurationProfiles: [
//       {
//         _id: "13891029992030",
//         name: "Profile 1",
//         updated: "9/12/2020",
//         details: {},
//       },
//       {
//         _id: "188399299030",
//         name: "Profile 2",
//         updated: "5/12/2020",
//         details: {},
//       },
//     ],
//   },
// ];
