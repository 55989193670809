import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { uniqueId } from 'lodash';
import TaskSubSection from './TaskSubsection';
import useSchedulers from './useSchedulers';
import Axios from 'axios';
import isElectron from 'is-electron';

const LongRunningTasks = () => {
	const { schedulerConfigs } = useSchedulers();
	const [allTasks, setAllTasks] = useState(
		/**
		 * @returns {{
		 * 	ids: string[],
		 * 	byId: {
		 * 		[key: string]: import('./LongRunningTasks').LongRunningTask
		 * 	}
		 * }}
		 */
		() => ({
			ids: [],
			byId: {},
		})
	);
	/**
	 *	Function to update the long running task
	 * @param {string} taskId
	 * @param {import('./LongRunningTasks').LongRunningTask} updatedFields
	 */
	const updateTask = (taskId, updatedFields) => {
		setAllTasks((old) => ({
			ids: old.ids,
			byId: {
				...old.byId,
				[taskId]: {
					...old.byId[taskId],
					...updatedFields,
				},
			},
		}));
	};

	const getAllTasks = useCallback(async () => {
		try {
			setAllTasks({ ids: [], byId: {} }); // reset tasks
			const apiCalls = schedulerConfigs.ids.map(async (schId, idx) => {
				const { brainId, endpointFullUrl, brainBaseURL } =
					schedulerConfigs.byId[schId];
				const response = await Axios.get(endpointFullUrl);
				// data should be array since each node can contain list of schedules
				if (!Array.isArray(response.data))
					throw new Error(`response.data is not an Array! idx: ${idx}`);
				return response.data.map((data) => ({
					...data,
					brainId,
					brainBaseURL,
					endpointFullUrl,
				}));
			});
			const apiResponses = await Promise.all(apiCalls);
			// Using flatmap to flatten since each scheduler contains list of tasks.
			// Therefor the result is array of arrays.
			/**
			 * @type {(import('./LongRunningTasks').SchedulerGetResponseTaskEntity & {
			 *	 		brainId: string,
			 *			brainBaseURL: string,
			 *	 		endpointFullUrl: string,
			 * 	}
			 * )[]}
			 */
			const responsesData = apiResponses.flatMap((resp) => resp);
			const tasks = responsesData.reduce(
				(acc, schedulerResponseEntity, idx) => {
					const {
						brainId,
						brainBaseURL,
						description,
						nextDate,
						expression,
						isRunning,
						endpointFullUrl,
						name,
						topic,
					} = schedulerResponseEntity;
					const newId = uniqueId();
					acc.ids.push(newId);
					// Create the new task object using the fields from schedulerResponseEntity
					acc.byId[newId] = {
						id: newId,
						title: topic,
						brainId,
						status: isRunning ? 'started' : 'stopped',
						config: {
							cronString: expression,
							nextDate,
						},
						isPinned: false,
						taskStatusIsLoading: false,
						endpointFullUrl,
						name,
						topic,
						brainBaseURL,
					};
					return acc;
				},
				{
					ids: [],
					/**
					 * @type {{
					 * 	[key: string]: import('./LongRunningTasks').LongRunningTask
					 * }}
					 */
					byId: {},
				}
			);
			console.log(
				'🚀 ~ file: index.js ~ line 98 ~ getAllTasks ~ tasks',
				tasks
			);
			setAllTasks(tasks);
		} catch (error) {
			console.error(error);
		}
	}, [schedulerConfigs.byId, schedulerConfigs.ids]);

	useEffect(() => {
		getAllTasks();
	}, [getAllTasks]);

	return (
		<Flex direction="column" minHeight="0">
			{allTasks.ids.length ? (
				<TaskSubSection
					// heading={'All Tasks'}
					themeColor={'orange.500'}
					tasks={allTasks.ids.map((id) => allTasks.byId[id])}
					updateTask={updateTask}
				/>
			) : (
				<Flex direction="column" alignItems="center" p="10">
					<Text>You don't have any tasks scheduled</Text>
					<Text
						color="purple.300"
						cursor="pointer"
						_hover={{
							color: 'purple.200',
						}}
						onClick={(e) => {
							e.preventDefault();
							if (isElectron()) {
								const href =
									'https://docs.mayahq.com/product/node-library/command-bar/bot-scheduler';
								window.open(href, '_blank');
							}
						}}
					>
						Learn more
					</Text>
				</Flex>
			)}
		</Flex>
	);
};

export default LongRunningTasks;
