import React from 'react';
import { useToast } from '@chakra-ui/react';

import _ from 'lodash';

import { GoSync } from 'react-icons/go';

//Components

import { HorizontalItemButton } from '../../Reusable/HorizontalItem';

//functions
import syncService from '../../../functions/contacts/syncService';

/**
 *
 * @param {*} param0
 */

const ImportContacts = ({
	setAllContacts,
	setHoveredButton,
	hoveredButton,
	serviceDetails,
	setContacts,
}) => {
	const toast = useToast();
	const handleSync = () => {
		const configDetails = serviceDetails;

		syncService({ configDetails })
			.then((res) => {
				console.log('THIS DATA', res);
				setContacts(res);
				toast({
					title: 'Synced!',
					description: 'Your contacts have been synced.',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
			})
			.catch((err) => {
				toast({
					title: 'Error!',
					description: 'There was an error in syncing.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<HorizontalItemButton
				icon={<GoSync />}
				buttonKey="sync"
				text={'sync'}
				onClick={handleSync}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
		</>
	);
};

export default ImportContacts;
