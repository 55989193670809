import { Button } from '@chakra-ui/button';
import React from 'react';

/**
 *
 * @param {{
 *  continueBtnRef: React.MutableRefObject<HTMLButtonElement>,
 *  label: string
 * } & import("@chakra-ui/button").ButtonProps} param0
 * @returns
 */
export const ContinueButton = ({
	continueBtnRef,
	label = 'Continue',
	...rest
}) => {
	return (
		<Button
			ref={continueBtnRef}
			background="purple.500"
			_hover={{
				background: '#9270db',
			}}
			_active={{
				background: '#ab90e4',
			}}
			_focus={{
				boxShadow: '0px 0px 0px 1px #B89AFB',
			}}
			color="white"
			width="8.7rem"
			height="2.8rem"
			// paddingX="1.5rem"
			fontFamily="body"
			zIndex="50"
			{...rest}
		>
			{label}
		</Button>
	);
};

/**
 *
 * @param {{
 *  backBtnRef: React.MutableRefObject<HTMLButtonElement>,
 *  label: string
 * } & import("@chakra-ui/button").ButtonProps} param0
 * @returns
 */
export const BackButton = ({ backBtnRef, label = 'Back', ...rest }) => {
	return (
		<Button
			ref={backBtnRef}
			// background="purple.500"
			// _hover={{
			//   background: "#9270db",
			// }}
			// _active={{
			//   background: "#ab90e4",
			// }}
			_focus={{
				boxShadow: '0px 0px 0px 1px #6a6a6abf',
			}}
			color="white"
			width="8.7rem"
			height="2.8rem"
			fontFamily="body"
			{...rest}
		>
			{label}
		</Button>
	);
};

export default null;
