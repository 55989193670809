import React from 'react';
import { Box } from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';

/**
 *
 * @param {{
 *  	onClick: () => void,
 * }} param0
 * @returns
 */
const CloseButton = ({ onClick }) => {
	return (
		<Box
			width="1.6rem"
			height="1.6rem"
			borderRadius="1rem"
			onClick={onClick}
			display="flex"
			justifyContent="center"
			alignItems="center"
			bg="maya_dark.250"
		>
			<SmallCloseIcon />
		</Box>
	);
};

export default CloseButton;
