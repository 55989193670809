import * as React from 'react';
import {
	Button,
	useToast,
	Modal,
	ModalBody,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalCloseButton,
	FormControl,
	FormLabel,
	Input,
	Select,
	useColorModeValue,
} from '@chakra-ui/react';

//Components
import { ImgUpload } from '../../../util/ImgUpload/';
import { dataURItoBlob } from '../../../util/Misc';

//API
import APIFetch from '../../../util/APIFetch';
import APIRoutes from '../../../util/APIRoutes';
import { config } from '../../../config';
import CustomModal from '../../Reusable/ChakraCustom/CustomModal';

export const PreviewModal = ({ onSubmit, isOpen, onClose, AddPreview }) => {
	const [formData, setFormData] = React.useState({ type: 'image' });
	const [isLoading, setLoading] = React.useState(false);
	const toast = useToast();
	const setThumbnail = (e) => {
		setFormData({ ...formData, img: e });
	};

	// Setup the API Fetch utility for Updating Robot info.
	const [{ fetchResults: uploadImageAPI }] = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.uploadFileS3.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.uploadFileS3.PATH_METHOD,
		contentType: 'multipart/form-data',
	});

	const handleSubmit = () => {
		if (formData.type === 'image') {
			console.log(formData);
			if (formData.img !== 'dummy') {
				setLoading(true);
				var blob = dataURItoBlob(formData.img);
				var fd = new FormData();
				fd.append('file', blob);
				uploadImageAPI(fd)
					.then((res) => {
						let form = {
							...formData,
							img: res.data.files[0].location,
							url: '',
						};
						setLoading(false);
						console.log(form);
						AddPreview(form);
						onClose();
					})
					.catch((err) => console.log(err));
			} else {
				toast({
					title: 'Image not saved.',
					description: 'Ensure you have selected & saved the image.',
					status: 'error',
					duration: 3000,
					isClosable: false,
				});
			}
		} else if (formData.type === 'video') {
			let form = {
				...formData,
				img: '',
			};
			setLoading(false);
			AddPreview(form);
			onClose();
		}
	};
	return (
		<CustomModal onClose={onClose} isOpen={isOpen} maxWidth="50rem">
			<ModalContent
				zIndex="2000"
				maxWidth="30rem"
				height={formData.img ? '33rem' : '22rem'}
				borderRadius="0.5rem"
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<ModalHeader pb="2" fontFamily="body">
					New Flow Preview
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody height="90%" paddingY="none">
					<FormControl isRequired width="90%" mb={2}>
						<FormLabel htmlFor="previewType">Preview Type</FormLabel>
						<Select
							id="previewType"
							placeholder="Type"
							height="2rem"
							width="26.5rem"
							color="gray.600"
							mr={3}
							mb={2}
							fontSize="0.8rem"
							boxShadow={'0 0 0 1px #EDF2F7'}
							focusBorderColor="purple.400"
							onChange={(e) =>
								setFormData({ ...formData, type: e.target.value })
							}
							isRequired
							defaultValue={'image'}
						>
							<option value="image">Image</option>
							<option value="video">Video</option>
						</Select>
					</FormControl>
					{formData.type === 'video' ? null : (
						<FormControl isRequired width="100%" mb={2}>
							<FormLabel htmlFor="previewThumbnail">Thumbnail</FormLabel>
							<ImgUpload setThumbnail={setThumbnail} />
						</FormControl>
					)}
					<FormControl isRequired width="90%" mb={2}>
						<FormLabel htmlFor="previewLabel">Label</FormLabel>
						<Input
							id="previewLabel"
							name="previewLabel"
							focusBorderColor="purple.400"
							placeholder="Enter a label to describe this preview..."
							minWidth="17rem"
							onChange={(e) =>
								setFormData({ ...formData, label: e.target.value })
							}
						/>
					</FormControl>
					{formData.type === 'image' ? null : (
						<FormControl isRequired width="90%" mb={2}>
							<FormLabel htmlFor="previewURL">Video URL</FormLabel>
							<Input
								id="previewURL"
								name="previewURL"
								focusBorderColor="purple.400"
								placeholder="Enter Youtube URL"
								minWidth="17rem"
								onChange={(e) =>
									setFormData({ ...formData, url: e.target.value })
								}
							/>
						</FormControl>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						onClick={handleSubmit}
						variantColor="purple"
						mr={3}
						isLoading={isLoading}
						loadingText="Submitting..."
						borderRadius="20px"
					>
						Submit
					</Button>
					<Button variantColor="gray" onClick={onClose}>
						Close
					</Button>
				</ModalFooter>
			</ModalContent>
		</CustomModal>
	);
};
