import React, { useMemo, useState, useEffect } from 'react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	useDisclosure,
	Spacer,
	Box,
} from '@chakra-ui/react';
import APIFunction from '../../../util/APIFetch/function';
import { config } from '../../../config';
import APIRoutes from '../../../util/APIRoutes';
import ModuleInstallModal from '../../Module/ModuleInstallModal';
import SearchInput from './SearchInput';
import SearchSuggestionsList from './SearchSuggestionsList';
import useQueryParams from '../../../util/useQueryParams';
import { algoliaModulesIndex } from '../../../util/AlgoliaSearch';
import useDebounce from '../../../util/useDebounce';
import { v4 as uuidv4 } from 'uuid';

const selectedModuleInit = {
	object: null,
	currentVersionDetails: null,
};

/**
 *
 * @param {{
 * 	popoverProps: import('@chakra-ui/popover').PopoverProps,
 * 	children: (renderProps: {
 * 	 	isOpen: boolean,
 * 	 	onOpen: () => void,
 * 	 	onClose: () => void,
 * 	}) => import('react').ReactNode
 * }} param0
 * @returns
 */
const NodeSearch = ({ popoverProps, children }) => {
	const query = useQueryParams();
	const currentBrainId = query.get('id');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [searchIsLoading, setSearchIsLoading] = useState(false);
	const [moduleIsFetching, setModuleIsFetching] = useState(false);
	const [searchInput, setSearchInput] = useState('');
	const debouncedSearchInput = useDebounce(searchInput, 500);
	const [selectedIdx, setSelectedIdx] = useState(0);
	const [selectedModule, setSelectedModule] = useState({
		...selectedModuleInit,
	});
	const {
		isOpen: moduleInstallModalIsOpen,
		onOpen: moduleInstallModalOnOpen,
		onClose: moduleInstallModalOnClose,
	} = useDisclosure();
	/** suggestions */
	const [suggestionItems, setSuggestionItems] = useState(
		/**
		 * @returns {import('./SearchSuggestionsList').SuggestionItem[]}
		 */
		() => [],
		[]
	);
	const searchInputRef = React.createRef();

	/**
	 * To be called on suggestion item selection
	 * @param {number} idx index of selected item
	 */
	const handleSuggestionItemSelect = async (idx) => {
		setSelectedModule({
			...selectedModuleInit, // reset selected module
		});
		setSelectedIdx(idx);
		/** get the id of the module to which the selected node belongs */
		const moduleId = suggestionItems[idx]?.moduleId;
		setModuleIsFetching(true);
		if (!moduleId) throw new Error(`moduleId not found in suggestions!`);
		const getModuleById = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getModuleById.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getModuleById.PATH_METHOD,
			PATH_QUERY: moduleId,
		});
		const res = await getModuleById();
		setModuleIsFetching(false);
		if (res.data.error) throw new Error(`getModuleById failed with error`);
		const moduleObj = res.data.results;
		const currentVersionDetails = moduleObj.versions.find(
			(versionDetails) => versionDetails.version === moduleObj.currentVersion
		);
		if (!currentVersionDetails)
			throw new Error(`currentVersionObj not found!`);
		// store the module obj + version details received
		setSelectedModule((old) => ({
			...old,
			object: moduleObj,
			currentVersionDetails,
		}));
		moduleInstallModalOnOpen();
	};

	/**
	 * @param {number} idx Index of the item clicked
	 */
	const handleSuggestionItemClick = async (idx) => {
		try {
			await handleSuggestionItemSelect(idx);
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 * @param {React.KeyboardEvent<HTMLInputElement>} event
	 */
	const handleSearchInputKeydown = async (event) => {
		try {
			switch (event.key) {
				case 'ArrowDown': {
					/** move selection downward */
					setSelectedIdx((oldIdx) =>
						oldIdx >= suggestionItems.length - 1 ? 0 : oldIdx + 1
					);
					break;
				}
				case 'ArrowUp': {
					/** move selection upward */
					setSelectedIdx((oldIdx) =>
						oldIdx <= 0 ? suggestionItems.length - 1 : oldIdx - 1
					);
					break;
				}
				case 'Enter': {
					/** on enter: ie. onSelect */
					if (suggestionItems.length)
						// run only if suggestions are populated
						await handleSuggestionItemSelect(selectedIdx);
					break;
				}
				default:
					break;
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		/** Update suggestions based on search input asynchronously */
		const getSuggestions = async () => {
			if (debouncedSearchInput) {
				setSearchIsLoading(true);
				const res = await algoliaModulesIndex.search(debouncedSearchInput);
				/**
				 * @type {import('./searchResults').searchResult[]}
				 */
				const hitsResult = res.hits;
				/** collate hits into suggestions */
				const suggestionItems = hitsResult.reduce(
					(prev, curr, idx, arr) => {
						const { icon: moduleIcon } = curr;
						/**
						 * @type {import('./SearchSuggestionsList').SuggestionItem[]}
						 */
						const componentsList = curr.components.list.map((item) => ({
							id: uuidv4(),
							moduleId: item.parentId,
							moduleIcon,
							title: item.componentName,
							description: item.componentDescription,
							tags: ['node'],
						}));
						prev.push(...componentsList);
						return prev;
					},
					[]
				);
				setSuggestionItems(suggestionItems);
				setSearchIsLoading(false);
			}
		};
		getSuggestions();
	}, [debouncedSearchInput]);

	useEffect(() => {
		/*
		 * reset focused idx to 0, and
		 * reset suggestions on Open
		 */
		if (isOpen) {
			setSelectedIdx(0);
			setSuggestionItems([]);
		}
	}, [isOpen]);

	return (
		<Popover
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-end"
			closeOnBlur={true}
			offset={[0, 2]}
			initialFocusRef={searchInputRef}
			matchWidth
			{...popoverProps}
		>
			<Box zIndex="12">
				<PopoverTrigger>
					{children({
						isOpen,
						onOpen,
						onClose,
					})}
				</PopoverTrigger>
				<PopoverContent
					w="full"
					borderRadius="none"
					borderStyle="none"
					borderColor="gray"
					borderWidth="thin"
					_focus={{
						boxShadow: 'none',
					}}
				>
					<PopoverBody
						p="1"
						w="80"
						boxShadow="dark-lg"
						bg="maya_dark.300"
						borderRadius="none"
					>
						<SearchInput
							inputRef={searchInputRef}
							isLoading={searchIsLoading || moduleIsFetching}
							onChange={(e) => {
								if (e.target.value && !isOpen) onOpen();
								setSearchInput(e.target.value);
							}}
							onKeyDown={handleSearchInputKeydown}
							inputProps={{
								// minW: '80%',
								w: '100% !important',
								placeholder:
									'Start typing to search & install nodes...',
							}}
						/>
						<Spacer h="1" />
						{searchInput ? (
							<SearchSuggestionsList
								items={suggestionItems}
								selectedIdx={selectedIdx}
								onItemClick={handleSuggestionItemClick}
								listProps={{
									m: 'initial !important',
								}}
							/>
						) : null}
					</PopoverBody>
					{!moduleIsFetching &&
					currentBrainId &&
					selectedModule.object &&
					selectedModule.currentVersionDetails ? (
						<ModuleInstallModal
							isOpen={moduleInstallModalIsOpen}
							onClose={moduleInstallModalOnClose}
							module={selectedModule.object}
							currentVersionDetails={
								selectedModule.currentVersionDetails
							}
							currentBrainId={currentBrainId}
						/>
					) : null}
				</PopoverContent>
			</Box>
		</Popover>
	);
};

export default NodeSearch;
