import React from 'react';
import { Box, Spacer, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import IconContainer from '../../../../../util/React_Icon';
import { GoVerified } from 'react-icons/go';
import { FiDownload, FiStar } from 'react-icons/fi';

/*
FOR REF :
const processComponents = (components, url) => {
	return components.map((component) => {
		if (component.type === 'prompt') {
			return {
				type: 'text',
				value: component.value,
			};
		}
		if (component.type === 'custom') {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: 'custom',
				endpointUrl: url + component.endpoint,
			};
		} else {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: component.type,
			};
		}
	});
};
*/

const bgColor = {
	light: 'card_ui.placeholder_light',
	dark: 'card_ui.placeholder_dark',
};
const color = {
	light: 'card_ui.font_light.200',
	dark: 'card_ui.font_dark.200',
};

/**
 *
 * @param {{
 * 	intentComponent: import('../../../../../redux/reducers/cardUIV2').CuiIntentComponent,
 * 	sizeMultiple: number,
 * }} param0
 * @returns
 */
export const StoreInputItemDisplay = ({ intentComponent, sizeMultiple }) => {
	const { colorMode } = useColorMode();

	return intentComponent.type === 'prompt' ? (
		<Box
			fontWeight="500"
			color={color[colorMode]}
			fontSize={`${1 * sizeMultiple}rem`}
			mr={2}
			fontFamily="body"
			whiteSpace="nowrap"
		>
			{intentComponent.value}
		</Box>
	) : (
		<Box
			height={`${1.4 * sizeMultiple}rem`}
			bg={bgColor[colorMode]}
			borderRadius="0.2rem"
			display="flex"
			justifyContent="center"
			alignItems="center"
			paddingX="0.4rem"
			marginTop="0.1rem"
			mr={2}
			color={color[colorMode]}
			_focus={{ outline: 'none' }}
			whiteSpace="nowrap"
		>
			<Box
				color={color[colorMode]}
				whiteSpace="nowrap"
				bg={'transparent'}
				fontWeight="600"
				isdisabled={'true'}
				fontFamily="body"
				padding="0"
				border="none"
				borderRadius="none"
				textAlign={'center'}
				fontSize={`${0.8 * sizeMultiple}rem`}
				width={`${intentComponent.value.length * 0.55}rem`}
			>
				{intentComponent.value}
			</Box>
		</Box>
	);
};

/**
 *
 * @param {{
 * icon?: JSX.Element,
 * children: JSX.Element,
 * sizeMultiple: number,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const StoreTagWithIcon = ({ icon, children, sizeMultiple = 1, ...rest }) => {
	const { colorMode } = useColorMode();
	return (
		<Box
			bg={useColorModeValue('#F4F4F4', '#585858')}
			paddingX="12px"
			paddingY="4px"
			borderRadius="50px"
			display="flex"
			alignItems="center"
			cursor="pointer"
			marginRight="8px"
			border="0.473682px solid "
			borderColor={useColorModeValue('#ebebeb', '#434040')}
			boxSizing="border-box"
			boxShadow="0.947365px 0.947365px 0.947365px rgba(0, 0, 0, 0.05)"
		>
			{icon ? (
				<IconContainer
					icon={icon}
					value={{
						color: colorMode === 'light' ? '#8A8A8A' : 'white',
						className: 'global-class-name',
						size: '10px',
					}}
				/>
			) : null}
			<Box
				ml="1.5"
				fontWeight="600"
				color={useColorModeValue('#8A8A8A', 'white')}
				fontFamily="body"
				fontSize="10px"
			>
				{children}
			</Box>
		</Box>
	);
};

/**
 *
 * @param {{
 *  	storeSkillItemObject: import('../../../../../redux/reducers/cardUIV2').CuiStoreSkill,
 * 	sizeMultiple?: number,
 * 	selected?: boolean,
 * }} param0
 * @returns
 */
const StoreSkillDisplay = ({
	storeSkillItemObject,
	sizeMultiple,
	selected = false,
}) => {
	return (
		<Box display="flex" flexDir="column">
			<Box display="flex" py="1.5">
				{storeSkillItemObject.components.map((component, i) => {
					return (
						<StoreInputItemDisplay
							key={i}
							intentComponent={component}
							sizeMultiple={sizeMultiple || 1}
						/>
					);
				})}
			</Box>
			{selected ? (
				<>
					<hr style={{ background: '#292929' }} />
					<Box display="flex" py="1.5">
						<StoreTagWithIcon icon={<GoVerified />}>
							{storeSkillItemObject.__meta.name}
						</StoreTagWithIcon>
						<StoreTagWithIcon icon={<FiDownload />}>
							{storeSkillItemObject.__meta.installs} installs
						</StoreTagWithIcon>
						<StoreTagWithIcon icon={<FiStar />}>
							{storeSkillItemObject.__meta.rating}
						</StoreTagWithIcon>
					</Box>
				</>
			) : null}
		</Box>
	);
};

export default StoreSkillDisplay;
