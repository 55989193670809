import { useState, useEffect } from 'react';
import isOnline from 'is-online';

function getOnlineStatus() {
	return typeof navigator !== 'undefined' &&
		typeof navigator.onLine === 'boolean'
		? navigator.onLine
		: true;
}

function useOnlineStatus() {
	const [onlineStatus, setOnlineStatus] = useState(getOnlineStatus());

	const goOnline = () => setOnlineStatus(true);

	const goOffline = () => setOnlineStatus(false);

	useEffect(() => {
		window.addEventListener('online', goOnline);
		window.addEventListener('offline', goOffline);

		return () => {
			window.removeEventListener('online', goOnline);
			window.removeEventListener('offline', goOffline);
		};
	}, []);

	useEffect(() => {
		setInterval(async () => {
			var online = await isOnline();

			if (online) {
				goOnline();
			} else {
				goOffline();
			}
		}, 120000);
	}, []);

	return onlineStatus;
}

export default useOnlineStatus;
