import React from 'react';

import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	useColorMode,
	useToast,
} from '@chakra-ui/react';

//Redux
import { connect, useSelector } from 'react-redux';
import { setUser, setStatus, setLoading } from '../../../redux/actions';

// Controllers
import LoginController from '../Login/LoginController';
import History from '../../../util/History';

const VerifyCard = ({ setUser, setStatus }) => {
	const toast = useToast();

	const { colorMode } = useColorMode();

	const email = useSelector((state) => {
		return state.user.info ? state.user.info.email : '';
	});

	const fontColor = { light: 'maya_light.400', dark: 'white' };
	const bgColor = { light: '#f9f9f9', dark: '#424242' };
	const borderColor = { light: '#f2f2f2', dark: '#494949' };

	const [isLoading, setLoading] = React.useState(false);

	const handleResend = () => {
		setLoading(true);
		LoginController.resendVerifyEmail({ email })
			.then((res) => {
				toast({
					title: 'Success!',
					description: 'Please check your inbox for verification email!',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				setLoading(false);
				History.push('/');
			})
			.catch((err) => {
				toast({
					title: 'Error occurred',
					description: 'Could not resend mail.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
				setLoading(false);
			});
	};

	return (
		<Box
			paddingTop="1rem"
			paddingBottom="2rem"
			paddingX="1.2rem"
			display="flex"
			flexDirection="row"
			justifyContent="center"
			flexGrow="0"
			flexShrink="0"
			bg={bgColor[colorMode]}
			fontFamily="body"
			fontWeight="600"
			borderRadius="5px"
			border="1px solid"
			borderColor={borderColor[colorMode]}
			zIndex="50"
		>
			<Stack>
				<Box color={fontColor[colorMode]} fontSize="1.3rem" mb="1rem">
					Verify Email
				</Box>
				<Box
					color={fontColor[colorMode]}
					fontSize="1rem"
					mb="2.5rem"
					width="330px"
					fontWeight="500"
					height="120px"
				>
					{email !== ''
						? `Seems like your email ID hasn't been verified yet. Check your
					inbox for a verification mail or click this button to resend it to this address:`
						: `Looks like you haven't logged in yet. Log in to continue verifying your email.`}
					<Box marginTop="0.5rem" color="purple.100" width="100%">
						{email}
					</Box>
				</Box>

				<Button
					size="md"
					cursor
					marginTop="2rem"
					bg={'purple.500'}
					color="white"
					fontSize="0.8rem"
					onClick={handleResend}
					isLoading={isLoading}
					isDisabled={email === ''}
				>
					Resend Verification Mail
				</Button>
			</Stack>
		</Box>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus })(VerifyCard);

// <Box
//           display="flex"
//           justifyContent="center"
//           color="gray.600"
//           fontSize="0.8rem"
//           fontFamily="body"
//         >
//           or
//         </Box>
//         <Button leftIcon={FaGoogle} variantColor="gray" size="md">
//           Sign in with Google
//         </Button>
