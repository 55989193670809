import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import range from 'lodash/range';
import React, { useEffect, useState } from 'react';

/**
 * @type {{
 * 	id: import('@mayahq/cron').CronDayOfWeekValueString,
 * 	label: string,
 * }[]}
 */
const weekDays = [
	{
		id: 'SUN',
		label: 'Sunday',
	},
	{
		id: 'MON',
		label: 'Monday',
	},
	{
		id: 'TUE',
		label: 'Tuesday',
	},
	{
		id: 'WED',
		label: 'Wednesday',
	},
	{
		id: 'THU',
		label: 'Thursday',
	},
	{
		id: 'FRI',
		label: 'Friday',
	},
	{
		id: 'SAT',
		label: 'Saturday',
	},
];

/**
 *
 * @param {{
 * 	selected: import('@mayahq/cron').CronDayOfWeekValueString,
 * 	onSelect: (newValue: import('@mayahq/cron').CronDayOfWeekValueString) => void,
 * 	selectionRange?: {
 * 		from: number,
 * 		to: number,
 * 	}
 *  	menuProps?: import('@chakra-ui/react').MenuProps,
 *		menuButtonProps?: import('@chakra-ui/react').MenuButtonProps & import('@chakra-ui/react').ButtonProps,
 *		menuListProps?: import('@chakra-ui/react').MenuListProps,
 *		menuItemProps?: import('@chakra-ui/react').MenuItemProps,
 * }} param0
 * @returns
 */
const WeekDayValuesSelector = ({
	selected,
	onSelect,
	selectionRange = { from: 0, to: 7 },
	menuProps,
	menuButtonProps,
	menuListProps,
	menuItemProps,
}) => {
	return (
		<Menu gutter={0} matchWidth placement="bottom" {...menuProps}>
			{({ isOpen }) => (
				<>
					<MenuButton
						as={Button}
						rightIcon={<ChevronDownIcon />}
						size="sm"
						minWidth="32"
						borderBottomRadius={isOpen ? 0 : undefined}
						{...menuButtonProps}
					>
						{weekDays.find((day) => day.id === selected).label}
					</MenuButton>
					<MenuList
						fontSize="sm"
						borderTopRadius={0}
						minWidth="inherit"
						bg="#3b3b3b"
						maxHeight="20rem"
						overflow="auto"
						{...menuListProps}
					>
						{range(selectionRange.from, selectionRange.to).map(
							(weekdayIndex) => (
								<MenuItem
									key={weekDays[weekdayIndex].id}
									onClick={() => onSelect(weekDays[weekdayIndex].id)}
									{...menuItemProps}
								>
									{weekDays[weekdayIndex].label}
								</MenuItem>
							)
						)}
					</MenuList>
				</>
			)}
		</Menu>
	);
};

export default React.memo(WeekDayValuesSelector);
