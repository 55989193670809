// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import IPCFunction from '../../util/IPCFetch/function';

import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';
/**
 * Function to check if device exists
 */
const checkDevice = async (formData) => {
	const { user } = store.getState();
	formData['userId'] = user.info.id;

	var checkDeviceFn = IPCFunction({
		EVENT: IPCRoutes.ipcService.checkDevice.EVENT,
	});
	let stringForm = JSON.stringify(formData);
	var checkDevicesResponse = await checkDeviceFn(JSON.parse(stringForm));
	console.log('checkDeviceResponse', checkDevicesResponse);
	return checkDevicesResponse['data'];
};

export default checkDevice;
