import isElectron from 'is-electron';

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import { store } from '../../redux/store';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const getContacts = async () => {
	const { user } = store.getState();

	var getContactsResponse, getContactsIPCFn;

	if (isElectron()) {
		if (window.navigator.onLine) {
			getContactsIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.listContacts.EVENT,
			});
			getContactsResponse = await getContactsIPCFn(user.info.id);
			if (!getContactsResponse.data.error) {
				return getContactsResponse['data']['results'];
			}
		}
	} else {
		return {
			data: {
				error: true,
				message:
					'This service is available only for Maya Desktop Application',
			},
		};
	}
};

export default getContacts;
