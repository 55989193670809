import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';

/**
 * @type {{
 * 	type: import('../../../../../redux/reducers/cardUIV2').CuiSearchType,
 * 	label: string,
 * }[]}
 */
export const resultsTabs = [
	{ type: 'intents', label: 'Commands' },
	{ type: 'cards', label: 'Cards' },
	// { type: 'tasks', label: 'Tasks' },
	{ type: 'store', label: 'Store' },
];

const TabBar = ({ currentTab, setCurrentTab }) => {
	const { colorMode } = useColorMode();
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};
	return (
		<Box height="1.6rem" display="flex" alignItems="center" pl={8}>
			{resultsTabs.map((result, i) => {
				return (
					<Box
						height="1.6rem"
						fontSize="0.7rem"
						fontFamily="body"
						fontWeight="600"
						width="5.5rem"
						display="flex"
						key={i}
						alignItems="center"
						justifyContent="center"
						color={color[colorMode]}
						borderBottom={'solid 2px'}
						onClick={() => setCurrentTab(result.type)}
						borderBottomColor={
							result.type === currentTab
								? 'card_ui.purple'
								: 'transparent'
						}
						transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
						cursor="pointer"
						opacity={result.type === currentTab ? '1' : '0.8'}
					>
						{result.label}
					</Box>
				);
			})}
		</Box>
	);
};

export default TabBar;
