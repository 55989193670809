import React, { useState, useEffect } from 'react';
import {
	Box,
	Flex,
	Img,
	useColorModeValue,
	Button,
	LightMode,
	useDisclosure,
} from '@chakra-ui/react';
import _ from 'lodash';
import { FiGitBranch, FiCommand, FiDownloadCloud } from 'react-icons/fi';
import { StorePackModal } from '../StorePack/StorePackModal';

const AppIcon = ({ thumbnailUrl }) => (
	<Box overflow="hidden" borderRadius="6px" mr="12px">
		<Img src={thumbnailUrl} maxW="30px" />
	</Box>
);

export function parseIntentLarge(intentString) {
	const parts = intentString.split(' ');
	const component = parts.map((part) => {
		if (part[0] !== '[') {
			return `${part} `;
		} else {
			return (
				<Box
					as="span"
					paddingTop="1px"
					paddingBottom="1px"
					paddingLeft="12px"
					paddingRight="12px"
					// boxSizing='border-box'
					fontWeight="700"
					fontSize="26px"
					bg="#5a5959"
					borderRadius="4px"
					ml="10px"
					mr="20px"
					// mb="20px"
					// mt="-10px"
					mt="auto"
					// mb='auto'
					display="inline-block"
					transform="translateY(-6px)"
				>
					{part.split('[')[1].split(']')[0]}
				</Box>
			);
		}
	});

	return component;
}

export function parseIntent(intentString) {
	const parts = intentString.split(' ');
	const component = parts.map((part) => {
		if (part[0] !== '[') {
			return `${part} `;
		} else {
			return (
				<Box
					as="span"
					paddingTop="3px"
					paddingBottom="3px"
					paddingLeft="8px"
					paddingRight="8px"
					// boxSizing='border-box'
					fontWeight="700"
					fontSize="15px"
					bg="#5a5959"
					borderRadius="4px"
					ml="6px"
					mr="6px"
				>
					{part.split('[')[1].split(']')[0]}
				</Box>
			);
		}
	});

	return component;
}

export const CommandCard = ({ command, currentBrainId }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [skillpack, setSkillpack] = useState({});

	const moduleIconUrls = command.apps.map((app) => app.thumbnail);
	const trimIcons = moduleIconUrls > 2;

	const moduleIcons = moduleIconUrls
		.slice(0, 2)
		.map((url) => <AppIcon thumbnailUrl={url} />);
	if (moduleIconUrls.length > 2) {
		const remaining = moduleIconUrls.length - 2;
		moduleIcons.push(
			<Flex
				bg="#3f3f3f"
				justifyContent="center"
				alignItems="center"
				borderRadius="20px"
				height="30px"
				width="30px"
				fontSize="12px"
				fontWeight="600"
			>
				{remaining}+
			</Flex>
		);
	}

	return (
		<>
			<Box
				bg="#424242"
				height={`220px`}
				// width={`${570 * scale}px`}
				// marginRight="0.6rem"
				marginBottom="0.6rem"
				borderRadius="10px"
				// border="solid 2px"
				cursor="pointer"
				display="flex"
				flexDir="column"
				// transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
				_hover={{ borderColor: 'purple.500' }}
				transition="all 0.3s cubic-bezier(.08,.52,.52,1)"
				border="2px solid transparent"
				_hover={{
					border: '2px solid #805ad5',
				}}
				onClick={onOpen}
				// bg='tomato'
			>
				<Box // Icon space
					bg="#4a4a4a"
					// bg='tomato'
					height="60px"
					borderTopLeftRadius="10px"
					borderTopRightRadius="10px"
					width="100%"
					display="flex"
					alignItems="center"
					padding="0px 16px"
				>
					{moduleIcons}
				</Box>
				<Box
					// bg='green'
					borderBottomRadius="10px"
					height="60px"
					flex="1 1 auto"
					boxSizing="border-box"
					padding="0px 16px"
					display="flex"
					flexDir="column"
				>
					<Box
						// bg='pink'
						flex="1 1 auto"
					>
						<Box
							margin="12px 0px 0px 0px"
							fontSize="18px"
							lineHeight="26px"
							// fontWeight='600'
						>
							{/* {parseIntent(command.heading)} */}
							{parseIntent(command.title)}
						</Box>
						<Box
							margin="8px 0px 0px 0px"
							fontSize="14px"
							color="#ccc"
							height="44px"
							display="-webkit-box"
							overflow="hidden"
							opacity="0.9"
							css={{
								'-webkit-box-orient': 'vertical',
								'-webkit-line-clamp': '2',
							}}
							// bg='green'
						>
							{command.description}
						</Box>
					</Box>
				</Box>
				<Box
					height="50px"
					padding="0px 16px"
					display="flex"
					justifyContent="space-between"
				>
					<Box display="flex" alignItems="center">
						<FiDownloadCloud />
						<Box ml="8px" fontSize="16px">
							{command.installs}
						</Box>
					</Box>
					<Box display="flex" alignItems="center">
						<Button // I'm using this as a label because I do not have time. I sincerely apologise.
							size="xs"
							marginRight="8px"
							bg="#515151"
							cursor="default"
							_hover={{
								backgroundColor: '#515151',
							}}
						>
							Command
						</Button>
						<LightMode>
							<Button size="xs" colorScheme="purple" onClick={onOpen}>
								Try it
							</Button>
						</LightMode>
					</Box>
				</Box>
			</Box>
			{isOpen ? ( // Mounting this component causes the skillpack request to fire in this case. See it's code.
				<StorePackModal
					isOpen={isOpen}
					onClose={onClose}
					publishedSkillPackId={command.skillpack}
					currentBrainId={currentBrainId}
				/>
			) : null}
		</>
	);
};

export const CommandCardSkeleton = (props) => {
	const { scale = 1 } = props;
	return (
		<Box
			bg={useColorModeValue('white', '#424242')}
			// height={`${170 * scale}px`}
			height="220px"
			marginBottom="0.6rem"
			borderRadius="10px"
			border="solid 2px"
			// display="flex"
			_hover={{ borderColor: 'purple.500' }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
			{...props}
		>
			<Box
				display="flex"
				height="60px"
				// width={`${295 * scale}px`}
				alignItems="center"
				justifyContent="center"
				bg="#4a4a4a"
			></Box>
			<Box
				width="100%"
				display="flex"
				// height="100%"
				alignItems="left"
				flexDirection="column"
				borderBottomRightRadius="5px"
				borderBottomLeftRadius="5px"
				bg={useColorModeValue('#f9f9f9', '#424242')}
				fontSize="0.8rem"
				borderTop="solid 1px"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				color={useColorModeValue('#555555', 'white')}
				paddingX={`${1.4 * scale}rem`}
				paddingTop={`${1 * scale}rem`}
				opacity="0.9"
				// bg='tomato'
			>
				<Box
					width="55%"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					height="15px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					opacity="0.9"
					marginY={`${8 * scale}px`}
				></Box>
				<Box
					width="40%"
					display="flex"
					height="15px"
					alignItems="center"
					justifyContent="space-between"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#4a4a4a')}
					fontSize="0.8rem"
					borderTop="solid 1px"
					borderRadius="10px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					color={useColorModeValue('#555555', 'white')}
					paddingX={`${0.5 * scale}rem`}
					paddingTop={`${0.4 * scale}rem`}
					opacity="0.9"
					marginY={`${8 * scale}px`}
				/>
			</Box>
		</Box>
	);
};
