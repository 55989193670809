import * as React from 'react';
import { IconContext } from 'react-icons';
const IconContainer = ({ icon, value }) => {
	return (
		<IconContext.Provider value={value}>
			<div>{icon}</div>
		</IconContext.Provider>
	);
};

export default IconContainer;
