import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';
//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */
const getToken = async ({ brainId, slug }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	var getTokenFn;
	if (!isElectron()) {
		getTokenFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.getToken.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.getToken.PATH_METHOD,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	} else {
		getTokenFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getTokenIPC.EVENT,
			PATH_QUERY: brainId,
			URL_PARAM: {
				profileSlug: profileSlug,
			},
		});
	}

	var getTokenResponse = await getTokenFn();
	return getTokenResponse['data'];
};

export default getToken;
