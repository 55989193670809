/**
 * Brain Reducer
 *
 * Creates a Redux reducer for populating the robots.
 */

import actionTypes from '../actionTypes';
import produce from 'immer';

// Setup initial state with an fleet info object.
const initialState = {
	checkoutModalIsOpen: false,
};

// Export the Brain Reducer.
const subscriptionsReducer = produce(
	/**
	 *
	 * @param {typeof initialState} draft
	 * @param {{
	 * 	type: keyof typeof actionTypes,
	 * 	payload: unknown
	 * }} action
	 * @returns
	 */
	(draft, action) => {
		switch (action.type) {
			case actionTypes.SUBSCRIPTIONS_CHECKOUT_MODAL_UPDATE: {
				/**
				 * @type {import('./types/subscriptionsReducer').SubscriptionsActionPayloads['SUBSCRIPTIONS_CHECKOUT_MODAL_UPDATE']}
				 */
				const payload = action.payload;
				const { checkoutModalIsOpen } = payload;
				draft.checkoutModalIsOpen = checkoutModalIsOpen;
				return draft;
			}
			default:
				return draft;
		}
	},
	initialState
);

export default subscriptionsReducer;
