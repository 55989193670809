import React from 'react';
import { Box, Tooltip, useColorMode } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import MayaSkeleton from '../../util/ContentLoaders/MayaSkeleton';
import IconContainer from '../../util/React_Icon';

/**
 * HorizontalItem is a resuable component used to render
 * all those wide horizontal cards.
 *
 * @prop {boolean} isOpen - If true, the card will show expanded content too.
 * @prop {function} onClickItem - Called when the card is clicked
 * @prop {component} itemLeft - The component to be rendered in the left portion of the card
 * @prop {component[]} buttons - List of buttons to show in the right portion of the card
 * @prop {component} expandedContent - Component to be rendered in expanded portion of the card
 */
const HorizontalItem = ({
	isOpen,
	onClickItem,
	selected,
	itemLeft,
	buttons,
	isItemExpanded,
	expandedContent,
}) => {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.250',
	};

	const borderColor = {
		light: '#f2f2f2',
		dark: '#403f3f',
	};

	return (
		<motion.div
			layout="position"
			animate={{ borderRadius: '8px' }}
			onClick={onClickItem}
			style={{
				border: `solid 2px`,
				marginBottom: '15px',
				borderColor: selected ? '#805AD5' : borderColor[colorMode],
				overflow: 'hidden',
				cursor: 'pointer',
				boxShadow: 'rgba(0, 0, 0, 0.09) 1px 2px 4px',
				width: '100%',
				zIndex: 5,
			}}
		>
			<motion.div layout>
				<Box
					padding="10px 0px"
					bg={bgColor[colorMode]}
					borderRadius="8px"
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					zIndex="4"
				>
					{itemLeft}
					{buttons}
				</Box>
			</motion.div>
			<AnimatePresence>
				{isOpen && <Content>{expandedContent}</Content>}
			</AnimatePresence>
		</motion.div>
	);
};

function Content({ children }) {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: '#F8F8F8',
		dark: 'transparent',
	};
	return (
		<motion.div
			style={{
				backgroundColor: bgColor[colorMode],
			}}
			layout
			initial={{ opacity: 0, height: 0 }}
			animate={{ opacity: 1, height: 'auto' }}
			exit={{ opacity: 0, height: 0 }}
		>
			{children}
		</motion.div>
	);
}

export const HorizontalItemButton = ({
	icon,
	text,
	hoveredButton,
	setHoveredButton,
	hovered,
	buttonKey,
	onClick,
	isDisabled,
	tooltip,
}) => {
	const { colorMode } = useColorMode();

	const fontColor = {
		light: '#6C6C6C',
		dark: 'white',
	};

	const iconHighlightColor = {
		light: '#F4F4F4',
		dark: '#5B5B5B',
	};

	return (
		<Tooltip label={tooltip}>
			<Box
				// bg="tomato"
				padding="5px"
				_hover={{
					bg: iconHighlightColor[colorMode],
					paddingTop: '3px',
				}}
				height="70px"
				width="70px"
				display="flex"
				alignItems="center"
				justifyContent="center"
				borderRadius="10px"
				mr="3"
				opacity={isDisabled ? '0.7' : '1'}
				flexDirection="column"
				transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
				onMouseEnter={() => setHoveredButton(buttonKey)}
				onMouseLeave={() => setHoveredButton('')}
				onClick={isDisabled ? () => {} : () => onClick()}
			>
				<IconContainer
					icon={icon}
					value={{
						color: fontColor[colorMode],
						className: 'global-class-name',
						size: '28px',
					}}
				/>
				{text ? (
					<Box
						fontFamily="body"
						fontSize="12px"
						color={fontColor[colorMode]}
						fontWeight="600"
						marginTop="5px"
						textAlign="center"
						opacity="0.8"
					>
						{text}
					</Box>
				) : null}
			</Box>
		</Tooltip>
	);
};

export const HorizontalItemSkeleton = ({ width }) => {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.250',
	};
	const borderColor = {
		light: '#f2f2f2',
		dark: 'dashboard.border_dark',
	};
	return (
		<Box>
			<Box
				height="130px"
				bg={bgColor[colorMode]}
				borderRadius="10px"
				display="flex"
				alignItems="center"
				maxWidth="1588px"
				marginBottom="15px"
				border={`solid 1px`}
				borderColor={borderColor[colorMode]}
				boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 6px"
			>
				<Box height="80%" display="flex">
					<MayaSkeleton
						borderRadius="6px"
						width="155px"
						height="100%"
						ml="2"
					/>
					<Box height="80%" ml="2">
						<MayaSkeleton
							borderRadius="9px"
							width="150px"
							height="20px"
							ml="2"
							mt="3"
						/>
						<Box display="flex">
							<MayaSkeleton
								borderRadius="9px"
								width="150px"
								height="20px"
								ml="2"
								mt="2"
							/>
							<MayaSkeleton
								borderRadius="9px"
								width="100px"
								height="20px"
								ml="2"
								mt="2"
							/>
						</Box>
						<Box display="flex">
							<MayaSkeleton
								borderRadius="9px"
								width="80px"
								height="20px"
								ml="2"
								mt="2"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default HorizontalItem;
