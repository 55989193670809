import React from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';
//Components
import InstalledSkills from './InstalledSkills';
import QuickLinks from './QuickLinks';
import { FiShoppingBag } from 'react-icons/fi';
import { HistoryWithSlug } from '../../util/History';
import AccordionSection from './AccordionSection';

import getDashboardData from '../../functions/dashboard/getDashboard';
import GetProfileSlug from '../../util/ProfileSlug';
import LongRunningTasks from '../Tasks';

// ACTIONS
import { setDashboardInfo } from '../../redux/actions';

const WelcomeSection = ({ user }) => {
	const chooseRandomMessage = () => {
		let msgArray1 = [
			'Why is there something instead of nothing?',
			"What's outside the simulation?",
			'Is there a merciful god?',
			'What would you like to do?',
		];

		let msgArray2 = [`Here's what's going on.`];

		let msgArray = msgArray2;

		return msgArray[Math.floor(Math.random() * msgArray.length)];
	};

	return (
		<Box mb="5px">
			<Box fontWeight="600" fontSize="2rem" mb="5px">
				{`Hello, ${user.name ? user.name : 'Samurai'}.`}
			</Box>
			<Box
				width="40px"
				height="2px"
				borderRadius="5px"
				bgColor="purple.400"
				mb="5px"
			/>
			{
				<Box fontWeight="400" fontSize="1rem">
					{`${chooseRandomMessage()}`}
				</Box>
			}
		</Box>
	);
};

const MayaStoreButton = ({ onClick }) => {
	return (
		<>
			<Button
				fontWeight="500"
				fontSize="0.84rem"
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				paddingX="0.5rem"
				width="7.8rem"
				height="1.8rem"
				borderRadius="0.3rem"
				bg="#464646"
				cursor="pointer"
				_hover={{ bg: '#565656' }}
				onClick={onClick}
			>
				<Box alignItems="center" paddingY="0.15rem">
					<FiShoppingBag size="0.9rem" />
				</Box>
				{'View in Store'}
			</Button>
		</>
	);
};

/**
 * Home Section
 *
 * @param {user} user
 * @param {brains} setDashboardInfo
 * @returns
 */

const HomeSection = ({ user, brains, setDashboardInfo }) => {
	const { slug } = GetProfileSlug();
	React.useEffect(() => {
		getDashboardData({ slug }).then(async (res) => {
			setDashboardInfo(res);
		});
	}, [slug]);
	return (
		<Flex direction="column" minHeight="0" overflow="auto">
			<WelcomeSection user={user} />
			<AccordionSection
				title={'Quick Links'}
				expandedContent={<QuickLinks />}
			/>
			<AccordionSection
				title={'Installed Collections'}
				expandedContent={<InstalledSkills brains={brains} />}
				rightHeaderContent={
					<MayaStoreButton
						onClick={() => {
							HistoryWithSlug.push('/store');
						}}
					/>
				}
			/>
			<AccordionSection
				title={'Tasks'}
				expandedContent={<LongRunningTasks />}
			/>
		</Flex>
	);
};

const mapStateToProps = (state) => {
	let { profiles } = state;
	let { user } = profiles;
	let { brains } = state;
	return { user, brains };
};

export default connect(mapStateToProps, { setDashboardInfo })(HomeSection);
