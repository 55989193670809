import { config } from '../../config';
import APIFetch from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

/**
 * Creates a checkout page based on the selected plan and returns its `url`
 * @param {{
 * 	plan: 'monthly' | 'annual'
 * }}
 */
export const getCheckoutUrl = async ({ plan, currency }) => {
	const apifetch = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getCheckoutPageURL.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getCheckoutPageURL.PATH_METHOD,
	});
	const response = await apifetch({ plan, currency });
	/**
	 * @type {{
	 *		error: boolean,
	 *		results: {
	 *			hosted_page: {
	 *				url: string
	 *			}
	 *		}
	 *	}}
	 */
	const data = response.data;
	return data;
};

/**
 * Used to set tier of the user via set cookie and update subscription details (if any)
 */
export const verifySubscriptionAPI = async () => {
	const apifetch = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.verifySubscription.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.verifySubscription.PATH_METHOD,
	});
	const response = await apifetch();
	/**
	 * @type {{
	 * 	error: boolean,
	 * 	results: {
	 * 	    status: string,
	 * 		 profile: {
	 * 		   tier: 'FREE' | 'PREMIUM',
	 * 		 },
	 * 	    subscription?: {
	 * 			plan: 'MONTHLY' | 'ANNUAL',
	 *				status: 'active' | 'non_renewing',
	 *				subscription_id: string,
	 *				start_date: string,
	 *				end_date?: string,
	 *				service: {
	 *				method: "chargebee",
	 *				chargebee: {
	 *				  id: string,
	 *				  billing_period_unit: string,
	 *				  current_term_start: number,
	 *				  current_term_end: number,
	 *				  next_billing_at: number,
	 *				  created_at: number,
	 *				  started_at: number,
	 *				  activated_at: number,
	 *				},
	 * 		}
	 * 	}
	 * }}
	 */
	const data = response.data;
	return data;
};

/**
 * Delete Subscription API
 */
export const deleteSubscriptionAPI = async () => {
	const apifetch = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.deleteSubscription.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.deleteSubscription.PATH_METHOD,
	});
	const response = await apifetch({});
	/**
	 * @type {{
	 *  status: boolean
	 * }}
	 */
	const data = response.data;
	return data;
};

/**
 * Cancel Subscription API
 */
export const cancelSubscriptionAPI = async () => {
	const apifetch = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.cancelSubscription.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.cancelSubscription.PATH_METHOD,
	});
	const response = await apifetch({});
	/**
	 * @type {{
	 *  status: boolean
	 * }}
	 */
	const data = response.data;
	return data;
};

export const getPaymentEnabledStatus = async () => {
	const apiFetch = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.paymentsStatus.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.paymentsStatus.PATH_METHOD,
	});

	const response = await apiFetch();
	/**
	 * @type {{
	 *  status: boolean
	 * }}
	 */
	const data = response.data;
	return data;
};
