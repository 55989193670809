import React from 'react'
import {
    Box, Flex
} from '@chakra-ui/react'

import CommandList from './CommandList'

const CommandSection = ({ categories, currentBrainId, apps }) => {
    return (
        <Box
			display="flex"
			flexDirection="column"
			paddingY="12px"
			marginLeft="20px"
			flex="1 1 auto"
		>
			<CommandList
                categories={categories}
				currentBrainId={currentBrainId}
				apps={apps}
            />
		</Box>
    )
}

export default CommandSection