import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
//Components
// import { ModuleItemCard } from './ModuleItem';
import { ModuleItemCard } from './CompactModuleCard';
import { HorizontalCardSkeleton } from '../Reusable/HorizontalCard';
import { Box, Wrap, WrapItem, SimpleGrid } from '@chakra-ui/react';

//Redux
import { connect } from 'react-redux';
import { updateBrainById } from '../../redux/actions';
import { addModules } from '../../redux/actions';

//Functions
import getStoreModules from '../../functions/store/getStoreModules';
import { isModuleInstalled, normalize } from '../../util/Misc';
import GetProfileSlug from '../../util/ProfileSlug';
import querySearch from '../../util/Search';

const ModuleList = ({
	modules,
	categories,
	apps,
	brains,
	query,
	currentBrainId,
	updateBrainById,
	addModules,
}) => {
	const loadingList = [1, 2, 3, 4, 5, 6, 7, 8];
	const [moduleList, setModuleList] = React.useState([]);
	const loc = useLocation();
	let URLQuery = new URLSearchParams(loc.search);
	const [id, setId] = React.useState(() => {
		if (loc.pathname === '/store/modules') {
			return URLQuery.get('id');
		} else return null;
	});

	// React.useEffect(() => {
	// 	let filteredList = querySearch(moduleList, query, 'modules');
	// 	let finalList = [];
	// 	for (let id of filteredList.allIds) {
	// 		finalList.push(filteredList.byId[id]);
	// 	}
	// 	setModuleList(finalList);
	// }, [query]);

	const [isLoading, setLoading] = React.useState(true);
	const { slug } = GetProfileSlug();

	React.useEffect(() => {
		setModuleList([]);
		getStoreModules({
			_id: id,
			slug: slug,
			configOnly: false,
			categories,
			apps,
			search: query
		}).then((res) => {
			let list = Array.isArray(res) ? res : [res];
			setLoading(false);
			addModules(normalize(list));
			console.log('modulere LIST2', list)
			setModuleList(list);
		});
	}, [addModules, setModuleList, slug, categories, apps, query]);

	const checkIsInstalled = (currentBrainId, moduleId) => {
		let check;
		if (currentBrainId && brains.byId[currentBrainId]) {
			check =
				_.findIndex(brains.byId[currentBrainId].modules, (module) => {
					return module.module === moduleId;
				}) !== -1;
		} else {
			check = false;
		}

		return check;
	};

	console.log('modulere LIST', moduleList)

	return (
		<Box
			position="relative"
			width="100%"
			display="flex"
			flex="1 1 auto"
			overflow="auto"
			// bg='blue.400'
		>
			<Box
				// bg='yellow.400'
				flex="1 1 auto"
				position="absolute"
				width="100%"
			>
				{!isLoading && moduleList.length === 0 ? (
					<Box
						textAlign="center"
						height="100%"
						width="100%"
						display="flex"
						flexDirection="column"
						justifyContent="center"
						zIndex="50"
					>
						<Box>Couldn't find what you were looking for?</Box>
						<Box
							color="purple.300"
							onClick={() => {
								window.Intercom('show');
							}}
							_hover={{ color: 'purple.200' }}
							cursor="pointer"
						>
							Suggest a module
						</Box>
					</Box>
				) : null}
				{/* <SimpleGrid
					// bg='red'
					width='100%'
					spacing='8px'
					minChildWidth='300px'
				>  */}
				<Box display="grid" gridTemplateColumns="1fr 1fr 1fr">
					{!isLoading
						? moduleList.length > 0 &&
						  moduleList.map((module) => {
								return process.env.NODE_ENV === 'production' ? (
									!module.private && (
										<ModuleItemCard
											module={module}
											openModal={moduleList.length === 1}
											currentBrainId={currentBrainId}
											updateBrainById={updateBrainById}
											isInstalled={checkIsInstalled(
												currentBrainId,
												module._id
											)}
										/>
									)
								) : (
									<ModuleItemCard
										module={module}
										openModal={moduleList.length === 1}
										currentBrainId={currentBrainId}
										updateBrainById={updateBrainById}
										isInstalled={checkIsInstalled(
											currentBrainId,
											module._id
										)}
									/>
								);
						  })
						: loadingList.map((item) => {
								return <HorizontalCardSkeleton key={item} />;
						  })}
					{/* </SimpleGrid> */}
				</Box>
			</Box>
		</Box>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	let { modules } = state;
	let { query } = state.navigation;
	const brainIds = brains.allIds;
	const { isLoading } = state.dashboard;
	return { brainIds, brains, isLoading, query, modules };
};

export default connect(mapStateToProps, { updateBrainById, addModules })(
	ModuleList
);
