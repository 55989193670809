/**
 * Redux Action Types
 *
 * Available action types for Redux dispatch operations
 * for the application.
 */

const actionTypes = {
	// Navigation
	SET_NAV_ROUTE: 'SET_NAV_ROUTE',
	SET_NAV_SEARCH_QUERY: 'SET_NAV_SEARCH_QUERY',
	SET_NAV_PAGE_MODE: 'SET_NAV_PAGE_MODE',
	SET_NAV_LOGGED_IN: 'SET_NAV_LOGGED_IN',
	// User
	SET_USER: 'SET_USER',
	LOGOUT_USER: 'LOGOUT_USER',
	SET_STATUS: 'SET_STATUS',
	// Devices
	SET_DEVICES: 'SET_DEVICES',
	SET_CURRENT_DEVICE: 'SET_CURRENT_DEVICES',
	CLEAR_DEVICES: 'CLEAR_DEVICES',
	//Profiles
	SET_PROFILES: 'SET_PROFILES',
	SET_CURRENT_PROFILE: 'SET_CURRENT_PROFILE',
	SET_CONTACTS: 'SET_CONTACTS',
	CLEAR_PROFILES: 'CLEAR_PROFILES',
	CURRENT_PROFILE_TIER_UPDATE: 'CURRENT_PROFILE_TIER_UPDATE',
	// Brains
	ADD_BRAINS: 'ADD_BRAINS',
	REMOVE_BRAIN: 'REMOVE_BRAIN',
	UPDATE_BRAIN: 'UPDATE_BRAIN',
	UPDATE_BRAIN_BY_ID: 'UPDATE_BRAIN_BY_ID',
	CLEAR_BRAINS: 'CLEAR_BRAINS',
	// PublishedSkillPacks
	ADD_PUBLISHEDSKILLPACKS: 'ADD_PUBLISHEDSKILLPACKS',
	REMOVE_PUBLISHEDSKILLPACK: 'REMOVE_PUBLISHEDSKILLPACK',
	UPDATE_PUBLISHEDSKILLPACK: 'UPDATE_PUBLISHEDSKILLPACK',
	UPDATE_PUBLISHEDSKILLPACK_BY_ID: 'UPDATE_PUBLISHEDSKILLPACK_BY_ID',
	CLEAR_PUBLISHEDSKILLPACKS: 'CLEAR_PUBLISHEDSKILLPACKS',
	// Brains
	ADD_MODULES: 'ADD_MODULES',
	REMOVE_MODULE: 'REMOVE_MODULE',
	UPDATE_MODULE: 'UPDATE_MODULE',
	UPDATE_MODULE_BY_ID: 'UPDATE_MODULE_BY_ID',
	CLEAR_MODULES: 'CLEAR_MODULES',
	// Dashboard
	SET_LOADING: 'SET_LOADING',
	SET_TRIAL_EXPIRY_MODAL_DISPLAY: 'SET_TRIAL_EXPIRY_MODAL_DISPLAY',
	// Tabs - only for Electron
	ADD_TAB: 'ADD_TAB',
	SWITCH_TAB: 'SWITCH_TAB',
	CLOSE_TAB: 'CLOSE_TAB',
	CLEAR_TABS: 'CLEAR_TABS',
	// Updates - only for Electron
	UPDATE_AVAILABLE: 'UPDATE_AVAILABLE',
	CHECKING_FOR_UPDATE: 'CHECKING_FOR_UPDATE',
	UPDATE_NOT_AVAILABLE: 'UPDATE_NOT_AVAILABLE',
	UPDATE_ERROR: 'UPDATE_ERROR',
	UPDATE_DOWNLOAD_PROGRESS: 'UPDATE_DOWNLOAD_PROGRESS',
	UPDATE_DOWNLOADED: 'UPDATE_DOWNLOADED',
	UPDATE_NOT_NOW: 'UPDATE_NOT_NOW',
	// Store
	SET_STORE_SEARCH: 'SET_STORE_SEARCH',
	SET_STORE_TYPES: 'SET_STORE_TYPES',
	SET_STORE_CATEGORIES: 'SET_STORE_CATEGORIES',
	SET_STORE_PLATFORMS: 'SET_STORE_PLATFORMS',
	// CardUI
	// Flow level actions
	CUI_SWITCH_OVERALL_MODE: 'SWITCH_OVERALL_MODE',
	CUI_SET_SELECTED_CARD_INDEX: 'SET_SELECTED_CARD_INDEX',
	CUI_NEW_SEARCH_FLOW: 'IPC::NEW_SEARCH_FLOW',
	CUI_DELETE_SEARCH_FLOW: 'IPC::DELETE_SEARCH_FLOW',
	// Card level actions
	CUI_SET_CARD_INTERACTION: 'SET_CARD_INTERACTION',
	CUI_SET_SEARCH_SUBMIT_PROGRESS: 'SET_SEARCH_SUBMIT_PROGRESS',
	CUI_SET_SEARCH_LOADING_STATUS: 'SET_SEARCH_LOADING_STATUS',
	CUI_SET_SEARCH_MODE: 'CUI_SET_SEARCH_MODE',
	CUI_SET_CARD_MAXIMIZED: 'SET_CARD_MAXIMIZED',
	CUI_NEW_CARD: 'NEW_CARD',
	CUI_DELETE_CARD: 'DELETE_CARD',
	CUI_TOGGLE_CARDS_VISIBILITY: 'TOGGLE_CARDS_VISIBILITY',
	CUI_TOGGLE_CARDS_ENABLED: 'TOGGLE_CARDS_ENABLED',
	CUI_TOGGLE_DISPLAY_MODE: 'TOGGLE_DISPLAY_MODE',
	CUI_SET_CARD_WIDTH: 'SET_CARD_WIDTH',
	CUI_TOGGLE_SEARCH_DISPLAY_MODE: 'CUI_TOGGLE_SEARCH_DISPLAY_MODE',
	// Intents
	CUI_ADD_INTENTS: 'CUI_ADD_INTENTS',
	// Top section actions
	CUI_SET_TOP_SEARCH_CONTENT: 'SET_TOP_SEARCH_CONTENT',
	CUI_SET_TOP_SEARCH_APP: 'SET_TOP_SEARCH_APP',
	CUI_SET_TOP_INTENT_ARRAY: 'SET_TOP_INTENT_ARRAY',

	// Middle section action
	CUI_SET_MIDDLE_SEARCH_ITEMS: 'SET_MIDDLE_SEARCH_ITEMS',
	CUI_HIGHLIGHT_MIDDLE_SEARCH_ITEM: 'HIGHLIGHT_MIDDLE_SEARCH_ITEM',
	CUI_SELECT_MIDDLE_SEARCH_ITEM: 'SELECT_MIDDLE_SEARCH_ITEM',
	CUI_SET_MIDDLE_EDITOR_CHART: 'SET_MIDDLE_EDITOR_CHART',
	CUI_SET_MIDDLE_SEARCH_MODE_AND_TYPE: 'SET_MIDDLE_SEARCH_MODE_AND_TYPE',
	CUI_SET_MIDDLE_SEARCH_ITEM_SELECTED_INDEX:
		'CUI_SET_MIDDLE_SEARCH_ITEM_SELECTED_INDEX',
	CUI_SET_VIBRATE_INSTRUCTIONS: 'CUI_SET_VIBRATE_INSTRUCTIONS',
	// PlaceHolder
	CUI_SET_PLACEHOLDER_VALUE_BY_ID: 'SET_PLACEHOLDER_VALUE_BY_ID',
	CUI_SET_TOP_CURRENT_PLACEHOLDER_ID: 'SET_TOP_CURRENT_PLACEHOLDER_ID',
	CUI_SET_MIDDLE_PLACEHOLDER_TEXT: 'SET_MIDDLE_PLACEHOLDER_TEXT',
	CUI_HIGHLIGHT_PLACEHOLDER_SEARCH_ITEM: 'HIGHLIGHT_PLACEHOLDER_SEARCH_ITEM',
	CUI_SET_PLACEHOLDER_SEARCH_ITEMS: 'SET_PLACEHOLDER_SEARCH_ITEMS',
	// Command bar status item
	CUI_UPDATE_COMMAND_BAR_STATUS: 'CUI_UPDATE_COMMAND_BAR_STATUS',
	// Usersnap
	SUPPORT_WIDGET_SET_OPEN: 'SUPPORT_WIDGET_SET_OPEN',
	SUPPORT_WIDGET_SET_WIDGET_OPEN: 'SUPPORT_WIDGET_SET_WIDGET_OPEN',
	// CardUI V2
	CUIV2_SET_COMMANDBAR_VISIBLE: 'CUIV2_SET_COMMANDBAR_VISIBLE',
	CUIV2_ENTITIES_UPSERT: 'CUIV2_ENTITIES_UPSERT',
	CUIV2_SEARCH_CANCEL: 'CUIV2_SEARCH_CANCEL',
	CUIV2_SEARCH_INPUT_KEYDOWN: 'CUIV2_SEARCH_INPUT_KEYDOWN',
	CUIV2_SEARCH_SUBMIT: 'CUIV2_SEARCH_SUBMIT',
	CUIV2_SEARCH_CHANGE: 'CUIV2_SEARCH_CHANGE',
	CUIV2_SEARCH_ADDON_CLOSE: 'CUIV2_SEARCH_ADDON_CLOSE',
	CUIV2_SUGGESTIONS_TAB_CLICK: 'CUIV2_SUGGESTIONS_TAB_CLICK',
	CUIV2_VIBRATE_INSTRUCTIONS_CHANGE: 'CUIV2_VIBRATE_INSTRUCTIONS_CHANGE',
	CUIV2_PLACEHOLDER_SEARCH_CANCEL: 'CUIV2_PLACEHOLDER_SEARCH_CANCEL',
	CUIV2_PLACEHOLDER_SEARCH_INIT: 'CUIV2_PLACEHOLDER_SEARCH_INIT',
	CUIV2_PLACEHOLDER_SEARCH_CHANGE: 'CUIV2_PLACEHOLDER_SEARCH_CHANGE',
	CUIV2_PLACEHOLDER_SEARCH_NEXT: 'CUIV2_PLACEHOLDER_SEARCH_NEXT',
	CUIV2_SUGGESTIONS_MODE_CHANGE: 'CUIV2_SUGGESTIONS_MODE_CHANGE',
	CUIV2_PLACEHOLDER_SUGGESTIONS_CHANGE: 'CUIV2_PLACEHOLDER_SUGGESTIONS_CHANGE',
	CUIV2_PLACEHOLDER_INPUT_KEYDOWN: 'CUIV2_PLACEHOLDER_INPUT_KEYDOWN',
	CUIV2_SET_PLACEHOLDER_LOADING: 'CUIV2_SET_PLACEHOLDER_LOADING',
	CUIV2_COMMAND_BAR_STATUS_UPDATE: 'CUIV2_COMMAND_BAR_STATUS_UPDATE',
	CUIV2_CARD_MAXIMIZE: 'CUIV2_CARD_MAXIMIZE',
	CUIV2_CARD_MINIMIZE: 'CUIV2_CARD_MINIMIZE',
	CUIV2_CARD_CLOSE: 'CUIV2_CARD_CLOSE',
	CUIV2_CARD_NAVIGATE: 'CUIV2_CARD_NAVIGATE',
	CUIV2_STORE_SEARCH_SUBMIT: 'CUIV2_STORE_SEARCH_SUBMIT',
	CUIV2_STARTUP_INTENTS_INIT: 'CUIV2_STARTUP_INTENTS_INIT',
	CUIV2_STARTUP_INTENTS_LOAD: 'CUIV2_STARTUP_INTENTS_LOAD',
	// Command/Intent History
	COMMAND_HISTORY_LOG_INTENT_EVENT: 'COMMAND_HISTORY_LOG_INTENT_EVENT',
	// Tasks
	ADD_TASK: 'ADD_TASK',
	UPDATE_TASK: 'UPDATE_TASK',
	// Subscription/Payments
	SUBSCRIPTIONS_CHECKOUT_MODAL_UPDATE: 'SUBSCRIPTIONS_CHECKOUT_MODAL_UPDATE',
};

export default actionTypes;
