// Dependency imports

import IPCFunction from '../../util/IPCFetch/function';
import isElectron from 'is-electron';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to open and navigate to route on the main window
 */

const navigateToMainWindowRoute = async (arg) => {
	if (isElectron()) {
		var navigateToMainWindowRouteIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.navigateToMainWindowRoute.EVENT,
		});
		return navigateToMainWindowRouteIPCFn(arg);
	} else {
		return {};
	}
};

export default navigateToMainWindowRoute;
