// Action Types
import actionTypes from '../actionTypes';

import { v4 as uuidv4 } from 'uuid';

export const setSelectedCardIndex = (index) => {
	return {
		type: actionTypes.CUI_SET_SELECTED_CARD_INDEX,
		payload: { index },
	};
};

export const newCard = (card) => {
	let cardId = 'Card-' + uuidv4();

	return {
		type: actionTypes.CUI_NEW_CARD,
		payload: { cardId, card },
	};
};

export const deleteCard = (cardId) => {
	return {
		type: actionTypes.CUI_DELETE_CARD,
		payload: { cardId },
	};
};

export const setCardsExpandable = (enabled) => {
	return {
		type: actionTypes.CUI_SET_CARD_INTERACTION,
		payload: { enabled },
	};
};

export const toggleCardsVisibility = (visibility) => {
	return {
		type: actionTypes.CUI_TOGGLE_CARDS_VISIBILITY,
		payload: { visibility },
	};
};
export const toggleCardsEnabled = (enabled) => {
	return {
		type: actionTypes.CUI_TOGGLE_CARDS_ENABLED,
		payload: { enabled },
	};
};

export const toggleDisplayMode = (cardId, displayMode) => {
	return {
		type: actionTypes.CUI_TOGGLE_DISPLAY_MODE,
		payload: { cardId, displayMode },
	};
};

export const toggleSearchDisplayMode = (displayMode) => {
	return {
		type: actionTypes.CUI_TOGGLE_SEARCH_DISPLAY_MODE,
		payload: { displayMode },
	};
};

export const setCardWidth = (cardId, width) => {
	return {
		type: actionTypes.CUI_SET_CARD_WIDTH,
		payload: { cardId, width },
	};
};

export const setTopSearchContent = (cardId, array) => {
	return {
		type: actionTypes.CUI_SET_TOP_SEARCH_CONTENT,
		payload: { cardId, array },
	};
};

export const setTopSearchApp = (cardId, name) => {
	return {
		type: actionTypes.CUI_SET_TOP_SEARCH_APP,
		payload: { cardId, name },
	};
};

export const setTopIntentArray = (intentObjectArray) => {
	return {
		type: actionTypes.CUI_SET_TOP_INTENT_ARRAY,
		payload: { intentObjectArray },
	};
};

export const setTopCurrentPlaceholderId = (id) => {
	return {
		type: actionTypes.CUI_SET_TOP_CURRENT_PLACEHOLDER_ID,
		payload: { id },
	};
};

export const setMiddleSearchItems = (searchItems) => {
	return {
		type: actionTypes.CUI_SET_MIDDLE_SEARCH_ITEMS,
		payload: { searchItems },
	};
};

export const setMiddlePlaceholderText = (text) => {
	return {
		type: actionTypes.CUI_SET_MIDDLE_PLACEHOLDER_TEXT,
		payload: { text },
	};
};

export const highlightMiddleSearchItem = (direction) => {
	return {
		type: actionTypes.CUI_HIGHLIGHT_MIDDLE_SEARCH_ITEM,
		payload: { direction }, // Send direction : "UP" | "DOWN"
	};
};

export const selectMiddleSearchItem = () => {
	return {
		type: actionTypes.CUI_SELECT_MIDDLE_SEARCH_ITEM,
	};
};

export const setMiddleSearchItemSelectedIndex = (selectedIndex) => {
	return {
		type: actionTypes.CUI_SET_MIDDLE_SEARCH_ITEM_SELECTED_INDEX,
		payload: { selectedIndex },
	};
};

export const setMiddleSearchModeAndType = (mode, type) => {
	return {
		type: actionTypes.CUI_SET_MIDDLE_SEARCH_MODE_AND_TYPE,
		payload: { mode, type },
	};
};

export const addIntents = (intents) => {
	return {
		type: actionTypes.CUI_ADD_INTENTS,
		payload: { intents },
	};
};

export const setCardMaximized = (id, maximized) => {
	return {
		type: actionTypes.CUI_SET_CARD_MAXIMIZED,
		payload: { id, maximized },
	};
};

export const setSearchBarSubmitProgress = (progress) => {
	return {
		type: actionTypes.CUI_SET_SEARCH_SUBMIT_PROGRESS,
		payload: { progress },
	};
};

export const setSearchBarLoadingStatus = (status) => {
	return {
		type: actionTypes.CUI_SET_SEARCH_LOADING_STATUS,
		payload: { status },
	};
};

export const setSearchMode = (mode) => {
	return {
		type: actionTypes.CUI_SET_SEARCH_MODE,
		payload: { mode },
	};
};

export const highlightPlaceholderSearchItem = (direction) => {
	return {
		type: actionTypes.CUI_HIGHLIGHT_PLACEHOLDER_SEARCH_ITEM,
		payload: { direction },
	};
};

export const setPlaceholderSearchItems = (searchItems) => {
	return {
		type: actionTypes.CUI_SET_PLACEHOLDER_SEARCH_ITEMS,
		payload: { searchItems },
	};
};

export const setPlaceholderValueById = (id, value, meta) => {
	return {
		type: actionTypes.CUI_SET_PLACEHOLDER_VALUE_BY_ID,
		payload: { id, value, meta },
	};
};

export const setVibrateInstructions = (vibrateInstructions) => {
	return {
		type: actionTypes.CUI_SET_VIBRATE_INSTRUCTIONS,
		payload: { vibrateInstructions },
	};
};

export const updateCommandBarStatus = (visible, status, message) => {
	return {
		type: actionTypes.CUI_UPDATE_COMMAND_BAR_STATUS,
		payload: { visible, status, message },
	};
};
