import React from 'react';
import {
	Box,
	useColorMode,
	useColorModeValue,
	Image,
	Tooltip,
} from '@chakra-ui/react';
import _ from 'lodash';

const HorizontalBox = ({ onClick, children }) => {
	const { colorMode } = useColorMode();
	const hoverColor = { light: '#f5f5f5', dark: 'maya_dark.250' };
	const borderColor = { light: '#f9f9f9', dark: '#4a4949' };
	return (
		<Box display="flex" height="10rem" position="relative" zIndex="50">
			<Box
				height="10rem"
				width="100%"
				onClick={onClick}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				_hover={{ bg: hoverColor[colorMode] }}
				cursor="pointer"
				border="solid 2px"
				borderColor={borderColor[colorMode]}
				borderRadius="5px"
				padding="0.5rem"
				display="flex"
				overflow="auto"
				position="absolute"
			>
				{children}
			</Box>
		</Box>
	);
};

export const NewItem = ({ onClick, icon, text, isDisabled, tooltip }) => {
	return (
		<Tooltip label={tooltip} bg="maya_dark.150" color="white">
			<Box
				bg={useColorModeValue('white', 'maya_dark.150')}
				width="13rem"
				height="100%"
				borderRadius="5px"
				onClick={onClick}
				border="solid 2px"
				_hover={{
					borderColor: isDisabled ? 'transparent' : 'purple.500',
				}}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
			>
				<Box
					height="75%"
					display="flex"
					width="13rem"
					alignItems="center"
					justifyContent="center"
					opacity={isDisabled ? '0.6' : '1'}
				>
					{icon}
				</Box>
				<Box
					width="100%"
					display="flex"
					alignItems="center"
					justifyContent="center"
					borderBottomRightRadius="5px"
					borderBottomLeftRadius="5px"
					bg={useColorModeValue('#f9f9f9', '#565656')}
					height="25%"
					fontSize="0.8rem"
					color={useColorModeValue('#555555', 'white')}
					borderTop="solid 2px"
					borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
					opacity={isDisabled ? '0.8' : '1'}
				>
					{text}
				</Box>
			</Box>
		</Tooltip>
	);
};

export const ItemCard = ({
	onClick,
	thumbnail,
	leftText,
	rightText,
	isDisabled,
}) => {
	return (
		<Box
			bg={useColorModeValue('white', '#464646')}
			height="100%"
			marginLeft="0.5rem"
			borderRadius="5px"
			onClick={onClick}
			border="solid 2px"
			_hover={{ borderColor: 'purple.500' }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
		>
			<Box
				height="75%"
				display="flex"
				width="13rem"
				alignItems="center"
				justifyContent="center"
			>
				<Image
					src={thumbnail}
					width="99.5%"
					height="99.5%"
					borderRadius="5px 5px 0px 0px"
					objectFit="cover"
				/>
			</Box>
			<Box
				width="100%"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				borderBottomRightRadius="5px"
				borderBottomLeftRadius="5px"
				bg={useColorModeValue('#f9f9f9', '#565656')}
				height="25%"
				borderTop="solid 1px"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				color={useColorModeValue('#555555', 'white')}
				paddingX="0.5rem"
				fontSize="0.8rem"
			>
				<Box>
					{_.truncate(leftText, {
						length: 20,
						separator: /,? +/,
					})}
				</Box>
				<Box opacity="0.8">
					{_.truncate(rightText, {
						length: 10,
						separator: /,? +/,
					})}
				</Box>
			</Box>
		</Box>
	);
};

export const ItemCardSkeleton = () => {
	return (
		<Box
			bg={useColorModeValue('white', '#464646')}
			height="100%"
			marginLeft="0.5rem"
			borderRadius="5px"
			border="solid 2px"
			_hover={{ borderColor: 'purple.500' }}
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
			boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 2px"
		>
			<Box
				height="75%"
				display="flex"
				width="13rem"
				alignItems="center"
				justifyContent="center"
			></Box>
			<Box
				width="100%"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				borderBottomRightRadius="5px"
				borderBottomLeftRadius="5px"
				bg={useColorModeValue('#f9f9f9', '#565656')}
				height="25%"
				fontSize="0.8rem"
				borderTop="solid 1px"
				borderColor={useColorModeValue('#f9f9f9', '#4a4949')}
				color={useColorModeValue('#555555', 'white')}
				paddingX="0.5rem"
				opacity="0.9"
			></Box>
		</Box>
	);
};

export default HorizontalBox;
