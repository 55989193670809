import React from 'react';
import {
	Box,
	useColorMode,
	useColorModeValue,
	Tooltip,
} from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';
const HeaderWithDescription = (props) => {
	const { colorMode } = useColorMode();

	const borderColor = {
		light: 'gray.100',
		dark: 'dashboard.border_dark',
	};

	return (
		<Box
			fontFamily="body"
			borderBottom="solid 1px"
			borderColor={borderColor[colorMode]}
			paddingY="0.5rem"
			marginBottom="0.5rem"
			justifyContent="space-between"
			display="flex"
			alignItems="center"
			{...props}
		>
			<Box>
				<Box
					color={useColorModeValue('#444444', 'white')}
					fontFamily="body"
					fontSize="18px"
				>
					{props.header}
					{props.tooltip ? (
						<Tooltip
							label={props.tooltip}
							aria-label="A tooltip"
							cursor="pointer"
						>
							<InfoIcon boxSize="11px" marginLeft="4px" opacity="0.9" />
						</Tooltip>
					) : null}
				</Box>
				{props.children}
				<Box
					color={useColorModeValue('#888383', '#c3c3c3')}
					fontSize="16px"
				>
					{props.desc}
				</Box>
			</Box>
			{props.rightbutton ? props.rightbutton : null}
		</Box>
	);
};

export default HeaderWithDescription;
