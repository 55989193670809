import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import dotProp from 'dot-prop-immutable';

//Components
import { BrainItem } from './BrainItem';
import { HorizontalItemSkeleton } from '../Reusable/HorizontalItem';

//Redux
import { connect } from 'react-redux';

//Utils
import querySearch from '../../util/Search';
import { Box } from '@chakra-ui/react';

/**
 * BrainList lists all BrainItems in one list
 * @param {height} physical height of the list, to limit scrolling
 * @param {skillButton} whether the skillsButton is visible or not
 * @param {controlButton} whether the controlButton is visible or not
 * @param {openButton} whether the openButton is visible or not
 * @param {onClickFn} what function executes as (brainId) => onClickFn(brainId) when BrainItem is clicked
 */

const BrainList = ({
	brainIds,
	brains,
	query,
	isLoading,
	height,
	skillsButton,
	controlButton,
	openButton,
	onClickFn,
	selectedBrainId,
	wholeItemActive,
}) => {
	const loadingList = [1, 2, 3, 4];
	const [brainList, setBrainList] = React.useState(brains);
	//This is to pause start/stop on all workspaces while one of them is in progress
	const [hold, setHold] = React.useState(false);

	React.useEffect(() => {
		let filteredBrains = brains;
		if (query && query !== '') {
			filteredBrains = querySearch(brains, query, 'brains');
			for (var key in filteredBrains.byId) {
				filteredBrains = dotProp.set(
					filteredBrains,
					`byId.${key}.isOpen`,
					true
				);
			}
		}
		setBrainList(filteredBrains);
	}, [brains, query]);

	return (
		<Box
			flex="1"
			height={height}
			width="full"
			borderRadius="5px"
			overflow="auto"
		>
			{isLoading
				? loadingList.map((item) => <HorizontalItemSkeleton key={item} />)
				: brainList.allIds.map((brainId) => (
						<BrainItem
							key={brainId}
							brain={brainList.byId[brainId]}
							skillsButton={skillsButton}
							controlButton={controlButton}
							openButton={openButton}
							selected={brainId === selectedBrainId}
							onClick={(brainId) => onClickFn(brainId)}
							wholeItemActive={wholeItemActive}
							isItemExpanded={brainList.byId[brainId].isOpen || false}
							hold={hold}
							setHold={setHold}
						/>
				  ))}
		</Box>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	let { query } = state.navigation;
	const brainIds = brains.allIds;
	const { isLoading } = state.dashboard;
	return { brainIds, brains, isLoading, query };
};

/**
 * BrainList lists all BrainItems in one list
 * @param {height} physical height of the list, to limit scrolling
 * @param {skillButton} whether the skillsButton is visible or not
 * @param {controlButton} whether the controlButton is visible or not
 * @param {openButton} whether the openButton is visible or not
 * @param {onClickFn} what function executes as (brainId) => onClickFn(brainId) when BrainItem is clicked
 */
export default connect(mapStateToProps, null)(BrainList);
