// import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';

const performanceData = {};

performanceData.init = () => {
	//   Sentry.init({
	//     dsn:
	//       "https://3f20844fa6bc49efb73317d8d49dad86@o561441.ingest.sentry.io/5698521",
	//     integrations: [new Integrations.BrowserTracing()],
	//     release: "maya-desktop@" + process.env.npm_package_version,
	//     // Set tracesSampleRate to 1.0 to capture 100%
	//     // of transactions for performance monitoring.
	//     // We recommend adjusting this value in production
	//     tracesSampleRate: 1.0,
	//   });
};

export default performanceData;
