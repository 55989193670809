// Export the Login API.
export default {
	fusionAuth: {
		forgotPassword: {
			PATH_SEARCH: '/api/user/forgot-password',
			PATH_METHOD: 'post',
		},
	},
	apiService: {
		forgotPassword: {
			PATH_SEARCH: '/api/fusionAuth/forgot-password',
			PATH_METHOD: 'post',
		},
	},
};
