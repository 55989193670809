import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { mayaRedCustomEvents } from './config';

/**
 *	# Red Injector
 *
 * Utility Component for injecting `React Components` into the
 * `Maya Red (jquery) editor` at the element selected using CSS `selectors` (uses querySelector).
 *
 * @param {{
 *  selectors: string,
 *  children: React.ReactNode,
 * }} param0
 */
const RedInjector = ({ selectors, children }) => {
	const [container, setContainer] = useState(null);
	useEffect(() => {
		if (window.RED)
			document.addEventListener(mayaRedCustomEvents.redOnInit, (data) => {
				const element = document.querySelector(selectors);
				if (element) {
					element.textContent = '';
					setContainer(element);
				}
			});
		return () => {};
	}, [selectors]);
	return container ? ReactDOM.createPortal(children, container) : null;
};

export default RedInjector;
