import isElectron from 'is-electron';

import IPCFunction from '../../../util/IPCFetch/function';
import IPCRoutes from '../../../util/IPCRoutes';

const getStatus = async () => {
	console.log('getting status');
	if (isElectron()) {
		var getStatusIPCFn, getStatusIPCResponse;
		getStatusIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.autoStartStatus.EVENT,
		});
		getStatusIPCResponse = await getStatusIPCFn();
		return getStatusIPCResponse;
	}
};

export default getStatus;
