// Dependency imports

import isElectron from 'is-electron';
// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to get Devices
 */
const toggleSetupStatus = async (value) => {
	// Create an instance of your worker
	var setupCheckFn;
	var setupCheckResponse;
	if (isElectron()) {
		setupCheckFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.toggleSetupStatus.EVENT,
		});
		setupCheckResponse = await setupCheckFn(value);
	}

	return setupCheckResponse;
};

export default toggleSetupStatus;
