import React, { useEffect, useRef } from 'react';
import { List, ListItem } from '@chakra-ui/react';
import NodeItem from '../../Reusable/NodeItem';

/**
 * @typedef {{
 * 	id: string,
 * 	moduleId: string,
 * 	moduleIcon: string,
 * 	title: string,
 * 	description: string,
 * 	tags: string[],
 * }} SuggestionItem
 */

/**
 * Search Suggestions List Component
 * @param {{
 * 	items: SuggestionItem[],
 * 	selectedIdx: number,
 * 	onItemClick: (idx: number) => void,
 * 	listProps: import('@chakra-ui/layout').ListProps,
 * 	listItemProps: import('@chakra-ui/layout').ListItemProps
 * }} param0
 * @returns
 */
const SearchSuggestionsList = ({
	items,
	selectedIdx,
	onItemClick,
	listProps,
	listItemProps,
}) => {
	/**
	 * @type {React.MutableRefObject<HTMLUListElement>}
	 */
	const listRef = useRef(null);
	/**
	 * @type {React.MutableRefObject<HTMLLIElement>[]}
	 */
	const itemRefs = items.map((_item) => React.createRef());

	useEffect(() => {
		/** get highlighted item ref */
		const itmRef = itemRefs.length ? itemRefs[selectedIdx]?.current : null;
		if (itmRef) {
			/** scroll highlighted item into view */
			itemRefs[selectedIdx].current.scrollIntoView();
		}
	}, [itemRefs, selectedIdx]);

	return (
		<List
			ref={listRef}
			fontSize="small"
			flex="1"
			minHeight="0"
			spacing="0"
			w="full"
			overflow="auto"
			maxHeight="96"
			{...listProps}
		>
			{items.map((item, idx) => (
				<ListItem
					tabIndex={0}
					display="flex"
					alignItems="center"
					py="1.5"
					px="2"
					userSelect="none"
					cursor="pointer"
					w="full"
					_hover={{
						bg: '#fafafa0d',
					}}
					_focus={{
						bg: '#fafafa0d',
					}}
					_active={{
						bg: '#fafafa1f',
					}}
					{...listItemProps}
					key={item.id}
					ref={itemRefs[idx]}
					bg={selectedIdx === idx ? '#fafafa0d' : undefined}
					onClick={() => onItemClick(idx)}
				>
					<NodeItem
						title={item.title}
						description={item.description}
						isHighlighted={selectedIdx === idx}
						tags={item.tags}
						icon={item.moduleIcon}
						boxProps={{
							marginBottom: undefined,
							w: 'full',
						}}
					/>
				</ListItem>
			))}
		</List>
	);
};

export default SearchSuggestionsList;
