import React from 'react';

import {
	Box,
	Button,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	useColorModeValue,
	Image,
	Progress,
	LightMode,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
//Redux
import { setDashboardInfo, setCurrentDevice } from '../../redux/actions';

//Components
import GeneralLoader from '../Reusable/GeneralLoader';

//functions
import startInterruptedBrains from '../../functions/brain/startInterruptedBrains';
import checkStartupStatus from '../../functions/brain/checkStartupStatus';
import GetProfileSlug from '../../util/ProfileSlug';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import checkDevice from '../../functions/devices/checkDevice';
import getMachineDetails from '../../functions/devices/getMachineDetails';
import { getTimeToExpiry } from '../../util/Tier/getTimeRemaining';

const StartupLoader = ({ setCurrentDevice }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [skipButton, setSkipButton] = React.useState(false);
	const { slug } = GetProfileSlug();

	React.useEffect(() => {
		checkStartupStatus(slug).then(async (res) => {
			try {
				const timeToExpiry = await getTimeToExpiry()
				if (timeToExpiry <= 0) {
					return
				}
			} catch (e) {
				console.log('StartupLoader: Unable to get time to tier expiry:', e)
				return
			}

			console.log('CHECK STATUS:', res);
			if (res) {
				onOpen();
				let runtimes = startInterruptedBrains(slug).then(async (result) => {
					function sleep(ms) {
						return new Promise((resolve) => setTimeout(resolve, ms));
					}
					await sleep(5000);
					onClose();
					return result;
				});
				setTimeout(() => {
					setSkipButton(true);
					if (runtimes) {
						onClose();
					}
				}, 20000);
			}
		});
	}, []);
	React.useEffect(() => {
		console.log('setting current device');
		getMachineDetails().then((machineDetailsResponse) => {
			if (machineDetailsResponse) {
				let machineId = machineDetailsResponse.machineID;
				checkDevice({
					machineID: machineId,
				}).then((deviceResponse) => {
					setCurrentDevice(deviceResponse.results.device);
				});
			}
		});
	}, []);
	return (
		<>
			<Box></Box>
			<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					maxWidth="100vw"
					height="99vh"
				>
					<ModalBody
						pb={5}
						justifyContent="center"
						display="flex"
						flexDirection="column"
						alignItems="center"
						paddingTop="1rem"
					>
						<GeneralLoader text="Starting workspaces..." />
						<LightMode>
							{skipButton ? (
								<Button
									colorScheme="purple"
									size="sm"
									borderRadius="5px"
									variant={'solid'}
									paddingX="0.9rem"
									onClick={() => {
										onClose();
									}}
									mt="5"
									width="7rem"
								>
									Skip
								</Button>
							) : (
								<Box height="2rem" mt="5" />
							)}
						</LightMode>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

/**
 *
 * @param {*} state
 */

const mapStateToProps = (state) => {
	let { brains } = state;
	const brainIds = brains.allIds;
	const { isLoading } = state.dashboard;
	return { brainIds, brains, isLoading };
};

export default connect(mapStateToProps, { setDashboardInfo, setCurrentDevice })(
	StartupLoader
);
