/* eslint-disable default-case */
import { SiDuckduckgo, SiBing } from 'react-icons/si';
import { FiCalendar } from 'react-icons/fi';
import { VscArrowSmallRight, VscFileCode } from 'react-icons/vsc';
import {
	FaSpotify,
	FaItunesNote,
	FaDrawPolygon,
	FaMapMarkerAlt,
	FaAppStore,
	FaWikipediaW,
} from 'react-icons/fa';
import {
	AiOutlineFileExcel,
	AiOutlineFileImage,
	AiOutlineFilePdf,
	AiOutlineFilePpt,
	AiOutlineFileWord,
	AiOutlineFileZip,
	AiOutlineFolder,
	AiOutlineFile,
	AiOutlineForm,
	AiOutlineYoutube,
	AiOutlineChrome,
	AiOutlineGoogle,
	AiOutlineTwitter,
	AiOutlineWhatsApp,
	AiOutlineAmazon,
	AiOutlineVideoCamera,
	AiOutlineCalculator,
	AiOutlineReddit,
} from 'react-icons/ai';

function camelize(kebab) {
	let arr = kebab.split('-');
	let capital = arr.map(
		(item) => item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
	);
	return capital.join('');
}

function chooseIcon(icon) {
	switch (icon) {
		case 'audio':
			return FaItunesNote;
		case 'document':
			return AiOutlineFileWord;
		case 'drawing':
			return FaDrawPolygon;
		case 'file':
		case 'shortcut':
			return AiOutlineFile;
		case 'text/x-csrc':
		case 'code':
			return VscFileCode;
		case 'folder':
			return AiOutlineFolder;
		case 'form':
			return AiOutlineForm;
		case 'map':
			return FaMapMarkerAlt;
		case 'photo':
		case 'picture':
		case 'image':
		case 'image/jpeg':
			return AiOutlineFileImage;
		case 'presentation':
			return AiOutlineFilePpt;
		case 'spreadsheet':
			return AiOutlineFileExcel;
		case 'video':
		case 'zoom':
		case 'video/quicktime':
			return AiOutlineVideoCamera;
		case 'pdf':
		case 'application/pdf':
			return AiOutlineFilePdf;
		case 'zip':
		case 'archive':
		case 'tar':
		case 'application/epub+zip':
		case 'application/zip':
			return AiOutlineFileZip;
		case 'app-store':
			return FaAppStore;
		case 'calculator':
			return AiOutlineCalculator;
		case 'spotify':
			return FaSpotify;
		case 'youtube':
			return AiOutlineYoutube;
		case 'calendar':
			return FiCalendar;
		case 'chrome':
		case 'website':
		case 'site':
		case 'url':
			return AiOutlineChrome;
		case 'duckduckgo':
			return SiDuckduckgo;
		case 'reddit':
			return AiOutlineReddit;
		case 'bing':
			return SiBing;
		case 'google':
			return AiOutlineGoogle;
		case 'twitter':
			return AiOutlineTwitter;
		case 'whatsapp':
			return AiOutlineWhatsApp;
		case 'amazon':
			return AiOutlineAmazon;
		case 'wikipedia':
			return FaWikipediaW;
		default:
			return VscArrowSmallRight;
	}
}

export default chooseIcon;
