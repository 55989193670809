const baseURL = 'http://localhost:1880';

export const insideJson = {
	type: 'Box',
	props: {
		paddingY: '0.5rem',
		paddingX: '0.5rem',
	},
	children: [
		{
			type: 'Flex',
			props: {},
			children: [
				{
					type: 'Box',
					props: {
						overflow: 'scroll',
					},
					children: [
						{
							type: 'Box',
							color: { light: 'gray.500', dark: 'white' },
							props: {
								fontSize: 'md',
								marginBottom: '0.2rem',
								ml: '4',
								fontFamily: 'body',
								fontWeight: '600',
								children: "It's great to have you onboard!",
							},
							children: [
								{
									type: 'Div',
									props: {
										value: "It's great to have you onboard!",
									},
									children: [],
								},
							],
						},
						{
							type: 'Box',
							color: { light: 'gray.500', dark: 'white' },
							props: {
								mt: '0.2rem',
								fontSize: '0.9rem',
								ml: '4',
								fontFamily: 'body',
								fontWeight: '500',
							},
							children: [
								{
									type: 'Div',
									props: {
										value: 'mayahq.com',
									},
									children: [],
								},
							],
						},
						{
							type: 'Box',
							color: { light: 'gray.500', dark: 'white' },
							bgColor: { light: 'white', dark: 'maya_dark.100' },
							props: {
								mt: '0.7rem',
								padding: '1rem',
								fontFamily: 'body',
								fontWeight: '500',
								fontSize: 'md',
								border: 'none',
								outline: 'none',
								rounded: '1rem',
								overflow: 'auto',
								contentEditable: true,
								suppressContentEditableWarning: 'true',
							},
							children: [
								{
									type: 'Div',
									props: {
										value: "With Maya, our mission is to build a general-purpose assistant that not only gets things done faster, but also gets better with time. Just hit Alt/Option (⌥) + Space, search and select the command your want, and long-press Enter. You can make new Maya automations in minutes to automate your browser, your web apps & your desktop and then share them with your team and the world. We're excited to answer the question : what happens when every human in the world gets a personal robot to multiple their efforts? Or, how many Tony Starks do we get if each one of us had a Jarvis? Thanks for joining us early in figuring this out.",
									},
									children: [],
								},
							],
						},
					],
				},
			],
		},
	],
};

export const initialState_old = {
	mode: 'flow',
	commandBarFeedback: {
		visible: false,
		type: 'success', // error | loading
		message: 'Success!',
	},
	intents: {
		byId: {
			66477288823102: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'demo-tab',
				},
				intent: [
					{
						type: 'text',
						value: 'search amazon tabs for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			89129100203102: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'amazon-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search amazon for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			319199298389123: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'hotstar-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search hotstar for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'video',
					},
				],
			},

			4321992234323: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'google-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search on google',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			546523234233243: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'browser-open',
				},
				intent: [
					{
						type: 'text',
						value: 'open browser',
					},
				],
			},
			932343234233243: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'browser-close',
				},
				intent: [
					{
						type: 'text',
						value: 'close browser',
					},
				],
			},
			4321243255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'youtube-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search youtube for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			311299243255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'reddit-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search reddit for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			773843255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'spotify-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search spotify for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			34232332223331: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'twitter-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search twitter for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			4322334255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'whereby',
				},
				intent: [
					{
						type: 'text',
						value: 'Search whereby for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'email',
						key: 'mail',
					},
					{
						type: 'text',
						value: 'at',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'time',
						key: 'time',
					},
					{
						type: 'text',
						value: 'between',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'timerange',
						key: 'range',
					},
				],
			},
			99388839255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'google-copied',
				},
				intent: [
					{
						type: 'text',
						value: 'search google for copied text',
					},
				],
			},
			823233449255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'youtube-playfirst',
				},
				intent: [
					{
						type: 'text',
						value: 'search jabberwocky for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'custom',
						key: 'query',
					},
					{
						type: 'text',
						value: '& play first result',
					},
				],
			},
		},
		allIds: [
			'546523234233243',
			'4321992234323',
			'89129100203102',
			'319199298389123',
			'932343234233243',
			'66477288823102',
			'4321243255433',
			'773843255433',
			'4322334255433',
			'311299243255433',
			'34232332223331',
			'99388839255433',
			'823233449255433',
		],
	},
	centerSearch: {
		mode: 'search',
		display: 'collapsed',
		moduleWidth: 35,
		top: {
			icon: 'search',
			selectedItem: [],
			submitProgress: 0,
			intentObjectArray: { byId: {}, allIds: [] },
			loadingStatus: null, // "submitting", null
		},
		middle: {
			mode: 'intents', // | "placeholder"
			type: 'commands', // | for placeholder - text, string, contact
			placeholderText: '',
			currentPlaceholderId: '',
			placeholderSearchList: {
				selectedIndex: 0,
				searchItems: {
					byId: {},
					allIds: [],
				},
			},
			length: 0,
			selectedIndex: 0,
			searchItems: {
				byId: {},
				allIds: [],
			},
		},
		bottom: {},
	},
	cards: {
		settings: {
			enabled: true,
			visibility: true,
			locateY: 360,
			separation: 35,
			opacity: 1,
			selectedIndex: 0,
			interactionEnabled: true,
		},
		byId: {
			'Card-765432': {
				mode: 'content',
				display: 'collapsed',
				maximized: false,
				moduleWidth: 15,
				top: {
					icon: 'chevron-right',
					text: 'Introducing Maya.',
				},
				middle: {
					jsonObject: insideJson,
				},
				bottom: {
					visible: false,
					actionItems: ['Add to flow', 'Save to Clipboard'],
				},
			},
			'Card-767893': {
				mode: 'web',
				display: 'collapsed',
				moduleWidth: 40,
				maximized: true,
				top: {
					icon: 'chevron-right',
					text: 'Your Wikipedia search : Artificial General Intelligence',
				},
				middle: {
					url: 'https://en.wikipedia.org/wiki/Artificial_general_intelligence',
				},
				bottom: {
					visible: false,
					actionItems: ['Add to flow', 'Save to Clipboard'],
				},
			},
			'Card-3345483': {
				mode: 'web',
				display: 'collapsed',
				moduleWidth: 40,
				maximized: false,
				top: {
					icon: 'chevron-right',
					text: 'Google search : Hello',
				},
				middle: {
					url: 'https://www.google.com/search?q=Hello',
				},
				bottom: {
					visible: false,
					actionItems: ['Open in Browser'],
				},
			},
		},
		allIds: ['Card-765432', 'Card-767893', 'Card-3345483'],
	},
};

export const initialState = {
	mode: 'flow',
	commandBarStatus: {
		visible: false,
		status: 'error', // error | loading
		message: 'Permission required!',
	},
	intents: {
		byId: {
			66477288823102: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'demo-tab',
				},
				intent: [
					{
						type: 'text',
						value: 'search amazon tabs for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			89129100203102: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'amazon-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search amazon for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			319199298389123: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'hotstar-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search hotstar for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'video',
					},
				],
			},

			4321992234323: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'google-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search on google',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			546523234233243: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'browser-open',
				},
				intent: [
					{
						type: 'text',
						value: 'open browser',
					},
				],
			},
			932343234233243: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'browser-close',
				},
				intent: [
					{
						type: 'text',
						value: 'close browser',
					},
				],
			},
			4321243255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'youtube-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search youtube for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			311299243255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'reddit-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search reddit for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			773843255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'spotify-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search spotify for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			34232332223331: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'twitter-search',
				},
				intent: [
					{
						type: 'text',
						value: 'search twitter for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'string',
						key: 'query',
					},
				],
			},
			4322334255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'whereby',
				},
				intent: [
					{
						type: 'text',
						value: 'Search whereby for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'email',
						key: 'mail',
					},
					{
						type: 'text',
						value: 'at',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'time',
						key: 'time',
					},
					{
						type: 'text',
						value: 'between',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'timerange',
						key: 'range',
					},
				],
			},
			99388839255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'google-copied',
				},
				intent: [
					{
						type: 'text',
						value: 'search google for copied text',
					},
				],
			},
			823233449255433: {
				submitDetails: {
					type: 'http',
					url: baseURL,
					trigger: 'youtube-playfirst',
				},
				intent: [
					{
						type: 'text',
						value: 'search jabberwocky for',
					},
					{
						type: 'placeholder',
						value: '',
						keyType: 'custom',
						key: 'query',
					},
					{
						type: 'text',
						value: '& play first result',
					},
				],
			},
		},
		allIds: [
			'546523234233243',
			'4321992234323',
			'89129100203102',
			'319199298389123',
			'932343234233243',
			'66477288823102',
			'4321243255433',
			'773843255433',
			'4322334255433',
			'311299243255433',
			'34232332223331',
			'99388839255433',
			'823233449255433',
		],
	},
	centerSearch: {
		mode: 'search',
		display: 'collapsed',
		moduleWidth: 35,
		top: {
			icon: 'search',
			selectedItem: [],
			submitProgress: 0,
			intentObjectArray: { byId: {}, allIds: [] },
			loadingStatus: null, // "submitting", null
		},
		middle: {
			mode: 'intents', // | "placeholder"
			type: 'commands', // | for placeholder - text, string, contact
			placeholderText: '',
			currentPlaceholderId: '',
			placeholderSearchList: {
				selectedIndex: 0,
				searchItems: {
					byId: {},
					allIds: [],
				},
			},
			length: 0,
			selectedIndex: 0,
			searchItems: {
				byId: {},
				allIds: [],
			},
			vibrateInstructions: false,
		},
		bottom: {},
	},
	cards: {
		settings: {
			enabled: true,
			visibility: true,
			locateY: 360,
			separation: 35,
			opacity: 1,
			selectedIndex: 0,
			interactionEnabled: true,
		},
		byId: {},
		allIds: [],
	},
};
