// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax

import APIFunction from '../../util/APIFetch/function';

import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
/**
 * Function to get all profiles for a user
 */
const getUserProfiles = async () => {
	// Create an instance of your worker
	var getUserProfilesFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getUserProfiles.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getUserProfiles.PATH_METHOD,
	});

	var getUserProfilesResponse = await getUserProfilesFn();
	return getUserProfilesResponse['data'];
};

export default getUserProfiles;
