/**
 * User Reducer
 *
 * Creates a Redux reducer for handling user actions.
 */

// Action Types
import actionTypes from '../actionTypes';

// Setup initial state with an empty user info object.
const initialState = {
	status: 'offline',
	info: null,
	id: '',
};

// Export the User Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_USER: {
			const { info } = action.payload;
			if (info) {
				return {
					status: 'online',
					info,
				};
			} else {
				return state;
			}
		}
		case actionTypes.LOGOUT_USER: {
			return {
				status: 'offline',
				info: null,
			};
		}
		case actionTypes.SET_STATUS: {
			return {
				...state,
				status: action.payload,
			};
		}
		default:
			return state;
	}
};
