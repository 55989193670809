import isElectron from 'is-electron';

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const authenticateConfiguration = async ({ configurationType, formData }) => {
	var authenticateConfigurationResponse, authenticateConfigurationIPCFn;
	let data = {
		config: {
			resource: configurationType.resource,
			type: configurationType.type,
			meta: configurationType.config.variables[0],
			redirectUrl: configurationType.redirectUrl,
			clientId: configurationType.clientId,
			authUrl: configurationType.authUrl,
			responseType: configurationType.responseType,
			extraAuthParams: configurationType.extraAuthParams,
			values: {},
		},
	};

	for (let key in formData) {
		if (key !== 'name') {
			data['config']['values'][key] = formData[key];
		}
	}
	if (isElectron()) {
		if (window.navigator.onLine) {
			authenticateConfigurationIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.authenticateConfiguration.EVENT,
			});
			try {
				authenticateConfigurationResponse =
					await authenticateConfigurationIPCFn(data);
				return authenticateConfigurationResponse['data'];
			} catch (err) {
				console.log('Error authenticating configuration');
				return {
					error: true,
					results: null,
				};
			}
		}
	} else {
		return {
			error: true,
			message: 'This service is available only for Maya Desktop Application',
		};
	}
};

export default authenticateConfiguration;
