import React, { useContext, useEffect, useState } from 'react';
import { Box, Heading, Input, useToast } from '@chakra-ui/react';
import IPCFetch from '../../../util/IPCFetch';
import SetupWizardContext from '../SetupWizardContext';
import ActionFooter from '../ActionFooter';
import registerDevice from '../../../functions/devices/registerDevice';
import isElectron from 'is-electron';
import { setupWizardStages } from '../Config';
import StorePackListSW from './StorePackListSW';
import analytics from '../../../util/Analytics';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';

/**
 * Skill Installation
 */
const SkillInstallation = ({ onFinish }) => {
	const toast = useToast();
	const {
		currentStage,
		continueBtnRef,
		setStage,
		selectedBrain,
		selectedPublishedSkillPacks,
	} = useContext(SetupWizardContext);
	const { feature } = useContext(AnalyticsContext);

	const handleContinue = () => {
		analytics.track(
			`[${feature}] SkillInstallation: Log Final Selected Published Collections`,
			{
				feature,
				selectedPublishedSkillPackIds: selectedPublishedSkillPacks.map(
					(psp) => psp._id
				),
				selectedPublishedSkillPackNames: selectedPublishedSkillPacks.map(
					(psp) => psp.name
				),
				selectedPublishedSkillPackVersions: selectedPublishedSkillPacks.map(
					(psp) => psp.currentVersion
				),
			}
		);
		setStage(
			setupWizardStages.list[
				setupWizardStages.list.indexOf(currentStage) + 1
			]
		);
	};

	useEffect(() => {
		if (continueBtnRef.current) {
			if (selectedPublishedSkillPacks.length > 0)
				continueBtnRef.current.disabled = false;
			else continueBtnRef.current.disabled = true;
		}
	}, [selectedPublishedSkillPacks]);

	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box flex="1" py="3" display="flex" flexDir="column">
				<Box as="h1" fontWeight="medium" fontSize="3xl">
					{setupWizardStages.byId[currentStage].label}
				</Box>
				<Box fontWeight="medium" fontSize="1rem" mb="2">
					Maya workflows are automations made by assembling different
					modules together to perform any task. Here are some pre-built
					skill collections you can choose to install:
				</Box>
				<Box flex="1">
					<StorePackListSW currentBrainId={selectedBrain} />
				</Box>
				<Box mt="2" fontSize="1rem" opacity="0.9">
					{selectedPublishedSkillPacks.length} collection
					{selectedPublishedSkillPacks.length === 1 ? '' : 's'} selected
					for install.
					{selectedPublishedSkillPacks.length === 0 &&
						' Please select at least one collection to continue.'}
				</Box>
			</Box>
			<ActionFooter
				continueBtnRef={continueBtnRef}
				currentSetupWizardStage={currentStage}
				onContinue={handleContinue}
				showSkip={true}
				onFinish={onFinish}
				showBackButton
				onBack={() =>
					setStage(
						setupWizardStages.list[
							setupWizardStages.list.indexOf(currentStage) - 1
						]
					)
				}
			/>
		</Box>
	);
};

export default SkillInstallation;
