import React from 'react';
import StorePackList from './StorePackList';
import { Box, useColorModeValue } from '@chakra-ui/react';

/**
 * SkillPackSection Component
 *
 * @param {{
 * 	currentBrainId: string,
 * 	categories: (import('../../../functions/store/types/Categories').StoreCategory & {
 *		 	_checked: boolean;
 *		 	kind: 'category';
 *		})[],
 * 	platforms: any,
 * 	apps: (import('../../../functions/store/types/Apps').StoreApp & {
 *		 	_checked: boolean;
 *		 	kind: 'app';
 *		})[]
 * }} param0
 * @returns
 */
const SkillPackSection = ({ currentBrainId, categories, platforms, apps }) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			paddingY="12px"
			marginLeft="20px"
			flex="1 1 auto"
		>
			<StorePackList
				currentBrainId={currentBrainId}
				categories={categories}
				platforms={platforms}
				apps={apps}
			/>
		</Box>
	);
};

export default SkillPackSection;
