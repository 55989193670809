import React from 'react';
import { Input, useColorMode, Box } from '@chakra-ui/react';
import KeyEnum from '../../../util/KeyEnum';

//Redux
import {
	setMiddleSearchModeAndType,
	toggleSearchDisplayMode,
	setMiddlePlaceholderText,
	toggleCardsVisibility,
	highlightPlaceholderSearchItem,
	setPlaceholderValueById,
} from '../../../redux/actions';
import { connect } from 'react-redux';

//Functions
import getCurrentPlaceholdersDetails from '../../../functions/commandBar/getCurrentPlaceholdersDetails';

const IntentPlaceholderInput = ({
	item,
	onSelect,
	onSubmit,
	StopSubmit,
	onNext,
	onCancel,
	StartSubmit,
	onInputChange,
	setMiddleSearchModeAndType,
	toggleSearchDisplayMode,
	highlightPlaceholderSearchItem,
	setMiddlePlaceholderText,
	toggleCardsVisibility,
	currentPlaceholderId,
	placeholderId,
	setPlaceholderValueById,
	myForwardRef,
	searchItems,
	selectedIndex,
}) => {
	const bgColor = {
		light: 'card_ui.placeholder_light',
		dark: 'card_ui.placeholder_dark',
	};
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};
	const deFocus = () => {
		myForwardRef.current.blur();
		setFocus(false);
		setValue(`${item.key}`);
	};

	const focusInput = () => {
		myForwardRef.current.select();
		onSelect();
		setFocus(true);
	};

	React.useEffect(() => {
		if (selectedIndex !== 0 && placeholderId === currentPlaceholderId) {
			setValue(searchItems[selectedIndex - 1].show);
			setMiddlePlaceholderText(searchItems[selectedIndex - 1].show);
			setPlaceholderValueById(
				placeholderId,
				searchItems[selectedIndex - 1].show,
				searchItems[selectedIndex - 1].submit
			);
		} else if (
			selectedIndex === 0 &&
			placeholderId === currentPlaceholderId
		) {
			focusInput();
		}

		// setMiddlePlaceholderText("testing" + selectedIndex);
	}, [selectedIndex, setMiddlePlaceholderText]);

	const handleChange = (e) => {
		onInputChange(e.target.value);
		setValue(e.target.value);
		setMiddlePlaceholderText(e.target.value);
		toggleCardsVisibility(false);
	};

	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.ENTER || keyCode === KeyEnum.TAB) {
			console.log('LOLOLOL', item);
			e.preventDefault();
			onNext();
			var ifMultiplePlaceholders = getCurrentPlaceholdersDetails().multiple;

			if (ifMultiplePlaceholders) {
				if (e.shiftKey) {
					console.log('SHIFT');
					onSubmit();
				}
			} else {
				console.log('SHIFT');
				onSubmit();
			}
			// StartSubmit();
		} else if (keyCode === KeyEnum.ESC) {
			e.preventDefault();
			onCancel();
		} else if (keyCode === KeyEnum.UP_ARROW) {
			e.preventDefault();
			highlightPlaceholderSearchItem('UP');
		} else if (keyCode === KeyEnum.DOWN_ARROW) {
			e.preventDefault();
			highlightPlaceholderSearchItem('DOWN');
		}
	};

	const [focused, setFocus] = React.useState(false);
	const [value, setValue] = React.useState(`${item.key}*`);
	const { colorMode } = useColorMode();
	return (
		<Box
			height="1.3rem"
			bg={bgColor[colorMode]}
			paddingX="0.7rem"
			paddingY="0.1rem"
			borderRadius="0.2rem"
			display="flex"
			justifyContent="center"
			alignItems="center"
			mr={2}
			onClick={focusInput}
			// onMouseLeave={deFocus}
			cursor="pointer"
			color={color[colorMode]}
			_focus={{ outline: 'none' }}
			whiteSpace="nowrap"
			mb={1}
			mt={1}
		>
			<Input
				color={color[colorMode]}
				whiteSpace="nowrap"
				bg={bgColor[colorMode]}
				ref={myForwardRef}
				fontWeight="600"
				cursor="pointer"
				height="80%"
				padding="0"
				border="none"
				borderRadius="none"
				contentEditable
				onChange={handleChange}
				textAlign={focused ? 'left' : 'center'}
				fontSize="0.9rem"
				value={value}
				width={`${(item.key.length + 1) * 0.55}rem`}
				_placeholder={{ color: '#777777' }}
				placeholder={focused ? '' : value}
				_focus={{ outline: 'none' }}
				onKeyDown={handleOnKeyDown}
				fontFamily="body"
			/>
		</Box>
	);
};

const mapStateToProps = (state) => {
	let { searchItems, selectedIndex } =
		state.cardUI.centerSearch.middle.placeholderSearchList;
	let { currentPlaceholderId } = state.cardUI.centerSearch.middle;
	return { searchItems, selectedIndex, currentPlaceholderId };
};

const ConnectedIntentPlaceholderInput = connect(mapStateToProps, {
	setMiddleSearchModeAndType,
	toggleSearchDisplayMode,
	setMiddlePlaceholderText,
	toggleCardsVisibility,
	highlightPlaceholderSearchItem,
	setPlaceholderValueById,
})(IntentPlaceholderInput);

export default React.forwardRef((props, ref) => (
	<ConnectedIntentPlaceholderInput {...props} myForwardRef={ref} />
));
