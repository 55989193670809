import * as React from 'react';

import {
	useClipboard,
	Flex,
	Input,
	LightMode,
	IconButton,
	DarkMode,
	Modal,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useColorModeValue,
	useDisclosure,
	Button,
	ModalHeader,
} from '@chakra-ui/react';
import { CopyIcon, CheckIcon } from '@chakra-ui/icons';
import isElectron from 'is-electron';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import config from '../../config/config';

export const ShareLinkModal = ({ isOpen, onClose, _id }) => {
	console.log('ShareLInkModal', _id);
	const [value, setValue] = React.useState(
		`${config.app.BASEURL}/store/skills?id=${_id}`
	);
	const { hasCopied, onCopy } = useClipboard(value);
	return (
		<DarkMode>
			<CustomModal
				onClose={onClose}
				isOpen={isOpen}
				maxWidth="50rem"
				isCentered={isElectron() ? true : false}
			>
				<ModalContent
					zIndex="2000"
					maxWidth="30rem"
					height={'10rem'}
					borderRadius="0.5rem"
					bg={useColorModeValue('white', 'maya_dark.300')}
				>
					<ModalHeader>Copy Link</ModalHeader>
					<ModalCloseButton />
					<ModalBody height="90%" paddingY="none">
						<Flex mb={2} mt={2}>
							<Input value={value} isReadOnly mr={2} />

							<DarkMode>
								<IconButton
									icon={hasCopied ? <CheckIcon /> : <CopyIcon />}
									onClick={onCopy}
									size="md"
								/>
							</DarkMode>
						</Flex>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</DarkMode>
	);
};

// TODO: pass _id as props here
const CopyLinkToClipBoard = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<>
			<DarkMode>
				<Button
					colorScheme="gray"
					size="sm"
					onClick={onOpen}
					mt={2}
					borderRadius="20px"
				>
					Share
				</Button>
			</DarkMode>
			<ShareLinkModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				_id={null}
			/>
		</>
	);
};

export default CopyLinkToClipBoard;
