import React from 'react';
import { Box, Text, useColorMode } from '@chakra-ui/react';
const placeholderBgColor = {
	light: 'card_ui.placeholder_light',
	dark: 'card_ui.placeholder_dark',
};

const color = {
	light: 'card_ui.font_light.200',
	dark: 'card_ui.font_dark.200',
};

const lighterColor = {
	light: 'card_ui.font_light.100',
	dark: 'card_ui.font_dark.100',
};

/**
 *
 * @param {{
 * 	cardObject: import('../../../../../redux/reducers/cardUIV2').CuiCard
 * }} param0
 * @returns
 */
export const CardItemDisplay = ({ cardObject }) => {
	const { colorMode } = useColorMode();

	return (
		<Box display="flex" alignItems="center" flex="1" minWidth="0">
			{cardObject.mode === 'web' ? (
				<Box
					height={`${1.4}rem`}
					bg={placeholderBgColor[colorMode]}
					borderRadius="0.2rem"
					display="flex"
					justifyContent="center"
					alignItems="center"
					paddingX="0.4rem"
					marginTop="0.1rem"
					mr={2}
					color={color[colorMode]}
					_focus={{ outline: 'none' }}
					whiteSpace="nowrap"
				>
					<Box
						color={color[colorMode]}
						whiteSpace="nowrap"
						bg={'transparent'}
						fontWeight="600"
						isdisabled={'true'}
						fontFamily="body"
						padding="0"
						border="none"
						borderRadius="none"
						textAlign={'center'}
						fontSize={`${0.8}rem`}
						width={`${5 * 0.55}rem`}
					>
						web
					</Box>
				</Box>
			) : null}
			{cardObject.mode === 'tab' ? (
				<Box
					height={`${1.4}rem`}
					bg={placeholderBgColor[colorMode]}
					borderRadius="0.2rem"
					display="flex"
					justifyContent="center"
					alignItems="center"
					paddingX="0.4rem"
					marginTop="0.1rem"
					mr={2}
					color={color[colorMode]}
					_focus={{ outline: 'none' }}
					whiteSpace="nowrap"
				>
					<Box
						color={color[colorMode]}
						whiteSpace="nowrap"
						bg={'transparent'}
						fontWeight="600"
						isdisabled={'true'}
						fontFamily="body"
						padding="0"
						border="none"
						borderRadius="none"
						textAlign={'center'}
						fontSize={`${0.8}rem`}
						width={`${5 * 0.55}rem`}
					>
						dash
					</Box>
				</Box>
			) : null}
			<Box flex="1" minWidth="0">
				<Text
					isTruncated
					display="flex"
					ml={3}
					fontSize="0.9rem"
					fontFamily="body"
					fontWeight="500"
					color={color[colorMode]}
				>
					{cardObject.topText}
				</Text>
				<Text
					isTruncated
					ml={3}
					fontSize="0.65rem"
					fontFamily="body"
					fontWeight="500"
					opacity="0.9"
					color={lighterColor[colorMode]}
				>
					{cardObject.mode === 'web'
						? cardObject.url.replace('https://www.', '')
						: 'Interactive dashboard'}
				</Text>
			</Box>
		</Box>
	);
};

export default CardItemDisplay;
