import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

const deleteDeveloperRequest = async (formData) => {
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;

	const deleteDeveloperRequestAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.deleteDeveloperRequest.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.deleteDeveloperRequest.PATH_METHOD,
	});

	const deleteDeveloperRequestAPIResponse = await deleteDeveloperRequestAPIFn({
		...formData,
		profileSlug,
	});
	return deleteDeveloperRequestAPIResponse;
};

export default deleteDeveloperRequest;
