import React from 'react';
import { Box } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import {
	EmailTypeSearch,
	StringTypeSearch,
	TimeTypeSearch,
	PhoneTypeSearch,
	TimeRangeTypeSearch,
	CustomTypeSearch,
} from './PlaceholderTypes';
import { placeholderSuggestionsChange } from '../../../../../../redux/actions/cardUIV2';

const TypeSelectArray = {
	string: StringTypeSearch,
	time: TimeTypeSearch,
	email: EmailTypeSearch,
	phone: PhoneTypeSearch,
	timerange: TimeRangeTypeSearch,
	custom: CustomTypeSearch,
};

const TypeSelectorSearch = ({
	placeholderText,
	placeholderType,
	selectedIndex,
	placeholderDetails,
	currentIntentId,
}) => {
	const dispatch = useDispatch();
	const searchItems = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.commandBar.suggestionsArea.placeholderSearch.searchItems
	);
	const Select = () => {
		return TypeSelectArray[placeholderType]
			? TypeSelectArray[placeholderType]
			: Box;
	};

	const TypeSearchSection = Select();

	return (
		<TypeSearchSection
			placeholderText={placeholderText}
			selectedIndex={selectedIndex}
			searchItems={searchItems}
			setPlaceholderSearchItems={(newItems) =>
				dispatch(placeholderSuggestionsChange({ searchItems: newItems }))
			}
			placeholderDetails={placeholderDetails}
			currentIntentId={currentIntentId}
		/>
	);
};

export default TypeSelectorSearch;
