import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import axios from 'axios'
// import config from '../../config/config'


// config
import { config } from '../../config';

const CMS_API_ROOT = config.app.CMS_BACKEND
/**
 *
 * @param {Array of skillpack ids} formData
 * @returns
 */
const getSkillPackList = async (formData) => {
	console.log('ST FORMDATA', formData);
	var getSkillPackListAPIFn, getSkillPackListResponse;

	getSkillPackListAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getSkillPackList.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getSkillPackList.PATH_METHOD,
	});
	getSkillPackListResponse = await getSkillPackListAPIFn(formData);
	console.log('getSkillPackListResponse', getSkillPackListResponse);
	if (getSkillPackListResponse['data']['error']) {
		return getSkillPackListResponse['data'];
	} else {
		return getSkillPackListResponse['data'];
	}
};

// async function getSkillPackList()

export default getSkillPackList;
