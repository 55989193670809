import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';
import _ from 'lodash';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

const installModuleToBrain = async (formData, moduleToInstall) => {
	const { user } = store.getState();
	let profileSlug = formData.profileSlug ? formData.profileSlug : user.info.id;
	formData.profileSlug = profileSlug;
	var installModuleToBrainAPIFn,
		installModuleToBrainIPCFn,
		installModuleToBrainResponse,
		getModuleByIdFn,
		getModuleByIdResponse;
	if (!isElectron()) {
		installModuleToBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.installModuleToBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.installModuleToBrain.PATH_METHOD,
		});
		installModuleToBrainResponse = await installModuleToBrainAPIFn();
		if (installModuleToBrainResponse['data']['error']) {
			return installModuleToBrainResponse['data'];
		} else {
			return installModuleToBrainResponse['data']['results'];
		}
	} else {
		// getModuleByIdFn = APIFunction({
		// 	BASEURL: config.apiServer.BASEURL,
		// 	PATH_SEARCH: APIRoutes.apiService.getModuleById.PATH_SEARCH,
		// 	PATH_METHOD: APIRoutes.apiService.getModuleById.PATH_METHOD,
		// 	PATH_QUERY: formData.moduleId,
		// });
		// getModuleByIdResponse = await getModuleByIdFn();
		installModuleToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.installModuleToBrain.EVENT,
		});
		installModuleToBrainResponse = await installModuleToBrainIPCFn({
			...formData,
			moduleToInstall: moduleToInstall,
		});
		return installModuleToBrainResponse['data'];
	}
};

export default installModuleToBrain;
