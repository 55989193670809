import * as React from 'react';

import { Box, useColorModeValue } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const StageCard = ({ number, text, selected, done }) => {
	return (
		<Box width="20rem" display="flex" alignItems="center">
			<Box
				boxSize="2rem"
				bg={selected || done ? 'purple.400' : '#f4f4f4'}
				borderRadius="2rem"
				display="flex"
				flexDirection="rows"
				justifyContent="center"
				alignItems="center"
				color={selected || done ? 'white' : 'gray.500'}
				fontWeight="700"
				fontSize="1rem"
				marginRight="0.6rem"
			>
				{done ? <CheckIcon /> : number}
			</Box>
			<Box
				width="10rem"
				fontSize="0.8rem"
				color={useColorModeValue('#6C6C6C', 'white')}
			>
				{text}
			</Box>
		</Box>
	);
};

const PublishStages = ({ stage, updateID }) => {
	return (
		<Box
			borderBottom="solid 1px"
			borderColor={useColorModeValue('#f2f2f2', '#494949')}
		>
			<Box
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				paddingY="1.5rem"
				paddingX="2rem"
				fontFamily="body"
				color="#555555"
			>
				<StageCard
					number="1"
					text={
						updateID
							? 'Select bot flow from your library to update with'
							: 'Select bot flow to publish from your library'
					}
					selected={stage === 1}
					done={stage > 1}
				/>
				<StageCard
					number="2"
					text={
						updateID
							? 'Enter extra details about the updated flow'
							: 'Enter extra details about the published flow'
					}
					selected={stage === 2}
					done={stage > 2}
				/>

				<StageCard
					number="3"
					text={
						updateID
							? 'Review all details and update!'
							: 'Review all details and publish!'
					}
					selected={stage === 3}
					done={stage > 3}
				/>
			</Box>
		</Box>
	);
};

export default PublishStages;
