import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Stack,
	Input,
	InputGroup,
	InputRightElement,
	Button,
	Alert,
	AlertDescription,
	AlertIcon,
	useColorMode,
	useToast,
	Link,
	CloseButton,
	CircularProgress,
	Text,
	Icon,
} from '@chakra-ui/react';
import LoginWithGoogleButton from './LoginWithGoogle';

import { Link as ReactLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import isElectron from 'is-electron';
// API
import APIFetch from '../../../util/APIFetch';
import APIFetchFn from '../../../util/APIFetch/function';
import LoginAPI from './LoginAPI';
//Redux
import { connect } from 'react-redux';
import { setUser, setStatus } from '../../../redux/actions';
// Config
import { config } from '../../../config';
//History
import History, { HistoryWithSlug } from '../../../util/History/';
//Utils
import KeyEnum from '../../../util/KeyEnum';
// Controllers
import LoginController from './LoginController';
import BrowserLogin from './BrowserLogin';
import OrDivider from './OrDivider';
import AppProtocol from '../../../util/AppProtocol';
import ElectronLoginWithGoogle from './LoginWithGoogle/ElectronLoginWithGoogle';

const AlertSection = ({ type, description }) => {
	return (
		<Alert status={type}>
			<AlertIcon />
			<AlertDescription>{description}</AlertDescription>
		</Alert>
	);
};

const PasswordInput = ({ name, onChange, onKeyDown }) => {
	const [show, setShow] = useState(false);
	const borderColor = { light: '#f2f2f2', dark: '#494949' };
	const inputBgColor = { light: 'white', dark: '#4c4c4c' };
	const inputFontColor = { light: 'gray.800', dark: 'white' };
	const { colorMode } = useColorMode();
	const handleClick = () => setShow(!show);

	return (
		<InputGroup size="md" fontFamily="body">
			<Input
				name={name}
				type={show ? 'text' : 'password'}
				placeholder="Enter password"
				onChange={onChange}
				borderColor={borderColor[colorMode]}
				focusBorderColor="purple.500"
				_placeholder={{ color: inputFontColor[colorMode] }}
				color={inputFontColor[colorMode]}
				fontSize="0.8rem"
				bg={inputBgColor[colorMode]}
				size="md"
				borderRadius="5px"
				onKeyDown={onKeyDown}
			/>
			<InputRightElement width="3rem">
				<Box
					as={show ? MdVisibilityOff : MdVisibility}
					size="32px"
					color="gray.300"
					onClick={handleClick}
				/>
			</InputRightElement>
		</InputGroup>
	);
};

/** styling constants */
const fontColor = { light: 'maya_light.400', dark: 'white' };
const bgColor = { light: '#f9f9f9', dark: '#424242' };
const inputBgColor = { light: 'white', dark: '#4c4c4c' };
const inputFontColor = { light: 'gray.800', dark: 'white' };
const borderColor = { light: '#f2f2f2', dark: '#494949' };

/**
 * # Fetch Access Token
 *
 * Function to fetch access token if the user is logged in.
 *
 * Throws an error if user is not logged in.
 */
const fetchAccessToken = APIFetchFn({
	BASEURL: config.apiServer.BASEURL,
	PATH_SEARCH: LoginAPI.apiService.getAccessTokens.PATH_SEARCH,
	PATH_METHOD: LoginAPI.apiService.getAccessTokens.PATH_METHOD,
});

/**
 * Login Card component
 * @param {*} param0
 * @returns
 */
const LoginCard = ({ setUser, setStatus }) => {
	const toast = useToast();
	const [formData, setFormData] = useState({});
	const [formAlert, setFormAlert] = useState({ type: null });
	const [isLoggingIn, setIsLoggingIn] = useState(false);
	const [isBrowserLoggingIn, setIsBrowserLoggingIn] = useState(false);
	/** For restoring the page the user was trying to access earlier, its passed via `location.state` */
	const [restoreLocation, setRestoreLocation] = useState(
		History.location.state?.restoreLocation
	);
	/** Auto Login behavior config */
	const [autoLoginConfig, setAutoLoginConfig] = useState({
		isLoading: false,
		loadingText: '',
	});
	const [loginProvider, setLoginProvider] = useState(
		/**
		 * @returns {'emailPassword' | 'google.com' | 'unset' }
		 */
		() => 'unset'
	);
	// Setup the API Fetch utility for the Login View.
	// console.log(formData);
	const [{ fetchResults: fusionAuthLogin }] = APIFetch({
		BASEURL: config.fusionAuth.BASEURL,
		PATH_SEARCH: LoginAPI.fusionAuth.getLogin.PATH_SEARCH,
		PATH_METHOD: LoginAPI.fusionAuth.getLogin.PATH_METHOD,
	});
	const [showVerify, setShowVerify] = useState(false);
	const [verified, setVerified] = useState(false);
	let query = new URLSearchParams(useLocation().search);
	var verificationId = query.get('verificationId');
	var tenantId = query.get('tenantId');

	React.useEffect(() => {
		if (verificationId && tenantId) {
			LoginController.verifyEmail({ verificationId, tenantId })
				.then((res) => {
					if (res.error) {
						setShowVerify(true);
						setVerified(false);
						toast({
							title: 'Unable to verify email',
							description: 'Email verification failed.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
					} else {
						if (res.verifiedNow) {
							setShowVerify(true);
							setVerified(true);
						} else {
							setShowVerify(true);
							setVerified(true);
							toast({
								title: 'Email already verified',
								description:
									'Email is already verified. Proceed to login',
								status: 'success',
								duration: 3000,
								isClosable: true,
							});
						}
					}
				})
				.catch((err) => {
					if (err.errorObject.status === 404) {
						setShowVerify(true);
						setVerified(true);
						toast({
							title: 'Email already verified',
							description: 'Email is already verified. Proceed to login',
							status: 'success',
							duration: 3000,
							isClosable: true,
						});
					} else {
						setShowVerify(true);
						setVerified(false);
						toast({
							title: 'Unable to verify email',
							description: 'Email verification failed.',
							status: 'error',
							duration: 3000,
							isClosable: true,
						});
					}
				});
		}
	}, [tenantId, toast, verificationId]);
	/** for redirect to desktop app */
	const desktopRedirect = JSON.parse(query.get('desktopRedirect'));
	const { colorMode } = useColorMode();

	/**
	 * Handle input changes
	 *
	 * Update the formData object to hold the newest value for an input
	 * when the value is changed. This is done so we can have a single source
	 * of truth for the inputs.
	 *
	 * @param {object} event Event object that executed the function.
	 */
	const handleInputChange = ({ target }) => {
		// Update state with new value for the input.
		setFormData({
			...formData,
			[target.name]: target.value,
		});
	};

	/**
	 * Handle login actions
	 *
	 * Used to efficiently handle responses from the login requests to either
	 * redirect the user or to display a message to the user.
	 *
	 * @param {Object} responseObj Response object from the request.
	 * @param {Object} data Data object required for handling the action.
	 */
	const handleResponseAction = (responseObj, data) => {
		// Handle login actions with the login controller.
		const response = LoginController.handleAction(responseObj, data, setUser);
		setIsLoggingIn(false);
		// Check if there is a response to be handled.
		if (response) {
			// Set the form alert information.
			console.log('Set form alert', response);
			setFormAlert(response);

			if (response.type === 'danger') {
				toast({
					title: 'Unable to log in.',
					description: 'Check if your details are right!',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			}
		}
		if (restoreLocation) {
			HistoryWithSlug.push(restoreLocation);
		}
	};

	/**
	 * Handle the login response
	 *
	 * Handles the response object from the login API request. Either
	 * tries to log the user in by setting cookies. If not possible,
	 * then the right login action is performed.
	 *
	 * @param {object} response Response object from login API
	 * @param {object} loginResponse Response data from login API analysis
	 */
	const handleLoginResponse = async (response, loginResponse) => {
		// Check if the response status from the FusionAuth call to /api/login
		// resulted in a 200 status, which means the access token and user data
		// were passed back in the response object.

		if (response.status === 200 || response.status === 212) {
			try {
				// Attempt to set the user cookies by utilizing the API Service and pass the access token, refresh token,
				// and language data for tha app.
				const loginResult = await LoginController.setCookies(
					response.data.refreshToken,
					response.data.token,
					response.data.user.id,
					response.data.user.email,
					'en'
				);
				// Handle the login action.
				handleResponseAction(loginResult, response.data);
			} catch (error) {
				// Handle the error.
				handleResponseAction({ type: 'danger', content: error });
			}
		} else {
			// Handle the result from the login response check.
			handleResponseAction(loginResponse, response.data);
		}
	};

	/**
	 * Login into Maya account using fusionAuth login response
	 * @param {*} fusionAuthLoginResponse
	 */
	const handleMayaLogin = async (fusionAuthLoginResponse) => {
		// Determine what to do with the user based on the success response from the FusionAuth service.
		const loginResponse = await LoginController.handleResponse(
			fusionAuthLoginResponse
		);
		console.log('logined', fusionAuthLoginResponse, loginResponse);
		// Handle the response.
		await handleLoginResponse(fusionAuthLoginResponse, loginResponse);
	};

	/**
	 *
	 * @param {*} response Login response obj containing various access tokens(usually from Fusion Auth)
	 */
	const handleFusionAuthLoginResponse = async (response) => {
		try {
			if (desktopRedirect && !isElectron()) {
				/** redirect back to desktop app with the response data as payload */
				if (response.data.refreshToken && response.data.token) {
					// const dataObj = {
					// 	event: 'login',
					// 	payload: {
					// 		...response,
					// 	},
					// };
					// window.location.href = `maya://data?=${JSON.stringify(dataObj)}`;
					delete response.data.user['registrations'];
					AppProtocol('login', {
						data: response.data,
						status: response.status,
					});
				} else console.error(`refreshToken/token invalid!`);
			}
			/** then login into Maya */
			await handleMayaLogin(response);
		} catch (error) {
			if (error.type) {
				// There was some sort of error on login, so show the user.
				handleResponseAction(error);
			} else {
				// Determine what to do with the user based on the error response from
				// the FusionAuth service. error.status can be undefined, so we have to
				// handle that as well.
				const status = error ? error.status : 500;
				// Handle the response.
				LoginController.handleResponse({ status }).catch((error) =>
					handleResponseAction(error)
				);
			}
		}
	};

	/**
	 * Handle form submit
	 *
	 * Perform requests to the FusionAuth and API Service upon form submittal. This
	 * will verify the user login information, and set cookies using the API Service
	 * if the user has provided valid credentials.
	 *
	 * @param {object} e Form object that executed the function.
	 */
	const handleFormSubmit = async (e) => {
		e.preventDefault();
		try {
			// Append the application ID to the form data (necessary to find the user).
			formData.applicationId = config.fusionAuth.APPLICATION_ID;
			setIsLoggingIn(true);
			/** login into fusion auth first and get the response */
			const response = await fusionAuthLogin(formData);
			await handleFusionAuthLoginResponse(response);
		} catch (error) {
			if (error.type) {
				// There was some sort of error on login, so show the user.
				handleResponseAction(error);
			} else {
				// Determine what to do with the user based on the error response from
				// the FusionAuth service. error.status can be undefined, so we have to
				// handle that as well.
				const status = error ? error.status : 500;
				// Handle the response.
				LoginController.handleResponse({ status }).catch((error) =>
					handleResponseAction(error)
				);
			}
		}
	};

	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.ENTER) {
			handleFormSubmit(e);
		}
	};

	useEffect(() => {
		/** This useEffect is for login via browser */
		if (isElectron()) {
			const { ipcRenderer } = window.require('electron');
			ipcRenderer.on('/browser/login', async (event, arg) => {
				try {
					/**
					 * arg payload received is the fusionAuth login response.
					 * This response was forwarded from login in browser
					 */
					const fusionAuthLoginResponse = arg;

					await handleMayaLogin(fusionAuthLoginResponse);
				} catch (error) {
					if (error.type) {
						// There was some sort of error on login, so show the user.
						handleResponseAction(error);
					} else {
						// Determine what to do with the user based on the error response from
						// the FusionAuth service. error.status can be undefined, so we have to
						// handle that as well.
						const status = error ? error.status : 500;
						// Handle the response.
						LoginController.handleResponse({ status }).catch((error) =>
							handleResponseAction(error)
						);
					}
				}
				setIsBrowserLoggingIn(false);
			});
		}
		return () => {
			/** cleanup */
			if (isElectron()) {
				const { ipcRenderer } = window.require('electron');
				ipcRenderer.removeAllListeners('/browser/login');
			}
		};
	}, []);

	useEffect(() => {
		const getTokensForDirectLoginViaBrowser = async () => {
			try {
				const response = await fetchAccessToken();
				if (response.data?.error)
					throw new Error(
						`fetchAccessToken failed: ${response.data?.error}`
					);
				setAutoLoginConfig((old) => ({
					...old,
					isLoading: true,
					loadingText: 'Logging in...',
				}));
				await handleFusionAuthLoginResponse(response);
			} catch (error) {
				console.error(error);
			}
			setAutoLoginConfig((old) => ({
				...old,
				isLoading: false,
				loadingText: '',
			}));
		};
		getTokensForDirectLoginViaBrowser();
	}, []);

	return (
		<Box>
			{showVerify && (
				<Alert
					status={verified ? 'success' : 'error'}
					size="sm"
					marginBottom="1rem"
					borderRadius="0.2rem"
				>
					<AlertIcon />
					<AlertDescription>
						{verified
							? 'Email verified! Now log in.'
							: 'Could not verify email.'}
					</AlertDescription>
				</Alert>
			)}
			<Box
				paddingTop="1rem"
				paddingBottom="2rem"
				paddingX="1.2rem"
				display="flex"
				flexDirection="row"
				justifyContent="center"
				flexGrow="0"
				flexShrink="0"
				bg={bgColor[colorMode]}
				fontFamily="body"
				fontWeight="600"
				borderRadius="5px"
				border="1px solid"
				borderColor={borderColor[colorMode]}
				zIndex="50"
			>
				{autoLoginConfig.isLoading ? (
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="center"
						alignItems="center"
						minWidth="44"
						minHeight="44"
					>
						<CircularProgress
							isIndeterminate
							color="#9B51E0"
							size="2.5rem"
							thickness="6px"
						/>
						{autoLoginConfig.loadingText ? (
							<Text
								fontWeight="thin"
								color="#d6d6d6"
								mt="2"
								maxWidth="10rem"
								textAlign="center"
							>
								{autoLoginConfig.loadingText}
							</Text>
						) : null}
					</Box>
				) : (
					<Stack>
						<Box color={fontColor[colorMode]} fontSize="1.3rem" mb="1rem">
							Welcome Back!
						</Box>
						<InputGroup size="md" style={{ marginTop: '0.5rem' }}>
							<Input
								name="loginId"
								placeholder="Enter email"
								borderColor={borderColor[colorMode]}
								onChange={handleInputChange}
								focusBorderColor="purple.500"
								_placeholder={{ color: inputFontColor[colorMode] }}
								bg={inputBgColor[colorMode]}
								color={inputFontColor[colorMode]}
								onKeyDown={handleOnKeyDown}
								size="md"
								width="14rem"
								fontSize="0.8rem"
								colorScheme="purple"
								borderRadius="5px"
							/>
						</InputGroup>
						<PasswordInput
							name="password"
							onChange={handleInputChange}
							onKeyDown={handleOnKeyDown}
						/>
						<Button
							size="md"
							marginTop="0.5rem"
							onClick={handleFormSubmit}
							isLoading={isLoggingIn}
							color="white"
							fontSize="0.8rem"
							bg="purple.500"
							_hover={{
								bg: 'purple.400',
							}}
							_active={{
								bg: 'purple.300',
							}}
						>
							Login
						</Button>
						<OrDivider />
						{isElectron() ? (
							<ElectronLoginWithGoogle
								onClick={() => {
									setLoginProvider('google.com');
									setIsBrowserLoggingIn(true);
								}}
								onTimeout={() => setIsBrowserLoggingIn(false)}
								isLoading={
									loginProvider === 'google.com' && isBrowserLoggingIn
								}
							/>
						) : (
							<LoginWithGoogleButton
								onClick={() => {
									setLoginProvider('google.com');
								}}
								handleFusionAuthLoginResponse={
									handleFusionAuthLoginResponse
								}
							/>
						)}
						{isElectron() ? (
							<BrowserLogin
								onClick={() => {
									setLoginProvider('emailPassword');
									setIsBrowserLoggingIn(true);
								}}
								onTimeout={() => setIsBrowserLoggingIn(false)}
								isLoading={
									loginProvider === 'emailPassword' &&
									isBrowserLoggingIn
								}
							/>
						) : null}
						<Box
							marginTop="1rem"
							fontSize="0.7rem"
							fontWeight="400"
							textAlign="center"
							opacity="0.9"
						>
							<Link to="/forgot" as={ReactLink} color="purple.100">
								Forgot password?
							</Link>
						</Box>
					</Stack>
				)}
			</Box>
		</Box>
	);
};

// Export the Login View.
export default connect(null, { setUser, setStatus })(LoginCard);
