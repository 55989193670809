import axios from 'axios'
import { config } from '../../config'

// const API_ROOT = 'https://maya-landing-page-git-stripe-checkout-mayahq.vercel.app'
const API_ROOT = config.app.WEBSITE_URL

async function getStripeSessionId(locale, email) {
    const request = {
        method: 'POST',
        url: `${API_ROOT}/api/checkout_session`,
        data: { locale, customerEmail: email }
    }

    const response = await axios(request)
    return response.data
}

export default getStripeSessionId