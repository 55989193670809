import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';
// config
import { config } from '../../config';
import isElectron from 'is-electron';
//redux
import { store } from '../../redux/store';
import { updateBrainById } from '../../redux/actions';
/**
 * Function to Create a New Brain, given some parameters
 */
const sendStopSignal = async ({ brainId, device, slug }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	const formData = {
		_id: brainId,
		device: {
			id: device.id,
			deviceName: device.deviceName,
		},
		profileSlug: profileSlug,
	};
	var stopBrainAPIFn, stopBrainIPCFn, stopBrainResponse;

	if (!isElectron()) {
		stopBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.stopBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.stopBrain.PATH_METHOD,
		});
		stopBrainResponse = await stopBrainAPIFn(formData);
		if (!stopBrainResponse['data']['error']) {
			return stopBrainResponse['data']['results'];
		} else {
			return stopBrainResponse['data'];
		}
	} else {
		stopBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.stopBrain.EVENT,
		});
		stopBrainResponse = await stopBrainIPCFn(formData);
		if (!stopBrainResponse['data']['error']) {
			return stopBrainResponse['data']['results'];
		} else {
			return stopBrainResponse['data'];
		}
	}
};

const stopBrain = ({ brain, slug, onDone, onError }) => {
	let device = {
		id: brain.deviceId,
		deviceName: brain.deviceName,
	};
	onDone = typeof onDone === 'function' ? onDone : () => {};
	onError = typeof onError === 'function' ? onError : () => {};
	if (isElectron()) {
		var electron = window.require('electron');
		var { ipcRenderer } = electron;
		console.log('Expecting kill event for /brain/status/stop/' + brain._id);
		ipcRenderer.removeAllListeners('/brain/status/stop/' + brain._id);
		ipcRenderer.on('/brain/status/stop/' + brain._id, (event, arg) => {
			console.log('event arg is', arg);
			if (arg === 'STOPPED') {
				console.log('Workspace stopped signal received');
				onDone();
				store.dispatch(
					updateBrainById(brain._id, { ...brain, status: 'STOPPED' })
				);
			}
		});
	}
	sendStopSignal({ brainId: brain._id, device: device, slug: slug }).then(
		(res) => {
			if (res['error']) {
				onError();
			}
		}
	);
};

export default stopBrain;
