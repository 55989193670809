import * as React from 'react';
import { Box, Button } from '@chakra-ui/react';
import { connect } from 'react-redux';
import isElectron from 'is-electron';
import analytics from '../../../util/Analytics';
//IPCFetch
import IPCFetch from '../../../util/IPCFetch';

const Updater = ({ update, dispatch }) => {
	// Setup the API Fetch utility for Fetching Robot info.
	const [{ fetchResultsIPC: submitInstallChoiceIPC }] = IPCFetch({
		EVENT: '/api/update',
		METHOD: 'post',
	});

	const choiceHandler = async ({ mode }) => {
		const formData = { mode };
		console.log(formData);
		await submitInstallChoiceIPC(formData);
		analytics.track('[Update App] Install & Restart', {
			version: `v${update.updateVersion}`,
			mode: mode,
		});
	};

	React.useEffect(() => {
		if (isElectron()) {
			const electron = window.require('electron');
			const { ipcRenderer } = electron;
			ipcRenderer.removeAllListeners('/api/update');
			ipcRenderer.on('/api/update', (event, arg) => {
				console.log('From autoupdate:', arg);
				dispatch(arg);
			});
		}
	});
	//update.updateDownloaded && update.visible &&
	return update.updateDownloaded && update.visible ? (
		<Box width="100%" position="absolute" zIndex="4000" bottom="0">
			<Box
				width="50%"
				color="white"
				height="2.5rem"
				borderTopRadius="5px"
				bg="purple.500"
				display="flex"
				alignItems="center"
				alignContent="center"
				flexDirection="column"
				marginLeft="auto"
				marginRight="auto"
			>
				<Box
					display="flex"
					flexDirection="row"
					alignContent="center"
					margin="0.5rem"
				>
					{`Update available to v${update.updateVersion}!`}
					<Button
						variantColor="white"
						variant="outline"
						size="xs"
						marginLeft="0.5rem"
						onClick={() => choiceHandler({ mode: 'InstallAndRestart' })}
					>
						Install & Restart
					</Button>
					<Button
						variantColor="white"
						variant="outline"
						size="xs"
						marginLeft="0.5rem"
						onClick={() => dispatch({ type: 'UPDATE_NOT_NOW' })}
					>
						Not Now
					</Button>
				</Box>
			</Box>
		</Box>
	) : null;
};

const mapStateToProps = (state) => {
	const { update } = state;
	return { update };
};

export default connect(mapStateToProps, null)(Updater);
