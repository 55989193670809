import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';
import getInstallerFlow from '../store/getInstallerFlow';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import deploySkills from '../editor/deploy';

const installExternalSkillpack = async (formData) => {
	var installExternalSkillpackAPIFn,
		installExternalSkillpackIPCFn,
		installExternalSkillpackResponse;

	if (!isElectron()) {
		//TODO install external flow on cloud runtimes
		// installExternalSkillpackAPIFn = APIFunction({
		// 	BASEURL: config.apiServer.BASEURL,
		// 	PATH_SEARCH:
		// 		APIRoutes.apiService.installExternalSkillpack.PATH_SEARCH,
		// 	PATH_METHOD:
		// 		APIRoutes.apiService.installExternalSkillpack.PATH_METHOD,
		// });
		// installExternalSkillpackResponse =
		// 	await installExternalSkillpackAPIFn();
		// if (installExternalSkillpackResponse['data']['error']) {
		// 	return installExternalSkillpackResponse['data'];
		// } else {
		// 	return installExternalSkillpackResponse['data']['results'];
		// }
	} else {
		installExternalSkillpackIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.installExternalSkillpack.EVENT,
		});

		installExternalSkillpackResponse = await installExternalSkillpackIPCFn({
			...formData,
		});
		if (installExternalSkillpackResponse['data']['error']) {
			return installExternalSkillpackResponse['data'];
		}

		installExternalSkillpackResponse['data']['brain'] = await deploySkills({
			profileSlug: formData.profileSlug,
			brainId: formData.brainId,
		});

		return installExternalSkillpackResponse['data'];
	}
};

export default installExternalSkillpack;
