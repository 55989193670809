import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import {
    Box, Flex,
    SimpleGrid
} from '@chakra-ui/react'

import { CommandCard, CommandCardSkeleton } from './CommandCard'
import getCommands from '../../../functions/commands/getCommands'

const NUM_SKELETONS = 10

const CommandList = ({
    query,
    categories = [],
    apps = [],
    currentBrainId,
}) => {
    const [loading, setLoading] = useState(true)
    const [commands, setCommands] = useState([])

    useEffect(() => {
        setLoading(true)
        getCommands(categories, apps, query)
            .then(res => {
                setCommands(res)
                setLoading(false)
            })
            .catch(e => {
                console.log('Error fetching commands:', e)
                setLoading(false)
            })
    }, [categories, query, apps])

    console.log('query', query)

    const commandComps = commands.map(command => <CommandCard command={command} currentBrainId={currentBrainId} />)
    return (
        <Box
            position='relative'
            width='100%'
            display='flex'
            flex='1 1 auto'
            overflow='auto'
        >
            <Box
                position='absolute'
                flex='1 1 auto'
                width='100%'
            >
                <SimpleGrid
                    width="100%"
                    overflowX="hidden"
                    spacing="24px"
                    minChildWidth="320px"
                >
                    {loading 
                    ?   Array.from(Array(NUM_SKELETONS).keys()).map(i => <CommandCardSkeleton key={i}/>)
                    :   commandComps}
                    {/* {commandComps} */}
                </SimpleGrid>
            </Box>
            {/* {[1,2,3].map(i => <CommandCard/>)} */}
        </Box>
    )
}

const mapStateToProps = (state) => {
    let { brains } = state;
	let { publishedSkillPacks } = state;
	let { query } = state.navigation;
	const brainIds = brains.allIds;
	return { brainIds, brains, query, publishedSkillPacks };
}

export default connect(mapStateToProps, null)(CommandList)