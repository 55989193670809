import React, { useContext } from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

//Components
import { StorePackItem } from './StorePackItemSW';
import HorizontalItemSkeleton from './HorizontalItemSkeleton';
import { Box } from '@chakra-ui/react';

//Redux
import { connect } from 'react-redux';
import { updateBrainById } from '../../../redux/actions';

import GetProfileSlug from '../../../util/ProfileSlug';

//Functions
import getStoreSkillPacks from '../../../functions/store/getStoreSkillPacks';
import SetupWizardContext from '../SetupWizardContext';
import analytics from '../../../util/Analytics';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';

const StorePackList = ({
	brainIds,
	brains,
	height,
	onClickFn,
	selectedPackId,
	wholeItemActive,
	currentBrainId,
	updateBrainById,
}) => {
	const loadingList = [1, 2, 3, 4];
	const [packList, setPackList] = React.useState([]);
	const { selectedPublishedSkillPacks, setSelectedPublishedSkillPacks } =
		useContext(SetupWizardContext);
	const { feature } = useContext(AnalyticsContext);

	const loc = useLocation();
	let query = new URLSearchParams(loc.search);
	const [id, setId] = React.useState(() => {
		if (loc.pathname === '/store/skills') {
			return query.get('id');
		} else return null;
	});
	const [isLoading, setLoading] = React.useState(true);
	const { slug } = GetProfileSlug();
	React.useEffect(() => {
		getStoreSkillPacks({
			slug: slug,
			_id: id,
			categories: [],
			platforms: [],
		}).then((res) => {
			setLoading(false);
			// console.log('packList', res.data.results);
			setPackList(res);
		});
	}, []);

	const checkIsInstalled = (currentBrainId, packId) => {
		let check;
		if (currentBrainId && brains.byId[currentBrainId]) {
			check =
				_.findIndex(
					brains.byId[currentBrainId].publishedSkillPacks,
					(pack) => {
						return pack.publishedSkillPack === packId;
					}
				) !== -1;
		} else {
			check = false;
		}
		console.log(check);
		return check;
	};

	return (
		<>
			<AnimateSharedLayout>
				<Box
					display="flex"
					height="100%"
					position="relative"
					overflow="auto"
					border="1px solid #535353"
					borderRadius="md"
				>
					<motion.ul
						layout
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							position: 'absolute',
							borderRadius: '10px',
							padding: '0.8rem',
						}}
					>
						{isLoading || packList.length < 1
							? loadingList.map((item) => (
									<HorizontalItemSkeleton key={item} width={'100%'} />
							  ))
							: packList.map((publishedSkillPack, i) => (
									<StorePackItem
										key={publishedSkillPack._id}
										publishedSkillPack={publishedSkillPack}
										selected={selectedPublishedSkillPacks.some(
											(psp) => psp._id === publishedSkillPack._id
										)}
										onClick={(publishedSkillPack) => {
											analytics.track(
												`[${feature}] StorePackItem: Click`,
												{
													feature,
													publishedSkillPackId: publishedSkillPack,
												}
											);
										}}
										wholeItemActive={wholeItemActive}
										isItemExpanded={i === 0}
										currentBrainId={currentBrainId}
										isInstalled={checkIsInstalled(
											currentBrainId,
											publishedSkillPack._id
										)}
										updateBrainById={updateBrainById}
									/>
							  ))}
					</motion.ul>
				</Box>
			</AnimateSharedLayout>
		</>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	//let { query } = state.navigation;
	const brainIds = brains.allIds;
	return { brainIds, brains };
};

export default connect(mapStateToProps, { updateBrainById })(StorePackList);
