import React from 'react';
import { connect } from 'react-redux';
import dotProp from 'dot-prop-immutable';
import CommandSearch from './CommandSearch';
import PlaceholderSearch from './PlaceholderSearch';

const SearchList = ({ mode, type }) => {
	const SelectSearchList = () => {
		if (mode === 'intents') {
			return <CommandSearch intentType={type} />;
		} else if (mode === 'placeholder') {
			return <PlaceholderSearch placeholderType={type} />;
		} else {
			return <div></div>;
		}
	};
	return SelectSearchList();
};

const matchStateToProps = (state, ownProps) => {
	const { centerSearch } = state.cardUI;
	const middle = dotProp.get(centerSearch, `middle`);
	const { mode, type } = middle;
	return { mode, type };
};

export default connect(matchStateToProps)(SearchList);
