import * as React from 'react';
import { Skeleton, useColorMode, Box } from '@chakra-ui/react';

const MayaSkeleton = (props) => {
	const { colorMode } = useColorMode();
	const colorStart = { light: '#ecebeb', dark: '#636363' };
	const colorEnd = { light: '#f3f3f3', dark: '#605E5E' };

	return (
		<Box {...props} opacity="0.7">
			<Skeleton
				width="100%"
				height="100%"
				borderRadius={props.borderRadius || '10px'}
				startColor={colorStart[colorMode]}
				endColor={colorEnd[colorMode]}
			/>
		</Box>
	);
};

export default MayaSkeleton;
