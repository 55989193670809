import startBrain from './startBrain';
import stopBrain from './stopBrain';
import getBrainById from './getBrainById';

const restartBrainUtil = async (
	brainList,
	slug,
	onDone,
	onError,
	onLoading,
	i
) => {
	let brain;
	if (i < brainList.length) {
		brain = await getBrainById({ brainId: brainList[i], slug });
		console.log('restarting Brain: ', brain._id, brain.name);
		stopBrain({
			brain: brain,
			slug,
			onDone: async () => {
				brain = await getBrainById({ brainId: brainList[i], slug });
				console.log(
					'Workspace stopped successfully: ',
					brain._id,
					brain.name
				);
				startBrain({
					brain: brain,
					slug,
					onDone: () => {
						console.log(
							'Workspace started successfully: ',
							brain._id,
							brain.name
						);
						restartBrainUtil(
							brainList,
							slug,
							onDone,
							onError,
							onLoading,
							i + 1
						);
					},
					onError: onError,
					onLoading: onLoading,
				});
			},
			onError: onError,
			onLoading: onLoading,
		});
	} else {
		console.log('All brains have been restarted successfully');
		onDone();
	}
};

const restartBrainList = ({ brainList, slug, onDone, onError, onLoading }) => {
	console.log('brainList: ', brainList);
	onDone = typeof onDone === 'function' ? onDone : () => {};
	onError = typeof onError === 'function' ? onError : () => {};
	onLoading = typeof onLoading === 'function' ? onLoading : () => {};
	let i = 0;
	restartBrainUtil(brainList, slug, onDone, onError, onLoading, i);
};

export default restartBrainList;
