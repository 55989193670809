import React from 'react';
import styled from '@emotion/styled';
import { Box, useColorMode, IconButton, Img } from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import isElectron from 'is-electron';
import { HeaderSection } from './Header';
import mandalaOne from '../../assets/patterns/mandala_one.png';

const LandingPage = ({ children }) => {
	const { colorMode } = useColorMode();
	const bgColor = { light: 'white', dark: 'maya_dark.300' };
	function ColorModeExample() {
		const { colorMode, toggleColorMode } = useColorMode();
		return (
			<IconButton
				onClick={toggleColorMode}
				position="absolute"
				bottom="0"
				right="0"
				icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
			>
				Toggle {colorMode === 'light' ? 'Dark' : 'Light'}
			</IconButton>
		);
	}
	return (
		<Box
			flexGrow="1"
			display="flex"
			flexDirection="column"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			bg={bgColor[colorMode]}
			marginTop={isElectron() ? '45px' : '0px'}
			overflow="hidden"
			height="100vh"
		>
			<HeaderSection />
			<Box display="flex" justifyContent="center" marginTop="4rem">
				{children}
			</Box>
			<Img
				width={{ sm: '105vw', md: '95vw', lg: '45vw' }}
				height={{ sm: '105vw', md: '95vw', lg: '45vw' }}
				objectFit="cover"
				src={mandalaOne}
				position="absolute"
				opacity="0.07"
				left="0"
				right="0"
				bottom="0"
				textAlign="center"
				margin="auto"
				marginBottom={{ sm: '-35vh', md: '-55vh', lg: '-35vh' }}
				zIndex="1"
			/>
		</Box>
	);
};

export default LandingPage;
