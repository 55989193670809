import React from 'react';
import { useColorMode, IconButton } from '@chakra-ui/react';

/**
 *
 * @param {{
 * 	onClick: () => void,
 * 	icon: import('react-icons/lib').IconType,
 * } & import('@chakra-ui/react').IconButtonProps} param0
 * @returns
 */
const WebCardButton = ({ onClick, icon, ...rest }) => {
	const { colorMode } = useColorMode();
	const bgColor = { light: 'brown.50', dark: 'maya_dark.200' };
	const color = { light: 'brown.200', dark: 'maya_dark.0' };
	return (
		<IconButton
			minWidth="0"
			padding="0"
			width="1.2rem"
			height="1.2rem"
			rounded="0.18rem"
			transition="none"
			alignContent="middle"
			bg={bgColor[colorMode]}
			color={color[colorMode]}
			border="none"
			outline="none"
			_focus={{ border: 'none' }}
			fontFamily="body"
			onClick={onClick}
			icon={icon}
			{...rest}
		/>
	);
};

export default WebCardButton;
