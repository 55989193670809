import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';

/**
 *
 * @param {Array of module package names} formData
 * @returns
 */
const getModuleList = async (formData) => {
	console.log(formData);
	var getModuleListAPIFn, getModuleListResponse;

	getModuleListAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getModuleList.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getModuleList.PATH_METHOD,
	});
	getModuleListResponse = await getModuleListAPIFn(formData);
	console.log('getModuleListResponse', getModuleListResponse);
	if (getModuleListResponse['data']['error']) {
		return getModuleListResponse['data'];
	} else {
		return getModuleListResponse['data'];
	}
};

export default getModuleList;
