import React, { useContext, useEffect, useRef, useState } from 'react';
import KeyEnum from '../../../../../../../util/KeyEnum';
import { useDispatch, useSelector } from 'react-redux';
import PlaceholderInput from './PlaceholderInput';
import {
	placeholderInputKeydown,
	placeholderSearchChange,
} from '../../../../../../../redux/actions/cardUIV2';
import CommandBarContext from '../../../CommandBarContext';

/*
endpointUrl: "http://localhost:3001/search-248e5ac1-2d9836"
key: "query"
keyType: "custom"
meta: undefined
ref: {current: input.chakra-input.css-e691w}
type: "placeholder"
value: "hell"

 */

/**
 *
 * @param {{
 *		item: import('../../../../../../../redux/reducers/cardUIV2').CuiIntentComponent,
 * 	placeholderId: string,
 *		onNext: () => void,
 *		onSelect: () => void,
 *		onCancel: () => void,
 *		onSubmit: () => void,
 * }} param0
 * @returns
 */
const IntentPlaceholder = ({
	item,
	placeholderId,
	onNext,
	onSelect,
	onCancel,
	onSubmit,
}) => {
	const dispatch = useDispatch();
	const { setCurrentInputRef } = useContext(CommandBarContext);
	const placeholderComponentValues = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) =>
			state.cardUIV2.commandBar.searchArea.placeholderComponents.byId[
				placeholderId
			]
	);
	const {
		selectedPlaceholderComponent: selectedPlaceholderId,
		ifMultipleInputPlaceholders,
	} = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.searchArea
	);
	/** Flag to set init value on inputs and not commit them to state */
	const [firstChange, setFirstChange] = useState(true);
	const [focused, setFocus] = useState(false);
	/**
	 * @type {React.MutableRefObject<HTMLInputElement>}
	 */
	const inputRef = useRef(null);
	// const [value, setValue] = useState(
	// 	inputValue ? inputValue : `${item.value}*`
	// );

	const focusInput = (e) => {
		if (inputRef.current) inputRef.current.select();
		onSelect();
		setFocus(true);
	};

	// useEffect(() => {
	// 	if (selectedIndex !== 0 && placeholderId === currentPlaceholderId) {
	// 		setValue(searchItems[selectedIndex - 1].show);
	// 		setMiddlePlaceholderText(searchItems[selectedIndex - 1].show);
	// 		setPlaceholderValueById(
	// 			placeholderId,
	// 			searchItems[selectedIndex - 1].show,
	// 			searchItems[selectedIndex - 1].submit
	// 		);
	// 	} else if (
	// 		selectedIndex === 0 &&
	// 		placeholderId === currentPlaceholderId
	// 	) {
	// 		focusInput();
	// 	}
	// 	// setMiddlePlaceholderText("testing" + selectedIndex);
	// }, [selectedIndex, setMiddlePlaceholderText]);

	const handleChange = (e) => {
		setFirstChange(false);
		dispatch(
			placeholderSearchChange({
				type: 'placeholderComponentById',
				placeholderId,
				changes: {
					inputValue: e.target.value,
				},
			})
		);
	};

	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.ENTER || keyCode === KeyEnum.TAB) {
			e.preventDefault();
			onNext();
			if (ifMultipleInputPlaceholders) {
				if (e.shiftKey) {
					console.log('SHIFT');
					onSubmit();
				}
			} else {
				console.log('No SHIFT, Direct ENTER PRESS');
				onSubmit();
			}
		} else if (keyCode === KeyEnum.ESC) {
			e.preventDefault();
			onCancel();
		} else if (keyCode === KeyEnum.UP_ARROW) {
			e.preventDefault();
			dispatch(placeholderInputKeydown({ keyType: 'UP' }));
		} else if (keyCode === KeyEnum.DOWN_ARROW) {
			e.preventDefault();
			dispatch(placeholderInputKeydown({ keyType: 'DOWN' }));
		}
	};

	useEffect(() => {
		/** Select this input if the selected placeholderID is the same as that of this components */
		if (placeholderId === selectedPlaceholderId) {
			if (inputRef.current) {
				inputRef.current.select();
				setCurrentInputRef(inputRef); // make this ref accessible globally
			}
		}
	}, [placeholderId, selectedPlaceholderId, setCurrentInputRef]);

	return placeholderComponentValues ? (
		<PlaceholderInput
			focused={focused}
			inputRef={inputRef}
			inputWidth={`${(item.value.length + 1) * 0.55}rem`}
			onChange={handleChange}
			onKeyDown={handleOnKeyDown}
			onPlaceholderClick={focusInput}
			value={
				firstChange
					? `${item.value}*`
					: placeholderComponentValues.inputValue
			}
		/>
	) : null;
};

export default IntentPlaceholder;
