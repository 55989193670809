import React from 'react';
import { motion, AnimateSharedLayout } from 'framer-motion';
//Components
import { StorePackItem } from '../Store/StorePack/StorePackItem';
import { HorizontalItemSkeleton } from '../Reusable/HorizontalItem';
import { Box } from '@chakra-ui/react';
//Redux
import { connect } from 'react-redux';

const LibraryStoreSection = ({
	brainIds,
	brains,
	query,
	height,
	onClickFn,
	selectedPackId,
	wholeItemActive,
	currentBrainId,
	publishedSkillPacks,
}) => {
	const loadingList = [1, 2, 3, 4];
	// React.useEffect(() => {
	//   setPackList(packListSearch(packs, query));
	// }, [packs, query]);

	const [isLoading, setLoading] = React.useState(false);
	console.log('LibrarStoreSection');

	return (
		<>
			<AnimateSharedLayout>
				<Box
					display="flex"
					height="100%"
					position="relative"
					overflow="auto"
				>
					<motion.ul
						layout
						style={{
							paddingTop: '1rem',
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							position: 'absolute',
							borderRadius: '10px',
						}}
					>
						{isLoading || publishedSkillPacks.length < 1
							? loadingList.map((item) => (
									<HorizontalItemSkeleton key={item} width={'100%'} />
							  ))
							: publishedSkillPacks.map(({ publishedSkillPack }) => {
									return publishedSkillPack ? (
										<StorePackItem
											key={publishedSkillPack._id}
											publishedSkillPack={publishedSkillPack}
											selected={false}
											onClick={(publishedSkillPack) =>
												console.log(publishedSkillPack._id)
											}
											wholeItemActive={true}
											isItemExpanded={false}
											currentBrainId={currentBrainId}
											addButton={false}
										/>
									) : null;
							  })}
					</motion.ul>
				</Box>
			</AnimateSharedLayout>
		</>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state) => {
	let { brains } = state;
	let { query } = state.navigation;
	const brainIds = brains.allIds;
	return { brainIds, brains, query };
};

export default connect(mapStateToProps, null)(LibraryStoreSection);
