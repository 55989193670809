import React from 'react';

/**
 * @type {{
 *  feature: 'setup-wizard' | 'command-bar' | 'card-ui' | 'uncategorized'
 * }}
 */
const contextObject = {
	feature: '',
};

const AnalyticsContext = React.createContext(contextObject);

export default AnalyticsContext;
