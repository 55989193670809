import React from 'react';
import {
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	MenuGroup,
	MenuDivider,
	useColorModeValue,
	useDisclosure,
	useToast,
	useColorMode,
} from '@chakra-ui/react';
import { connect, useSelector } from 'react-redux';
import { DoubleChevron } from '../../../assets/icons';
import { FiSettings } from 'react-icons/fi';
import { AiOutlineTeam, AiOutlinePlus, AiOutlineLogout } from 'react-icons/ai';
import { useLocation } from 'react-router-dom';

// Redux Actions
import {
	logoutUser,
	setProfiles,
	setCurrentProfile,
	setLoggedIn,
} from '../../../redux/actions';

// Controllers
import LogoutController from '../../../util/Logout';

//Images
// import dummy from "./dummy.png";
// import maya_icon from "../../../assets/icon.png";

//Components
import IconContainer from '../../../util/React_Icon';
import CreateTeamModal from '../../Teams/CreateTeam';
import Avatar from '../../Reusable/Avatar';
import MayaSkeleton from '../../../util/ContentLoaders/MayaSkeleton';
import UsageStats from '../UsageStats';
import AppVersion from '../AppVersion';

//Utils
import History from '../../../util/History';

//Functions
import getUserProfiles from '../../../functions/profile/getUserProfiles';

const CreateTeam = () => {
	const toast = useToast();
	var iconColor = useColorModeValue('#656565', 'white');
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<Box
			height="100%"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
			onClick={() => {
				toast({
					title: 'Coming soon!',
					description: 'Share workflows with your team & friends.',
					status: 'success',
					duration: 9000,
					isClosable: true,
				});
				// History.push('/onboarding');
			}}
		>
			<Box display="flex" alignItems="center">
				<Box ml="1rem">
					<IconContainer
						icon={<AiOutlineTeam />}
						value={{
							color: iconColor,
							className: 'global-class-name',
							size: '1rem',
						}}
					/>
				</Box>

				<Box marginLeft="1.3rem">
					<Box fontSize="0.8rem" fontWeight="600" color={'white'}>
						{'Create Team'}
					</Box>
				</Box>
			</Box>
			<Box mr="5">
				<IconContainer
					icon={<AiOutlinePlus />}
					value={{
						color: iconColor,
						className: 'global-class-name',
						size: '1rem',
					}}
				/>
			</Box>
			<CreateTeamModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
		</Box>
	);
};

const Logout = ({ setLoggedIn, logoutUser }) => {
	var iconColor = useColorModeValue('#656565', 'white');
	const toast = useToast();
	const logout = (e) => {
		// Prevent the link from executing.
		// e.preventDefault();
		console.log('logging out');

		LogoutController.logout()
			.then(() => {
				// Call the logout function.
				logoutUser();
				setLoggedIn(false);
				// window.location.reload();
				History.replace('/login');
			})
			.catch((error) =>
				// Display the error from the API server on logout.
				toast({
					title: 'An Error Occurred',
					description: "We're working on it.",
					position: 'bottom',
					status: 'error',
					duration: 3000,
					isClosable: false,
				})
			);

		// Use the LogoutController to handle the logout.
	};

	return (
		<Box
			height="3rem"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
			onClick={logout}
		>
			<Box display="flex" alignItems="center">
				<Box ml="1rem">
					<IconContainer
						icon={<AiOutlineLogout />}
						value={{
							color: iconColor,
							className: 'global-class-name',
							size: '1rem',
						}}
					/>
				</Box>
				<Box marginLeft="1.3rem">
					<Box
						fontSize="0.8rem"
						fontWeight="600"
						color={useColorModeValue('#444444', 'white')}
					>
						{'Log Out'}
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const ConnectedLogout = connect(null, { logoutUser, setLoggedIn })(Logout);

// const ProfileItem = ({ name, img }) => {
//   var iconColor = useColorModeValue("#656565", "white");

//   return (
//     <Box
//       height="3rem"
//       width="100%"
//       display="flex"
//       flexDirection="row"
//       alignItems="center"
//       justifyContent="space-between"
//       fontFamily="body"
//       borderRadius="5px"
//       cursor="pointer"
//     >
//       <Box display="flex" alignItems="center">
//         <Image
//           boxSize="1.5rem"
//           borderRadius="full"
//           src={img}
//           alt="Fluffybuns the destroyer"
//           ml="0.8rem"
//         />
//         <Box marginLeft="1rem">
//           <Box
//             fontSize="0.8rem"
//             fontWeight="600"
//             color={useColorModeValue("#444444", "white")}
//           >
//             {name}
//           </Box>
//         </Box>
//       </Box>
//       <Box mr="5">
//         <IconContainer
//           icon={<FiSettings />}
//           value={{
//             color: iconColor,
//             className: "global-class-name",
//             size: "1rem",
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

export const unSwitchableRoutes = ['/store/skills', '/store/modules'];

const ProfileItem = ({ details, setCurrentProfile, currentProfile }) => {
	var iconColor = useColorModeValue('#656565', 'white');
	let location = useLocation();

	const switchProfile = () => {
		var newPath;
		console.log(newPath);
		if (
			currentProfile.profileSlug !== details.profileSlug &&
			unSwitchableRoutes.indexOf(location.pathname) === -1
		) {
			if (details.isTeam) {
				newPath = location.pathname.substring(
					location.pathname.indexOf('/') + 1
				);
				var testPath = `${details.profileSlug}/${newPath}`;
				var finalRoute =
					'/' + testPath.replace(currentProfile.profileSlug + '/', '');
				console.log(finalRoute);
				History.push(finalRoute);
			} else {
				if (currentProfile.isTeam) {
					newPath = location.pathname.replace(
						'/' + currentProfile.profileSlug,
						''
					);
				} else {
					newPath = location.pathname;
				}

				console.log(newPath);
				History.push(newPath);
			}
		}
		setCurrentProfile(details);
	};
	return (
		<Box
			height="3rem"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
			onClick={switchProfile}
		>
			<Box display="flex" alignItems="center" paddingLeft="0.4rem">
				<Avatar name={details.name} size="21" />
				<Box marginLeft="1rem">
					<Box
						fontSize="0.8rem"
						fontWeight="600"
						color={useColorModeValue('#444444', 'white')}
					>
						{details.name}
					</Box>
				</Box>
			</Box>
			<Box mr="5">
				<IconContainer
					icon={<FiSettings />}
					value={{
						color: iconColor,
						className: 'global-class-name',
						size: '1rem',
					}}
				/>
			</Box>
		</Box>
	);
};

const SelectProfile = (props) => {
	//const [teams, setTeams] = React.useState([]);
	var { user, teams, selected, setProfiles, setCurrentProfile } = props;
	const { colorMode } = useColorMode();
	const { tier } = useSelector((store) => store.profiles.selected);
	const fontColour = {
		light: 'maya_dark.500',
		dark: 'white',
	};

	React.useEffect(() => {
		getUserProfiles().then((res) => {
			setProfiles(res);
			// res.map((item) => {
			//   if (!item.isTeam) {
			//     setUser(item);
			//   } else {
			//     var newTeams = [...teams, item];
			//     setTeams(newTeams);
			//   }
			// });
			console.log('Profiles :', res);
		});
	}, []);

	return (
		<Box {...props}>
			{tier === 'FREE' && <UsageStats />}
			{/* {true && <UsageStats />} */}
			<AppVersion />
			<Menu
				border="solid 1px"
				borderColor={useColorModeValue(
					'dashboard.border_light',
					'dashboard.border_dark'
				)}
				placement="top"
				display="flex"
			>
				<MenuButton
					as={Box}
					width="100%"
					bottom="0"
					height="50px"
					display="flex"
					alignItems="center"
					borderTop="solid 1px"
					borderBottom="solid 1px"
					borderColor={useColorModeValue('#f2f2f2', '#494949')}
					_hover={{
						bg: useColorModeValue('#f9f9f9', 'maya_dark.150'),
					}}
					cursor="pointer"
					transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
				>
					<Box
						alignItems="center"
						justifyContent="space-between"
						display="flex"
						paddingLeft="10px"
					>
						{selected.name ? (
							<Box display="flex" alignItems="center" mt="1">
								<Avatar
									name={selected.name}
									size="20"
									marginLeft="0.5rem"
								/>
								<Box
									fontFamily="body"
									color={fontColour[colorMode]}
									fontSize="16px"
									ml="4"
								>
									{selected.name}
								</Box>
							</Box>
						) : (
							<Box display="flex" alignItems="center">
								<MayaSkeleton
									width="1rem"
									height="1rem"
									ml="2"
									borderRadius="1rem"
								/>
								<MayaSkeleton
									width="4rem"
									height="0.5rem"
									ml="2"
									borderRadius="1rem"
								/>
							</Box>
						)}
						<Box mr="2">
							<DoubleChevron />
						</Box>
					</Box>
				</MenuButton>
				<MenuList
					bg={useColorModeValue('white', 'maya_dark.300')}
					boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
					minWidth="210px"
				>
					<MenuGroup title="Personal" fontFamily="body">
						<MenuItem
							minH="48px"
							padding="0"
							transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
							onClick={() => {
								setCurrentProfile(user);
							}}
						>
							<ProfileItem
								details={user}
								setCurrentProfile={setCurrentProfile}
								currentProfile={selected}
								pageMode={props.pageMode}
							/>
						</MenuItem>
					</MenuGroup>
					<MenuDivider />
					<MenuGroup title="Teams" fontFamily="body">
						{teams.map((team) => {
							return (
								<MenuItem
									minH="48px"
									padding="0"
									key={team.name}
									transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
									onClick={() => {
										setCurrentProfile(team);
									}}
								>
									<ProfileItem
										details={team}
										setCurrentProfile={setCurrentProfile}
										pageMode={props.pageMode}
										currentProfile={selected}
									/>
								</MenuItem>
							);
						})}
					</MenuGroup>
					<MenuDivider />

					<MenuItem
						minH="48px"
						padding="0"
						transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					>
						<CreateTeam />
					</MenuItem>
					<MenuItem
						minH="48px"
						padding="0"
						transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					>
						<ConnectedLogout />
					</MenuItem>
				</MenuList>
			</Menu>
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { user, teams, selected } = state.profiles;
	const { pageMode } = state.navigation;
	return { user, teams, selected, pageMode };
};

export default connect(mapStateToProps, { setProfiles, setCurrentProfile })(
	SelectProfile
);
