import React, { useEffect, useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useColorModeValue,
} from '@chakra-ui/react';
import CustomModal from '../../Reusable/ChakraCustom/CustomModal';
import HeaderWithDescription from '../../Reusable/HeaderWithDesc';
import { StorePackDetails, StorePackItemSkeleton } from './StorePackDetails';
import getSkillPack from '../../../functions/skillPack/getSkillPack';
import getStoreSkillPacks from '../../../functions/store/getStoreSkillPacks';
import getPublishedSkillpackGQuery from '../../../functions/publishedSkillPack/getPublishedSkillpackGquery';

export const StorePackModal = ({
	isOpen,
	onClose,
	publishedSkillPack,
	currentBrainId,
	currentFlow,
	publishedSkillPackId,
}) => {
	const [loading, setLoading] = useState(false);
	const [pspTemp, setPsptemp] = useState(publishedSkillPack || {});
	const [finalPsp, setFinalPsp] = useState(publishedSkillPack || pspTemp);
	/**
	 * When displaying commands, it makes more sense to load modal content after it's rendered
	 * instead of passing it as a prop. Otherwise, each command card will send one request to
	 * pre-fetch the publishedSkillPack associated with it, which will result in a network request
	 * explosion. Explosions bad.
	 */
	useEffect(() => {
		if (publishedSkillPack) {
			//this condition works when modal is opened coming from skillpacks list
			publishedSkillPack['currentFlow'] = currentFlow;
			setLoading(false);
			return;
		} else {
			// this condition works when modal is opened from commands list page
			getStoreSkillPacks({ _id: publishedSkillPackId }).then((res) => {
				setPsptemp(res);
				getPublishedSkillpackGQuery({ _id: publishedSkillPackId }).then(
					(response) => {
						res['currentFlow'] =
							response.data['skillpacks'][0].currentFlow;
						setFinalPsp(res);
						setLoading(false);
					}
				);
			});
		}
	}, [currentFlow, publishedSkillPack, publishedSkillPackId]);

	return (
		<>
			<CustomModal onClose={onClose} isOpen={isOpen} isCentered={true}>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					height="35rem"
					maxWidth="55rem"
				>
					<ModalCloseButton
						right="2"
						border="none"
						cursor
						_focus={{ outline: 'none' }}
					/>
					<ModalBody paddingX="0">
						{loading ? (
							<HeaderWithDescription
								header={'Install Collection'}
								desc={finalPsp.shortDescription}
								paddingX="1rem"
								tooltip={
									'A collection of related Maya commands that are used to trigger Maya skill workflows.'
								}
							/>
						) : (
							<HeaderWithDescription
								header={'Install Collection'}
								desc={''}
								paddingX="1rem"
								tooltip={
									'A collection of related Maya commands that are used to trigger Maya skill workflows.'
								}
							/>
						)}
						{loading ? (
							<StorePackItemSkeleton />
						) : (
							<StorePackDetails
								publishedSkillPack={finalPsp}
								currentBrainId={currentBrainId}
							/>
						)}
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};
