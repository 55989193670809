import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import MayaSkeleton from '../../../util/ContentLoaders/MayaSkeleton';

export const HorizontalItemSkeleton = ({ width }) => {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.250',
	};
	const borderColor = {
		light: '#f2f2f2',
		dark: 'dashboard.border_dark',
	};
	return (
		<Box zIndex="4">
			<Box
				height="100px"
				bg={bgColor[colorMode]}
				borderRadius="10px"
				display="flex"
				alignItems="center"
				maxWidth={width}
				marginBottom="15px"
				border={`solid 1px`}
				borderColor={borderColor[colorMode]}
				boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 6px"
				zIndex="4"
			>
				<Box height="80%" display="flex" zIndex="4">
					<MayaSkeleton
						borderRadius="6px"
						width="107px"
						height="100%"
						ml="2"
					/>
					<Box
						height="100%"
						ml="2"
						display="flex"
						flexDir="column"
						justifyContent="center"
					>
						<MayaSkeleton
							borderRadius="9px"
							width="150px"
							height="15px"
							ml="2"
							// mt="1"
						/>
						<Box display="flex">
							<MayaSkeleton
								borderRadius="9px"
								width="150px"
								height="15px"
								ml="2"
								mt="2"
							/>
							<MayaSkeleton
								borderRadius="9px"
								width="100px"
								height="15px"
								ml="2"
								mt="2"
							/>
						</Box>
						<Box display="flex">
							<MayaSkeleton
								borderRadius="9px"
								width="80px"
								height="15px"
								ml="2"
								mt="2"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default HorizontalItemSkeleton;
