import isElectron from 'is-electron';

import IPCFunction from '../../../util/IPCFetch/function';
import IPCRoutes from '../../../util/IPCRoutes';

const isUsingKeytar = async () => {
	if (isElectron()) {
		var useKeytarIPCFn, useKeytarIPCResponse;
		useKeytarIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.useKeytar.EVENT,
		});
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useKeytarIPCResponse = await useKeytarIPCFn();
		return useKeytarIPCResponse;
	}
};

export default isUsingKeytar;
