import GQLFetch from '../../util/GQLFetch';
// import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
import { store } from '../../redux/store';

/**
 *
 * @param {*} {"_id": "skillpackId"}
 * @returns
 */
const getPublishedSkillPackGQuery = async (formData) => {
	console.log(formData);
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;
	const skillpackId = formData._id;
	const getPublishedSkillPackGQueryFn = GQLFetch({
		BASEURL: config.app.CMS_BACKEND + '/graphql',
		gquery: `
		query($_id: String!) {
			skillpacks (where: {
				_id: $_id
			 }){
				_id
				currentFlow {
				_id
				flowJSON
				intents
				externalModules {
					 name
					 packageName
					 version
					 type
				}
				modules{
					 _id
					 name
					 module{
						  _id
						  userProfile
						  name
						  thumbnail
						  packageName
						  description
						  currentVersion
						  type
						  configurationType{
							_id
							type
							config
							resource
							authUrl
							clientId
							responseType
							redirectUrl
							resource
							extraAuthParams
						 	}
						  versions {
							  _id
							  version
							  nodes {
								  name
								  description
							  }
							  downloadDetails{
								site
								user
								id
								repo
								_id
							 }
						  }
					 }
				}
				}
			 }
		}`,
		params: { _id: skillpackId },
	});

	const getPublishedSkillPackGQueryResponse =
		await getPublishedSkillPackGQueryFn();
	return getPublishedSkillPackGQueryResponse;
};

export default getPublishedSkillPackGQuery;
