import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import deploySkills from '../editor/deploy';

const updatePublishedSkillPackToBrain = async (formData) => {
	console.log(formData);
	var updatePublishedSkillPackToBrainAPIFn,
		updatePublishedSkillPackToBrainIPCFn,
		updatePublishedSkillPackToBrainResponse,
		getPublishedSkillPackByIdFn,
		getPublishedSkillPackByIdResponse;
	if (!isElectron()) {
		updatePublishedSkillPackToBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH:
				APIRoutes.apiService.updatePublishedSkillPackToBrain.PATH_SEARCH,
			PATH_METHOD:
				APIRoutes.apiService.updatePublishedSkillPackToBrain.PATH_METHOD,
		});
		updatePublishedSkillPackToBrainResponse =
			await updatePublishedSkillPackToBrainAPIFn();
		if (updatePublishedSkillPackToBrainResponse['data']['error']) {
			return updatePublishedSkillPackToBrainResponse['data'];
		} else {
			return updatePublishedSkillPackToBrainResponse['data']['results'];
		}
	} else {
		updatePublishedSkillPackToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.updatePublishedSkillPackToBrain.EVENT,
		});

		updatePublishedSkillPackToBrainResponse =
			await updatePublishedSkillPackToBrainIPCFn({
				...formData,
			});
		console.log(
			'updatePublishedSkillPackToBrainResponse',
			updatePublishedSkillPackToBrainResponse
		);
		if (updatePublishedSkillPackToBrainResponse['data']['error']) {
			return updatePublishedSkillPackToBrainResponse['data'];
		}

		updatePublishedSkillPackToBrainResponse['data']['brain'] =
			await deploySkills({
				profileSlug: formData.profileSlug,
				brainId: formData.brainId,
			});

		return updatePublishedSkillPackToBrainResponse['data']['results'];
	}
};

export default updatePublishedSkillPackToBrain;
