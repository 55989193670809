//Dependencies
import React from 'react';

import { Box, useColorMode } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
//icons
import {
	ClockIcon,
	StoreIcon,
	SkillIcon,
	BrainIcon,
	SettingsIcon,
	LibraryIcon,
	UpdatesIcon,
} from '../../assets/icons';

const icons = {
	home: ClockIcon,
	store: StoreIcon,
	brains: BrainIcon,
	skills: SkillIcon,
	modules: SkillIcon,
	commands: SkillIcon,
	settings: SettingsIcon,
	library: LibraryIcon,
	updates: UpdatesIcon,
};

/**
 * SearchTag
 *
 * Tags to indicate which mode you're in Navigation Search bar
 * @param {type} type : enter what type the SearchTag is of
 */

const SearchTag = ({ type }) => {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: '#fff',
		dark: '#4F4F4F',
	};
	const borderColor = {
		light: 'dashboard.border_light',
		dark: 'dashboard.border_dark',
	};
	const fontColor = {
		light: '#6C6C6C',
		dark: 'white',
	};

	const IconElement = icons[type];
	return (
		<Box
			display="flex"
			bg={bgColor[colorMode]}
			height="2rem"
			alignItems="center"
			paddingX="3"
			ml="2"
			borderRadius="5px"
			border="solid 1px"
			borderColor={borderColor[colorMode]}
		>
			<Box mt="0.2rem">
				<IconElement size={16} color={fontColor[colorMode]} />
			</Box>
			<Box
				ml="2"
				fontFamily="body"
				fontSize="0.8rem"
				fontWeight="400"
				color={fontColor[colorMode]}
				marginRight="0.5rem"
			>
				{type.charAt(0).toUpperCase() + type.slice(1)}
			</Box>
		</Box>
	);
};

export default SearchTag;
