import isElectron from 'is-electron';

import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const getAccountId = async ({ configDetails, formData }) => {
	if (isElectron()) {
		// TODO: redundant condition. Will be removed later.
		if (configDetails.configurationType.resource !== 'zoom') {
			return null;
		}

		let getAccountIdIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.getAccountId.EVENT,
		});
		let getAccountIdResponse = await getAccountIdIPCFn({
			configDetails,
			formData,
		});
		if (getAccountIdResponse['data']['error']) {
			return null;
		} else {
			return getAccountIdResponse['data']['results']['accountId'];
		}
	} else {
		return {
			data: {
				error: true,
				message:
					'This service is available only for Maya Desktop Application',
			},
		};
	}
};

export default getAccountId;
