import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Button, Tooltip, LightMode, useColorMode, useDisclosure, useToast } from '@chakra-ui/react';
import { connect, useSelector } from 'react-redux';
// API
import APIFetch from '../../util/APIFetch';
import APIRoutes from '../../util/APIRoutes';
import GetProfileSlug from '../../util/ProfileSlug';
import deploySkills from '../../functions/editor/deploy';
import getDashboardData from '../../functions/dashboard/getDashboard';
import getToken from '../../functions/brain/getToken';
// electron
import isElectron from 'is-electron';
// config
import { config } from '../../config';
// Components
import GeneralLoader from '../Reusable/GeneralLoader';
import { MenuDrawer } from './MenuDrawer';
// ScreenCapture
import { takeScreenshot } from '../../util/ScreenCapture';
import { dataURItoBlob } from '../../util/Misc';
import updateBrain from '../../functions/brain/updateBrain';
// Redux Actions
import {
	setDashboardInfo,
	updateBrainById,
	setNavPageMode,
} from '../../redux/actions';
// functions
import startBrain from '../../functions/brain/startBrain';
import getBrainById from '../../functions/brain/getBrainById';
import getUsage from '../../functions/settings/usage/getUsage';
import { getRedFlows } from './RedApiWrappers';
import getMayaJson from '../../functions/skillPack/getMayaJson';
import './style.css';
import './theme.css';
//Analytics
import analytics from '../../util/Analytics';
import redScript from './redScript';
import RedInjector from './RedInjector';
import ExportFlowsModal from './ExportFlowsModal';
import { mayaRedActions, mayaRedCustomEvents } from './config';
import ImportFlowsModal from './ImportFlowsModal';

const bgColor = {
	light: 'white',
	dark: 'maya_dark.300',
};

const Loader = ({ text }) => {
	const { colorMode } = useColorMode();
	return (
		<Box
			display="flex"
			flexDirection="columns"
			height="100vh"
			width="100vw"
			alignItems="center"
			justifyContent="center"
			margin="0 auto"
			bg={bgColor[colorMode]}
		>
			<GeneralLoader text={text} />
		</Box>
	);
};

const CrashAlert = ({ brainId, recovered }) => {
	const [ticker, _setTicker] = useState(3)
	const tickerRef = useRef()

	const [alertText, setAlertText] = useState('Aw snap! This workspace just crashed')
	const [restartInitiated, setRestartInitiated] = useState(false)

	async function getFlows() {
		const RED = window.RED
		const redFlows = getRedFlows(RED, 'full') // full -> get all flows in editor
		const res = await getMayaJson(null, redFlows, brainId)

		return res?.data
	}

	function decreaseTicker() {
		if (!tickerRef.current) {
			tickerRef.current = 3
		}
		tickerRef.current = tickerRef.current - 1
		_setTicker(tickerRef.current-1)
	}

	async function handleRestart() {
		// Don't wanna refresh window if brain hasn't restarted yet
		if (!recovered) {
			return
		}
		
		setRestartInitiated(true)

		setAlertText('Flows backed up to clipboard! Restarting in 3')
		const interval = setInterval(async () => {
			console.log('yeet2', tickerRef.current)
			decreaseTicker()
			setAlertText(`Flows backed to clipboard! Restarting in ${tickerRef.current}`)
			if (tickerRef.current < 1) {
				try {
					const flows = await getFlows()
					navigator.clipboard.writeText(JSON.stringify(flows, null, 2))
					window.location.reload()
					clearInterval(interval)
				} catch (e) {
					console.log('Unable to restart editor')
					setRestartInitiated(false)
				}  
			}
		}, 1_000)
	}

	return (
		<Box
			bg='red.400'
			color='white'
			display='flex'
			borderRadius='5px'
			alignItems='center'
			padding='8px 16px'
		>
			<Box>
				{alertText}
			</Box>
			
			{!restartInitiated ? (
				<Tooltip
					label='All flows will be copied to your clipboard to prevent loss of any undeployed changes'
				>
						<Button
							size='sm'
							ml='12px'
							colorScheme={'blackAlpha'}
							color='#fefefe'
							onClick={handleRestart}
							disabled={!recovered}
							isLoading={!recovered}
						>
							{recovered ? 'Restart' : 'Recovering'}
						</Button>
				</Tooltip>
			) : null}
		</Box>
	)
}

const RedEditor = ({ brainId, runtime, brainStatus }) => {
	const toastRef = React.useRef()
	const toast = useToast()
	const [crashed, setCrashed] = useState(false)

	const {
		isOpen: exportFlowsModalIsOpen,
		onClose: exportFlowsModalOnClose,
		onOpen: exportFlowsModalOnOpen,
	} = useDisclosure();
	const {
		isOpen: importFlowsModalIsOpen,
		onClose: importFlowsModalOnClose,
		onOpen: importFlowsModalOnOpen,
	} = useDisclosure();

	useEffect(() => {
		if (window.RED) {
			setTimeout(() => {
				/**
				 * Using timeout to defer setting the event handler before red-editor runs,
				 * to prevent overwrite by the red-editor.
				 * This can be removed once the `RED.actions.add` for this specific event is
				 * removed from the red-editor source code.
				 */
				window.RED.actions.add(mayaRedActions.showExportDialog, () => {
					exportFlowsModalOnOpen();
				});
				window.RED.actions.add(mayaRedActions.showImportDialog, () => {
					importFlowsModalOnOpen();
				});
			}, 1000);
		}
		return () => {
			if (window.RED) {
				// Cleanup
				window.RED.actions.remove(mayaRedActions.showExportDialog);
				window.RED.actions.remove(mayaRedActions.showImportDialog);
			}
		};
	}, [exportFlowsModalOnOpen, importFlowsModalOnOpen]);

	useEffect(() => {
		if (brainStatus === 'TEMP_CRASH') {
			setCrashed(true)
			toastRef.current = toast({
				position: 'bottom',
				render: () => CrashAlert({ brainId, recovered: false }),
				duration: null
			})
		} else if (brainStatus === 'STARTED') {
			if (crashed) {
				if (toastRef.current) {
					toast.update(toastRef.current, {
						render: () => CrashAlert({ brainId, recovered: true }),
						duration: null
					})
				}
			}
		}
	}, [brainStatus])

	// Remove crash toast if user closes editor
	useEffect(() => {
		return () => toast.closeAll()
	}, [])

	return (
		<>
			<RedInjector selectors="#red-ui-header > ul">
				<li>
					<span>
						<MenuDrawer brainId={brainId} runtime={runtime} />
					</span>
				</li>
			</RedInjector>
			<ExportFlowsModal
				isOpen={exportFlowsModalIsOpen}
				onClose={exportFlowsModalOnClose}
			/>
			<ImportFlowsModal
				isOpen={importFlowsModalIsOpen}
				onClose={importFlowsModalOnClose}
			/>
		</>
	);
};

/**
 * EditorFetch Component
 * @param {*} props
 */

const EditorFetch = ({
	user,
	status,
	setDashboardInfo,
	setNavPageMode,
	device,
	robots,
	updateBrainById,
	deviceId,
	brains,
	tabs,
}) => {
	const { colorMode } = useColorMode();
	const [runtime, setRuntime] = React.useState('');
	const [imgDataUri, setImgDataUri] = React.useState('');
	const [flows, setFlows] = React.useState([]);
	const [brainId, setBrainId] = React.useState('');
	const [token, setToken] = React.useState('');
	const [starting, setStarting] = React.useState(true);
	const [selectedTabs, setSelectedTabs] = React.useState(tabs.selected);
	const [redScriptIsInit, setRedScriptIsInit] = React.useState(false);

	const [deployTicker, _setDeployTicker] = React.useState(-1)
	const deployTickerRef = React.useRef()

	// Jugaad for triggering flow-screenshot effect
	function increaseDeployTicker() {
		if (deployTickerRef.current === undefined) {
			deployTickerRef.current = 0
		} else {
			deployTickerRef.current = deployTickerRef.current + 1
		}

		_setDeployTicker(deployTickerRef.current)
	}


	let { slug } = GetProfileSlug();
	const [{ fetchResults: uploadImageAPI }] = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.uploadFileS3.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.uploadFileS3.PATH_METHOD,
		contentType: 'multipart/form-data',
	});
	const { tier } = useSelector((store) => store.profiles.selected);
	const [disableDeploy, setDisableDeploy] = React.useState(false);

	React.useEffect(() => {
		getUsage().then((res) => {
			// show this when
			if (res.nodes > res.upperLimit && tier === 'FREE') {
				setDisableDeploy(true);
			} else {
				setDisableDeploy(false);
			}
		});
	}, [brains, tier]);

	// TODO : reduce one API call here to make finding onDevice more permanent, state not changing for some reason
	const handleDeploy = (id) => {
		console.log('Handle Deploy');
		let formData = {
			profileSlug: slug,
			brainId: id,
		};

		deploySkills(formData).then((res) => {
			console.log('Updating the brain with res', res);
			updateBrainById(id, res);

			increaseDeployTicker()
			setFlows(res.flows);
		});
	};

	const updateThumbnail = async (image, brainID) => {
		var blob = dataURItoBlob(image);
		var fd = new FormData();
		fd.append('file', blob);

		uploadImageAPI(fd)
			.then((res) => {
				const brain = brains.byId[brainID];
				updateBrain({
					...brain,
					thumbnail: res.data.files[0].location,
				});
			})
			.catch((err) => console.log('updateBrain', err));
	};

	React.useEffect(() => {
		setNavPageMode('editor');
		// Update the document title using the browser API
	}, [setNavPageMode]);

	/**
	 * Offline State Update
	 *
	 * useEffect hook to update results in Redux state for offline state
	 *
	 */
	// React.useEffect(() => {
	//   getDashboardData({ slug }).then(async (res) => {
	//     setDashboardInfo(res);
	//   });
	//   // Update the document title using the browser API
	// }, [setDashboardInfo]);

	/**
	 * Online State Update
	 *
	 * useEffect hook to update results in Redux state for online state
	 */
	React.useEffect(() => {
		if (user.info) {
			analytics.identify(user.info.id);
			analytics.setUserDetails(user.info.email, user.info.id);
		}

		const handleDone = (formData, res, fetchAgain) => {
			// console.log("This is C")
			return new Promise(async function (resolve, reject) {
				let tokens;
				if (isElectron()) {
					tokens = await getToken(formData).then((tokenResponse) => {
						if (!tokenResponse.error) {
							setToken(tokenResponse['results']);
							return tokenResponse['results'];
						} else {
							throw new Error(
								'Could not fetch access token to workspace'
							);
						}
					});
				}

				if (fetchAgain) {
					// console.log("This is D")
					getBrainById(formData).then((response) => {
						console.log('RESPONSE', response);
						if (process.env.NODE_ENV === 'production') {
							console.log('URL : ', response.url);
							setRuntime(res.url);
						} else if (process.env.NODE_ENV === 'development') {
							console.log('URL : ', response.url);
							setRuntime(res.url);
						}
						resolve(tokens);
					});
				} else {
					// console.log("This is E")
					if (process.env.NODE_ENV === 'production') {
						console.log('URL : ', res.url);
						setRuntime(res.url);
					} else if (process.env.NODE_ENV === 'development') {
						console.log('URL : ', res.url);
						setRuntime(res.url);
					}
					resolve(tokens);
				}
			});
		};
		const setOnDeploy = async (id) => {
			let check = setInterval(() => {
				if (document.querySelector('#red-ui-header-button-deploy')) {
					clearInterval(check);
					analytics.track('[Editor] Deploy Button Pressed', {
						brainId: id,
					});
					document
						.querySelector('#red-ui-header-button-deploy')
						.addEventListener('click', function () {
							console.log('deploy');
							handleDeploy(id);
						});
				}
			}, 3000);
		};
		getDashboardData({ slug }).then(async (res) => {
			// console.log("This is B")
			setDashboardInfo(res);
			let search = window.location.search;
			let params = new URLSearchParams(search);
			let id = params.get('id');
			let formData = {
				brainId: id,
				profileSlug: slug,
			};
			setBrainId(id);
			await getBrainById(formData).then(async (res) => {
				if (res.status === 'STARTED') {
					// console.log("Starting is", starting)
					setStarting(false);
					let tokens = await handleDone(formData, res, true);
					setOnDeploy(id);
					await redScript(res.url, tokens);
					setRedScriptIsInit(true);
				} else if (res.status === 'STOPPED') {
					setStarting(true);
					startBrain({
						brain: res,
						slug,
						onDone: async () => {
							getBrainById(formData).then(async (response) => {
								handleDone(formData, response, false).then(() => {
									// window.location.reload();
									setOnDeploy(id);
								});
							});
						},
						OnError: () => {},
						onLoading: () => {},
					});
				} else if (res.status === 'PENDING') {
					setStarting(true);
					if (isElectron()) {
						var electron = window.require('electron');
						var { ipcRenderer } = electron;
						ipcRenderer.removeAllListeners(
							'/brain/status/start/' + res._id
						);
						ipcRenderer.on(
							'/brain/status/start/' + res._id,
							(event, arg) => {
								if (arg === 'STARTED') {
									updateBrainById(res._id, {
										...res,
										status: 'STARTED',
									});
									getBrainById(formData).then(async (response) => {
										handleDone(formData, response, false).then(() => {
											// window.location.reload();
											setOnDeploy(id);
										});
									});
								}
							}
						);
					}
				}
			});
			//fetchData(id);
		});
	}, [slug, tabs.selected]);

	// Take a screenshot and update the brain's thumbnail
	// everytime the flows object changes
	React.useEffect(() => {
		const search = window.location.search;
		const params = new URLSearchParams(search);
		const id = params.get('id');
		
		takeScreenshot()
		.then((res) => {
				updateThumbnail(res, id);
			})
			.catch((err) => {
				console.log(err)
			});
	}, [deployTicker]);

	return (
		<Box
			bg={bgColor[colorMode]}
			height="100%"
			width="100%"
			onClick={(e) => {
				let elem = document.elementFromPoint(e.clientX, e.clientY);
				analytics.track('User Clicked Editor', { element: elem.outerHTML });
			}}
		>
			<Box
				id="red-ui-editor"
				style={isElectron() ? { top: '45px' } : { top: '0px' }}
			/>

			{/* <MenuDrawer brainId={brainId} runtime={runtime} /> */}
			{runtime === '' ? (
				<Loader
					text={starting ? 'Starting workspace...' : 'Loading editor...'}
				/>
			) : null}
			{redScriptIsInit ? (
				<RedEditor brainId={brainId} runtime={runtime} brainStatus={brains.byId[brainId]?.status} />
			) : null}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { status } = state.user;
	const { user } = state;
	const { device } = state.devices.current;
	const { brains } = state;
	const { tabs } = state;
	const deviceId = status === 'online' ? (device ? device._id : null) : null;

	return { status, device, brains, deviceId, user, tabs };
};

export default connect(mapStateToProps, {
	setDashboardInfo,
	updateBrainById,
	setNavPageMode,
})(EditorFetch);
