import React, { useCallback, useState } from 'react';
import { useSpeechRecognition } from 'react-speech-kit';
import AudioIcon from './AudioIcon';

const mocking = true;

const Voice = ({ setInputValue }) => {
	const [mockListening, setMockListening] = useState(false);

	const onStopListening = useCallback(() => {
		// if (searchItems.allIds.length !== 0) {
		// selectMiddleSearchItem();
		// setMiddleSearchItems({ byId: {}, allIds: [] });
		// toggleSearchDisplayMode('collapsed');
		// toggleCardsVisibility(false);
		// setMode('enter');
		// }
	}, []);

	const { listen, listening, stop } = useSpeechRecognition({
		onResult: (result) => {
			setInputValue(result);
		},
		onEnd: () => {
			onStopListening();
		},
	});

	const onStartListening = () => {
		listen();

		setTimeout(() => {
			stop();
			onStopListening();
		}, 5000);
	};

	const onMockStart = () => {
		setMockListening(true);
		const valueArray = [
			'voice',
			'Voice control',
			'Voice control Coming',
			'Voice control coming soon!',
		];

		valueArray.map((input, i) => {
			setTimeout(() => {
				setInputValue(input);
			}, i * 700 + 1000);
		});

		setTimeout(() => {
			setMockListening(false);
		}, 6000);
	};

	return (
		<AudioIcon
			listening={listening || mockListening}
			onStartListening={mocking ? onMockStart : onStartListening}
			onStopListening={stop}
		/>
	);
};

// const mapStateToProps = (state) => {
// 	const { centerSearch } = state.cardUI;
// 	const { selectedItem, loadingStatus, submitProgress } = centerSearch.top;
// 	const { searchItems } = centerSearch.middle;
// 	const { intents } = state.cardUI;
// 	return { selectedItem, intents, loadingStatus, submitProgress, searchItems };
// };

// export default connect(mapStateToProps, {
// 	selectMiddleSearchItem,
// 	setMiddleSearchItems,
// 	toggleSearchDisplayMode,
// 	toggleCardsVisibility,
// })(Voice);

export default Voice;
