import React from 'react';
import {
	PseudoBox,
	Button,
	useDisclosure,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	Tooltip,
	Box,
	LightMode,
	useColorModeValue,
	Progress,
	Stack,
	IconButton,
	Input,
	InputGroup,
	InputLeftElement,
	InputRightElement,
	Spinner,
} from '@chakra-ui/react';
import { ChevronLeftIcon, SearchIcon } from '@chakra-ui/icons';
import {
	FiClock,
	FiShoppingBag,
	FiSidebar,
	FiGitBranch,
	FiCommand,
	FiLayers,
	FiSettings,
	FiDownloadCloud,
	FiHome,
	FiGrid,
} from 'react-icons/fi';
//Components
import MenuPanel from './Panel';
import getUsage from '../../functions/settings/usage/getUsage';
import PaymentsModal from '../Payments';

// electron
import isElectron from 'is-electron';
import History, { HistoryWithSlug } from '../../util/History';

//Analytics
import analytics from '../../util/Analytics';
import CustomDrawer from '../Reusable/ChakraCustom/CustomDrawer';

//Redux
import { useSelector } from 'react-redux';
import NodeSearch from './NodeSearch';

export const MenuDrawer = ({ brainId, runtime }) => {
	const { isOpen, onOpen, onClose } = useDisclosure(false);
	const btnRef = React.useRef();
	const route = useSelector((store) => store.navigation.route);
	const brains = useSelector((store) => store.brains);
	const { tier } = useSelector((store) => store.profiles.selected);
	const {
		isOpen: isPaymentsOpen,
		onClose: onPaymentsClose,
		onOpen: onPaymentsOpen,
	} = useDisclosure();
	const [section, setSection] = React.useState('store');

	React.useEffect(() => {
		getUsage().then((res) => {
			// show this when
			if (res.nodes > res.upperLimit && tier === 'FREE') {
				onPaymentsOpen();
			} else {
				onPaymentsClose();
			}
		});
	}, [brains, route, tier]);

	return (
		<>
			<Box
				width="1rem"
				height={isElectron() ? '1.9rem' : '1.5rem'}
				hidden={isElectron() ? true : false}
				left="0"
				bg="none"
				marginTop="0.5rem"
				zIndex="14"
				position="absolute"
				borderRadius="0"
				_hover={{ border: 'none', bg: '#eaeaea' }}
				onClick={() => {
					HistoryWithSlug.push('/home');
				}}
			>
				<Tooltip label="Back to dashboard" placement="right">
					<ChevronLeftIcon boxSize="1rem" color="gray.700" />
				</Tooltip>
			</Box>
			<Stack
				direction="row"
				spacing="3.5"
				zIndex="14"
				// position="absolute"
				// right="0"
				// marginTop="50px"
				marginRight="2"
			>
				<LightMode>
					<Tooltip label="View Dashboard" placement="bottom">
						<Button
							ref={btnRef}
							height={isElectron() ? '1.85rem' : '1.5rem'}
							colorScheme="purple"
							borderRadius="5px"
							fontFamily="Lato"
							fontWeight="400"
							fontSize={isElectron() ? '0.9rem' : '0.8rem'}
							_focus={{ border: 'none' }}
							onClick={() => {
								onOpen();
								setSection('/dashboard');
								analytics.track('[Editor] Dashboard Button Pressed', {
									brainId: brainId,
								});
							}}
						>
							<FiSidebar />
						</Button>
					</Tooltip>
					<Tooltip label="View Store" placement="bottom">
						<Button
							ref={btnRef}
							height={isElectron() ? '1.85rem' : '1.5rem'}
							colorScheme="purple"
							borderRadius="5px"
							fontFamily="Lato"
							fontWeight="400"
							fontSize={isElectron() ? '0.9rem' : '0.8rem'}
							_focus={{ border: 'none' }}
							onClick={() => {
								onOpen();
								setSection('/store/modules');
								analytics.track('[Editor] Store Button Pressed', {
									brainId: brainId,
								});
							}}
						>
							<FiShoppingBag />
						</Button>
					</Tooltip>
				</LightMode>
				<NodeSearch>
					{({ onOpen }) => (
						<Tooltip label="Search & install nodes" placement="bottom">
							<IconButton
								icon={<SearchIcon />}
								height={isElectron() ? '1.85rem' : '1.5rem'}
								borderRadius="5px"
								fontFamily="body"
								fontWeight="400"
								fontSize={isElectron() ? '0.9rem' : '0.8rem'}
								_focus={{ border: 'none' }}
								onClick={() => {
									onOpen();
									analytics.track('[Editor] Search Button Pressed', {
										brainId: brainId,
									});
								}}
							/>
						</Tooltip>
					)}
				</NodeSearch>
			</Stack>
			<CustomDrawer isOpen={isOpen} placement="bottom" onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent bg="#F8F8F8" width="100vw">
					<MenuPanel
						closeFn={onClose}
						brainId={brainId}
						runtime={runtime}
						section={section}
					/>
				</DrawerContent>
			</CustomDrawer>

			<PaymentsModal
				isOpen={isPaymentsOpen}
				onClose={() => {
					// History.push(pathname);
					onPaymentsClose();
				}}
			/>
		</>
	);
};

//
