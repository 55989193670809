import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import deploySkills from '../editor/deploy';

const installSkillPackToBrain = async (formData) => {
	var installSkillPackToBrainAPIFn,
		installSkillPackToBrainIPCFn,
		installSkillPackToBrainResponse,
		getSkillPackByIdFn,
		getSkillPackByIdResponse;
	if (!isElectron()) {
		installSkillPackToBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.installSkillPackToBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.installSkillPackToBrain.PATH_METHOD,
		});
		installSkillPackToBrainResponse = await installSkillPackToBrainAPIFn();
		if (installSkillPackToBrainResponse['data']['error']) {
			return installSkillPackToBrainResponse['data'];
		} else {
			return installSkillPackToBrainResponse['data']['results'];
		}
	} else {
		installSkillPackToBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.installSkillPackToBrain.EVENT,
		});
		installSkillPackToBrainResponse = await installSkillPackToBrainIPCFn({
			...formData,
		});
		if (installSkillPackToBrainResponse['data']['error']) {
			return installSkillPackToBrainResponse['data'];
		}

		console.log(
			'installSkillPackToBrainResponse 1',
			installSkillPackToBrainResponse
		);

		installSkillPackToBrainResponse['data']['brain'] = await deploySkills({
			profileSlug: formData.profileSlug,
			brainId: formData.brainId,
		});

		console.log(
			'installSkillPackToBrainResponse 2',
			installSkillPackToBrainResponse
		);

		return installSkillPackToBrainResponse['data'];
	}
};

export default installSkillPackToBrain;
