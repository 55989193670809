import React from 'react';
import {
	Box,
	useDisclosure,
	useColorModeValue,
	Modal,
	ModalContent,
	ModalCloseButton,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button,
	FormControl,
	FormLabel,
	Input,
	Alert,
	AlertIcon,
	Textarea,
	useToast,
	IconButton,
	Icon,
	Tooltip,
} from '@chakra-ui/react';
import { SmallCloseIcon, CheckCircleIcon, InfoIcon } from '@chakra-ui/icons';

import newDeveloperRequest from '../../../functions/developer/newDeveloperRequest';
import deleteDeveloperRequest from '../../../functions/developer/deleteDeveloperRequest';
import CustomModal from '../../Reusable/ChakraCustom/CustomModal';

export const DeveloperAccountButton = ({ onSubmit }) => {
	const [formData, setFormData] = React.useState({});
	const [isLoading, setLoading] = React.useState(false);
	const toast = useToast();
	const { isOpen, onOpen, onClose } = useDisclosure(false);

	const handleInputChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};
	const handleSubmit = () => {
		setLoading(true);
		newDeveloperRequest(formData)
			.then((res) => {
				console.log(res);
				onSubmit(res.data.developer);
				toast({
					title: 'Success!',
					//description: res.data.message,
					status: 'success',
					duration: 3000,
					isClosable: false,
				});
				setLoading(false);
				onClose();
			})
			.catch((e) => {
				console.log('error:', e);
				toast({
					title: 'Error Occurred.',
					//description: e.data.message,
					status: 'error',
					duration: 3000,
					isClosable: false,
				});
				setLoading(false);
			});
	};
	return (
		<>
			<Box
				fontFamily="body"
				mt="2"
				ml="2"
				fontSize="0.8rem"
				color="purple.200"
				_hover={{ textDecoration: 'underline' }}
				cursor="pointer"
				onClick={onOpen}
			>
				+ Request developer account
			</Box>
			<CustomModal
				onClose={onClose}
				isOpen={isOpen}
				maxWidth="50rem"
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<ModalContent
					zIndex="4000"
					maxWidth="35rem"
					height="25rem"
					borderRadius="0.5rem"
					bg={useColorModeValue('white', 'maya_dark.300')}
				>
					<ModalHeader pb="2" fontFamily="body">
						Request Developer Account
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody height="80%" paddingY="none">
						<FormControl isRequired width="100%">
							<FormLabel htmlFor="aname">Account Name</FormLabel>
							<Input
								id="aname"
								name="name"
								focusBorderColor="purple.400"
								placeholder="E.g. Jarvis Technologies Pvt. Ltd."
								minWidth="90%"
								onChange={handleInputChange}
							/>
						</FormControl>
						<FormControl isRequired mt="4">
							<FormLabel htmlFor="aname">
								What would you like to add to the Maya Store?
							</FormLabel>
							<Textarea
								focusBorderColor="purple.400"
								name="description"
								placeholder="Do list what kind of skills you'd like to share on the Maya store."
								size="sm"
								width="93%"
								onChange={handleInputChange}
								boxShadow="rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px"
							/>
						</FormControl>
						<Alert status="info" fontSize="0.9rem" width="93%" mt="3">
							<AlertIcon />
							We're still processing requests manually, if you'd like to
							fast-forward the process, ping us on community.mayahq.com.
						</Alert>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={handleSubmit}
							variantColor="purple"
							mr={3}
							isLoading={isLoading}
							loadingText="Submitting..."
						>
							Send Request
						</Button>
						<Button variantColor="gray" onClick={onClose}>
							Close
						</Button>
					</ModalFooter>
				</ModalContent>
			</CustomModal>
		</>
	);
};

/**
 * DeveloperAccountTabs
 * @param {*} param0
 */

export const DeveloperAccountTabs = ({
	list,
	onDelete,
	heading,
	deleteButton,
	devAccountId,
	setDevAccountId,
}) => {
	console.log('developer list', list);
	const [deleteLoadingID, setDeleteLoadingID] = React.useState('');
	const toast = useToast();

	const handleDelete = (accountID) => {
		setDeleteLoadingID(accountID);
		onDelete(accountID);
		deleteDeveloperRequest({ developerId: accountID })
			.then((res) => {
				setDeleteLoadingID('');
			})
			.catch((e) => {
				console.log('error:', e);
				toast({
					title: 'Error Occurred.',
					description: e.data.message,
					status: 'error',
					duration: 3000,
					isClosable: false,
				});
			});
	};
	return (
		<>
			{heading ? (
				<Box fontFamily="body" fontSize="0.8rem" mb={2} color="gray.600">
					Developer Accounts
				</Box>
			) : null}
			{list.length > 0
				? list.map((item, i) => {
						return (
							<Box
								pl={3}
								pt={'0.25rem'}
								pb={'0.25rem'}
								key={i}
								mb={1}
								shadow="md"
								borderWidth="1px"
								width="20rem"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								borderRadius="5px"
								cursor="pointer"
								onClick={() => {
									if (setDevAccountId) setDevAccountId(item._id);
								}}
								transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
								borderColor={
									item._id === devAccountId ? 'purple.300' : 'none'
								}
							>
								<Box>
									<Box
										fontSize="18px"
										fontWeight="600"
										display="flex"
										alignItems="center"
									>
										{item.name}
										{item.approved ? (
											<CheckCircleIcon
												boxSize="15px"
												ml={1}
												color="green.500"
											/>
										) : (
											<InfoIcon
												boxSize="15px"
												ml={1}
												color="orange.500"
											/>
										)}
									</Box>

									<Box
										fontSize="15px"
										display="flex"
										alignItems="center"
									>
										{item.approved
											? 'Verified Account'
											: 'Pending Approval'}
									</Box>
								</Box>
								<Box>
									{item.approved && deleteButton ? (
										<Tooltip
											label="Cancel Request"
											placement="bottom"
										>
											<IconButton
												variantColor="gray"
												aria-label="Search database"
												icon={<SmallCloseIcon />}
												size="xs"
												mr="2"
												isLoading={item._id === deleteLoadingID}
												onClick={() => handleDelete(item._id)}
											/>
										</Tooltip>
									) : null}
								</Box>
							</Box>
						);
				  })
				: null}
		</>
	);
};
