import React from 'react';

import {
	useDisclosure,
	useToast,
	AlertDialog,
	AlertDialogBody,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogContent,
	AlertDialogOverlay,
	Button,
	useColorModeValue,
	LightMode,
	Box,
	Checkbox,
} from '@chakra-ui/react';

import isElectron from 'is-electron';
import {
	FiPlusCircle,
	FiGitBranch,
	FiTrash2,
	FiMinusCircle,
	FiCommand,
	FiCopy,
} from 'react-icons/fi';
import _ from 'lodash';

//Components
import { HorizontalItemButton } from '../Reusable/HorizontalItem';

import SkillPackInstallModal from './SkillPackInstallModal';

//Functions
import getBrainById from '../../functions/brain/getBrainById';
import installSkillPackToBrain from '../../functions/skillPack/installSkillPackToBrain';
import uninstallSkillPackFromBrain from '../../functions/skillPack/uninstallSkillPackFromBrain';
import deleteLibrarySkillPack from '../../functions/library/deleteLibrarySkillPack';
import copyLibrarySkillPack from '../../functions/library/copySkillPackToLibrary';
// Components
import GetProfileSlug from '../../util/ProfileSlug';

//Analytics
import analytics from '../../util/Analytics';
import { useSelector } from 'react-redux';

export const SkillsButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiCommand />}
			buttonKey="skills"
			text={'skills'}
			onClick={onClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};

export const DeleteButton = ({
	pack,
	setHoveredButton,
	hoveredButton,
	removeBrain,
	currentBrainId,
	packs,
	setPacks,
	brains,
	updateBrainById,
}) => {
	const [isDeleting, setIsDeleting] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();

	const handleDelete = () => {
		setIsDeleting(true);
		var formData = {
			skillPack: pack,
			profileSlug: slug,
		};
		deleteLibrarySkillPack(formData)
			.then((res) => {
				console.log(res);
				if (res.data.error) {
					setIsDeleting(false);
					toast({
						title: 'Error!',
						description: 'The selected collection could not be deleted.',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				} else {
					setIsDeleting(false);
					toast({
						title: 'Deleted!',
						description: 'The selected collection has been deleted.',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					let temp = _.filter(packs, (p) => {
						return p._id.toString() !== pack._id;
					});
					setPacks(temp);
					console.log(packs);
				}
				onClose();
			})
			.catch((err) => {
				setIsDeleting(false);
				console.log(err);
				toast({
					title: 'Error!',
					description: 'The selected collection could not be deleted.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			{/* <HorizontalItemButton
				icon={<FiTrash2 />}
				buttonKey="delete"
				text={'delete'}
				onClick={() => {
					setIsOpen(true);
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/> */}
			<Box
				onClick={() => {
					setIsOpen(true);
				}}
				display="flex"
				flexDirection="row"
				width="100%"
			>
				<Box paddingTop="3.5px">
					<FiTrash2 />
				</Box>
				<Box marginLeft="0.5rem">Delete</Box>
			</Box>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Delete Collection
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={handleDelete}
									ml={3}
									isLoading={isDeleting}
									loadingText={'Deleting'}
								>
									Delete
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export const CopyToButton = ({
	pack,
	setHoveredButton,
	hoveredButton,
	removeBrain,
	currentBrainId,
	packs,
	setPacks,
	brains,
	updateBrainById,
}) => {
	const [isCopying, setIsCopying] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();
	const [toLibrary, setToLibrary] = React.useState('');
	const teamProfiles = useSelector((state) => {
		return state.profiles.teams;
	});
	const userProfile = useSelector((state) => {
		return state.profiles.user;
	});

	const profileList = React.useMemo(() => {
		return [...teamProfiles, userProfile];
	}, [userProfile, teamProfiles]);

	const handleCopy = () => {
		setIsCopying(true);
		const formData = {
			skillPackId: pack._id.toString(),
			fromProfileSlug: slug,
			toProfileSlug: toLibrary,
		};

		copyLibrarySkillPack(formData)
			.then((res) => {
				console.log(res);
				if (res.data.error) {
					setIsCopying(false);
					toast({
						title: 'Error!',
						description: 'The selected collection could not be copied.',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
				} else {
					setIsCopying(false);
					toast({
						title: 'Copied!',
						description: 'The selected collection has been copied.',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				}
				onClose();
			})
			.catch((err) => {
				setIsCopying(false);
				toast({
					title: 'Error!',
					description: 'The selected collection could not be copied.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			{/* <HorizontalItemButton
				icon={<FiTrash2 />}
				buttonKey="delete"
				text={'delete'}
				onClick={() => {
					setIsOpen(true);
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/> */}
			{profileList.length > 0 ? (
				<Box
					onClick={() => {
						setIsOpen(true);
					}}
					display="flex"
					flexDirection="row"
					width="100%"
				>
					<Box paddingTop="3.5px">
						<FiCopy />
					</Box>
					<Box marginLeft="0.5rem">Copy To</Box>
				</Box>
			) : null}
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Copy Collection To
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							<Box marginBottom="10px">
								Select which library to copy this to:{' '}
							</Box>
							{profileList.map((profile) => {
								return profile.profileSlug !== slug ? (
									<Box display="flex">
										<Checkbox
											onChange={(e) => {
												if (e.currentTarget.checked) {
													setToLibrary(profile.profileSlug);
												}
											}}
										/>
										<Box marginLeft="10px">{profile.name}</Box>
									</Box>
								) : null;
							})}
						</AlertDialogBody>
						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="green"
									onClick={handleCopy}
									ml={3}
									isLoading={isCopying}
									loadingText={'Copying...'}
									isDisabled={toLibrary === ''}
								>
									Continue
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};
/**
 * Set uninstall button
 * @param {*} param0
 */

export const UninstallButton = ({
	setHoveredButton,
	hoveredButton,
	pack,
	currentBrainId,
	updateBrainById,
}) => {
	const [isUninstalling, setIsUninstalling] = React.useState(false);
	const [isOpen, setIsOpen] = React.useState(false);
	const toast = useToast();
	const onClose = () => setIsOpen(false);
	const cancelRef = React.useRef();
	const { slug } = GetProfileSlug();
	const handleUninstall = () => {
		setIsUninstalling(true);
		var formData = {
			SkillPackToUninstall: pack,
			brainId: currentBrainId,
			profileSlug: slug,
		};

		uninstallSkillPackFromBrain(formData)
			.then((res) => {
				if (res['error']) {
					setIsUninstalling(false);
					toast({
						title: 'Error!',
						description: 'This collection could not be uninstalled.',
						status: 'error',
						duration: 3000,
						isClosable: true,
					});
					analytics.track('[Library] collection Uninstall', {
						skillPack: pack._id,
						brainId: currentBrainId,
						status: 'failure',
						error: res['error'],
					});
				} else {
					setIsUninstalling(false);
					toast({
						title: 'Uninstalled!',
						description: 'This collection was uninstalled.',
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
					console.log(res);
					updateBrainById(currentBrainId, res.brain);
					analytics.track('[Library] Collection Uninstall', {
						skillPack: pack._id,
						brainId: currentBrainId,
						status: 'success',
						error: !res['error'],
					});
				}
				onClose();
			})
			.catch((err) => {
				setIsUninstalling(false);
				toast({
					title: 'Error!',
					description: 'This collection could not be uninstalled.',
					status: 'error',
					duration: 3000,
					isClosable: true,
				});
			});
	};

	return (
		<>
			<HorizontalItemButton
				icon={<FiMinusCircle />}
				buttonKey="uninstall"
				text={'uninstall'}
				onClick={() => {
					setIsOpen(true);
					//analytics.track("[Library] Collection Item Uninstall Button", {});
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Uninstall Collection
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							Are you sure? You can't undo this action afterwards.
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={handleUninstall}
									ml={3}
									isLoading={isUninstalling}
									loadingText={'Uninstalling...'}
								>
									Delete
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

export const UpdateButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiMinusCircle />}
			buttonKey="uninstall"
			text={'uninstall'}
			onClick={onClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
		/>
	);
};

/**
 * Install Button
 * @param {} param0
 */
export const InstallButton = ({
	setHoveredButton,
	hoveredButton,
	onClick,
	pack,
	packId,
	currentBrainId,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [brainId, setBrainId] = React.useState();

	// needs to be changed whenever used next

	const [moduleList, setModuleList] = React.useState(() => {
		let mods = {};
		for (let mod of pack.currentVersionDetails.flow.modules) {
			mods[mod.module._id] = {
				installed: false,
				referenceId: null,
			};
		}
		return mods;
	});

	React.useEffect(() => {
		if (currentBrainId) {
			setBrainId(currentBrainId);
		}

		console.log('first time', moduleList, currentBrainId);
	}, []);

	React.useEffect(() => {
		if (currentBrainId) {
			setBrainId(currentBrainId);
		}
		const func = async () => {
			const brain = await getBrainById({ brainId, slug: '' });
			console.log(brain);
			let list = moduleList;
			for (let o of brain.modules) {
				if (o.module in moduleList) {
					list[o.module] = {
						installed: true,
						referenceId: o.referenceId ? o.referenceId : null,
					};
				}
			}
			setModuleList(list);
			console.log('recurrence', moduleList);
		};
		if (brainId) {
			func();
		}
	}, [brainId]);

	return (
		<>
			<HorizontalItemButton
				icon={<FiPlusCircle />}
				buttonKey="install"
				text={'install'}
				onClick={() => {
					//onClick();
					onOpen();
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<SkillPackInstallModal
				onClose={onClose}
				isOpen={isOpen}
				currentBrainId={currentBrainId}
				pack={pack}
			/>
		</>
	);
};
