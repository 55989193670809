import React from 'react';
import {
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useColorModeValue,
} from '@chakra-ui/react';

import { DoubleChevron } from '../../assets/icons';

//Components

const SelectItem = ({ name }) => {
	return (
		<Box
			height="1.5rem"
			width="100%"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
		>
			<Box display="flex" alignItems="center">
				<Box
					fontSize="0.8rem"
					fontWeight="600"
					color={useColorModeValue('#444444', 'white')}
					marginLeft="1rem"
					fontFamily="body"
				>
					{name}
				</Box>
			</Box>
		</Box>
	);
};

/**
 * SelectDropdown
 * @param {Array} items : provide an array of items in the form [{label: ..., value: ...}]
 * @param {Function} onChange : provide a function that item.value will be passed on to and run
 */

const SelectDropdown = (props) => {
	const items = props.items;

	const [selectedItem, selectItem] = React.useState(items[0]);
	return (
		<Box {...props}>
			<Menu
				border="solid 1px"
				borderColor={useColorModeValue(
					'dashboard.border_light',
					'dashboard.border_dark'
				)}
				display="flex"
			>
				<MenuButton
					as={Box}
					height="5vh"
					display="flex"
					alignItems="center"
					border="solid 1px"
					borderRadius="5px"
					borderColor={useColorModeValue('#f2f2f2', '#494949')}
					_hover={{
						bg: useColorModeValue('#f9f9f9', '#464646'),
					}}
					cursor="pointer"
					transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
					boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
				>
					<Box
						alignItems="center"
						justifyContent="space-between"
						display="flex"
					>
						<Box
							fontFamily="body"
							color={useColorModeValue('maya_dark.500', 'white')}
							fontSize="0.8rem"
							ml="2"
						>
							{selectedItem.label}
						</Box>

						<Box mr="2">
							<DoubleChevron />
						</Box>
					</Box>
				</MenuButton>
				<MenuList
					bg={useColorModeValue('white', 'maya_dark.300')}
					boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
				>
					{items.map((item) => {
						return (
							<MenuItem
								minH="48px"
								padding="0"
								key={item.label}
								transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
								onClick={() => {
									selectItem(item);
									props.selectitem(item);
								}}
							>
								<SelectItem name={item.label} />
							</MenuItem>
						);
					})}
				</MenuList>
			</Menu>
		</Box>
	);
};

export default SelectDropdown;
