import fuzzysort from 'fuzzysort';
import { useSelector } from 'react-redux';
import useSmartSuggest from './useSmartSuggest';

const useFuzzySort = () => {
	const { intents, components, cards } = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities
	);
	const commandHistoryLogs = useSelector(
		/**
		 * @param {{commandHistory: import('../../../../../../redux/reducers/types/commandHistoryReducer').CommandHistoryState}} state
		 */
		(state) => state.commandHistory.log
	);

	const { getSmartSuggestIntents } = useSmartSuggest();

	/**
	 * Gets sorted list of intent Items by input Value given
	 * @param {string} inputValue
	 */
	const getFuzzySortedIntents = (inputValue) => {
		/**
		 * @type {import('../../../../../../redux/reducers/cardUIV2').default['commandBar']['suggestionsArea']['searchItems']}
		 */
		let sortedItems = {
			byId: {},
			allIds: [],
		};
		try {
			if (inputValue) {
				let searchableIntents = intents.allIds
					.filter(
						/** Get only active(started) brain's intents */
						(intentId) => intents.byId[intentId].brainStatus === 'STARTED'
					)
					.map((intentId) => {
						const intentLabel = intents.byId[intentId].components
							.map((componentId) => components.byId[componentId].value)
							.join(' ');
						/**
						 * Maximum number of recent logs from command history
						 * to be used for recommendation bias
						 */
						const MAX_RECENT_LOGS_FOR_RECOMMENDATION = 100;
						const allIntentLogs = commandHistoryLogs
							.slice(-MAX_RECENT_LOGS_FOR_RECOMMENDATION)
							.filter(
								(logObj) =>
									logObj.intentId === intentId &&
									logObj.status === 'start'
							);
						return {
							label: intentLabel,
							id: intentId,
							hits: allIntentLogs.length ? allIntentLogs.length : 0,
						};
					});
				const results = fuzzysort.go(inputValue, searchableIntents, {
					allowTypo: true,
					key: 'label',
				});
				const weightedResults = results.map((res) => {
					const { hits } = res.obj;
					const hitsWeight = 5;
					const weightedScore =
						res.score + hitsWeight * Math.log10(hits ? hits + 1 : 1);
					return { ...res, weightedScore };
				});
				const sortedResults = weightedResults.sort(
					(prev, next) => next.weightedScore - prev.weightedScore // Descending order
				);

				const selectedIntentIds = sortedResults.map((intent) => {
					return intent.obj.id;
				});

				selectedIntentIds.forEach((intentId) => {
					sortedItems.byId[intentId] = intents.byId[intentId];
					sortedItems.allIds.push(intentId);
				});
			} else {
				sortedItems = getSmartSuggestIntents();
			}
		} catch (error) {
			console.error(error);
		}
		return sortedItems;
	};

	/**
	 * Gets sorted list of card Items by input Value given
	 * @param {string} inputValue
	 */
	const getFuzzySortedCards = (inputValue) => {
		/**
		 * @type {import('../../../../../../redux/reducers/cardUIV2').default['commandBar']['suggestionsArea']['searchItems']}
		 */
		let sortedItems = {
			byId: {},
			allIds: [],
		};
		try {
			if (inputValue) {
				let searchableCards = cards.allIds.map((cardId) => {
					const cardLabel = cards.byId[cardId].topText;
					const url = cards.byId[cardId].url;
					return {
						label: cardLabel,
						id: cardId,
						url,
					};
				});
				const results = fuzzysort.go(inputValue, searchableCards, {
					allowTypo: true,
					keys: ['label', 'url'],
				});
				const selectedCardIds = results.map((card) => {
					return card.obj.id;
				});
				selectedCardIds.forEach((cardId) => {
					sortedItems.byId[cardId] = cards.byId[cardId];
					sortedItems.allIds.push(cardId);
				});
			} else {
				// return all cards if i/p value is empty
				cards.allIds.forEach((cardId) => {
					sortedItems.byId[cardId] = cards.byId[cardId];
					sortedItems.allIds.push(cardId);
				});
			}
		} catch (error) {
			console.error(error);
		}
		return sortedItems;
	};

	return { getFuzzySortedIntents, getFuzzySortedCards };
};

export default useFuzzySort;
