import React, { useContext, useMemo } from 'react';
import { Box, Image, useColorMode } from '@chakra-ui/react';
import mainInstructionsDark from '../../../../../../assets/cardui/embedded_view_instructions_dark.svg';
import { useDispatch, useSelector } from 'react-redux';
import InstructionSection from '../../../../CardUI/Search/ExpandedSection/InstructionSection';
import WebCard from './WebCard';
import {
	cardMaximize,
	cardNavigate,
	suggestionsTabClick,
} from '../../../../../../redux/actions/cardUIV2';
import TabBar from '../TabBar';
import CommandBarContext from '../../CommandBarContext';

const EmbeddedView = () => {
	const { currentInputRef } = useContext(CommandBarContext);
	const { colorMode } = useColorMode();
	const dispatch = useDispatch();
	const brains = useSelector((state) => state.brains);
	const {
		type: currentTab,
		selectedSearchItem: selectedSearchItemId,
		vibrateInstructions,
	} = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea
	);
	const allCards = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities.cards
	);
	const selectedCardItem = useMemo(
		() =>
			allCards.allIds.length > 0 && selectedSearchItemId
				? allCards.byId[selectedSearchItemId]
				: null,
		[allCards.allIds.length, allCards.byId, selectedSearchItemId]
	);
	const botDashUrl = useMemo(() => {
		if (
			selectedCardItem &&
			selectedCardItem.mode === 'tab' &&
			selectedCardItem.brainId
		) {
			const hostname = brains.byId[selectedCardItem.brainId].url;
			const newUrl = selectedCardItem.url.includes('?')
				? hostname +
				  selectedCardItem.url +
				  '&mode=' +
				  colorMode +
				  '&theme=angular' +
				  '&tab=' +
				  selectedCardItem.tabId
				: hostname +
				  selectedCardItem.url +
				  '?mode=' +
				  colorMode +
				  '&theme=angular' +
				  '&tab=' +
				  selectedCardItem.tabId;
			return newUrl;
		} else return '';
	}, [brains.byId, colorMode, selectedCardItem]);

	return (
		<Box
			onMouseDown={(e) => {
				e.preventDefault();
				if (currentInputRef.current) currentInputRef.current.focus();
			}}
		>
			<TabBar
				currentTab={currentTab}
				setCurrentTab={(tab) =>
					dispatch(suggestionsTabClick({ tabName: tab }))
				}
			/>
			<Box display="flex">
				<Box width="100%">
					{selectedCardItem && selectedCardItem.mode === 'web' ? (
						<WebCard
							url={selectedCardItem.url}
							isMaximized={false}
							onMaximize={() => dispatch(cardMaximize())}
							onWebviewDidNavigate={(url) =>
								dispatch(
									cardNavigate({ cardId: selectedCardItem.id, url })
								)
							}
						/>
					) : null}
					{selectedCardItem &&
					selectedCardItem.mode === 'tab' &&
					botDashUrl ? (
						<WebCard
							url={botDashUrl}
							isMaximized={false}
							onMaximize={() => dispatch(cardMaximize())}
						/>
					) : null}
				</Box>
			</Box>
			<InstructionSection
				vibrate={vibrateInstructions}
				children={
					<Image src={mainInstructionsDark} height="1.2rem" mr={4} />
				}
			/>
		</Box>
	);
};

export default EmbeddedView;
