import React from 'react';
import { Box, useColorMode, useColorModeValue } from '@chakra-ui/react';
import isElectron from 'is-electron';
import { useLocation } from 'react-router-dom';

//config
import { config } from '../../config';
import History from '../../util/History';
import { HistoryWithSlug } from '../../util/History';

//Components
import SidebarMast from './SidebarMast';
import SidebarLink from './SidebarLink';
import ProfileButton from './ProfileButton/Profile';

//Redux
import { connect, useSelector } from 'react-redux';
import { setNavRoute } from '../../redux/actions';

//Utils
import GetProfileSlug from '../../util/ProfileSlug';

const Sidebar = ({ mode, setNavRoute, route, pageMode, selectedProfile }) => {
	const [pathName, setPath] = React.useState('/home');

	let location = useLocation();

	React.useEffect(() => {
		setPath(route);
	}, [route]);
	const { slug } = GetProfileSlug();

	React.useEffect(() => {
		setPath(location.pathname);
		if (pageMode === 'dashboard') {
			setNavRoute(location.pathname);
		}
	}, [location, setNavRoute, pageMode]);

	const navigateToRoute = (route, includeSlug) => {
		if (mode === 'menu') {
			setNavRoute(route);
		} else {
			console.log(slug);
			HistoryWithSlug.push(route);
		}
	};
	const redDashboardShow = React.useMemo(() => {
		return true;
	}, [pageMode]);

	return (
		<Box
			width="225px"
			height="100%"
			display="flex"
			flexDirection="column"
			flexGrow="0"
			flexShrink="0"
			borderRight="solid 1px"
			borderColor={useColorModeValue('#f2f2f2', 'dashboard.border_dark')}
			justifyContent="space-between"
			position="absolute"
		>
			<Box display="flex" flexDirection="column">
				<SidebarMast />
				<SidebarLink
					type="home"
					text={'Home'}
					selected={pathName.includes('/home')}
					onClick={() => {
						navigateToRoute('/home');
					}}
				/>
				{config.settings.storeEnabled ? (
					<SidebarLink
						type="store"
						text={'Store'}
						selected={pathName.includes('/store')}
						onClick={() => {
							navigateToRoute('/store/skills');
						}}
					/>
				) : null}
				<SidebarLink
					type="brains"
					text={'Workspaces'}
					selected={
						pathName.includes('/skills') &&
						!pathName.includes('store/skills')
					}
					onClick={() => {
						navigateToRoute('/skills');
					}}
				/>
				{/* <SidebarLink
					type="library"
					text={'Library'}
					selected={pathName.includes('/library')}
					onClick={() => {
						navigateToRoute('/library');
						// setPath("/library");
					}}
				/> */}
				<SidebarLink
					type="settings"
					text={'Settings'}
					selected={pathName.includes('/settings')}
					onClick={() => {
						// setPath("/settings");
						navigateToRoute('/settings');
					}}
				/>
				{pageMode === 'editor' ? (
					<SidebarLink
						type="dashboard"
						text={'Dashboard'}
						selected={pathName.includes('/dashboard')}
						onClick={() => {
							//setPath("/updates");
							navigateToRoute('/dashboard');
						}}
					/>
				) : (
					<Box />
				)}
				{pageMode === 'dashboard' && redDashboardShow ? (
					<SidebarLink
						type="updates"
						text={'Updates'}
						selected={pathName.includes('/updates')}
						onClick={() => {
							//setPath("/updates");
							navigateToRoute('/updates');
						}}
					/>
				) : (
					<Box />
				)}
			</Box>
			<ProfileButton />
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { navigation, profiles } = state;
	const { selected } = profiles;
	const { route, pageMode } = navigation;
	return { route, pageMode, selectedProfile: selected };
};

export default connect(mapStateToProps, { setNavRoute })(Sidebar);
