import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import isElectron from 'is-electron';
//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Brain, given some parameters
 */
const startInterruptedBrains = async (slug) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;
	console.log('LOGGING STARTUP VALUE OF PROFILE SLUG', profileSlug);
	var startInterruptedBrainsAPIFn,
		startInterruptedBrainsIPCFn,
		startInterruptedBrainsResponse;

	if (!isElectron()) {
		startInterruptedBrainsAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.startInterruptedBrains.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.startInterruptedBrains.PATH_METHOD,
		});
		startInterruptedBrainsResponse = await startInterruptedBrainsAPIFn(
			profileSlug
		);
		return startInterruptedBrainsResponse['data'];
	} else {
		startInterruptedBrainsIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.startInterruptedBrains.EVENT,
		});
		startInterruptedBrainsResponse = await startInterruptedBrainsIPCFn(
			profileSlug
		);
		return startInterruptedBrainsResponse['data'];
	}
};

export default startInterruptedBrains;
