// redux
import { store } from '../../../redux/store';

/**
 * get user's usage based on how many nodes have been used
 */
// filter out all comment, tab, random nodes etc
import { getNodesCount } from '../../../util/Nodes';

const getUsage = async () => {
	const { brains } = store.getState();
	var totalCount = 0;
	for (let index = 0; index < brains.allIds.length; index++) {
		let nodeCount = getNodesCount(brains.byId[brains.allIds[index]].flows);
		if (!brains.byId[brains.allIds[index]].default) {
			totalCount += nodeCount;
		}
	}
	// No node limits for now
	const upperLimit = process.env.NODE_ENV === 'production' ? 1000000 : 1000000;
	return {
		nodes: totalCount,
		upperLimit,
		percentage: Math.round((totalCount / upperLimit) * 100),
	};
};

export default getUsage;
