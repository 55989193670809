import React from 'react';
import { useSelector } from 'react-redux';
import NormalSearch from './NormalSearch';
import PlaceholderSearch from './PlaceholderSearch';
import { Box } from '@chakra-ui/react';
import EmbeddedView from './EmbeddedView';

const SuggestionsArea = () => {
	// New State
	const suggestionsAreaMode = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea.mode
	);

	return (
		<Box>
			{suggestionsAreaMode === 'normal' ? <NormalSearch /> : null}
			{suggestionsAreaMode === 'placeholder' ? <PlaceholderSearch /> : null}
			{suggestionsAreaMode === 'embeddedView' ? <EmbeddedView /> : null}
		</Box>
	);
};

export default SuggestionsArea;
