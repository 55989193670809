import {
	Box,
	Text,
	Flex,
	Image,
	Stack,
	Tag,
	TagLeftIcon,
	TagLabel,
} from '@chakra-ui/react';
import startCase from 'lodash/startCase';
import { FiPackage } from 'react-icons/fi';

/**
 * Node Item reusable component
 * @param {{
 * 	title: string,
 * 	description: string,
 * 	icon?: string,
 * 	tags?: string[],
 * 	isHighlighted: boolean,
 * 	boxProps: import('@chakra-ui/layout').BoxProps,
 * }} param0
 * @returns
 */
const NodeItem = ({
	title,
	description,
	icon,
	tags,
	isHighlighted,
	boxProps,
}) => {
	return (
		<Box
			bg="#383737"
			paddingY="0.7rem"
			paddingX="1rem"
			marginBottom="0.4rem"
			borderRadius="5px"
			borderLeft="solid 1px #805ad5"
			borderRight="solid 1px #383737"
			borderTop="solid 1px #383737"
			borderBottom="solid 1px #383737"
			border={isHighlighted ? 'solid 1px #805ad5' : undefined}
			_hover={{ border: 'solid 1px #805ad5' }}
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			minWidth="10rem"
			display="flex"
			{...boxProps}
		>
			{icon ? (
				<Image src={icon} ml="-0.5rem" minWidth="3rem" ignoreFallback />
			) : null}
			<Flex direction="column">
				<Box borderRadius="20px" fontSize="13px" fontWeight="700">
					{startCase(title)}
				</Box>
				<Text
					fontSize="11px"
					opacity="0.7"
					noOfLines={2}
					maxWidth="11rem"
					m="unset !important"
				>
					{description}
				</Text>
				{tags ? (
					<Stack direction="row" spacing="1" mt="1">
						{tags.map((tag) => (
							<Tag
								key={`tg-${tag}`}
								size="sm"
								variant="subtle"
								borderRadius="xl"
								fontSize="0.6rem"
							>
								<TagLeftIcon boxSize="10px" as={FiPackage} mr="1" />
								<TagLabel>{tag}</TagLabel>
							</Tag>
						))}
					</Stack>
				) : null}
			</Flex>
		</Box>
	);
};

export default NodeItem;
