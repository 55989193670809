import React, { useContext, useEffect, useState } from 'react';
import { Box, useToast, Code, Kbd } from '@chakra-ui/react';
import IPCFetch from '../../../util/IPCFetch';
import SetupWizardContext from '../SetupWizardContext';
import ActionFooter from '../ActionFooter';
import registerDevice from '../../../functions/devices/registerDevice';
import isElectron from 'is-electron';
import { setupWizardStages } from '../Config';
import StorePackListSW from './StorePackListSW';
import { FiCommand } from 'react-icons/fi';
import analytics from '../../../util/Analytics';
import AnalyticsContext from '../../../util/Analytics/AnalyticsContext';
import VideoAnimationLoop from '../../Reusable/VideoAnimation';
import toggleSetupStatus from '../../../functions/setup/toggleSetupStatus';
import { useSelector } from 'react-redux';

//assets
import demoAnimation from '../../../assets/video/demo-animation.webm';

/**
 * Finish Setup
 * @param {{
 *  onFinish: () => void,
 * }} param0
 * @returns
 */
const FinishSetup = ({ onFinish }) => {
	const toast = useToast();
	const {
		currentStage,
		continueBtnRef,
		setStage,
		selectedBrain,
		selectedPublishedSkillPacks,
	} = useContext(SetupWizardContext);
	const { feature } = useContext(AnalyticsContext);
	const handleContinue = () => {
		if (continueBtnRef.current) continueBtnRef.current.disabled = true;
		onFinish();
		analytics.track(`[${feature}] FinishButton: Click`, {
			feature,
		});
	};
	const commandBarVisible = useSelector((state) => state.cardUIV2.visible);
	React.useEffect(() => {
		if (commandBarVisible) {
			handleContinue();
		}
	}, [commandBarVisible, handleContinue]);

	// React.useEffect(() => {
	// 	if (isElectron()) {
	// 		const electron = window.require('electron');
	// 		const { ipcRenderer } = electron;
	// 		ipcRenderer.removeAllListeners('/toggleCmdBar');
	// 		ipcRenderer.on('/toggleCmdBar', (event, arg) => {
	// 			console.log('toggling command signal to mainWindow', arg);
	// 			handleContinue();
	// 			ipcRenderer.removeAllListeners('/toggleCmdBar');
	// 		});
	// 	}
	// });

	var isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
	return (
		<Box display="flex" flexDirection="column" height="100%">
			<Box flex="1" py="4" display="flex" flexDir="column">
				{true && (
					<Box as="h1" fontWeight="medium" fontSize="3xl">
						{setupWizardStages.byId[currentStage].label}
					</Box>
				)}
				{true && (
					<Box
						fontWeight="medium"
						fontSize="1rem"
						mb="2"
						display="flex"
						alignItems="center"
					>
						The following collections were installed:
					</Box>
				)}
				<Box flex="1" display="flex">
					<StorePackListSW currentBrainId={selectedBrain} />
					<Box paddingLeft="30px">
						<Box display="inline" fontSize="1.2rem" mb="5">
							You're all set! Press
							{isMac ? (
								<Box>
									<Kbd>Option (⌥)</Kbd> + <Kbd>Space</Kbd>
								</Box>
							) : (
								<Box>
									<Kbd>Alt</Kbd> + <Kbd>Space</Kbd>
								</Box>
							)}{' '}
							to open the Maya command bar.
						</Box>
						<VideoAnimationLoop src={demoAnimation} width={'400px'} />
					</Box>
				</Box>
			</Box>
			<ActionFooter
				continueBtnRef={continueBtnRef}
				currentSetupWizardStage={currentStage}
				onContinue={handleContinue}
				continueBtnLabel="Finish"
				hideContinueButton={true}
			/>
		</Box>
	);
};

export default FinishSetup;
