import { filter } from 'lodash';
export const getNodesCount = (flow) => {
	const blacklist = [
		'tab',
		'group',
		'flow',
		'comment',
		'debug',
		'subflow',
		'status',
		'link out',
		'link in',
		'complete',
		'catch',
	];
	let nodes = filter(flow, function (comp) {
		return !blacklist.includes(comp.type);
	});
	return nodes.length;
};
