import React from 'react';
import { useDisclosure, Text } from '@chakra-ui/react';
import { FiLayers, FiTrash2, FiShare, FiShare2 } from 'react-icons/fi';
//Components
import HeaderWithDesc from '../../Reusable/HeaderWithDesc';
import { StorePackItem } from '../../Store/StorePack/StorePackItem';
import GetProfileSlug from '../../../util/ProfileSlug';
import { HorizontalItemSkeleton } from '../../Reusable/HorizontalItem';
import { HorizontalItemButton } from '../../Reusable/HorizontalItem';
import { HistoryWithSlug } from '../../../util/History';
import { ShareLinkModal } from '../../Publish/ShareLink';
//Functions
import getPublishedSkillPackByDeveloper from '../../../functions/publishedSkillPack/getPublishedSkillPackByDeveloper';
import publishSkillPack from '../../../functions/publishedSkillPack/publishSkillPack';

export const UpdateButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiLayers />}
			buttonKey="update"
			text={'update'}
			onClick={onClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
			hovered={hoveredButton}
		/>
	);
};

export const DeleteButton = ({ setHoveredButton, hoveredButton, onClick }) => {
	return (
		<HorizontalItemButton
			icon={<FiTrash2 />}
			buttonKey="delete"
			text={'delete'}
			onClick={onClick}
			setHoveredButton={setHoveredButton}
			hoveredButton={hoveredButton}
			hovered={hoveredButton}
		/>
	);
};

export const ShareButton = ({
	setHoveredButton,
	hoveredButton,
	onClick,
	publishedSkillPack,
}) => {
	const { onClose, isOpen, onOpen } = useDisclosure();
	return (
		<>
			<HorizontalItemButton
				icon={<FiShare2 />}
				buttonKey="share"
				text={'share'}
				onClick={onOpen}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
				hovered={hoveredButton}
			/>
			<ShareLinkModal
				onClose={onClose}
				isOpen={isOpen}
				onOpen={onOpen}
				_id={publishedSkillPack._id}
			/>
		</>
	);
};

/**
 *
 * @param {*} param0
 */

const PublishedSkillPacks = ({ devAccountId }) => {
	const [packList, setPackList] = React.useState([]);
	const [hoveredButtonId, setHoveredButtonId] = React.useState({
		button: '',
		id: '',
	});

	const loadingList = [1, 2, 3];
	const [isLoading, setLoading] = React.useState(true);

	const { slug } = GetProfileSlug();
	React.useEffect(() => {
		//TODO : add get store skill packs
		getPublishedSkillPackByDeveloper({
			slug: slug,
			developerId: devAccountId,
		}).then((res) => {
			setLoading(false);
			if (res.data && Array.isArray(res.data)) setPackList(res.data);
			else console.error('res.data is not an array!');
		});
	}, [devAccountId]);
	return (
		<>
			<HeaderWithDesc
				header={'Published Collections'}
				desc={"View and update collections you've published on the store."}
				marginLeft="0.5rem"
			/>
			{isLoading ? (
				loadingList.map((item) => (
					<HorizontalItemSkeleton key={item} width={'100%'} />
				))
			) : packList.length ? (
				packList.map((publishedSkillPack) => (
					<StorePackItem
						key={publishedSkillPack._id}
						publishedSkillPack={publishedSkillPack}
						selected={false}
						onClick={(publishedSkillPack) =>
							console.log(publishedSkillPack._id)
						}
						wholeItemActive={false}
						isItemExpanded={false}
						addButton={false}
						skillsButton={false}
						rightItem={
							<>
								<ShareButton
									hoveredButton={
										hoveredButtonId.id === publishedSkillPack._id &&
										hoveredButtonId.button === 'share'
									}
									setHoveredButton={() => {
										setHoveredButtonId({
											button: 'share',
											id: publishedSkillPack._id,
										});
									}}
									publishedSkillPack={publishedSkillPack}
									onClick={() => {}}
								/>
								<UpdateButton
									hoveredButton={
										hoveredButtonId.id === publishedSkillPack._id &&
										hoveredButtonId.button === 'update'
									}
									setHoveredButton={() => {
										setHoveredButtonId({
											button: 'update',
											id: publishedSkillPack._id,
										});
									}}
									onClick={() => {
										HistoryWithSlug.push(
											`/publish?update=${publishedSkillPack._id}`
										);
									}}
								/>
								<DeleteButton
									hoveredButton={
										hoveredButtonId.id === publishedSkillPack._id &&
										hoveredButtonId.button === 'delete'
									}
									setHoveredButton={() => {
										setHoveredButtonId({
											button: 'delete',
											id: publishedSkillPack._id,
										});
									}}
									onClick={() => {}}
								/>
							</>
						}
					/>
				))
			) : (
				<Text marginLeft="0.5rem" fontSize="sm" color="GrayText">
					No Published Collections available.
				</Text>
			)}
		</>
	);
};

export default PublishedSkillPacks;
