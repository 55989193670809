import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
import { store } from '../../redux/store';

const getPublishedSkillPack = async (formData) => {
	console.log(formData);
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;
	const getPublishedSkillPackAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getPublishedSkillPack.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getPublishedSkillPack.PATH_METHOD,
		URL_PARAM: {
			_id: formData._id,
		},
	});

	const getPublishedSkillPackAPIResponse = await getPublishedSkillPackAPIFn({
		profileSlug,
		...formData,
	});
	return getPublishedSkillPackAPIResponse;
};

export default getPublishedSkillPack;
