import React, { useRef, useState } from 'react';
import SearchArea from './SearchArea';
import { useDispatch, useSelector } from 'react-redux';
import { Box, useColorMode, Progress } from '@chakra-ui/react';
import SuggestionsArea from './SuggestionsArea';
import CommandBarContext from './CommandBarContext';
import CommandBarStatus from './CommandBarStatus';
import { commandBarStatusUpdate } from '../../../../redux/actions/cardUIV2';

const CommandBar = () => {
	const dispatch = useDispatch();
	const display = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.display
	);
	const commandBarStatus = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.commandBarStatus
	);

	const { colorMode } = useColorMode();
	const bgColor = { light: 'card_ui.bg_light', dark: 'card_ui.bg_dark' };
	const [currentInput, setCurrentInput] = useState(null);

	return (
		<CommandBarContext.Provider
			value={{
				currentInputRef: currentInput,
				setCurrentInputRef: setCurrentInput,
			}}
		>
			<Box
				className="maya-command-ui" // added for checkInBody identification
				width={`${38}rem`}
				marginTop="19vh"
				position="absolute"
				left={'0'}
				right={'0'}
				marginLeft={'auto'}
				marginRight={'auto'}
			>
				<CommandBarStatus
					className="maya-command-ui" // added for checkInBody identification
					mb="0.2rem"
					visible={commandBarStatus.visible}
					status={commandBarStatus.status}
					message={commandBarStatus.message}
					onClose={() =>
						dispatch(commandBarStatusUpdate({ visible: false }))
					}
				/>

				<Box
					width="full"
					display="inline-block"
					roundedTop="0.25rem"
					roundedBottom="0.25rem"
					bg={bgColor[colorMode]}
					justifyContent="center"
					boxShadow={'1px 5px 20px rgba(0, 0, 0, 0.2)'}
				>
					<SearchArea display={display} />

					{display === 'expanded' ? <SuggestionsArea /> : null}
				</Box>
			</Box>
		</CommandBarContext.Provider>
	);
};

export default CommandBar;
