/**
 * History Utility
 *
 * This creates a representation of the history of the application
 * that can be utilized throughout the different components without the need
 * to pass the history property from the route (App -> Dashboard -> new component)
 * to each component.
 */

// Dependencies
import { createBrowserHistory } from 'history';
import isElectron from 'is-electron';
// Export the history module.
// export default isElectron()
//   ? createBrowserHistory({
//       basename: window.location.pathname
//     })
//   : createBrowserHistory();

// export default createBrowserHistory();
// export default isElectron() ? createHashHistory() : createBrowserHistory();

export default isElectron()
	? createBrowserHistory({
			basename: '/',
	  })
	: createBrowserHistory();
