// Dependency imports

// import worker from 'workerize-loader!../../../src/maya-service-worker'; // eslint-disable-line import/no-webpack-loader-syntax
import IPCFunction from '../../util/IPCFetch/function';

import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

import { store } from '../../redux/store';

/**
 * Function to register a new device
 */
const registerDevice = async (formData) => {
	const { user } = store.getState();
	formData['userId'] = user.info.id;
	// Create an instance of your worker
	var registerDeviceFn = IPCFunction({
		EVENT: IPCRoutes.ipcService.registerDevice.EVENT,
	});

	var registerDeviceResponse = await registerDeviceFn(formData);

	return registerDeviceResponse['data'];
};

export default registerDevice;
