import React from 'react';

//Libs
import { Box, useColorMode, Image } from '@chakra-ui/react';
import dotProp from 'dot-prop-immutable';
import { ChevronRightIcon } from '@chakra-ui/icons';

//Assets
import enterIconDark from '../../../../../assets/cardui/entericon_dark.svg';
import enterIconLight from '../../../../../assets/cardui/entericon_light.svg';
import mainInstructionsDark from '../../../../../assets/cardui/main_instructions_dark.svg';

//Redux
import { connect } from 'react-redux';

//Components
import { IntentDisplay } from '../../../CommandItem/IntentDisplay';
import InstructionSection from './InstructionSection';
import {
	selectMiddleSearchItem,
	setMiddleSearchItems,
	setMiddleSearchItemSelectedIndex,
	setMiddleSearchModeAndType,
	setSearchBarLoadingStatus,
	setSearchMode,
	setVibrateInstructions,
	toggleCardsVisibility,
	toggleSearchDisplayMode,
} from '../../../../../redux/actions/cardui';
import ItemRightInstructions from './ItemInstructions';
import WebCard from '../../CardsNew/WebCard';

const SearchItem = ({ selected, lastItem, intent, onMouseDown }) => {
	const { colorMode } = useColorMode();
	const bgColorSelected = {
		light: 'card_ui.focus_light',
		dark: 'card_ui.focus_dark',
	};
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	const dividerColor = {
		light: 'card_ui.divider_light',
		dark: 'card_ui.divider_dark',
	};
	return (
		<Box
			paddingY="1.5rem"
			paddingLeft="1.2rem"
			height="2rem"
			display="flex"
			alignItems="center"
			bg={selected ? bgColorSelected[colorMode] : 'none'}
			borderRight="solid 3px"
			borderRightColor={selected ? '#9B51E0' : 'transparent'}
			borderTop={'solid 1px'}
			justifyContent="space-between"
			borderTopColor={dividerColor[colorMode]}
			borderBottomLeftRadius={lastItem ? '5px' : '0px'}
			borderBottomRightRadius={lastItem ? '5px' : '0px'}
			onMouseDown={onMouseDown}
			// roundedBottom={lastItem ? "1rem" : "0rem"}
		>
			<Box display="flex" alignItems="center">
				<ChevronRightIcon
					color={selected ? color[colorMode] : 'transparent'}
				/>
				<Box display="flex" alignItems="center" ml={3} flexWrap={'wrap'}>
					<IntentDisplay intentArray={intent} highlighted={selected} />
				</Box>
			</Box>
			{selected ? <ItemRightInstructions /> : null}
		</Box>
	);
};
export const resultsTabs = [
	{ type: 'commands', label: 'Commands' },
	{ type: 'cards', label: 'Cards' },
	{ type: 'tasks', label: 'Tasks' },
	{ type: 'store', label: 'Store' },
];

const ResultTabs = ({ currentTab, setCurrentTab }) => {
	const { colorMode } = useColorMode();
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};
	return (
		<Box height="1.6rem" display="flex" alignItems="center" pl={8}>
			{resultsTabs.map((result, i) => {
				return (
					<Box
						height="1.6rem"
						fontSize="0.7rem"
						fontFamily="body"
						fontWeight="600"
						width="5.5rem"
						display="flex"
						key={i}
						alignItems="center"
						justifyContent="center"
						color={color[colorMode]}
						borderBottom={'solid 2px'}
						onClick={() => setCurrentTab(result.type)}
						borderBottomColor={
							result.type === currentTab
								? 'card_ui.purple'
								: 'transparent'
						}
						transition="all 0.1s cubic-bezier(.08,.52,.52,1)"
						cursor="pointer"
						opacity={result.type === currentTab ? '1' : '0.8'}
					>
						{result.label}
					</Box>
				);
			})}
		</Box>
	);
};

const CommandSearchList = ({
	searchItems,
	selectedIndex,
	resultsType,
	setSelectedIndex,
	setSearchBarLoadingStatus,
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
	setMode,
	setMiddleSearchModeAndType,
	vibrateInstructions,
	setVibrateInstructions,
	cardIds,
}) => {
	// const listLength = searchItems.allIds.length - 1;
	let maxResultsLength = 4;
	let searchItemsIndexed = searchItems.allIds.map((intentId, i) => ({
		index: i,
		intentId: intentId,
	}));

	const itemsToRender = () => {
		let indiceArrayLength =
			searchItemsIndexed.length > maxResultsLength
				? maxResultsLength
				: searchItemsIndexed.length;
		let indiceArray = Array.from({ length: indiceArrayLength }, (_, i) => i);
		if (selectedIndex > maxResultsLength - 1) {
			indiceArray = Array.from(
				{ length: maxResultsLength },
				(_, i) => i + selectedIndex - maxResultsLength + 1
			);
		}

		return {
			items: indiceArray.map((index) => {
				return searchItemsIndexed[index];
			}),
			indices: indiceArray,
		};
		// return searchItemsIndexed;
	};

	let toRender = itemsToRender();
	const listItemsRendered = toRender.items.map((item, i) => (
		<SearchItem
			key={i}
			intent={searchItems.byId[item.intentId].intent}
			selected={item.index === selectedIndex ? true : false}
			lastItem={i === toRender.items.length - 1 ? true : false}
		/>
	));

	var itemHeight = 3; //in rem

	var scrollHeight =
		(maxResultsLength * itemHeight * maxResultsLength) /
		searchItemsIndexed.length;
	var listHeight = itemHeight * maxResultsLength;

	var scrollMarginTop =
		toRender.indices[0] === 0
			? 0
			: ((selectedIndex - maxResultsLength + 1) *
					(listHeight - scrollHeight)) /
			  (searchItemsIndexed.length - maxResultsLength);

	return (
		<Box
			onMouseDown={(e) => {
				e.preventDefault();
				setVibrateInstructions(true);
				setTimeout(() => setVibrateInstructions(false), 500);
			}}
		>
			<ResultTabs
				currentTab={resultsType}
				setCurrentTab={(tab) => setMiddleSearchModeAndType('intents', tab)}
			/>
			<Box display="flex">
				{searchItemsIndexed.length > maxResultsLength ? (
					<Box width="1px" position="absolute">
						<Box
							width="100%"
							bg={'card_ui.purple'}
							height={scrollHeight + 'rem'}
							borderRadius="4px"
							// marginBottom="0"
							marginTop={scrollMarginTop + 'rem'}
						/>
					</Box>
				) : null}
				<Box width="100%">
					{resultsType === 'commands' ? listItemsRendered : null}
					{/* {resultsType === 'cards' ? (
						<WebCard
							id={cardIds[cardIds.length - 1]}
							isMaximized={false}
						/>
					) : null} */}
				</Box>
			</Box>
			<InstructionSection
				vibrate={vibrateInstructions}
				children={
					<Image src={mainInstructionsDark} height="1.2rem" mr={4} />
				}
			/>
		</Box>
	);
};

const matchStateToProps = (state, ownProps) => {
	const { centerSearch, cards } = state.cardUI;
	const cardIds = cards.allIds;
	const middle = dotProp.get(centerSearch, `middle`);
	const mode = dotProp.get(centerSearch, `mode`);
	const { searchItems, selectedIndex, type, vibrateInstructions } = middle;
	return {
		searchItems,
		selectedIndex,
		resultsType: type,
		mode,
		vibrateInstructions,
		cardIds,
	};
};

export default connect(matchStateToProps, {
	setSelectedIndex: setMiddleSearchItemSelectedIndex,
	setMode: setSearchMode,
	setSearchBarLoadingStatus,
	selectMiddleSearchItem,
	setMiddleSearchItems,
	toggleSearchDisplayMode,
	toggleCardsVisibility,
	setVibrateInstructions,
	setMiddleSearchModeAndType,
})(CommandSearchList);
