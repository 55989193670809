import React from 'react';
import {
	Flex,
	Box,
	Button,
	useColorMode,
	Icon,
	HStack,
	ButtonGroup,
	IconButton,
} from '@chakra-ui/react';
import {
	ArrowLeftIcon,
	ArrowRightIcon,
	ExternalLinkIcon,
} from '@chakra-ui/icons';
import { CgArrowsExpandRight } from 'react-icons/cg';
import { FiMinimize2 } from 'react-icons/fi';
import WebCardButton from './Button';

/**
 *
 * @param {{
 *  onOpenInBrowser: () => void,
 *  onBackward: () => void,
 *  onForward: () => void,
 *  onMaximize?: () => void,
 *  onMinimize?: () => void,
 * } & import('@chakra-ui/react').BoxProps} param0
 * @returns
 */
const NavBar = ({
	onOpenInBrowser,
	onBackward,
	onForward,
	onMaximize,
	onMinimize,
	...rest
}) => {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'linear-gradient(180deg, #F6F6F6 0%, rgba(234, 234, 234, 0.97) 100%)',
		// dark: 'linear-gradient(180deg, #393737 0%, #313030 100%)',
		dark: '#343434',
	};

	return (
		<ButtonGroup
			spacing="1"
			background={bgColor[colorMode]}
			padding="0.19rem"
			{...rest}
		>
			<WebCardButton
				onClick={onBackward}
				icon={<ArrowLeftIcon boxSize="0.4rem" />}
			/>
			<WebCardButton
				onClick={onForward}
				icon={<ArrowRightIcon boxSize="0.4rem" />}
			/>
			<WebCardButton
				onClick={onOpenInBrowser}
				icon={<ExternalLinkIcon boxSize="0.6rem" />}
			/>
			{onMaximize ? (
				<WebCardButton
					onClick={onMaximize}
					icon={<Icon as={CgArrowsExpandRight} boxSize="0.65rem" />}
				/>
			) : null}
			{onMinimize ? (
				<WebCardButton
					onClick={onMinimize}
					icon={<Icon as={FiMinimize2} boxSize="0.65rem" />}
				/>
			) : null}
		</ButtonGroup>
	);
};

export default NavBar;
