import React from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

/*
FOR REF :
const processComponents = (components, url) => {
	return components.map((component) => {
		if (component.type === 'prompt') {
			return {
				type: 'text',
				value: component.value,
			};
		}
		if (component.type === 'custom') {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: 'custom',
				endpointUrl: url + component.endpoint,
			};
		} else {
			return {
				type: 'placeholder',
				value: '',
				key: component.value,
				keyType: component.type,
			};
		}
	});
};
*/

/**
 *
 * @param {{
 * 	intentComponent: import('../../../../../redux/reducers/cardUIV2').CuiIntentComponent,
 * 	sizeMultiple: number,
 * }} param0
 * @returns
 */
export const IntentInputItemDisplay = ({ intentComponent, sizeMultiple }) => {
	const bgColor = {
		light: 'card_ui.placeholder_light',
		dark: 'card_ui.placeholder_dark',
	};
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};
	const { colorMode } = useColorMode();

	/** if intentComponent is invalid return null */
	if (!intentComponent) return null;

	return intentComponent.type === 'prompt' ? (
		<Box
			fontWeight="500"
			color={color[colorMode]}
			fontSize={`${1 * sizeMultiple}rem`}
			mr={2}
			fontFamily="body"
			whiteSpace="nowrap"
		>
			{intentComponent.value}
		</Box>
	) : (
		<Box
			height={`${1.4 * sizeMultiple}rem`}
			bg={bgColor[colorMode]}
			borderRadius="0.2rem"
			display="flex"
			justifyContent="center"
			alignItems="center"
			paddingX="0.4rem"
			marginTop="0.1rem"
			mr={2}
			color={color[colorMode]}
			_focus={{ outline: 'none' }}
			whiteSpace="nowrap"
		>
			<Box
				color={color[colorMode]}
				whiteSpace="nowrap"
				bg={'transparent'}
				fontWeight="600"
				isdisabled={'true'}
				fontFamily="body"
				padding="0"
				border="none"
				borderRadius="none"
				textAlign={'center'}
				fontSize={`${0.8 * sizeMultiple}rem`}
				width={`${intentComponent.value.length * 0.55}rem`}
			>
				{intentComponent.value}
			</Box>
		</Box>
	);
};

/**
 *
 * @param {{
 *  	intentComponentIds: string[]
 * }} param0
 * @returns
 */
const IntentDisplay = ({ intentComponentIds, sizeMultiple }) => {
	const components = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities.components
	);
	return intentComponentIds.map((componentId, i) => {
		const component = components.byId[componentId];
		return (
			<IntentInputItemDisplay
				key={i}
				intentComponent={component}
				sizeMultiple={sizeMultiple || 1}
			/>
		);
	});
};

export default IntentDisplay;
