import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React from 'react';

/**
 * @type {{
 * 	id: import('./SimpleCronSchdeduler').TimePeriod,
 * 	label: string,
 * }[]}
 */
const periodConfigs = [
	{
		id: 'seconds',
		label: 'Seconds',
	},
	{
		id: 'minutes',
		label: 'Minutes',
	},
	{
		id: 'hours',
		label: 'Hours',
	},
	{
		id: 'days',
		label: 'Days',
	},
	{
		id: 'dayOfWeek',
		label: 'Day Of Week',
	},
	{
		id: 'dayOfMonth',
		label: 'Day Of Month',
	},
	// {
	// 	id: 'month',
	// 	label: 'Month',
	// },
	// {
	// 	id: 'year',
	// 	label: 'Year',
	// },
];

/**
 *
 * @param {{
 * 	selectedPeriod: import('./SimpleCronSchdeduler').TimePeriod,
 * 	onSelect: (newTimePeriod: import('./SimpleCronSchdeduler').TimePeriod) => void,
 *  	menuProps?: import('@chakra-ui/react').MenuProps,
 *		menuButtonProps?: import('@chakra-ui/react').MenuButtonProps & import('@chakra-ui/react').ButtonProps,
 *		menuListProps?: import('@chakra-ui/react').MenuListProps,
 *		menuItemProps?: import('@chakra-ui/react').MenuItemProps,
 * }} param0
 * @returns
 */
const PeriodSelector = ({
	selectedPeriod,
	onSelect,
	menuProps,
	menuButtonProps,
	menuListProps,
	menuItemProps,
}) => {
	return (
		<Menu gutter={0} matchWidth placement="bottom" {...menuProps}>
			{({ isOpen }) => (
				<>
					<MenuButton
						as={Button}
						rightIcon={<ChevronDownIcon />}
						size="sm"
						minWidth="8rem"
						borderBottomRadius={isOpen ? 0 : undefined}
						{...menuButtonProps}
					>
						{periodConfigs.find((c) => c.id === selectedPeriod).label}
					</MenuButton>
					<MenuList
						fontSize="sm"
						borderTopRadius={0}
						minWidth="inherit"
						bg="#3b3b3b"
						{...menuListProps}
					>
						{periodConfigs.map((config) => (
							<MenuItem
								key={config.id}
								onClick={() => onSelect(config.id)}
								{...menuItemProps}
							>
								{config.label}
							</MenuItem>
						))}
					</MenuList>
				</>
			)}
		</Menu>
	);
};

export default React.memo(PeriodSelector);
