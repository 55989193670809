import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

import getAccountId from './getAccountId';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const saveConfigProfile = async ({
	configurationType,
	formData,
	slug = null,
	moduleId,
	moduleName,
	mode,
	referenceId,
}) => {
	let { user, devices } = store.getState();
	let profileSlug = slug ? slug : user.info.id;
	var saveConfigProfileResponse, saveConfigProfileIPCFn;
	var addSavedConfigToProfileResponse, addSavedConfigToProfileAPIFn;
	let data = {
		profileName: formData.name,
		config: {
			resource: configurationType.resource,
			type: configurationType.type,
			values: {},
		},
		moduleName,
		mode,
		referenceId,
	};
	for (let key in formData) {
		console.log(key);
		if (key !== 'name') {
			data['config']['values'][key] = formData[key];
		}
	}
	if (isElectron()) {
		if (window.navigator.onLine) {
			saveConfigProfileIPCFn = IPCFunction({
				EVENT: IPCRoutes.ipcService.saveConfigProfile.EVENT,
			});
			saveConfigProfileResponse = await saveConfigProfileIPCFn(data);
			if (!saveConfigProfileResponse.data.error) {
				let accountId = await getAccountId({
					configurationType,
					formData,
				}).catch((e) => console.log(e));
				addSavedConfigToProfileAPIFn = APIFunction({
					BASEURL: config.apiServer.BASEURL,
					PATH_SEARCH: APIRoutes.apiService.addConfigToProfile.PATH_SEARCH,
					PATH_METHOD: APIRoutes.apiService.addConfigToProfile.PATH_METHOD,
				});
				if (mode === 'add') {
					let profileConfigUpdate = {
						deviceId: devices.current.device._id,
						resource: data.config.resource,
						name: data.profileName,
						configType: data.config.type,
						referenceId:
							saveConfigProfileResponse.data.results.referenceId,
						profileSlug: profileSlug,
						moduleId: moduleId,
						accountId: accountId,
					};
					try {
						addSavedConfigToProfileResponse =
							await addSavedConfigToProfileAPIFn(profileConfigUpdate);
						return addSavedConfigToProfileResponse['data'];
					} catch (err) {
						return {
							error: true,
							message: 'config profile creation failed',
						};
					}
				} else {
					return {
						error: false,
						message: 'config profile has been updated',
					};
				}
			} else {
				return {
					error: true,
					message: 'config profile could not be saved',
				};
			}
		} else {
			return {
				error: true,
				message: 'You need to be online to verify and save config profile',
			};
		}
	} else {
		return {
			error: true,
			message: 'This service is available only for Maya Desktop Application',
		};
	}
};

export default saveConfigProfile;
