import algoliasearch from 'algoliasearch/lite';

export const algoliaClient = algoliasearch(
	process.env.REACT_APP_ALGOLIA_APPLICATION_ID,
	process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);
/**
 * Algolia client with Intents Indexed
 */
export const algoliaIntentsIndex = algoliaClient.initIndex('prod_intents');

/**
 * Algolia client with Modules Indexed
 */
export const algoliaModulesIndex =
	algoliaClient.initIndex('prod_strapi_module');
