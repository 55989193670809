import React, { useContext, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Heading,
	Input,
	LightMode,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Stack,
	useColorModeValue,
	useDisclosure,
	useToast,
} from '@chakra-ui/react';
import { connect } from 'react-redux';
import { setCurrentDevice, setDevices } from '../../redux/actions';
import IPCFetch from '../../util/IPCFetch';
import registerDevice from '../../functions/devices/registerDevice';
import getMachineDetails from '../../functions/devices/getMachineDetails';
import checkDevice from '../../functions/devices/checkDevice';
import isElectron from 'is-electron';
import getMachineName from '../../functions/devices/getMachineName';
import truncate from 'lodash/truncate';
import IconContainer from '../../util/React_Icon';
import { IoAdd } from 'react-icons/io5';
import HeaderWithDescription from './HeaderWithDescription';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

/**
 *
 * @param {{
 *  	onClick: () => void
 * }} param0
 * @returns
 */
const AddNewDeviceItem = ({ onClick }) => {
	var iconColor = useColorModeValue('#656565', 'white');

	return (
		<Button
			fontWeight="normal"
			p="0"
			height="3.5rem"
			width="15.5rem"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			onClick={onClick}
		>
			<Box display="flex" alignItems="center">
				<Box marginLeft="1rem">
					<IconContainer
						icon={<IoAdd />}
						value={{
							color: iconColor,
							className: 'global-class-name',
							size: '1.8rem',
						}}
					/>
				</Box>
				<Flex direction="column" align="flex-start" marginLeft="1rem">
					<Box
						fontSize="0.9rem"
						color={useColorModeValue('#444444', 'white')}
					>
						Add new device
					</Box>
					<Box
						fontSize="0.6rem"
						color={useColorModeValue('#888383', '#c3c3c3')}
					>
						Create a new device
					</Box>
				</Flex>
			</Box>
		</Button>
	);
};

/**
 * New Device Registration
 */
const NewDevice = ({ setCurrentDevice, onCreate }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const [deviceName, setDeviceName] = useState('');
	const [deviceNameIsCustomized, setDeviceNameIsCustomized] = useState(false);
	const [machineDetails, setMachineDetails] = React.useState({});
	console.log('machineDetails', machineDetails);

	const handleConfirm = async () => {
		try {
			const res = await registerDevice({
				name: deviceName,
			});
			if (res.error) throw new Error(res.message);
			await getMachineDetails().then(async (machineDetailsResponse) => {
				if (machineDetailsResponse) {
					let machineId = machineDetailsResponse.machineID;
					setMachineDetails(machineId);
					const deviceResponse = await checkDevice({
						machineID: machineId,
					});
					setCurrentDevice(deviceResponse.results.device);
				}
			});
			toast({
				title: 'Device Created',
				description: res.message,
				status: 'success',
				duration: 3000,
				isClosable: false,
			});
			onCreate();
			onClose();
		} catch (error) {
			console.error(error);
			toast({
				title: 'Error Occurred.',
				description: error.message,
				status: 'error',
				duration: 3000,
				isClosable: false,
			});
		}
	};

	useEffect(() => {
		if (isOpen)
			getMachineName().then((res) => {
				setDeviceName(res);
			});
	}, [isOpen]);

	return (
		<>
			<AddNewDeviceItem onClick={onOpen} />
			<CustomModal
				isOpen={isOpen}
				onClose={onClose}
				isCentered
				size="lg"
				// {...modalProps}
			>
				<ModalOverlay />
				<ModalContent height="xs" bg="#313131">
					<HeaderWithDescription
						header="Register New Device"
						description="Register new device so that you can install
						skills on it"
						containerProps={{
							px: '4',
							py: '3',
							borderTopRadius: 'xl',
						}}
					/>
					<ModalCloseButton />
					<ModalBody
						flex="1"
						minHeight="0"
						display="flex"
						flexDirection="column"
						p="0"
					>
						<Flex direction="column" flex="1" minHeight="0" p="4">
							<Input
								placeholder="Enter device name"
								_focus={{
									borderColor: 'purple.500',
								}}
								my="5"
								value={deviceName}
								onChange={(e) => {
									setDeviceNameIsCustomized(true);
									setDeviceName(e.target.value);
								}}
								bg="#3C3C3C"
								zIndex="50"
							/>
							<Box fontWeight="medium" fontSize="1rem" opacity="0.8">
								<span role="img">💡</span> Using Maya, you can automate
								tasks by installing Maya skills across devices and
								platforms - desktop, cloud & soon, edge devices.
							</Box>
						</Flex>
						<Flex py="3" px="4" bg="#363636" borderBottomRadius="xl">
							<Spacer />
							<Stack direction="row" spacing="3">
								<Button
									borderRadius="0.25rem"
									size="sm"
									minWidth="28"
									onClick={onClose}
								>
									Close
								</Button>
								<LightMode>
									<Button
										size="sm"
										borderRadius="0.25rem"
										colorScheme="purple"
										minWidth="28"
										onClick={handleConfirm}
									>
										Confirm
									</Button>
								</LightMode>
							</Stack>
						</Flex>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

const mapStateToProps = (state) => {
	const { devices } = state;
	return { devices };
};

export default connect(mapStateToProps, { setCurrentDevice, setDevices })(
	NewDevice
);
