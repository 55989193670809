import React from 'react';
import { Box } from '@chakra-ui/react';
import ModuleList from '../../Module/ModuleList';

const ModulePackSection = ({ currentBrainId, categories, platforms, apps }) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			// paddingY="12px"
			marginLeft="20px"
			maxWidth="100%"
			flex="1 1 auto"
			// bg='pink'
		>
			<ModuleList
				currentBrainId={currentBrainId}
				addButton={true}
				profilesButton={true}
				categories={categories}
				apps={apps}
				platforms={platforms}
			/>
		</Box>
	);
};

export default ModulePackSection;
