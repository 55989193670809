export const formatModuleItems = (mods) => {
	var arr = [];
	for (var key in mods.byId) {
		let newItem = {
			_id: mods.byId[key]._id,
			name: mods.byId[key].name,
			packageName: mods.byId[key].packageName,
		};
		arr.push(newItem);
	}
	return arr;
};
