import React, { useState } from 'react';
import {
	Box,
	useColorModeValue,
	Progress,
	useDisclosure,
} from '@chakra-ui/react';

import { connect, useSelector } from 'react-redux';

//Functinos
import getUsage from '../../functions/settings/usage/getUsage';
import PaymentsModal from '../Payments';
import { store } from '../../redux/store';
import { getExpiryTime, getTimeToExpiry } from '../../util/Tier/getTimeRemaining';
import { TrialExpiredModal } from '../Payments/TrialExpiredModal';

const TOTAL_TRIAL_DAYS = 14;
const MILISECONDS_PER_DAY = 86400000;

const UsageStats = () => {
	const [usageData, setUsageData] = useState({
		daysRemaining: 0,
		daysUsed: 0,
		trialDays: 15
	});

	const route = useSelector((store) => store.navigation.route);
	const brains = useSelector((store) => store.brains);
	const { tier } = useSelector((store) => store.profiles.selected);
	const { isOpen, onClose, onOpen } = useDisclosure();

	React.useEffect(() => {
		getExpiryTime()
			.then(({ expiryTime, trialDays }) => {
				const timeToExpiry = expiryTime - Date.now()
				const daysRemaining = parseInt((timeToExpiry - 1) / MILISECONDS_PER_DAY) + 1;
				setUsageData({
					daysRemaining,
					trialDays,
					daysUsed: trialDays - daysRemaining
				})
			})

		// getTimeToExpiry().then((timeToExpiry) => {
		// 	const daysRemaining =
		// 		parseInt((timeToExpiry - 1) / MILISECONDS_PER_DAY) + 1;
		// 	setUsageData({
		// 		daysRemaining,
		// 		daysUsed: usageData.trialDays - daysRemaining,
		// 	});
		// });
		}, [brains, route, tier]);

	const tierInfoVisible = false;
	const percentage = parseInt((usageData.daysUsed / usageData.trialDays) * 100);

	return (
		<Box
			width="90%"
			// height="100px"
			bg={'maya_dark.250'}
			margin="10px"
			borderRadius="5px"
			padding="10px"
			cursor="pointer"
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
		>
			<Box fontSize="14px" mb="10px">
				{`${
					usageData.daysRemaining >= 0 ? usageData.daysRemaining : 0
				} of ${usageData.trialDays} days left on free trial`}
			</Box>
			<Progress colorScheme="gray" size="xs" value={percentage} mb="2" />
			<Box
				fontSize="13px"
				// fontWeight='bold'
				// mb="2"
				onClick={() => {
					onOpen();
				}}
				_hover={{ textDecoration: 'underline' }}
			>
				Upgrade and go unlimited
			</Box>
			{/* <PaymentsModal
				isOpen={isOpen}
				onClose={() => {
					// History.push(pathname);
					onClose();
				}}
			/> */}
			<TrialExpiredModal
				isOpen={isOpen}
				onClose={onClose}
				trialExpired={false}
			/>
		</Box>
	);

	// return tier === 'FREE' && tierInfoVisible ? (
	// 	<Box
	// 		width="90%"
	// 		height="100px"
	// 		bg={'maya_dark.250'}
	// 		margin="10px"
	// 		borderRadius="5px"
	// 		padding="10px"
	// 		cursor="pointer"
	// 		boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
	// 	>
	// 		<Box fontSize="14px" mb="2">
	// 			{`${details.nodes} of ${details.upperLimit} monthly active node limit used (${details.percentage}%).`}
	// 		</Box>
	// 		<Progress
	// 			colorScheme="gray"
	// 			size="xs"
	// 			value={details.percentage}
	// 			mb="2"
	// 		/>
	// 		<Box
	// 			fontSize="12px"
	// 			mb="2"
	// 			onClick={() => {
	// 				onOpen();
	// 			}}
	// 			_hover={{ textDecoration: 'underline' }}
	// 		>
	// 			Upgrade to go unlimited
	// 		</Box>
	// 		<PaymentsModal
	// 			isOpen={isOpen}
	// 			onClose={() => {
	// 				// History.push(pathname);
	// 				onClose();
	// 			}}
	// 		/>
	// 	</Box>
	// ) : null;
};

export default UsageStats;
