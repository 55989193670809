// Dependency imports

import isElectron from 'is-electron';

import APIFunction from '../../util/APIFetch/function';
import IPCFunction from '../../util/IPCFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCRoutes from '../../util/IPCRoutes';

// config
import { config } from '../../config';

//redux
import { store } from '../../redux/store';
/**
 * Function to Create a New Workspace, given some parameters
 */

const createBrain = async ({ name, device, slug, defaultRuntime = false }) => {
	var { user } = store.getState();
	var profileSlug = slug ? slug : user.info.id;

	const formData = {
		name: name,
		profileSlug: profileSlug,
		default: defaultRuntime,
		device: {
			id: device._id,
			deviceName: device.name,
		},
	};
	var createBrainAPIFn, createBrainIPCFn, createBrainResponse;
	if (!isElectron()) {
		formData['local'] = false; //flag to check if brain PV is to be created on cloud
		createBrainAPIFn = APIFunction({
			BASEURL: config.apiServer.BASEURL,
			PATH_SEARCH: APIRoutes.apiService.createBrain.PATH_SEARCH,
			PATH_METHOD: APIRoutes.apiService.createBrain.PATH_METHOD,
		});
		var createBrainResponse = await createBrainAPIFn(formData);
		if (createBrainResponse['data']['error']) {
			return createBrainResponse['data'];
		} else {
			return createBrainResponse['data']['results'];
		}
	} else {
		formData['local'] = true; //flag to check if brain PV is to be created on cloud
		createBrainIPCFn = IPCFunction({
			EVENT: IPCRoutes.ipcService.createBrain.EVENT,
		});
		createBrainResponse = await createBrainIPCFn(formData);
		if (!createBrainResponse['data']['error']) {
			return createBrainResponse['data']['results'];
		} else {
			return createBrainResponse['data'];
		}
	}
};

export default createBrain;
