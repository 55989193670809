import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import profileIcon from '../../../assets/settings/profile.png';
import dotProp from 'dot-prop-immutable';

import _ from 'lodash';

import APIFetch from '../../../util/APIFetch';
import APIRoutes from '../../../util/APIRoutes';

import { config } from '../../../config';
import MayaSkeleton from '../../../util/ContentLoaders/MayaSkeleton';

/**
 * Profile Loader
 */

const ProfileLoader = () => {
	return (
		<Box pl="2">
			<MayaSkeleton
				height="150px"
				my="10px"
				width="150px"
				opacity="0.5"
				mt={2}
				borderRadius="100px"
			/>
			<MayaSkeleton
				height="20px"
				my="10px"
				width="5rem"
				opacity="0.5"
				mt={4}
				borderRadius="5px"
			/>
			<MayaSkeleton
				height="20px"
				my="10px"
				width="10rem"
				opacity="0.5"
				mt={2}
				borderRadius="5px"
			/>
			<MayaSkeleton
				height="20px"
				my="10px"
				width="5rem"
				opacity="0.5"
				mt={4}
				borderRadius="5px"
			/>
			<MayaSkeleton
				height="20px"
				my="10px"
				width="10rem"
				opacity="0.5"
				mt={2}
				borderRadius="5px"
			/>
		</Box>
	);
};

/**
 * Profile Section
 *
 */

export const ProfileSection = () => {
	const [isLoading, setLoading] = React.useState(true);
	const [details, setDetails] = React.useState({});

	// Setup the API Fetch utility for getting current flow
	const [{ fetchResults: getUserDetailsAPI }] = APIFetch({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getUserDetails.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getUserDetails.PATH_METHOD,
	});

	React.useEffect(() => {
		setLoading(true);
		getUserDetailsAPI()
			.then((res) => {
				setDetails({
					...res.data.user,
					developerAccounts: res.data.developerAccounts,
				});
				setLoading(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [setDetails]);

	const handleAddAccount = (account) => {
		let newDetails = dotProp.set(details, 'developerAccounts', (list) => [
			...list,
			account,
		]);
		setDetails(newDetails);
	};
	const handleDeleteAccount = (accountID) => {
		let index = _.findIndex(details.developerAccounts, (o) => {
			return o._id === accountID;
		});

		if (index !== -1) {
			let newDetails = dotProp.delete(details, `developerAccounts.${index}`);
			setDetails(newDetails);
		}
	};

	return isLoading ? (
		<ProfileLoader />
	) : (
		<Box pl="2">
			<Image
				rounded="full"
				size="150px"
				src={profileIcon}
				alt="Profile Picture"
				mb="4"
			/>
			<Box fontFamily="body" fontSize="0.8rem" color="gray.600">
				Name
			</Box>
			<Box fontFamily="body" mb="2">
				{details.name}
			</Box>
			<Box fontFamily="body" fontSize="0.8rem" color="gray.600">
				Email
			</Box>
			<Box fontFamily="body" mb="2">
				{details.email}
			</Box>
		</Box>
	);
};
