import React, { useEffect, useState, useRef } from 'react';
import dayjs from 'dayjs';
import { Button } from '@chakra-ui/react';
import { config } from '../../../config';

/**
 *	Login with Browser component
 * @param {{
 * 	onTimeout: () => void,
 * 	onClick: () => void,
 * 	timeout?: number,
 * 	isLoading?: boolean,
 * 	buttonProps: import('@chakra-ui/button').ButtonProps,
 * }} param0
 * @returns
 */
const BrowserLogin = ({
	timeout = 30,
	onClick,
	onTimeout,
	isLoading,
	buttonProps,
}) => {
	const loginClickTime = useRef('');
	const [loadingText, setLoadingText] = useState('');

	/**
	 * Opens login page on browser
	 */
	const handleBrowserLoginBtnClick = () => {
		loginClickTime.current = dayjs().toISOString();
		onClick();
		window.open(`${config.app.BASEURL}/login?desktopRedirect=true`, '_blank');
	};

	useEffect(() => {
		const updateLoadingText = () => {
			/** time remaining in seconds before timeout */
			const remainingSeconds =
				timeout - dayjs().diff(loginClickTime.current, 'seconds');
			if (remainingSeconds && remainingSeconds > 0) {
				setLoadingText(`Waiting for login...(${remainingSeconds}s)`);
				setTimeout(updateLoadingText, 1000);
			} else {
				loginClickTime.current = '';
				onTimeout();
				clearTimeout(updateLoadingText);
			}
		};
		if (isLoading) updateLoadingText();
		return () => {
			clearTimeout(updateLoadingText);
		};
	}, [isLoading, onTimeout, timeout]);

	return (
		<Button
			size="md"
			bg="purple.500"
			color="white"
			fontSize="0.8rem"
			_hover={{
				bg: 'purple.400',
			}}
			_active={{
				bg: 'purple.300',
			}}
			loadingText={loadingText}
			{...buttonProps}
			isLoading={isLoading}
			onClick={handleBrowserLoginBtnClick}
		>
			Login with Browser
		</Button>
	);
};

export default BrowserLogin;
