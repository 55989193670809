import { schema, normalize } from 'normalizr';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { nativePseudoBrain } from './nativeCommands';

/**
 * Custom Hooke that returns fn(s) for normalizing entities inside the brain
 * */
const useNormalizedBrains = () => {
	const allBrains = useSelector((state) => state.brains);

	const brains = useMemo(() => {
		/** Add native pseudo brain to all brains */
		return {
			byId: {
				...allBrains.byId,
				[nativePseudoBrain._id]: {
					...nativePseudoBrain,
				},
			},
			allIds: [...new Set([...allBrains.allIds, nativePseudoBrain._id])],
		};
	}, [allBrains.allIds, allBrains.byId]);

	const getNormalizedBrains = useCallback(() => {
		const componentsSchema = new schema.Entity(
			'components',
			{},
			{
				idAttribute: (value, parent, key) =>
					/** generate ID based on intent endpoint and query endpoint (if available)
					 * Here parent is the intent obj from which the component is extracted
					 */
					parent.endpoint
						? value.endpoint
							? `${value['value']}-${parent.endpoint}-${value.endpoint}`
							: `${value['value']}-${parent.endpoint}`
						: value['value'],
				processStrategy: (value, parent, key) => {
					return {
						id: parent.endpoint
							? value.endpoint
								? `${value['value']}-${parent.endpoint}-${value.endpoint}`
								: `${value['value']}-${parent.endpoint}`
							: value['value'],
						entity: 'components',
						...value,
					};
				},
			}
		);
		const intentSchema = new schema.Entity(
			'intents',
			{
				components: [componentsSchema],
				endpoint: new schema.Values('endpoint'),
			},
			{
				// set id based on native/normal intent
				idAttribute: (value, parent, key) =>
					parent._id === 'nativePseudoBrain'
						? `${parent._id}-${value.endpoint}`
						: value.endpoint.substring(8),
				processStrategy: (value, parent, key) => {
					return {
						// set id based on native/normal intent
						id:
							parent._id === 'nativePseudoBrain'
								? `${parent._id}-${value.endpoint}`
								: value.endpoint.substring(8),
						entity: 'intents',
						type: parent._id === 'nativePseudoBrain' ? 'native' : 'http', // if nativePseudoBrain set `native` else `http`
						baseUrl: parent.url,
						brainId: parent._id,
						brainStatus: parent.status,
						...value,
					};
				},
			}
		);
		const brainsSchema = new schema.Entity(
			'brains',
			{
				intents: [intentSchema],
				// modules: [
				// 	new schema.Entity('modules', {}, { idAttribute: 'module' }),
				// ],
				// publishedSkillPacks: [
				// 	new schema.Entity(
				// 		'publishedSkillPacks',
				// 		{},
				// 		{ idAttribute: 'publishedSkillPack' }
				// 	),
				// ],
				// flows: [new schema.Entity('flows')],
			},
			{
				idAttribute: '_id',
				processStrategy: (value, parent, key) => {
					return {
						id: value._id,
						...value,
					};
				},
			}
		);
		/**
		 * @type {{
		 * 	entities: {
		 * 		components: {
		 * 			[key: string]: any
		 * 		},
		 * 		intents: {
		 * 			[key: string]: any
		 * 		},
		 * 		brains: {
		 * 			[key: string]: any
		 * 		},
		 * 	},
		 * 	result: string[]
		 * }}
		 */
		const normalizedOutput = normalize(brains.byId, [brainsSchema]);

		return normalizedOutput;
	}, [brains.byId]);

	return { getNormalizedBrains };
};

export default useNormalizedBrains;
