import _ from 'lodash';
//Functions
import getModuleList from '../../functions/modules/getModuleList';

const getIncludedModules = (flow) => {
	let moduleArray = _.map(flow, (n) => {
		return n.module;
	});
	return _.compact(_.uniq(moduleArray));
};

const generateId = (length) => {
	var result = [];
	var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result.push(
			characters.charAt(Math.floor(Math.random() * charactersLength))
		);
	}
	return result.join('');
};

const createSkillPackFromFlow = (flow, slug, prompt) => {
	return new Promise(async (resolve, reject) => {
		var moduleArray = await getIncludedModules(flow);
		// console.log("ARRAY", moduleArray);
		var modules = await getModuleList(moduleArray);
		//console.log("Module: ", modules);
		var moduleList = modules.map((module) => {
			return {
				module: module,
				included: true,
				name: module.packageName,
				version: module.currentVersion,
				_id: module._id,
			};
		});
		//console.log("ModuleList", moduleList);
		var flowId = generateId(24);
		//console.log("FlowId", flowId);
		var packTemplate = {
			createdAt: '2021-04-20T19:18:22.975Z',
			currentVersion: '0.1',
			currentVersionDetails: {
				published: false,
				version: '0.1',
				flow: {
					_id: flowId,
					tabs: [],
					flowJSON: flow,
					modules: moduleList,
					intents: [],
					createdAt: '2021-04-20T19:18:22.975Z',
					createdBy: slug,
				},
			},
			_id: generateId(24),
			description: _.truncate(prompt, {
				length: 25,
				separator: /,? +/,
			}),
			installs: 1,
			name: 'Generated Skill Workflow',
			thumbnail:
				'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/static-1618943525569.jpg',
			updatedAt: '2021-04-20T19:18:22.975Z',
			versions: [
				{
					published: false,
					version: '0.1',
					flow: {
						_id: flowId,
						tabs: [],
						flowJSON: flow,
						modules: moduleList,
						intents: [],
						createdAt: '2021-04-20T19:18:22.975Z',
						createdBy: slug,
					},
				},
			],
		};
		//console.log("PackTemplate", packTemplate);
		resolve(packTemplate);
	});
};

export default createSkillPackFromFlow;
