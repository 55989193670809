// Config object

require('dotenv').config();
// console.log('config object', process.env);
const config = {
	settings: {
		teamEnabled: false,
		storeEnabled: true,
		publishEnabled: false,
	},
	fusionAuth: {
		// FusionAuth URL: http://localhost:9011
		BASEURL: process.env.REACT_APP_FUSIONAUTH_BASEURL,
		// Application ID from FusionAuth
		APPLICATION_ID: process.env.REACT_APP_FUSIONAUTH_APPLICATION_ID,
		IDENTITY_PROVIDER_ID:
			process.env.REACT_APP_FUSIONAUTH_IDENTITY_PROVIDER_ID,
		API_KEY: process.env.REACT_APP_FUSIONAUTH_API_KEY,
	},
	apiServer: {
		// API Server URL: http://localhost:5000
		BASEURL:
			process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_BACKEND
				: process.env.REACT_APP_BACKEND_PROD,
	},
	app: {
		// Name for the 2Factor application: FusionAuth
		TWO_FA_NAME: 'AppName',
		BASEURL:
			process.env.NODE_ENV === 'development'
				? `http://localhost:3000`
				: process.env.REACT_APP_BACKEND_PROD.includes('prod')
				? 'https://app.mayahq.com'
				: 'https://devapp.mayahq.com',
		VERSION: require('../../package.json').version,
		APP_URL_STAGING: process.env.APP_URL_STAGING,
		APP_URL_PROD: process.env.APP_URL_PROD,
		CMS_BACKEND: process.env.CMS_BACKEND || 'https://cms.prod.mayahq.com',
		WEBSITE_URL: process.env.NODE_ENV === 'development' ? 'https://maya-landing-page-git-stripeapp-mayahq.vercel.app' : 'https://mayahq.com'
	},
	payment: {
		razorpay: {
			KEY_ID: process.env.REACT_APP_RAZORPAY_TEST_KEY_ID,
			checkoutTimeout: process.env.REACT_APP_RAZORPAY_CHECKOUT_TIMEOUT,
		},
		enabled: process.env.REACT_APP_PAYMENTS_ENABLED,
		// Name for the 2Factor application: FusionAuth
	},
	mixpanel: {
		TOKEN:
			process.env.NODE_ENV === 'development'
				? process.env.REACT_APP_MIXPANEL_DEV
				: process.env.REACT_APP_MIXPANEL_PROD,
	},
	reservedPaths: JSON.parse(process.env.REACT_APP_RESERVED_ROUTES),
};

// Export the application config.
export default config;
