import React from 'react';
import { AiFillAppstore } from 'react-icons/ai';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';

const AppTypeSearch = ({
	placeholderText: text,
	placeholderType: type,
	currentPlaceholderId,
	setPlaceholderSearchItems,
	setSearchBarLoadingStatus,
	searchItems,
	selectedIndex,
}) => {
	const [allItems, setAllItems] = React.useState([
		{
			name: 'Visual Studio Code',
			path: 'Applications/VisualStudioCode/Resources',
		},
		{
			name: 'Adobe Acrobat Reader',
			path: 'Applications/AdobeAcrobatReader/Resources',
		},
		{ name: 'Sublime Text', path: 'Applications/Sublime/Resources' },
	]);

	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.8, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['name', 'path'],
	};

	const fuse = new Fuse(allItems, options);

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * [{label : "", value : "", icon: ""},{label : "", value : "", icon: ""}]
	 * @param {{}} itemsArray
	 */

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return { show: thing.item.name, submit: thing.item.path };
		});
	};

	const onTextChange = (searchText) => {
		if (
			searchText &&
			_.findIndex(searchItems, function (o) {
				return o.show === searchText;
			}) === -1
		) {
			var results = fuse.search(searchText);
			setFilteredItems(results);
			setPlaceholderSearchItems(selectFunction(results));
		}
	};

	React.useEffect(() => {
		onTextChange(text);
	}, [text]);

	return filteredItems.map((contact, i) => {
		console.log(contact);
		return (
			<SearchItem
				selectedIndex={selectedIndex}
				key={i}
				i={i}
				searchItemMainText={contact.item.name}
				searchItemSubText={contact.item.path}
				reactIcon={<AiFillAppstore />}
			/>
		);
	});
};

export default AppTypeSearch;
