import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
import { store } from '../../redux/store';

const publishSkillPack = async (formData) => {
	delete formData.skillPackDetails;
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;
	const publishSkillPackAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.publishSkillPack.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.publishSkillPack.PATH_METHOD,
	});

	const publishSkillPackAPIResponse = await publishSkillPackAPIFn({
		profileSlug,
		...formData,
	});
	console.log('response for publishSkillPackAPI', publishSkillPackAPIResponse);
	return publishSkillPackAPIResponse;
};

export default publishSkillPack;
