import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import { Box, Image } from '@chakra-ui/react';
import 'react-alice-carousel/lib/alice-carousel.css';

import search from './../../../assets/swcarousel/searchByOptionSpace.png';
import infinite from './../../../assets/swcarousel/infinitelyExtensible.png';
import share from './../../../assets/swcarousel/shareWithTeam.png';
import store from './../../../assets/swcarousel/discoverAndShare.png';
import anything from './../../../assets/swcarousel/automateAnything.png';

const SlideComponent = ({ src }) => {
	return (
		<Box display="flex" alignItems="center">
			<Image src={src} width="full" paddingRight="20" />
		</Box>
	);
};

const Slide1 = () => {
	return <SlideComponent src={search} />;
};
const Slide2 = () => {
	return <SlideComponent src={infinite} />;
};

const Slide3 = () => {
	return <SlideComponent src={anything} />;
};

const Slide4 = () => {
	return <SlideComponent src={store} />;
};

const Slide5 = () => {
	return <SlideComponent src={share} />;
};

const SliderCarousel = () => {
	let slides = {
		1: <Slide1 />,
		2: <Slide2 />,
		3: <Slide3 />,
		4: <Slide4 />,
		5: <Slide5 />,
	};
	let state = {
		galleryItems: [1, 2, 3, 4, 5].map((i) => slides[i]),
	};

	let responsive = {
		0: { items: 1 },
		1024: { items: 1 },
	};

	const onSlideChange = (e) => {
		console.debug('Item`s position during a change: ', e.item);
		console.debug('Slide`s position during a change: ', e.slide);
	};

	const onSlideChanged = (e) => {
		console.debug('Item`s position after changes: ', e.item);
		console.debug('Slide`s position after changes: ', e.slide);
	};

	return (
		<AliceCarousel
			items={state.galleryItems}
			responsive={responsive}
			buttonsDisabled={true}
			dotsDisabled={true}
			autoPlayInterval={4000}
			autoPlayDirection="ltr"
			autoPlay={true}
			fadeOutAnimation={true}
			mouseTrackingEnabled={true}
			playButtonEnabled={false}
			disableAutoPlayOnAction={false}
			onSlideChange={onSlideChange}
			onSlideChanged={onSlideChanged}
		/>
	);
};

export default SliderCarousel;
