import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';
import { store } from '../../redux/store';

const updatePublishedSkillPack = async (formData) => {
	console.log(formData);
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;
	const updatePublishedSkillPackAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.updatePublishedSkillPack.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.updatePublishedSkillPack.PATH_METHOD,
	});

	const updatePublishedSkillPackAPIResponse =
		await updatePublishedSkillPackAPIFn({
			profileSlug,
			...formData,
		});
	return updatePublishedSkillPackAPIResponse;
};

export default updatePublishedSkillPack;
