import React from 'react';
import { AiFillCalendar } from 'react-icons/ai';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';
import * as chrono from 'chrono-node';

const mergeValues = (values) => {
	for (let key in values.impliedValues) {
		values.knownValues[key] = values.impliedValues[key];
	}

	return values.knownValues;
};

export const formatDate = (values) => {
	let monthArray = {
		1: 'January',
		2: 'February',
		3: 'March',
		4: 'April',
		5: 'May',
		6: 'June',
		7: 'July',
		8: 'August',
		9: 'September',
		10: 'October',
		11: 'November',
		12: 'December',
	};

	let stringArray = {
		day: values.knownValues.day
			? values.knownValues.day
			: values.impliedValues.day,
		month: monthArray[
			values.knownValues.month
				? values.knownValues.month
				: values.impliedValues.month
		],
		hour: values.knownValues.hour
			? values.knownValues.hour.toString()
			: values.impliedValues.hour
			? values.impliedValues.hour.toString()
			: '0',
		minute: values.knownValues.minute
			? values.knownValues.minute.toString()
			: values.impliedValues.minute
			? values.impliedValues.minute.toString()
			: '0',
	};

	if (stringArray.hour.length === 1) {
		stringArray.hour = '0' + stringArray.hour;
	}
	if (stringArray.minute.length === 1) {
		stringArray.minute = '0' + stringArray.minute;
	}

	return (
		stringArray.day +
		' ' +
		stringArray.month +
		' ' +
		stringArray.hour +
		stringArray.minute +
		'hrs'
	);
};

const TimeTypeSearch = ({
	placeholderText: text,
	placeholderType: type,
	currentPlaceholderId,
	setPlaceholderSearchItems,
	setSearchBarLoadingStatus,
	searchItems,
	selectedIndex,
}) => {
	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is time
	 * @param {} array
	 */

	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return {
				show: formatDate(thing.start),
				submit: mergeValues(thing.start),
			};
		});
	};

	const onTextChange = (searchText) => {
		if (
			searchText &&
			_.findIndex(searchItems, function (o) {
				return o.show === searchText;
			}) === -1
		) {
			console.log('Chrono:', chrono.parse(searchText));
			var results = chrono.parse(searchText);
			if (results.length > 0) {
				setFilteredItems([
					{
						main: formatDate(results[0].start),
						subtext: 'Formatted Time',
					},
				]);
				setPlaceholderSearchItems(selectFunction(results));
			} else {
				setFilteredItems([
					{
						main: 'Enter a valid time',
						subtext: `e.g "tomorrow 3pm" or "12 Sept 4.30pm" or "next Friday"`,
					},
				]);
				setPlaceholderSearchItems([]);
			}
		}
	};

	React.useEffect(() => {
		onTextChange(text);
	}, [text]);

	return filteredItems.map((thing, i) => {
		return (
			<SearchItem
				selectedIndex={selectedIndex}
				key={i}
				i={i}
				searchItemMainText={thing.main}
				searchItemSubText={thing.subtext}
				reactIcon={<AiFillCalendar />}
			/>
		);
	});
};

export default TimeTypeSearch;
