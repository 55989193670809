import React from 'react';
import {
	Box,
	Image,
	useColorMode,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import _ from 'lodash';

import { GoVerified } from 'react-icons/go';
import { FiGitPullRequest, FiDownload, FiCommand } from 'react-icons/fi';
import { useLocation } from 'react-router-dom';
//Components

import HorizontalItem from '../../Reusable/HorizontalItem';
import TagWithIcon from '../../Reusable/TagWithIcon';
import CommandList from '../../Commands/CommandList';
import getPublishedSkillpackGquery from '../../../functions/publishedSkillPack/getPublishedSkillpackGquery';
import { StorePackNewCard } from './StorePackNewCard';

import {
	SkillsButton,
	InstallButton,
	UninstallButton,
} from './StorePackButtons';
import { ItemCard } from '../../Reusable/HorizontalBoxCardWithDescription';
import { StorePackModal } from './StorePackModal';

export const StorePackItemLeft = ({
	publishedSkillPack,
	onClick,
	isInstalledGlobal,
}) => {
	const { colorMode } = useColorMode();

	const rating = publishedSkillPack.rating ? publishedSkillPack.rating : 4;

	return (
		<Box display="flex" alignItems="center" onClick={onClick} height="100%">
			<Box
				height="100%"
				display="flex"
				alignItems="center"
				paddingX="5px"
				width="160px"
				justifyContent="center"
				ml="1"
			>
				<Image
					src={
						publishedSkillPack.thumbnail ||
						'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
					}
					objectFit="cover"
					borderRadius="5px"
				/>
			</Box>
			<Box ml="1rem">
				<Box
					color="#555555"
					display="flex"
					flexDirection="row"
					alignItems="center"
				>
					<Box
						fontSize="20px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
					>
						{publishedSkillPack.name}
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Box
						fontSize="17px"
						color={useColorModeValue('#6C6C6C', 'white')}
						fontFamily="body"
						opacity="0.7"
					>
						{_.truncate(publishedSkillPack.shortDescription, {
							length: 75,
							separator: /,? +/,
						})}
					</Box>
				</Box>
				<Box
					color="#555555"
					// fontSize="1rem"
					display="flex"
					flexDirection="row"
					alignItems="center"
					mt="8px"
				>
					<TagWithIcon text={'Maya Official'} icon={<GoVerified />} />

					{process.env.NODE_ENV === 'development' ? (
						<TagWithIcon
							text={publishedSkillPack.currentVersion}
							icon={<FiGitPullRequest />}
						/>
					) : null}

					<TagWithIcon
						text={`${publishedSkillPack?.currentFlow?.intents?.length} skills`}
						icon={<FiCommand />}
					/>
					{isInstalledGlobal ? (
						<TagWithIcon text={'installed'} icon={<FiDownload />} />
					) : null}
				</Box>
			</Box>
		</Box>
	);
};

// <TagWithIcon
//             text={`${publishedSkillPack.installs} installs`}
//             icon={<FiDownload />}
//           />
//           <TagWithIcon text={rating} icon={<AiFillStar />} />

export const StorePackItem = ({
	publishedSkillPack,
	skillsButton,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	addButton,
	rightItem,
	isInstalled,
	isInstalledGlobal,
	updateBrainById,
}) => {
	const [hoveredButton, setHoveredButton] = useState('');
	const [isOpen, setIsOpen] = useState(isItemExpanded);

	React.useEffect(() => {
		setIsOpen(isItemExpanded);
	}, [isItemExpanded]);

	const toggleOpen = () => setIsOpen(!isOpen);

	return (
		<HorizontalItem
			isOpen={isOpen}
			onClickItem={
				wholeItemActive
					? () => {
							toggleOpen();
							onClick(publishedSkillPack._id);
					  }
					: () => onClick(publishedSkillPack._id)
			}
			selected={selected}
			itemLeft={
				<StorePackItemLeft
					publishedSkillPack={publishedSkillPack}
					onClick={() => {
						toggleOpen();
						onClick(publishedSkillPack._id);
					}}
					isInstalled={isInstalled}
					isInstalledGlobal={isInstalledGlobal}
				/>
			}
			buttons={
				<Box display="flex">
					{skillsButton === false ? null : (
						<SkillsButton
							onClick={() => {
								toggleOpen();
								onClick(publishedSkillPack._id);
							}}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
						/>
					)}
					{addButton === false ? null : !isInstalled ? (
						<InstallButton
							onClick={() => {}}
							publishedSkillPack={publishedSkillPack}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							currentBrainId={currentBrainId}
							updateBrainById={updateBrainById}
						/>
					) : (
						<UninstallButton
							onClick={() => {
								toggleOpen();
								onClick(publishedSkillPack._id);
							}}
							publishedSkillPack={publishedSkillPack}
							setHoveredButton={setHoveredButton}
							hoveredButton={hoveredButton}
							currentBrainId={currentBrainId}
							updateBrainById={updateBrainById}
						/>
					)}

					{rightItem}
				</Box>
			}
			isItemExpanded={isItemExpanded}
			expandedContent={
				<Box
					display="flex"
					justifyContent="space-between"
					flexDir="column"
					paddingLeft="11rem"
				>
					<CommandList
						commands={publishedSkillPack?.currentFlow?.intents}
					/>
				</Box>
			}
		/>
	);
};

export const StorePackItemCard = ({
	publishedSkillPack,
	skillsButton,
	onClick,
	selected,
	wholeItemActive,
	isItemExpanded,
	currentBrainId,
	addButton,
	rightItem,
	isInstalled,
	isInstalledGlobal,
	updateBrainById,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isLoading, setLoading] = React.useState(false);
	const [currentFlow, setCurrentFlow] = React.useState({});
	React.useEffect(() => {
		setLoading(true);
		if (isOpen) {
			getPublishedSkillpackGquery({ _id: publishedSkillPack._id })
				.then((res) => {
					setCurrentFlow(res.data.skillpacks[0]?.currentFlow);
					setLoading(false);
				})
				.catch((err) => console.log(err));
		}
	}, [isOpen, onOpen]);
	const loc = useLocation();
	let query = new URLSearchParams(loc.search);
	let id = query.get('id');
	React.useEffect(() => {
		if (id === publishedSkillPack._id) {
			onOpen();
		}
	}, [id]);

	return (
		<Box>
			<StorePackNewCard
				skillpack={publishedSkillPack}
				onOpen={onOpen}
				isInstalledGlobal={isInstalledGlobal}
				isInstalled={isInstalled}
			/>
			{/* <ItemCard
				key={publishedSkillPack._id}
				thumbnail={publishedSkillPack.thumbnail}
				heading={publishedSkillPack.name}
				description={publishedSkillPack.shortDescription}
				count={currentFlow.intents?.length}
				moduleDependencies={currentFlow?.modules || []}
				apps={publishedSkillPack.apps}
				isInstalledGlobal={isInstalledGlobal}
				onClick={() => {
					onOpen();
				}}
				scale={0.75}
				devTag={true}
				s
				isActive={isInstalled}
			/> */}

			{isOpen ? (
				<StorePackModal
					isOpen={isOpen}
					onClose={onClose}
					publishedSkillPack={publishedSkillPack}
					currentFlow={currentFlow}
					currentBrainId={currentBrainId}
					isLoading={isLoading}
				/>
			) : null}
		</Box>
	);
};

//
