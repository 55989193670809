import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	useQuery,
	gql,
} from '@apollo/client';

import { createStandaloneToast } from '@chakra-ui/toast';
import { config } from '../../config';

//redux
import { store } from '../../redux/store';
import { setLoggedIn } from '../../redux/actions';

import customTheme from '../../theme';

import History from '../History';

import GetProfileSlug from '../ProfileSlug';

/**
 * Graph QL fetch utility
 *
 * A simple to use api interface to query Maya CMS with graphql
 */
const GQLFetch = ({
	locale = 'en',
	BASEURL,
	gquery,
	initialData = null,
	params,
	status = 'online',
	contentType = 'application/json',
}) => {
	// Setup initial state
	let uri = BASEURL;
	const results = initialData;
	const hasError = null;
	const Authorization = config.fusionAuth.API_KEY;
	const finalQuery = gql(gquery);
	// const query = gquery;

	// let slug;
	// try {
	// 	slug = GetProfileSlug().slug;
	// } catch (err) {
	// 	slug = null;
	// }

	const toast = createStandaloneToast({ theme: customTheme });

	const fetchResults = () => {
		if (status === 'local') {
			return;
		}

		// let headers;
		// // Setup headers for the request.
		// if (slug) {
		// 	headers = {
		// 		locale,
		// 		profileSlug: slug,
		// 	};
		// } else {
		// 	headers = {
		// 		locale,
		// 	};
		// }

		return new Promise((resolve, reject) => {
			const client = new ApolloClient({
				uri: uri,
				cache: new InMemoryCache(),
			});
			client
				.query({
					query: finalQuery,
					variables: params,
				})
				.then((result) => resolve(result))
				.catch(({ response }) => {
					// Check if the response requires the user to log in again.
					if (response) {
						if (response.data.loginAgain) {
							// Let the user know they cannot access the page.
							console.log('Error:', response.data.message);
							console.log('going to login');
							store.dispatch(setLoggedIn(false));
							/** Sending the user to the login page. */
							/** get current location */
							const currentPathWithoutDomain = `${History.location.pathname}${History.location.search}${History.location.hash}`;
							History.push('/login', {
								restoreLocation: currentPathWithoutDomain, // pass it via state to be restored after login
							});
						} else if (
							Object.keys(response.data).includes('emailVerified') &&
							!response.data.emailVerified
						) {
							History.push('/verify');
						} else {
							// Return from the promise with the error from the API request.
							reject(response);
						}
					} else {
						toast({
							title: 'An error occurred.',
							description:
								'We were unable to connect to the mothership.',
							status: 'error',
							duration: 2000,
							isClosable: true,
						});
					}
				});
		});
	};

	// Return the useful data and functions from the API Fetch utility.
	return fetchResults;
};

export default GQLFetch;
