import React, { useEffect } from 'react';
import isElectron from 'is-electron';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	LightMode,
	useDisclosure,
	Button,
	useToast,
	useColorModeValue,
	Box,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';

// Redux
import { connect } from 'react-redux';
import { setCurrentDevice, setDevices } from '../../redux/actions';

// Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import InputComponent from '../Reusable/Input';

// Functions
import checkDevice from '../../functions/devices/checkDevice';
import registerDevice from '../../functions/devices/registerDevice';
import getDevices from '../../functions/devices/getDevices';
import getMachineDetails from '../../functions/devices/getMachineDetails';
import { normalize } from '../../util/Misc';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

const CheckOrRegisterDevice = ({ setCurrentDevice, setDevices }) => {
	const toast = useToast();
	const [machineDetails, setMachineDetails] = React.useState({});
	const [deviceName, setDeviceName] = React.useState('');
	const { isOpen, onOpen, onClose } = useDisclosure(false);
	const initialRef = React.useRef();
	var location = useLocation();

	const setUserDevices = async () => {
		getDevices().then((response) => {
			if (response.results) {
				const devices = normalize(response.results);
				console.log('Setting redux store for listing devices', devices);
				setDevices(devices);
			}
		});
	};

	const checkDeviceExists = async (machineID) => {
		const formData = {
			machineID: machineID,
		};
		const response = await checkDevice(formData);
		console.log('Checking device', response);
		if (response.results.status) {
			setCurrentDevice(response.results.device);
			setUserDevices();
			onClose();
		} else if (
			!location.pathname.includes('login') ||
			!location.pathname.includes('register')
		) {
			onOpen();
		}
	};

	const registerCurrentDevice = async () => {
		const formData = {
			...machineDetails,
			name: deviceName,
		};
		registerDevice(formData)
			.then((res) => {
				toast({
					title: 'Device Created',
					description: res.message,
					status: 'success',
					duration: 3000,
					isClosable: false,
				});
				onClose();
			})
			.catch((e) => {
				toast({
					title: 'Error Occurred.',
					description: e.data.message,
					status: 'error',
					duration: 3000,
					isClosable: false,
				});
			});
	};

	useEffect(() => {
		const getMachineID = async () => {
			// TODO : get from ipcMain
			getMachineDetails().then((res) => {
				if (res) {
					let machineId = res.machineID;
					setMachineDetails(machineId);
					checkDeviceExists(machineId);
				}
			});
		};
		if (isElectron) {
			getMachineID();
		}
	}, [location]);

	const handleClose = async () => {
		if (isElectron()) {
			getMachineDetails().then((res) => {
				if (res) {
					let machineId = res.machineID;
					setMachineDetails(machineId);
					checkDeviceExists(machineId);
					onClose();
				}
			});
		}
	};

	return (
		<>
			<CustomModal
				isOpen={isOpen}
				initialFocusRef={initialRef}
				isCentered={true}
			>
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					height="15rem"
					maxWidth="30rem"
					borderRadius={5}
					pb={5}
				>
					<HeaderWithDescription
						header={'Register New Device'}
						desc={`Name this new device so you can install skills on it.`}
						paddingX="1rem"
						paddingY="1rem"
					/>

					<ModalBody paddingBottom="0rem" paddingY="1rem" paddingX="0rem">
						<Box paddingX="1rem">
							<InputComponent
								placeholder="Enter device name here"
								paddingY="1.25rem"
								onChange={(event) => {
									setDeviceName(event.target.value);
								}}
							/>
						</Box>
						<Box
							height="3rem"
							alignItems="center"
							display="flex"
							marginTop="3rem"
						>
							<LightMode>
								<Button
									colorScheme="purple"
									size="xs"
									ml="1rem"
									borderRadius="1rem"
									variant={'solid'}
									paddingX="0.9rem"
									loadingText={'Installing...'}
									onClick={registerCurrentDevice}
								>
									Register
								</Button>
							</LightMode>
						</Box>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

const mapStateToProps = (state) => {
	const { devices } = state;
	return { devices };
};

export default connect(mapStateToProps, { setCurrentDevice, setDevices })(
	CheckOrRegisterDevice
);
