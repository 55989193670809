import React from 'react';
import { Box, useColorMode, Image, Text } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';

const iconColor = {
	light: '#6C6C6C',
	dark: '#FFFEFE',
};

const DefaultSuggestionIcon = () => {
	const { colorMode } = useColorMode();
	return (
		<Box
			borderRadius="13px"
			width="22px"
			height="22px"
			display="flex"
			justifyContent="center"
			alignItems="center"
			bg="#434343"
			transform="translateX(-1px)"
		>
			<ChevronRightIcon size="20px" color={iconColor[colorMode]} />
		</Box>
	);
};

export default DefaultSuggestionIcon;
