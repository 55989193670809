import React from 'react';
import {
	Button,
	Modal,
	Box,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	useDisclosure,
	useColorModeValue,
	LightMode,
} from '@chakra-ui/react';
import isElectron from 'is-electron';
import _ from 'lodash';

import { GoKey } from 'react-icons/go';

//Components
import HeaderWithDescription from '../../Reusable/HeaderWithDesc';
import Dropdown from '../../Reusable/Dropdown';
import { HorizontalItemButton } from '../../Reusable/HorizontalItem';

//functions
import authenticateService from '../../../functions/contacts/authenticateService';
import listContactServices from '../../../functions/contacts/listContactServices';
import getContacts from '../../../functions/contacts/getContacts';
import CustomModal from '../../Reusable/ChakraCustom/CustomModal';

/**
 *
 * @param {*} param0
 */

const ImportContacts = ({
	setHoveredButton,
	hoveredButton,
	serviceDetails,
	setContacts,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	// const [allServices, setAllServices] = React.useState([]);
	const selectedService = serviceDetails;
	const handleImport = () => {
		const configDetails = serviceDetails;
		authenticateService({ configDetails }).then((res) => {
			getContacts().then((response) => {
				setContacts(response);
			});
		});

		onClose();
	};

	// React.useEffect(() => {
	//   listContactServices().then((res) => {
	//     let newArray = _.map(res, (item) => {
	//       return { label: item.name, value: item.resource };
	//     });
	//     setAllServices(newArray);
	//   });
	// }, []);

	return (
		<>
			<HorizontalItemButton
				icon={<GoKey />}
				buttonKey="auth"
				text={'auth'}
				onClick={() => {
					onOpen();
				}}
				setHoveredButton={setHoveredButton}
				hoveredButton={hoveredButton}
			/>
			<CustomModal
				onClose={onClose}
				isOpen={isOpen}
				isCentered={isElectron() ? true : false}
			>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					height={'10rem'}
					maxWidth="35rem"
				>
					<ModalCloseButton
						right="2"
						border="none"
						cursor
						_focus={{ outline: 'none' }}
					/>
					<ModalBody paddingX="0">
						<HeaderWithDescription
							header={`Import Contacts`}
							desc={`Authorize Maya to import and sync contact from  ${selectedService.name}`}
							paddingX="1rem"
						/>
						<Box
							paddingX="1rem"
							height="1.5rem"
							alignItems="center"
							display="flex"
						>
							<LightMode>
								<Button
									colorScheme="purple"
									size="sm"
									mr="1"
									mt="12"
									borderRadius="5px"
									variant={'solid'}
									paddingX="0.9rem"
									onClick={handleImport}
									loadingText={'Installing...'}
								>
									Authenticate & Import
								</Button>
							</LightMode>
						</Box>
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

export default ImportContacts;
