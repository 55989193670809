import React, { useEffect } from 'react';
import {
	Box,
	LightMode,
	Switch,
	Tag,
	useDisclosure,
	Button,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogOverlay,
	AlertDialogHeader,
	AlertDialogFooter,
	useColorModeValue,
	Input,
	Code,
} from '@chakra-ui/react';
import { connect, useSelector } from 'react-redux';
import isElectron from 'is-electron';
//Icons
import { MdDataUsage } from 'react-icons/md';

//Components
import SettingsItem from './SettingsItem';
import PaymentsModal from '../../Payments';

//Functions
import getUsage from '../../../functions/settings/usage/getUsage';
import getStatus from '../../../functions/settings/autoStart/getStatus';
import toggleStatus from '../../../functions/settings/autoStart/toggleStatus';
import resetDefaults from '../../../functions/settings/reset';
import useQueryParams from '../../../util/useQueryParams';
import History from '../../../util/History';
import { useLocation } from 'react-router-dom';
import { TrialExpiredModal } from '../../Payments/TrialExpiredModal';

const AutoStartItem = () => {
	const [autoStartStatus, setAutoStartStatus] = React.useState(true);
	React.useEffect(() => {
		getStatus().then((res) => {
			setAutoStartStatus(res);
		});
	}, [autoStartStatus]);
	const handleToggle = () => {
		toggleStatus().then((res) => {
			setAutoStartStatus(res);
		});
	};
	return (
		<SettingsItem
			text={'Auto Start on Boot Up'}
			icon={<MdDataUsage />}
			rightItem={
				<LightMode>
					<Switch
						id="auto-start"
						mr="4"
						colorScheme={'purple'}
						isChecked={autoStartStatus}
						onChange={handleToggle}
					/>
				</LightMode>
			}
		/>
	);
};

const UsageItem = ({ brains }) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const query = useQueryParams();
	const { pathname } = useLocation();
	const checkout = query.get('checkout');
	const [details, setDetails] = React.useState({
		nodes: 0,
		upperLimit: 0,
		percentage: 0,
	});
	const { tier } = useSelector((store) => store.profiles.selected);

	React.useEffect(() => {
		getUsage().then((res) => {
			setDetails(res);
		});
	}, [brains]);

	function handleClick() {
		if (tier === 'FREE') {
			onOpen()
		}
	}

	return (
		<>
			<SettingsItem
				text={'Usage Plan'}
				icon={<MdDataUsage />}
				rightItem={
					<Box
						fontWeight="600"
						mr="4"
						fontSize="0.8rem"
						display="flex"
						alignItems="center"
					>
						{/* {details.nodes}/
						{tier === 'PREMIUM' ? (
							<Text fontSize="xl" mr="1">
								∞
							</Text>
						) : (
							<Text mr="1">{details.upperLimit}</Text>
						)}
						nodes used */}
						<Tag size={'sm'} variant="subtle" ml="2" colorScheme="purple">
							{tier === 'FREE' ? 'FREE PLAN' : 'PREMIUM PLAN'}
						</Tag>
					</Box>
				}
				onClick={handleClick}
			/>
			<TrialExpiredModal
				isOpen={isOpen}
				onClose={onClose}
				trialExpired={false}
			/>
		</>
	);
};

const mapStateToPropsUsageItem = (state) => {
	const { brains } = state;
	return { brains };
};

const ConnectedUsageItem = connect(mapStateToPropsUsageItem, null)(UsageItem);

const ResetItem = () => {
	const [isOpen, setIsOpen] = React.useState(false);
	const [isResetting, setResettingStatus] = React.useState(false);
	const onClose = () => setIsOpen(false);
	const [confirmInput, setConfirmInput] = React.useState('');
	const cancelRef = React.useRef();

	const resetDefaultsFn = () => {
		setResettingStatus(true);
		resetDefaults().then(() => {
			setResettingStatus(false);
		});
	};

	return (
		<>
			<SettingsItem
				text={'Reset Defaults'}
				icon={<MdDataUsage />}
				rightItem={
					<LightMode>
						<Button
							colorScheme="purple"
							size="xs"
							marginRight="1rem"
							onClick={() => {
								setResettingStatus(false);
								setIsOpen(true);
							}}
						>
							Reset
						</Button>
					</LightMode>
				}
				onClick={() => {}}
			/>
			<AlertDialog
				isOpen={isOpen}
				leastDestructiveRef={cancelRef}
				onClose={onClose}
				fontFamily="body"
				isCentered={isElectron()}
				bg={useColorModeValue('white', 'maya_dark.300')}
			>
				<AlertDialogOverlay>
					<AlertDialogContent
						bg={useColorModeValue('white', 'maya_dark.300')}
					>
						<AlertDialogHeader
							fontSize="lg"
							fontWeight="bold"
							fontFamily="body"
						>
							Reset To Defaults?
						</AlertDialogHeader>

						<AlertDialogBody fontFamily="body">
							This action will permanently delete all workspaces,
							configurations and local settings on this machine and will
							restart the application.{' '}
							<Box marginTop="1rem">
								{' '}
								Enter the phrase <Code>RESET DEFAULTS</Code> below to
								confirm.
							</Box>
							<Input
								marginTop="1rem"
								focusBorderColor="grey.200"
								onChange={(e) => {
									setConfirmInput(e.currentTarget.value);
								}}
							/>
						</AlertDialogBody>

						<AlertDialogFooter fontFamily="body">
							<Button ref={cancelRef} onClick={onClose}>
								Cancel
							</Button>
							<LightMode>
								<Button
									colorScheme="red"
									onClick={resetDefaultsFn}
									ml={3}
									isLoading={isResetting}
									loadingText={'Resetting...'}
									isDisabled={confirmInput !== 'RESET DEFAULTS'}
								>
									Continue
								</Button>
							</LightMode>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialogOverlay>
			</AlertDialog>
		</>
	);
};

const GeneralSettings = () => {
	return (
		<Box>
			<AutoStartItem />
			<ConnectedUsageItem />
			<ResetItem />
		</Box>
	);
};

export default GeneralSettings;
