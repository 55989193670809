/**
 * Store Reducer
 *
 * Creates a Redux reducer for populating the stores.
 */

// Action Types
import actionTypes from '../actionTypes';

//Dependencies
import dotProp from 'dot-prop-immutable';
import _ from 'lodash';

// Setup initial state with an fleet info object.
const initialState = {
	searchString: '',
	type: { kind: 'type', name: 'Skills', checked: true },
	categories: [],
	platforms: [],
};

// Export the Store Reducer.
export default (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_STORE_SEARCH: {
			const { info } = action.payload;
			return { ...state, searchString: info };
		}
		case actionTypes.SET_STORE_TYPES: {
			const { info } = action.payload;
			if (info.checked) {
				state = dotProp.set(state, 'type', info);
			}
			return state;
		}
		case actionTypes.SET_STORE_CATEGORIES: {
			const { info } = action.payload;
			if (info.checked) {
				state = dotProp.set(state, 'categories', (list) => [...list, info]);
			} else {
				let index = _.findIndex(state.categories, function (o) {
					return o.name === info.name;
				});
				if (index !== -1) {
					state = dotProp.delete(state, `categories.${index}`);
				}
			}
			return state;
		}
		case actionTypes.SET_STORE_PLATFORMS: {
			const { info } = action.payload;

			if (info.checked) {
				state = dotProp.set(state, 'platforms', (list) => [...list, info]);
			} else {
				let index = _.findIndex(state.platforms, function (o) {
					return o.name === info.name;
				});
				if (index !== -1) {
					state = dotProp.delete(state, `platforms.${index}`);
				}
			}
			return state;
		}
		default:
			return state;
	}
};
