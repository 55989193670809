import React, { useContext, useEffect, useRef, useState } from 'react';
import dotProp from 'dot-prop-immutable';
import Search from './Search';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Box, useColorMode, Progress } from '@chakra-ui/react';
import { setSearchMode } from '../../../../../redux/actions/cardui';
import Voice from './Voice';
import { searchCancel } from '../../../../../redux/actions/cardUIV2';
import CommandBarContext from '../CommandBarContext';

const audioEnabled = true;

/**
 *
 * @param {{
 * 	display: 'expanded' | 'collapsed',
 * }} param0
 * @returns
 */
const SearchArea = ({ display }) => {
	const { setCurrentInputRef } = useContext(CommandBarContext);
	const [inputValue, setInputValue] = useState('');
	const { colorMode } = useColorMode();
	/**
	 * @type {React.MutableRefObject<HTMLInputElement>}
	 */
	const searchRef = useRef();
	const searchAreaMode = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.searchArea.mode
	);
	const dispatch = useDispatch();

	const placeholderLoading = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.searchArea.placeholderLoading
	);

	const bgColor = { light: 'card_ui.bg_light', dark: 'card_ui.bg_dark' };
	const dividerColor = {
		light: 'card_ui.divider_light',
		dark: 'card_ui.divider_dark',
	};

	const onCancel = () => {
		setInputValue('');
		dispatch(searchCancel());
		setTimeout(() => {
			searchRef.current.focus();
		}, 200);
	};

	useEffect(() => {
		/**
		 * UseEffect to set current input ref back to normal search input
		 * when mode is changed back to search from placeholder, and call focus
		 */
		if (searchAreaMode === 'search') {
			if (searchRef.current) {
				searchRef.current.focus();
				setCurrentInputRef(searchRef);
			}
		}
	}, [searchAreaMode, setCurrentInputRef]);

	return (
		<Box
			roundedTop="0.7rem"
			roundedBottom={display === 'expanded' ? '0rem' : '0.7rem'}
			bg={bgColor[colorMode]}
			overflow="hidden"
			borderColor="gray.200"
			width="100%"
			paddingTop="0.6rem"
			onClick={() => {
				return searchAreaMode === 'search'
					? searchRef.current.focus()
					: () => {};
			}}
			cursor="pointer"
			gridTemplateColumns="1fr 1fr"
			borderBottom={'solid 1px'}
			borderBottomColor={dividerColor[colorMode]}
		>
			<Box
				justifyContent="space-between"
				alignItems="center"
				display="flex"
				width="100%"
				marginBottom="0.5rem"
			>
				<Search
					myForwardedRef={searchRef}
					mode={searchAreaMode}
					// setMode={setMode}
					onCancel={onCancel}
					inputValue={inputValue}
					setInputValue={setInputValue}
				/>

				{audioEnabled && searchAreaMode === 'search' ? (
					<Voice setInputValue={setInputValue} />
				) : null}
			</Box>
			<Progress
				height="2px"
				isIndeterminate
				colorScheme="gray"
				opacity={placeholderLoading ? '0.3' : '0'}
				transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			/>
		</Box>
	);
};

export default SearchArea;
