import { store } from '../../redux/store';

const getCurrentPlaceholdersDetails = () => {
	let { cardUI } = store.getState();
	let intentObjectArray = cardUI.centerSearch.top.intentObjectArray;
	let placeholderArray = [];
	intentObjectArray.allIds.map((intentItemId) => {
		if (intentObjectArray.byId[intentItemId].type === 'placeholder') {
			placeholderArray.push(intentObjectArray.byId[intentItemId]);
		}
	});

	let returnObject = {
		placeholderArray,
		multiple: placeholderArray.length > 1,
	};

	return returnObject;
};

export default getCurrentPlaceholdersDetails;
