import React from 'react';
import { useToast } from '@chakra-ui/react';
//import { PublishStages } from "./Heading";
import PublishStages from './PublishStages';
import PublishPage from './PublishPage/PublishPage';
import SkillPackSelectPage from './SkillPackSelectPage';
import SkillPackDetailsPage from './SkillPackDetailsPage';
import { useLocation } from 'react-router-dom';

import getPublishedSkillPack from '../../functions/publishedSkillPack/getPublishedSkillPack';

const PublishSection = () => {
	const [stage, setStage] = React.useState(1);
	const [updateID, setUpdateID] = React.useState('');
	const toast = useToast();

	const [flowDetails, setFlowDetails] = React.useState({
		pricingType: 'free',
		previews: [],
		visibilty: 'public',
	});

	console.log(flowDetails);
	let query = new URLSearchParams(useLocation().search);
	React.useEffect(() => {
		const update = query.get('update');
		if (update) {
			setUpdateID(update);
		}
	}, [query]);

	React.useEffect(() => {
		if (updateID) {
			getPublishedSkillPack({ _id: updateID })
				.then((res) => {
					setFlowDetails({
						...res.data,
						skillPackDetails: res.data.skillPack,
						skillPack: res.data.skillPack._id,
					});
					setStage(2);
				})
				.catch((err) => {
					toast({
						title: 'Flow not found.',
						description: 'Could not find collection:' + updateID,
						status: 'error',
						duration: 3000,
						isClosable: false,
					});
				});
		}
	}, [updateID]);
	return (
		<>
			<PublishStages stage={stage} updateID={updateID} />
			{stage === 1 ? (
				<SkillPackSelectPage
					flowDetails={flowDetails}
					setFlowDetails={setFlowDetails}
					nextStage={() => setStage(2)}
					prevStage={() => setStage(1)}
					updateID={updateID}
				/>
			) : null}
			{stage === 2 ? (
				<PublishPage
					flowDetails={flowDetails}
					setFlowDetails={setFlowDetails}
					nextStage={() => setStage(3)}
					prevStage={() => setStage(1)}
					updateID={updateID}
				/>
			) : null}
			{stage === 3 ? (
				<SkillPackDetailsPage
					flowDetails={flowDetails}
					setFlowDetails={setFlowDetails}
					nextStage={() => setStage(4)}
					prevStage={() => setStage(2)}
					updateID={updateID}
				/>
			) : null}
		</>
	);
};

export default PublishSection;
