import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';
import IPCFunction from '../../util/IPCFetch/function';
import IPCRoutes from '../../util/IPCRoutes';

import isElectron from 'is-electron';

// config
import { config } from '../../config';

import { store } from '../../redux/store';
/**
 * Function to Create a new team profile
 */
const createTeam = async ({ workspaceName, slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;
	var createTeamFn;
	let formData = {
		name: workspaceName,
		slug: profileSlug,
	};
	createTeamFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.createTeam.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.createTeam.PATH_METHOD,
	});

	var createTeamResponse = await createTeamFn(formData);
	if (createTeamResponse['data']['error']) {
		return createTeamResponse['data'];
	} else {
		return createTeamResponse['data']['results'];
	}
};

export default createTeam;
