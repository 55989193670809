import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

/**
 * Intercom feedback component
 *
 * @param {{
 * 	onOpen?: () => void,
 * 	onClose?: () => void,
 * 	onWidgetOpen?: () => void,
 * 	onWidgetClose?: () => void,
 * 	onHide?: () => void
 * }} param0
 */
const Intercom = ({
	onOpen,
	onClose,
	onWidgetOpen,
	onWidgetClose,
	visible,
}) => {
	const userInfo = useSelector((state) => state.user.info);
	const userName = useSelector((state) => state.profiles.user.name);
	const location = useLocation();
	useEffect(() => {
		function loadFunction(checker) {
			if (window.Intercom) {
				window.Intercom('boot', {
					app_id: 'o55mr2qw',
					email: userInfo ? userInfo.email : '', // Full name
					name: userName,
					user_id: userInfo ? userInfo.id : '',
				});
				window.Intercom('onHide', function () {
					onWidgetClose();
				});
				window.Intercom('onShow', function () {
					onWidgetOpen();
				});
				clearInterval(checker);
			}
		}
		if (window.Intercom) {
			loadFunction();
		}
		let checker = setInterval(() => {
			if (window.Intercom && location.pathname !== '/cli') {
				loadFunction(checker);
			}
		}, 10);
	}, [onWidgetClose, onWidgetOpen, location, userInfo, userName]);

	React.useEffect(() => {
		if (location.pathname === '/cli') {
			window.Intercom('shutdown');
		}
	}, [location]);

	return (
		<Helmet>
			<script>
				{`
				
				var APP_ID = 'o55mr2qw';
				(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
				`}
			</script>
		</Helmet>
	);
};

export default Intercom;
