import React from 'react';
import { Input, useColorMode, Box } from '@chakra-ui/react';

/**
 *
 * @param {{
 *		inputRef: React.MutableRefObject<HTMLInputElement>,
 *		onPlaceholderClick: () => void,
 *		onChange: (e: React.ChangeEvent<HTMLInputElement) => void,
 *		focused: boolean,
 *		value: string,
 *		inputWidth: string | number,
 *		onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void,
 * }} param0
 * @returns
 */
const PlaceholderInput = ({
	inputRef,
	onPlaceholderClick,
	onChange,
	focused,
	value,
	inputWidth,
	onKeyDown,
}) => {
	const { colorMode } = useColorMode();
	const bgColor = {
		light: 'card_ui.placeholder_light',
		dark: 'card_ui.placeholder_dark',
	};
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	return (
		<Box
			height="1.3rem"
			bg={bgColor[colorMode]}
			paddingX="0.7rem"
			paddingY="0.1rem"
			borderRadius="0.2rem"
			display="flex"
			justifyContent="center"
			alignItems="center"
			mr={2}
			cursor="pointer"
			color={color[colorMode]}
			_focus={{ outline: 'none' }}
			whiteSpace="nowrap"
			mb={1}
			mt={1}
			onClick={onPlaceholderClick}
		>
			<Input
				color={color[colorMode]}
				whiteSpace="nowrap"
				bg={bgColor[colorMode]}
				ref={inputRef}
				fontWeight="600"
				cursor="pointer"
				height="80%"
				padding="0"
				border="none"
				fontSize="0.9rem"
				borderRadius="none"
				contentEditable
				_placeholder={{ color: '#777777' }}
				_focus={{ outline: 'none' }}
				fontFamily="body"
				onChange={onChange}
				textAlign={focused ? 'left' : 'center'}
				value={value}
				width={inputWidth}
				placeholder={focused ? '' : value}
				onKeyDown={onKeyDown}
			/>
		</Box>
	);
};

export default PlaceholderInput;
