import React, { useContext } from 'react';
import { Box, Spacer, Tooltip } from '@chakra-ui/react';
import { BackButton, ContinueButton } from './Buttons';
import { setupWizardStages } from './Config';
import Stepper from './Stepper';
import AnalyticsContext from '../../util/Analytics/AnalyticsContext';
import analytics from '../../util/Analytics';

/**
 *
 * @param {{
 *  stepperStages: string[],
 *  currentSetupWizardStage: import("./Config").SetupWizardStage,
 *  onContinue: () => void,
 *  continueBtnRef: React.MutableRefObject<HTMLButtonElement>,
 *  continueBtnLabel: string,
 *  showBackButton: boolean
 *  onBack: () => void,
 * 	shopSkip : boolean,
 * 	onFinish : () => void,
 * }} param0
 * @returns
 */
const ActionFooter = ({
	currentSetupWizardStage,
	onContinue,
	continueBtnRef,
	continueBtnLabel,
	showBackButton,
	hideContinueButton,
	onBack,
	showSkip,
	onFinish,
}) => {
	const { feature } = useContext(AnalyticsContext);
	return (
		<Box display="flex" justifyContent="space-between">
			<Stepper
				stages={setupWizardStages.list}
				currentStage={currentSetupWizardStage}
				label={setupWizardStages.byId[currentSetupWizardStage].label}
				onClick={() => {
					analytics.track(`[${feature}] Stepper: Click`, {
						feature,
					});
				}}
			/>
			<Box display="flex" alignItems="center">
				{showBackButton ? <BackButton onClick={() => onBack()} /> : null}
				<Spacer width="1rem" />
				{hideContinueButton ? null : (
					<ContinueButton
						continueBtnRef={continueBtnRef}
						tabIndex={1}
						onClick={() => onContinue()}
						label={continueBtnLabel}
					/>
				)}
				{showSkip ? (
					<Tooltip>
						<Box
							ml="0.5rem"
							color="grey.200"
							cursor="pointer"
							onClick={onFinish}
						>
							or <u>skip</u>
						</Box>
					</Tooltip>
				) : null}
			</Box>
		</Box>
	);
};

export default ActionFooter;
