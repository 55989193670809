import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

/**
 * Function to Create a New Brain, given some parameters
 */
const addToLibraryFromStore = async (formData) => {
	var { user } = store.getState();
	const profileSlug = formData.slug ? formData.slug : user.info.id;

	const addToLibraryFromStoreAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.addToLibraryFromStore.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.addToLibraryFromStore.PATH_METHOD,
	});

	const addToLibraryFromStoreResponse = await addToLibraryFromStoreAPIFn({
		publishedSkillPack: formData.publishedSkillPack,
		profileSlug,
	});

	return addToLibraryFromStoreResponse;
};

export default addToLibraryFromStore;
