import isElectron from 'is-electron';

// import IPCFunction from '../../util/IPCFetch/function';
// import IPCRoutes from '../../util/IPCRoutes';
import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

import { config } from '../../config';

/**
 * Function to get all configuration profiles of a certain module for user
 */
const listContactService = async () => {
	var listContactServiceResponse, listContactServiceAPIFn;

	if (isElectron()) {
		if (window.navigator.onLine) {
			listContactServiceAPIFn = APIFunction({
				BASEURL: config.apiServer.BASEURL,
				PATH_SEARCH: APIRoutes.apiService.getConfigType.PATH_SEARCH,
				PATH_METHOD: APIRoutes.apiService.getConfigType.PATH_METHOD,
				PATH_QUERY: '61431dbb7e7c9e68a2e5c719',
			});
			listContactServiceResponse = await listContactServiceAPIFn();
			if (listContactServiceResponse.status === 200) {
				return [listContactServiceResponse['data']['results']];
			}
		}
	} else {
		return {
			data: {
				error: true,
				message:
					'This service is available only for Maya Desktop Application',
			},
		};
	}
};

export default listContactService;
