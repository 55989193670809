import React from 'react';
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
	Button,
	useColorModeValue,
	useColorMode,
	Image,
	Spinner,
	IconButton,
	useToast,
} from '@chakra-ui/react';
import { SmallCloseIcon } from '@chakra-ui/icons';
import { BrainIconLarge } from '../../assets/icons';
import { AnimatePresence, motion } from 'framer-motion';
import isElectron from 'is-electron';

//Redux
import { connect, useSelector } from 'react-redux';

//Components
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import { BrainItemLeft } from './BrainItem';
import NewBrain from './NewBrain';
import HorizontalBox from '../Reusable/HorizontalBox';
import { NewItem, ItemCard, ItemCardSkeleton } from '../Reusable/HorizontalBox';
import PaymentModal from '../Payments';
//Functions
import startBrain from '../../functions/brain/startBrain';
import createBrain from '../../functions/brain/createBrain';

///Redux
import { addBrains } from '../../redux/actions';

//Utils
import GetProfileSlug from '../../util/ProfileSlug';
import { normalize } from '../../util/Misc';
import randomDefaultBrainName from '../../util/RandomDefaults/newbrain';

//Analytics
import analytics from '../../util/Analytics';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';

const SelectBrainModal = ({
	isOpen,
	onOpen,
	onClose,
	brains,
	addBrains,
	setFinalBrain,
	startBrainUtil,
}) => {
	const [isLoading, setLoading] = React.useState(false);
	const defaultBrain = [];
	brains.allIds.forEach((id) => {
		if (brains.byId[id].default) {
			defaultBrain.push(id);
		}
	});
	const [name, setName] = React.useState(
		defaultBrain.length === 0 ? 'Default Workspace' : randomDefaultBrainName()
	);
	const [isDefault, setIsDefault] = React.useState(
		defaultBrain.length === 0 ? true : false
	);
	const [device, setDevice] = React.useState({ _id: '', name: '' });
	console.log('🚀 ~ file: SelectBrain.js ~ line 72 ~ device', device);
	const [isDisabled, setDisabled] = React.useState(true);
	const { slug } = GetProfileSlug();

	React.useEffect(() => {
		if (!device._id || !device.name || !name) setDisabled(true);
		else setDisabled(false);
	}, [device, name]);

	const onSelect = () => {
		setLoading(true);
		createBrain({ name, device, slug, defaultRuntime: isDefault }).then(
			(res) => {
				onClose();
				addBrains(normalize([res]));
				analytics.track('New Workspace: Created', {
					feature: 'uncategorized',
					brain: {
						...res,
						flows: null,
						nodes: null,
						intents: null,
					},
				});
				setLoading(false);
				setFinalBrain(res);
				startBrainUtil(res);
			}
		);
	};
	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="md"
			fontFamily="body"
			isCentered={isElectron()}
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				minHeight="25rem"
				maxWidth="40rem"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody
					paddingX="0"
					display="flex"
					flexDir="column"
					justifyContent="space-between"
				>
					<HeaderWithDescription
						header={'New Workspace'}
						marginLeft="1rem"
					/>
					<Box height="100%" paddingX="0.5rem">
						<AnimatePresence>
							<motion.div
								layout
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
							>
								<NewBrain
									onClose={onClose}
									name={name}
									setName={setName}
									setDevice={setDevice}
									defaultRuntime={isDefault ? true : false}
								/>
							</motion.div>
						</AnimatePresence>
					</Box>
					<Box
						paddingTop="0.5rem"
						display="flex"
						flexDir="row"
						width="100%"
						borderTop="solid 1px"
						borderColor={useColorModeValue(
							'dashboard.border_light',
							'dashboard.border_dark'
						)}
						justifyContent="flex-end"
						boxShadow="rgba(0, 0, 0, 0.1) 0px -2px 4px"
					>
						<Button
							colorScheme="purple"
							bg={'purple.500'}
							color="white"
							size="sm"
							onClick={async () => {
								onSelect();
							}}
							isDisabled={isDisabled}
							isLoading={isLoading}
							loadingText={'Creating...'}
							borderRadius="5px"
							paddingX="20px"
							marginY="5px"
							marginX="1rem"
						>
							Create
						</Button>
					</Box>
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

// <ModalHeader>Select Brain</ModalHeader>

/**
 * Select Brain/New Workspace Component.
 *
 * This is a reusable component which can be used anywhere to select
 * an existing Maya Brain or create a new one and select it.
 * @param {*} param0
 */

const SelectBrain = ({
	brains,
	addBrains,
	brainIds,
	isLoading,
	onSelectFn,
	initialBrainId,
	skipNewBrain = false,
	hideDefaultBrain = false,
	onlyDefaultBrain = false,
	disableCrossButton = false,
	setBrainId,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const { colorMode } = useColorMode();
	const [finalBrain, setFinalBrain] = React.useState({ _id: '' });
	const [defaultBrainExists, setDefaultBrainExists] = React.useState(false);
	const [brainLoading, setBrainLoading] = React.useState(false);
	const toast = useToast();
	const { slug } = GetProfileSlug();
	const brainItemBorderColor = {
		light: '#f2f2f2',
		dark: '#494949',
	};
	const brainItemBgColor = {
		light: 'dashboard.bg_light.100',
		dark: '#444444',
	};

	React.useEffect(() => {
		const defaultBrainList = [];
		brains.allIds.forEach((id) => {
			if (brains.byId[id].default) {
				console.log(
					'defaultBrainExists',
					brains.byId[id].default,
					onlyDefaultBrain
				);
				defaultBrainList.push(brains.byId[id]);
			}
		});
		setDefaultBrainExists(defaultBrainList.length > 0 ? true : false);
	}, [brains]);

	React.useEffect(() => {
		if (brains.allIds.length >= 0 && initialBrainId) {
			if (brains.byId[initialBrainId]) {
				setFinalBrain(brains.byId[initialBrainId]);
			}
		}
	}, [initialBrainId, brains]);

	const startBrainUtil = (brain) => {
		setBrainLoading(true);
		startBrain({
			brain: brain,
			slug,
			onDone: (showToast) => {
				setFinalBrain({
					...brain,
					status: 'STARTED',
				});
				setBrainLoading(false);
				if (showToast) {
					toast({
						title: 'Workspace started!',
						description: 'The workspace has successfully started',
						status: 'success',
						duration: 1000,
						isClosable: true,
					});
				}
				analytics.track(`Select Brain: Start Brain`, {
					feature: 'uncategorized',
					status: 'success',
					brain: {
						...brain,
						flows: null,
						intents: null,
						nodes: null,
					},
				});
			},
			onError: () => {
				setBrainLoading(false);
				toast({
					title: 'Error!',
					description:
						'This workspace could not be started. Try again later.',
					status: 'error',
					duration: 1000,
					isClosable: true,
				});
				analytics.track(`Select Brain: Start Brain`, {
					feature: 'uncategorized',
					status: 'failure',
					brain: {
						...brain,
						flows: null,
						intents: null,
						nodes: null,
					},
				});
			},
			onLoading: () => {
				toast({
					title: 'Start in progress.',
					description: 'Please wait while we are starting the workspace',
					status: 'info',
					duration: 3000,
					isClosable: true,
				});
			},
		});
	};

	React.useEffect(() => {
		if (finalBrain._id && !brainLoading) {
			onSelectFn(finalBrain._id);
		} else {
			onSelectFn('');
		}
	}, [finalBrain, onSelectFn, brainLoading]);

	const loadingList = [1, 2, 3];

	const { tier } = useSelector((store) => store.profiles.selected);

	const [limited, setLimited] = React.useState(false);

	// Workspace limits removed from free tier
	// React.useEffect(() => {
	// 	if (tier === 'FREE') {
	// 		if (brains.allIds.length >= 2) {
	// 			setLimited(true);
	// 		}
	// 	}
	// }, [brains, tier]);
	return (
		<Box>
			<HeaderWithDescription
				header={'Select Workspace'}
				desc={
					limited
						? 'Pick from available workspaces to continue. Upgrade your plan to create unlimited workspaces.'
						: !skipNewBrain
						? 'Create or select a workspace to start working with workflows.'
						: 'Pick brain workspace to continue'
				}
				tooltip={
					'A brain workspace is a cross-device virtual environment where all the automation workflows are run.'
				}
			/>
			{finalBrain._id === '' ? (
				<HorizontalBox>
					{skipNewBrain ||
					(defaultBrainExists && onlyDefaultBrain) ? null : (
						<NewItem
							onClick={() => {
								if (!limited) {
									setFinalBrain({ _id: '' });
									analytics.track(
										'Select Brain - Horizontal Box : New Workspace',
										{}
									);
									onOpen();
								} else {
									//onOpenPaymentModal();
								}
							}}
							icon={
								<BrainIconLarge
									size={40}
									color={
										{ light: '#464646', dark: 'white' }[colorMode]
									}
								/>
							}
							text={'+ New Workspace'}
							isDisabled={limited}
							tooltip={
								limited
									? 'Upgrade your plan create new workspaces.'
									: ''
							}
						/>
					)}
					{isLoading
						? loadingList.map((item) => {
								return <ItemCardSkeleton key={item} />;
						  })
						: onlyDefaultBrain
						? brainIds.map((id) => {
								return (
									brains.byId[id].default && (
										<ItemCard
											key={id}
											thumbnail={
												brains.byId[id].thumbnail ||
												'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
											}
											leftText={brains.byId[id].name}
											onClick={() => {
												setFinalBrain(brains.byId[id]);
												startBrainUtil(brains.byId[id]);
											}}
										/>
									)
								);
						  })
						: brainIds.map((id) => {
								return hideDefaultBrain &&
									brains.byId[id].default ? null : (
									<ItemCard
										key={id}
										thumbnail={
											brains.byId[id].thumbnail ||
											'https://maya-frontend-static.s3.ap-south-1.amazonaws.com/default.jpg'
										}
										leftText={brains.byId[id].name}
										onClick={() => {
											setFinalBrain(brains.byId[id]);
											startBrainUtil(brains.byId[id]);
										}}
									/>
								);
						  })}
				</HorizontalBox>
			) : (
				<Box
					borderRadius="5px"
					border="solid 2px"
					marginTop="15px"
					borderColor={brainItemBorderColor[colorMode]}
					overflow="hidden"
					cursor="pointer"
					boxShadow="rgba(0, 0, 0, 0.09) 1px 2px 4px"
					paddingY="0.5rem"
					bg={brainItemBgColor[colorMode]}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					opacity={brainLoading ? '30%' : '100%'}
				>
					<BrainItemLeft brain={finalBrain} />
					{brainLoading ? (
						<Spinner mr="2rem" size="md" color="maya_white.500" />
					) : (
						<IconButton
							aria-label="cancel"
							icon={<SmallCloseIcon />}
							mr="1rem"
							size="sm"
							borderRadius="5px"
							isDisabled={brainLoading || disableCrossButton}
							onClick={() => {
								setFinalBrain({ _id: '' });
								if (setBrainId) setBrainId('');
								analytics.track('Select Brain - Clear Selection', {
									feature: 'uncategorized',
								});
							}}
						/>
					)}
				</Box>
			)}
			<SelectBrainModal
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				brainIds={brainIds}
				brains={brains}
				addBrains={addBrains}
				setFinalBrain={setFinalBrain}
				startBrainUtil={startBrainUtil}
			/>
		</Box>
	);
};

/**
 * mapStateToProps
 * @param {state} state
 */
const mapStateToProps = (state, ownProps) => {
	let brains = ownProps.brains ? ownProps.brains : state.brains;
	const brainIds = brains.allIds;
	const { isLoading } = state.dashboard;
	return { brainIds, brains, isLoading };
};

export default connect(mapStateToProps, { addBrains })(SelectBrain);
