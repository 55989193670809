import React from 'react';
import { Box, SimpleGrid, useColorMode, useDisclosure } from '@chakra-ui/react';

import {
	FiGitBranch,
	FiShoppingBag,
	FiCodesandbox,
	FiDownloadCloud,
	FiCommand,
} from 'react-icons/fi';

import { BiBrain } from 'react-icons/bi';
import { HiOutlineLightningBolt } from 'react-icons/hi';

import isElectron from 'is-electron';

//Analytics
import analytics from '../../util/Analytics';

//Components
import IconContainer from '../../util/React_Icon';
import { HistoryWithSlug } from '../../util/History';
import SetupWizard from '../SetupWizard';
import DownloadAppModal from '../SetupWizard/DownloadApp';

//Functions
import toggleCommandBar from '../../functions/commandBar/toggleCommandBar';

//import toggleSetupStatus from '../../functions/setup/toggleSetupStatus';

const HomeButtonItem = ({ title, description, icon, onClick }) => {
	const { colorMode } = useColorMode();

	const fontColor = { light: '#555555', dark: 'white' };

	const hoverColor = {
		light: 'dashboard.bg_light.100',
		dark: 'maya_dark.150',
	};
	const borderColor = {
		light: 'dashboard.border_light',
		dark: 'dashboard.border_dark',
	};
	const bgColor = {
		light: '#f9f9f9',
		dark: 'maya_dark.250',
	};
	return (
		<Box
			height="90px"
			bg={bgColor[colorMode]}
			display="flex"
			alignItems="center"
			borderRadius="0.3rem"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
			border="solid 1px"
			boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
			borderColor={borderColor[colorMode]}
			_hover={{
				bg: hoverColor[colorMode],
				borderColor: borderColor[colorMode],
			}}
			marginTop="8px"
			onClick={() => {
				onClick();
				analytics.track(`Home Button`, {
					title,
				});
			}}
			width="24.5%"
		>
			<Box
				ml="1rem"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
				width="100%"
			>
				<Box display="flex" alignItems="center">
					<Box
						width="40px"
						flexDir="column"
						display="flex"
						alignItems="center"
					>
						<IconContainer
							icon={icon}
							value={{
								color: fontColor[colorMode],
								className: 'global-class-name',
								size: '25px',
							}}
						/>
					</Box>
					<Box ml="1rem" width="200px" paddingRight="15px">
						<Box
							fontFamily="body"
							color={fontColor[colorMode]}
							fontWeight="600"
							marginBottom="0.1rem"
							fontSize="14px"
						>
							{title}
						</Box>
						<Box
							fontFamily="body"
							fontSize="13px"
							color={fontColor[colorMode]}
							opacity="0.9"
						>
							{description}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

const SetupWizardButtonItem = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [isClosable, setIsClosable] = React.useState(false);
	return (
		<>
			<HomeButtonItem
				title={'Setup Wizard'}
				description={'Quick onboarding guide to help you get started.'}
				icon={<FiCodesandbox />}
				onClick={() => {
					// toggleSetupStatus().then((res) => {
					// 	console.log('Trying to open modal now');
					// 	onOpen();
					// });
					setIsClosable(true);
					onOpen();
				}}
			/>
			<SetupWizard
				isOpen={isOpen}
				onClose={onClose}
				onOpen={onOpen}
				isClosable={isClosable}
			/>
		</>
	);
};

/**
 *
 */

const DownloadButtonItem = () => {
	const { isOpen, onOpen, onClose } = useDisclosure(true);
	return (
		<>
			<HomeButtonItem
				title={'Download Desktop App'}
				description={
					"Start using Maya with it's command bar on your desktop."
				}
				icon={<HiOutlineLightningBolt />}
				onClick={() => {
					onOpen();
				}}
			/>
			<DownloadAppModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
		</>
	);
};

/**
 * Home Section
 */

const QuickLinks = () => {
	return (
		<Box
			columns={4}
			width="100%"
			display="flex"
			flexDirection="row"
			justifyContent="space-between"
		>
			{isElectron() ? null : <DownloadButtonItem />}
			{isElectron() ? (
				<HomeButtonItem
					title={'Launch Command Bar'}
					description={
						'Press Alt/Option (⌥) + Space to trigger Maya commands.'
					}
					icon={<HiOutlineLightningBolt />}
					onClick={() => {
						toggleCommandBar({ method: 'quicklink' });
					}}
				/>
			) : null}
			{false && (
				<HomeButtonItem
					title={'Explore the Maya Store'}
					description={
						'A marketplace of Maya skills to pick, configure and use.'
					}
					icon={<FiShoppingBag />}
					onClick={() => {
						HistoryWithSlug.push('/store');
					}}
				/>
			)}
			{true && (
				<HomeButtonItem
					title={'New Skill Workflow'}
					description={
						'Build a new workflow, from scratch or from a template.'
					}
					icon={<FiCommand />}
					onClick={() => {
						HistoryWithSlug.push('/skills/new');
					}}
				/>
			)}
			{false && (
				<HomeButtonItem
					title={'Edit Installed Skills'}
					description={
						'Navigate through Brain workspace and edit skill workflows.'
					}
					icon={<BiBrain />}
					onClick={() => {
						HistoryWithSlug.push('/skills');
					}}
				/>
			)}
			<HomeButtonItem
				title={'Request Custom Bot'}
				description={
					'Get a repetitive task automated for you in a day or two.'
				}
				icon={<FiDownloadCloud />}
				onClick={() => {
					if (isElectron()) {
						const electron = window.require('electron');
						const { shell } = electron;
						shell.openExternal('https://mayahq.com/custom');
					}
				}}
			/>
			{isElectron() ? <SetupWizardButtonItem /> : null}
		</Box>
	);
};

export default QuickLinks;
