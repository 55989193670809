import React from 'react';
import { Box } from '@chakra-ui/react';

const Avatar = ({ name, size }) => {
	return (
		<Box
			width={`${size}px`}
			height={`${size}px`}
			borderRadius="10px"
			bg="purple.500"
			display="flex"
			alignItems="center"
			justifyContents="center"
			ml="2"
		>
			<Box fontSize={`${size * 0.6}px`} width="100%" textAlign="center">
				{name ? name.charAt(0).toUpperCase() : 'M'}
			</Box>
		</Box>
	);
};

export default Avatar;
