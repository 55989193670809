/**
 * Usersnap Actions
 */

// Action Types
import actionTypes from '../actionTypes';

/**
 *
 * @param {boolean} isOpen
 * @returns
 */
const supportWidgetSetOpen = (isOpen) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.SUPPORT_WIDGET_SET_OPEN,
		payload: {
			isOpen,
		},
	});
};

/**
 *
 * @param {boolean} widgetIsOpen
 * @returns
 */
const supportWidgetSetWidgetOpen = (widgetIsOpen) => (dispatch, getState) => {
	// Grab only the pertinent information to send to storage. We don't want everything.

	// Dispatch the result.
	dispatch({
		type: actionTypes.SUPPORT_WIDGET_SET_WIDGET_OPEN,
		payload: {
			widgetIsOpen,
		},
	});
};

// Export the actions.
export { supportWidgetSetOpen, supportWidgetSetWidgetOpen };
