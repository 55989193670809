import React from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';

const UpdatesMenu = ({ list, mode, LeftSection, setUpdates }) => {
	return (
		<Box
			width="100%"
			borderBottom="solid 1px"
			borderColor={useColorModeValue('#f2f2f2', '#4a4949')}
			marginTop="0.5rem"
			display="flex"
			paddingLeft="0.5rem"
			justifyContent="space-between"
			alignItems="center"
		>
			<Box display="flex">
				{list.map((item) => {
					return (
						<Box
							fontSize="16px"
							height="40px"
							width="9rem"
							display="flex"
							borderBottom="solid 1px"
							borderColor={
								mode === item.label ? 'purple.500' : 'transparent'
							}
							paddingX="0.3rem"
							paddingBottom="0.7rem"
							justifyContent="center"
							alignItems="center"
							marginLeft="0.5rem"
							cursor="pointer"
							transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
							onClick={item.onClick}
						>
							<Box>{item.label}</Box>
							{item.updatesNos > 0 ? (
								<Box
									bg="purple.500"
									height="1rem"
									width="1.5rem"
									marginLeft="0.7rem"
									display="flex"
									alignItems="center"
									justifyContent="center"
									borderRadius="0.5rem"
									fontSize="0.6rem"
									color="white"
								>
									{item.updatesNos}
								</Box>
							) : null}
						</Box>
					);
				})}
			</Box>
			<LeftSection setUpdates={setUpdates} />
		</Box>
	);
};

export default UpdatesMenu;
