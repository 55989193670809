import {
	Box,
	Flex,
	Icon,
	IconButton,
	SimpleGrid,
	useDisclosure,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FiPause, FiPlay } from 'react-icons/fi';
import LongRunningTaskItem from '../Reusable/LongRunningTaskItem';
import RescheduleTaskModal from './RescheduleTaskModal';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Axios from 'axios';

dayjs.extend(relativeTime);

/**
 *
 * @param {{
 * 	heading: React.ReactNode,
 * 	tasks: import('./LongRunningTasks').LongRunningTask[],
 * 	updateTask: (taskId: string, updatedFields: import('./LongRunningTasks').LongRunningTask) => void,
 * 	themeColor: import('@chakra-ui/react').BorderProps['borderLeftColor'],
 *		containerProps?: import('@chakra-ui/react').FlexProps,
 * }} param0
 * @returns
 */
const TaskSubSection = ({
	heading,
	tasks,
	updateTask,
	containerProps,
	themeColor,
}) => {
	const [selectedTaskItem, setSelectedTaskItem] = useState(
		/**
		 * @returns {import('./LongRunningTasks').LongRunningTask | null}
		 */
		() => null
	);
	const { isOpen, onOpen, onClose } = useDisclosure();

	/**
	 *	fetches the task details via GET API and updates the state for the card.
	 * @param {import('./LongRunningTasks').LongRunningTask} taskItem
	 */
	const refreshTaskDetails = async (taskItem) => {
		/** GET API to fetch task updated info */
		const getRes = await Axios.get(
			taskItem.endpointFullUrl + `/${taskItem.name}`
		);
		/**
		 * @type {import('./LongRunningTasks').SchedulerGetResponseTaskEntity}
		 */
		const schedulerResponseEntity = getRes.data;
		const { description, nextDate, expression, isRunning, name, topic } =
			schedulerResponseEntity;
		/** Update the state with new values as fetched above */
		updateTask(taskItem.id, {
			title: topic,
			status: isRunning ? 'started' : 'stopped',
			config: {
				cronString: expression,
				nextDate,
			},
			taskStatusIsLoading: false,
			name,
			topic,
		});
	};

	/**
	 *	Controls pause/play of the long running tasks
	 * @param {import('./LongRunningTasks').LongRunningTask} taskItem
	 */
	const handlePausePlayButtonClick = async (taskItem) => {
		try {
			updateTask(taskItem.id, {
				taskStatusIsLoading: true,
			});
			/** POST API to make changes to the task */
			const postRes = await Axios.post(taskItem.endpointFullUrl, {
				name: taskItem.name,
				command: taskItem.status === 'started' ? 'stop' : 'start',
			});
			await refreshTaskDetails(taskItem);
		} catch (error) {
			console.error(error);
		}
	};

	/**
	 *	Handles the Rescheduling of the Task
	 * @param {string} newCronString
	 */
	const handleRescheduleTaskConfirm = async (newCronString) => {
		try {
			const res = await Axios.post(selectedTaskItem.endpointFullUrl, {
				name: selectedTaskItem.name,
				topic: selectedTaskItem.topic,
				command: 'add',
				expressionType: 'cron',
				expression: newCronString,
				scheduleType: 'static',
			});
			await refreshTaskDetails(selectedTaskItem);
			onClose();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<Flex flexDirection="column" minHeight="0" py="1" {...containerProps}>
			<Box fontSize="sm" mb="1.5" fontWeight="600" fontFamily="body">
				{heading}
			</Box>
			<SimpleGrid
				columns={{
					sm: 2,
					md: 3,
					lg: 4,
					// xl: 4,
				}}
				spacing="2"
				width="full"
			>
				{tasks.map((taskItem, i) => {
					return (
						<LongRunningTaskItem
							key={i}
							title={taskItem.title}
							themeColor={
								taskItem.status === 'started' ? 'green.300' : themeColor
							}
							taskItem={taskItem}
							deviceName={taskItem.device?.name}
							nextTime={dayjs(taskItem.config.nextDate).fromNow()}
							onClick={() => {
								setSelectedTaskItem(taskItem);
								onOpen();
							}}
							actionButton={
								<IconButton
									isLoading={taskItem.taskStatusIsLoading}
									icon={
										taskItem.status === 'started' ? (
											<Icon as={FiPause} color="white" />
										) : (
											<Icon as={FiPlay} color="white" />
										)
									}
									onClick={(e) => {
										e.stopPropagation();
										handlePausePlayButtonClick(taskItem);
									}}
									size="sm"
								/>
							}
						/>
					);
				})}
			</SimpleGrid>
			<RescheduleTaskModal
				isOpen={isOpen}
				task={selectedTaskItem}
				onCancel={onClose}
				onConfirm={handleRescheduleTaskConfirm}
			/>
		</Flex>
	);
};

export default TaskSubSection;
