import { Button, IconButton } from '@chakra-ui/button';
import { Checkbox, CheckboxGroup } from '@chakra-ui/checkbox';
import { CheckIcon, CloseIcon, CopyIcon } from '@chakra-ui/icons';
import { Box, Code, Flex, Spacer, Stack, Text } from '@chakra-ui/layout';
import {
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from '@chakra-ui/modal';
import { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import getMayaJson from '../../../functions/skillPack/getMayaJson';
import useQueryParams from '../../../util/useQueryParams';
import { getRedFlows } from '../RedApiWrappers';
import HeaderWithDescription from './HeaderWithDescription';
import StepperContext from './StepperContext';
import { LightMode } from '@chakra-ui/color-mode';
import { Input } from '@chakra-ui/input';
import { Textarea } from '@chakra-ui/textarea';
import SelectFlowStepperView from './SelectFlowStepperView';
import InstallFlowStepperView from './InstallFlowStepperView';

/**
 *
 * @param {{
 *  isOpen: boolean,
 *  onClose?: () => void,
 *  modalProps?: import('@chakra-ui/modal').ModalProps,
 * }} param0
 * @returns
 */
const ImportFlowsModal = ({ isOpen, onClose, modalProps }) => {
	const query = useQueryParams();
	const currentBrainId = query.get('id');
	const [mayaFlowJson, setMayaFlowJson] = useState(null);
	const [isFlowLoading, setIsFlowLoading] = useState(false);
	const [isFlowCopied, setIsFlowCopied] = useState(false);
	const [stepIndex, setStepIndex] = useState(0);

	useEffect(() => {
		if (isOpen) {
			setStepIndex(0); // reset stepper
			setMayaFlowJson(null);
		}
	}, [isOpen]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			isCentered
			size="3xl"
			{...modalProps}
		>
			<ModalOverlay />
			<ModalContent height="xl" bg="#313131">
				<HeaderWithDescription
					header="Import Flows"
					description="Import and install external flow with all its module dependencies."
					containerProps={{
						px: '4',
						py: '3',
						borderTopRadius: 'xl',
					}}
				/>
				<ModalCloseButton />
				<ModalBody
					flex="1"
					minHeight="0"
					display="flex"
					flexDirection="column"
					p="0"
				>
					<StepperContext.Provider
						value={{
							stepIndex,
							setStepIndex,
							mayaFlowJson,
							setMayaFlowJson,
							onModalClose: onClose,
						}}
					>
						{stepIndex === 0 ? <SelectFlowStepperView /> : null}
						{stepIndex === 1 ? <InstallFlowStepperView /> : null}
					</StepperContext.Provider>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default ImportFlowsModal;
