import React from 'react';
import { Box } from '@chakra-ui/react';
import { useParams, useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

//Components
import NewSkillSection from './NewSkill';
import NewSkillButton from './NewSkillButton';
import BrainList from '../Brains/BrainList';

//Hooks
import GetProfileSlug from '../../util/ProfileSlug';
// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const SkillPage = ({ initMode }) => {
	//   let { id } = useParams();
	//   let query = useQuery();
	const { slug } = GetProfileSlug();
	console.log('SLUG', slug);
	const [selectedBrain, selectBrain] = React.useState({ _id: '' });

	const [mode, setMode] = React.useState(initMode || 'list');

	React.useEffect(() => {
		setMode(initMode);
	}, [initMode]);

	return (
		<Box
			borderRadius="10px"
			display="flex"
			flexDirection="column"
			height="100%"
			position="relative"
		>
			<NewSkillButton mode={mode} setMode={setMode} />
			<AnimatePresence>
				{mode === 'new' && (
					<motion.div
						layout
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
					>
						<NewSkillSection />
					</motion.div>
				)}
				{mode === 'list' && (
					<motion.div
						layout
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						style={{
							top: '0',
							left: '0',
							display: 'flex',
							flexDirection: 'column',
							flex: '1 1 auto',
							height: 0,
						}}
					>
						<BrainList
							skillsButton={false}
							controlButton={true}
							openButton={true}
							selectedBrainId={selectedBrain}
							onClickFn={selectBrain}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</Box>
	);
};

export default SkillPage;

// List : Show Brains of Account ID: {id} and search for {query.get("q")}

//   <Box position="absolute" bottom="0">
//     Show Brains of Account ID: {id} and search for {query.get("q")}
//   </Box>
