import React from 'react';
import { useColorMode, Box, Image } from '@chakra-ui/react';
import { BiMicrophone } from 'react-icons/bi';
import audio_anim_light from '../../../../../../assets/cardui/voice/animation_audio_light.gif';
import audio_anim_dark from '../../../../../../assets/cardui/voice/animation_audio_dark.gif';
import IconContainer from '../../../../../../util/React_Icon';

/**
 *
 * @param {{
 * listening: boolean,
 *	onStartListening: () => void,,
 *	onStopListening: () => void,
 * }} param0
 * @returns
 */
const AudioIcon = ({ listening, onStartListening, onStopListening }) => {
	const { colorMode } = useColorMode();

	const color = {
		light: '#6C6C6C',
		dark: '#FFFEFE',
	};
	const audio_icons = {
		light: audio_anim_light,
		dark: audio_anim_dark,
	};
	return (
		<Box display="flex">
			{listening ? (
				<Box
					width="4rem"
					height="2.5rem"
					overflow="hidden"
					cursor="pointer"
					onClick={onStopListening}
				>
					<Image
						src={audio_icons[colorMode]}
						objectFit="cover"
						boxSize="5.5rem"
						cursor="pointer"
						marginTop="-1.5rem"
					/>
				</Box>
			) : (
				<Box mr={5} cursor="pointer" onClick={onStartListening}>
					<IconContainer
						icon={<BiMicrophone />}
						cursor="pointer"
						value={{
							color: color[colorMode],
							className: 'global-class-name',
							size: '1.3rem',
						}}
					/>
				</Box>
			)}
		</Box>
	);
};

export default AudioIcon;
