/**
 * # Get Red flows [Red API wrapper]
 *
 * Get flows/nodes json based on `type`:
 * - `selected`: only selected nodes
 * - `flow`: only current flow
 * - `full`: all flows
 * @param {*} RED window.RED object
 * @param {'selected' | 'flow' | 'full'} type
 */
export const getRedFlows = (RED, type) => {
	let nodes = null;
	if (type === 'selected') {
		var selection = RED.workspaces.selection();
		if (selection.length > 0) {
			nodes = [];
			selection.forEach(function (n) {
				nodes.push(n);
				nodes = nodes.concat(RED.nodes.groups(n.id));
				nodes = nodes.concat(RED.nodes.filterNodes({ z: n.id }));
			});
		} else {
			nodes = RED.view.selection().nodes || [];
		}
		// Don't include the subflow meta-port nodes in the exported selection
		nodes = RED.nodes.createExportableNodeSet(
			nodes.filter(function (n) {
				return n.type !== 'subflow';
			})
		);
	} else if (type === 'flow') {
		var activeWorkspace = RED.workspaces.active();
		nodes = RED.nodes.groups(activeWorkspace);
		nodes = nodes.concat(RED.nodes.filterNodes({ z: activeWorkspace }));
		RED.nodes.eachConfig(function (n) {
			if (n.z === RED.workspaces.active() && n._def.hasUsers === false) {
				// Grab any config nodes scoped to this flow that don't
				// require any flow-nodes to use them
				nodes.push(n);
			}
		});
		var parentNode =
			RED.nodes.workspace(activeWorkspace) ||
			RED.nodes.subflow(activeWorkspace);
		nodes.unshift(parentNode);
		nodes = RED.nodes.createExportableNodeSet(nodes);
	} else if (type === 'full') {
		nodes = RED.nodes.createCompleteNodeSet(false);
	}
	return nodes;
};
