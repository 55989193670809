import {
	isCronDayOfMonthValue,
	isCronDayOfWeekValueString,
	isCronHoursValue,
	isCronMinutesValue,
	isCronSecondsValue,
} from '@mayahq/cron';

/**
 *	Parses the Cron Object to get the selected time period that
 *	fits in the simple cron scheduler. If does'nt fit returns `unknown`.
 * @param {import('@mayahq/cron').CronQuartz} cronObject
 * @returns
 */
const parseCronObjectIntoPeriod = (cronObject) => {
	for (const k in cronObject) {
		/**
		 * @type {keyof import('@mayahq/cron').CronQuartz}
		 */
		const key = k;
		// find the key with value having an every
		if (cronObject[key].every) {
			const cronObjectWithoutEveryKey = { ...cronObject };
			delete cronObjectWithoutEveryKey[key]; // delete current key of that object
			if (
				key === 'seconds' && // if key is seconds
				Object.entries(cronObjectWithoutEveryKey).every(
					([key, val]) => val === '*'
				) // rest all values are "*"
			)
				return 'seconds';
			else if (
				key === 'minutes' && // if key is minutes
				Object.entries(cronObjectWithoutEveryKey).every(([key, val]) =>
					key === 'seconds' ? isCronSecondsValue(val) : val === '*'
				) // rest all values are "*" except seconds is set to actual seconds value
			)
				return 'minutes';
			else if (
				key === 'hours' && // if key is minutes
				Object.entries(cronObjectWithoutEveryKey).every(([key, val]) =>
					key === 'seconds'
						? isCronSecondsValue(val)
						: key === 'minutes'
						? isCronMinutesValue(val)
						: val === '*'
				)
				// rest all values are "*" except
				// seconds is set to actual seconds value
				// minutes is set to actual minutes value
			)
				return 'hours';
			else if (
				key === 'dayOfMonth' && // if key is dayOfMonth
				Object.entries(cronObjectWithoutEveryKey).every(([key, val]) =>
					key === 'seconds'
						? isCronSecondsValue(val)
						: key === 'minutes'
						? isCronMinutesValue(val)
						: key === 'hours'
						? isCronHoursValue(val)
						: key === 'dayOfWeek'
						? val === '?'
						: val === '*'
				)
				// rest all values are "*" except
				// seconds is set to actual seconds value
				// minutes is set to actual minutes value
				// dayOfWeek is set to "?"
			)
				return 'days';
			else return 'unknown'; // if no matches
		} else if (cronObject[key] === '?') {
			if (
				key === 'dayOfMonth' &&
				isCronDayOfWeekValueString(cronObject['dayOfWeek'])
			) {
				const cronObjectWithoutWMKeys = { ...cronObject };
				delete cronObjectWithoutWMKeys['dayOfWeek'];
				delete cronObjectWithoutWMKeys['dayOfMonth'];
				if (
					Object.entries(cronObjectWithoutWMKeys).every(([key, val]) =>
						key === 'seconds'
							? isCronSecondsValue(val)
							: key === 'minutes'
							? isCronMinutesValue(val)
							: key === 'hours'
							? isCronHoursValue(val)
							: val === '*'
					)
				)
					return 'dayOfWeek';
				else return 'unknown';
			} else if (
				key === 'dayOfWeek' &&
				isCronDayOfMonthValue(cronObject['dayOfMonth'])
			) {
				const cronObjectWithoutWMKeys = { ...cronObject };
				delete cronObjectWithoutWMKeys['dayOfWeek'];
				delete cronObjectWithoutWMKeys['dayOfMonth'];
				if (
					Object.entries(cronObjectWithoutWMKeys).every(([key, val]) =>
						key === 'seconds'
							? isCronSecondsValue(val)
							: key === 'minutes'
							? isCronMinutesValue(val)
							: key === 'hours'
							? isCronHoursValue(val)
							: val === '*'
					)
				)
					return 'dayOfMonth';
				else return 'unknown';
			}
		}
	}
	return 'unknown';
};

export default parseCronObjectIntoPeriod;
