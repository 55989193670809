/**
 * Dashboard Actions
 *
 * Action creators for Dashboard based actions.
 */

// Action Types
import actionTypes from '../actionTypes';

import { addBrains } from './brain';

/**
 * Set the Loading Status
 *
 * Set the loading status of the dashboard
 *
 * @param {Object} devices The devices list obtained from the backend.
 */
const setLoading = (status) => (dispatch, getState) => {
	// Dispatch the result.
	dispatch({
		type: actionTypes.SET_LOADING,
		payload: {
			info: status,
		},
	});
};

const setDashboardInfo = (brains) => (dispatch, getState) => {
	console.log('Setting dashboard info..');

	if (brains) {
		dispatch(addBrains(brains, true));
	}
};

const setTrialExpiryModalDisplay = (status) => (dispatch, getState) => {
	dispatch({
		type: actionTypes.SET_TRIAL_EXPIRY_MODAL_DISPLAY,
		payload: {
			info: status
		}
	})
}

// Export the actions.
export { setLoading, setDashboardInfo, setTrialExpiryModalDisplay };
