import domtoimage from 'dom-to-image';
import Jimp from 'jimp';

const cropImage = (url) => {
	return new Promise((resolve, reject) => {
		let buffer = new Buffer(
			url.replace(/^data:image\/\w+;base64,/, ''),
			'base64'
		);

		Jimp.read(buffer)
			.then((img) => {
				img.resize(700, 700)
					.crop(50, 50, 500, 300)
					.getBase64(Jimp.AUTO, (err, res) => {
						if (err) {
							reject(err);
						}
						resolve(res);
					});
			})
			.catch(function (err) {});
	});
};

export const takeScreenshot = () => {
	return new Promise((resolve, reject) => {
		const el = document.querySelector('#red-ui-workspace-chart');

		domtoimage
			.toPng(el, {
				height: 1000,
				width: 1000,
				filter: function (element) {
					if (element.nodeName === 'image') {
						return false;
					} else {
						return true;
					}
				},
			})
			.then(function (dataUrl) {
				cropImage(dataUrl).then((url) => {
					resolve(url);
				});
			})
			.catch(function (error) {
				console.error('oops, something went wrong!', error);
				reject(error);
			});

		// html2canvas(el, options)
		//   .then((canvas) => {
		//     // console.log(canvas.toDataURL());
		//     let croppedCanvas = document.createElement("canvas");
		//     croppedCanvas.style.cssText =
		//       "pointer-events : none; background : red;";
		//     let croppedCanvasContext = croppedCanvas.getContext("2d");

		//     let cropWidth = 500;
		//     let cropHeight = 300;
		//     let cropPositionLeft = 0;
		//     let cropPositionTop = 0;
		//     croppedCanvas.width = cropWidth;
		//     croppedCanvas.height = cropHeight;

		//     croppedCanvasContext.drawImage(
		//       canvas,
		//       cropPositionLeft,
		//       cropPositionTop,
		//       cropWidth,
		//       cropHeight,
		//       0,
		//       0,
		//       cropWidth,
		//       cropHeight
		//     );

		//     // console.log(fn);
		//     return croppedCanvas.toDataURL();
		//   })
		//   .then((res) => resolve(res))
		//   .catch((err) => reject(err));
	});
};
