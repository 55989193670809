import actionTypes from '../actionTypes';
import dotProp from 'dot-prop-immutable';
import produce from 'immer';

/**
 * @type {import('./types/commandHistoryReducer').CommandHistoryState}
 */
const initialState = {
	log: [],
};

const MAX_LOG_LENGTH = 100000;

const commandHistoryReducer = produce(
	/**
	 *
	 * @param {typeof initialState} draft
	 * @param {{
	 * 	type: keyof typeof actionTypes,
	 * 	payload: unknown
	 * }} action
	 * @returns
	 */
	(draft, action) => {
		switch (action.type) {
			case actionTypes.COMMAND_HISTORY_LOG_INTENT_EVENT: {
				const { logItem } = action.payload;
				draft.log.push(logItem);
				/** Prevent array exceed max length */
				if (draft.log.length > MAX_LOG_LENGTH) draft.log.shift();
				return draft;
			}

			default: {
				return draft;
			}
		}
	},
	initialState
);

export default commandHistoryReducer;
