import isElectron from 'is-electron';

/**
 * AppProtocol
 *
 * This utility allows making a call from the web to Maya application
 *
 *
 * @param {String} EVENT The event type that needs to be passed
 * @param {String} arg this contains the data that needs to be sent to the application
 */
const AppProtocol = (EVENT, args) => {
	console.log('This is where we at');
	if (!isElectron()) {
		let data = {
			event: EVENT,
			args: args,
		};
		console.log('is not electron');
		let protocolLink = `maya://data?=${JSON.stringify(data)}`;
		window.open(protocolLink);
	}
};

export default AppProtocol;
