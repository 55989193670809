import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	useColorModeValue,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	Text,
	Button,
	Spacer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Icon,
	Switch,
	Link,
	Code,
	Img,
	useDisclosure,
	Spinner,
	Badge,
	Wrap,
	WrapItem,
	Tooltip,
} from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import isElectron from 'is-electron';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import { config } from '../../config';
import { camelCase, startCase } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionsCheckoutModalUpdate } from '../../redux/actions/subscriptions';
import useQueryParams from '../../util/useQueryParams';
import { useLocation } from 'react-router-dom';
import {
	cancelSubscriptionAPI,
	deleteSubscriptionAPI,
	getCheckoutUrl,
	verifySubscriptionAPI,
	getPaymentEnabledStatus,
} from './Service';
import getCheckoutPageURL from '../../functions/payments/getCheckoutPageURL';
import tierTableConfig from './Config';
import DeactivateSubscriptionAlert from './DeactivateSubscriptionAlert';
import History, { HistoryWithSlug } from '../../util/History';
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import mandalaOne from '../../assets/patterns/mandala_one.png';
import GetProfileSlug from '../../util/ProfileSlug';
import getUsage from '../../functions/settings/usage/getUsage';
import { currentProfileTierUpdate } from '../../redux/actions/profile';
import dayjs from 'dayjs';

/**
 * Different payment subscription plans
 * @typedef {'monthly' | 'annual'} SubscriptionPlan
 */

/**
 *
 * @param {{} & import('@chakra-ui/react').ButtonProps} param0
 * @returns
 */
const UpgradeButton = ({ ...rest }) => {
	return (
		<Button
			bg="hsl(271, 70%, 50%)"
			display="flex"
			alignItems="center"
			_focus={{
				boxShadow: 'none',
			}}
			_hover={{
				bg: 'hsl(271, 70%, 60%)',
			}}
			fontSize="1rem"
			fontWeight="normal"
			borderRadius="md"
			width="150px"
			{...rest}
		/>
	);
};

/**
 * @type {{
 * 	isLoading: boolean,
 * 	data?: {
 * 		plan: 'MONTHLY' | 'ANNUAL',
 * 		status: 'active' | 'non_renewing',
 * 		subscriptionEndDate?: string,
 * 	}
 * }}
 */
const initSubscriptionDetails = {
	isLoading: false,
};

/**
 *
 * @param {{
 * isOpen: boolean,
 * onClose: () => void,
 * }} param0
 * @returns
 */
const PaymentModal = ({ isOpen, onClose }) => {
	const { email } = useSelector((store) => store.user.info);
	const { selected: selectedProfile } = useSelector((store) => store.profiles);
	const [payment, setPayment] = useState(false);
	const [currency, setCurrency] = React.useState('USD');

	const dispatch = useDispatch();
	const query = useQueryParams();
	const checkout = query.get('checkout');
	/**
	 * @type {SubscriptionPlan}
	 */
	const subscriptionPlan = query.get('subscription_plan');
	/**
	 * Query param to trigger redirect to desktop app
	 * @type {boolean}
	 */
	const desktopRedirect = query.get('desktopRedirect');
	/**
	 * Query param to obtain user selected currency in checkout
	 * @type {String} 'INR' | 'USD'
	 */
	const selectedCurrency = query.get('currency');
	const { pathname } = useLocation();
	const {
		isOpen: deactivateSubscriptionAlertIsOpen,
		onOpen: deactivateSubscriptionAlertOnOpen,
		onClose: deactivateSubscriptionAlertOnClose,
	} = useDisclosure();
	const { slug } = GetProfileSlug();
	/**
	 * @type {'FREE' | 'PREMIUM'}
	 */
	const activeSubscriptionTier = useSelector(
		(store) => store.profiles.selected.tier
	);
	const brains = useSelector((store) => store.brains);
	const route = useSelector((store) => store.navigation.route);
	const [details, setDetails] = useState({
		nodes: 0,
		upperLimit: 0,
		percentage: 0,
	});
	const [subscriptionDetails, setSubscriptionDetails] = useState({
		...initSubscriptionDetails,
	});

	/**
	 * Initiates subscription creation and opens
	 *	payment gateway checkout page, on successful payment,
	 * verifies payment and redirects back to the desktop app
	 */
	const handleSubscriptionCheckout = useCallback(
		/**
		 * @param {SubscriptionPlan} plan
		 * @param {String} currency
		 */
		async (plan, currency) => {
			try {
				const responseData = await getCheckoutUrl({
					currency,
					plan,
				});
				window.location.href = `${responseData.results.hosted_page.url}`; // navigate to checkout page
			} catch (error) {
				console.error(error);
			}
		},
		[]
	);

	/**
	 * Subscription button click handler
	 * @param {SubscriptionPlan} plan
	 */
	const handleSubscriptionClick = async (plan) => {
		var pathEnd = selectedProfile.isTeam
			? `/${slug}/settings?checkout=true&subscription_plan=${plan}&currency=${currency}`
			: `/settings?checkout=true&subscription_plan=${plan}&currency=${currency}`;

		console.log(`${config.app.BASEURL}${pathEnd}}`, ':', pathEnd);
		if (isElectron()) {
			window.open(`${config.app.BASEURL}${pathEnd}`, '_blank');
		} else History.push(`${pathEnd}`);
	};

	/**
	 * Verify(get current subscription) and update the tier info in redux
	 */
	const updateSubscriptionAndRedirect = useCallback(async () => {
		try {
			setSubscriptionDetails((old) => ({
				...old,
				isLoading: true,
				data: undefined,
			}));
			const verificationResponse = await verifySubscriptionAPI();
			if (verificationResponse.error)
				throw new Error(`verifySubscriptionAPI failed!`);
			const tier = verificationResponse?.results?.profile?.tier;
			if (!tier) throw new Error(`tier is invalid!`);
			if (tier === 'PREMIUM') {
				setSubscriptionDetails((old) => ({
					...old,
					isLoading: false,
					// data: {
					// 	plan: verificationResponse.results.subscription.plan,
					// 	status: verificationResponse.results.subscription.status,
					// 	subscriptionEndDate: verificationResponse.results.subscription
					// 		?.end_date
					// 		? dayjs(
					// 				verificationResponse.results.subscription.end_date
					// 		  ).format('DD/MM/YYYY')
					// 		: undefined,
					// },
					data: {
						plan: 'ANNUAL',
						status: 'active',
						subscriptionEndDate: undefined,
					},
				}));
			} else if (tier === 'FREE')
				setSubscriptionDetails((old) => ({
					...old,
					isLoading: false,
				}));
			dispatch(currentProfileTierUpdate(tier));
			if (desktopRedirect && !isElectron()) {
				// redirect to desktop app
				const pathEnd = selectedProfile.isTeam
					? `/${slug}/settings?checkout=true`
					: `/settings?checkout=true`;
				window.location.href = `maya://data?={"event":"open","args":"${pathEnd.slice(
					1
				)}"}`;
			}
		} catch (error) {
			console.error(error);
		}
	}, [desktopRedirect, dispatch, selectedProfile.isTeam, slug]);

	/**
	 * Deactivate/Cancel active subscription
	 */
	const handleDeactivateSubscription = async () => {
		try {
			const response = await cancelSubscriptionAPI();
			deactivateSubscriptionAlertOnClose(); // close deactivate alert
			await updateSubscriptionAndRedirect();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		/**
		 * This useEffect initiates subscription automatically
		 * if checkout, subscription plan and currency is set in query params
		 */
		if (isOpen && !isElectron()) {
			if (checkout && subscriptionPlan && selectedCurrency) {
				handleSubscriptionCheckout(subscriptionPlan, selectedCurrency);
			}
		}
	}, [
		checkout,
		selectedCurrency,
		handleSubscriptionCheckout,
		isOpen,
		subscriptionPlan,
	]);

	useEffect(() => {
		getUsage().then((res) => {
			setDetails(res);
		});
	}, [brains, route, activeSubscriptionTier]);

	useEffect(() => {
		// Update subscription details on modal open
		if (isOpen) updateSubscriptionAndRedirect();
	}, [isOpen, updateSubscriptionAndRedirect]);

	useEffect(() => {
		getPaymentEnabledStatus().then((res) => {
			setPayment(res.status);
		});
	}, []);

	return (
		<CustomModal
			isOpen={isOpen}
			onClose={onClose}
			size="md"
			fontFamily="body"
			isCentered={isElectron()}
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				minHeight="60vh"
				maxWidth="40rem"
				overflow="hidden"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				{true && (
					<Img
						width={{ sm: '105vw', md: '95vw', lg: '45vw' }}
						height={{ sm: '105vw', md: '95vw', lg: '45vw' }}
						objectFit="cover"
						src={mandalaOne}
						position="absolute"
						opacity="0.07"
						top="0"
						left="0"
						right="0"
						bottom="0"
						textAlign="center"
						margin="auto"
						marginLeft="-45vh"
						marginTop={{ sm: '-35vh', md: '-55vh', lg: '-40vh' }}
						zIndex="1"
					/>
				)}
				<HeaderWithDescription
					header={'Upgrade to Premium'}
					desc={
						details.nodes > details.upperLimit &&
						activeSubscriptionTier === 'FREE'
							? `Oops, looks like you've used up ${details.nodes} of the free ${details.upperLimit} node limit. Upgrade here to continue.`
							: "Become a power user today to get all of Maya's benefits."
					}
					paddingLeft="1rem"
					paddingTop="1rem"
					paddingBottom="0.5rem"
				/>
				<ModalBody p="4" display="flex" flexDir="column">
					<Table>
						<Thead>
							<Tr>
								<Th
									color="white"
									fontSize="x-medium"
									fontWeight="semibold"
									textTransform="none"
									borderColor="#ffffff1f"
									fontFamily="body"
								>
									Features
								</Th>
								<Th
									color="white"
									fontSize="x-medium"
									fontWeight="semibold"
									textTransform="none"
									borderColor="#ffffff1f"
									bg="#00000017"
									fontFamily="body"
								>
									Free
								</Th>
								<Th
									color="white"
									fontSize="x-medium"
									fontWeight="semibold"
									textTransform="none"
									borderColor="#ffffff1f"
									bg="#00000026"
									fontFamily="body"
									w="15rem"
								>
									Premium
								</Th>
							</Tr>
						</Thead>
						<Tbody>
							{tierTableConfig.map((row, idx) => (
								<Tr key={row.id}>
									<Td borderColor="#ffffff1f">
										{row.feature}{' '}
										{row.tooltip ? (
											<Tooltip label={row.tooltip}>
												<InfoOutlineIcon
													boxSize="0.7rem"
													width="0.7rem"
													marginLeft="0.2rem"
												/>
											</Tooltip>
										) : null}
									</Td>
									<Td borderColor="#ffffff1f" bg="#00000017">
										{row.free}
									</Td>
									<Td borderColor="#ffffff1f" bg="#00000026">
										{row.premium}
									</Td>
								</Tr>
							))}
							<Tr maxHeight="0">
								<Td borderColor="#ffffff00">Price</Td>
								<Td borderColor="#ffffff00" bg="#00000017">
									Free
								</Td>
								<Td borderColor="#ffffff00" bg="#00000026">
									{subscriptionDetails.isLoading ? (
										<Spinner />
									) : activeSubscriptionTier === 'PREMIUM' ? (
										subscriptionDetails.data ? (
											<Box fontSize="0.85rem" w="8rem">
												{subscriptionDetails.data.status ===
												'active' ? (
													<>
														<Wrap
															spacing="1"
															mb="1"
															minWidth="10rem"
														>
															<WrapItem>
																{/* <Badge
																	variant="subtle"
																	colorScheme="yellow"
																>
																	{
																		subscriptionDetails.data
																			.plan
																	}
																</Badge> */}
															</WrapItem>
															<WrapItem>
																<Badge
																	variant="subtle"
																	colorScheme="green"
																	ml="-4px" // Visual alignment
																>
																	ACTIVE
																</Badge>
															</WrapItem>
														</Wrap>
														{/* <Link
															onClick={() =>
																deactivateSubscriptionAlertOnOpen()
															}
															color="gray"
															textDecoration="underline"
															whiteSpace="nowrap"
														>
															Deactivate Subscription
														</Link> */}
													</>
												) : (
													<Wrap spacing="1" minWidth="10rem">
														<WrapItem>
															<Badge
																variant="subtle"
																colorScheme="yellow"
															>
																{subscriptionDetails.data.plan}
															</Badge>
														</WrapItem>
														<WrapItem>
															<Badge
																variant="subtle"
																colorScheme="red"
															>
																cancelled
															</Badge>
														</WrapItem>
														<WrapItem>
															<Badge
																variant="subtle"
																colorScheme="orange"
																display="flex"
															>
																Expires on
																<Text color="white" ml="1">
																	{
																		subscriptionDetails.data
																			.subscriptionEndDate
																	}
																</Text>
															</Badge>
														</WrapItem>
													</Wrap>
												)}
												{/* {process.env.NODE_ENV === 'development' ? (
													<>
														<br />
														<Link
															onClick={async () => {
																try {
																	await deleteSubscriptionAPI();
																} catch (error) {
																	console.error(error);
																}
															}}
															color="gray"
															textDecoration="underline"
														>
															Delete Subscription
														</Link>
													</>
												) : null} */}
											</Box>
										) : null
									) : (
										<>
											<UpgradeButton
												onClick={() => {
													if (payment) {
														handleSubscriptionClick('monthly');
													} else {
														window.Intercom(
															'showNewMessage',
															'Initiate my upgrade to the monthly premium plan!'
														);
													}
												}}
												loadingText="Processing"
												isLoading={subscriptionPlan === 'monthly'}
												disabled={subscriptionPlan}
											>
												<Text
													fontSize="1.4rem"
													fontWeight="normal"
													mr="1"
												>
													{currency === 'USD' ? '$15' : '₹1,200'}
												</Text>
												<Box
													fontSize="x-small"
													fontWeight="normal"
													display="flex"
													flexDirection="column"
													alignItems="flex-start"
													pb="0.4rem"
												>
													<Text>per</Text>
													<Text fontSize="sm" lineHeight="0.6rem">
														month
													</Text>
												</Box>
											</UpgradeButton>
											<Spacer h="2" />
											<UpgradeButton
												loadingText="Processing"
												onClick={() => {
													if (payment) {
														handleSubscriptionClick('annual');
													} else {
														window.Intercom(
															'showNewMessage',
															'Initiate my upgrade to the annual premium plan!'
														);
													}
												}}
												isLoading={subscriptionPlan === 'annual'}
												disabled={subscriptionPlan}
											>
												<Text
													fontSize="1.4rem"
													fontWeight="normal"
													mr="1"
												>
													{currency === 'USD' ? '$150' : '₹12,000'}
												</Text>
												<Box
													fontSize="x-small"
													fontWeight="normal"
													display="flex"
													flexDirection="column"
													alignItems="flex-start"
													pb="0.4rem"
												>
													<Text>per</Text>
													<Text fontSize="sm" lineHeight="0.6rem">
														year
													</Text>
												</Box>
											</UpgradeButton>
										</>
									)}
									{activeSubscriptionTier === 'PREMIUM' ? null : (
										<Box
											display="flex"
											flexDirection="row"
											alignItems="center"
											marginTop="2"
										>
											USD
											<Switch
												size="sm"
												paddingX="2"
												colorScheme="purple"
												onChange={(e) => {
													if (e.currentTarget.checked) {
														setCurrency('INR');
													} else {
														setCurrency('USD');
													}
												}}
											/>
											INR
											<Tooltip label="Choose INR if you will be transacting using an Indian card or address, otherwise choose USD. Prices displayed exclude taxes.">
												<InfoOutlineIcon
													boxSize="12px"
													marginLeft="10px"
												/>
											</Tooltip>
										</Box>
									)}
								</Td>
							</Tr>
						</Tbody>
					</Table>
					<DeactivateSubscriptionAlert
						isOpen={deactivateSubscriptionAlertIsOpen}
						onClose={deactivateSubscriptionAlertOnClose}
						onDeactivateBtnClick={handleDeactivateSubscription}
					/>
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

export default PaymentModal;
