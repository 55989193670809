import React from 'react';
import { MdPermContactCalendar } from 'react-icons/md';
import Fuse from 'fuse.js';
import _ from 'lodash';
import SearchItem from './SearchItem';

const PhoneTypeSearch = ({
	placeholderText: text,
	placeholderType: type,
	currentPlaceholderId,
	setPlaceholderSearchItems,
	setSearchBarLoadingStatus,
	searchItems,
	selectedIndex,
	contacts,
}) => {
	const [allItems, setAllItems] = React.useState([
		{ name: 'Sibesh Kar', email: 'sibesh96@gmail.com', phone: '9829118530' },
		{
			name: 'Shubham Mishra',
			email: 'shubham.root@gmail.com',
			phone: '9542226312',
		},
		{
			name: 'Mukesh Bhai',
			email: 'jio@roxx.com',
			phone: '81828810992',
		},
	]);

	React.useEffect(() => {
		var contactsArray = [];
		for (let key in contacts) {
			contactsArray = _.concat(contactsArray, contacts[key]);
		}

		var phoneArray = [];

		contactsArray.forEach((contact) => {
			contact.phoneNumbers.forEach((phone) => {
				phoneArray.push({ name: contact.displayName, phone: phone });
			});
		});
		setAllItems(phoneArray);
	}, [contacts]);

	const options = {
		// isCaseSensitive: false,
		includeScore: true,
		// shouldSort: true,
		// includeMatches: false,
		// findAllMatches: false,
		// minMatchCharLength: 1,
		// location: 0,
		threshold: 0.5, // the less the more accurate the match
		distance: 10,
		// useExtendedSearch: false,
		// ignoreLocation: false,
		// ignoreFieldNorm: false,
		keys: ['name', 'phone'],
	};

	const fuse = new Fuse(allItems, options);

	const [filteredItems, setFilteredItems] = React.useState([]);

	/**
	 * This value will get selected by the placeholder, in this case it is email
	 * @param {} array
	 */
	const selectFunction = (itemsArray) => {
		return itemsArray.map((thing) => {
			return { show: thing.item.phone, submit: thing.item.phone };
		});
	};

	var doneTypingInterval = 10; //time in ms, 5 second for example
	const typingTimer = React.useRef(null);

	const onTextChange = (searchText) => {
		if (
			searchText &&
			_.findIndex(searchItems, function (o) {
				return o.show === searchText;
			}) === -1
		) {
			var results = fuse.search(searchText);
			setFilteredItems(results);
			setPlaceholderSearchItems(selectFunction(results));
		}
	};

	React.useEffect(() => {
		clearTimeout(typingTimer.current);
		if (text) {
			typingTimer.current = setTimeout(() => {
				onTextChange(text);
			}, doneTypingInterval);
		}
	}, [text, doneTypingInterval]);

	return filteredItems.map((contact, i) => {
		console.log(contact);
		return (
			<SearchItem
				selectedIndex={selectedIndex}
				key={i}
				i={i}
				searchItemMainText={contact.item.name}
				searchItemSubText={contact.item.phone}
				reactIcon={<MdPermContactCalendar />}
			/>
		);
	});
};

export default PhoneTypeSearch;
