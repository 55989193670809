import { extendTheme } from '@chakra-ui/react';
const customTheme = {
	components: {
		Tooltip: {
			baseStyle: {
				bg: 'maya_dark.150',
				color: 'white',
			},
		},
	},
	colors: {
		maya_light: {
			400: '-webkit-linear-gradient(180deg, #F6F6F6 0%, rgba(234, 234, 234, 0.97) 100%)',
			200: '#F6F6F6',
			100: '#FAFAFA',
			50: '#FCFCFC',
		},
		maya_dark: {
			500: '#686767',
			450: '#606060',
			400: '#585858',
			300: '#313131',
			250: '#3c3c3c',
			200: '#3F3E3E',
			150: '#464646',
			100: '#474545',
			75: '#ABA7A7',
			50: '#323334',
			25: '#ABA7A7',
			15: '#CAC6C6',
		},
		dashboard: {
			bg_light: {
				100: '#FCFCFC',
				200: '#F3F3F3',
			},
			bg_dark: {
				100: '#484545',
				400: '#3C3C3C',
			},
			font_light: 'maya_dark.500',
			font_dark: 'white',
			border_light: '#f2f2f2',
			border_dark: '#3c3c3c',
		},
		card_ui: {
			bg_light: '#ffffff',
			focus_light: '#F9F9F9',
			font_light: { 100: '#6C6C6C', 200: '#6C6C6C' },
			placeholder_light: '#E0EEF3',
			divider_light: '#F2F2F2',
			bg_dark: '#343434',
			focus_dark: '#434343',
			font_dark: { 100: '#E8E8E8', 200: '#FFFEFE' },
			divider_dark: '#3E3E3E',
			placeholder_dark: '#5A5959',
			purple: '#9B51E0',
		},
		brown: {
			200: '#787373',
			100: '#D4D4D4',
			50: '#E5E3E3',
		},
	},
	fonts: {
		body: 'Lato, sans-serif',
	},
	config: {
		useSystemColorMode: false,
		initialColorMode: 'dark',
	},
	styles: {
		global: {
			html: {
				backgroundColor: 'transparent',
				fontFamily: 'body',
				height: '100%',
			},
			body: {
				backgroundColor: 'transparent',
				fontFamily: 'body',
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				overflow: 'hidden',
			},
			img: {
				pointerEvents: 'none',
			},
		},
	},
};

export default extendTheme(customTheme);
