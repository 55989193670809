import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Box, useColorMode } from '@chakra-ui/react';
import { v4 as uuidv4 } from 'uuid';
import { connect, useDispatch, useSelector } from 'react-redux';
import IntentPlaceholderInput from './IntentPlaceholderInput';
import {
	placeholderSearchCancel,
	placeholderSearchChange,
	placeholderSearchInit,
	placeholderSearchNext,
	suggestionsModeChange,
} from '../../../../../../../redux/actions/cardUIV2';
import CommandBarContext from '../../../CommandBarContext';

const intentArraytoJSON = (intentArray) => {
	let newArray = {};
	for (let key in intentArray) {
		if (intentArray[key].type === 'placeholder') {
			console.log(intentArray, intentArray[key]);
			newArray[intentArray[key].key] = {
				value: intentArray[key].value,
				meta: intentArray[key].meta,
			};
		}
	}
	// console.log(newArray, intentArray);
	return newArray;
};

/**
 *
 * @param {{
 * 	placeholderComponentIds: string[],
 * 	onCancel: () => void,
 *		onSubmit: (formData: { [key: string]: { value: string, meta: any }}) => void,
 * }} param0
 * @returns
 */
const Query = ({
	placeholderComponentIds,
	onCancel,
	onSubmit,
	toggleCardsVisibility,
}) => {
	const dispatch = useDispatch();
	const { currentInputRef } = useContext(CommandBarContext);
	const allPlaceholderComponents = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.entities.components
	);
	const placeholderComponentsValues = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.searchArea.placeholderComponents
	);
	const placeholderSuggestions = useSelector(
		/**
		 * @param {{cardUIV2: import('../../../../../../../redux/reducers/cardUIV2').default}} state
		 */
		(state) => state.cardUIV2.commandBar.suggestionsArea.placeholderSearch
	);
	/**
	 * Components that make up the selected intent (ie. components available for selected intent)
	 * @type {{
	 *		allIds: string[];
	 *			byId: {
	 *				[x: string]: import('../../../../../../../redux/reducers/cardUIV2').CuiIntentComponent;
	 *			};
	 *	}}
	 */
	const intentPlaceholderComponents = useMemo(
		() =>
			placeholderComponentIds.reduce(
				(prev, curr, idx, arr) => {
					if (allPlaceholderComponents.allIds.includes(curr)) {
						prev.allIds.push(curr);
						prev.byId[curr] = allPlaceholderComponents.byId[curr];
					}
					return prev;
				},
				{
					allIds: [],
					byId: {},
				}
			),
		[
			allPlaceholderComponents.allIds,
			allPlaceholderComponents.byId,
			placeholderComponentIds,
		]
	);
	/**
	 * Placeholder Ids of the intent components that support form inputs
	 */
	const inputPlaceholderIds = intentPlaceholderComponents.allIds.filter(
		(compId) => intentPlaceholderComponents.byId[compId].type !== 'prompt'
	);
	const { colorMode } = useColorMode();
	const color = {
		light: 'card_ui.font_light.200',
		dark: 'card_ui.font_dark.200',
	};

	const onNext = () => {
		dispatch(placeholderSearchNext());
	};

	const handleSubmit = () => {
		const lastInputPlaceholderId =
			inputPlaceholderIds[inputPlaceholderIds.length - 1];
		if (!lastInputPlaceholderId) {
			console.error('lastInputPlaceholderId invalid!');
			return;
		}
		/** Create fomrData from placeholder inputs and components for onSubmit */
		const formData = intentPlaceholderComponents.allIds.reduce(
			(prev, curr, idx, arr) => {
				const componentObj = intentPlaceholderComponents.byId[curr];
				if (
					// if input type and present in values
					componentObj.type !== 'prompt' &&
					placeholderComponentsValues.allIds.includes(curr)
				) {
					/**
					 * Special handling of the last input placeholder if a time/timeRange type
					 * It needs to have the 1st suggestion item autoSelected. Thus we simply
					 * extract the required info for formData from the 1st suggestion Item for
					 * this case.
					 */
					if (
						curr === lastInputPlaceholderId &&
						(componentObj.type === 'time') |
							(componentObj.type === 'timerange')
					) {
						// Set value and meta(if avail)
						prev[componentObj.value] = {
							// extract value from placeholderSuggestions instead of placeholderComponentsValues
							value: placeholderSuggestions.searchItems[0].show,
						};
						// extract meta from placeholderSuggestions instead of placeholderComponentsValues
						const meta = placeholderSuggestions.searchItems[0].submit;
						if (meta) prev[componentObj.value]['meta'] = meta;
					} else if (componentObj.value) {
						// Run this for other normal cases
						// Set value(based on placeholder type) and meta(if avail)
						if (componentObj.type === 'custom') {
							// only for custom placeholder
							prev[componentObj.value] = {
								// extract value from `searchValue` instead of `inputValue`
								value: placeholderComponentsValues.byId[curr]
									.searchValue,
							};
						} // for other placeholder types
						else
							prev[componentObj.value] = {
								value: placeholderComponentsValues.byId[curr]
									.inputValue,
							};
						const { meta } = placeholderComponentsValues.byId[curr];
						if (meta) prev[componentObj.value]['meta'] = meta;
					}
				}
				return prev;
			},
			{}
		);
		onSubmit(formData);
	};

	useEffect(() => {
		if (inputPlaceholderIds.length === 0) {
			onSubmit({});
		} else {
			/** Change suggestions mode to placeholder as there are input placeholders available */
			dispatch(suggestionsModeChange({ mode: 'placeholder' }));
		}
	}, [dispatch, inputPlaceholderIds.length]); // onSubmit is excluded due to multiple re-renders

	return (
		<Box
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			width={intentPlaceholderComponents.allIds.length > 4 ? '40vw' : '100%'}
			pl={1}
			onClick={() => {
				if (currentInputRef.current) currentInputRef.current.select();
			}}
		>
			<Box
				mt={1}
				display="flex"
				alignItems="center"
				flexWrap={
					intentPlaceholderComponents.allIds.length > 4 ? 'wrap' : 'wrap'
				}
			>
				{intentPlaceholderComponents.allIds.map((componentId, i) => {
					return intentPlaceholderComponents.byId[componentId].type ===
						'prompt' ? (
						<Box
							key={i}
							fontWeight="500"
							color={color[colorMode]}
							fontSize="1.1rem"
							mr={2}
							whiteSpace="nowrap"
							mb={1}
							fontFamily="body"
							mt={1}
						>
							{intentPlaceholderComponents.byId[componentId].value}
						</Box>
					) : (
						<IntentPlaceholderInput
							item={intentPlaceholderComponents.byId[componentId]}
							key={i}
							placeholderId={componentId}
							onNext={onNext}
							onCancel={() => {
								onCancel();
								dispatch(placeholderSearchCancel());
								// toggleCardsVisibility(true);
							}}
							onSelect={() =>
								dispatch(
									placeholderSearchChange({
										type: 'selectedPlaceholderComponent',
										selectedPlaceholderComponent: componentId,
									})
								)
							}
							onSubmit={handleSubmit}
						/>
					);
				})}
			</Box>
		</Box>
	);
};

export default Query;
