import React from 'react';
import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	Box,
	Image,
	LightMode,
	useColorModeValue,
	useColorMode,
	Img,
} from '@chakra-ui/react';
import { manJumping } from '../../assets/setup/doodles';
import { useLocation } from 'react-router-dom';
import icon from '../../assets/icon.png';
import isElectron from 'is-electron';
import { getOS } from '../../util/PlatformUtils/platform';
import IconContainer from '../../util/React_Icon';
import {
	AiFillApple,
	AiFillWindows,
	AiOutlinePlayCircle,
} from 'react-icons/ai';

import { DiLinux } from 'react-icons/di';

import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import mandalaOne from '../../assets/patterns/mandala_one.png';
import { FaCloud } from 'react-icons/fa';
const https = require('https');

const DownloadSection = () => {
	const { colorMode } = useColorMode();
	const fontColor = { light: '#5C5A5A', dark: 'white' };
	const [selectedPlatform, selectPlatform] = React.useState(getOS());
	const handleClick = () => {
		https.get(
			'https://api.github.com/repos/mayahq/maya-desktop/releases/latest',
			(response) => {
				let data = '';
				let tag = '';
				let releaseName = '';
				response.on('data', (chunk) => {
					data += chunk;
				});
				response.on('end', () => {
					var gitResponse = JSON.parse(data);
					tag = gitResponse.tag_name;
					releaseName = gitResponse.name;
					let platform = selectedPlatform;
					if (platform === 'Windows') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya.Setup.' +
								releaseName +
								'.exe'
						);
					} else if (platform === 'Linux') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya-' +
								releaseName +
								'.AppImage'
						);
					} else if (platform === 'Mac') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya-' +
								releaseName +
								'.dmg'
						);
						// window.open(
						//   "https://drive.google.com/file/d/1qb8Jgy6__j5-1_5KKxciLzUw1gEv4oco/view?usp=sharing"
						// );
					}
				});
			}
		);
	};
	const handleClickArm = () => {
		https.get(
			'https://api.github.com/repos/mayahq/maya-desktop/releases/latest',
			(response) => {
				let data = '';
				let tag = '';
				let releaseName = '';
				response.on('data', (chunk) => {
					data += chunk;
				});
				response.on('end', () => {
					var gitResponse = JSON.parse(data);
					tag = gitResponse.tag_name;
					releaseName = gitResponse.name;
					let platform = selectedPlatform;
					if (platform === 'Mac') {
						window.open(
							'https://github.com/mayahq/maya-desktop/releases/download/' +
								tag +
								'/Maya-' +
								releaseName +
								'-arm64.dmg'
						);
						// window.open(
						//   "https://drive.google.com/file/d/1qb8Jgy6__j5-1_5KKxciLzUw1gEv4oco/view?usp=sharing"
						// );
					}
				});
			}
		);
	};

	return (
		<Box
			display="flex"
			paddingLeft="2rem"
			fontFamily="body"
			fontSize="18px"
			flexDirection="column"
		>
			<Box
				marginTop="1rem"
				color={fontColor[colorMode]}
				width="24rem"
				marginBottom="1rem"
			>
				Get started by downloading and logging into the Maya desktop app for
				your platform.
			</Box>

			{selectedPlatform === 'Mac' ? (
				<Box width="24rem">
					<LightMode>
						<Button
							colorScheme="purple"
							size="sm"
							marginTop="2rem"
							onClick={handleClick}
						>
							{`For Mac (Intel)`}
						</Button>
					</LightMode>
					<LightMode>
						<Button
							colorScheme="purple"
							size="sm"
							marginLeft="14px"
							marginTop="2rem"
							onClick={handleClickArm}
						>
							{`For Mac (M1 Silicon)`}
						</Button>
					</LightMode>
				</Box>
			) : (
				<Box width="24rem">
					<LightMode>
						<Button
							colorScheme="purple"
							size="sm"
							marginTop="2rem"
							onClick={handleClick}
						>
							{`Download For ${selectedPlatform}`}
						</Button>
					</LightMode>
				</Box>
			)}
			<Box
				marginTop="1rem"
				fontSize="0.9rem"
				opacity="0.8"
				display="flex"
				alignItems="center"
			>
				<Box marginRight="0.3rem"> Available for </Box>
				<Box
					marginRight="0.2rem"
					onClick={() => selectPlatform('Mac')}
					cursor="pointer"
				>
					<IconContainer
						icon={<AiFillApple />}
						value={{
							color: selectedPlatform === 'Mac' ? '#eda0e7' : 'white',
							className: 'global-class-name',
							size: '17px',
						}}
					/>
				</Box>
				<Box
					marginRight="0.2rem"
					onClick={() => selectPlatform('Windows')}
					cursor="pointer"
				>
					<IconContainer
						icon={<AiFillWindows />}
						value={{
							color:
								selectedPlatform === 'Windows' ? '#eda0e7' : 'white',
							className: 'global-class-name',
							size: '17px',
						}}
					/>
				</Box>
				<Box
					marginRight="0.2rem"
					onClick={() => selectPlatform('Linux')}
					cursor="pointer"
				>
					<IconContainer
						icon={<DiLinux />}
						value={{
							color: selectedPlatform === 'Linux' ? '#eda0e7' : 'white',
							className: 'global-class-name',
							size: '17px',
						}}
					/>
				</Box>
			</Box>
		</Box>
	);
};

/**
 * Download Wizard
 */

const DownloadApp = ({ isOpen, onOpen, onClose }) => {
	const btnRef = React.useRef();

	let query = new URLSearchParams(useLocation().search);
	let location = useLocation();

	React.useEffect(() => {
		if (!isElectron() && location.pathname !== '/store') {
			// onOpen();
		}
	}, [onOpen, location]);

	return (
		<CustomModal
			finalFocusRef={btnRef}
			onClose={onClose}
			isOpen={true}
			closeOnOverlayClick={true}
			closeOnEsc={false}
		>
			<ModalOverlay />
			<ModalContent
				mt={'5rem'}
				maxWidth="550px"
				height="300px"
				borderRadius="0.5rem"
				bg={useColorModeValue('white', 'maya_dark.300')}
				boxShadow="10px 10px 30px rgba(0, 0, 0, 0.15)"
				overflow="hidden"
			>
				<ModalHeader
					pb={0}
					display="flex"
					alignItems="center"
					justifyContent="space-between"
				>
					<Box display="flex" alignItems="center" marginLeft="0.5rem">
						<Image src={icon} boxSize="2rem" />
						<Box fontSize="2rem" ml={3}>
							Welcome onboard!
						</Box>
					</Box>
				</ModalHeader>
				<ModalBody paddingX="0">
					<DownloadSection />
					<Img
						width={{ md: '35vw' }}
						height={{ md: '35vw' }}
						objectFit="cover"
						src={mandalaOne}
						position="absolute"
						opacity="0.07"
						left="0"
						right="0"
						bottom="0"
						textAlign="center"
						margin="auto"
						marginRight="-25vh"
						marginBottom={{ sm: '-35vh', md: '-55vh', lg: '-40vh' }}
						zIndex="1"
					/>
				</ModalBody>
			</ModalContent>
		</CustomModal>
	);
};

export default DownloadApp;
