import React from 'react';
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalCloseButton,
	useColorModeValue,
	Code,
} from '@chakra-ui/react';
import isElectron from 'is-electron';
import ReactJson from 'react-json-view';
import HeaderWithDescription from '../../components/Reusable/HeaderWithDesc';

const NodePreviewModal = ({ isOpen, onOpen, onClose, metadata, label }) => {
	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="xl"
			fontFamily="body"
			isCentered={isElectron()}
		>
			<ModalOverlay />
			<ModalContent
				bg={useColorModeValue('white', 'maya_dark.300')}
				maxHeight="500px"
				maxWidth="500px"
			>
				<ModalCloseButton
					right="2"
					border="none"
					cursor
					_focus={{ outline: 'none' }}
				/>
				<ModalBody paddingX="0" width="500px">
					<HeaderWithDescription
						desc={
							<Box>
								<Code children={metadata.type} />
							</Box>
						}
						paddingX="0"
						marginX="1rem"
						paddingY="0.2rem"
						borderBottom="none"
					/>
					<Box
						overflow="scroll"
						height="300px"
						width="100%"
						paddingLeft="0.4rem"
					>
						<ReactJson theme="railscasts" src={metadata} />
					</Box>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default NodePreviewModal;
