import React from 'react';
import { Box } from '@chakra-ui/react';
import { connect } from 'react-redux';
import {
	ConnectedEmailTypeSearch,
	ConnectedStringTypeSearch,
	ConnectedFileTypeSearch,
	ConnectedTimeTypeSearch,
	ConnectedAppTypeSearch,
	ConnectedPhoneTypeSearch,
	ConnectedTimeRangeTypeSearch,
	ConnectedCustomTypeSearch,
} from './PlaceholderTypes';

// const StringTypeSearch = ({ placeholderText, placeholderType }) => {
//   return <Box></Box>;
// };

// const DateTypeSearch = ({ placeholderText, placeholderType }) => {
//   return <Box></Box>;
// };

const TypeSelectArray = {
	string: ConnectedStringTypeSearch,
	time: ConnectedTimeTypeSearch,
	email: ConnectedEmailTypeSearch,
	file: ConnectedFileTypeSearch,
	app: ConnectedAppTypeSearch,
	phone: ConnectedPhoneTypeSearch,
	timerange: ConnectedTimeRangeTypeSearch,
	custom: ConnectedCustomTypeSearch,
};

const TypeSelector = ({
	placeholderText,
	placeholderType,
	currentPlaceholderId,
	placeholderDetails,
}) => {
	const Select = () => {
		return TypeSelectArray[placeholderType]
			? TypeSelectArray[placeholderType]
			: Box;
	};

	const TypeSearchSection = Select();
	return (
		<TypeSearchSection
			placeholderText={placeholderText}
			placeholderType={placeholderType}
			currentPlaceholderId={currentPlaceholderId}
			placeholderDetails={placeholderDetails}
		/>
	);
};

const mapStateToProps = (state) => {
	let { placeholderText, type } = state.cardUI.centerSearch.middle;
	let { currentPlaceholderId } = state.cardUI.centerSearch.middle;
	let placeholderDetails =
		state.cardUI.centerSearch.top.intentObjectArray.byId[
			currentPlaceholderId
		];
	return {
		placeholderText,
		placeholderType: type,
		currentPlaceholderId,
		placeholderDetails,
	};
};

export default connect(mapStateToProps, null)(TypeSelector);
