import { Button } from '@chakra-ui/button';
import { Checkbox } from '@chakra-ui/checkbox';
import { Flex, Spacer, Stack, Text } from '@chakra-ui/layout';
import { Textarea } from '@chakra-ui/textarea';
import {
	FormLabel,
	FormControl,
	FormErrorMessage,
	Input,
	Icon,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import StepperContext from './StepperContext';
import StepperFooter from './StepperFooter';
import { AiOutlineDownload } from 'react-icons/ai';
import { useFormik } from 'formik';

/**
 * # Import Option Definitions
 *
 * Defines all the options for importing flow JSON.
 */
const importOptionDefs = [
	{
		id: 'clipboard',
		label: 'Clipboard',
	},
	{
		id: 'library',
		label: 'Library',
	},
	{
		id: 'examples',
		label: 'Examples',
	},
];

const SelectFlowStepperView = () => {
	const {
		onModalClose,
		stepIndex,
		setStepIndex,
		mayaFlowJson,
		setMayaFlowJson,
	} = useContext(StepperContext);
	/** formik instance for managing the import flow json form state */
	const formik = useFormik({
		initialValues: {
			/**
			 * @type {'clipboard' | 'library' | 'examples'}
			 */
			selectedImportOption: 'clipboard',
			textAreaValue: mayaFlowJson
				? JSON.stringify(mayaFlowJson, null, 2)
				: '',
		},
		validate: (values) => {
			const errors = {};
			try {
				if (!values.textAreaValue)
					throw new Error('Please add a valid MayaJSON');
				const jsonObj = JSON.parse(values.textAreaValue);
				if (Array.isArray(jsonObj) && jsonObj.length > 0) {
					if (jsonObj.some((obj) => !obj.id || !obj.type))
						throw new Error(`Please check your JSON for valid nodes!`);
				} else throw new Error(`Please add a valid MayaJSON`);
			} catch (error) {
				errors.textAreaValue = error.message;
			}
			return errors;
		},
		validateOnMount: true,
		onSubmit: async (values, actions) => {
			actions.setSubmitting(true);
			setMayaFlowJson(JSON.parse(values.textAreaValue));
		},
	});
	/**
	 *
	 * @param {import('react').ChangeEvent<HTMLInputElement>} event
	 */
	const handleFileSelectorChange = (event) => {
		try {
			formik.setFieldTouched('textAreaValue');
			if (!event.target.files.length) throw new Error(`no file selected!`);
			const file = event.target.files[0];
			const fr = new FileReader();
			fr.onload = (e) => {
				try {
					const parsedJson = JSON.parse(e.target.result);
					formik.setValues((values) => ({
						...values,
						// prettify json text
						textAreaValue: JSON.stringify(parsedJson, null, 2),
					}));
				} catch (error) {
					console.error(error);
					formik.setValues((values) => ({
						...values,
						textAreaValue: '',
					}));
					formik.setErrors((errors) => ({
						...errors,
						textAreaValue: error.message,
					}));
				}
			};
			fr.readAsText(file);
		} catch (error) {
			formik.setErrors((errors) => ({
				...errors,
				textAreaValue: error.message,
			}));
		}
	};

	/**
	 *
	 * @param {import('react').ChangeEvent<HTMLTextAreaElement>} event
	 */
	const handleTextAreaChange = (event) => {
		formik.setFieldTouched('textAreaValue');
		formik.setValues((values) => ({
			...values,
			textAreaValue: event.target.value,
		}));
	};

	return (
		<>
			<Flex flex="1" minHeight="0" p="4">
				<Stack direction="column" spacing="1.5" pr="3">
					{importOptionDefs.map((nodeTypeObj, i) => {
						return (
							<Checkbox
								bg="#424242"
								px="3"
								py="0.7rem"
								borderRadius="0.25rem"
								size="sm"
								minWidth="36"
								key={nodeTypeObj.id}
								colorScheme="purple"
								value={nodeTypeObj.id}
								isChecked={
									nodeTypeObj.id === formik.values.selectedImportOption
								}
								onChange={(e) => {
									formik.setValues((values) => ({
										...values,
										selectedImportOption: nodeTypeObj.id,
									}));
								}}
							>
								<Text ml="1" fontSize="small">
									{nodeTypeObj.label}
								</Text>
							</Checkbox>
						);
					})}
				</Stack>
				<Flex flex="1" minWidth="0" direction="column">
					{formik.values.selectedImportOption === 'clipboard' ? (
						<Flex
							flex="1"
							minHeight="0"
							flexDirection="column"
							bg="#424242"
							borderRadius="0.25rem"
							p="1.5"
						>
							<Flex alignItems="center" fontSize="small" mb="1.5">
								<Text>Paste flow JSON or</Text>
								<FormLabel
									fontSize="small"
									borderRadius="0.2rem"
									bg="#595959"
									m="0"
									ml="2"
									py="1"
									px="2"
									_focusWithin={{
										boxShadow: 'var(--chakra-shadows-outline)',
									}}
									_hover={{
										filter: 'brightness(110%)',
									}}
									display="flex"
									alignItems="center"
									htmlFor="jsonFile"
								>
									<Icon as={AiOutlineDownload} mr="1" />
									Select file to import
									<Input
										id="jsonFile"
										type="file"
										position="absolute"
										w="1px"
										height="1px"
										p="0"
										overflow="hidden"
										border="0"
										_focusWithin={{
											boxShadow: 'none',
										}}
										onChange={handleFileSelectorChange}
									/>
								</FormLabel>
							</Flex>
							<FormControl
								flex="1"
								minHeight="0"
								display="flex"
								flexDirection="column"
								isInvalid={
									!!formik.errors.textAreaValue &&
									formik.touched.textAreaValue
								}
							>
								<Textarea
									flex="1"
									minHeight="0"
									bg="#424242"
									filter="brightness(70%)"
									p="1.5"
									_focus={{
										borderColor: '#b784e7',
									}}
									placeholder="Paste Maya flow JSON"
									value={formik.values.textAreaValue}
									onChange={handleTextAreaChange}
									onBlur={() =>
										formik.setFieldTouched('textAreaValue')
									}
								/>
								<FormErrorMessage>
									{formik.errors.textAreaValue}
								</FormErrorMessage>
							</FormControl>
						</Flex>
					) : null}
					{formik.values.selectedImportOption === 'library' ? (
						<Flex padding="0.5rem">Coming Soon!</Flex>
					) : null}
					{formik.values.selectedImportOption === 'examples' ? (
						<Flex padding="0.5rem">Coming Soon!</Flex>
					) : null}
				</Flex>
			</Flex>
			<StepperFooter
				onCancel={onModalClose}
				onContinue={async () => {
					try {
						await formik.handleSubmit();
						setStepIndex(stepIndex + 1);
					} catch (error) {
						console.error(error);
					}
				}}
				continueBtnProps={{
					isLoading: formik.isValidating || formik.isSubmitting,
					disabled: !formik.isValid,
				}}
			/>
		</>
	);
};

export default SelectFlowStepperView;
