import React from 'react';
import { CircularProgress, Box, Image, useColorMode } from '@chakra-ui/react';
import mayaIconDark from '../../../../../../assets/cardui/mayaicon_dark.svg';
import mayaIconLight from '../../../../../../assets/cardui/mayaicon_light.svg';
import CloseButton from './CloseButton';

const mayaIcon = {
	light: mayaIconLight,
	dark: mayaIconDark,
};

/**
 *
 * @param {{
 * 	status: 'normal' | 'waiting' | 'submitting',
 * 	onClose: () => void,
 * 	onMayaIconClick: () => void,
 * }} param0
 * @returns
 */
const Addon = ({ status, onClose, onMayaIconClick }) => {
	const { colorMode } = useColorMode();
	return (
		<Box>
			{status === 'normal' ? (
				<Image
					src={mayaIcon[colorMode]}
					boxSize="1.6rem"
					onClick={onMayaIconClick}
					ignoreFallback
				/>
			) : null}
			{status === 'waiting' ? (
				// <CircularProgress
				// 	isIndeterminate
				// 	size="25px"
				// 	ml={0}
				// 	color="gray"
				// 	thickness={0.2}
				// 	position="relative"
				// 	capIsRound
				// />
				// <Image
				// 	src={mayaIcon[colorMode]}
				// 	boxSize="1.6rem"
				// 	onClick={onMayaIconClick}
				// />
				<CloseButton onClick={onClose} />
			) : null}
			{status === 'submitting' ? <CloseButton onClick={onClose} /> : null}
		</Box>
	);
};

export default Addon;
