import { Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
	parseStringQuartz,
	parseCronQuartz,
	isCronSecondsValue,
	isCronMinutesValue,
	isCronHoursValue,
	isCronDayOfWeekValueString,
	isCronDayOfMonthValue,
} from '@mayahq/cron';
import PeriodSelector from './PeriodSelector';
import EverySecondsSelector from './Seconds/EverySecondsSelector';
import EveryMinutesSelector from './Minutes/EveryMinutesSelector';
import EveryHoursSelector from './Hours/EveryHoursSelector';
import EveryDayOfWeekSelector from './Day/EveryDayOfWeekSelector';
import EveryDayOfMonthSelector from './Day/EveryDayOfMonthSelector';
import EveryMonthSelector from './Month/EveryMonthSelector';
import EveryYearSelector from './Year/EveryYearSelector';
import EveryDaysSelector from './Day/EveryDaysSelector';
import parseCronObjectIntoPeriod from './parseCronObjectIntoPeriod';

/**
 *
 *	# SimpleCronScheduler
 * - value: quartz cron expression.
 * - onChange: function that returns the newly set cron expression.
 * @param {{
 * 	value: string,
 * 	onChange: (newValue: string) => void
 * }} param0
 * @returns
 */
const SimpleCronScheduler = ({ value, onChange }) => {
	const [cronObject, setCronObject] = useState(() => {
		const obj = parseStringQuartz(value);
		if (!obj) return parseStringQuartz('* * * * * * *');
		return obj;
	});
	const [selectedPeriod, setSelectedPeriod] = useState(
		/**
		 *
		 * @returns {import('./SimpleCronSchdeduler').TimePeriod}
		 */
		() => {
			const period = parseCronObjectIntoPeriod(cronObject);
			// default seconds
			if (period === 'unknown') return 'seconds';
			else return period;
		}
	);

	useEffect(() => {
		console.log(
			'🚀 ~ file: index.js ~ line 44 ~ useEffect ~ cronObject',
			cronObject
		);
		onChange(parseCronQuartz(cronObject));
	}, [cronObject, onChange]);

	return selectedPeriod !== 'unknown' ? (
		<Flex p="2" direction="column" alignItems="flex-start">
			<PeriodSelector
				selectedPeriod={selectedPeriod}
				onSelect={setSelectedPeriod}
			/>
			{selectedPeriod === 'seconds' ? (
				<EverySecondsSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{selectedPeriod === 'minutes' ? (
				<EveryMinutesSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{selectedPeriod === 'hours' ? (
				<EveryHoursSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{selectedPeriod === 'days' ? (
				<EveryDaysSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{selectedPeriod === 'dayOfWeek' ? (
				<EveryDayOfWeekSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{selectedPeriod === 'dayOfMonth' ? (
				<EveryDayOfMonthSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{/* {selectedPeriod === 'month' ? (
				<EveryMonthSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null}
			{selectedPeriod === 'year' ? (
				<EveryYearSelector
					cronObject={cronObject}
					onCronObjectChange={setCronObject}
				/>
			) : null} */}
		</Flex>
	) : null;
};

export default SimpleCronScheduler;
