// Global utilitity dependencies
import { forEach, isEmpty } from 'lodash';
import History from '../History';
import IPC from './ipc';

/**
 * IPC Funcion Utility to abstract react logic from IPC communications
 *
 * This Utility is conduit to be directly exported and used by react components
 * to access Electron backend services as needed for the react frontend through
 * requiste EVENT and METHODs
 *
 * @param {String} EVENT Event name for IPC based communication.
 * @param {Object} METHOD To mimic API like behaviours but with IPC
 * @param {Object} formData Form data to be sent with the API request.
 * @param {Object} initialData Form data to be sent with the API request.
 */

const IPCFunction = ({
	EVENT,
	formData = null,
	URL_PARAM = {},
	PATH_QUERY = {},
	initialData = null,
}) => {
	const urlParam = URL_PARAM;
	const pathQuery = PATH_QUERY;
	/**
	 * Fetch results from the IPC main
	 *
	 * Function performs the request to the provided IPC backend. Can be called manually,
	 * but it is also called when state changes in useEffect which can be used to
	 * load data when the page first loads.
	 */
	const fetchResultsIPC = (formData = null) => {
		// Set the error state to null.
		var data = {};
		if (formData === null) {
			data['params'] = urlParam;
			data['paths'] = pathQuery;
		} else {
			data = formData;
			console.log(data);
		}
		return new Promise((resolve, reject) => {
			IPC({ event: EVENT, data: data })
				.then((result) => {
					// Make a function call to set the result from the API request.
					resolve(result);
				})
				.catch((response) => {
					// Check if the response requires the user to log in again.
					if ('data' in response) {
						if (response.data.loginAgain) {
							// Let the user know they cannot access the page.
							console.log('Error:', response.data.message);
							console.log('going to login');
							/** Sending the user to the login page. */
							/** get current location */
							const currentPathWithoutDomain = `${History.location.pathname}${History.location.search}${History.location.hash}`;
							History.push('/login', {
								restoreLocation: currentPathWithoutDomain, // pass it via state to be restored after login
							});
						} else {
							// Return from the promise with the error from the API request.
							reject(response);
						}
					}
				});
		});
	};

	return fetchResultsIPC;
};

// Export the API Fetch utility.
export default IPCFunction;
