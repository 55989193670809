import React, { useContext } from 'react';
import { Code } from '@chakra-ui/layout';
import TabContext from './TabContext';

const JsonTabContent = () => {
	const { mayaFlowJson: flowJson } = useContext(TabContext);

	return (
		<Code
			flex="1"
			minHeight="0"
			lang="json"
			display="flex"
			flexDirection="column"
			bg="#424242"
			filter="brightness(70%)"
			borderRadius="0.25rem"
		>
			<pre
				style={{
					flex: '1',
					minHeight: '17.8rem',
					overflow: 'auto',
				}}
			>
				{JSON.stringify(flowJson, null, 2)}
			</pre>
		</Code>
	);
};

export default JsonTabContent;
