import { useDisclosure } from '@chakra-ui/hooks';
import { Flex, Text } from '@chakra-ui/layout';
import { CircularProgress } from '@chakra-ui/progress';
import { useContext, useEffect, useState } from 'react';
import getInstallerFlow from '../../../functions/store/getInstallerFlow';
import useQueryParams from '../../../util/useQueryParams';
import SkillPackInstallModal from '../../SkillPack/SkillPackInstallModal';
import StepperContext from './StepperContext';
import StepperFooter from './StepperFooter';

const InstallFlowStepperView = () => {
	const {
		onModalClose,
		stepIndex,
		setStepIndex,
		mayaFlowJson,
		setMayaFlowJson,
	} = useContext(StepperContext);
	const query = useQueryParams();
	const currentBrainId = query.get('id');
	const [installablePack, setInstallablePack] = useState(null);
	const [installablePackIsLoading, setInstallablePackIsLoading] =
		useState(false);
	const {
		isOpen: skillPackInstallModalIsOpen,
		onClose: skillPackInstallModalOnClose,
		onOpen: skillPackInstallModalOnOpen,
	} = useDisclosure();

	useEffect(() => {
		const getFlow = async () => {
			try {
				if (!mayaFlowJson) return;
				setInstallablePackIsLoading(true);
				const res = await getInstallerFlow(mayaFlowJson);
				setInstallablePackIsLoading(false);
				if (!res) throw new Error(`Invalid response`);
				setInstallablePack(res);

				skillPackInstallModalOnOpen();
			} catch (error) {
				console.error(error);
				setInstallablePackIsLoading(false);
			}
		};
		getFlow();
	}, [mayaFlowJson, skillPackInstallModalOnOpen]);

	return (
		<>
			<Flex flex="1" minHeight="0" p="4" direction="column">
				{installablePackIsLoading ? (
					<Flex alignItems="center">
						<CircularProgress size="20px" isIndeterminate />
						<Text ml="2">Fetching Flow information. Please wait...</Text>
					</Flex>
				) : (
					<Flex alignItems="center">
						<Text ml="2">
							Please install the flow as per the instructions shown...
						</Text>
					</Flex>
				)}
				{installablePack && !installablePackIsLoading ? (
					<SkillPackInstallModal
						currentBrainId={currentBrainId}
						pack={installablePack}
						publishedSkillPackId={null}
						skillPackId={null}
						isOpen={skillPackInstallModalIsOpen}
						onClose={() => {
							skillPackInstallModalOnClose();
							onModalClose();
						}}
					/>
				) : null}
			</Flex>
			<StepperFooter
				onCancel={onModalClose}
				onContinue={() => setStepIndex(stepIndex + 1)}
				continueBtnProps={{
					disabled: true,
					children: 'Finish',
				}}
			/>
		</>
	);
};

export default InstallFlowStepperView;
