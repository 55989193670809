import React from 'react';
import {
	Box,
	useColorMode,
	Input,
	useColorModeValue,
	Image,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { useLocation } from 'react-router-dom';

//Components
import TagTemplate from './Tag';

//Utils
import KeyEnum from '../../util/KeyEnum';
import History from '../../util/History';

//Redux
import { connect } from 'react-redux';
import { setNavSearchQuery } from '../../redux/actions';
import { PanelExpandClose } from '../Editor/Panel';

//Assets
import enterIconDark from '../../assets/cardui/entericon_dark.svg';
import enterIconLight from '../../assets/cardui/entericon_light.svg';

/**
 * Navigation Search Bar
 *
 * Helps search through and navigate the dashboard.
 */

export const SearchBar = ({ setNavSearchQuery, route, mode, misc }) => {
	const { colorMode } = useColorMode();
	const [input, setInput] = React.useState('');
	const [tagArray, setTagArray] = React.useState([]);

	const enterIcon = {
		light: enterIconLight,
		dark: enterIconDark,
	};

	let search = window.location.search;
	let params = new URLSearchParams(search);
	let pathQuery = params.get('q');
	let location = useLocation();
	React.useEffect(() => {
		if (pathQuery) {
			setInput(pathQuery);
			setNavSearchQuery(pathQuery);
		} else {
		}
	}, [pathQuery, setNavSearchQuery]);

	React.useEffect(() => {
		console.log(route);
		if (route.includes('home')) {
			setTagArray(['home']);
		} else if (route.includes('skills') && !route.includes('store')) {
			setTagArray(['skills']);
		} else if (
			route.includes('store') &&
			!route.includes('skills') &&
			!route.includes('modules') &&
			!route.includes('commands')
		) {
			setTagArray(['store']);
		} else if (route.includes('store/skills')) {
			setTagArray(['store', 'skills']);
		} else if (route.includes('store/modules')) {
			setTagArray(['store', 'modules']);
		} else if (route.includes('store/commands')) {
			setTagArray(['store', 'commands'])
		} else if (route.includes('library')) {
			setTagArray(['library']);
		} else if (route.includes('settings')) {
			setTagArray(['settings']);
		} else if (route.includes('updates')) {
			setTagArray(['updates']);
		}
		setNavSearchQuery('');
		setInput('');
	}, [route, setNavSearchQuery]);

	const handleOnKeyDown = (e) => {
		const { keyCode } = e;
		if (keyCode === KeyEnum.ENTER) {
			e.preventDefault();
			if (mode !== 'menu') {
				if (input) {
					History.push(`${location.pathname}?q=${input}`);
				} else {
					History.push(`${location.pathname}`);
				}
			}

			setNavSearchQuery(input);
		} else if (keyCode === KeyEnum.ESC) {
			e.preventDefault();
			if (mode !== 'menu') {
				History.push(`${location.pathname}`);
			}
			setNavSearchQuery('');
			setInput('');
		}
	};

	const color = {
		light: '#8E8E8E',
		dark: 'dashboard.font_dark',
	};
	const fontColor = {
		light: '#6C6C6C',
		dark: 'dashboard.font_dark',
	};
	return (
		<Box
			flex="1 1 auto"
			// maxWidth="1575px"
			paddingLeft="8"
			paddingRight="8"
			height="82px"
			display="flex"
			alignItems="center"
		>
			<Box
				bg={useColorModeValue('dashboard.bg_light.100', 'maya_dark.250')}
				borderRadius="5px"
				paddingY="1"
				// width={mode === "menu" ? "72vw" : "80vw"}
				height="50px"
				width="100%"
				border="solid 1px"
				borderColor={useColorModeValue(
					'dashboard.border_light',
					'dashboard.border_dark'
				)}
				display="flex"
				alignItems="center"
				boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
			>
				<SearchIcon ml="4" mr="2" color={color[colorMode]} />
				{tagArray.map((tag, i) => {
					return <TagTemplate type={tag} key={i} />;
				})}

				<Input
					bg="transparent"
					border="none"
					value={input}
					_hover={{ border: 'none' }}
					_focus={{ border: 'none' }}
					fontFamily="body"
					color={fontColor[colorMode]}
					onChange={(event) => setInput(event.target.value)}
					onKeyDown={handleOnKeyDown}
				/>
				<Image
					src={enterIcon[colorMode]}
					boxSize="1.3rem"
					mr={5}
					opacity={input === '' ? 0.5 : 1}
				/>
			</Box>
			{mode === 'menu' ? (
				<PanelExpandClose
					expanded={misc.expanded}
					setExpanded={misc.setExpanded}
					handleHideExpand={misc.handleHideExpand}
					handleClose={misc.handleClose}
				/>
			) : null}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { navigation } = state;
	const { route } = navigation;
	return { route };
};

export default connect(mapStateToProps, { setNavSearchQuery })(SearchBar);
