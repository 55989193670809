import React, { useCallback } from 'react';
import {
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	useColorModeValue,
	Button,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { AiFillApple, AiFillWindows } from 'react-icons/ai';
import { FaCloud } from 'react-icons/fa';
import { DiLinux } from 'react-icons/di';

//Components
import IconContainer from '../../util/React_Icon';
//Functions
import getDevices from '../../functions/devices/getDevices';
import { normalize } from '../../util/Misc';
//Redux
import { connect } from 'react-redux';
import { setDevices } from '../../redux/actions';

import GetProfileSlug from '../../util/ProfileSlug';
import truncate from 'lodash/truncate';
import NewDevice from './NewDevice';

const DeviceItem = ({ deviceName, release, platform, rightIcon }) => {
	var iconColor = useColorModeValue('#656565', 'white');
	const platformLogos = {
		win: (
			<IconContainer
				icon={<AiFillWindows />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.8rem',
				}}
			/>
		),
		darwin: (
			<IconContainer
				icon={<AiFillApple />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '2rem',
				}}
			/>
		),
		linux: (
			<IconContainer
				icon={<DiLinux />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.7rem',
				}}
			/>
		),
		cloud: (
			<IconContainer
				icon={<FaCloud />}
				value={{
					color: iconColor,
					className: 'global-class-name',
					size: '1.7rem',
				}}
			/>
		),
	};
	return (
		<Box
			height="3.5rem"
			width="15.5rem"
			display="flex"
			flexDirection="row"
			alignItems="center"
			justifyContent="space-between"
			fontFamily="body"
			borderRadius="5px"
			cursor="pointer"
			transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
		>
			<Box display="flex" alignItems="center">
				<Box marginLeft="1rem">{platformLogos[platform]}</Box>
				<Box marginLeft="1rem">
					<Box
						fontSize="0.9rem"
						color={useColorModeValue('#444444', 'white')}
					>
						{truncate(deviceName, { length: 16 })}
					</Box>
					<Box
						fontSize="0.6rem"
						color={useColorModeValue('#888383', '#c3c3c3')}
					>
						{release}
					</Box>
				</Box>
			</Box>
			{rightIcon && <ChevronDownIcon marginLeft="1rem" marginRight="1rem" />}
		</Box>
	);
};

const SelectDevice = (props) => {
	const [selectedDevice, selectDevice] = React.useState(
		getDevices().then(async (res) => {
			return await res[0];
		})
	);

	const refreshDevices = useCallback(async () => {
		const res = await getDevices();
		if (Array.isArray(res.results)) {
			props.setDevices(normalize(res.results));
			selectDevice(res.results[0]);
			props.onChange(res.results[0]);
		}
	}, []);

	React.useEffect(() => {
		refreshDevices();
	}, [refreshDevices]);

	return (
		<Box {...props}>
			{props.devices.list.allIds.length ? (
				<Menu
					border="solid 1px"
					borderColor={'dashboard.border_dark'}
					placement="auto-start"
				>
					<MenuButton
						as={Box}
						borderRadius="5px"
						cursor="pointer"
						width="15.5rem"
						border="solid 1px"
						bg={'#424242'}
						borderColor={'dashboard.border_dark'}
						boxShadow="rgba(0, 0, 0, 0.05) 1px 2px 2px"
					>
						<DeviceItem
							deviceName={selectedDevice.name}
							release={selectedDevice.release}
							platform={selectedDevice.platform}
							rightIcon={true}
						/>
					</MenuButton>
					<MenuList bg={'maya_dark.300'}>
						{props.devices.list.allIds.map((deviceId) => {
							return (
								<MenuItem
									minH="48px"
									padding="0"
									key={deviceId}
									transition="all 0.2s cubic-bezier(.08,.52,.52,1)"
									onClick={() => {
										selectDevice(props.devices.list.byId[deviceId]);
										props.onChange(props.devices.list.byId[deviceId]);
									}}
								>
									<DeviceItem
										deviceName={
											props.devices.list.byId[deviceId].name
										}
										release={
											props.devices.list.byId[deviceId].release
										}
										platform={
											props.devices.list.byId[deviceId].platform
										}
										rightIcon={false}
									/>
								</MenuItem>
							);
						})}
					</MenuList>
				</Menu>
			) : (
				<NewDevice onCreate={() => refreshDevices()} />
			)}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { devices } = state;
	return { devices };
};

export default connect(mapStateToProps, { setDevices })(SelectDevice);
