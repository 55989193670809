/**
 * Brain Reducer
 *
 * Creates a Redux reducer for populating the robots.
 */

import actionTypes from '../actionTypes';
import produce from 'immer';

// Setup initial state with an fleet info object.
const initialState = {
	byId: {},
	allIds: [],
};

// Export the Brain Reducer.
const brainReducer = produce(
	/**
	 *
	 * @param {typeof initialState} draft
	 * @param {{
	 * 	type: keyof typeof actionTypes,
	 * 	payload: unknown
	 * }} action
	 * @returns
	 */
	(draft, action) => {
		switch (action.type) {
			case actionTypes.ADD_BRAINS: {
				/** Add new brains (normalized) to the brains in redux, overwrite if already exists */
				const { info, refresh } = action.payload;
				if (refresh) {
					/** reset draft */
					draft.allIds = [];
					draft.byId = {};
				}
				/** Using `set` to prevent duplicates */
				draft.allIds = [...new Set([...draft.allIds, ...info.allIds])];
				draft.byId = { ...draft.byId, ...info.byId };
				return draft;
			}
			case actionTypes.REMOVE_BRAIN: {
				const { info } = action.payload;
				const index = draft.allIds.indexOf(info);
				if (index > -1) {
					draft.allIds.splice(index, 1);
					delete draft.byId[info];
				}
				return draft;
			}
			case actionTypes.UPDATE_BRAIN: {
				const { info } = action.payload;
				draft.byId[info._id] = info;
				return draft;
			}
			case actionTypes.UPDATE_BRAIN_BY_ID: {
				const { info, id } = action.payload;
				draft.byId[id] = { ...draft.byId[id], ...info };
				return draft;
			}

			case actionTypes.CLEAR_BRAINS: {
				return initialState;
			}

			default:
				return draft;
		}
	},
	initialState
);

export default brainReducer;
