import APIFunction from '../../util/APIFetch/function';
import APIRoutes from '../../util/APIRoutes';

// config
import { config } from '../../config';
import { store } from '../../redux/store';

const getDeveloperAccounts = async ({ slug }) => {
	var { user } = store.getState();
	const profileSlug = slug ? slug : user.info.id;

	const getDeveloperAccountsAPIFn = APIFunction({
		BASEURL: config.apiServer.BASEURL,
		PATH_SEARCH: APIRoutes.apiService.getDeveloperAccounts.PATH_SEARCH,
		PATH_METHOD: APIRoutes.apiService.getDeveloperAccounts.PATH_METHOD,
		URL_PARAM: {
			profileSlug: profileSlug,
		},
	});

	const getDeveloperAccountsAPIResponse = await getDeveloperAccountsAPIFn();
	return getDeveloperAccountsAPIResponse;
};

export default getDeveloperAccounts;
