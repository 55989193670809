import React from 'react';
import { Box, useColorModeValue, Button } from '@chakra-ui/react';
import { connect } from 'react-redux';

//components

import ContactServiceItem from './ContactServiceItem';

//redux
import { setContacts } from '../../../redux/actions';

//functions
import getContacts from '../../../functions/contacts/getContacts';
import listContactServices from '../../../functions/contacts/listContactServices';

const ContactSection = ({ contacts, setContacts }) => {
	const [services, setServices] = React.useState([]);
	// React.useEffect(() => {
	//   getContacts().then((res) => {
	//     setContacts(res);
	//   });
	// }, [setContacts]);
	React.useEffect(() => {
		listContactServices().then((res) => {
			console.log('Contact Service', res);
			setServices(res);
		});
	}, []);
	return (
		<Box paddingTop="1rem">
			{services.length > 0
				? services.map((service) => {
						if (contacts) {
							return (
								<ContactServiceItem
									contactsDetails={contacts[service.resource]}
									contactService={service.resource}
									contactServiceName={service.name}
									serviceDetails={service}
									setContacts={setContacts}
								/>
							);
						} else {
							return (
								<ContactServiceItem
									// contactsDetails={"contacts[service.resource]"}
									contactService={service.resource}
									contactServiceName={service.name}
									serviceDetails={service}
									setContacts={setContacts}
								/>
							);
						}
				  })
				: null}
		</Box>
	);
};

const mapStateToProps = (state) => {
	const { contacts } = state.profiles;
	return { contacts };
};

export default connect(mapStateToProps, { setContacts })(ContactSection);
