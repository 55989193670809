import React from 'react';

import { Box } from '@chakra-ui/react';
import _ from 'lodash';

//Components
import SkillPackList from '../SkillPack/SkillPackList';

const LibrarySkillPackSection = ({
	currentBrainId,
	isLoading,
	skillsButton,
	addButton,
	deleteButton,
	onClick,
	wholeItemActive,
	packs,
	setPacks,
}) => {
	return (
		<Box
			display="flex"
			flexDirection="column"
			paddingY="1rem"
			// width="95%"
			height="100%"
			borderRadius="10px"
			flex="1 1 auto"
		>
			<SkillPackList
				currentBrainId={currentBrainId}
				isLoading={isLoading}
				packs={packs}
				skillsButton={skillsButton}
				addButton={addButton}
				deleteButton={deleteButton}
				onClick={onClick}
				wholeItemActive={wholeItemActive}
				setPacks={setPacks}
			/>
		</Box>
	);
};

export default LibrarySkillPackSection;
