import React from 'react';
import {
	useDisclosure,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	useColorModeValue,
	Box,
	LightMode,
	Button,
	useToast,
} from '@chakra-ui/react';
import CustomModal from '../Reusable/ChakraCustom/CustomModal';
import PostInstallScreen from '../Reusable/PostInstallScreen';
import HeaderWithDescription from '../Reusable/HeaderWithDesc';
import SelectBrain from '../Brains/SelectBrain';
// functions
import getBrainsByPublishedSkillPack from '../../functions/publishedSkillPack/getBrainsByPublishedSkillPack';
import uninstallPublishedSkillPackFromBrain from '../../functions/publishedSkillPack/uninstallPublishedSkillPackFromBrain';
import updatePublishedSkillPackToBrain from '../../functions/publishedSkillPack/updatePublishedSkillPackToBrain';
import HistoryWithSlug from '../../util/History';
import { normalize } from '../../util/Misc';
import getSkillPack from '../../functions/skillPack/getSkillPack';
import getPublishedSkillPack from '../../functions/publishedSkillPack/getPublishedSkillPack';
import getPublishedSkillPackGQuery from '../../functions/publishedSkillPack/getPublishedSkillpackGquery';
import GetProfileSlug from '../../util/ProfileSlug';
import analytics from '../../util/Analytics';
// Redux imports
import { addTab, updateBrainById } from '../../redux/actions';
import { connect } from 'react-redux';

const SkillDetailsModal = ({
	isOpen,
	onOpen,
	onClose,
	publishedSkillPack,
	addTab,
	updateBrainById,
}) => {
	const [brains, setBrains] = React.useState({});
	const [selectedBrain, selectBrain] = React.useState();
	const [option, setOption] = React.useState('default');
	const [loadingText, setLoadingText] = React.useState('Loading');
	const [successText, setSuccessText] = React.useState();
	const [isLoading, setLoading] = React.useState(false);
	const { slug } = GetProfileSlug();
	const toast = useToast();
	const borderColor = useColorModeValue(
		'dashboard.border_light',
		'dashboard.border_dark'
	);
	React.useEffect(() => {
		getBrainsByPublishedSkillPack({
			publishedSkillPackId: publishedSkillPack._id,
		}).then((res) => {
			setBrains(normalize(res));
			console.log('brains are here', res);
		});
	}, []);

	const handleUninstall = async () => {
		try {
			setLoading(true);
			setLoadingText('Uninstalling');
			var formData = {
				PublishedSkillPackToInstall: publishedSkillPack,
				brainId: selectedBrain._id,
				profileSlug: slug,
			};

			const res = await uninstallPublishedSkillPackFromBrain(formData);
			if (res['error']) {
				console.log('error in uninstall step', res);
				throw new Error('uninstall failed');
			} else {
				setLoading(false);
				setSuccessText('Uninstalled');
				updateBrainById(formData.brainId, res.brain);
				toast({
					title: 'Success!',
					description: 'This collection was successfully uninstalled',
					status: 'success',
					duration: 3000,
					isClosable: true,
				});
				analytics.track(`[Home] Collection Item : Uninstall Modal`, {
					feature: 'Home',
					_id: publishedSkillPack._id,
					name: publishedSkillPack.name,
					status: 'success',
				});
			}
		} catch (error) {
			console.log('error in unknown step', error);
			setLoading(false);
			toast({
				title: 'Error!',
				description: 'This collection could not be uninstalled.',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			analytics.track(`[Home] Collection Item : Uninstall Modal`, {
				_id: publishedSkillPack._id,
				name: publishedSkillPack.name,
				error,
				status: 'failure',
			});
		}
	};

	const handleUpdate = async () => {
		try {
			setLoading(true);
			setLoadingText('Updating');
			let psp = await getPublishedSkillPackGQuery({
				_id: publishedSkillPack._id,
			});
			// let psp = await getPublishedSkillPack({
			// 	_id: publishedSkillPack._id,
			// });
			let skillPack = await getSkillPack({
				_id: publishedSkillPack.skillPack,
			});
			if (publishedSkillPack) {
				for (let brainId of brains.allIds) {
					await updatePublishedSkillPackToBrain({
						SkillPackToInstall: skillPack,
						PublishedSkillPackToInstall: psp.data,
						version: psp.data.currentVersion,
						brainId: brainId,
						profileSlug: slug,
					});
				}
			}
			setLoading(false);
			successText('Updated');
		} catch (e) {
			console.log(e);
			setLoading(false);
			toast({
				title: 'Error!',
				description: 'This collection could not be uninstalled',
				status: 'error',
				duration: 3000,
			});
		}
	};

	const handleContinue = (selectedBrain) => {
		if (!selectedBrain) {
			toast({
				title: 'Error!',
				description: 'Please select brain to continue',
				status: 'error',
				duration: 3000,
				isClosable: true,
			});
			return;
		}
		if (option === 'editor') {
			addTab(selectedBrain._id, selectedBrain.name);
			HistoryWithSlug.push(`/edit?id=${selectedBrain._id}`);
		} else if (option === 'update') {
			// handleUpdate();
			HistoryWithSlug.push(`/updates`);
		} else if (option === 'uninstall') {
			handleUninstall();
		}
	};
	return (
		<>
			<CustomModal
				onClose={() => {
					if (option !== 'default') {
						setOption('default');
					} else {
						onClose();
					}
				}}
				isOpen={isOpen}
				isCentered={true}
			>
				<ModalOverlay />
				<ModalContent
					bg={useColorModeValue('white', 'maya_dark.300')}
					height={
						publishedSkillPack.postInstallInstruction
							? publishedSkillPack.postInstallInstruction.length > 100
								? '40.5rem'
								: '39rem'
							: publishedSkillPack.longDescription.length > 100
							? '40.5rem'
							: '39rem'
					}
					maxWidth="55rem"
				>
					<ModalCloseButton
						right="2"
						border="none"
						cursor
						_focus={{ outline: 'none' }}
					/>
					<ModalBody
						paddingX="0"
						display="flex"
						flex="1"
						flexDir="column"
						minHeight="0"
					>
						{option === 'default' ? (
							<Box>
								<HeaderWithDescription
									header={`${publishedSkillPack.name}`}
									desc={`${publishedSkillPack.shortDescription}`}
									paddingX="1rem"
								/>
								<Box
									display="flex"
									justifyContent="space-around"
									flexDir="column"
									flex="1"
									minHeight="0"
								>
									<PostInstallScreen
										publishedSkillPack={publishedSkillPack}
										isInstalled={true}
									/>
								</Box>
								<Box
									paddingX="1rem"
									borderTop="solid 1px"
									borderColor={borderColor}
									height="3.5rem"
									alignItems="center"
									justifyContent="flex-end"
									display="flex"
								>
									<LightMode>
										<Button
											bg="#444444"
											_hover={{ bg: '#666666' }}
											size="sm"
											mr="2"
											borderRadius="5px"
											variant={'solid'}
											paddingX="0.9rem"
											marginTop="0.5rem"
											onClick={() => {
												setOption('editor');
											}}
										>
											open
										</Button>
									</LightMode>
									<LightMode>
										<Button
											bg="#444444"
											_hover={{ bg: '#666666' }}
											size="sm"
											mr="2"
											borderRadius="5px"
											variant={'solid'}
											paddingX="0.9rem"
											marginTop="0.5rem"
											onClick={() => {
												setOption('uninstall');
											}}
										>
											uninstall
										</Button>
									</LightMode>
									<LightMode>
										<Button
											bg="#444444"
											_hover={{ bg: '#666666' }}
											size="sm"
											mr="2"
											borderRadius="5px"
											variant={'solid'}
											paddingX="0.9rem"
											marginTop="0.5rem"
											onClick={() => {
												setOption('update');
												HistoryWithSlug.push(`/updates`);
											}}
										>
											update
										</Button>
									</LightMode>
								</Box>
							</Box>
						) : (
							<Box
								paddingX="1rem"
								display="flex"
								flexDirection="column"
								justifyContent="space-between"
								height="100%"
							>
								<Box>
									<HeaderWithDescription
										header={`${
											option === 'uninstall' ? 'Uninstall' : 'Open'
										} ${publishedSkillPack.name} Skill`}
										desc={`${publishedSkillPack.name} collection has been installed in the following brains. Select one of the brains to continue.`}
									/>
									<Box
										display="flex"
										justifyContent="space-around"
										flexDir="column"
										flex="1"
										minHeight="0"
									>
										<SelectBrain
											brains={brains}
											brainIds={brains.allIds}
											isLoading={!selectedBrain}
											skipNewBrain={false}
											onSelectFn={(id) => {
												selectBrain(brains.byId[id]);
												console.log(
													'selected Brain',
													selectedBrain
												);
											}}
										/>
									</Box>
								</Box>

								<Box
									borderTop="solid 1px"
									borderColor={borderColor}
									height="3.5rem"
									alignItems="center"
									justifyContent="flex-end"
									display="flex"
								>
									<LightMode>
										<Button
											bg="#444444"
											_hover={{ bg: '#666666' }}
											size="sm"
											borderRadius="5px"
											variant={'solid'}
											paddingX="1.5rem"
											mr="4"
											marginTop="0.5rem"
											onClick={() => {
												setOption('default');
											}}
										>
											Back
										</Button>
									</LightMode>
									<LightMode>
										<Button
											colorScheme="purple"
											size="sm"
											borderRadius="5px"
											variant={'solid'}
											paddingX="0.9rem"
											marginTop="0.5rem"
											isLoading={isLoading}
											loadingText={`${loadingText}...`}
											isDisabled={
												!isLoading && selectedBrain ? false : true
											}
											onClick={() => {
												handleContinue(selectedBrain);
											}}
										>
											{successText ? successText : 'Continue'}
										</Button>
									</LightMode>
								</Box>
							</Box>
						)}
					</ModalBody>
				</ModalContent>
			</CustomModal>
		</>
	);
};

export default connect(null, { addTab, updateBrainById })(SkillDetailsModal);
